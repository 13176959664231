import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import SelectDate from "../../../common/form/SelectDate";
import { History } from "history";
import * as Yup from "yup";
import CateService from "../../../../services/service/Service";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";

interface Props {
  history: History;

}
interface State {
  name: string;
  code: string;
  type: string;
  end_date: string;
  start_date: string;
}
class CreateForm extends Component<InjectedFormikProps<Props, State>> {

  onChangeSelectDateValid = (date, dateString) => {
    this.props.setFieldValue("end_date", dateString);
  };

  onChangeSelectDateRelease = (date, dateString) => {
    this.props.setFieldValue("start_date", dateString);
  };

  onSetValue = () => {
  }

  render() {
    const {
      name,
      code,
      end_date,
      start_date,
    } = this.props.values;

    return (
      <form>
        <div className="input-group">
          <InputWithLabel
            name="name"
            label="Tên dịch vụ"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
            maxLength={255}
          />
          <InputWithLabel
            name="code"
            label="Mã dịch vụ"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
            maxLength={255}
          />
          <SelectDate
            label="Ngày hiệu lực"
            name="start_date"
            wrappedClass="col-md-3 form-group h-35"
            onChange={this.onChangeSelectDateRelease}
            value={start_date}
            error={this.props.errors.start_date}
            isRequired={true}
            placeholder={'yyyy-mm-dd'}
          />
          <SelectDate
            label="Ngày hết hiệu lực"
            name="end_date"
            wrappedClass="col-md-3 form-group h-35"
            onChange={this.onChangeSelectDateValid}
            value={end_date}
            error={this.props.errors.end_date}
            isRequired={true}
            placeholder={'yyyy-mm-dd'}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url='/dich-vu'></BackButton>
          <SubmitButton onClick={this.props.handleSubmit}></SubmitButton>
        </div>
      </form>
    );
  }
}

const RenderCreateForm = withFormik<Props, State>({
  mapPropsToValues: () => ({
    name: "",
    code: "",
    type: "",
    end_date: "",
    start_date: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên dịch vụ không được bỏ trống."),
    code: Yup.string().required("Mã dịch vụ không được bỏ trống."),
    start_date: Yup.date().required("Ngày hiệu lực không được trống"),
    end_date: Yup.date().required("Ngày hết hiệu lực không được trống"),
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new CateService();
    const result = await service.createService(values);
    if (result) {
      if (result.status === 200) {
        onSuccessAction('Thêm mới dịch vụ thành công!', function () {
          props.history.goBack();
        })
      } else if (result.status === 422) {
        setErrors(result.error);
      } else {
        onFailAction(result.errors);
      }
    }
  }
})(CreateForm);

export default RenderCreateForm;
