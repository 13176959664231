export const PERSONAL_SCALE_OPTIONS = {
  1: "Dưới 10 nhân sự",
  2: "Trên 10 nhân sự"
};

export const SUPPLY_CAPACITY_OPTIONS = {
  1: "Dưới 50 thuê bao",
  2: "Trên 50 thuê bao"
};

export const CONTRACT_TYPE_OPTIONS = {
  2: "Hợp đồng điện tử",
  1: "Hợp đồng giấy",
};

export const STATUS_LABEL_CLASS = {
  1: {
    label: "Nháp",
    class: ""
  },
  2: {
    label: "Chờ duyệt",
    class: "orange"
  },
  3: {
    label: "Từ chối",
    class: "red"
  },
  4: {
    label: "HTKD Đã duyệt",
    class: "blue"
  },
  5: {
    label: "Giám đốc từ chối",
    class: "red"
  },
  6: {
    label: "Hoạt động",
    class: "green"
  }
};

export enum AgencyEnum {
  STATUS_WAITING_APPROVE = 2,
  STATUS_DRAFT = 1,
  DIGITAL_CONTRACT = 2,
}

export const AgencyRules = {
  code: [
    {
      required: true,
      message: "Mã đại lý không thể bỏ trống!"
    }
  ],
  tax_code: [
    {
      required: true,
      message: "Mã số thuế không thể bỏ trống!"
    }
  ],
  deputy: [{}]
};

export const AGENCY_CONTACT = {
  1: "Ban lãnh đạo",
  2: "Kinh doanh",
  3: "Kỹ thuật nghiệp vụ",
  4: "Hỗ trợ khách hàng",
  5: "Kế toán"
};

export enum AGENCY_CONACT_TYPE_ENUM {
  DIRECTORS = 1,
  BUSINESS = 2,
  SKILL_BUSINESS = 3,
  CUSTOMER_SUPPORT = 4,
  ACCOUNTANT = 5
}
