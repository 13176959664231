import React, { Component } from "react";
import "../css/main.css";
import InputWithLabel from "../form/InputWithLabel";
import { formatDateTime } from "../../../helpers/NewCaHelper";

interface Props {
    assetList?: any;
    changeData?: any;
    errors?: any;
    data?: any;
    //onDelete?: any;
    //keyDelete?: any;
    keyDownload?: any;
    tittle?: string;
    //changeSelectDate?: any;
    isDisabled?: boolean;
    isHidden?: boolean;
}

interface State {
}


export default class Table extends Component<Props, State> {
    render() {
        const dataSource = this.props.data;
        return (
            <table className="w-100 m-t-5 ant-table-bordered" key="2">
                <thead className="ant-table-thead">
                    <tr>
                        <th className="text-center w-5">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">STT</span>
                                </div>
                            </span>
                        </th>
                        <th className="text-center w-25">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Serial Number</span>
                                </div>
                            </span>
                        </th>
                        <th className="text-center w-15">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Ngày bắt đầu</span>
                                </div>
                            </span>
                        </th>
                        <th className="text-center w-15">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Ngày kết thúc</span>
                                </div>
                            </span>
                        </th>
                        <th className="text-center w-15">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Mã token</span>
                                </div>
                            </span>
                        </th>
                        <th className="text-center w-15">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Ngày cài đặt</span>
                                </div>
                            </span>
                        </th>
                        {/* <th className="text-center w-15">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">File chứng thư số</span>
                                </div>
                            </span>
                        </th> */}
                    </tr>
                </thead>
                <tbody className="ant-table-tbody">
                    {dataSource ? (
                        Object.keys(dataSource).map((key, index) => (
                            <tr
                                className={`ant-table-row editable-row ant-table-row-level-${key}`}
                                data-row-key={key}
                                key={key}
                            >
                                <td className="text-center">{1 + Number(key)}</td>
                                <td className="text-center">
                                    <InputWithLabel
                                        name={`serial_number${key}`}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.changeData}
                                        value={dataSource[key]["serial_number"]}
                                        dataKey={"serial_number"}
                                        isDisabled={this.props.isDisabled}
                                    />
                                </td>
                                <td className="text-center">
                                    <InputWithLabel
                                        name={`cert_begin${key}`}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.changeData}
                                        value={formatDateTime(dataSource[key]["cert_begin"]) || ""}
                                        dataKey={"cert_begin"}
                                        isDisabled={this.props.isDisabled}
                                    />
                                </td>
                                <td className="text-center">
                                    <InputWithLabel
                                        name={`cert_end${key}`}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.changeData}
                                        value={formatDateTime(dataSource[key]["cert_end"]) || ""}
                                        dataKey={"cert_end"}
                                        isDisabled={this.props.isDisabled}
                                    />
                                </td>
                                <td className="text-center">
                                    <InputWithLabel
                                        name={`token_code${key}`}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.changeData}
                                        value={dataSource[key]["token_code"]}
                                        dataKey={"token_code"}
                                        isDisabled={this.props.isDisabled}
                                    />
                                </td>
                                <td className="text-center">
                                    <InputWithLabel
                                        name={`setting_date${key}`}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.changeData}
                                        value={formatDateTime(dataSource[key]["setting_date"]) || ""}
                                        dataKey={"token_code"}
                                        isDisabled={this.props.isDisabled}
                                    />
                                </td>
                                {/* <td className="text-center">
                                    <div className="">
                                    <a  onClick={this.props.keyDownload}>
                                        <i className="fa fa-download"></i> Tải xuống
                                    </a>
                                    </div>
                                </td> */}
                            </tr>
                        ))
                    ) : (
                        <tr />
                    )}
                </tbody>
            </table>
        );
    }
}
