
import { BehaviorSubject } from 'rxjs';
import FetchAPI from '../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../helpers/constant/MethodAPIConstant';

const currentUserSubject = new BehaviorSubject(
    JSON.parse(localStorage.getItem("currentUser")!)
);
const currentUserPermission = new BehaviorSubject([""]);

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    currentUserPermissions: currentUserPermission.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value;
    },
    get currentUserPermissionValue(){
        return currentUserPermission.getValue();
    },
    setCurrentUserPermissionValue: value => {
        currentUserPermission.next(value);
    }
};

async function login(data) {
    const fetchAPI = new FetchAPI();
    const user = await fetchAPI.request(`auth/login`, data, TypeMethodContsantEnum.POST);
    if (user && user.token) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        currentUserSubject.next(user);
        return user;
    }else if(user){
        return user;
    }
    return null;
}

function logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    currentUserPermission.next([]);
}
