import React, { Component } from 'react'
import { match, RouteComponentProps } from 'react-router'
import withNavbar from '../../../common/HOC/NavbarContainerHOC'
import UpdateUserForm from './UpdateUserForm'

interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams>{
    match:match<MatchParams>;
}
interface State {
    
}

class UpdateUser extends Component<Props, State> {
    state = {}

    componentDidMount(){
    }

    render() {
        return (
            <div className="card">
                <div className="card-title1">Cập nhật người dùng</div>
                <div className="card-body">
                    <UpdateUserForm history={this.props.history} id={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}


const updateUserWithNavBar = withNavbar()(UpdateUser);

export default updateUserWithNavBar;

