import React, { Component } from "react";
import { Modal } from "antd";
import { Table } from "antd";
import { formatDateTime } from "../../../../helpers/NewCaHelper";
import { TYPEEXPORT_TOKEN_DETAIL } from "./../Enum";
import { Tag } from "antd";

interface Props {
  handleCancel?: any;
  data?: any;
  visible?: boolean;
  loading?: boolean;
  title?: string;
}
interface State {
  visible: boolean;
}

const renderTypeColumn = text => {
  return (
    <div>
      <span className={`badge ${TYPEEXPORT_TOKEN_DETAIL[text].class} ml-2`}>
        {TYPEEXPORT_TOKEN_DETAIL[text].label}
      </span>{" "}
    </div>
  );
};

const columns = [
  {
    title: "STT",
    key: "index",
    render: (text, record, index) => index + 1,
    className: "text-center",
    width: "10%"
  },
  {
    title: "Mã token",
    dataIndex: "code",
    key: "code",
    width: "30%"
  },
  {
    title: "Trạng thái",
    dataIndex: "status",
    render: renderTypeColumn,
    key: "status",
    width: "30%"
  },
  {
    title: "Ngày xuất kho/Ngày nhập kho",
    dataIndex: "updated_at",
    render: text => formatDateTime(text),
    key: "updated_at",
    width: "30%"
  }
];
export default class ModalViewTokenDetail extends Component<Props, State> {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Modal
          title={this.props.title}
          visible={this.props.visible}
          onOk={this.props.handleCancel}
          cancelButtonProps={{ hidden: true }}
          zIndex={0}
          destroyOnClose={true}
          okText="Đóng"
          className="modal-xl"
          width="auto"
          closable={false}
          style={{marginTop: '100px'}}
        >
          <p>
            Tổng số token trong kho{" "}
            <Tag color="blue">{this.props.data.total}</Tag> || Tổng số token đã
            xuất{" "}
            <Tag color="red">
              {this.props.data.total - this.props.data.rest}
            </Tag>{" "}
            || Tổng số token còn lại
            <Tag color="green">{this.props.data.rest}</Tag>
          </p>
          <Table
            className = {"ant-table-bordered"}
            dataSource={
              this.props.data.token_detail_list
                ? this.props.data.token_detail_list
                : []
            }
            columns={columns}
            rowKey="id"
          />
        </Modal>
      </div>
    );
  }
}
