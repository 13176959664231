import React, {Component} from "react";
import { match, RouteComponentProps } from 'react-router'
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import UpdateFormDetail from "./UpdateForm";
import {onFailAction} from "../../../common/swal/SwalCommon";
import CustomerService from "../../../../services/customer/CustomerService";
import { STATUS_CERT_LABEL } from "../Enum";
import {Card, Table, Tag} from "antd";
import BackButton from "../../../common/button/BackButton";
import PageWrapper from "../../wrapper/PageWrapper"
import {formatDateTime} from "../../../../helpers/NewCaHelper";

interface MatchParams {
    id: string;
    uid: string;
    created_by: string;
}

interface Props extends RouteComponentProps<MatchParams>{
    match: match<MatchParams>;
}
interface State {
    dataSource:any;
}

class UpdatePage extends Component<Props, State>{
    state = {
        dataSource: []
    };

    fetchDataFunction:any = async  () => {
        try{
            const service = new CustomerService();
            const result = await service.getCert(this.props.match.params.uid,this.props.match.params.created_by);
            this.setState({dataSource: result.data})
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    }
    componentDidMount() {
        this.fetchDataFunction();
    }
    renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_CERT_LABEL[text].class}`}>
                {STATUS_CERT_LABEL[text].label}
            </Tag>
        );
    };
    render() {
        return (
            <PageWrapper title="Xem chi tiết khách hàng cá nhân">
                <UpdateFormDetail history={this.props.history} id={this.props.match.params.id}></UpdateFormDetail>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin chứng thư số khách hàng</label>} size="small">
                    <Table
                        rowKey={(record, index) => {
                            return index.toString();
                        }}
                        columns={[
                            {
                                title: "Mã Token",
                                dataIndex: "",
                                key: "",
                                width: "auto",
                                render: e => {
                                    if (e.tokenmanager) return e.tokenmanager.code
                                    else return ""
                                }
                            },
                            {
                                title: "Subjet DN",
                                dataIndex: "",
                                key: "",
                                width: "auto",
                                render: e => {
                                    if (e.subjectDN) return e.subjectDN
                                    else return ""
                                }
                            },
                            {
                                title: "Serial CTS",
                                dataIndex: "",
                                key: "",
                                width: 300,
                                render: e => {
                                    if (e.certificateSerial) return e.certificateSerial
                                    else return ""
                                }
                            },
                            {
                                title: "Đối tượng",
                                dataIndex: "",
                                key: "",
                                width: 140,
                                render: e => {
                                    if (e) {
                                        if(e.object === 1){
                                            return (<Tag color="cyan">Cấp mới</Tag>);
                                        }
                                        if(e.object === 2){
                                            return (<Tag color="magenta">Gia hạn</Tag>);
                                        }
                                        if(e.object === 3){
                                            return (<Tag color="geekblue">Điều chỉnh thông tin</Tag>);
                                        }
                                    }
                                }
                            },
                            {
                                title: "Loại thiết bị đầu cuối",
                                dataIndex: "",
                                key: "",
                                width: 200,
                                render: e => {
                                    if (e) {
                                        if (e.type===1) return (<Tag color="geekblue">USB Token</Tag>);
                                        if (e.type===2) return (<Tag color="purple">HSM</Tag>);
                                    }
                                }
                            },
                            {
                                title: "Hiệu lực",
                                dataIndex: "",
                                key: "",
                                width: 140,
                                render: e => formatDateTime(e.certificateBegin)
                            },
                            {
                                title: "Ngày kết thúc",
                                dataIndex: "",
                                key: "",
                                width: 140,
                                render: e => formatDateTime(e.certificateEnd)
                            },
                            {
                                title: "Trạng thái",
                                dataIndex: "status",
                                key: "status",
                                width: 140,
                                render: this.renderStatusColumn
                            },
                            {
                                title: "Ngày gửi yêu cầu",
                                dataIndex: "",
                                key: "",
                                width: 140,
                                render: e => {
                                    if (e.time_action_cert) {
                                        formatDateTime(e.time_action_cert.request_time)
                                    }
                                }
                            },
                            {
                                title: "Ngày hoàn thành",
                                dataIndex: "",
                                key: "",
                                width: 140,
                                render: e => {
                                    if (e.time_action_cert) {
                                        formatDateTime(e.time_action_cert.completed_time)
                                    }
                                }
                            },
                        ]}
                        dataSource={
                            Object.keys(this.state.dataSource).length !== 0 ? this.state.dataSource : []
                        }
                        scroll={{ x: 1200 }}
                        pagination={false}
                        className="ant-table-bordered"
                    />
                </Card>
                <div className="input-group d-flex justify-content-center p-5">
                    <BackButton history={this.props.history}
                        // url='/danh-sach-khach-hang'
                    ></BackButton>
                </div>
            </PageWrapper>
        );
    }
}
const updateWithNavBar = withNavbar()(UpdatePage);

export default updateWithNavBar;