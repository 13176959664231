import { Drawer, Divider, Col, Row } from 'antd';
import React, { Component } from "react";
import { match } from "react-router";

interface Props {
    location: Location;
    history: History;
    match: match;
    user: any;
    visible_pf: boolean;
    onClose?: any;
}
interface State { }

const pStyle = {
    fontSize: 16,
    color: 'rgba(0,0,0,0.85)',
    lineHeight: '24px',
    display: 'block',
    marginBottom: 16,
};

const DescriptionItem = ({ title, content }) => (
    <div
        style={{
            fontSize: 14,
            lineHeight: '22px',
            marginBottom: 7,
            color: 'rgba(0,0,0,0.65)',
        }}
    >
        <p
            style={{
                marginRight: 8,
                display: 'inline-block',
                color: 'rgba(0,0,0,0.85)',
            }}
        >
            {title}:
    </p>
        {content}
    </div>
);

export default class DrawerInfoUser extends Component<Props, State> {
    state = { visible_pf: this.props.visible_pf };
    render() {
        return (
            <div>
                <Drawer
                    width={600}
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible_pf}
                >
                    <p style={{ ...pStyle, marginBottom: 24 }}>Thông tin tài khoản</p>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="Full name" content={this.props.user.fullname} />{' '}
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Username" content={this.props.user.username} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="City" content="" />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Country" content="VN" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="Birthday" content="" />
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Website" content="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <DescriptionItem
                                title="Message"
                                content="Make things as simple as possible but no simpler."
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <p style={pStyle}>Thông tin liên hệ</p>
                    <Row>
                        <Col span={12}>
                            <DescriptionItem title="Email" content={this.props.user.email}/>
                        </Col>
                        <Col span={12}>
                            <DescriptionItem title="Phone Number" content={this.props.user.phone} />
                        </Col>
                    </Row>
                </Drawer>
            </div>
        );
    }
}
