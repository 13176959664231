import React, { Component } from 'react'
import { Result } from 'antd';
import withNavbar from '../../common/HOC/NavbarContainerHOC'
import BackHome from '../../common/button/BackHome';
import { History } from "history";
interface Props {
    history: History;
  }
interface State {
    
}

class NotFoundError extends Component<Props, State> {
    state = {}

    render() {
        return (
            <Result
                status="error"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<BackHome history={this.props.history}></BackHome>}
            />
        )
    }
}

const errorWithNavBar = withNavbar()(NotFoundError);

export default errorWithNavBar;