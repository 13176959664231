import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import WhDocumentService from "../../../../services/WhDocument/WhDocumentService";
import {loading} from "../../../common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";
import AssetCategoryService from "../../../../services/asset-category/AssetCategoryService";
import SelectDate from "../../../common/form/SelectDate";
import InputFileUploadPreview from "../../../common/form/InputFileUploadPreview";
import ModalView from "../../../common/form/Modal";
import {Card} from "antd";
import ProviderCategoryService from "../../../../services/ProviderCategory/ProviderCategoryService";

interface FormValues {
    code: string;
    note: string;
    asset_id: string;
    total: string;
    receive_date: string;
    deliver_pos: string;
    deliver: string;
    receiver: string;
    receiver_pos: string;
    file: string;
    deliver_phone: string;
    receiver_phone: string;
    deliver_email: string;
    receiver_email: string;
    provider_id: string;
}

interface OtherProps extends RouteComponentProps<{
    id: string;
}> {
}

type Props = OtherProps & FormikProps<FormValues>;

const WhDocuemntUpdate: React.FC<Props> = props => {
    const [modal, setModal] = useState({
        visible_modal : false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });


    const handleModalCancel = () => {
        setModal({...modal,'visible_modal': false}) ;
    };
    const id = props.match.params.id;

    const onChangeSelectReceiveDate = (date, dateString) => {
        props.setFieldValue("receive_date", dateString);
    };

    const [providerList, setProviderList] = useState({});
    const [ProviderCateList, setProviderCateList] = useState({});

    const {
        code,
        asset_id,
        total,
        receive_date,
        deliver_pos,
        deliver,
        receiver,
        receiver_pos,
        file,
        note,
        deliver_email,
        deliver_phone,
        receiver_email,
        receiver_phone,
        provider_id} = props.values;


    const fetchWhAssetOption = async () => {
        const assetCate = new AssetCategoryService();
        const result = await assetCate.all(1);
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setProviderList(options);
    };

    const fetchProviderOption = async () => {
        const assetCate = new ProviderCategoryService();
        const result = await assetCate.all();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setProviderCateList(options);
    };

    const onClickModalFile = async () => {
        const service = new WhDocumentService();
        const result = await service.getBase64(id);
        if (result && result.status === 200) {
            setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'File nhập kho','type_file': 'pdf'}) ;
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra khi lấy file");
    };

    const onFetchData = async () => {
        const service = new WhDocumentService();
        const whDoc = await service.find(id);
        for (let key in whDoc.data) {
            props.setFieldValue(key, whDoc.data[key]);
        }
    };

    useEffect(() => {
        fetchWhAssetOption();
        onFetchData();
        fetchProviderOption();
        // eslint-disable-next-line
    }, []);

    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            if (fileData) {
                props.setFieldValue("file", fileData.file);
            }
            loading.stopRunLoading();
        } else {
            props.setFieldValue("file", "");
        }
    };

    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Cập nhật kho tài liệu bán hàng</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            label="Mã nhập kho"
                            wrapClass="col-md-2 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="asset_id"
                            options={providerList}
                            label="Loại tài liệu bán hàng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Chọn loại tài liệu bán hàng --"
                            onChange={props.handleChange}
                            value={asset_id}
                            error={props.errors.asset_id}
                            isRequired={true}
                        />
                        <SelectBox
                            name="provider_id"
                            options={ProviderCateList}
                            label="Loại tài liệu bán hàng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Chọn loại tài liệu bán hàng --"
                            onChange={props.handleChange}
                            value={provider_id}
                            error={props.errors.provider_id}
                            isRequired={true}
                        />
                        <InputWithLabel
                            name="total"
                            label="Số lượng"
                            wrapClass="col-md-1 form-group"
                            onChangeValue={props.handleChange}
                            value={total}
                            error={props.errors.total}
                            isRequired={true}
                            type="number"
                        />
                        <SelectDate
                            label="Ngày nhập kho"
                            name="receive_date"
                            wrappedClass="col-md-3 form-group"
                            onChange={onChangeSelectReceiveDate}
                            value={receive_date}
                            error={props.errors.receive_date}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputFileUploadPreview
                            name="file"
                            classWrapped="col-md-3 viewInputHide"
                            label="File bàn giao"
                            filename={file}
                            onChanageFileUpload={onChangeFile}
                            isRequired={true}
                            accept={"application/pdf"}
                            onClickDownloadFile = {file}
                            funcntionView = {onClickModalFile}
                            error={props.errors.file}
                        />
                        <InputWithLabel
                            name="note"
                            label="Ghi chú"
                            wrapClass="col-md-9 form-group"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                        />
                        <ModalView
                            visible={modal.visible_modal}
                            handleCancel={handleModalCancel}
                            title={modal.title_modal}
                            value={modal.base64}
                            type_file={modal.type_file}
                            loading={modal.loading}
                        />
                    </div>
                    <Card className="m-r-15-i m-l-15-i" title="Thông tin người bàn giao">
                        <div className="input-group">
                            <InputWithLabel
                                name="deliver"
                                label="Người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver}
                                error={props.errors.deliver}
                                isRequired={true}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="deliver_pos"
                                label="Chức vụ người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_pos}
                                error={props.errors.deliver_pos}
                                isRequired={false}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="deliver_phone"
                                label="Số điện thoại người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_phone}
                                error={props.errors.deliver_phone}
                                isRequired={false}
                                maxLength={20}
                            />
                            <InputWithLabel
                                name="deliver_email"
                                label="Email người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_email}
                                error={props.errors.deliver_email}
                                isRequired={false}
                                maxLength={255}
                            />
                        </div>
                    </Card>
                    <Card className="m-r-15-i m-l-15-i mt-3" title="Thông tin người nhận">
                        <div className="input-group m-t-10">
                            <InputWithLabel
                                name="receiver"
                                label="Người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver}
                                error={props.errors.receiver}
                                isRequired={true}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="receiver_pos"
                                label="Chức vụ người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_pos}
                                error={props.errors.receiver_pos}
                                isRequired={false}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="receiver_phone"
                                label="Số điện thoại người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_phone}
                                error={props.errors.receiver_phone}
                                isRequired={false}
                                maxLength={20}
                            />
                            <InputWithLabel
                                name="receiver_email"
                                label="Email người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_email}
                                error={props.errors.receiver_email}
                                isRequired={false}
                                maxLength={255}
                            />
                        </div>
                    </Card>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            url="/kho-tai-lieu-ban-hang"
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        note: "",
        asset_id: "",
        total: "",
        receive_date: "",
        deliver: "",
        deliver_pos: "",
        receiver: "",
        receiver_pos: "",
        file: "",
        deliver_phone: "",
        receiver_phone: "",
        deliver_email: "",
        receiver_email: "",
        provider_id: "",
    }),
    validationSchema: Yup.object().shape({
        code: Yup.string().required("Mã nhập kho không được bỏ trống."),
        asset_id: Yup.string().required("Loại tài liệu bán hàng không được bỏ trống."),
        total: Yup.string().required("Số lượng không được bỏ trống."),
        receive_date: Yup.string().required("Ngày nhập kho không được bỏ trống."),
        deliver: Yup.string().required("Người giao không được bỏ trống."),
        receiver: Yup.string().required("Người nhận không được bỏ trống."),
        provider_id: Yup.string().required("Nhà cung cấp không được bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new WhDocumentService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result && result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result && result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction('Có lỗi trong quá trình cập nhật');
        }
    }
})(WhDocuemntUpdate);

export default withNavbar()(RenderCreateForm);
