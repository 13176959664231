import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON, reloadPage} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onDeleteAlert, onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SignatureService from "../../../services/signature/SignatureService";
import axios from "axios";
interface Props extends RouteComponentProps {
}

const TITLE = "Thêm chứng thư số";

const Signature: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({chungthu_serial: ""});
    const [listTaxCode, setListTaxCode] = useState("");
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const service = new SignatureService();

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            return await service.list({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const fetchListTaxCode = async () => {
        const listTaxCode = await service.getListTaxCode();
        try {
            return setListTaxCode(listTaxCode.data)
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    useEffect(() => {
        fetchListTaxCode();
        // eslint-disable-next-line
    }, []);

    const onClickDeleteButton = id => {
        onDeleteAlert(async willDelete => {
            if (willDelete) {
                const service = new SignatureService();
                const result = await service.delete(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa bản ghi tài sản thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
            }
        });
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickDelete={() => onClickDeleteButton(record.id)}
            />
        );
    };

    const onClickAddSignature = () => {
        axios.get(`http://localhost:6703/api/cybercrm/getcert?mst=${listTaxCode}`)
        .then((response) => {
            if(response){
                const data = response.data;
                data.mst = listTaxCode;
                senDataSignatureToStore(response.data);
            }
        })
        .catch((error) => {
            onFailAction('Kết nối Usb Token thất bại');
        })
    };

    const senDataSignatureToStore = async (data) => {
        const result = await service.sendDataSignature(data);
        if (result.status === 200) {
            onSuccessAction("Thêm mới thành công!", function () {
                props.history.goBack();
            });
        } else {
            onFailAction(result.error);
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="chungthu_serial"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.chungthu_serial}
                            isRequired={false}
                            label={""}
                            placeholder={"Chứng thư serial"}
                        />
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <button className="btn btn-success mr-1 ml-1 font-btn" onClick={onClickAddSignature}><i className="fas fa-plus mr-1"></i>Thêm</button>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Serial", dataIndex: "chungthu_serial"},
                        {title: "Sở hữu", dataIndex: "chungthu_sohuu"},
                        {title: "Đơn vị cung cấp", dataIndex: "chungthu_ncc"},
                        {title: "Mã số thuế", dataIndex: "doanhnghiep_mst"},
                        {
                            title: "Ngày bắt đầu",
                            dataIndex: "chungthu_batdau",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Ngày hết hiệu lực",
                            dataIndex: "chungthu_ketthuc",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },

                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(Signature);
