import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import { onFailAction } from "../../../../helpers/SwalCommon";
import { TYPE_LABEL_CLASS } from "../Enum";
import { formatDateTime } from "../../../../helpers/NewCaHelper";
import DistrictService from "../../../../services/district/DistrictService";
import { onDeleteAlert, onSuccessAction } from "../../../common/swal/SwalCommon";
import {
    reloadPage
} from "../../../../helpers/NewCaHelper";
import TableActionButton from "./action-button/TableActionButton";

interface Props {
    location: Location;
    history: History;
    match: match;
}
interface State { }

export default class AgencyTable extends Component<Props, State> {
    state = {};
    service = new DistrictService();
    componentDidMount() {
        this.fetch();
    }

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        try {
            const result = await this.service.getDistricts(params);
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    onClickDeleteButton = (id) => {
        const self = this;
        onDeleteAlert(async function (willDelete) {
            if (willDelete) {
                const result = await self.service.deleteDistrictById(id);
                if (result && result.status === 200) {
                    onSuccessAction('Xóa quận huyện thành công!', reloadPage(self.props.location, self.props.history));
                }
            }
        });
    }

    render() {
        return (
            <Table
                columns={this.columns}
                onFetchData={this.fetch}
            />
        );
    }

    onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={() =>
                    this.props.history.push(`/quan-huyen/cap-nhat/${record.id}`)
                }
                isDisplayUpdateButton={true}
            />
        );
    };

    formatValid = (text) => {
        return (
            <span key={text} className={`badge ${TYPE_LABEL_CLASS[text].class}`}>
              {TYPE_LABEL_CLASS[text].label}
            </span>
        );
    };

    columns = [
        { title: "Tên quận huyện", dataIndex: "fullname" },
        { title: "Mã quận huyện", dataIndex: "code" },
        {
            title: "Ngày bắt đầu",
            dataIndex: "start_date",
            render: (text) => formatDateTime(text)
        },
        {
            title: "Ngày kết thúc",
            dataIndex: "end_date",
            render: (text) => formatDateTime(text)
        },
        { title: "Tỉnh thành", dataIndex: "province" },
        {
            title: "Thời gian tạo",
            dataIndex: "created_at",
            render: (text) => formatDateTime(text)
        },
        {
            title: "Hiệu lực",
            dataIndex: "isvalid",
            render: (text) => this.formatValid(text)
        },
        {
            title: "Tác vụ",
            render: this.onRenderActionColumn
        }
    ];
}
