import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ROUTERS_CONSTANT } from "./RoutersList";
import NotFoundError from "../pages/errors/NotFoundError";
import { PrivateRoute } from "./PrivateRoute";
import Login from "../pages/login/Login";
import { authenticationService } from "../../services/AuthenticationService";

class Routers extends React.Component<any, any> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          {ROUTERS_CONSTANT.map((route, index) => (
            <PrivateRoute
              key={index}
              exact
              path={route.link}
              component={route.component}
            />
          ))}
          <Switch>
            <Route
              exact
              path="/login"
              render={props => {
                return <Login {...props} />;
              }}
            />
            <Route
              exact
              render={props => {
                const currentUser = authenticationService.currentUserValue;
                if (!currentUser) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location }
                      }}
                    />
                  );
                }
                return <NotFoundError {...props} />;
              }}
            />
          </Switch>
        </Switch>
      </React.Fragment>
    );
  }
}

export default Routers;
