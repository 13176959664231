import React from 'react';
import {Table, Tag} from "antd";
import {CERT_OBJECT, CERT_DEVICE} from "./CertStatusEnum";
import TableActionButton from "../user/table/action-button/TableActionButton";
import RequestChangeInfoCertificateService
    from "../../../services/request-change-info-certificate/RequestChangeInfoCertificateService";
import {onDenyChange, onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";

export const TableInfoCert = (props) => {
    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickDenyChangeInfo={()=>denyChange(record.id, record.request_change_info_id)}
                isDenyChangeInfo={checkAction(record)}
            />
        );
    };
    const denyChange = (id, requestID) => {
        try {
            onDenyChange(async willDeny => {
                if (willDeny) {
                    const service = new RequestChangeInfoCertificateService();
                    const result = await service.denyChange(id, requestID);
                    if (result && result.status === 200) {
                        onSuccessAction(
                            "Từ chối thành công",
                            window.location.reload()
                        );
                    }else{
                        onFailAction("Có lỗi xay ra.");
                    }
                }
            });
        }catch (e) {
            onFailAction(e);
        }
    };
    const checkAction =  (record: any): any => {
        if (Number(record.status) === 1){
            return true;
        }else {
            return false;
        }
    };
    const columns = [
        {
            title: "STT",
            key: "key",
            render: (text, record, index) => index + 1,
            className: "text-center",
            width: "10%",
        },
        {
            title: "SubjectDN",
            dataIndex: "",
            key: "subjectDN",
            render: (e) => {return (e.cert.subjectDN)}
        },
        {
            title: "serial CTS",
            dataIndex: "",
            className: "text-center",
            key: "certificateSerial",
            render: (e) => {return (e.cert.certificateSerial)}
        },
        {
            title: "Đối tượng",
            dataIndex: "",
            key: "object",
            className: "text-center",
            render: (e) => {return (<Tag color={CERT_OBJECT[e.cert.object]['color']}>{CERT_OBJECT[e.cert.object]['text']}</Tag>)},
        },
        {
            title: "Loại thiết bị",
            dataIndex: "",
            key: "type",
            className: "text-center",
            render: (e) => {return (<Tag color={CERT_DEVICE[e.cert.type]['color']}>{CERT_DEVICE[e.cert.type]['text']}</Tag>)},
        },
        {
            title: "Mã bảo mật",
            dataIndex: "secret_code",
            key: "price",
            className: "text-center",
        },
        {
            title: "Trạng thái",
            dataIndex: "",
            className: "text-center",
            render: (e) => {
                if (Number(e.status) === 1){
                    return (<Tag color="orange">Chờ gen cert</Tag>)
                }if (Number(e.status) === 2){
                    return (<Tag color="green">Đã gen cert</Tag>)
                }else {
                    return (<Tag color="red">Từ chối điều chỉnh</Tag>)
                }
            },
        },
        {
            title: "Tác vụ",
            render: onRenderActionColumn
        },
    ];
    return (
        <Table
            className={"w-100 m-t-20 ant-table-bordered"}
            columns={columns}
            dataSource={props.dataCert ? props.dataCert : []}
            pagination={false}
            rowKey="id"
        />
    );
};