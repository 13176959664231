import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import {
  convertJsonToQueryString,
  formatDateTimeAll,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { RouteComponentProps } from "react-router-dom";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import { onFailAction, onSuccessAction } from "../../../helpers/SwalCommon";
import TableActionButton from "./TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import RequestLockAccount from "../../../services/request-lock-account/RequestLockAccount";
import SelectBox from "../../common/form/SelectBox";
import { OWNER_LABEL_CLASS, STATUS_LABEL_CLASS, OPTION } from "./Enum";
import UserService from "../../../services/user/UserService";
import SelectBoxAgency from "./../../common/form/SelectBoxAgency";
import SelectUserManager from "./../../common/form/SelectUserManager";
import { reloadPage } from "./../../../helpers/NewCaHelper";
import { Tag } from "antd";

interface Props extends RouteComponentProps {}

const TITLE = "Danh sách yêu cầu khóa tài khoản";

const WhAsset: React.FC<Props> = props => {
  const [agency, setAgency] = useState({});
  const [userManager, setUserManager] = useState({});
  const [dataSearch, setDataSearch] = useState({
    belong_to: "",
    owner_id: "",
    status: "",
    user_id: ""
  });

  const onSubmitSearchButton = e => {
    e.preventDefault();
    
      const search = queryStringToJSON(props.location.search);
      let raw = {};
      for (let key in search){
          if (key === 'raw'){
              raw['raw'] = search[key];
          }
      }
      const queryString = convertJsonToQueryString({...dataSearch, ...raw});
    props.history.push({
      search: queryString
    });
  };

  const fetchDataFunction = async () => {
    const service = new RequestLockAccount();
    const queryString = queryStringToJSON(props.location.search);
    try {
      let result = await service.list({ ...dataSearch, ...queryString });
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  const onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickConfirmButton={() => onConfirmDestroyRequest(record.id)}
        mesDelete="Bạn có chắc chắn muốn khóa tài khoản của đại lý/ cộng tác viên này!"
        permissionConfirm={checkUpdateAble(record)}
        icon = "fa fa-lock"
        tittle= "Khóa"
      />
    );
  };

  const onConfirmDestroyRequest = async id => {
    const { location, history } = props;
    try {
      const service = new RequestLockAccount();
      await service.confirm(id);
      reloadPage(location, history);
      onSuccessAction("Khóa tài khoản thành công!");
    } catch (error) {
      onFailAction("Có lỗi xảy ra trong quá trình khóa!");
    }
  };

  const checkUpdateAble = (record: any): any => {
    if ([1].indexOf(record.status) !== -1) return true;
    return false;
  };

  useEffect(() => {
    getUserCreated('5,6');
  }, []);

  const renderTypeOwner = (text, record, index) => {
    return (
      <Tag color={`${OWNER_LABEL_CLASS[text].class}`}>
        {OWNER_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  const renderStatusColumn = (text, record, index) => {
    return (
      <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
        {STATUS_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  const getUserCreated = async e => {
    const service = new UserService();
    const result = await service.getUserByType(e);
    if (result && result.status === 200) {
      setUserManager(result.data);
    }
  };

  const onChangeSelectedRole = (e) => {
    setDataSearch({ ...dataSearch, "belong_to": e});
    let typeGet = 7;
    if (e === "2") {
      typeGet = 8;
    }
    getAgency(typeGet);
  };
  const onChangeDataSearchStatus = (e) => {
    setDataSearch({ ...dataSearch, "status": e});
  }
  const onChangeDataSearchUserId = (e) => {
    setDataSearch({ ...dataSearch, "user_id": e});
  }

  //Lấy dữ liệu cộng tác viên hoặc đại lý
  const getAgency = async type => {
    const userService = new UserService();
    const lst = await userService.getAgencyContributor(type);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      setAgency(agency);
    }
  };
  const handleChangeBoxAgency = e => {
    setDataSearch({ ...dataSearch, owner_id: e });
  };

  return (
    <div className="card">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <h4 className="card-title1">{TITLE}</h4>
      <div className="card-body">
        <form onSubmit={onSubmitSearchButton} className="search-form-index">
          <div className="input-group">
            <SelectBox
              options={OPTION.USERTYPE}
              label=""
              name="belong_to"
              wrappedClass="col-md-2 nopadding-left"
              onChange={onChangeSelectedRole}
              value={dataSearch.belong_to}
              firstOptionLabel="Chọn loại người dùng"
              isRequired={true}
            />
            <SelectBoxAgency
              options={agency}
              label=""
              name="owner_id"
              wrappedClass="col-md-3 form-group nopadding-left"
              onChange={handleChangeBoxAgency}
              value={dataSearch.owner_id}
              firstOptionLabel="Tất cả đại lý"
              isRequired={false}
            />
            <SelectBox
              value={dataSearch.status}
              label=""
              onChange={onChangeDataSearchStatus}
              name="status"
              wrappedClass="col-md-2 form-group nopadding-left"
              options={OPTION.STATUS}
              firstOptionLabel="Tất cả trạng thái"
            />
            <SelectUserManager
              label=""
              onChange={onChangeDataSearchUserId}
              value={dataSearch.user_id}
              name="user_id"
              wrappedClass="col-md-2 form-group nopadding-left"
              options={userManager}
              firstOptionLabel="Người yêu cầu"
            />
            <div className="col-md-3 form-group">
              <SearchButton onClick={onSubmitSearchButton} />
            </div>
          </div>
        </form>
        <NewTable
          columns={[
            {
              title: "Loại người dùng",
              dataIndex: "belong_to",
              render: renderTypeOwner
            },
            {
              title: "Mã số thuế/Chứng minh thư",
              dataIndex: "",
              render: e => {
                if (e) {
                  if (e.belong_to === 1) {
                    return <a href="/#">{e?.owner?.tax_code}</a>;
                  } else {
                    return <a href="/#">{e?.owner?.passport}</a>;
                  }
                }
              }
            },

            { title: "Tên đại lý/cộng tác viên", dataIndex: "owner.fullname" },
            { title: "Lý do khóa tài khoản", dataIndex: "reason" },
            {
              title: "Trạng thái",
              dataIndex: "status",
              render: renderStatusColumn
            },
            { title: "Người yêu cầu", dataIndex: "user.fullname" },
            {
              title: "Ngày yêu cầu",
              dataIndex: "created_at",
              render: text => formatDateTimeAll(text)
            },

            {
              title: "Tác vụ",
              render: onRenderActionColumn
            }
          ]}
          onFetchData={fetchDataFunction}
        />
      </div>
    </div>
  );
};

export default withNavbar()(WhAsset);
