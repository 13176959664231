import React, {useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import ModalView from "../../../common/form/Modal";
import CateContractService from "../../../../services/category-contract/CateContractService";
interface FormValues {
    code: string;
    name: string;
    type: string;
    type_agency: string;
    params: string;
    content: string;
    note: string;
}


interface OtherProps extends RouteComponentProps {
}

type Props = OtherProps & FormikProps<FormValues>;

const ContractCategoryCreate: React.FC<Props> = props => {
    const handleModalCancel = () => {
        setModal({...modal,'visible_modal': false}) ;
    };
    const [dataEditor, setdataEditor] = useState({});
    const onClickModalFile = async () => {
        const service = new CateContractService();
        const data = {dataEditor, code};
        const result = await service.getBase64(data);
        if (result && result.status === 200) {
            setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'Xem trước','type_file': 'pdf'});
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra khi lấy file");
    };

    const [modal, setModal] = useState({
        visible_modal : false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const {code, name, type, note, type_agency, params} = props.values;

    const changeType = (e) => {
        props.setFieldValue("type",e)
    }
    const changeTypeAgency = (e) => {
        props.setFieldValue("type_agency",e)
    }
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <h4 className="card-title1 ml-3">Thêm mới mẫu tài liệu</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="name"
                            label="Tên tài liệu"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={name}
                            error={props.errors.name}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="code"
                            label="Mã tài liệu"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                        />
                        <SelectBox
                            name="type"
                            options={{
                                1: "Hợp đồng đại lý",
                                2: "Hợp đồng dịch vụ",
                                3: "Khác"
                            }}
                            label="Loại tài liệu"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn loại tài liệu"
                            onChange={changeType}
                            value={type}
                            error={props.errors.type}
                            isRequired={true}
                        />
                        <SelectBox
                            name="type_agency"
                            options={{
                                1: "Đại lý",
                                2: "Cộng tác viên"
                            }}
                            label="Loại người dùng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn loại người dùng"
                            onChange={changeTypeAgency}
                            value={type_agency}
                            error={props.errors.type_agency}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="params"
                            label="Params"
                            wrapClass="col-md form-group"
                            onChangeValue={props.handleChange}
                            value={params}
                            error={props.errors.params}
                            maxLength={500}
                        />
                    </div>
                    <div className="col-md-12">
                        <CKEditor
                            name ="content"
                            onChange={ ( event) => {
                                const data = event.editor.getData();
                                setdataEditor(data);
                                props.setFieldValue('content',data);
                            } }
                            config={ {
                                extraPlugins: 'lineheight,tableresize',
                            } }
                        />
                    </div>
                    <div className="col-md-12">
                        <a href="#/" onClick = {onClickModalFile}>xem trước</a>
                    </div>
                    <div className="input-group mt-4">
                        <InputWithLabel
                            name="note"
                            label="Ghi chú"
                            wrapClass="col-md form-group"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                            maxLength={500}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            url="/danh-muc-hop-dong"
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        name: "",
        note: "",
        type: "",
        type_agency: "",
        content:"",
        params:"",
    }),
    validationSchema: Yup.object().shape({
        code: Yup.string().required("Mã không thể bỏ trống."),
        name: Yup.string().required("Tên không thể bỏ trống."),
        type: Yup.string().required("Loại tài liệu không thể bỏ trống."),
        type_agency: Yup.string().required("Loại đại lý không thể bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.store(values);
        if (result.status === 200) {
            onSuccessAction("Thêm mới thành công!", function () {
                props.history.push(`/danh-muc-hop-dong`)
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(ContractCategoryCreate);

export default withNavbar()(RenderCreateForm);
