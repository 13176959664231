import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString, formatDateTime, queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import RequestTrainingServices from "../../../services/request-training/RequestTrainingService";
import TableActionButton from "../user/table/action-button/TableActionButton";
import SelectDate from "../../common/form/SelectDate";
import {onFailAction} from "../../../helpers/SwalCommon";
import SelectBox from "../../common/form/SelectBox";
import {checkPermission} from "../../../helpers/NewCaHelper"
import { Tag } from "antd";

interface Props extends RouteComponentProps{

}
const per_view_detail_request_training = 'view-detail-request-training-ad';
const TITLE = "Danh sách yêu cầu đào tạo";

const RequestTraining: React.FC<Props> = props => {
    const service = new RequestTrainingServices();
    const onChangeSelectDateTraining = (date, dateString) => {
        setDataSearch({...dataSearch, training_date: dateString});
    };
    const onChangeSelectDateCreatedAt = (date, dateString)=> {
        setDataSearch({...dataSearch, createdAt: dateString});
    }
    const [dataSearch, setDataSearch] = useState({
        id: "",tax_code:"",training_date: "",createdAt: "", status: "", belong_to: ""});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    }
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const onChangeDataSearchId = (e) => {
        setDataSearch({...dataSearch, 'id': e});
    };
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, 'status': e});
    };
    const onChangeDataSearchBelongTo = async (e) => {
        if (Number(e)===1 || Number(e)===2){
            await getListFullName(e);
        }
        setDataSearch({...dataSearch, 'belong_to': e, 'id':''})
    }

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            console.log(result);
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    }
    const [listFullName, setListFullName] = useState({});
    useEffect(() => {
        // getListFullName();
        // eslint-disable-next-line
    }, []);
    const getListFullName = async (e) =>{
        const result = await service.getFullName(e)
        const options = {};
        result.data.forEach(element => {
            if (element.belong_to===1 )
                options[element.id] = element.owner_agency.fullname;
            if (element.belong_to===2 )
                options[element.id] = element.owner_contributor.fullname;
        });
        setListFullName(options)
        setDataSearch({...dataSearch, 'id': ''});
    }
    const onRenderActionColumn = (text, record, index) => {
        if(checkPermission(per_view_detail_request_training))
            return (
                <TableActionButton
                    onClickView={() =>
                        props.history.push(`/danh-sach-yeu-cau-dao-tao/xem-chi-tiet/${record.id}`)
                    }
                />
            );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                        <div className="row">
                            <SelectBox
                                options={{1: "Đại lý", 2: "Cộng tác viên"}}
                                label={""}
                                name={"belong_to"}
                                wrappedClass={"col-md-2 form-group"}
                                firstOptionLabel="Loại người dùng"
                                onChange={onChangeDataSearchBelongTo}
                                value={dataSearch.belong_to}
                            />
                            <SelectBox
                                options={listFullName}
                                label={""}
                                name={"id"}
                                wrappedClass={"col-md-2 form-group "}
                                firstOptionLabel="Người yêu cầu"
                                onChange={onChangeDataSearchId}
                                value={dataSearch.id}
                            />
                            <InputWithLabel
                                name="tax_code"
                                wrapClass="col-md-2 form-group "
                                onChangeValue={onChangeDataSearchInput}
                                value={dataSearch.tax_code}
                                isRequired={false}
                                label={""}
                                placeholder="Mã số thuế hoặc CMND"
                            />
                        </div>
                        <div className="row mt-2">
                            <SelectDate
                                label=""
                                name="training_date"
                                wrappedClass="col-md-2 form-group"
                                onChange={onChangeSelectDateTraining}
                                value={dataSearch.training_date}
                                isRequired={true}
                                placeholder="Ngày đào tạo"
                            />
                            <SelectDate
                                label=""
                                name="createdAt"
                                wrappedClass="col-md-2 form-group"
                                onChange={onChangeSelectDateCreatedAt}
                                value={dataSearch.createdAt}
                                isRequired={true}
                                placeholder="Ngày tạo"
                            />
                            <SelectBox
                                options={{4: "Chờ giám đốc duyệt",5:"Giám đốc từ chối",6:"Hoàn thành" }}
                                label={""}
                                name={"status"}
                                wrappedClass={"col-md-2 form-group "}
                                firstOptionLabel="Trạng thái"
                                onChange={onChangeDataSearchStatus}
                                value={dataSearch.status}
                            />

                            <div className="col-md-3 form-group">
                                <SearchButton onClick={onSubmitSearchButton}/>
                            </div>
                        </div>  
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Tên khách hàng",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.belong_to===1) return e.owner_agency.fullname;
                                    if (e.belong_to===2) return e.owner_contributor.fullname;
                                }
                            }
                        },
                        {
                            title: "MST/Số CMND",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.belong_to===1) return e.owner_agency.tax_code;
                                    if (e.belong_to===2) return e.owner_contributor.passport;
                                }
                            }
                        },
                        {
                            title: "Nội dung đào tạo",
                            dataIndex: "content_train"
                        },
                        {
                            title: "Thời gian đào tạo",
                            dataIndex: "",
                            render: (e) => formatDateTime(e.training_date)
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "",
                            render: (e) => formatDateTime(e.created_at)
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: (e) => {
                                if (e.status === 1) {
                                    return (<Tag color="">Nháp</Tag>);
                                }
                                if (e.status === 2) {
                                    return (<Tag color="cyan">Chờ duyệt hỗ trợ kinh doanh duyệt</Tag>);
                                }
                                if (e.status === 3) {
                                    return (<Tag color="volcano">Hỗ trợ kinh doanh từ chối</Tag>);
                                }
                                if (e.status === 4) {
                                    return (<Tag color="geekblue">Chờ giám đốc duyệt</Tag>);
                                }
                                if (e.status === 5) {
                                    return (<Tag color="red">Giám đốc từ chối</Tag>);
                                }
                                if (e.status === 6){
                                    return (<Tag color="green">Hoàn thành</Tag>);
                                }
                            }
                        },
                        {
                          title: "Tác vụ",
                          render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
}
export default withNavbar()(RequestTraining);
