import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class DocumentService extends FetchAPI{

    public async getDocuments(data: object) {
        const path = "/cate/document"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteDocumentById(id){
        const result =  await this.request("cate/document-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getDocument(id){
        const result = await this.request("cate/document-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async downloadDocument(id){
        const result = await this.request("cate/document-download",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createDocument(data){
        const result = await this.request("cate/document",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateDocument(data){
        const result = await this.request("cate/document-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
}

export default DocumentService;