import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";
const PREFIX_URL = "admin/yeu-cau-cap-bu";

class CompensationRequestService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "admin/yeu-cau-cap-bu",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async find(id) {
        const result = await this.request(
            `${PREFIX_URL}/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async approve(id) {
        const result = await this.request(
            `${PREFIX_URL}/approve/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async deny(data) {
        const result = await this.request(
            `${PREFIX_URL}/deny`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getListManagerUser() {
        const result = await this.request(
            `${PREFIX_URL}/getListManagerUser`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getListCert(id) {
        const result = await this.request(
            `${PREFIX_URL}/getListCert/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getApproveUser(id) {
      
        const result = await this.request(
            `${PREFIX_URL}/getApproveUser/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getAgencyData(uid) {
      
        const result = await this.request(
            `${PREFIX_URL}/getAgencyData/${uid}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async resendMailSuccess(id,email) {
        const result = await this.request(
            `${PREFIX_URL}/resendMailSuccess`,
            { id,email },
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async resendMailDeny(id,email) {
        const result = await this.request(
            `${PREFIX_URL}/resendMailDeny`,
            { id,email },
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async sendMailReSign(data) {
        const result = await this.request(
            `${PREFIX_URL}/sendMailReSign`,
            data ,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
}

export default CompensationRequestService;
