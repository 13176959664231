import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class WhAssetService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "admin/quan-ly-token/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async find(id) {
        const result = await this.request(
            `admin/quan-ly-token/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async store(data) {
        const result = await this.request(
            "admin/quan-ly-token/store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async update(data) {
        const result = await this.request(
            "admin/quan-ly-token/update",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async delete(id) {
        const result = await this.request(
            "admin/quan-ly-token/delete",
            {id},
            TypeMethodContsantEnum.DELETE
        );
        return result;
    }
    
    public async lock(code) {
        const result = await this.request(
            "admin/quan-ly-token/lock",
            {code},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async unlock(code) {
        const result = await this.request(
            "admin/quan-ly-token/unlock",
            {code},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async delCertificate(code) {
        const result = await this.request(
            "admin/quan-ly-token/delCertificate",
            {code},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async approveUpdate(data) {
        const result = await this.request(
            "admin/quan-ly-token/approveUpdate",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async getVersionToken() {
        const result = await this.request(
            `admin/quan-ly-token/getVersionToken`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async getOwner(belong_to) {
        const result = await this.request(
            `admin/quan-ly-token/getOwner?belong_to=${belong_to}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async import(data) {
        const result = await this.request(
            `admin/quan-ly-token/import`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
}

export default WhAssetService;
