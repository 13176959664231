import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { convertJsonToQueryString, queryStringToJSON } from "../../../helpers/NewCaHelper";
import { RouteComponentProps } from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import { onFailAction } from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import CompensationRequestService from "../../../services/compensation/CompensationRequestService";
import { Tag } from "antd";
import SelectBox from "../../common/form/SelectBox";
import SelectDate from "../../common/form/SelectDate";

interface Props extends RouteComponentProps {
}

const TITLE = "Yêu cầu cấp bù";

const CompensationRequest: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({ uid: "", status: "", created_at_from: "", created_at_to: "", approve_by: "", step: "" });
    const service = new CompensationRequestService();
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search) {
            if (key === 'raw') {
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({ ...dataSearch, ...raw });
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({ ...dataSearch, "status": e });
    };
    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            console.log(await service.list({ ...dataSearch, ...queryString }));
            return await service.list({ ...dataSearch, ...queryString });
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at_from: dateString });
    };
    const onChangeSelectDateToValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at_to: dateString });
    };

    const [listManagerId, setListManagerId] = useState({});
    useEffect(() => {
        getListManagerUser();
        //getServicePackage();
        // eslint-disable-next-line
    }, []);
    const getListManagerUser = async () => {
        const result = await service.getListManagerUser();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setListManagerId(options);

    };
    const onChangeDataSearchApproveBy = (e) => {
        setDataSearch({ ...dataSearch, 'approve_by': e })
    };
    const onChangeDataSearchStep = (e) => {
        setDataSearch({ ...dataSearch, step: e })
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={() => props.history.push(`/yeu-cau-cap-bu/chi-tiet/${record.id}`)}
            />
        );
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="uid"
                            wrapClass="col-md-2 form-group pl-0"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.uid}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã định danh"}
                        />
                        <SelectBox
                            name="status"
                            options={{
                                1: "Đã duyệt",
                                2: "Chờ duyệt",
                                3: "Hoàn thành",
                                4: "Hủy",
                            }}
                            label=""
                            wrappedClass="col-md-2 pl-0"
                            firstOptionLabel="Trạng thái"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.status}
                        />
                        <SelectDate
                            label=""
                            name="created_at_from"
                            wrappedClass="col-md-2 form-group pl-0"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at_from}
                            isRequired={true}
                            placeholder="Ngày tạo - Từ ngày"
                        />
                        <SelectDate
                            label=""
                            name="created_at_to"
                            wrappedClass="col-md-2 form-group pl-0"
                            onChange={onChangeSelectDateToValid}
                            value={dataSearch.created_at_to}
                            isRequired={true}
                            placeholder="Ngày tạo - Đến ngày"
                        />
                        <SelectBox
                            name="approved_by"
                            options={listManagerId}
                            label=""
                            wrappedClass="col-md-2 pl-0"
                            firstOptionLabel="Người duyệt"
                            onChange={onChangeDataSearchApproveBy}
                            value={dataSearch.approve_by}
                        />
                        <SelectBox
                            name="step"
                            options={{
                                1: "Đã tạo yêu cầu",
                                2: "Đã ký DK01",
                                3: "Cài đặt bước 1",
                                4: "Cài đặt bước 2 (Gen CRS)",
                                5: "Cài đặt bước 2 (Cài đặt Token)",
                                6: "Hoàn thành",
                            }}
                            label=""
                            wrappedClass="col-md-2 pl-0"
                            firstOptionLabel="Bước thực hiện"
                            onChange={onChangeDataSearchStep}
                            value={dataSearch.step}
                        />
                        <div className="col-md-2 form-group pl-0">
                            <SearchButton onClick={onSubmitSearchButton} />
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        { title: "Định danh", dataIndex: "uid" },
                        { title: "Tên khách hàng", dataIndex: "owner" },
                        { title: "Ngày yêu cầu", dataIndex: "created_at" },
                        {
                            title: "Người duyệt",
                            dataIndex: "",
                            render: (e) => {
                                if (e.status === 1 || e.status === 3) {
                                    if (e.user_approve){
                                        return e.user_approve.fullname;
                                    }else {
                                        return "Auto";
                                    }
                                }
                            }
                         },
                        { title: "Ngày duyệt", dataIndex: "approve_at" },
                        {
                            title: "Trạng thái", dataIndex: "",
                            render: (e) => {
                                if (e.status === 1) {
                                    return (<Tag color="cyan">Đã duyệt</Tag>);
                                }
                                if (e.status === 2) {
                                    return (<Tag color="volcano">Chờ duyệt</Tag>);
                                }
                                if (e.status === 3) {
                                    return (<Tag color="green">Hoàn thành</Tag>);
                                }
                                if (e.status === 4) {
                                    return (<Tag color="magenta">Hủy</Tag>);
                                }
                            }
                        },
                        {
                            title: "Bước thực hiện", dataIndex: "",
                            render: (e) => {
                                if (e.step === 1) {
                                    return (<Tag color="cyan">Đã tạo yêu cầu</Tag>);
                                }
                                if (e.step === 2) {
                                    return (<Tag color="volcano">Đã ký DK01</Tag>);
                                }
                                if (e.step === 3) {
                                    return (<Tag color="red">Cài đặt bước 1</Tag>);
                                }
                                if (e.step === 4 ) {
                                    return (<Tag color="magenta">Cài đặt bước 2 (Gen CRS)</Tag>);
                                }
                                if (e.step === 5 ) {
                                    return (<Tag color="purple">Cài đặt bước 2 (Cài đặt Token)</Tag>);
                                }
                                if (e.step === 6) {
                                    return (<Tag color="green">Hoàn thành</Tag>);
                                }
                            }
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(CompensationRequest);
