import React, { Component  } from "react";
import { TreeSelect } from 'antd';
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";

const { SHOW_PARENT } = TreeSelect;

interface Props {
    name: string;
    label: string;
    wrappedClass: string;
    isRequired?: boolean;
    firstOptionLabel?: string;
    error?: any;
    value: any;
    onChange: any;
    options: any;
}
interface State {}

export default class InputTree extends Component<Props, State> {
      onChange = value => {
        this.setState({ value });
        this.props.onChange(value);
      };
      render() {
        const tProps = {
          treeData: this.props.options ? this.props.options : [],
          value: this.props.value,
          onChange: this.onChange,
          treeCheckable: true,
          showCheckedStrategy: SHOW_PARENT,
          searchPlaceholder: this.props.firstOptionLabel,
          style: {
            width: '100%',
          },
        };
        return (
        <div className={this.props.wrappedClass}>
        <LabelInput
          nameFor={this.props.name}
          label={this.props.label}
          isRequired={this.props.isRequired}
        />
        <TreeSelect {...tProps} />
        <ValidateMessageError error={this.props.error}></ValidateMessageError>
        </div>
        );
      }
  }
