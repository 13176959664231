import React, { Component } from "react";
import "../css/main.css";
import SelectBox from "../form/wh/SelectBox";
import InputWithLabel from "../form/InputWithLabel";
import { Popconfirm } from "antd";

interface Props {
  listType?: any;
  versionTokenList?: any;
  changeData?: any;
  errors?: any;
  data?: any;
  onDelete?: any;
  keyDelete?: any;
}
interface State {}

export default class Table extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const dataSource = this.props.data;
    return (
      <table className="w-100 m-t-20 ant-table-bordered" key="2">
        <thead className="ant-table-thead">
          <tr>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">STT</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Loại token<span className="text-danger ml-1">*</span>
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Version Token<span className="text-danger ml-1">*</span>
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Số lượng<span className="text-danger ml-1">*</span>
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Số lượng còn lại
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Tác vụ</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {dataSource ? (
            Object.keys(dataSource).map((key, index) => (
              <tr
                className={`ant-table-row editable-row ant-table-row-level-${key}`}
                data-row-key={key}
                key={key}
              >
                <td className="text-center">{1 + Number(key)}</td>
                <td>
                  <SelectBox
                    options={this.props.listType}
                    label=""
                    name={`type${key}`}
                    wrappedClass=""
                    onChange={this.props.changeData}
                    value={dataSource[key]["type"]}
                    firstOptionLabel="Lựa chọn"
                    error={
                      this.props.errors.dataToken &&
                      dataSource[key]["type"] === ""
                        ? "Loại token không được trống"
                        : ""
                    }
                    isRequired={true}
                    dataKey={"type"}
                  />
                </td>
                <td className="">
                  <SelectBox
                    name={`version_type${key}`}
                    options={this.props.versionTokenList}
                    label=""
                    wrappedClass=""
                    firstOptionLabel="Chọn version token"
                    onChange={this.props.changeData}
                    value={dataSource[key]["version_type"]}
                    error={
                      this.props.errors.dataToken &&
                      dataSource[key]["version_type"] === ""
                        ? "Version token không được trống"
                        : ""
                    }
                    isRequired={true}
                    dataKey={"version_type"}
                  />
                </td>

                <td className="text-center">
                  <InputWithLabel
                    name={`amount${key}`}
                    label=""
                    wrapClass=""
                    onChangeValue={this.props.changeData}
                    value={dataSource[key]["amount"]}
                    error={
                      this.props.errors.dataToken &&
                      dataSource[key]["amount"] !== "" &&
                      (!Number.isInteger(Number(dataSource[key]["amount"])) ||
                        Number(dataSource[key]["amount"]) < 1)
                        ? "Số lượng phải là số nguyên dương"
                        : "" ||
                          (this.props.errors.dataToken &&
                            dataSource[key]["amount"] === "")
                        ? "Số lượng không được trống"
                        : "" ||
                          (Number(dataSource[key]["amount"]) > Number(dataSource[key]["rest"]))
                        ? "Số lượng không đủ"
                        : ""
                    }
                    isRequired={true}
                    maxLength={255}
                    dataKey={"amount"}
                    type="number"
                    min={1}
                  />
                </td>
                <td className="text-center">{dataSource[key]["rest"]}</td>
                <td className="text-center">
                  {Number(key) !== 0 ? (
                    <Popconfirm
                      title="Bạn có chắc chắn xóa?"
                      onConfirm={this.props.onDelete}
                      okText="Xóa"
                      cancelText="Bỏ qua"
                    >
                      <a
                        href="/#"
                        className="pointer text-danger ml-1 mr-1"
                        title="Xóa"
                        onClick={this.props.keyDelete}
                      >
                        <i className="fas fa-trash-alt fa-lg"></i>
                      </a>
                    </Popconfirm>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    );
  }
}
