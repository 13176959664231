import React from "react";
import ActionButtonView from "../../common/button/action/ActionButtonView";

interface Props {
    onClickUpdate?: () => void;
    onClickView?: () => void;
    onClickAddLiquidation?: () => void;
    onClickUploadLiquidation?: () => void;
    tittle?: string;
    icon?: string;
    isUpdate?: boolean;
    isView?: boolean;
    isAddLiquidation?: boolean;
    isUploadLiquidation?: boolean;
}

export default function TableActionButton(props: Props) {
    return (
        <div className="row justify-content-center">
            {props.isAddLiquidation ? (
                <button onClick={props.onClickAddLiquidation} className="pointer text-primary ml-1 mr-1" title="Thêm biên bản thanh lý">
                    <i className="far fa-calendar-plus fa-lg"/></button>
            ) : (
                ""
            )}
            {props.isUpdate ? (
                <button onClick={props.onClickUpdate} className="pointer text-primary ml-1 mr-1" title="Cập nhật">
                    <i className="fas fa-edit fa-lg"/></button>
            ) : (
                ""
            )}
            {props.onClickView ? (
                <ActionButtonView onClick={props.onClickView}/>
            ) : (
                ""
            )}
            {props.isUploadLiquidation ? (
                <button onClick={props.onClickUploadLiquidation} className="pointer text-primary ml-1 mr-1" title="Upload biên bản thanh lý">
                    <i className="fas fa-cloud-upload-alt fa-lg"/></button>
            ) : (
                ""
            )}
        </div>
    );
}
