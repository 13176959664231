import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class Services extends FetchAPI{

    public async getServices(data: object) {
        const path = "/cate/service"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteServiceById(id){
        const result =  await this.request("cate/service-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getService(id){
        const result = await this.request("cate/service-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createService(data){
        const result = await this.request("cate/service",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateService(data){
        const result = await this.request("cate/service-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
    public async getAll(){
        const result = await this.request("cate/service-all",{},TypeMethodContsantEnum.GET);
        return result;
    }
}

export default Services;