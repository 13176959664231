import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import swal from "sweetalert";
import { onFailAction } from "../../../common/swal/SwalCommon";
import SelectDate from "../../../common/form/SelectDate";
import SelectSwitch from "../../../common/form/SelectSwitch";
import InputFileUpload from "../../../common/form/InputFileUpload";
import { loading } from "../../../../components/common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";
import DocumentService from "../../../../services/document/DocumentService";

interface Props {
  id: string;
  history: History;
}
interface State {
  name: string;
  number: string;
  type: string;
  type1: boolean;
  type2: boolean;
  file_name: string;
  valid_at: string;
  release_at: string;
}

class UpdateUserForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    permissions: {},
    agency: {},
    filename: '',
    valid_at: '',
    release_at: '',
  };

  componentDidMount() {

    this.props.setFieldValue("valid_at", '');
    this.props.setFieldValue("release_at", '');
    this.getDataUpdate();
  }

  getDataUpdate = async () => {
    const service = new DocumentService();
    const result = await service.getDocument(this.props.id);
    if (result && result.status === 200) {
      const {
        name,
        number,
        type,
      } = result.data;
      
      this.props.setValues({
        name,
        number,
        type,
      } as Pick<State, keyof State>);

      this.setState({
        filename: result.data.file
      });
      let value_valid = '';
      if(result.data.valid_at !== null){
        value_valid = result.data.valid_at;
      }
      let value_release = '';
      if(result.data.release_at !== null){
        value_release = result.data.release_at;
      }
      this.props.setFieldValue("valid_at", value_valid);
      this.props.setFieldValue("release_at", value_release);
      for(let i = 1; i < 3; i++ ){
        if(result.data.type.indexOf(i) > -1){
          this.props.setFieldValue("type"+i, true);
        }else{
          this.props.setFieldValue("type"+i, false);
        }
      }
    }
  };

  onChangeSelectDateValid = (date, dateString) => {
    this.props.setFieldValue("valid_at", dateString);
  };

  onChangeSelectDateRelease = (date, dateString) => {
    this.props.setFieldValue("release_at", dateString);
  };

  onChangeSystem1 = e => {
    this.props.setFieldValue("type1", e);
  }

  onChangeSystem2 = e => {
    this.props.setFieldValue("type2", e);
  }

  onChangeFile = async e => {
    const files = e.target.files[0];
      if (files) {
        const fileSize = files.size / 1024 / 1024;
        if (fileSize > 35) {
          onFailAction("File tải lên không thể lớn hơn 35MB", () => {
            this.setState({
              filename: ''
            });
            this.props.setFieldValue("file_name", '');
          });
        }else {
          const formData = new FormData();
          formData.append("file", files);
          loading.runLoadingBlockUI();
          const fileData = await BaseServices.axiosUpLoadFile(formData);
          this.props.setFieldValue("file_name", fileData.file);
          this.setState({
            filename: files.name
          });
          loading.stopRunLoading();
        }
      } else {
        this.setState({
          filename: ''
        });
        this.props.setFieldValue("file_name", '');
      }

  };

  render() {
    const {
      name,
      number,
      valid_at,
      release_at,
      type1,
      type2,
    } = this.props.values;

    return (
      <form>
        <div className="input-group">
          <SelectSwitch
            label="Hiện thị ở Admin"
            name="type1"
            wrappedClass="col-md-2 form-group"
            onChange={this.onChangeSystem1}
            value={type1}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={false}
          />
          <SelectSwitch
            label="Hiện thị ở QLBH"
            name="type2"
            wrappedClass="col-md-2 form-group"
            onChange={this.onChangeSystem2}
            value={type2}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={false}
          />
          <InputWithLabel
            name="name"
            label="Tên tài liệu"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
          />
          <InputWithLabel
            name="number"
            label="Số tài liệu"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={number}
            error={this.props.errors.number}
            isRequired={true}
          />
        </div>
        <div className="input-group">
        <SelectDate
            label="Ngày phát hành"
            name="release_at"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateRelease}
            value={release_at}
            error={this.props.errors.release_at}
            isRequired={false}
          />
          <SelectDate
            label="Ngày hiệu lực"
            name="valid_at"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateValid}
            value={valid_at}
            error={this.props.errors.valid_at}
            isRequired={false}
          />
          <InputFileUpload
            name="file_name"
            classWrapped="col-md-6 form-group"
            label="File tài liệu"
            filename={this.state.filename}
            onChangeFileUpload={this.onChangeFile}
            error={this.props.errors.file_name}
            isRequired={false}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url='/tai-lieu'/>
          <SubmitButton onClick={this.props.handleSubmit}/>
        </div>
      </form>
    );
  }
}

const userUpdateForm = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: "",
    number: "",
    type: "",
    type1: true,
    type2: true,
    base_64: "",
    file_name: "",
    valid_at: "",
    release_at: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên tài liệu không được bỏ trống."),
    number: Yup.string().required("Số tài liệu không được bỏ trống."),
  }),
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new DocumentService();
    const result = await service.updateDocument({ ...values, permissions: '', id: props.id });
    if (result && result.status === 200) {
      swal({
        title: "Thành công!",
        text: "Cập nhật thành công!",
        icon: "success",
        dangerMode: false
      }).then(function () {
        props.history.goBack();
      });
    } else if (result.status === 1) {
      setErrors(result.errors);
    } else {
      onFailAction(result.errors);
    }
  },

})(UpdateUserForm);

export default userUpdateForm;
