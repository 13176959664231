import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import SelectDate from "../../../common/form/SelectDate";
import SelectSwitch from "../../../common/form/SelectSwitch";
import { History } from "history";
import * as Yup from "yup";
import SoftwareService from "../../../../services/software/SoftwareService";
import InputFileUpload from "../../../common/form/InputFileUpload";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";
import { loading } from "../../../common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";

interface Props {
  history: History;
}
interface State {
  name: string;
  version: string;
  type: string;
  type1: boolean;
  type2: boolean;
  valid_at: string;
  release_at: string;
  filename: string;
  file_name: string;
}
class CreateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    type1: true,
    type2: true,
    filename: ""
  };

  onChangeSelectDateValid = (date, dateString) => {
    this.props.setFieldValue("valid_at", dateString);
  };

  onChangeSelectDateRelease = (date, dateString) => {
    this.props.setFieldValue("release_at", dateString);
  };

  onChangeSystem1 = e => {
    this.props.setFieldValue("type1", e);
  };

  onChangeSystem2 = e => {
    this.props.setFieldValue("type2", e);
  };

  onChangeFile = async e => {
    const files = e.target.files[0];
    if (files) {
      const fileSize = files.size/1024/1024;
      if(fileSize > 20){
        this.props.setFieldError("file_name", "File tải lên không thể lớn hơn 20MB")
      }
      else {
        this.props.setFieldError("file_name", "")
        const formData = new FormData();
        formData.append("file", files);
        loading.runLoadingBlockUI();
        const fileData = await BaseServices.axiosUpLoadFile(formData);
        this.props.setFieldValue("file_name", fileData.file);
        this.setState({
          filename: files.name
        });

        loading.stopRunLoading();
      }
    } else {
      this.setState({
        filename: ""
      });
      this.props.setFieldValue("file_name", "");
    }
  };

  onSetValue = () => {};

  render() {
    const {
      name,
      version,
      valid_at,
      release_at,
      type1,
      type2
    } = this.props.values;

    return (
      <form>
        <div className="input-group">
          <SelectSwitch
            label="Hiện thị ở Admin"
            name="type2"
            wrappedClass="col-md-2 form-group"
            onChange={this.onChangeSystem1}
            value={type1}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={false}
          />
          <SelectSwitch
            label="Hiện thị ở QLBH"
            name="type1"
            wrappedClass="col-md-2 form-group"
            onChange={this.onChangeSystem2}
            value={type2}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={false}
          />
          <InputWithLabel
            name="name"
            label="Tên phần mềm"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
          />
          <InputWithLabel
            name="version"
            label="Version"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={version}
            error={this.props.errors.version}
            isRequired={true}
          />
        </div>
        <div className="input-group">
          <SelectDate
            label="Ngày phát hành"
            name="release_at"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateRelease}
            value={release_at}
            error={this.props.errors.release_at}
            isRequired={false}
          />
          <SelectDate
            label="Ngày hiệu lực"
            name="valid_at"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateValid}
            value={valid_at}
            error={this.props.errors.valid_at}
            isRequired={false}
          />
            <InputFileUpload
                name="file_name"
                classWrapped="col-md-6 form-group"
                label="File phần mềm"
                filename={this.state.filename}
                onChangeFileUpload={this.onChangeFile}
                error={this.props.errors.file_name}
                accept={[".exe", ".zip", ".rar"]}
                isRequired={true}
                isNote={false}
            />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url="/phan-mem"/>
          <SubmitButton onClick={this.props.handleSubmit}/>
        </div>
      </form>
    );
  }
}

const RenderCreateForm = withFormik<Props, State>({
  mapPropsToValues: () => ({
    name: "",
    version: "",
    type: "",
    type1: true,
    type2: true,
    file_name: "",
    valid_at: "",
    release_at: "",
    filename: ""
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên phần mềm không được bỏ trống."),
    version: Yup.string().required("Verison phần mềm không được bỏ trống."),
    file_name: Yup.string().required("File phần mềm không được bỏ trống.")
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const user = new SoftwareService();
    const result = await user.createSoftware(values);
    if (result) {
      if (result.status === 200) {
        onSuccessAction("Thêm mới phần mềm thành công!", function() {
          props.history.goBack();
        });
      } else if (result.status === 1) {
        setErrors(result.errors);
      } else {
        onFailAction(result.errors);
      }
    }
  }
})(CreateForm);

export default RenderCreateForm;
