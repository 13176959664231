import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

const PREFIX_SERVICES = "admin/quan-ly-chiet-khau/";

class CateDiscountService extends FetchAPI{

    public async list(data) {
        const result = await this.request(
            PREFIX_SERVICES + "index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async store(data) {
        const result = await this.request(
            PREFIX_SERVICES + "store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async update(data) {
        const result = await this.request(
            PREFIX_SERVICES + "update",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async getAgencyContributor(belong_to){
        const result = await this.request(
            PREFIX_SERVICES + "getAgencyContributor",
            {belong_to},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async delete(code) {
        const result = await this.request(
            PREFIX_SERVICES +"delete",
            { code },
          TypeMethodContsantEnum.DELETE
        );
        return result;
    }
    public async findCode(id) {
        const result = await this.request(
            PREFIX_SERVICES + "findCode",
          {id},
          TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async lock(code,type) {
        const result = await this.request(
            PREFIX_SERVICES +"lock",
            { code,type },
          TypeMethodContsantEnum.GET
        );
        return result;
    }
}

export default CateDiscountService;