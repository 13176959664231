import BaseServices from "../base/BaseServices";

const PREFIX_SERVICES = "admin/gen-cts/";


const getListIndex = async (data: object) => {
    const result = await BaseServices.request(
        PREFIX_SERVICES + "index",
        data,
        "GET"
    );
    return result;
};
const getListCateServicePackage = async () => {
    const listCate = await BaseServices.request(
        PREFIX_SERVICES + "get-cate-service-package",
        {},
        "GET"
    );
    return listCate;
};
const getRequestDoc = async (owner_id, type_gen, type_user) =>{
    const result = await BaseServices.request(
        PREFIX_SERVICES + 'get-request-doc',
        {owner_id, type_gen, type_user},
        'GET'
    );
    return result;
};
const previewInfoCert = async id =>{
    const requestDigital = await BaseServices.request(
        PREFIX_SERVICES + "view-info-cert/" +id ,
        {},
        "GET"
    );
    return requestDigital;
};
export const GenCertServices = {
    getListIndex,
    getListCateServicePackage,
    getRequestDoc,
    previewInfoCert
};
