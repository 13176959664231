import React, { Component } from "react";
import "./navbar.css";
import { History, Location } from "history";
import { match } from "react-router";
import { Link } from "react-router-dom";
import { NAVLINK_CONSTANT } from "../routes/RoutersList";
import _ from "lodash";
import { Menu } from "antd";
import DrawerInfoUser from "./DrawerInfoUser";
import Store from "../../store/Store";
import Badges from './../common/badge/Badges';

const { SubMenu } = Menu;

interface Props {
  history: History;
  location: Location;
  match: match;
}
interface State {
  current: string;
  visible_pf: boolean;
}
class Navbar extends Component<any, State> {
  state = {
    current: "",
    visible_pf: false
  };

  componentDidMount() {
    let temp = "home";
    for (let elem of NAVLINK_CONSTANT) {
      let key = 1;
      for (let elem2 of elem.childMenu) {
        if (this.props.location.pathname.indexOf(elem2.link) !== -1) {
          temp = elem.link + ":" + key;
          break;
        }
        key++;
      }
    }

    this.setState({ current: temp }, function() {});
  }

  onLogout = () => {
    this.props.history.push("/logout");
    return false;
  };
  handleClick = e => {};

  checkSelectedLink = link => {
    return _.startsWith(this.props.location.pathname, link)
      ? "selected-link"
      : "";
  };

  checkSelectedLinkTop = link => {
    return _.startsWith(this.props.location.pathname, link)
      ? "ant-menu-item-selected"
      : "";
  };

  checkSubMenu = lstLink => {
    const pathName = this.props.location.pathname;
    return _.some(lstLink, function(tag) {
      return _.startsWith(pathName, tag.link);
    })
      ? " show"
      : "";
  };

  objectContains(obj, term: string): boolean {
    for (let key in obj) {
      if (obj[key].indexOf(term) !== -1) return true;
    }
    return false;
  }

  showDrawerPf = () => {
    this.setState({
      visible_pf: true
    });
  };

  onClosePf = () => {
    this.setState({
      visible_pf: false
    });
  };

  render() {
    const isExpand = this.props.isExpand;
    // const user_role = Store.getState().authReducer.permission_list;
    const user = Store.getState().authReducer;
    const user_role = user.permission_list;
    return (
      <React.Fragment>
        <DrawerInfoUser
          location={this.props.location}
          history={this.props.history}
          match={this.props.match}
          user={user}
          visible_pf={this.state.visible_pf}
          onClose={this.onClosePf}
        />

        <nav className="navbar navbar-expand-md my-navbar-css">
          <div className="ant-pro-top-nav-header-menu">
            <a href="/" className="navbar-brand">
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                width="auto"
                height="30"
                className="d-inline-block align-top ml-4 mr-2"
                alt=""
              />

              <span className="menu-collapsed hearder-text"></span>
            </a>
          </div>
          <div className="ant-pro-top-nav-header-menu">
            <Menu
              onClick={this.handleClick}
              selectedKeys={[this.state.current]}
              mode="horizontal"
            >
              {NAVLINK_CONSTANT.map((route, index) => {
                if (route.childMenu.length > 0) {
                  if(this.objectContains(user_role,route.permission))
                    return (
                      <SubMenu
                        title={
                          <span className={`${route.icon}`} >
                            <span className="submenu-title-wrapper">
                              {route.displayName}
                            </span>
                            {route.link === 'support-sell' && Number(user.number_need_support) > 0 ? <Badges count={Number(user.number_need_support)}/>  : ''}
                            {route.link === 'agency' && Number(user.number_manager_cooperate) >0 ? <Badges count={Number(user.number_manager_cooperate)}/>  : '' }
                            {route.link === 'operation' && Number(user.number_operation) > 0 ? <Badges count={Number(user.number_operation)}/>  : ''}
                            {route.link === 'manager-files' && Number(user.number_doc_legal) > 0 ? <Badges count={Number(user.number_doc_legal)}/>  : ''}
                          </span>
                        }
                        key={route.link}
                      >
                        {route.childMenu.map((childRoute, childIndex) => {
                          if (
                            this.objectContains(user_role, childRoute.permission)
                          ) {
                            return (
                              <Menu.Item
                                key={`${route.link} : ${childIndex + 1}`}
                                className={`${this.checkSelectedLinkTop(
                                  childRoute.link
                                )} `}
                              >
                                <Link
                                  key={childIndex}
                                  to={childRoute.link}
                                  className="child-menu-top"
                                >
                                  <span className={`${childRoute.childIcon}`}>
                                    <span
                                      className={`submenu-title-wrapper ${
                                        isExpand ? "d-none" : ""
                                      }`}
                                    >
                                      {childRoute.displayName}
                                      {childRoute.link === '/danh-sach-yeu-cau-dao-tao' && Number(user.number_training_support) ? <Badges count={Number(user.number_training_support)}/>  : ''}
                                      {childRoute.link === '/yeu-cau-token-cts' && Number(user.number_document_support) ? <Badges count={Number(user.number_document_support)}/>  : ''}

                                      {childRoute.link === '/yeu-cau-tk-admin-token' && Number(user.number_create_account) ? <Badges count={Number(user.number_create_account)}/> : ''}
                                      {childRoute.link === '/dai-ly' && Number(user.number_agency) ? <Badges count={Number(user.number_agency)}/>  : ''}
                                      {childRoute.link === '/cong-tac-vien' && Number(user.number_contributor) ? <Badges count={Number(user.number_contributor)}/> : ''}
                                      {childRoute.link === '/dung-hop-tac' && Number(user.number_stop_cooperate) ? <Badges count={Number(user.number_stop_cooperate)}/> : ''}
                                      {childRoute.link === '/yeu-cau-thay-doi-thong-tin' && Number(user.number_change_info) ? <Badges count={Number(user.number_change_info)}/> : ''}

                                      {childRoute.link === '/yeu-cau-khoa-tai-khoan' && Number(user.number_request_look_acc) ? <Badges count={Number(user.number_request_look_acc)}/> : ''}
                                      {childRoute.link === '/yeu-cau-chung-thu-so' && Number(user.number_request_cts) ? <Badges count={Number(user.number_request_cts)}/> : ''}
                                      {childRoute.link === '/thay-doi-thong-tin-chung-thu-so' && Number(user.number_change_info_cts) ? <Badges count={Number(user.number_change_info_cts)}/> : ''}
                                      {childRoute.link === '/quan-ly-ho-so-phap-ly' && Number(user.number_doc_legal) ? <Badges count={Number(user.number_doc_legal)}/> : ''}

                                    </span>
                                  </span>
                                </Link>
                              </Menu.Item>
                            );
                          }
                          return true;
                        })}
                      </SubMenu>
                    );
                    return true;
                } else {
                  return (
                    <Link
                      key={index}
                      to={route.link}
                      className={`${this.checkSelectedLink(
                        route.link
                      )} bg-light list-group-item list-group-item-action`}
                    >
                      <div className="d-flex w-100 justify-content-start align-items-center">
                        <span className={`${route.icon} fa-fw mr-3`} />
                        <span
                          className={`menu-collapsed ${
                            isExpand ? "d-none" : ""
                          }`}
                        >
                          {route.displayName}
                        </span>
                      </div>
                    </Link>
                  );
                }
              })}
            </Menu>
          </div>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav navbar-nav-right nav-menu-right">
              <li className="nav-item dropdown d-none d-xl-inline-block">
                <a
                  className="nav-link dropdown-toggle hearder-text"
                  id="UserDropdown"
                  href="/#"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="menu-icon"></i>
                  {user.username}
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="UserDropdown"
                >
                  <Link
                    key={``}
                    to={`#`}
                    className={"dropdown-item mt-2"}
                    onClick={this.showDrawerPf}
                  >
                    <span className="">Quản lý tài khoản</span>
                  </Link>
                  <Link
                    key={`/doi-mat-khau`}
                    to={`/doi-mat-khau`}
                    className={"dropdown-item  mt-2"}
                  >
                    <span className="">Đổi mật khẩu</span>
                  </Link>
                  <a
                    className="dropdown-item  mt-2"
                    href="/#"
                    onClick={this.onLogout}
                  >
                    Đăng xuất{" "}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
