import React, { Component } from "react";
import "../css/main.css";
interface Props {
  text?: string;
  css?: string;
  icon?: string;
  onClick?: any;
  disable ?: boolean;
}
interface State {}

export default class ClickButton extends Component<Props, State> {
  state = {};

  render() {
    return (
      <button
        className={this.props.css || "btn btn-success ml-1 mr-1 font-btn"}
        onClick={this.props.onClick} type="button" disabled= {this.props.disable || false}
      >
        <i className={this.props.icon || "fas fa-plus mr-1"}></i>
        {this.props.text || "Thêm mới"}
      </button>
    );
  }
}
