import React, { Component } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { History, Location } from "history";
import { match } from "react-router";
import SearchButton from "../../common/button/SearchButton";
import InputWithLabel from "../../common/form/InputWithLabel";
import Helmet from "react-helmet";
import { Type } from "./ContributorEnum";
import ContributorService from "../../../services/contributor/ContributorService";
import SelectBox from "../../common/form/SelectBox";
import UserService from "../../../services/user/UserService";
import SelectUserManager from "../../common/form/SelectUserManager";
import AgencyTable from "./table/Table";
import SelectDate from "../../common/form/SelectDate";

interface Props {
  history: History;
  location: Location;
  match: match;
  name:string;
  userManager: object;
  user: any;
}
interface State {
  dataSearch: any;
}
const TITLE = 'Danh sách cộng tác viên';
class PageIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      text_search: "",
      status: "",
      passport: "",
      created_at: "",
      sale_id : ""
    },
    userManager: {}
  };

   fetchDataFunction = async (dataSearch = {})=>{
    const service = new ContributorService();
    const result = await service.getContributors(
        {...dataSearch},
      );
    if(result && result.status===200){
      return result
    }
    return null;
  }
  onChangeSelectDateValid = (date, dateString) => {
    const dataSearch = { ...this.state.dataSearch, 'created_at': dateString };
    this.setState({ dataSearch });
  };

  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };
  onChangeDataSearchSale_Id = (e) => {
    const dataSearch = { ...this.state.dataSearch, 'sale_id': e };
    this.setState({ dataSearch });
  };
  onChangeDataSearchStatus = (e) => {
    const dataSearch = { ...this.state.dataSearch, 'status': e };
    this.setState({ dataSearch });
  };

  onChangeDataSearchSelect = (e) => {
    const dataSearch = { ...this.state.dataSearch, 'sale_id' : e };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = (data) =>{
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  }

  componentDidMount() {
    this.getUserManager();
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch:queryString
    })
  }

  getUserManager = async () => {
    const service = new UserService();
    const result = await service.getUserManager();
    if(result && result.status===200){
      this.setState({
        userManager: result.data
      } as any);
    }
  }

  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="card-body">
        <h4 className="card-title1">{TITLE}</h4>
          <form onSubmit={this.onSubmitSearchButton} className='search-form-index'>
            <div className="input-group">
                <SelectUserManager
                  label=""
                  onChange={this.onChangeDataSearchSale_Id}
                  value={this.state.dataSearch.sale_id}
                  name="sale_id"
                  wrappedClass="col-md-2 form-group nopadding-left"
                  options={this.state.userManager}
                  firstOptionLabel="Người quản lý"
                />
              <SelectBox
                  value={this.state.dataSearch.status}
                  label=""
                  onChange={this.onChangeDataSearchStatus}
                  name="status"
                  wrappedClass="col-md-2 form-group nopadding-left"
                  options={Type.STATUS}
                  firstOptionLabel="Tất cả trạng thái"
                />
              <InputWithLabel
                name="passport"
                wrapClass="col-md-2 form-group nopadding-left"
                onChangeValue={this.onChangeDataSearchInput}
                value={this.state.dataSearch.passport}
                isRequired={false}
                label={""}
                placeholder={"Chứng minh thư"}
              />
              <InputWithLabel
                name="text_search"
                wrapClass="col-md-3 form-group nopadding-left"
                onChangeValue={this.onChangeDataSearchInput}
                value={this.state.dataSearch.text_search}
                isRequired={false}
                label={""}
                placeholder={"Mã hoặc tên cộng tác viên"}
              />
              <SelectDate
                  label=""
                  name="created_at"
                  wrappedClass="col-md-2 form-group nopadding-left"
                  onChange={this.onChangeSelectDateValid}
                  value={this.state.dataSearch.created_at ? this.state.dataSearch.created_at : ''}
                  isRequired={true}
                  placeholder="Ngày tạo"
              />
              <div className="col-md-1 form-group nopadding-left">
                <SearchButton onClick={this.onSubmitSearchButton} />
              </div>
            </div>
          </form>
          <AgencyTable
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(PageIndex);

export default pageWithNavBar;
