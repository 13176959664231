import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import WhDocumentService from "../../../services/WhDocument/WhDocumentService";
import AssetCategoryService from "../../../services/asset-category/AssetCategoryService";
import SelectBox from "../../common/form/SelectBox";
import SelectDate from "../../common/form/SelectDate";

interface Props extends RouteComponentProps {
}

const TITLE = "Kho tài liệu bán hàng";

const WhAsset: React.FC<Props> = props => {
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({...dataSearch, receive_date: dateString});
    };

    const [dataSearch, setDataSearch] = useState({
        asset_id: "",
        code: "",
        receive_date: ""
    });

    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchAssetId = (e) => {
        setDataSearch({...dataSearch, 'asset_id': e});
      };

    const fetchDataFunction = async () => {
        const whAsset = new WhDocumentService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            return await whAsset.list({...dataSearch, ...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={record.status === 1 ? () => props.history.push(`/kho-tai-lieu-ban-hang/cap-nhat/${record.id}`) : null}
                onClickView={() =>
                    props.history.push(`/kho-tai-lieu-ban-hang/xem-chi-tiet/${record.id}`)
                }
            />
        );
    };
    const [assetCateList, setAssetCateList] = useState({});
    useEffect(() => {
        getAssetCate();
        // eslint-disable-next-line
    }, []);

    const getAssetCate = async () => {
        const service = new AssetCategoryService();
        const result = await service.all(1);
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setAssetCateList(options);
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã nhập kho"}
                        />
                        <SelectBox
                            name="asset_id"
                            options={assetCateList}
                            label=""
                            wrappedClass="col-md-2"
                            firstOptionLabel="Loại tài liệu bán hàng"
                            onChange={onChangeDataSearchAssetId}
                            value={dataSearch.asset_id}
                        />
                        <SelectDate
                            label=""
                            name="receive_date"
                            wrappedClass="col-md-2 form-group"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.receive_date}
                            isRequired={true}
                            placeholder="Ngày nhập"
                        />
                        <div className="col-md-6 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`}/>
                            <GoToCreatePageButton
                                toURL={`/kho-tai-lieu/xuat-kho`}
                                text="Xuất kho tài liệu"
                                icon="fas fa-file-export"
                            />
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Mã nhập kho", dataIndex: "code"},
                        {title: "Tài liệu bán hàng", dataIndex: "asset_category.name"},
                        {title: "Người giao", dataIndex: "deliver"},
                        {title: "Người nhận", dataIndex: "receiver"},
                        {title: "Số lượng", dataIndex: "total"},
                        {
                            title: "Ngày nhận",
                            dataIndex: "receive_date",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={fetchDataFunction}
                />
            </div>
        </div>
    );
};

export default withNavbar()(WhAsset);
