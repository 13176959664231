import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import RequestService from "../../../../services/requesttokenagency/RequestTokenService";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import { STATUS_LABEL_CLASS, STATUS_CREATE_ACCOUNT, OWNER_LABEL_CLASS } from "../RequestTkAdminEnum";
import { checkPermission } from "../../../../helpers/NewCaHelper";
import { Tag } from "antd";

interface Props {
  location: Location;
  history: History;
  match: match;
  user: any;
}
interface State { }

const per_view_detail = 'view-deatail-request-create-acc';

export default class RequestTable extends Component<Props, State> {
  state = {};

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetch();
    }
    window.onpopstate = e => {
      this.fetch();
    };
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const service = new RequestService();
    try {
      return await service.getRequestsCreateUser(params);
    } catch (error) {
            onFailAction();
    }
  };

  render() {
    return (
      <Table
        columns={this.columns}
        onFetchData={this.fetch}
      />
    );
  }

  onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickLock={() => true}
        onClickUpdate={() =>
          this.props.history.push(`/yeu-cau-tk-admin-token/xu-ly/${record.id}`)
        }
        isDisplayLockButton={false}
        isDisplayUpdateButton={this.checkUpdateAble(record)}
      />
    );
  };

  checkUpdateAble = (record: any): any => {
    return checkPermission(per_view_detail);
  };

  renderStatusColumn = (text, record, index) => {
    return (
      <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
        {STATUS_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  renderTypeOwner = (text, record, index) => {
    return (
      <Tag color={`${OWNER_LABEL_CLASS[text].class}`}>
        {OWNER_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  renderStatusAccountColumn = (text, record, index) => {
    return (
      <Tag color={`${STATUS_CREATE_ACCOUNT[text].class}`}>
        {STATUS_CREATE_ACCOUNT[text].label}
      </Tag>
    );
  };

  columns = [
    {
      title: "Mã yêu cầu",
      dataIndex: '',
      render: (e) => {
        return e.code
      }
    },
    {
      title: 'Mã số thuế',
      dataIndex: '',
      render: (e) => {
        if (e) {
          if (e.belong_to){
            if(Number(e.belong_to) === 1){
              return <a href="#/" >{e.agency ? e.agency.tax_code : ""}</a>;
            }else if(Number(e.belong_to) === 2){
              return <a href="#/" >{e.contributor? e.contributor.passport : ""}</a>;
            }else {
              return <a href="#/" >{e.owner? e.owner.tax_code : ""}</a>;
            }
          }else {
            return <a href="#/" >{e.agency ? e.agency.tax_code : ""}</a>;
          }
        }
      }
    },
    { title: "Tên đại lý",
      dataIndex: "",
      render:(e) =>{
        if (e.belong_to) {
          return e.owner.fullname;
        }else {
          return e.agency.fullname;
        }
      }
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: this.renderStatusColumn
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
    },
    { title: "Người tạo", dataIndex: "user.fullname" },
    {
      title: "Trạng thái tài khoản",
      dataIndex: "status_create_account",
      render: this.renderStatusAccountColumn
    },
    {
      title: "Tác vụ",
      render: this.onRenderActionColumn
    }
  ];
}
