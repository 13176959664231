import React, { Component } from 'react'
import { authenticationService } from '../../../services/AuthenticationService';
import { History } from 'history';

interface Props {
    history : History;
}
interface State {

}

export default class Logout extends Component<Props, State> {
    constructor(props){
        super(props)
        this.logout();
    }
    state = {}

    logout(){
        authenticationService.logout();
        this.props.history.push('/');
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}
