import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import StopAgencyService from "../../../../services/stop-agency/StopAgencyService";
import BaseServices from "../../../../services/base/BaseServices";
import InputFileUpload from "../../../common/form/InputFileUpload";
import {loading} from "../../../common/loading/NewLoading";
interface FormValues {
    file_type: string;
    file: string;
}

interface OtherProps extends RouteComponentProps<{id: string;}> {}

type Props = OtherProps & FormikProps<FormValues>;

const StopAgencyView: React.FC<Props> = props => {
    const service = new StopAgencyService();

    const [data, setData] = useState({
        type_user : "",
        owner_name: "",
        short_name: "",
        owner_code: "",
        owner_tax_code: "",
    });
    const {file_type, file} = props.values;
    const onFetchDataOwner = async () => {
        const data = await service.getDataOwnerStop(id);
        var taxCode;
        var typeUser;
        if (data.data.passport){
            taxCode = data.data.passport;
            typeUser = 'Cộng tác viên';
        }else{
            taxCode = data.data.tax_code;
            typeUser = 'Đại lý';
        }
        setData({...data,
            'type_user': typeUser,
            'owner_name': data.data.fullname,
            'short_name': data.data.sortname,
            'owner_code': data.data.code,
            'owner_tax_code': taxCode,
        });
    };

    useEffect(() => {
        onFetchDataOwner();
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const id = props.match.params.id;

    const onFetchData = async () => {
        const result = await service.find(id);
        props.setFieldValue(result, result.data.result);
        for (let key in result.data) {
            if(key !== 'file'){
                props.setFieldValue(key, result.data[key]);
            }
        }
    };

    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            if(fileData){
                props.setFieldValue("file", fileData.file);
            }
            loading.stopRunLoading();
        } else {
            props.setFieldValue("file", "");
        }
    };
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Cập nhật biên bản thanh lý</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Loại người dùng"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.type_user}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Tên đại lý/CTV"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Mã số thế/CMND"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_tax_code}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Tên viết tắt"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Mã đại lý/CTV"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_code}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="file_type"
                            options={{
                                1: "Điện tử",
                                2: "Giấy"
                            }}
                            label="Loại biên bản thanh lý"
                            firstOptionLabel="-- Chọn loại biên bản thanh lý --"
                            wrappedClass="col-md-3"
                            onChange={props.handleChange}
                            value={file_type}
                            error={props.errors.file_type}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputFileUpload
                            name="file"
                            classWrapped="col-md-3 "
                            label="Tải file biên bản thanh lý"
                            filename={file}
                            onChangeFileUpload={onChangeFile}
                            isRequired={false}
                            accept={"application/pdf"}
                            error={props.errors.file}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <SubmitButton onClick={props.handleSubmit} text="Cập nhật"/>
                        <BackButton
                            history={props.history}
                            url="/dung-hop-tac"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        file_type: "",
        file:"",
    }),
    validationSchema: Yup.object().shape({
        file: Yup.string().required("file biên bản thanh lý không được bỏ trống"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new StopAgencyService();
        const result = await service.uploadLiquidation({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.push('/dung-hop-tac');
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(StopAgencyView);

export default withNavbar()(RenderCreateForm);
