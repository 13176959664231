import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class CateContractService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "admin/yeu-cau-dung-hop-tac/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getDataOwnerStop(id) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/getDataOwnerStop/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async store(data) {
        const result = await this.request(
            "admin/yeu-cau-dung-hop-tac/store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async update(data) {
        const result = await this.request(
            "admin/yeu-cau-dung-hop-tac/update",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async find(id) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getListSaleUser() {
        const result = await this.request(
            "admin/yeu-cau-dung-hop-tac/getListSaleUser",
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getBase64(data) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/preview`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async getLiquidationFile(id) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/getLiquidationFile/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async ceoRefuse(data) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/ceoRefuse`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async getCeoApprove(id) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/getCeoApprove/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async uploadLiquidation(data) {
        const result = await this.request(
            `admin/yeu-cau-dung-hop-tac/uploadLiquidation`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
}

export default CateContractService;
