import React, { Component } from "react";
import "../css/main.css";
import SelectBox from "../form/wh/SelectBox";
import InputWithLabel from "../form/InputWithLabel";
import { Popconfirm } from "antd";

interface Props {
  assetList?: any;
  changeData?: any;
  errors?: any;
  data?: any;
  onDelete?: any;
  keyDelete?: any;
  tittle?: string;
}
interface State {}

export default class Table extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const dataSource = this.props.data;
    return (
      <table className="w-100 m-t-20 ant-table-borde~red" key="2">
        <thead className="ant-table-thead">
          <tr>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">STT</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center w-40">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    {this.props.tittle
                      ? this.props.tittle
                      : "Danh mục quà tặng"}
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>

            <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Đơn vị</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>

            <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Số lượng</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>

            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Số lượng còn lại
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>

            {/* <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Đơn giá(VNĐ)</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th> */}
            {/* <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Thành tiền(VNĐ)</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th> */}

            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Tác vụ</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {dataSource ? (
            Object.keys(dataSource).map((key, index) => (
              <tr
                className={`ant-table-row editable-row ant-table-row-level-${key}`}
                data-row-key={key}
                key={key}
              >
                <td className="text-center">{1 + Number(key)}</td>
                <td>
                  <SelectBox
                    name={`asset_id${key}`}
                    options={this.props.assetList}
                    label=""
                    wrappedClass=""
                    firstOptionLabel={"Chọn danh mục tài sản"}
                    onChange={this.props.changeData}
                    value={dataSource[key]["asset_id"]}
                    error={
                      this.props.errors.dataAsset &&
                      dataSource[key]["asset_id"] === ""
                        ? "Danh mục tài sản không được trống"
                        : ""
                    }
                    isRequired={true}
                    dataKey={"asset_id"}
                  />
                </td>
                <td className="text-center">
                  <InputWithLabel
                    name={`unit${key}`}
                    label=""
                    wrapClass=""
                    onChangeValue={this.props.changeData}
                    value={dataSource[key]["unit"]}
                    isRequired={true}
                    maxLength={255}
                    dataKey={"unit"}
                  />
                </td>
                <td className="text-center">
                  <InputWithLabel
                    name={`amount${key}`}
                    label=""
                    wrapClass=""
                    onChangeValue={this.props.changeData}
                    value={dataSource[key]["amount"]}
                    error={
                      this.props.errors.dataAsset &&
                      dataSource[key]["amount"] !== "" &&
                      (!Number.isInteger(Number(dataSource[key]["amount"])) ||
                        Number(dataSource[key]["amount"]) < 1)
                        ? "Số lượng phải là số nguyên dương > 0"
                        : "" ||
                          (this.props.errors.dataAsset &&
                            dataSource[key]["amount"] === "")
                        ? "Số lượng không được trống"
                        : ""||
                        Number(dataSource[key]["amount"]) >
                          Number(dataSource[key]["rest"])
                      ? "Số lượng không đủ"
                      : ""
                    }
                    isRequired={true}
                    maxLength={255}
                    dataKey={"amount"}
                    type="number"
                  />
                </td>
                <td className="text-center">{dataSource[key]["rest"]}</td>
                {/* <td className="text-right">
                  <InputWithLabel
                    name={`price${key}`}
                    label=""
                    wrapClass=""
                    onChangeValue={this.props.changeData}
                    value={dataSource[key]["price"]}
                    error={
                      this.props.errors.dataAsset &&
                      dataSource[key]["price"] !== "" &&
                      (!Number.isInteger(Number(dataSource[key]["price"])) ||
                        Number(dataSource[key]["price"]) < 1)
                        ? "Đơn giá phải là số nguyên dương"
                        : ""
                    }
                    isRequired={true}
                    maxLength={255}
                    dataKey={"price"}
                    type="number"
                    min={1}
                  />
                </td>
                <td className="text-right">{dataSource[key]["total"]}</td> */}
                <td className="text-center">
                  <Popconfirm
                    title="Bạn có chắc chắn xóa?"
                    onConfirm={this.props.onDelete}
                    okText="Xóa"
                    cancelText="Bỏ qua"
                  >
                    <a
                      href="/#"
                      className="pointer text-danger ml-1 mr-1"
                      title="Xóa"
                      onClick={this.props.keyDelete}
                    >
                      <i className="fas fa-trash-alt fa-lg"></i>
                    </a>
                  </Popconfirm>
                </td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    );
  }
}
