import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString,
    formatDateTime, queryStringToJSON,
} from "../../../helpers/NewCaHelper";
import { RouteComponentProps } from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import WhTokenService from "../../../services/whToken/WhTokenService";
import {
  onFailAction,
} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import { TypeWhToken, TYPE_LABEL_CLASS } from "./Enum";
import ProviderCategoryService from "../../../services/ProviderCategory/ProviderCategoryService";
import VersionTokenCategoryService from "../../../services/version-token/VersionTokenServices";
import SelectDate from "../../common/form/SelectDate";
import { Tag } from "antd";

interface Props extends RouteComponentProps {}

const TITLE = "Kho Token";

const VersionToken: React.FC<Props> = props => {
  const [dataSearch, setDataSearch] = useState({
    text_search: "",
    type: "",
    provider_id: "",
    version_token_id: "",
    receive_date: "",
  });
  const [providerList, setProviderList] = useState({});
  const [versionTokenList, setVersionTokenList] = useState({});

  const onSubmitSearchButton = e => {
    e.preventDefault();
      const search = queryStringToJSON(props.location.search);
      let raw = {};
      for (let key in search){
          if (key === 'raw'){
              raw['raw'] = search[key];
          }
      }
      const queryString = convertJsonToQueryString({...dataSearch, ...raw});
    props.history.push({
      search: queryString
    });
  };

  const onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    setDataSearch({ ...dataSearch, [name]: value });
  };

  const onChangeDataSearchType = (e) => {
    setDataSearch({ ...dataSearch, 'type': e });
  };

  const onChangeDataSearchProviderId = (e) => {
    setDataSearch({ ...dataSearch, 'provider_id': e });
  };
  const onChangeDataSearchVersionTokenId = (e) => {
    setDataSearch({ ...dataSearch, 'version_token_id': e });
  }
  const fetchDataFunction = async () => {
    const service = new WhTokenService();
    const queryString = queryStringToJSON(props.location.search);
    try {
      const result = await service.getWhTokens({...dataSearch,...queryString});
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  const fetchProviderOption = async () => {
    const service = new ProviderCategoryService();
    const result = await service.all();
    const options = {};
    result.data.forEach(element => {
        options[element.id] = element.name;
    });
    setProviderList(options);
  };

  const fetchVersionTokenOption = async () => {
    const service = new VersionTokenCategoryService();
    const result = await service.all();
    const options = {};
    result.data.forEach(element => {
        options[element.id] = element.code;
    });
    setVersionTokenList(options);
  };

  useEffect(() => {
    fetchProviderOption();
    fetchVersionTokenOption();
  }, []);

  const onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickUpdate={record.status === 1 ? () => props.history.push(`/kho-token/cap-nhat/${record.id}`) : null}
        onClickView={() => props.history.push(`/kho-token/xem-chi-tiet/${record.id}`)}
        isDisplayUpdateButton={checkUpdateAble(record)}
      />
    );
  };

  const checkUpdateAble = (record: any): any => {
    if (record.total !== record.rest) return false;
    return true;
  };


  const renderTypeColumn = text => {
    return (
      <div>
        <Tag color={`${TYPE_LABEL_CLASS[text].class}`}>
          {TYPE_LABEL_CLASS[text].label}
        </Tag>{" "}
      </div>
    );
  };

  const onChangeSelectDateValid = (date, dateString) => {
    setDataSearch({ ...dataSearch, 'receive_date': dateString });
  };

  return (
    <div className="card">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <h4 className="card-title1">{TITLE}</h4>
      <div className="card-body">
        <form onSubmit={onSubmitSearchButton} className="search-form-index">
          <div className="input-group">
            <InputWithLabel
              name="text_search"
              wrapClass="col-md-2 form-group nopadding-left"
              onChangeValue={onChangeDataSearchInput}
              value={dataSearch.text_search}
              isRequired={false}
              label={""}
              placeholder={"Mã nhập kho"}
            />
            <SelectBox
              value={dataSearch.type}
              label=""
              onChange={onChangeDataSearchType}
              name="type"
              wrappedClass="col-md-1 form-group nopadding-left"
              options={TypeWhToken.TYPE}
              firstOptionLabel="Loại token"
            />
            <SelectBox
              label=""
              onChange={onChangeDataSearchProviderId}
              value={dataSearch.provider_id}
              name="provider_id"
              wrappedClass="col-md-2 form-group nopadding-left"
              options={providerList}
              firstOptionLabel="Nhà cung cấp"
            />
            <SelectBox
              label=""
              onChange={onChangeDataSearchVersionTokenId}
              value={dataSearch.version_token_id}
              name="version_token_id"
              wrappedClass="col-md-2 form-group nopadding-left"
              options={versionTokenList}
              firstOptionLabel="Vesion token"
            />
            <SelectDate
              label=""
              name="receive_date"
              wrappedClass="col-md-2 nopadding-left h-35"
              onChange={onChangeSelectDateValid}
              value={dataSearch.receive_date}
              isRequired={true}
              placeholder={'Ngày nhận'}
            />
            <div className="col-md-3 form-group">
              <SearchButton onClick={onSubmitSearchButton} />
              <GoToCreatePageButton
                toURL={`${props.match.url}/them-moi`}
                text="Nhập kho"
              />
              <GoToCreatePageButton
                toURL={`${props.match.url}/xuat-kho`}
                text="Xuất kho"
                icon="fas fa-file-export"
              />
            </div>
          </div>
        </form>
        <NewTable
          columns={[
            { title: "Mã nhập kho", dataIndex: "code" },
            { title: "Nhà cung cấp", dataIndex: "provider.name" },
            { title: "Version token", dataIndex: "version_token.code" },
            {
              title: "Loại token",
              dataIndex: "type",
              render: renderTypeColumn
            },
            { title: "Số lượng", dataIndex: "total" },
            { title: "Người giao", dataIndex: "deliver" },
            { title: "Người nhận", dataIndex: "receiver" },
            {
              title: "Ngày nhận",
              dataIndex: "receive_date",
              render: text => formatDateTime(text)
            },
            {
              title: "Tác vụ",
              render: onRenderActionColumn
            }
          ]}
          onFetchData={fetchDataFunction}
        />
      </div>
    </div>
  );
};

export default withNavbar()(VersionToken);
