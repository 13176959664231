import FetchAPI from "../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../helpers/constant/MethodAPIConstant";

class RoleService extends FetchAPI {
  public async getRoleList(type) {
    const result = await this.request(
      "/role-all",
      { type },
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async getRoles(data) {
    const result = await this.request(
      "/role",
      data,
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async deleteRoleById(id) {
    const result = await this.request(
      "role-delete",
      { id },
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async getRole(id) {
    const result = await this.request(
      "role-show",
      { id },
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async createRole(data) {
    const result = await this.request(
      "role",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

  public async updateRole(data) {
    const result = await this.request(
      "role-update",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }
}

export default RoleService;
