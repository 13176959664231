import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/yeu-cau-thay-doi-thong-tin-chung-thu-so";
class RequestChangeInfoCertificate extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            `${PREFIX_URL}/index`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getListUser() {
        const result = await this.request(
            `${PREFIX_URL}/getListUser`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async find(id, type) {
        const result = await this.request(
            `${PREFIX_URL}/find/${id}/${type}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getApproveGroup(id) {
        const result = await this.request(
            `${PREFIX_URL}/getApproveGroup/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getApprovePersonal(id) {
        const result = await this.request(
            `${PREFIX_URL}/getApprovePersonal/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getDeny(data) {
        const result = await this.request(
            `${PREFIX_URL}/getDeny`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getFile(id, type) {
        const result = await this.request(
            `${PREFIX_URL}/getFile/${id}/${type}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async denyChange(id, requestID) {
        const result = await this.request(
            `${PREFIX_URL}/denyChange/${id}/${requestID}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getFileDK02(id, object) {
        const result = await this.request(
            `${PREFIX_URL}/get-file-dk02/${id}/${object}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

}
export default RequestChangeInfoCertificate;
