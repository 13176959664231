import React from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import CateMailService from "../../../../services/mail-category/CateMailService";

interface FormValues {
    code: string;
    name: string;
    title: string;
    content: string;
    note: string;
}
interface OtherProps extends RouteComponentProps {
}

type Props = OtherProps & FormikProps<FormValues>;

const MailCategoryCreate: React.FC<Props> = props => {

    const {code, name, title, note} = props.values;
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Thêm mới mẫu mail</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="name"
                            label="Tên mail"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={name}
                            error={props.errors.name}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="code"
                            label="Mã mail"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="title"
                            label="Title"
                            wrapClass="col-md-6 form-group"
                            onChangeValue={props.handleChange}
                            value={title}
                            error={props.errors.title}
                            isRequired={true}
                            maxLength={255}
                        />
                    </div>
                    <div className="col-md-12">
                        <label>Nội dung mail</label>
                        <CKEditor
                            name ="content"
                            onChange={ ( event) => {
                                const data = event.editor.getData();
                                props.setFieldValue('content',data);
                            } }
                            config={ {
                                extraPlugins: 'lineheight,tableresize',
                            } }
                        />
                    </div>
                    <div className="input-group mt-4">
                        <InputWithLabel
                            name="note"
                            label="Mô tả"
                            wrapClass="col-md form-group"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                            maxLength={500}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            // url="/danh-muc-mail"
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        name: "",
        note: "",
        title: "",
        content:"",
    }),
    validationSchema: Yup.object().shape({
        code: Yup.string().required("Mã không thể bỏ trống."),
        name: Yup.string().required("Tên không thể bỏ trống."),
        title: Yup.string().required("Title không thể bỏ trống."),
        content: Yup.string().required("Nội dung mail không thể bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateMailService();
        const result = await service.store(values);
        if (result.status === 200) {
            onSuccessAction("Thêm mới thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(MailCategoryCreate);

export default withNavbar()(RenderCreateForm);
