import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../css/main.css";
interface Props {
  toURL: string;
  text?: string;
  css?: string;
  icon?: string;
  hidden ?: boolean;
}
interface State {}

export default class GoToCreatePageButton extends Component<Props, State> {
  state = {};

  render() {
    return (
      <Link to={this.props.toURL} className={this.props.css || 'btn btn-success ml-1 mr-1 font-btn'} hidden={this.props.hidden}>
        <i className={this.props.icon || "fas fa-plus mr-1"}></i>{this.props.text || 'Thêm mới'}
      </Link>
    );
  }
}
