import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class CateContractService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "admin/cau-hinh-chung-thu-so/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async getListTaxCode() {
        const result = await this.request(
            "admin/cau-hinh-chung-thu-so/getListTaxCode",
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async sendDataSignature(data) {
        const result = await this.request(
            "admin/cau-hinh-chung-thu-so/store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async delete(id) {
        const result = await this.request(
            "admin/cau-hinh-chung-thu-so/delete",
            {id},
            TypeMethodContsantEnum.DELETE
        );
        return result;
    }
}

export default CateContractService;
