import React, { useState, useEffect } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { onSuccessAction, onFailAction } from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import { RouteComponentProps } from "react-router-dom";
import InputTree from "../../../common/form/InputTree";
import RoleService from "../../../../services/role/RoleServices";
import PermissionService from "../../../../services/permission/PermissionService";
import { TypeRole } from "../Enum";

interface FormValues {
  display_name: string;
  name: string;
  type: string;
  description: string;
  permissions: object;
}

interface OtherProps
  extends RouteComponentProps<{
    id: string;
  }> {}

type Props = OtherProps & FormikProps<FormValues>;

const AssetCategoryCreate: React.FC<Props> = props => {
  const id = props.match.params.id;
  const { display_name, name, type, description,permissions } = props.values;
  const [arrPermission, setArrPermission] = useState({});

  const getPermissionList = async type => {
    const perService = new PermissionService();
    const lstPermission = await perService.getPermissionList(type);
    if (lstPermission) {
      setArrPermission(lstPermission.data);
    }
  };

  const onChangeSelectedType = e => {
    props.setFieldValue("type",e);
    getPermissionList(e);
    props.setFieldValue('permissions',[]);
  };

  const onChangePermission = e => {
    props.setFieldValue("permissions", e);
  };

  const onFetchData = async () => {
    const roleService = new RoleService();
    const role = await roleService.getRole(id);
    if (role && role.status === 200) {
      for (let key in role.data) {
        props.setFieldValue(key, role.data[key]);
        getPermissionList(role.data.type);
      }
      const per = role.key;
      props.setFieldValue("permissions", per);
    }
  };

  useEffect(() => {
    onFetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="card">
      <h4 className="card-title1 ml-3">Cập nhật vai trò</h4>
      <div className="card-body">
        <form>
          <div className="input-group">
            <InputWithLabel
              name="name"
              label="Tên vai trò"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={name}
              error={props.errors.name}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="display_name"
              label="Tên hiển thị"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={display_name}
              error={props.errors.display_name}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="description"
              label="Mô tả"
              wrapClass="col-md-6"
              onChangeValue={props.handleChange}
              value={description}
              error={props.errors.description}
              isRequired={false}
              maxLength={255}
            />
          </div>
          <div className="input-group">
            <SelectBox
              options={TypeRole.TYPE}
              label="Loại vai trò"
              name="type"
              wrappedClass="col-md-3"
              onChange={onChangeSelectedType}
              value={type}
              firstOptionLabel="Chọn loại vai trò"
              error={props.errors.type}
              isRequired={true}
            />
            <InputTree
              label="Quyền hạn"
              name="permissions"
              wrappedClass="col-md-9"
              firstOptionLabel="Chọn quyền hạn"
              isRequired={true}
              onChange={onChangePermission}
              value={permissions}
              options={arrPermission}
              error={props.errors.permissions}
            />
          </div>
          <div className="input-group d-flex justify-content-center p-5">
            <BackButton
              history={props.history}
              // url="/quan-ly-vai-tro"
            ></BackButton>
            <SubmitButton onClick={props.handleSubmit}></SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const RenderCreateForm = withFormik<Props, FormValues>({
  mapPropsToValues: (props: Props) => ({
    name: "",
    display_name: "",
    description: "",
    type: "",
    permissions: []
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên vai trò không được bỏ trống."),
    display_name: Yup.string().required("Tên hiển thị không được bỏ trống."),
    type: Yup.number().required("Loại vai trò không được bỏ trống."),
    permissions: Yup.string().required("Quyền hạn không được bỏ trống.")
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const data = new RoleService();
    const result = await data.updateRole(values);
    if (result) {
      if (result.status === 200) {
        props.history.goBack();
        onSuccessAction("cập nhật vai trò thành công!", function() {
          window.location.reload(false);
        });
      } else if (result.status === 1) {
        setErrors(result.errors);
      } else {
        onFailAction(result.errors);
      }
    }
  }
})(AssetCategoryCreate);

export default withNavbar()(RenderCreateForm);
