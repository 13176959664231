import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class RequestTrainingService extends FetchAPI{
    public async list(data) {
        const result = await this.request(
            "admin/yeu-cau-dao-tao/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getModel(id){
        const path = "admin/yeu-cau-dao-tao/getModel/"+id;
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    }
    public async approveRequest(id){
        const path = "admin/yeu-cau-dao-tao/approveRequest/"+id;
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    }
    public async denyRequest(data) {
        const path = "admin/yeu-cau-dao-tao/denyRequest";
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }
    public async getFullName(belong_to){
        const path = "admin/yeu-cau-dao-tao/getOwnerFullName/"+belong_to;
        const result = await this.request(path, {},TypeMethodContsantEnum.GET);
        return result;
    }
}

export default RequestTrainingService;
