import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {History} from "history";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import WhTokenServices from "../../../../services/whToken/WhTokenService";
import SelectBox from "../../../common/form/SelectBox";
import ProviderCategoryService from "../../../../services/ProviderCategory/ProviderCategoryService";
import VersionTokenCategoryService from "../../../../services/version-token/VersionTokenServices";
import Radio from "../../../common/form/Radio";
import {TypeWhToken} from "../Enum";
import InputFileUpload from "../../../common/form/InputFileUpload";
import {loading} from "../../../../components/common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";
import {Card} from "antd";
import SelectDate from "../../../common/form/SelectDate";

interface FormValues {
    note: string;
    provider_id: string;
    version_token_id: string;
    type: string;
    sn_start: string;
    sn_end: string;
    total: string;
    total_box: string;
    device_status: string;
    file: string;
    deliver: string;
    deliver_pos: string;
    deliver_phone: string;
    deliver_email: string;
    receiver: string;
    receiver_pos: string;
    receive_phone: string;
    receive_email: string;
    receive_date: string;
    sort: string;
}

interface OtherProps {
    history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const WhTokenCreate: React.FC<Props> = props => {
    const [providerList, setProviderList] = useState({});
    const [versionTokenList, setVersionTokenList] = useState({});
    const {
        note,
        provider_id,
        version_token_id,
        type,
        sn_start,
        sn_end,
        total,
        device_status,
        file,
        deliver,
        deliver_pos,
        deliver_phone,
        deliver_email,
        receiver,
        receiver_pos,
        receive_phone,
        receive_email,
        receive_date,
        sort,
    } = props.values;

    const fetchProviderOption = async () => {
        const service = new ProviderCategoryService();
        const result = await service.all();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setProviderList(options);
    };

    const fetchVersionTokenOption = async () => {
        const service = new VersionTokenCategoryService();
        const result = await service.all();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.code;
        });
        setVersionTokenList(options);
    };
    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            if (fileData) {
                props.setFieldValue("file", fileData.file);
            }
            loading.stopRunLoading();
        } else {
            props.setFieldValue("file", "");
        }
    };

    const onChangeSelectDateValid = (date, dateString) => {
        props.setFieldValue("receive_date", dateString);
    };

    useEffect(() => {
        fetchProviderOption();
        fetchVersionTokenOption();
    }, []);

    const handleChangeTotal = async (e) => {
        const value = e.target.value;
        const result = await new WhTokenServices().getLastCodeToken(value, sn_start);
        props.setFieldValue('sn_end', result);
    };

    const changeProvider = (e) => {
        props.setFieldValue('provider_id',e);
    };

    const changeVersionTokenId= (e) => {
        props.setFieldValue('version_token_id',e);
    };

    const handleChangeSnStart = async (e) => {
        const value = e.target.value;
        if (total){
            const result = await new WhTokenServices().getLastCodeToken(total, value);
            props.setFieldValue('sn_end', result);
        }
    };
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Nhập kho Token</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <SelectBox
                            name="provider_id"
                            options={providerList}
                            label="Nhà cung cấp"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn nhà cung cấp"
                            onChange={changeProvider}
                            value={provider_id}
                            error={props.errors.provider_id}
                            isRequired={true}
                        />
                        <SelectBox
                            name="version_token_id"
                            options={versionTokenList}
                            label="Version Token"
                            wrappedClass="col-md-2"
                            firstOptionLabel="Chọn version token"
                            onChange={changeVersionTokenId}
                            value={version_token_id}
                            error={props.errors.version_token_id}
                            isRequired={true}
                        />
                        <Radio
                            options={TypeWhToken.TYPE}
                            name="type"
                            label="Loại token"
                            wrappedClass="col-md-2"
                            onChange={props.handleChange}
                            value={type}
                            error={props.errors.type}
                            isRequired={true}
                            isDisabled={false}
                        />
                        <SelectDate
                            label="Ngày nhập kho"
                            name="receive_date"
                            wrappedClass="col-md-2"
                            onChange={onChangeSelectDateValid}
                            value={receive_date}
                            error={props.errors.receive_date}
                            isRequired={true}
                            placeholder={"yyyy-mm-dd"}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="sn_start"
                            label="Mã số thiết bị đầu tiên"
                            wrapClass="col-md-3 "
                            onChangeValue={props.handleChange}
                            value={sn_start}
                            error={props.errors.sn_start}
                            isRequired={true}
                            maxLength={255}
                            onBlur={handleChangeSnStart}
                        />
                        <InputWithLabel
                            name="sn_end"
                            label="Mã số thiết bị cuối cùng"
                            wrapClass="col-md-3 "
                            onChangeValue={props.handleChange}
                            value={sn_end}
                            error={props.errors.sn_end}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="total"
                            label="Tổng số thiết bị"
                            wrapClass="col-md-1 "
                            onChangeValue={props.handleChange}
                            value={total}
                            error={props.errors.total}
                            isRequired={true}
                            type={"number"}
                            onBlur={handleChangeTotal}
                            maxLength={4}
                        />
                        <InputWithLabel
                            name="device_status"
                            label="Tình trạng thiết bị"
                            wrapClass="col-md-3 "
                            onChangeValue={props.handleChange}
                            value={device_status}
                            error={props.errors.device_status}
                            isRequired={false}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="sort"
                            label="Thứ tự ưu tiên xuất kho"
                            wrapClass="col-md-2"
                            onChangeValue={props.handleChange}
                            value={sort}
                            error={props.errors.sort}
                            isRequired={false}
                            maxLength={255}
                            type={"number"}
                        />
                    </div>
                    <div className="input-group">
                        <InputFileUpload
                            name="file_name"
                            classWrapped="col-md-3 "
                            label="File bàn giao"
                            filename={file}
                            onChangeFileUpload={onChangeFile}
                            isRequired={true}
                            accept={"application/pdf, application/vnd.ms-excel"}
                            error={props.errors.file}
                        />
                        <InputWithLabel
                            name="note"
                            label="Mô tả"
                            wrapClass="col-md-9"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                            maxLength={500}
                        />
                    </div>
                    <Card className="m-r-15-i m-l-15-i" title="Thông tin người bàn giao">
                        <div className="input-group">
                            <InputWithLabel
                                name="deliver"
                                label="Người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver}
                                error={props.errors.deliver}
                                isRequired={true}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="deliver_pos"
                                label="Chức vụ người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_pos}
                                error={props.errors.deliver_pos}
                                isRequired={false}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="deliver_phone"
                                label="Số điện thoại người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_phone}
                                error={props.errors.deliver_phone}
                                isRequired={false}
                                maxLength={20}
                            />
                            <InputWithLabel
                                name="deliver_email"
                                label="Email người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_email}
                                error={props.errors.deliver_email}
                                isRequired={false}
                                maxLength={255}
                            />
                        </div>
                    </Card>
                    <Card className="m-r-15-i m-l-15-i mt-4" title="Thông tin người nhận">
                        <div className="input-group m-t-10">
                            <InputWithLabel
                                name="receiver"
                                label="Người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver}
                                error={props.errors.receiver}
                                isRequired={true}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="receiver_pos"
                                label="Chức vụ người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_pos}
                                error={props.errors.receiver_pos}
                                isRequired={false}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="receive_phone"
                                label="Số điện thoại người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receive_phone}
                                error={props.errors.receive_phone}
                                isRequired={false}
                                maxLength={20}
                            />
                            <InputWithLabel
                                name="receive_email"
                                label="Email người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receive_email}
                                error={props.errors.receive_email}
                                isRequired={false}
                                maxLength={255}
                            />
                        </div>
                    </Card>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton history={props.history}
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        note: "",
        provider_id: "",
        version_token_id: "",
        type: "",
        sn_start: "",
        sn_end: "",
        total: "",
        total_box: "",
        device_status: "",
        file: "",
        deliver: "",
        deliver_pos: "",
        deliver_phone: "",
        deliver_email: "",
        receiver: "",
        receiver_pos: "",
        receive_phone: "",
        receive_email: "",
        receive_date: "",
        sort: "",
    }),
    validationSchema: Yup.object().shape({
        provider_id: Yup.string().required("Nhà cung cấp không thể bỏ trống."),
        sn_start: Yup.string().required(
            "Mã số thiết bị đầu tiên không thể bỏ trống."
        ).min(12,'Mã token phải 12 ký tự'),
        sn_end: Yup.string().required(
            "Mã số thiết bị cuối cùng không thể bỏ trống."
        ),
        total: Yup.number().required("Tổng số thiết bị không thể bỏ trống."),
        version_token_id: Yup.string().required(
            "Version token không thể bỏ trống."
        ),
        type: Yup.string().required("Loại token không thể bỏ trống."),
        deliver: Yup.string().required("Người bàn giao không thể bỏ trống."),
        receiver: Yup.string().required("Người nhận không thể bỏ trống."),
        receive_date: Yup.date().required("Ngày nhập kho không được trống")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new WhTokenServices();
        const result = await service.createWhToken(values);
        if (result && result.status === 200) {
            onSuccessAction("Nhập kho token thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else if (result && result.status === 202){
            onFailAction(result.message)
        }else {
            onFailAction(result.error);
        }
    }
})(WhTokenCreate);

export default withNavbar()(RenderCreateForm);
