import React, { Component } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { match } from "react-router";
import { History, Location } from "history";
import CreateForm from "./CreateForm";


interface Props {
  history: History;
  location: Location;
  match: match;
}
interface State {
}

class CreatePage extends Component<Props, State> {
  state = {
  };

  onChangeInput = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    } as any);
  };

  render() {
    return (
      <div className="card">
        <h4 className="card-title1">Thêm mới lĩnh vực kinh doanh</h4>
        <div className="card-body">
          <CreateForm history={this.props.history}></CreateForm>
        </div>
      </div>
    );
  }
}

const createWithNavbar = withNavbar()(CreatePage);

export default createWithNavbar;
