import React, { Component } from "react";
import { Modal } from 'antd';
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import { Checkbox } from 'antd';

interface Props {
    handleCancel?: any;
    handleButton?: any;
    checkedList?: any;
    plainOptions?: any;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    error? : string;
    indeterminate? : boolean;
    checkAll? : boolean;
    onCheckAllChange ? : any;
}
interface State {
    visible: boolean;
}

const CheckboxGroup = Checkbox.Group;

export default class ModalSelect extends Component<Props, State> {

    componentDidMount() {
    }

    render() {

        return (
            <div>
                <Modal
                    title={this.props.title}
                    visible={this.props.visible}
                    onOk={this.props.handleButton}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Đồng ý"
                    cancelText="Đóng"
                    className="modal-lg"
                    width="auto"
                    closable={false}
                >
                    <>
                    <Checkbox indeterminate={this.props.indeterminate} onChange={this.props.onCheckAllChange } checked={this.props.checkAll}>
                        Check all
                    </Checkbox>
                    <CheckboxGroup options={this.props.plainOptions} value={this.props.checkedList} onChange={this.props.onChange} />
                    </>
                    <ValidateMessageError error={this.props.error}></ValidateMessageError>
                </Modal>
            </div>
        );
    }
}
