export enum StatusEnum {
    ACTIVE = "0",
    STOP = "1",
    DEACTIVE = "2"
}

export class TypeUser {
    static readonly TYPE = {
        1: "Admin",
        2: "Giám đốc",
        3: "Kế toán",
        4: "Nghiệp vụ",
        5: "Nhân viên kinh doanh",
        6: "Hỗ trợ kinh doanh",
        7: "Đại lý",
        8: "Cộng tác viên"
    };
    static readonly TYPE_SYSTEM = {
        1: "Admin",
        2: "QLBH",
    };
    static readonly badgeClass = {
        1: "badge-primary",
        2: "badge-primary",
        3: "badge-primary",
        4: "badge-primary",
        5: "badge-success",
        6: "badge-success",
        7: "badge-success",
        8: "badge-success"
    };
    static readonly LOGIN_TYPE = {
        1: "Bằng mật khẩu",
        2: "Chứng thư số"
    };
}

export const TYPE_LABEL_CLASS = {
    0: {
        label: "Hiệu lực",
        class: "badge-success"
    },
    1: {
        label: "Hết hiệu lực",
        class: "badge-danger"
    }
};

