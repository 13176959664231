import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class CustomerService extends FetchAPI{
    public async list(data){
        const path = "admin/khach-hang/index";
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }
    public async show(id,type){
        const path = "admin/khach-hang/show/"+id+'/'+type;
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    }
    public async getCert(uid, created_by){
        const path = "admin/khach-hang/getCert/"+uid+'/'+created_by;
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    };
    public async getInfo(id, type){
        const path = "admin/khach-hang/fetchData/"+id+'/'+type;
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    };
    public async getProvince(){
        const path = "admin/khach-hang/getProvince";
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    };
    public async getDistrictByProvince(provinceCode){
        const path = "admin/khach-hang/getDistrictByProvince";
        const result = await this.request(path, {provinceCode}, TypeMethodContsantEnum.GET);
        return result;
    };
    public async requestChangeInfoOrganization(data){
        const path = "admin/khach-hang/request-change-info-organization";
        const result = await this.request(path, data, TypeMethodContsantEnum.POST);
        return result;
    };
    public async requestChangeInfoPersonal(data){
        const path = "admin/khach-hang/request-change-info-personal";
        const result = await this.request(path, data, TypeMethodContsantEnum.POST);
        return result;
    };
    public async updateRequestChangeInfoOrganization(data){
        const path = "admin/khach-hang/update-change-group";
        const result = await this.request(path, data, TypeMethodContsantEnum.POST);
        return result;
    };
    public async updateRequestChangeInfoPersonal(data){
        const path = "admin/khach-hang/update-change-personal";
        const result = await this.request(path, data, TypeMethodContsantEnum.POST);
        return result;
    };
    public async getModelToUpdate(id, type){
        const path = "admin/khach-hang/get-model-to-update/"+id+'/'+type;
        const result = await this.request(path, {}, TypeMethodContsantEnum.GET);
        return result;
    };
}
export default CustomerService;
