import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import SelectDate from "../../../common/form/SelectDate";
import { History } from "history";
import * as Yup from "yup";
import ProvinceService from "../../../../services/province/ProvinceService";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";

interface Props {
    history: History;
}
interface State {
    fullname: string;
    short_name: string;
    code: string;
    start_date: string;
    end_date: string;
}
class CreateForm extends Component<InjectedFormikProps<Props, State>> {

    onChangeSelectStartDate = (date, dateString) => {
        this.props.setFieldValue("start_date", dateString);
    };

    onChangeSelectEndDate = (date, dateString) => {
        this.props.setFieldValue("end_date", dateString);
    };


    render() {
        const {
            fullname,
            short_name,
            code,
            start_date,
            end_date,
        } = this.props.values;

        return (
            <form>
                <div className="input-group">
                    <InputWithLabel
                        name="code"
                        label="Mã tỉnh thành"
                        wrapClass="col-md-4 form-group"
                        onChangeValue={this.props.handleChange}
                        value={code}
                        error={this.props.errors.code}
                        isRequired={true}
                    />
                    <InputWithLabel
                        name="short_name"
                        label="Tên viết tắt"
                        wrapClass="col-md-4 form-group"
                        onChangeValue={this.props.handleChange}
                        value={short_name}
                        error={this.props.errors.short_name}
                        isRequired={true}
                    />
                    <InputWithLabel
                        name="fullname"
                        label="Tên đầy đủ"
                        wrapClass="col-md-4 form-group"
                        onChangeValue={this.props.handleChange}
                        value={fullname}
                        error={this.props.errors.fullname}
                        isRequired={true}
                    />
                </div>
                <div className="input-group">
                    <SelectDate
                        label="Ngày bắt đầu hiệu lực"
                        name="start_date"
                        wrappedClass="col-md-3 form-group"
                        onChange={this.onChangeSelectStartDate}
                        value={start_date}
                        error={this.props.errors.start_date}
                        isRequired={false}
                    />
                    <SelectDate
                        label="Ngày hết hiệu lực"
                        name="end_date"
                        wrappedClass="col-md-3 form-group"
                        onChange={this.onChangeSelectEndDate}
                        value={end_date}
                        error={this.props.errors.end_date}
                        isRequired={false}
                    />
                </div>
                <div className="input-group d-flex justify-content-center p-5">
                    <BackButton history={this.props.history} url='/tinh-thanh'/>
                    <SubmitButton onClick={this.props.handleSubmit}/>
                </div>
            </form>
        );
    }
}

const RenderCreateForm = withFormik<Props, State>({
    mapPropsToValues: () => ({
        fullname: "",
        short_name: "",
        start_date: "",
        end_date: "",
        code: "",
    }),
    validationSchema: Yup.object().shape({
        fullname: Yup.string().required("Tên tỉnh thành không được bỏ trống."),
        code: Yup.string().required("Mã tỉnh thành không được bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
        setSubmitting(false);
        const user = new ProvinceService();
        const result = await user.createProvince(values);
        if (result) {
            if (result.status === 200) {
                onSuccessAction('Thêm mới tỉnh thành thành công!', function () {
                    props.history.goBack();
                })
            } else if (result.status === 1) {
                setErrors(result.errors);
            } else {
                onFailAction(result.errors);
            }
        }
    }
})(CreateForm);

export default RenderCreateForm;
