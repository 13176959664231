import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import swal from "sweetalert";
import { onFailAction } from "../../../common/swal/SwalCommon";
import CateService from "../../../../services/service/Service";
import { Type } from "../Enum";
import PackageService from "./../../../../services/package/PackageService";
import SelectBoxService from "./../../../common/form/SelectBoxAgency";
import Radio from "../../../common/form/Radio";
import { Input, Card } from "antd";

const { TextArea } = Input;

interface Props {
  id: string;
  history: History;
}
interface State {
  name: string;
  code: string;
  type: string;
  year: string;
  month_sale: string;
  day_sale: string;
  description: string;
  price: string;
  object: string;
  provider_id: string;
  services: object;
  type_device: string;
  valid: string;
  entity_certificate_profile: string;
  certificate_profile_name: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    type: "",
    type_device: "",
    valid: "",
    entity_certificate_profile: "",
    certificate_profile_name: "",
    list_type: Type.TYPE,
    list_type_device: Type.TYPE_DEVICE,
    list_valid: Type.VALID,
    services: {},
    entity_certificate_profile_list: {},
    certificate_profile_name_list: {},
  };

  componentDidMount() {
    this.getListService().then();
    this.getDataUpdate().then();
    this.getEntityCertificateProfile().then();
  }

  //Lấy dữ liệu  dịch vụ
  getEntityCertificateProfile = async () => {
    const service = new PackageService();
    const lst = await service.getEntityCertificateProfile();
    if (lst) {
      let entity_certificate_profile = {};
      lst.data.map(
        (value) => (entity_certificate_profile[value.id] = value.name)
      );
      this.setState({
        entity_certificate_profile_list: entity_certificate_profile,
      });
    }
  };

  getListService = async () => {
    const service = new CateService();
    const lst = await service.getAll();
    if (lst) {
      let services = {};
      lst.data.map((value) => (services[value.id] = value.name));
      this.setState({ services });
    }
  };

  getDataUpdate = async () => {
    const service = new PackageService();
    const result = await service.getPackage(this.props.id);
    if (result && result.status === 200) {
      const {
        name,
        code,
        type,
        year,
        month_sale,
        day_sale,
        description,
        price,
        object,
        provider_id,
        type_device,
        valid,
        entity_certificate_profile,
        certificate_profile_name,
      } = result.data;
      this.props.setValues({
        name,
        code,
        year,
        month_sale,
        day_sale,
        description,
        price,
        type,
        object,
        provider_id,
        type_device,
        valid,
        entity_certificate_profile,
        certificate_profile_name,
      } as Pick<State, keyof State>);
    }
  };

  handleChangeBoxService = (e) => {
    this.props.setFieldValue("object", e);
  };
  handleChangeBoxNCC = (e) => {
    this.props.setFieldValue("provider_id", e);
  };
  handleChangePrice = (e) => {
    let valueChangeStr = e.target.value;
    let valueChangeNumber = parseInt(valueChangeStr);

    if (valueChangeStr.length > 9 || valueChangeNumber <= 0) return;
    this.props.setFieldValue("price", valueChangeStr);
  };
  handleChangeEntityCertificateProfile = async (e) => {
    const listEntity = this.state.entity_certificate_profile_list;
    let val = "";
    Object.keys(listEntity).forEach(function (key) {
      if (Number(key) === Number(e)) {
        val = listEntity[key];
      }
    });
    this.props.setFieldValue("entity_certificate_profile", val);
    const service = new PackageService();
    const listCertProfileName = await service.getCertificateProfileName(e);
    if (listCertProfileName) {
      let certificate_profile_name = {};
      listCertProfileName.data.map(
        (value) => (certificate_profile_name[value.id] = value.name)
      );
      this.setState({
        certificate_profile_name_list: certificate_profile_name,
      });
    }
    this.props.setFieldValue("certificate_profile_name", "");
  };
  handleChangeCertificateProfileName = async (e) => {
    const listEntity = this.state.certificate_profile_name_list;
    let val = "";
    Object.keys(listEntity).forEach(function (key) {
      if (Number(key) === Number(e)) {
        val = listEntity[key];
      }
    });
    this.props.setFieldValue("certificate_profile_name", val);
  };

  render() {
    const {
      name,
      code,
      year,
      month_sale,
      day_sale,
      description,
      price,
      type,
      object,
      provider_id,
      type_device,
      valid,
      entity_certificate_profile,
      certificate_profile_name,
    } = this.props.values;

    return (
      <Card
        className="m-r-15-i m-l-15-i"
        title={
          <label className={"h4 text-uppercase"}>Cập nhật gói dịch vụ</label>
        }
        size="default"
      >
        <form>
          <div className="input-group">
            <Radio
              options={this.state.list_type}
              name="type"
              label="Loại gói"
              wrappedClass="col-md-3"
              onChange={this.props.handleChange}
              value={type}
              error={this.props.errors.type}
              isRequired={true}
              isDisabled={false}
            />
            <InputWithLabel
              name="name"
              label="Tên gói dịch vụ"
              wrapClass="col-md-1"
              onChangeValue={this.props.handleChange}
              value={name}
              error={this.props.errors.name}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="code"
              label="Mã gói dịch vụ"
              wrapClass="col-md-2"
              onChangeValue={this.props.handleChange}
              value={code}
              error={this.props.errors.code}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="price"
              label="Giá gói dịch vụ (VNĐ)"
              wrapClass="col-md-2"
              onChangeValue={this.handleChangePrice}
              min={0}
              max={999999999}
              value={price}
              error={this.props.errors.price}
              isRequired={true}
              type="number"
            />
            <SelectBoxService
              options={this.state.entity_certificate_profile_list}
              label="Entity Certificate Profile"
              name="entity_certificate_profile"
              wrappedClass="col-md-2"
              onChange={this.handleChangeEntityCertificateProfile}
              value={entity_certificate_profile}
              firstOptionLabel="Chọn Entity Certificate Profile"
              error={this.props.errors.entity_certificate_profile}
              isRequired={true}
            />
            <SelectBoxService
              options={this.state.certificate_profile_name_list}
              label="Certificate Profile Name"
              name="certificate_profile_name"
              wrappedClass="col-md-2"
              onChange={this.handleChangeCertificateProfileName}
              value={certificate_profile_name}
              firstOptionLabel="Chọn Certificate Profile Name"
              error={this.props.errors.certificate_profile_name}
              isRequired={true}
            />
          </div>
          <div className="input-group">
            <Radio
              options={this.state.list_type_device}
              name="type_device"
              label="Loại thiết bị đầu cuối thuê bao"
              wrappedClass="col-md-2"
              onChange={this.props.handleChange}
              value={type_device}
              error={this.props.errors.type_device}
              isRequired={true}
              isDisabled={false}
            />
            <SelectBoxService
              options={{
                1: "Cá nhân",
                2: "Cá nhân thuộc tổ chức",
                3: "Tổ chức",
              }}
              label="Đối tượng"
              name="object"
              wrappedClass="col-md-2"
              onChange={this.handleChangeBoxService}
              value={object}
              firstOptionLabel="Lựa chọn đối tượng"
              error={this.props.errors.object}
              isRequired={true}
            />
            <SelectBoxService
              options={{
                1: "FastCA",
                2: "Newtel",
              }}
              label="Nhà cung cấp"
              name="provider_id"
              wrappedClass="col-md-2"
              onChange={this.handleChangeBoxNCC}
              value={provider_id}
              firstOptionLabel="Lựa chọn NCC"
              error={this.props.errors.provider_id}
              isRequired={true}
            />
            <InputWithLabel
              name="year"
              label="Năm sử dụng gói dịch vụ"
              wrapClass="col-md-2"
              onChangeValue={this.props.handleChange}
              value={year}
              error={this.props.errors.year}
              type={"number"}
            />
            <InputWithLabel
              name="month_sale"
              label="Khuyến mãi tháng(nếu có)"
              wrapClass="col-md-2"
              onChangeValue={this.props.handleChange}
              value={month_sale}
              error={this.props.errors.month_sale}
              type={"number"}
            />
            <InputWithLabel
              name="day_sale"
              label="Khuyến mãi ngày(nếu có)"
              wrapClass="col-md-2"
              onChangeValue={this.props.handleChange}
              value={day_sale}
              error={this.props.errors.day_sale}
              type={"number"}
            />
          </div>
          <div className="input-group">
            <Radio
              options={this.state.list_valid}
              name="valid"
              label="Hiệu lực"
              wrappedClass="col-md-3"
              onChange={this.props.handleChange}
              value={valid}
              error={this.props.errors.valid}
              isRequired={true}
              isDisabled={false}
            />
          </div>
          <div className="col-md-12">
            <label>Mô tả</label>
            <TextArea
              name="description"
              placeholder="Ghi chú"
              autoSize={{ minRows: 4, maxRows: 10 }}
              className="form-control"
              required={true}
              value={description}
              onChange={this.props.handleChange}
            />
          </div>
          <div className="input-group d-flex justify-content-center p-5">
            <BackButton
              history={this.props.history}
              // url="/goi-dich-vu"
            />
            <SubmitButton onClick={this.props.handleSubmit} />
          </div>
        </form>
      </Card>
    );
  }
}

const Form = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: "",
    code: "",
    type: "",
    year: "",
    month_sale: "",
    day_sale: "",
    object: "",
    provider_id: "",
    description: "",
    price: "",
    services: [],
    type_device: "",
    valid: "",
    entity_certificate_profile: "",
    certificate_profile_name: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên dịch vụ không được bỏ trống."),
    code: Yup.string().required("Mã dịch vụ không được bỏ trống."),
    type: Yup.string().required("Loại gói không được bỏ trống."),
    price: Yup.number().required(
      "Giá gói dịch vụ không thể bỏ trống, hoặc không đúng định dạng."
    ),
    type_device: Yup.string().required(
      "Loại thiết bị đầu cuối thuê bao không được bỏ trống."
    ),
    valid: Yup.string().required("Hiệu lực không được bỏ trống."),
    object: Yup.string().required("Đối tượng không được bỏ trống."),
    provider_id: Yup.string().required("Nhà cung cấp không được bỏ trống."),
    entity_certificate_profile: Yup.string().required(
      "Entity Certificate Profile không được bỏ trống."
    ),
    certificate_profile_name: Yup.string().required(
      "Certificate Profile Name không được bỏ trống."
    ),
  }),
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new PackageService();
    const result = await service.updatePackage({
      ...values,
      permissions: "",
      id: props.id,
    });
    if (result && result.status === 200) {
      swal({
        title: "Thành công!",
        text: "Cập nhật thành công!",
        icon: "success",
        dangerMode: false,
      }).then(function () {
        props.history.goBack();
      });
    } else if (result && result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction(result.error);
    }
  },
})(UpdateForm);

export default Form;
