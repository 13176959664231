import React, { Component } from "react";
import { History } from "history";
import "../css/main.css";
interface Props {
  history: History;
  name: string;
  id?: string;
  type?: string;
  onClick?: any;
}
interface State {}

export default class PreviewButton extends Component<Props, State> {
  state = {};

  onBackButton = e => {
    e.preventDefault();
    this.props.onClick();
  };

  render() {
    return (
      <button
        className="btn-submit-form btn btn-primary ml-1 mr-1 p-b-b-3"
        onClick={this.onBackButton}
      >
        <i className="fas fa-eye mr-1"></i>
        {this.props.name}
      </button>
    );
  }
}
