import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class DataWhService extends FetchAPI {
    public async getToken(data) {
        const result = await this.request(
            "admin/du-lieu-kho/get-token",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async getDocument(data) {
        const result = await this.request(
            "admin/du-lieu-kho/get-document",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async getAsset(data) {
        const result = await this.request(
            "admin/du-lieu-kho/get-asset",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getAgencyContributor(type){
        const result = await this.request(
            "admin/du-lieu-kho/getAgency",
            {type},
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getAgencyContributorByRequest(type){
        const result = await this.request(
            "admin/du-lieu-kho/getAgencyByRequest",
            {type},
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getRequestByOwner(id){
        const result = await this.request(
            "admin/du-lieu-kho/getRequestByOwner",
            {id},
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async listTokenDetail(data){
        const result = await this.request(
            "admin/du-lieu-kho/listTokenDetail",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
}

export default DataWhService;
