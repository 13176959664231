import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString,
    queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import RequestCertificateService from "../../../services/request-certificate/RequestCertificateService";
import TableActionButton from "../user/table/action-button/TableActionButton";
import { Tag } from "antd";
import moment from "moment";
import SelectDate from "../../common/form/SelectDate";
interface Props extends RouteComponentProps {
}

const TITLE = "Yêu cầu chứng thư số";

const RequestCertificate: React.FC<Props> = props => {
    const service = new RequestCertificateService();
    const [dataSearch, setDataSearch] = useState({created_by: "", object: "", fullname:"", tax_code:"", created_at_from:"", created_at_to:"", package_id:"", status: "", provider_id: ""});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at_from: dateString });
    };
    const onChangeSelectDateToValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at_to: dateString });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const onChangeDataSearchCreatedBy = (e) => {
        setDataSearch({...dataSearch, 'created_by': e})
    };
    const onChangeDataSearchPackageId = (e) => {
        setDataSearch({...dataSearch, 'package_id': e})
    };
    const onChangeDataSearchObject = (e) => {
        setDataSearch({...dataSearch, 'object': e})
    };
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, 'status': e})
    };
    const onChangeDataSearchProvider = (e) => {
        setDataSearch({...dataSearch, 'provider_id': e})
    };
    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const [listSaleId, setListSaleId] = useState({});
    useEffect(() => {
        getListSaleUser();
        getServicePackage();
        // eslint-disable-next-line
    }, []);
    const getListSaleUser = async () => {
        const result = await service.getListSaleUser();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setListSaleId(options);
    };

    const [listPackage, setListPackage] = useState({});
    const getServicePackage = async () => {
        const result = await service.getServicePackage();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setListPackage(options);
    };
    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickView={() =>
                    (
                        Number(record.type_request) === 1
                            ? props.history.push(`/yeu-cau-chung-thu-so/to-chuc/${record.id}`)
                            : props.history.push(`/yeu-cau-chung-thu-so/ca-nhan/${record.id}`)
                    )
                }
            />
        );
    };
    const formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        } else {
            return "";
        }
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <div className="input-group">
                            <InputWithLabel
                                name="fullname"
                                wrapClass="col-md-2 form-group nopadding-left"
                                onChangeValue={onChangeDataSearchInput}
                                value={dataSearch.fullname}
                                isRequired={false}
                                label={""}
                                placeholder="Tên khách hàng"
                            />
                            <InputWithLabel
                                name="tax_code"
                                wrapClass="col-md-2 form-group nopadding-left"
                                onChangeValue={onChangeDataSearchInput}
                                value={dataSearch.tax_code}
                                isRequired={false}
                                label={""}
                                placeholder="Mã số DN hoặc CMND"
                            />
                            <SelectBox
                                name="created_by"
                                options={listSaleId}
                                label=""
                                wrappedClass="col-md-2 nopadding-left"
                                firstOptionLabel="Người quản lý"
                                onChange={onChangeDataSearchCreatedBy}
                                value={dataSearch.created_by}
                            />
                            <SelectBox
                                name="package_id"
                                options={listPackage}
                                label=""
                                wrappedClass="col-md-2 nopadding-left"
                                firstOptionLabel="Gói dịch vụ"
                                onChange={onChangeDataSearchPackageId}
                                value={dataSearch.package_id}
                            />
                            <SelectBox
                                name="object"
                                options={{
                                    1: "Cấp mới",
                                    2: "Gia hạn",
                                    3: "Chuyển đổi"
                                }}
                                label=""
                                wrappedClass="col-md-2 nopadding-left"
                                firstOptionLabel="Đối tượng"
                                onChange={onChangeDataSearchObject}
                                value={dataSearch.object}
                            />
                            <div className="col-md-2 form-group nopadding-left">
                                <SearchButton onClick={onSubmitSearchButton}/>
                            </div>
                        </div>
                        <div className="input-group">
                            <SelectBox
                                name="status"
                                options={{
                                    2: "Chờ duyệt",
                                    3: "Nghiệp vụ từ chối",
                                    4: "Nghiệp vụ đã duyệt",
                                    5: "Đã sinh chứng thư số",
                                    6: "Chờ gen",
                                    7: "CA từ chối",
                                    8: "Đã khóa"
                                }}
                                label=""
                                wrappedClass="col-md-2 nopadding-left"
                                firstOptionLabel="Trạng thái"
                                onChange={onChangeDataSearchStatus}
                                value={dataSearch.status}
                            />
                            <SelectBox
                                name="provider_id"
                                options={{
                                    1: "FastCA",
                                    2: "NewTel"
                                }}
                                label=""
                                wrappedClass="col-md-2 nopadding-left"
                                firstOptionLabel="Nhà cung cấp"
                                onChange={onChangeDataSearchProvider}
                                value={dataSearch.provider_id}
                            />
                            <SelectDate
                                label=""
                                name="created_at_from"
                                wrappedClass="col-md-2 form-group nopadding-left"
                                onChange={onChangeSelectDateValid}
                                value={dataSearch.created_at_from}
                                isRequired={true}
                                placeholder="Ngày tạo - Từ ngày"
                            />
                            <SelectDate
                                label=""
                                name="created_at_to"
                                wrappedClass="col-md-2 form-group nopadding-left"
                                onChange={onChangeSelectDateToValid}
                                value={dataSearch.created_at_to}
                                isRequired={true}
                                placeholder="Ngày tạo - Đến ngày"
                            />
                        </div>

                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Mã yêu cầu",
                            dataIndex: "code",
                        },
                        {
                            title: "Tên khách hàng",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.organization) return e.organization.fullname;
                                    if (e.requestpersonal) return e.requestpersonal.fullname;
                                }
                            }
                        },
                        {
                            title: "CMND/Mã số DN",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.organization){
                                        return e.organization.code;
                                    }
                                    if (e.requestpersonal) {
                                        return e.requestpersonal.passport;
                                    }
                                }
                            }
                        },
                        {
                            title: "Đối tượng",
                            dataIndex: "",
                            render: (e) => {
                                if(e.object === 1){
                                    return (<Tag color="green">Cấp mới</Tag>);
                                }
                                if(e.object === 2){
                                    return (<Tag color="volcano">Gia hạn</Tag>);
                                }
                                if(e.object === 3){
                                    return (<Tag color="geekblue">Chuyển đổi</Tag>);
                                }
                            }
                        },
                        {title: "Gói dịch vụ", dataIndex: "cateservicespackage.name"},
                        {
                            title: "Người tạo",
                            dataIndex: "",
                            render : (e) => {
                                if(e){
                                    return e.user.fullname
                                }
                            }
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "",
                            render: (e) => formatDateTime(e.created_at)
                        },
                        {
                            title: "Người duyệt yêu cầu",
                            dataIndex: "",
                            render : (e) => {
                                if(Number(e.status) === 4 || Number(e.status) === 5 || Number(e.status) === 6){
                                    if (Number(e.provider_id) === 1){
                                        return "Nhà cung cấp đã duyệt";
                                    }else {
                                        return e.user_approve.fullname;
                                    }
                                }
                            }
                        },
                        {
                            title: "Người quản lý",
                            dataIndex: "",
                            render : (e:  any) =>{
                                if(e) {
                                    if(e.user.type === 5) return e.user?.fullname;
                                    if(e.user.type === 7) return e.user?.agency?.user?.fullname;
                                    if(e.user.type === 8) return e.user?.contributor?.user?.fullname;
                                }
                            }
                        },
                        {
                            title: "Loại yêu cầu",
                            dataIndex: "",
                            render: (e) => {
                                if(e.type_request === 1){
                                    return (<Tag color="cyan">Tổ chức</Tag>);
                                }
                                if(e.type_request === 2){
                                    return (<Tag color="blue">Cá nhân</Tag>);
                                }
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: (e) => {
                                if(e.status === 2){
                                    return (<Tag color="orange">Chờ duyệt</Tag>);
                                }
                                if(e.status === 3){
                                    return (<Tag color="red">Nghiệp vụ từ chối</Tag>);
                                }
                                if(e.status === 4){
                                    return (<Tag color="geekblue">Nghiệp vụ đã duyệt</Tag>);
                                }
                                if(e.status === 5){
                                    return (<Tag color="green">Đã sinh chứng thư số</Tag>);
                                }
                                if(e.status === 6){
                                    return (<Tag color="blue">Chờ gen</Tag>);
                                }
                                if(e.status === 7){
                                    return (<Tag color="red">CA từ chối</Tag>);
                                }
                                if(e.status === 8){
                                    return (<Tag color="maroon">Đã khóa</Tag>);
                                }
                            }
                        },
                        {
                            title: "NCC",
                            dataIndex: "",
                            render: (e) => {
                                if(e.provider_id === 1){
                                    return (<Tag color="blue">FastCA</Tag>);
                                }
                                else{
                                    return (<Tag color="red">NewTel</Tag>);
                                }
                            }
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(RequestCertificate);
