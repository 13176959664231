import React, { useEffect, useState } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import { FormikProps, withFormik } from "formik";
import InputWithLabel from "../../common/form/InputWithLabel";
import BackButton from "../../common/button/BackButton";
import SubmitButton from "../../common/form/SubmitButton";
import SelectBox from "../../common/form/SelectBox";
import { RouteComponentProps } from "react-router-dom";
import CompensationRequestService from "../../../services/compensation/CompensationRequestService";
import { Card } from "antd";
import { formatDateTimeAll } from "../../../helpers/NewCaHelper";
import { onFailAction, onSuccessAction } from "../../../helpers/SwalCommon";
import DenyButton from "../../common/button/DenyButton";
import ModalDeny from "../../common/form/ModalDeny";
import TableNoFooter from "../../common/table/TableListCert";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface FormValues {
    cert_begin: string;
    cert_end: string;
    owner: string;
    uid: string;
    serial_number: string;
    token_code: string;
    request_time: string;
    contact_name: string;
    contact_phone: string;
    contact_email: string;
    file_dk01_signed: string;
    type_customer: number;
    type_file_legal: string;
    file_passport: string;
    file_legal: string;
    deputy_name: string;
    status: number;
    reason: string;
    contact_position: string;
    file_dk02: string;
    link_send_mail: string;
    approve_at: string;
    data_cert: object;
    approve_by: string;
    updated_at: string;
    agency_data: object;
    approveUser: object;
}

interface OtherProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const CompensationRequestDetail: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [linkReSign, setLinkReSign] = useState(false);
    const [link, setLink] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });
    //nó đnag lấy status ở đây 
    const { owner, uid, request_time,
        type_customer, type_file_legal, file_passport, file_legal, status, contact_email,
        contact_name, contact_phone, reason, contact_position, file_dk02, file_dk01_signed, link_send_mail, approve_at, updated_at
    } = props.values;
    const service = new CompensationRequestService();
    const onFetchData = async () => {
        const compensationRequest = await service.find(id);
        console.log(compensationRequest)
        for (let key in compensationRequest.data) {
            props.setFieldValue(key, compensationRequest.data[key]);
        }
        if (compensationRequest.data.file_dk02 && compensationRequest.data.number_signed !== 2){
            setLinkReSign(true);
            setLink(compensationRequest.domain+"/re-sign-dk02/" + btoa(id) +"/2")
            setSecretCode(compensationRequest.data['password']);
        }

        const infoCert = await service.getListCert(id);
        setDataCert(infoCert.data);

        const infoagency = await service.getAgencyData(compensationRequest.data['uid']);
        setagencyData(infoagency.data);

        if (compensationRequest.data['status'] === 3 || compensationRequest.data['status'] === 1 || compensationRequest.data['status'] === 4) {
            let param = "";
            if ((compensationRequest.data['status'] === 3 || compensationRequest.data['status'] === 1) && compensationRequest.data['approve_by'] !== "")
                param = compensationRequest.data['approve_by'];
            if (compensationRequest.data['status'] === 4 && compensationRequest.data['updated_by'] !== "")
                param = compensationRequest.data['updated_by'];
            console.log(param);
            if (param !== null) {
                const userApprove = await service.getApproveUser(param);
                console.log(userApprove);
                setapproveUser(userApprove);
                console.log(userApprove);
            }
        }


    };
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const changeType = (e) => {
        props.setFieldValue("customer_type", e)
    };
    const renderTypeFileLegal = (type_file_legal) => {
        const typeLegalPer = {
            1: 'Chứng minh nhân dân',
            2: 'Căn cước công dân',
            3: 'Hộ chiếu',
        };
        const typeLegalOrg = {
            1: 'Đăng ký kinh doanh',
            2: 'Giấy phép đầu tư',
            3: 'Quyết định thành lập',
        };
        let text = '';
        if (type_customer === 0 || type_customer === 1) {
            text = typeLegalPer[type_file_legal];
        } else {
            text = typeLegalOrg[type_file_legal];
        }
        return text;
    };
    const onViewFileDoc = type => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/yeu-cau-cap-bu/view-file-legal/${id}/${type}?token=${obj.token}`;
                window.open(link);
            }

        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    const submitApprove = async () => {
        const result = await service.approve(id);
        if (result && result.status === 200) {
            onSuccessAction("Duyệt thành công", props.history.goBack())
        }
    };
    const submitDeny = () => {
        setModalDeny({ ...modalDeny, 'visible_modal_deny': true });
    };
    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim().length === 0) {
            onFailAction('Lý do từ chối không được bỏ trống');
            return false;
        }
        const result = await service.deny({ id, text: modalDeny.text_deny });
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công", props.history.goBack());
        }
    };
    const handleModalDenyCancel = () => {
        setModalDeny({ ...modalDeny, 'visible_modal_deny': false })
    };
    const onChangeDataDeny = ({ target: { value } }) => {
        setModalDeny({ ...modalDeny, 'text_deny': value });
    };

    const [dataCert, setDataCert] = useState({});
    const changeDataCustomer = e => {
        let key = $(e.target)
            .parent()
            .parent()
            .parent()
            .data("row-key");
        let value = e.target.value;
        dataCert[key][$(e.target).data("key")] = value;
        setDataCert(dataCert);
        //props.setFieldValue("data_cert", dataCert);
    };
    const [approveUser, setapproveUser] = useState({});
    const changeapproveUser = e => {
        let key = $(e.target)
            .parent()
            .parent()
            .parent()
            .data("row-key");
        let value = e.target.value;
        approveUser[key][$(e.target).data("key")] = value;
        setDataCert(approveUser);
        //props.setFieldValue("approve_by", approveUser);
    };
    const [agencyData, setagencyData] = useState({});
    const changeagencyData = e => {
        let key = $(e.target)
            .parent()
            .parent()
            .parent()
            .data("row-key");
        let value = e.target.value;
        agencyData[key][$(e.target).data("key")] = value;
        setDataCert(agencyData);
        //props.setFieldValue("agency_data", agencyData);
    };

    const getKeyDownload = e => {
        let key = $(e.target)
        .parent()
        .parent()
        .parent()
        .data("row-key");
        //setKeyDelete(key);
        console.log(dataCert[key]['serial_number']);

    };
  const submitSendMailSuccess = async () => {

        const result = await service.resendMailSuccess(id,contact_email);
        if (result && result.status === 200) {
            onSuccessAction("Gửi mail thành công", props.history.goBack())
        }
    };
    const submitSendMailDeny = async () => {

        const result = await service.resendMailDeny(id,contact_email);
        if (result && result.status === 200) {
            onSuccessAction("Gửi mail thành công", props.history.goBack())
        }
    };
    const sendMailReSign = async () => {
        const dataSend = {
            id: id,
            link: link
        };
        const result = await service.sendMailReSign(dataSend);
        if (result && result.status === 200) {
            onSuccessAction("Gửi mail thành công", () => {
                props.history.goBack();
            })
        }
    };

    return (
        <div className="card">
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <form>
                <h4 className="card-title1 ml-3">Chi tiết yêu cầu cấp bù</h4>
                {status === 4 && <div className="input-group">
                    <InputWithLabel
                        name=""
                        label="Lý do từ chối"
                        wrapClass="col-md-12 form-group"
                        onChangeValue={props.handleChange}
                        value={reason}
                        maxLength={255}
                        isDisabled={true}
                    />
                </div>}
                {linkReSign ? (
                    <React.Fragment>
                        <div className="input-group">
                            <InputWithLabel
                                name={""}
                                label="Link ký lại DK02"
                                isDisabled={true}
                                wrapClass="col-md-6"
                                value={link}
                                onChangeValue={""}
                            />
                            <SubmitButton btnClass={"btn-submit-form btn btn-primary ml-1 mr-1 justify-content-center right"} onClick={sendMailReSign} text="Gửi mail ký lại" iconClass="fas fa-paper-plane" />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                name={""}
                                label="Mã xác thực"
                                isDisabled={true}
                                wrapClass="col-md-6"
                                value={secretCode}
                                onChangeValue={""}
                            />
                        </div>

                    </React.Fragment>


                ) : (
                    ""
                )}
                {((status === 1 || status === 4) && link_send_mail) && <div className="input-group">
                    <InputWithLabel
                        name=""
                        label="Link thực hiện"
                        wrapClass="col-md-12 form-group"
                        onChangeValue={props.handleChange}
                        value={link_send_mail}
                        maxLength={255}
                        isDisabled={true}
                    />
                </div>}
                <Card title="Thông tin yêu cầu cấp bù">
                    <div className="input-group">
                        <SelectBox
                            name="status"
                            options={{
                                1: "Đã duyệt",
                                2: "Chờ duyệt",
                                3: "Hoàn thành",
                                4: "Hủy"
                            }}
                            label="Trạng thái"
                            wrappedClass="col-md-3 form-group"
                            firstOptionLabel=""
                            onChange={changeType}
                            value={status === 1 ? "Đã duyệt" : status}
                            error={props.errors.status}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="uid"
                            label="Định danh"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={uid}
                            error={props.errors.uid}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="owner"
                            label="Khách hàng"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={owner}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="type_customer"
                            options={{
                                0: "Cá nhân",
                                1: "Cá nhân thuộc tổ chức",
                                2: "Tổ chức"
                            }}
                            label="Loại khách hàng"
                            wrappedClass="col-md-3 form-group"
                            firstOptionLabel=""
                            onChange={changeType}
                            value={type_customer === 0 ? "Cá nhân" : type_customer}
                            error={props.errors.type_customer}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                            {/* Đoạn này của thằng 2 */}
                        <InputWithLabel
                            name="request_time"
                            label="Ngày gửi yêu"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={formatDateTimeAll(request_time)}
                            isDisabled={true}
                        />
                        {(status === 4) &&
                            <InputWithLabel
                                name="updated_at"
                                label="Ngày cập nhật"
                                wrapClass="col-md-3 form-group"
                                onChangeValue={props.handleChange}
                                value={formatDateTimeAll(updated_at) ? updated_at : ""}
                                isDisabled={true}
                            />
                        }
                        {(status === 4) &&
                            <InputWithLabel
                                name="updated_by"
                                label="Người cập nhật"
                                wrapClass="col-md-3 form-group"
                                onChangeValue={changeapproveUser}
                                value={approveUser ? approveUser : ""}
                                isDisabled={true}
                            />
                        }
                        {(status === 1 || status === 3) &&
                            <InputWithLabel
                                name="approve_at"
                                label="Ngày được duyệt"
                                wrapClass="col-md-3 form-group"
                                onChangeValue={props.handleChange}
                                value={formatDateTimeAll(approve_at) ? approve_at : ""}
                                isDisabled={true}
                            />
                        }
                        {(status === 1 || status === 3) &&
                            <InputWithLabel
                                name="approve_by"
                                label="Người duyệt"
                                wrapClass="col-md-3 form-group"
                                onChangeValue={changeapproveUser}
                                value={approveUser ? "Auto" : "Auto"}
                                isDisabled={true}
                            />
                        }
                    </div>
                </Card>
                <Card title="Thông tin liên hệ">
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Tên"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={contact_name}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Chức vụ"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={contact_position}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="contact_email"
                            label="Email"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={contact_email}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            name=""
                            label="Số điện thoại"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={contact_phone}
                            isDisabled={true}
                        />

                    </div>
                </Card>
                <Card title="Thông tin chứng thư">
                    <div className="input-group">
                        <TableNoFooter
                            errors={props.errors}
                            data={dataCert}
                            changeData={changeDataCustomer}
                            //onDelete={onClickDeleteDoc}
                            keyDownload={getKeyDownload}
                            //changeSelectDate={}
                            isDisabled={true}
                            isHidden={true}
                        />
                    </div>
                </Card>
                <Card title="Thông tin đại lý">
                    <div className="input-group">
                        <InputWithLabel
                            name="account_name"
                            label="Tên"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={changeagencyData}
                            value={agencyData ? agencyData['account_name'] : ""}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="create_username"
                            label="Người tạo"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={changeagencyData}
                            value={agencyData ? agencyData['create_username'] : ""}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="request_month"
                            label="Tháng"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={changeagencyData}
                            value={agencyData ? agencyData['request_month'] : ""}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="request_year"
                            label="Năm"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={changeagencyData}
                            value={agencyData ? agencyData['request_year'] : ""}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card title="Hồ sơ">
                    <div className="input-group">
                        {
                            type_file_legal && <InputWithLabel
                                name=""
                                label="Loại giấy tờ pháp lý"
                                wrapClass="col-md-3 form-group"
                                onChangeValue={props.handleChange}
                                value={renderTypeFileLegal(type_file_legal)}
                                maxLength={255}
                                isDisabled={true}
                            />
                        }
                        {file_passport && <div className="col-md-2">
                            <label className="invisible">12121</label>
                            <button type="button" className="form-control btn btn-info ml-1 mr-1 p-b-b-3" onClick={() => onViewFileDoc(1)}>File CMND/CCCD/HC</button>
                        </div>}
                        {file_legal && <div className="col-md-2">
                            <label className="invisible">12121</label>
                            <button type="button" className="form-control btn btn-info ml-1 mr-1 p-b-b-3" onClick={() => onViewFileDoc(2)}>File giấy tờ pháp lý</button>
                        </div>}
                        {file_dk01_signed && <div className="col-md-2">
                            <label className="invisible">12121</label>
                            <button type="button" className="form-control btn btn-info ml-1 mr-1 p-b-b-3" onClick={() => onViewFileDoc(3)}>File DK01</button>
                        </div>}
                        {file_dk02 && <div className="col-md-2">
                            <label className="invisible">12121</label>
                            <button type="button" className="form-control btn btn-info ml-1 mr-1 p-b-b-3" onClick={() => onViewFileDoc(4)}>File DK02</button>
                        </div>}
                    </div>
                </Card>

                <div className="input-group d-flex justify-content-center p-5">
                    <BackButton history={props.history} />
                    {status === 2 &&
                        <React.Fragment>
                            <SubmitButton onClick={submitApprove} text="Duyệt" iconClass="fas fa-check" />
                            <DenyButton onClick={submitDeny} />
                        </React.Fragment>
                    }
                    {status === 3 &&
                        <React.Fragment>
                            <SubmitButton onClick={submitSendMailSuccess} text="Gửi lại mail hoàn thành" iconClass="fas fa-paper-plane" />
                        </React.Fragment>
                    }
                    {status === 4 &&
                        <React.Fragment>
                            <SubmitButton onClick={submitSendMailDeny} text="Gửi lại mail từ chối" iconClass="fas fa-paper-plane" />
                        </React.Fragment>
                    }
                </div>
            </form>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        cert_begin: '',
        cert_end: '',
        owner: '',
        uid: '',
        serial_number: '',
        token_code: '',
        request_time: '',
        contact_name: '',
        contact_phone: '',
        contact_email: '',
        file_dk01_signed: '',
        type_customer: 0,
        type_file_legal: '',
        file_passport: '',
        file_legal: '',
        deputy_name: '',
        status: 0,
        reason: '',
        link_send_mail: '',
        contact_position: '',
        file_dk02: '',
        approve_at: '',
        data_cert: {},
        approve_by: '',
        updated_at: '',
        agency_data: {},
        approveUser:{}
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
        setSubmitting(false);
    }
})(CompensationRequestDetail);

export default withNavbar()(RenderCreateForm);
