import React, { Component } from "react";
import "../css/main.css";
interface Props {
  onClick: any;
}
interface State {}

export default class ChangePasswordButton extends Component<Props, State> {
  state = {};

  render() {
    return (
      <button className="btn btn-primary mr-1 ml-1 font-btn" onClick={this.props.onClick}>
        <i className="fas fa-save mr-1"></i>Thay đổi mật khẩu
      </button>
    );
  }
}
