import React from "react";
import ActionButtonUpdate from "../../../../common/button/action/ActionButtonUpdate";
import ActionButtonDelete from "../../../../common/button/action/ActionButtonDelete";

interface Props {
  onClickUpdate: () => void;
  onClickLock: () => void;
  isDisplayUpdateButton?: boolean;
  isDisplayLockButton?: boolean;
}

export default function TableActionButton(props: Props) {
  return (
    <div className="row justify-content-center">
      {props.isDisplayUpdateButton ? (
        <ActionButtonUpdate onClick={props.onClickUpdate} />
      ) : (
        ""
      )}
      {props.isDisplayLockButton ? (
        <ActionButtonDelete onClick={props.onClickLock} />
      ) : (
        ""
      )}
    </div>
  );
}
