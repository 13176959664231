import React, { Component } from "react";
import { History } from "history";
import "../css/main.css";
interface Props {
  history: History;
}
interface State {}

export default class BackHome extends Component<Props, State> {
  state = {};

  onBackButton = e => {
    e.preventDefault();
    this.props.history.push('/');
  };

  render() {
    return (
      <button
        className="btn btn-primary ml-1 mr-1 font-btn"
        onClick={this.onBackButton}
      >
        <i className="fas fa-arrow-left mr-1"></i>
        Back Home
      </button>
    );
  }
}
