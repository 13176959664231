import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class FieldService extends FetchAPI{

    public async getFieldList(type){
        const result = await this.request("/field-all",{type},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getFields(data: object) {
        const path = "/cate/field"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteFieldById(id){
        const result =  await this.request("cate/field-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getField(id){
        const result = await this.request("cate/field-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createField(data){
        const result = await this.request("cate/field",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateField(data){
        const result = await this.request("cate/field-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
}

export default FieldService;