import React, {Component} from "react";
import {InjectedFormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import PreviewButton from "../../../common/button/PreviewButton";
import SubmitButton from "../../../common/form/SubmitButton";
import Radio from "../../../common/form/Radio";
import DenyButton from "../../../common/button/DenyButton";
import {History} from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import WrapperButtonInput from "../../../common/form/wrapper-button-form/wrapperButtonInput";
import ModalView from "../../../common/form/Modal";
import ModalDeny from "../../../common/form/ModalDeny";
import {onFailAction, onSuccessAction} from "../../../common/swal/SwalCommon";
import RequestTokenService from "../../../../services/requesttokenagency/RequestTokenService";
import {TYPE_RECEIVE, TYPE_PAY, Type} from "../Enum";
import {Input} from "antd";
import {checkPermission} from "./../../../../helpers/NewCaHelper";
import SelectBox from "./../../../common/form/SelectBox";

const {TextArea} = Input;

interface Props {
    id: string;
    history: History;
}

interface State {
    owner: any;
    user: any;
    agency: any;
    contributor: any;
    base64: string;
    token_admin_num: string;
    receive_type: string;
    type_pay: string;
    box_num: string;
    envelope_num: string;
    receive_fullname: string;
    receive_passport: string;
    receive_address: string;
    receive_phone: string;
    status: string;
    token_admin_note: string;
    receive_date: string;
    status_create_account: string;
    id: string;
    belong_to: string;
    owner_id: string;
}

const permission_confirm = "confirm-request-document";
const permission_create_account = "create-account-admin-agency";

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
    service = new RequestTokenService();
    state = {
        dataSearchDocument: {
            id: this.props.id,
            type: ""
        },
        visible_modal: false,
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: "",
        list_type_receive: {},
        list_type_pay: {},
        typeRequest: 0
    };

    componentDidMount() {
        this.getData();
        this.setState({list_type_receive: TYPE_RECEIVE});
        this.setState({list_type_pay: TYPE_PAY});
    }

    getData = async () => {
        const data = await this.service.getRequest(this.props.id);
        if (data && data.status === 200) {
            const {
                owner,
                user,
                agency,
                contributor,
                token_admin_num,
                receive_type,
                type_pay,
                box_num,
                envelope_num,
                receive_fullname,
                receive_passport,
                receive_address,
                receive_phone,
                status,
                token_admin_note,
                receive_date,
                status_create_account,
                id,
                belong_to,
                owner_id
            } = data.data;
            this.props.setValues({
                owner,
                user,
                agency,
                contributor,
                token_admin_num,
                receive_type,
                type_pay,
                box_num,
                envelope_num,
                receive_fullname,
                receive_passport,
                receive_address,
                receive_phone,
                status,
                token_admin_note,
                receive_date,
                status_create_account,
                id,
                belong_to,
                owner_id
            } as Pick<State, keyof State>);
        }
        this.setState({typeRequest: data.data.type})
    };

    onClickModalFile = tittle => {
        this.fetchDataFunction();

        this.setState({visible_modal: true, title_modal: tittle});
    };

    fetchDataFunction = async () => {
        const result = await this.service.getFile(this.props.id);
        if (result && result.status === 200) {
            this.setState({loading: true});
            this.setState({base64: result.data});
            this.setState({type_file: result.type_file});
            return result;
        } else {
            this.setState({base64: ""});
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra khi lấy file");
    };

    handleModalCancel = () => {
        this.setState({
            visible_modal: false
        });
    };

    submitApprove = async () => {
        const value = this.props.values;
        const self = this;
        const result = await this.service.getApprove({
            ...value,
            id: this.props.id
        });
        if (result && result.status === 200) {
            onSuccessAction("Duyệt yêu cầu token admin thành công!", () => {
                self.props.history.push("/kho-token/xuat-kho");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        }
    };

    handleModalDenyCancel = () => {
        this.setState({
            visible_modal_deny: false
        });
    };

    onChangeDataDeny = ({target: {value}}) => {
        this.setState({text_deny: value});
    };

    submitDeny = () => {
        this.setState({visible_modal_deny: true});
    };

    confirmDeny = async () => {
        const value = this.props.values;
        if (this.state.text_deny.replace(/\s+/g, '') === "") {
            this.setState({error_text_deny: "Lý do từ chối không được trống"});
            return false;
        }
        const self = this;
        const result = await this.service.getDeny({
            ...value,
            id: this.props.id,
            text_deny: this.state.text_deny
        });
        await this.setState({visible_modal_deny: false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối yêu cầu token admin thành công!", () => {
                self.props.history.push("/yeu-cau-token-cts");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.errors);
        }
    };

    checkTypeReceive = () => {
        const receive_type = this.props.values.receive_type + "";
        if (receive_type === "1") {
            return (
                <InputWithLabel
                    name="receive_passport"
                    label="Số chứng minh nhân dân"
                    wrapClass="col-md-3"
                    onChangeValue={this.props.handleChange}
                    value={this.props.values.receive_passport}
                    isRequired={true}
                    isDisabled={true}
                />
            );
        } else {
            return (
                <React.Fragment>
                    <InputWithLabel
                        name="receive_address"
                        label="Địa chỉ"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={this.props.values.receive_address}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="receive_phone"
                        label="Số điện thoại"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={this.props.values.receive_phone}
                        isRequired={true}
                        isDisabled={true}
                    />
                </React.Fragment>
            );
        }
    };

    getParamsAgency = agency_id => {
        const params = {
            type: 7,
            agency_id: agency_id
        };
        return params;
    };

    checkButtonsConfirm = () => {
        return !(!checkPermission(permission_confirm) || Number(this.state.typeRequest) === 1);
    };

    checkButtonCreateAccount = () => {
        return checkPermission(permission_create_account);
    };

    render() {
        const {
            owner,
            user,
            agency,
            contributor,
            token_admin_num,
            receive_type,
            type_pay,
            box_num,
            envelope_num,
            receive_fullname,
            status,
            token_admin_note,
            receive_date,
            belong_to
        } = this.props.values;
        return (
            <form onSubmit={this.props.handleSubmit}>
                {/*<InputWithLabel name='nvkd_id' label={''} wrapClass={''} onChangeValue={this.props.handleChange} value={user.id} hidden={true}/>*/}
                <div className="input-group">
                    {(belong_to) ? (
                        <SelectBox
                            options={Type.USERTYPE}
                            label="Loại người dùng"
                            name="belong_to"
                            wrappedClass="col-md-2 form-group"
                            value={belong_to}
                            firstOptionLabel="Chọn loại người dùng"
                            isRequired={true}
                            isDisabled={true}
                        />
                    ) : (
                        <SelectBox
                            options={Type.USERTYPE}
                            label="Loại người dùng"
                            name="belong_to"
                            wrappedClass="col-md-2 form-group"
                            value={agency ? 1 : 2}
                            firstOptionLabel="Chọn loại người dùng"
                            isRequired={true}
                            isDisabled={true}
                        />
                    )}

                    {Number(belong_to) === 3 ? (
                        <InputWithLabel
                            name="nvkd_name"
                            label="Tên đại lý/CTV/NVKD"
                            wrapClass="col-md-4"
                            onChangeValue={this.props.handleChange}
                            value={user.fullname}
                            isRequired={true}
                            isDisabled={true}
                        />
                    ) : (
                        (owner) ? (
                            <InputWithLabel
                                name="owner_name"
                                label="Tên đại lý/cộng tác viên"
                                wrapClass="col-md-4"
                                onChangeValue={this.props.handleChange}
                                value={owner.fullname}
                                isRequired={true}
                                isDisabled={true}
                            />
                        ) : (
                            <InputWithLabel
                                name="owner_name"
                                label="Tên đại lý/cộng tác viên"
                                wrapClass="col-md-4"
                                onChangeValue={this.props.handleChange}
                                value={agency.fullname || contributor.fullname}
                                isRequired={true}
                                isDisabled={true}
                            />
                        )


                    )}
                    {Number(belong_to) === 3 ? (
                        <InputWithLabel
                            name="tax_code"
                            label="Mã số thuế/CMND"
                            wrapClass="col-md-3"
                            onChangeValue={this.props.handleChange}
                            value={''}
                            isDisabled={true}
                        />
                    ) : (
                        (owner) ? (
                            <InputWithLabel
                                name="tax_code"
                                label="Mã số thuế/CMND"
                                wrapClass="col-md-3"
                                onChangeValue={this.props.handleChange}
                                value={owner.tax_code || owner.passport}
                                isRequired={true}
                                isDisabled={true}
                            />
                        ) : (
                            <InputWithLabel
                                name="tax_code"
                                label="Mã số thuế/CMND"
                                wrapClass="col-md-3"
                                onChangeValue={this.props.handleChange}
                                value={agency.tax_code || contributor.passport}
                                isRequired={true}
                                isDisabled={true}
                            />
                        )

                    )}

                    <InputWithLabel
                        name="token_admin_num"
                        label="Số lượng token"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={token_admin_num}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <Radio
                        options={this.state.list_type_receive}
                        name="receive_type"
                        label="Hình thức nhận"
                        wrappedClass="col-md-3"
                        onChange={this.props.handleChange}
                        value={receive_type}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <Radio
                        options={this.state.list_type_pay}
                        name="type_pay"
                        label="Hình thức thanh toán"
                        wrappedClass="col-md-3"
                        onChange={this.props.handleChange}
                        value={type_pay}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="box_num"
                        label="Số lượng vỏ hộp"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={box_num}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="envelope_num"
                        label="Số lượng phong bì"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={envelope_num}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>

                <div className="input-group">
                    <InputWithLabel
                        name="receive_fullname"
                        label="Tên người nhận"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={receive_fullname}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="receive_date"
                        label="Ngày nhận"
                        wrapClass="col-md-3"
                        value={receive_date}
                        onChangeValue={this.props.handleChange}
                        isRequired={true}
                        isDisabled={true}
                    />
                    {this.checkTypeReceive()}
                </div>
                <div className="input-group">
                    <div className="col-md-3">
                        <WrapperButtonInput>
                            <PreviewButton
                                name={"File Yêu cầu"}
                                history={this.props.history}
                                onClick={() => {
                                    this.onClickModalFile("File yêu cầu token admin");
                                }}
                            />
                        </WrapperButtonInput>
                    </div>
                    <div className="col-md-9">
                        <TextArea
                            placeholder="Ghi chú"
                            autoSize={{minRows: 4, maxRows: 10}}
                            className="form-control"
                            required={true}
                            value={token_admin_note}
                        />
                    </div>
                </div>

                <WrapperButtonDiv>
                    <BackButton history={this.props.history} url="/yeu-cau-token-cts"/>
                    {["2"].indexOf(status + "") > -1 && this.checkButtonsConfirm()
                        ? <DenyButton onClick={this.submitDeny}/>
                        : ""}
                    {(["2"].indexOf(status + "") > -1 && this.checkButtonsConfirm())
                        ? <SubmitButton onClick={this.submitApprove} text={" Duyệt"} iconClass={"fa fa-check-circle"}/>
                        : ""}
                </WrapperButtonDiv>

                <ModalView
                    visible={this.state.visible_modal}
                    handleCancel={this.handleModalCancel}
                    title={this.state.title_modal}
                    value={this.state.base64}
                    loading={this.state.loading}
                    type_file={this.state.type_file}
                />
                <ModalDeny
                    visible={this.state.visible_modal_deny}
                    handleCancel={this.handleModalDenyCancel}
                    handleDeny={this.confirmDeny}
                    value={this.state.text_deny}
                    onChange={this.onChangeDataDeny}
                    error={this.state.error_text_deny}
                />
            </form>
        );
    }
}

const userUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        owner: {},
        user: {},
        agency: {},
        contributor: {},
        base64: "",
        token_admin_num: "",
        receive_type: "",
        type_pay: "",
        box_num: "",
        envelope_num: "",
        receive_fullname: "",
        receive_passport: "",
        receive_address: "",
        receive_phone: "",
        status: "",
        token_admin_note: "",
        receive_date: "",
        status_create_account: "",
        id: "",
        belong_to: "",
        owner_id: ""
    }),
    validationSchema: Yup.object().shape({}),

    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
    }
})(UpdateForm);

export default userUpdateForm;
