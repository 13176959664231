import React, { Component } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import "../../common/css/dashboard.css";
import ListTopAgency from "./ListTopAgency"
import Card from "./Card"
import AmChartColumn from "./AmChartColumn"
import DonutChart from "./DonutChart"

interface Props { }
interface State {
}

class Home extends Component<Props, State> {
  state = {
  };

  async componentDidMount() {
  }

  render() {
    return (
    <div className="main-body">
      <div className="page-wrapper">
        <div className="page-body">
          <div className="row m-2">
            <div className="col-md-9">
              <Card/>
            </div>
            <div className="col-md-3">
              <div className="col-xl-12 p-0">
                <ListTopAgency/>
              </div>
            </div>
          </div>
          <div className="row m-2 mt-4">
            <div className="col-md-6">
              <div className ="card chart">
                <div className ="card-block">
                  <div className="card-feeds">
                    <div id={"columnChart"}></div>
                  </div>
                </div>
              </div>
              <AmChartColumn/>
            </div>
            <div className="col-md-6">
              <div className ="card chart">
                <div className ="card-block">
                  <div className="card-feeds">
                    <div id={"donutChart"}></div>
                  </div>
                </div>
              </div>
              <DonutChart/>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
const pageWithNavBar = withNavbar()(Home);

export default pageWithNavBar;
