import React, { useState, useEffect } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import VersionTokenCategoryService from "../../../../services/version-token/VersionTokenServices";
import { TypeWhToken } from "../Enum";
import { Input } from "antd";
import SelectDate from "../../../common/form/SelectDate";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import TableAddRowToken from "../../../common/table/TableAddRowToken";
import TableAddRowDocumentToken from "../../../common/table/TableAddRowDocumentToken";
import TableAddRowGift from "../../../common/table/TableAddRowGift";
import SelectBox from "../../../common/form/SelectBox";
import ClickButton from "./../../../common/button/ClickButton";
import AssetCategoryService from "../../../../services/asset-category/AssetCategoryService";
import { getDateCurrent } from "./../../../../helpers/NewCaHelper";
import WhTokenExportService from "./../../../../services/whTokenExport/WhTokenExportService";
import { onSuccessAction, onFailAction } from "../../../../helpers/SwalCommon";
import DataWhService from "./../../../../services/data-wh/DataWhService";
import Store from "../../../../store/Store";
import SelectBoxRequestCTS from './../../../common/form/SelectBoxRequestCTS';

const { TextArea } = Input;

interface FormValues {
  code: string;
  note: string;
  exporter: string;
  exporter_pos: string;
  type: string;
  location: string;
  receiver: string;
  export_date: string;
  created_date: string;
  owner_id: string;
  belong_to: string;
  dataToken: object;
  dataDoc: object;
  dataGift: object;
  request_id: string;
}

interface OtherProps {
  history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const WhTokenCreate: React.FC<Props> = props => {
  const [versionTokenList, setVersionTokenList] = useState({});
  const [agency, setAgency] = useState({});
  const [request, setRequest] = useState({});
  const [dataTokenState, setDataTokenState] = useState({});
  const [dataDocState, setDataDocState] = useState({});
  const [dataGiftState, setDataGiftState] = useState({});
  const [keyDelete, setKeyDelete] = useState({});
  const [assetCateDocList, setAssetCateDocList] = useState({});
  const [assetCateGiftList, setAssetCateGiftList] = useState({});
  const {
    code,
    note,
    location,
    receiver,
    export_date,
    owner_id,
    request_id,
    belong_to,
    dataToken,
    dataDoc,
    dataGift
  } = props.values;
  const getAgency = async type => {
    const service = new DataWhService();
    const lst = await service.getAgencyContributorByRequest(type);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      setAgency(agency);
      props.setFieldValue("owner_id", "");
    }
  };

  const fetchVersionTokenOption = async () => {
    const service = new VersionTokenCategoryService();
    const result = await service.all();
    const options = {};
    result.data.forEach(element => {
      options[element.id] = element.code;
    });
    setVersionTokenList(options);
  };

  const onChangeSelectDateExportValid = (date, dateString) => {
    props.setFieldValue("export_date", dateString);
    if (dateString) {
      fetchCodeExport(dateString);
    } else {
      fetchCodeExport(getDateCurrent("-"));
    }
  };

  const handleChangeBoxAgency = e => {
    props.setFieldValue("owner_id", e);
    getRequestByOwner(e)
  };
  const handleChangeBoxRequest = e => {
    props.setFieldValue("request_id", e);
  };

  const getRequestByOwner = async id => {
    const service = new DataWhService();
    const lst = await service.getRequestByOwner(id);
    if (lst) {
      let arr = {};
      lst.data.map(value => (arr[value.id] = `#${value.code}`));
      setRequest(arr);
      props.setFieldValue("request_id", "");
    }
  };


  const changeDataDoc = e => {
    let key = $(e.target)
      .parent()
      .parent()
      .parent()
      .data("row-key");

    let value = e.target.value;
    dataDocState[key][$(e.target).data("key")] = value;
    setDataDocState(dataDocState);
    props.setFieldValue("dataDoc", dataDocState);
    if (
      $(e.target).data("key") === "doc_asset_id"
    ) {
      showDocumentRest(key);
    }
  };

  const onClickDeleteDoc = () => {
    let newDataDelete = removeObjectByKey(dataDocState);
    const arr: string[] = [];
    $.each(newDataDelete, function(key, value) {
      if (value) {
        arr.push(value);
      }
    });
    setDataDocState(arr);
    props.setFieldValue("dataDoc", arr);
  };

  const changeDataGift = e => {
    let key = $(e.target)
      .parent()
      .parent()
      .parent()
      .data("row-key");

    let value = e.target.value;
    dataGiftState[key][$(e.target).data("key")] = value;
    setDataGiftState(dataGiftState);
    props.setFieldValue("dataGift", dataGiftState);
    if (
      $(e.target).data("key") === "gift_asset_id"
    ) {
      showGiftRest(key);
    }
  };

  const showDocumentRest = async key => {
    if (dataDocState[key]["doc_asset_id"]) {
      const service = new DataWhService();
      const result = await service.getDocument({
        asset_id: dataDocState[key]["doc_asset_id"]
      });
      if (result && result.status === 200) {
        dataDocState[key]["doc_rest"] = result.data;
      } else {
        dataDocState[key]["doc_rest"] = 0;
      }
    } else {
      dataDocState[key]["doc_rest"] = 0;
    }
    setDataDocState(dataDocState);
    props.setFieldValue("dataDoc", dataDocState);
  };

  const showGiftRest = async key => {
    if (dataGiftState[key]["gift_asset_id"]) {
      const service = new DataWhService();
      const result = await service.getAsset({
        asset_id: dataGiftState[key]["gift_asset_id"]
      });
      if (result && result.status === 200) {
        dataGiftState[key]["gift_rest"] = result.data;
      } else {
        dataGiftState[key]["gift_rest"] = 0;
      }
    } else {
      dataGiftState[key]["gift_rest"] = 0;
    }
    setDataGiftState(dataGiftState);
    props.setFieldValue("dataGift", dataGiftState);
  };

  const onClickDeleteGift = () => {
    let newDataDelete = removeObjectByKey(dataGiftState);
    const arr: string[] = [];
    $.each(newDataDelete, function(key, value) {
      if (value) {
        arr.push(value);
      }
    });
    setDataGiftState(arr);
    props.setFieldValue("dataGift", arr);
  };

  const onChangeSelectedType = (e) => {
    props.setFieldValue("belong_to", e);
    if (!e) {
      setAgency({});
    } else {
      let type = 0
      if (Number(e) === 1) type = 7
      else if (Number(e) === 2) type = 8
      else if (Number(e) === 3) type = 5
      getAgency(type);
    }
  };

  const showTokenRest = async key => {
    if (dataTokenState[key]["type"] && dataTokenState[key]["version_type"]) {
      const service = new DataWhService();
      const result = await service.getToken({
        type: dataTokenState[key]["type"],
        version_token_id: dataTokenState[key]["version_type"]
      });
      if (result && result.status === 200) {
        dataTokenState[key]["rest"] = result.data;
      } else {
        dataTokenState[key]["rest"] = 0;
      }
    } else {
      dataTokenState[key]["rest"] = 0;
    }
    setDataTokenState(dataTokenState);
    props.setFieldValue("dataToken", dataTokenState);
  };

  const showTotalMoney = async key => {
    if (dataTokenState[key]["amount"] && dataTokenState[key]["price"]) {
      let total =
        Number(dataTokenState[key]["amount"]) *
        Number(dataTokenState[key]["price"]);
      dataTokenState[key]["total"] = total;
    } else {
      dataTokenState[key]["total"] = 0;
    }
    setDataTokenState(dataTokenState);
    props.setFieldValue("dataToken", dataTokenState);
  };

  const showTotal = () => {
    let total = 0;
    Object.keys(dataTokenState).map(
      (key, index) => (total = total + Number(dataTokenState[key]["total"]))
    );
  };

  const changeData = e => {
    let key = $(e.target)
      .parent()
      .parent()
      .parent()
      .data("row-key");

    let value = e.target.value;
    dataTokenState[key][$(e.target).data("key")] = value;
    setDataTokenState(dataTokenState);
    props.setFieldValue("dataToken", dataTokenState);

    if (
      $(e.target).data("key") === "type" ||
      $(e.target).data("key") === "version_type"
    ) {
      showTokenRest(key);
    }

    if (
      $(e.target).data("key") === "amount" ||
      $(e.target).data("key") === "price"
    ) {
      showTotalMoney(key);
      showTotal();
    }
  };

  const onClickDelete = () => {
    let newDataDelete = removeObjectByKey(dataTokenState);
    const arr: string[] = [];
    $.each(newDataDelete, function(key, value) {
      if (value) {
        arr.push(value);
      }
    });
    setDataTokenState(arr);
    props.setFieldValue("dataToken", arr);
  };

  const getKeyDelete = e => {
    let key = $(e.target)
      .parent()
      .parent()
      .parent()
      .data("row-key");
    setKeyDelete(key);
  };

  const removeObjectByKey = arr => {
    delete arr[Number(keyDelete)];
    return arr;
  };

  const setDataFirst = () => {
    const dataFirstToken = [
      {
        key: 0,
        type: "",
        version_type: "",
        rest: "0",
        amount: "",
        price: "",
        total: "0"
      }
    ];
    const dataFirstDoc = [
      {
        key: 0,
        doc_asset_id: "",
        doc_unit: "",
        doc_rest: "0",
        doc_amount: "",
        doc_price: "",
        doc_total: "0"
      }
    ];
    const dataFirstGift = [
      {
        key: 0,
        gift_asset_id: "",
        gift_unit: "",
        gift_rest: "0",
        gift_amount: "",
        gift_price: "",
        gift_total: "0"
      }
    ];

    setDataTokenState(dataFirstToken);
    setDataDocState(dataFirstDoc);
    setDataGiftState(dataFirstGift);
    props.setFieldValue("dataToken", dataFirstToken);
    props.setFieldValue("dataDoc", dataFirstDoc);
    props.setFieldValue("dataGift", dataFirstGift);
  };

  const fetchWhAssetOptionDoc = async () => {
    const assetCate = new AssetCategoryService();
    const result = await assetCate.all(1);
    const options = {};
    result.data.forEach(element => {
      options[element.id] = element.name;
    });

    setAssetCateDocList(options);
  };
  const fetchWhAssetOptionGift = async () => {
    const assetCate = new AssetCategoryService();
    const result = await assetCate.all(2);
    const options = {};
    result.data.forEach(element => {
      options[element.id] = element.name;
    });

    setAssetCateGiftList(options);
  };
  const fetchCodeExport = async date => {
    const getCodeService = new WhTokenExportService();
    const result = await getCodeService.getCode(date);
    if (result && result.status === 200) {
      props.setFieldValue("code", result.data);
    }
  };
    const userName =  Store.getState().authReducer.fullname;
  useEffect(() => {
      const date = getDateCurrent("-");
    fetchWhAssetOptionDoc();
    fetchWhAssetOptionGift();
    fetchVersionTokenOption();
    fetchCodeExport(date);
    setDataFirst();
    props.setFieldValue("type", "1");
    // eslint-disable-next-line
  }, []);
  const onClickAddHandle = async () => {
    let key = Object.keys(dataTokenState).length;
    let newData = {
      key: key,
      type: "",
      version_type: "",
      rest: "",
      amount: "",
      price: "",
      total: ""
    };
    var totalData = { ...dataTokenState, [key]: newData };
    setDataTokenState(totalData);
    props.setFieldValue("dataToken", totalData);
  };

  const onClickAddHandleDoc = async () => {
    let key = Object.keys(dataDocState).length;
    let newData = {
      key: key,
      doc_asset_id: "",
      doc_unit: "",
      doc_rest: "0",
      doc_amount: "",
      doc_price: "",
      doc_total: ""
    };
    var totalData = { ...dataDocState, [key]: newData };
    setDataDocState(totalData);
    props.setFieldValue("dataDoc", totalData);
  };
  const onClickAddHandleAsset = async () => {
    let key = Object.keys(dataGiftState).length;
    let newData = {
      key: key,
      gift_asset_id: "",
      gift_unit: "",
      gift_rest: "",
      gift_amount: "",
      gift_price: "",
      gift_total: ""
    };
    var totalData = { ...dataGiftState, [key]: newData };
    setDataGiftState(totalData);
    props.setFieldValue("dataGift", totalData);
  };
  return (
    <div className="card">
      <h4 className="card-title1 ml-3">Xuất kho token</h4>
      <div className="card-body">
        <form>
          <div className="input-group">
            <InputWithLabel
              name="code"
              label="Số hóa đơn"
              wrapClass="col-md-2"
              onChangeValue={props.handleChange}
              value={code}
              error={props.errors.code}
              isRequired={true}
              maxLength={255}
              isDisabled={true}
            />
            <SelectBox
              options={TypeWhToken.TYPEAGENCY}
              label="Loại người dùng"
              name="belong_to"
              wrappedClass="col-md-2"
              onChange={onChangeSelectedType}
              value={belong_to}
              firstOptionLabel="Lựa chọn"
              error={props.errors.belong_to}
              isRequired={true}
            />
            <SelectBoxAgency
              options={agency}
              label="Xuất theo yêu cầu của"
              name="owner_id"
              wrappedClass="col-md-4"
              onChange={handleChangeBoxAgency}
              value={owner_id}
              firstOptionLabel="Chọn đại lý/cộng tác viên"
              error={props.errors.owner_id}
              isRequired={true}
              
            />
            <SelectBoxRequestCTS
              options={request}
              label="Mã yêu cầu"
              name="request_id"
              wrappedClass="col-md-2"
              onChange={handleChangeBoxRequest}
              value={request_id}
              firstOptionLabel="Chọn mã yêu cầu"
              error={props.errors.request_id}
              isRequired={true}
            />
            <InputWithLabel
              name="exporter"
              label="Người xuất kho"
              wrapClass="col-md-2"
              onChangeValue={props.handleChange}
              value={userName}
              error={props.errors.exporter}
              isRequired={true}
              maxLength={255}
              isDisabled={true}
            />
          </div>
          <div className="input-group">
            <InputWithLabel
              name="receiver"
              label="Người nhận hàng"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={receiver}
              error={props.errors.receiver}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="location"
              label="Tại kho"
              wrapClass="col-md-7"
              onChangeValue={props.handleChange}
              value={location}
              error={props.errors.location}
              isRequired={false}
              maxLength={255}
            />
            <SelectDate
              label="Ngày xuất kho"
              name="export_date"
              wrappedClass="col-md-2"
              onChange={onChangeSelectDateExportValid}
              value={export_date}
              error={props.errors.export_date}
              isRequired={true}
              placeholder={"yyyy-mm-dd"}
            />
          </div>
          <div className="input-group">
            <div className="col-md-12">
              <label>Về việc</label>
              <TextArea
                name="note"
                placeholder="Ghi chú"
                autoSize={{ minRows: 4, maxRows: 10 }}
                className="form-control"
                required={false}
                value={note}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="input-group col-md-12 m-t-20">
            <div className="col-md-11 p-l-0">
              <h5>Token</h5>
            </div>
            <div className="col-md-1 p-r-0">
              <ClickButton
                text="Thêm"
                css="btn btn-success ml-1 mr-1 font-btn float-right"
                onClick={onClickAddHandle}
              />
            </div>
            <TableAddRowToken
              listType={TypeWhToken.TYPE}
              versionTokenList={versionTokenList}
              data={dataToken}
              changeData={changeData}
              onDelete={onClickDelete}
              keyDelete={getKeyDelete}
              errors={props.errors}
            />
          </div>

          <div className="input-group col-md-12 m-t-20">
            <div className="col-md-11 p-l-0">
              <h5>Tài liệu bán hàng</h5>
            </div>
            <div className="col-md-1 p-r-0">
              <ClickButton
                text="Thêm"
                css="btn btn-success ml-1 mr-1 font-btn float-right"
                onClick={onClickAddHandleDoc}
              />
            </div>
            <TableAddRowDocumentToken
              assetList={assetCateDocList}
              data={dataDoc}
              changeData={changeDataDoc}
              onDelete={onClickDeleteDoc}
              keyDelete={getKeyDelete}
              errors={props.errors}
            />
          </div>

          <div className="input-group col-md-12 m-t-20">
            <div className="col-md-11 p-l-0">
              <h5>Quà tặng</h5>
            </div>
            <div className="col-md-1 p-r-0">
              <ClickButton
                text="Thêm"
                css="btn btn-success ml-1 mr-1 font-btn float-right"
                onClick={onClickAddHandleAsset}
              />
            </div>
            <TableAddRowGift
              assetList={assetCateGiftList}
              data={dataGift}
              changeData={changeDataGift}
              onDelete={onClickDeleteGift}
              keyDelete={getKeyDelete}
              errors={props.errors}
            />
          </div>

          <div className="input-group d-flex justify-content-center p-5">
            <BackButton history={props.history}/>
            <SubmitButton onClick={props.handleSubmit}/>
          </div>
        </form>
      </div>
    </div>
  );
};

const RenderCreateForm = withFormik<Props, FormValues>({
  mapPropsToValues: () => ({
    code: "",
    note: "",
    exporter: "",
    exporter_pos: "",
    type: "",
    location: "",
    receiver: "",
    export_date: "",
    created_date: "",
    owner_id: "",
    request_id: "",
    belong_to: "",
    dataToken: {},
    dataDoc: {},
    dataGift: {}
  }),
  validationSchema: Yup.object().shape({
    belong_to: Yup.string().required("Phải lựa chọn loại người dùng."),
    owner_id: Yup.string().required(
      "Đại lý/ cộng tác viên không thể bỏ trống."
    ),
    request_id: Yup.string().required("Mã yêu cầu không thể bỏ trống."),
    receiver: Yup.string().required("Người nhận không thể bỏ trống."),
    export_date: Yup.date().required("Ngày xuất kho không được trống"),
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new WhTokenExportService();
    const result = await service.store(values);
    if (result && result.status === 200) {
      onSuccessAction("Xuất kho token thành công!", function() {
        props.history.goBack();
      });
    } else if (result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction(result.error);
    }
  }
})(WhTokenCreate);

export default withNavbar()(RenderCreateForm);
