import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class WhAssetService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "wh/asset/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async find(id) {
        const result = await this.request(
            `wh/asset/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async store(data) {
        const result = await this.request(
            "wh/asset/store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async update(data) {
        const result = await this.request(
            "wh/asset/update",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async delete(id) {
        const result = await this.request(
            "danh-muc-version-token/delete",
            {id},
            TypeMethodContsantEnum.DELETE
        );
        return result;
    }

    public async getBase64(id) {
        const result = await this.request(
            `wh/asset/getFile/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
}

export default WhAssetService;
