import React, { Component } from "react";
import Table from "../../common/table/Table";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { History, Location } from "history";
import { match } from "react-router";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import SearchButton from "../../common/button/SearchButton";
import InputWithLabel from "../../common/form/InputWithLabel";
import Helmet from "react-helmet";
import TaxDepartmentService from "../../../services/taxdepartment/TaxDepartmentService";
import ProvinceService from "../../../services/province/ProvinceService";
import SelectProvince from "../../common/form/SelectProvince";

interface Props {
  history: History;
  location: Location;
  match: match;
  name: string;
}
interface State {
  dataSearch: any;
  province: Object;
}

const TITLE = "Danh sách chi cục thuế";
class PageIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      province_code: "",
      text_search: "",
    },
    province: {}
  };

  fetchDataFunction = async (dataSearch = {}) => {
    const service = new TaxDepartmentService();
    const result = await service.getTaxDepartments(
      { ...dataSearch },
    );
    if (result && result.status === 200) {
      return result
    }
    return null;
  }

  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = (data) => {
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  }

  componentDidMount() {
    this.getProvince();
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch: queryString
    })
  }
  getProvince = async () => {
    const service = new ProvinceService();
    const result = await service.getProvinceList();
    if (result && result.status === 200) {
      this.setState({
        province: result.data
      } as any);
    }
  }


  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <h4 className="card-title1">{TITLE}</h4>
        <div className="card-body">
          <form onSubmit={this.onSubmitSearchButton} className='search-form-index'>
            <div className="input-group">
              <InputWithLabel
                name="text_search"
                wrapClass="col-md-4 form-group nopadding-left"
                onChangeValue={this.onChangeDataSearchInput}
                value={this.state.dataSearch.text_search}
                isRequired={false}
                label={""}
                placeholder={"Mã hoặc tên chi cục thuế"}
              />
              <SelectProvince
                label=""
                onChange={this.onChangeDataSearchInput}
                value={this.state.dataSearch.province_code}
                name="province_code"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={this.state.province}
                firstOptionLabel="Tỉnh thành"
              />
              <div className="col-md-3 form-group nopadding-left">
                <SearchButton onClick={this.onSubmitSearchButton} />
                <GoToCreatePageButton
                  toURL={`${this.props.match.url}/them-moi`}
                />
              </div>
            </div>
          </form>
          <Table
            columns={[
              {
                headers: "Mã chi cục thuế",
                accessor: "code"
              },
              {
                headers: "Tên chi cục thuế",
                accessor: "fullname"
              },
              {
                headers: "Tên viết tắt",
                accessor: "short_name"
              },
              {
                headers: "Tên tỉnh thành",
                accessor: "province"
              },
              {
                headers: "Thời gian tạo",
                accessor: "created_at"
              },
              {
                headers: "Thời gian cập nhật",
                accessor: "updated_at"
              }
            ]}
            pagination={true}
            addActionRow={true}
            defaultTdClass="text-center"
            defaultThClass="text-center"
            onClickEdit={this.onClickUpdateButton}
            location={this.props.location}
            fetchDataFunction={this.fetchDataFunction}
            history={this.props.history}
            match={this.props.match}
            checkDeleteAble={false}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(PageIndex);

export default pageWithNavBar;
