import { Dropdown, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { convertJsonToQueryString, formatDateTimeAll, queryStringToJSON } from "../../../helpers/NewCaHelper";
import { onFailAction, onSuccessAction } from "../../../helpers/SwalCommon";
import BaseServices from "../../../services/base/BaseServices";
import DocLegalManagerService from "../../../services/doc-legal-manager/DocLegalManagerService";
import SearchButton from "../../common/button/SearchButton";
import InputWithLabel from "../../common/form/InputWithLabel";
import ModalView from "../../common/form/Modal";
import ModalDeny from "../../common/form/ModalDeny";
import ModalSelect from "../../common/form/ModalSelect";
import SelectBox from "../../common/form/SelectBox";
import SelectBox2 from "../../common/form/wh/SelectBox";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import NewTable from "../../common/table/NewTable";
import ModalUploadDocument from './../../common/form/ModalUploadDocument';
import { loading } from './../../common/loading/Loading';
import { listName } from "./Enum";
import MenuDropDoc from "./MenuDrop/MenuDropDoc";
const { REACT_APP_BASE_API_URL } = window['runConfig'];

interface Props extends RouteComponentProps {
}

const TITLE = "Quản lý hồ sơ pháp lý";

const DocLegalManager: React.FC<Props> = props => {
    const service = new DocLegalManagerService();
    const [dataSearch, setDataSearch] = useState({customer_code: "", code:"", type_request:"", object:"", file_status:"",type_update:"", update_profile_at:"", agency_code:""});
    const [dataStateDoc, setDataStateDoc] = useState([]);
    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });
    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });
    const [modalSendFastCA, setModalSendFastCA] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        type_file: "",
        text_deny: "",
        data:[],
        value: [],
        error_text: "",
        indeterminate: false,
        checkAll: true
    });

    const [modalUploadDoc, setModalUploadDoc] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        type_file: "",
        text_deny: "",
        data:[],
        dataId:[],
        value: [],
        error_text: "",
    });
    const [fileName, setFileName] = useState({
        name1: "",
        name2: "",
        name3: "",
        name4: "",
        name5: "",
        name6: "",
        name7: "",
        name8: "",
        name9: "",
        name10: "",
        name11: "",
        name12: "",
        name13: "",
        name14: ""
    });
    const [id] = useState();
    const [listAgency, setListAgency] = useState({});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, "file_status": e})
    };
    const onChangeDataSearchTypeUpdate = (e) => {
        setDataSearch({...dataSearch, "type_update": e})
    };
    const onChangeDataSearchAgency = (e) => {
        setDataSearch({...dataSearch, "agency_code": e})
    };
    useEffect(() => {
        fetchDataFunction();
        getAllAgencyAndContributor();
        // eslint-disable-next-line
    }, []);
    const getAllAgencyAndContributor = async () => {
        try {
            const result = await service.getAllAgencyAndContributor();
            const options = {};
            if (result && result.status === 200){
                result.data.map((agency)=>{
                    return options[agency.code] = agency.fullname;
                });
            }
            setListAgency(options);
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            let arrAll:any = [];
            result.data.data.forEach(function (values) {
                arrAll[values.id] = values.type_docurment;
            });
            setDataStateDoc(arrAll);
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    //Click xem file
    const onPreviewFile = async (password, type) => {
        try{
            var token = localStorage.getItem("currentUser");
            if(token){
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/quan-ly-ho-so/previewFile/${password}/${type}?token=${obj.token}`;
                window.open(link);
            }
        }catch (error){
            onFailAction("Có lỗi xảy ra!");
        }
    };
    // nút xem file
    const renderActionViewFile = (text, record, index) => {
        console.log(record)
        let listDoc: Array<number> = [];
        record.list_document.forEach(function (value) {
            listDoc.push(value.type);
        });
        return (
            <div className="row justify-content-center">
                <Dropdown
                    overlay={
                        <MenuDropDoc onClick={(index) => onPreviewFile(record.password, index)} dataDoc={listDoc}/>
                    }
                    trigger={["click"]}
                    placement="bottomLeft"
                >
                    <button className="btn btn-outline-primary btn-sm w-100 ml-2 mr-2">Xem file <i className="fas fa-angle-down"/></button>
                </Dropdown>
            </div>
        );
    };
    // const renderActionButton = (text, record, index) => {
    //     return (
    //         <React.Fragment>
    //             <div className="row justify-content-center">
    //                 {record.file_status === 1 || record.file_status === 5 ?
    //                 <button type="button" title="Xác nhận Hợp lệ" id={record.id} className="text-primary ml-2 fa-lg" onClick={onClickApprove}><i className="fas fa-check"/></button>
    //                 : "" }
    //                 {record.file_status === 1 ?
    //                 <button type="button" title="Không hợp lệ" className="text-danger ml-2 fa-lg" onClick={()=>submitDeny(record.id)}><i className="fas fa-ban"/></button>
    //                 : "" }
    //                 {record.file_status === 4 ?
    //                 <button type="button" title="Xác nhận cho phép cập nhật" id={record.id} className="text-primary ml-2 fa-lg" onClick={onClickApproveUpdateFile}><i className="fas fa-check-square"/></button>
    //                 : "" }
    //                 {record.file_status === 4 ?
    //                 <button type="button" title="Từ chối cho phép cập nhật" id={record.id} className="text-danger ml-2 fa-lg" onClick={onClickRefuseUpdateFile}><i className="fas fa-exclamation-circle"/></button>
    //                 : "" }
    //                 {record.file_status === 2 ?
    //                 <button type="button" title="Cập nhật sang FastCA" id={record.id} className="text-warning ml-2 fa-lg" onClick={()=>onClickSendFastCA(record) }><i className="fas fa-retweet"/></button>
    //                 : "" }
    //                 <button type="button" title="Upload file" id={record.id} className="text-primary ml-2 fa-lg" onClick={()=>onClickUploadFile(record) }><i className="fas fa-upload"/></button>
    //             </div>
    //         </React.Fragment>
    //     );
    // };
    // const onClickApprove = e =>{
    //     const keyId = e.currentTarget.getAttribute('id');
    //     onApproveFile(async (willApprove) => {
    //         if (willApprove) {

    //             let typeD = 0;
    //             dataStateDoc.forEach(function(item, index, array) {
    //                 if(Number(index) === Number(keyId)){
    //                     typeD = item;
    //                 }
    //             });
    //             onApprove(keyId, typeD);
    //         }
    //     });
    // };
    // const onClickApproveUpdateFile = e =>{
    //     const keyId = e.currentTarget.getAttribute('id');
    //     onApproveFileUpdateRequest(async (willApprove) => {
    //         if (willApprove) {
    //             onApproveUpdateFile(keyId);
    //         }
    //     });
    // };
    // const onClickRefuseUpdateFile = e =>{
    //     const keyId = e.currentTarget.getAttribute('id');
    //     onRefuseFileUpdateRequest(async (willApprove) => {
    //         if (willApprove) {
    //             onRefuseUpdateFile(keyId);
    //         }
    //     });
    // };
    // const submitDeny = (id) => {
    //     setModalDeny({...modalDeny, 'visible_modal_deny': true});
    //     setId(id);
    // };
    // const onClickSendFastCA = (record) => {
    //     console.log(record);
    //     const keyId = record.id;
    //     let arrTemp = [] as  any
    //     record.list_document.forEach(function (value) {
    //         arrTemp.push(listName[value.type])
    //     });
    //     setModalSendFastCA({...modalSendFastCA, 'visible_modal': true, 'data': arrTemp, 'value': arrTemp, 'indeterminate': false, 'checkAll': true});
    //     setId(keyId);
    // };
    // const onClickUploadFile = (record) => {
    //     console.log(record);
    //     const keyId = record.id;
    //     let arrTemp = [] as  any
    //     arrTemp = listName
    //     let arrTempId = [] as  any
    //     record.list_document.forEach(function (value) {
    //         arrTempId.push(value.type);
    //     });
    //     setModalUploadDoc({...modalUploadDoc, 'visible_modal': true, 'data': arrTemp, 'dataId': arrTempId,'value': arrTemp, 'error_text': ''});
    //     setId(keyId);
    // };
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false});
    };
    const handleModalSendFCACancel = () => {
        setModalSendFastCA({...modalSendFastCA, 'visible_modal': false});
    };
    const handleModalUploadDocCancel = () => {
        setModalUploadDoc({...modalUploadDoc, 'visible_modal': false, 'error_text': '' });
        setFileName({...fileName,  name1: "",name2: "",name3: "",name4: "",name5: "",name6: "",name7: "",name8: "",name9: "",name10: "",name11: "",name12: "",name13: "",name14: "" });
    };
    const confirmImport = async () => {
        let check = true;
        let arrTemp = [] as any;
        arrTemp = fileName;
        for (let [key,value] of Object.entries(arrTemp)) {
            console.log(key)
            if(value !== ''){
                check = false;
            }
        };
        if (check) {
            setModalUploadDoc({...modalUploadDoc, 'error_text': "Bạn chưa tải file hồ sơ lên" });
            return false;
        }
        const data = {fileName:fileName, id: id};
        const result = await service.uploadFile(data);
        await setModalUploadDoc({...modalUploadDoc, 'visible_modal': false });
        if (result && Number(result.status) === 200) {
            onSuccessAction("Tải file thành công!", window.location.reload());
        } else {
            onFailAction('Có lỗi xảy ra trong quá trình cập nhật!', () => {
                props.history.push("/quan-ly-ho-so-phap-ly");
            });
        }
    };
    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim() === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.onRefuse(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/quan-ly-ho-so-phap-ly");
                setModalDeny({...modalDeny,'text_deny':'','visible_modal_deny': false})
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra!");
        } else {
            onFailAction(result.error);
        }
    };
    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };
    const onChangeDataSendFCA = (list) => {
        let checkAllTemp = list.length === modalSendFastCA.data.length;
        let checkIndeterminate = !!list.length && list.length < modalSendFastCA.data.length;
        setModalSendFastCA({...modalSendFastCA, 'value': list, 'indeterminate' : checkIndeterminate, checkAll: checkAllTemp });
    };
    const onChangeAllDataSendFCA = (e) => {
        let temp = [] as any;
        temp = e.target.checked ? modalSendFastCA.data : [];
        setModalSendFastCA({...modalSendFastCA, 'value': temp, 'indeterminate' : false, checkAll: e.target.checked });
    };
    const onChangeFile = async e => {
        const files = e.target.files[0];
        const name = e.target.name;
        let nameTemp = 'name'+name;
        if (files) {
            if (files.type !== "application/pdf") {
                setModalUploadDoc({...modalUploadDoc, 'error_text': "Chỉ chấp nhận định dạng file PDF" });
                return false;
            }
            const fileSize = files.size/1024/1024;
            if(fileSize>2){
                setModalUploadDoc({...modalUploadDoc, 'error_text': "Giới hạn dung lượng 2MB" });
                return false;
            }else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if(fileData){
                    setFileName({...fileName, [nameTemp] : fileData.file});
                }
                loading.stopRunLoading();
            }
        } else {
            setFileName({...fileName, [nameTemp] : ""});
        }
    };
    const confirmSendFastCA = async (e) => {
        if(modalSendFastCA.value.length === 0){
            setModalSendFastCA({...modalSendFastCA, 'error_text': 'Bạn phải chọn ít nhất 1 File'});
            return
        }
        let temp = [] as any;
        modalSendFastCA.value.forEach(function (value) {
            temp.push(listName.indexOf(value));
        });
        console.log(temp, id)
        const result = await service.onApproveSendFastCA(id, temp);
        if (result && result.status === 200) {
            onSuccessAction("Cập nhật thành công!(Vui lòng chờ 1 phút)");
            setModalSendFastCA({...modalSendFastCA, 'visible_modal': false});
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình cập nhật!");
        }
    };
    // const onApprove = async (id, typeD) => {
    //     const result = await service.onApprove(id, typeD);
    //     if (result && result.status === 200) {
    //         onSuccessAction("Duyệt thành công!", () => {
    //             props.history.push("/quan-ly-ho-so-phap-ly");
    //         });
    //     } else {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     }
    // };
    // const onApproveUpdateFile = async (id) => {
    //     const result = await service.onApproveRequestUpload(id);
    //     if (result && result.status === 200) {
    //         onSuccessAction("Duyệt thành công!", () => {
    //             props.history.push("/quan-ly-ho-so-phap-ly");
    //         });
    //     } else {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     }
    // };
    // const onRefuseUpdateFile = async (id) => {
    //     const result = await service.onRefuseRequestUpload(id);
    //     if (result && result.status === 200) {
    //         onSuccessAction("Từ chối thành công!", () => {
    //             props.history.push("/quan-ly-ho-so-phap-ly");
    //         });
    //     } else {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     }
    // };
    const renderActionSelectTypeDoc = (record) => {
        return (
            <div className="w-100 select-type-doc">
                <SelectBox2 options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}} label={""} dataKey={record.id} name={"typeDocId"} wrappedClass={""} value={dataStateDoc[record.id]} onChange={onChangeTypeDoc}/>
             </div>
        );
    };
    const onChangeTypeDoc = e =>{
        const key = e.currentTarget.getAttribute('data-key');
        const value = e.target.value;
        let temp:any = [];
        dataStateDoc.forEach(function(item, index, array) {
            if(Number(index) === Number(key)){
                temp[index] = value
            }else{
                temp[index] = item
            }
        });
        setDataStateDoc(temp)
    };
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <ModalSelect
                title="Cập nhật lại file sang hệ thống FAST CA"
                visible={modalSendFastCA.visible_modal}
                handleCancel={handleModalSendFCACancel}
                handleButton={confirmSendFastCA}
                plainOptions={modalSendFastCA.data}
                checkedList={modalSendFastCA.value}
                onChange={onChangeDataSendFCA}
                onCheckAllChange ={onChangeAllDataSendFCA}
                error={modalSendFastCA.error_text}
                indeterminate={modalSendFastCA.indeterminate}
                checkAll={modalSendFastCA.checkAll}
            />

            <ModalUploadDocument
                title="Cập nhật lại hồ sơ"
                visible={modalUploadDoc.visible_modal}
                handleCancel={handleModalUploadDocCancel}
                handleButton={confirmImport}
                plainOptions={modalUploadDoc.data}
                plainIdOptions={modalUploadDoc.dataId}
                checkedList={modalUploadDoc.value}
                onChange={onChangeFile}
                error={modalUploadDoc.error_text}
                fileName={fileName}
            />

            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="customer_code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.customer_code}
                            isRequired={false}
                            label={""}
                            placeholder="Mã khách hàng"
                        />
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            isRequired={false}
                            label={""}
                            placeholder="Mã số DN hoặc CMND"
                        />
                        <SelectBox
                            name="file_status"
                            options={{1: "Chờ xác nhận", 2:"Hợp lệ", 3:"Không hợp lệ", 4: "Chờ xác nhận cho phép cập nhật", 5: "Chờ xác nhận(Admin cập nhật)"}}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Trạng thái"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.file_status}
                        />
                        <SelectBox
                            name="agency_code"
                            options={listAgency}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Đại lý/ CTV"
                            onChange={onChangeDataSearchAgency}
                            value={dataSearch.agency_code}
                        />
                        <SelectBox
                            name="type_update"
                            options={{1: "Đã cập nhật hồ sơ", 2:"Chưa cập nhật hồ sơ"}}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Trạng thái cập nhật"
                            onChange={onChangeDataSearchTypeUpdate}
                            value={dataSearch.type_update}
                        />
                        <div className="col-md-2 form-group nopadding-left">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Mã khách hàng",
                            dataIndex: "",
                            render: (e) => {
                                if (e.type_request === 1){
                                    return e.organization.uid;
                                } else {
                                    return e.requestpersonal.uid;
                                }
                            }
                        },
                        {
                            title: "Mã yêu cầu",
                            dataIndex: "code",
                        },
                        {
                            title: "Mã DN/CMND",
                            dataIndex: "",
                            render: (e) => {
                                if (e.type_request === 1){
                                    return e.organization.code;
                                } else {
                                    return e.requestpersonal.passport;
                                }
                            }
                        },
                        {
                            title: "Đại lý/CTV",
                            dataIndex: "",
                            render: (e) => {
                                if (e.user.type === 7){
                                    return e.user.agency.fullname;
                                }
                                if (e.user.type === 8){
                                    return e.user.contributor.fullname;
                                }
                            }
                        },
                        {
                            title: "Tài khoản",
                            dataIndex: "",
                            render: (e) => {
                                return e.user.fullname;
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: (e) => {
                                if (e.file_status === 1){
                                    return <Tag color="orange">Chờ xác nhận</Tag>
                                }
                                if (e.file_status === 2){
                                    return <Tag color="green">Hợp lệ</Tag>
                                }
                                if (e.file_status === 3){
                                    return (
                                        <Tooltip title={e.reason_refusal_file} placement="topLeft">
                                            <Tag color="magenta">Không hợp lệ</Tag>
                                        </Tooltip>
                                    )
                                }
                                if (e.file_status === 4){
                                    return (
                                        <Tooltip title={formatDateTimeAll(e.time_request_update_file) + ':' +e.reason_request_update_file} placement="topLeft">
                                                <Tag color="geekblue">Chờ xác nhận cho phép cập nhật</Tag>
                                        </Tooltip>
                                    )
                                }
                                if (e.file_status === 5){
                                    return <Tag color="purple">Chờ xác nhận(admin cập nhật)</Tag>
                                }
                            }
                        },
                        {
                            title: "Loại hồ sơ",
                            render: (e) => {
                                if (e.file_status === 1 || e.file_status === 5){
                                    return  renderActionSelectTypeDoc(e)
                                }else {
                                    return <SelectBox2
                                        isDisabled={true}
                                        options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}}
                                        label={""}
                                        name={""}
                                        wrappedClass={"w-100 select-type-doc"}
                                        value={e.type_docurment}
                                    />
                                }
                            }
                        },
                        {
                            title: "File",
                            render: renderActionViewFile
                        },
                        {
                            title: "Ngày cập nhật hồ sơ",
                            className: "text-center",
                            render: (e) => {
                                if (e.update_profile_at){
                                    return formatDateTimeAll(e.update_profile_at)
                                }
                            }
                        },
                        // {
                        //     title: "Tác vụ",
                        //     render: renderActionButton
                        // }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(DocLegalManager);
