import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const path = "admin/log-management";
class LogActionService extends FetchAPI{
    public async list(data){
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }
}
export default LogActionService;
