import React, {Component} from "react";
import Table from "../../../common/table/NewTable";
import {History, Location} from "history";
import {match} from "react-router";
import RequestService from "../../../../services/requesttokenagency/RequestTokenService";
import {onFailAction} from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import {OWNER_LABEL_CLASS, STATUS_LABEL_CLASS} from "../Enum";
import {checkPermission} from "../../../../helpers/NewCaHelper";
import {Tag} from "antd";

interface Props {
    location: Location;
    history: History;
    match: match;
    user: any;
}

interface State {
}

const per_view_detail_request_document = 'view-detail-request-document-ad'

export default class RequestTable extends Component<Props, State> {
    state = {};

    componentDidMount() {
        this.fetch();
    }

    fetch = async (params = {}) => {
        this.setState({loading: true});
        const service = new RequestService();
        try {
            return  await service.getRequests(params);
        } catch (error) {
            onFailAction();
        }
    };

    render() {
        return (
            <Table
                columns={this.columns}
                onFetchData={this.fetch}
            />
        );
    }

    onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickLock={() => true}
                onClickUpdate={() => this.props.history.push(`/yeu-cau-token-cts/xu-ly/${record.id}`)}
                isDisplayLockButton={false}
                isDisplayUpdateButton={this.checkUpdateAble(record)}
            />
        );
    };

    checkUpdateAble = (record: any): any => {
        return checkPermission(per_view_detail_request_document);
    };

    renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
                {STATUS_LABEL_CLASS[text].label}
            </Tag>
        );
    };

    renderTypeOwner = (text, record, index) => {
        if (text.belong_to) {
            return (
                <Tag color={`${OWNER_LABEL_CLASS[text.belong_to].class}`}>
                    {OWNER_LABEL_CLASS[text.belong_to].label}
                </Tag>
            );
        } else {
            if (text.agency) {
                return <Tag color="cyan">Đại lý</Tag>
            } else if (text.contributor) {
                return <Tag color="green">Cộng tác viên</Tag>
            } else {
                return <Tag color="volcano">Không xác định </Tag>
            }
        }
    };

    columns = [
        {
            title: "Mã yêu cầu",
            dataIndex: '',
            render: (e) => {
                return e.code
            }
        },
        {
            title: "Loại người dùng",
            dataIndex: "",
            render: this.renderTypeOwner
        },
        {
            title: 'Mã số thuế/Chứng minh thư',
            dataIndex: '',
            render: (e) => {
                if (e.belong_to) {
                    if (e.belong_to === 1) {
                        return <a href="#/">{e.owner.tax_code}</a>;
                    } else if (e.belong_to === 2) {
                        return <a href="#/">{e.owner.passport}</a>;
                    }
                }else {
                    if (e.agency) {
                        return <a href="#/">{e.agency.tax_code}</a>;
                    }else if(e.contributor){
                        return <a href="#/">{e.contributor.passport}</a>;
                    }else {
                        return ""
                    }
                }
            }
        },

        {
            title: "Tên đại lý/Cộng tác viên/ NVKD",
            render: (e) => {
                if (e) {
                    if (Number(e.belong_to) === 1 || Number(e.belong_to) === 2) {
                        return <p>{e.owner.sortname}</p>;
                    } else {
                        return <p>{e.user.fullname}</p>;
                    }
                }
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            render: this.renderStatusColumn
        },
        {
            title: "Thời gian tạo",
            dataIndex: "created_at",
        },
        {title: "Người yêu cầu", dataIndex: "user.fullname"},
        {title: "Tên người nhận", dataIndex: "receive_fullname"},
        {
            title: "Tác vụ",
            render: this.onRenderActionColumn
        }
    ];
}
