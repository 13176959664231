import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import PreviewButton from "../../../common/button/PreviewButton";
import SubmitButton from "../../../common/form/SubmitButton";
import DenyButton from "../../../common/button/DenyButton";
import { History } from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import WrapperButtonInput from "../../../common/form/wrapper-button-form/wrapperButtonInput";
import ModalView from "../../../common/form/Modal";
import ModalDeny from "../../../common/form/ModalDeny";
import { onFailAction, onSuccessAction } from "../../../common/swal/SwalCommon";
import AgencyService from "../../../../services/agency/AgencyService";
import { Table } from "antd";
import { CONTRACT_TYPE_OPTIONS } from "../AgencyEnum";
import CheckBoxGroup from "../../../common/form/CheckBoxGroup";
import {checkPermission} from "../../../../helpers/NewCaHelper"

const per_agency_handle_admin = 'agency-handle-admin'
interface Props {
  id: string;
  history: History;
}
interface State {
  fullname: string;
  address: string;
  bank_branch: string;
  bank_number: string;
  code: string;
  contract_code: string;
  contract_date: string;
  created_at: string;
  created_by: string;
  deputy: string;
  deputy_position: string;
  discount_rate: string;
  email: string;
  fax: string;
  field_id: string;
  competitive_area: string;
  sale_fullname: string;
  office_address: string;
  personnal_scale: string;
  phone: string;
  sortname: string;
  status: number;
  supply_capacity: string;
  tax_code: string;
  type: string;
  data_contact: any;
  visible_modal: boolean;
  visible_modal_deny: boolean;
  loading: boolean;
  title_modal: string;
  dataSearchDocument: any;
  base64: string;
  type_file: string;
  is_cooperate: string,
  product: any,
}
const columns = [
  {
    title: "STT",
    width: 70,
    dataIndex: "key",
    key: "key",
    fixed: true
  },
  {
    title: "Đầu mối phối hợp",
    width: 250,
    dataIndex: "type_name",
    key: "type_name",
    fixed: true,
    render: text => <b>{text}</b>
  },
  {
    title: "Họ tên",
    dataIndex: "name",
    key: "name",
    width: "auto"
  },
  {
    title: "Chức vụ",
    dataIndex: "position",
    key: "position",
    width: "auto"
  },
  {
    title: "Số điện thoại",
    dataIndex: "phone",
    key: "phone",
    width: "auto"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: "auto"
  }
];

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
  service = new AgencyService();
  state = {
    dataSearchDocument: {
      id: this.props.id,
      type: ""
    },
    visible_modal: false,
    visible_modal_deny: false,
    loading: false,
    title_modal: "",
    base64: "",
    type_file: "",
    text_deny: "",
    error_text_deny: "",
    type_contract: "",
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await this.service.getAgency(this.props.id);
    if (data && data.status === 200) {
      const {
        fullname,
        address,
        bank_branch,
        bank_number,
        code,
        contract_code,
        contract_date,
        created_at,
        created_by,
        deputy,
        deputy_position,
        discount_rate,
        email,
        fax,
        field_id,
        competitive_area,
        sale_fullname,
        office_address,
        personnal_scale,
        phone,
        sortname,
        status,
        supply_capacity,
        tax_code,
        type,
        data_contact,
        is_cooperate,
        product
      } = data.data;
      this.props.setValues({
        fullname,
        address,
        bank_branch,
        bank_number,
        code,
        contract_code,
        contract_date,
        created_at,
        created_by,
        deputy,
        deputy_position,
        discount_rate,
        email,
        fax,
        field_id,
        competitive_area,
        sale_fullname,
        office_address,
        personnal_scale,
        phone,
        sortname,
        status,
        supply_capacity,
        tax_code,
        type,
        data_contact,
        is_cooperate,
        product
      } as Pick<State, keyof State>);
    }

  };

  onClickModalFile = (type, tittle) => {
    const dataSearchDocument = { ...this.state.dataSearchDocument, type };
    this.fetchDataFunction(dataSearchDocument);

    this.setState({ visible_modal: true, title_modal: tittle });
  };

  fetchDataFunction = async data => {
    const result = await this.service.getAgencyDocumentFile(data);
    console.log(result)
    if (result && result.status === 200) {
      this.setState({ loading: true });
      this.setState({ base64: result.data });
      this.setState({ type_file: result.type_file });
      return result;
    } else {
      this.setState({ base64: "" });
      onFailAction("Không tìm thấy file");
    }
    onFailAction("Có lỗi xảy ra khi lấy file");
  };

  handleModalCancel = () => {
    this.setState({
      visible_modal: false
    });
  };

  submitApprove = async () => {
    const value = this.props.values;
    const self = this;
    const result = await this.service.getAgencyApprove({
      ...value,
      id: this.props.id
    });
    if (result && result.status === 200) {
      onSuccessAction("Duyệt và ký đại lý thành công!", () => {
        self.props.history.push("/dai-ly");
      });
    } else if (result && result.status === 404) {
      onFailAction("Có lỗi xảy ra trong quá trình duyệt và ký!");
    } else {
      onFailAction("Có lỗi xảy ra trong quá trình duyệt và ký!");
    }
  };

  handleModalDenyCancel = () => {
    this.setState({
      visible_modal_deny: false
    });
  };
  onChangeDataDeny = ({ target: { value } }) => {
    this.setState({ text_deny: value });
  };

  submitDeny = () => {
    this.setState({ visible_modal_deny: true });
  };

  confirmDeny = async () => {
    const value = this.props.values;
    if (this.state.text_deny.replace(/\s+/g, '') === "") {
      this.setState({ error_text_deny: "Lý do từ chối không được trống" });
      return false;
    }
    const self = this;
    const result = await this.service.getAgencyDeny({
      ...value,
      id: this.props.id,
      text_deny: this.state.text_deny
    });
    await this.setState({ visible_modal_deny: false });
    if (result && result.status === 200) {
      onSuccessAction("Từ chối đại lý thành công!", () => {
        self.props.history.push("/dai-ly");
      });
    } else if (result && result.status === 404) {
      onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
    } else {
      onFailAction(result.error);
    }
  };

  render() {
    const {
      fullname,
      address,
      bank_branch,
      bank_number,
      code,
      contract_code,
      contract_date,
      deputy,
      deputy_position,
      discount_rate,
      email,
      field_id,
      competitive_area,
      office_address,
      personnal_scale,
      phone,
      sortname,
      supply_capacity,
      tax_code,
      data_contact,
      is_cooperate,
      product
    } = this.props.values;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="input-group">
          <InputWithLabel
            name="fullname"
            label="Tên đại lý"
            wrapClass="col-md-5"
            onChangeValue={this.props.handleChange}
            value={fullname}
            error={this.props.errors.fullname}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="tax_code"
            label="Mã số thuế"
            wrapClass="col-md-2"
            onChangeValue={this.props.handleChange}
            value={tax_code}
            error={this.props.errors.tax_code}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="sortname"
            label="Tên đại lý viết tắt"
            wrapClass="col-md-2"
            onChangeValue={this.props.handleChange}
            value={sortname}
            error={this.props.errors.sortname}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="code"
            label="Mã đại lý"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={false}
            isDisabled={true}
          />
        </div>
        <div className="input-group">
          <InputWithLabel
            name="email"
            label="Email"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={email}
            error={this.props.errors.email}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="phone"
            label="Số điện thoại"
            wrapClass="col-md-2"
            onChangeValue={this.props.handleChange}
            value={phone}
            error={this.props.errors.phone}
            isRequired={false}
            isDisabled={true}
          />

          <InputWithLabel
            name="deputy"
            label="Người đại diện"
            wrapClass="col-md-4"
            onChangeValue={this.props.handleChange}
            value={deputy}
            error={this.props.errors.deputy}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="deputy_position"
            label="Chức vụ"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={deputy_position}
            error={this.props.errors.deputy_position}
            isRequired={false}
            isDisabled={true}
          />
        </div>
        <div className="input-group">
          <InputWithLabel
            name="address"
            label="Trụ sở chính"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={address}
            error={this.props.errors.address}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="office_address"
            label="Văn phòng giao dịch"
            wrapClass="col-md-5"
            onChangeValue={this.props.handleChange}
            value={office_address}
            error={this.props.errors.office_address}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="field_id"
            label="Lĩnh vực hoạt động"
            wrapClass="col-md-2"
            onChangeValue={this.props.handleChange}
            value={field_id}
            error={this.props.errors.field_id}
            isDisabled={true}
          />
          <InputWithLabel
            name="personnal_scale"
            label="Quy mô nhân sự"
            wrapClass="col-md-2"
            onChangeValue={this.props.handleChange}
            value={personnal_scale}
            error={this.props.errors.personnal_scale}
            isRequired={false}
            isDisabled={true}
          />
        </div>
        <div className="input-group">
          <InputWithLabel
            name="supply_capacity"
            label="Năng lực cung cấp CKS/tháng"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={supply_capacity}
            error={this.props.errors.supply_capacity}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="competitive_area"
            label="Khu vực thị trường"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={competitive_area}
            error={this.props.errors.competitive_area}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="bank_number"
            label="Số tài khoản"
            wrapClass="col-md-2"
            onChangeValue={this.props.handleChange}
            value={bank_number}
            error={this.props.errors.bank_number}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="bank_branch"
            label="Mở tại"
            wrapClass="col-md-4"
            onChangeValue={this.props.handleChange}
            value={bank_branch}
            error={this.props.errors.bank_branch}
            isRequired={false}
            isDisabled={true}
          />
        </div>
        <div className="input-group">
          <InputWithLabel
              label="Đã hợp tác với nhà cung cấp nào khác"
              name="is_cooperate"
              onChangeValue={this.props.handleChange}
              wrapClass="col-md-5"
              isDisabled={true}
              value={is_cooperate}
          />
          <CheckBoxGroup
              label="Cung cấp sản phẩm, dịch vụ cho NewCA"
              wrappedClass="col-md-7 check-box-group"
              onChange={this.props.handleChange}
              name="product"
              defaultValue={product}
              options= { [
                { title: 'Dịch vụ chữ ký số', value: "1" },
                { title: 'Phần mềm hóa đơn điện tử', value: "2" },
                { title: 'Phần mềm kê khai bảo hiểm xã hội (IVAN)', value: "3" },
              ]}

          />
        </div>
        <div className="col-md-12">
          <Table
            rowKey={(record, index) => {
              return index.toString();
            }}
            columns={columns}
            dataSource={
              Object.keys(data_contact).length !== 0 ? data_contact : []
            }
            scroll={{ x: 1200 }}
            pagination={false}
            className="form-group"
          />
        </div>
        <div className="input-group">
          <InputWithLabel
            name="type"
            label="Loại hợp đồng"
            wrapClass="col-md-3 "
            onChangeValue={this.props.handleChange}
            value={CONTRACT_TYPE_OPTIONS[this.props.values.type]}
            error={this.props.errors.type}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="contract_date"
            label="Ngày hợp đồng"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={contract_date}
            error={this.props.errors.contract_date}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="contract_code"
            label="Số hợp đồng"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={contract_code}
            error={this.props.errors.contract_code}
            isRequired={false}
            isDisabled={true}
          />
          <InputWithLabel
            name="discount_rate"
            label="Phần trăm chiết khấu"
            wrapClass="col-md-3"
            onChangeValue={this.props.handleChange}
            value={discount_rate}
            error={this.props.errors.discount_rate}
            isRequired={false}
            isDisabled={true}
          />
        </div>
        <div className="input-group">
          <WrapperButtonInput>
            <PreviewButton
              name={"File GPĐKKD"}
              history={this.props.history}
              onClick={() => {
                this.onClickModalFile("4", "File GPĐKKD");
              }}
            />
          </WrapperButtonInput>
          <WrapperButtonInput name={""}>
            <PreviewButton
              name={"Hợp đồng"}
              history={this.props.history}
              onClick={() => {
                this.onClickModalFile("2", "Hợp đồng");
              }}
            />
            {Number(this.props.values.type) === 2 && JSON.stringify(product).includes('1') ? (
                <PreviewButton
                    name={"Phụ lục 1"}
                    history={this.props.history}
                    onClick={() => {
                      this.onClickModalFile("11", "Phụ lục 1");
                    }}
                />
            ) : ("")}
            {Number(this.props.values.type) === 2 && JSON.stringify(product).includes('2')  ? (
                <PreviewButton
                    name={"Phụ lục 2"}
                    history={this.props.history}
                    onClick={() => {
                      this.onClickModalFile("12", "Phụ lục 2");
                    }}
                />
            ) : ("")}
            {Number(this.props.values.type) === 2 && JSON.stringify(product).includes('3')  ? (
                <PreviewButton
                    name={"Phụ lục 3"}
                    history={this.props.history}
                    onClick={() => {
                      this.onClickModalFile("13", "Phụ lục 3");
                    }}
                />
            ) : ("")}
            {Number(this.props.values.type) === 2 ? (
                <PreviewButton
                    name={"Tờ trình"}
                    history={this.props.history}
                    onClick={() => {
                      this.onClickModalFile("1", "Tờ trình");
                    }}
                />
            ) : ("")}
          </WrapperButtonInput>
        </div>
        <WrapperButtonDiv>
          <BackButton history={this.props.history} url="/dai-ly"/>

          {([5, 6].indexOf(this.props.values.status) > -1 || !checkPermission(per_agency_handle_admin))
            ? ""
            : <DenyButton onClick={this.submitDeny}/>
          }
          {([5, 6].indexOf(this.props.values.status) > -1 || !checkPermission(per_agency_handle_admin)) 
            ? ""
            : <SubmitButton onClick={this.submitApprove} text={" Duyệt và ký"} iconClass={"fa fa-check-circle"}/>
          }
        </WrapperButtonDiv>
        <ModalView
          visible={this.state.visible_modal}
          handleCancel={this.handleModalCancel}
          title={this.state.title_modal}
          value={this.state.base64}
          type_file={this.state.type_file}
          loading={this.state.loading}
        />
        <ModalDeny
          visible={this.state.visible_modal_deny}
          handleCancel={this.handleModalDenyCancel}
          handleDeny={this.confirmDeny}
          value={this.state.text_deny}
          onChange={this.onChangeDataDeny}
          error={this.state.error_text_deny}
        />
      </form>
    );
  }
}

const userUpdateForm = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    fullname: "",
    address: "",
    bank_branch: "",
    bank_number: "",
    code: "",
    competitive_area: "",
    contract_code: "",
    contract_date: "",
    created_at: "",
    created_by: "",
    deputy: "",
    deputy_position: "",
    discount_rate: "",
    email: "",
    fax: "",
    field_id: "",
    sale_fullname: "",
    office_address: "",
    personnal_scale: "",
    phone: "",
    sortname: "",
    status: 0,
    supply_capacity: "",
    tax_code: "",
    type: "",
    data_contact: {},
    visible_modal: false,
    loading: false,
    title_modal: "",
    base64: "",
    dataSearchDocument: {},
    visible_modal_deny: false,
    type_file: "",
    is_cooperate: "",
    product: [],
  }),
  validationSchema: Yup.object().shape({}),

  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
  }
})(UpdateForm);

export default userUpdateForm;
