import { Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { convertJsonToQueryString, queryStringToJSON, reloadPage, } from "../../../helpers/NewCaHelper";
import {
    onDelCertificate,
    onDeleteAlert,
    onFailAction,
    onLockAlert,
    onSuccessAction,
    onUnLockAlert
} from "../../../helpers/SwalCommon";
import BaseServices from "../../../services/base/BaseServices";
import TokenManagerService from "../../../services/token-manager/TokenManagerService";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import ButtonImportExcel from "../../common/button/ButtonImportExcel";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import SearchButton from "../../common/button/SearchButton";
import InputWithLabel from "../../common/form/InputWithLabel";
import ModalImport from "../../common/form/ModalImport";
import SelectBox from "../../common/form/SelectBox";
import { loading } from "../../common/loading/NewLoading";
import NewTable from "../../common/table/NewTable";
import TableActionButton from "../user/table/action-button/TableActionButton";
import { STATUS_CTS_IN_TOKEN_LABEL, STATUS_TOKEN_LABEL } from "./Enum";

interface Props extends RouteComponentProps {
}

const TITLE = "Quản lý Token";

const TokenManager: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({
        version_id: "",
        code: "",
        agency_name: "",
        tax_number: ""
    });

    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchVersionId = (e) => {
        setDataSearch({...dataSearch, 'version_id': e});
    };

    const fetchDataFunction = async () => {
        const service = new TokenManagerService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result =  await service.list({...dataSearch, ...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const onClickDeleteButton = id => {
        onDeleteAlert(async willDelete => {
            if (willDelete) {
                const service = new TokenManagerService();
                const result = await service.delete(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa bản ghi token thành công!",
                        reloadPage(props.location, props.history)
                    );
                }else{
                    onFailAction(result.error);
                }
            }
        });
    };
    const onClickLock = code => {
        onLockAlert(async willDelete => {
            if (willDelete) {
                const service = new TokenManagerService();
                const result = await service.lock(code);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Khóa thành công",
                        reloadPage(props.location, props.history)
                    );
                } else {
                    onFailAction(result.error);
                }
            }
        });
    };
    const onClickUnlock = code => {
        onUnLockAlert(async willDelete => {
            if (willDelete) {
                const service = new TokenManagerService();
                const result = await service.unlock(code);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Mở khóa thành công",
                        reloadPage(props.location, props.history)
                    );
                }else{
                    onFailAction(result.error);
                }
            }
        });
    };
    const onClickDelCertificate = code => {
        onDelCertificate(async willDelete => {
            if (willDelete) {
                const service = new TokenManagerService();
                const result = await service.delCertificate(code);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa chứng thư số thành công",
                        reloadPage(props.location, props.history)
                    );
                }else{
                    onFailAction(result.error);
                }
            }
        });
    };
    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={() =>
                    props.history.push(`/quan-ly-token/cap-nhat/${record.id}`)
                }
                onClickView={() =>
                    props.history.push(`/quan-ly-token/xem-chi-tiet/${record.id}`)
                }
                onClickDelete={() => onClickDeleteButton(record.id)}
                onClickLock={()=>onClickLock(record.code)}
                onClickUnlock={()=>onClickUnlock(record.code)}
                onClickDelCertificate={()=>onClickDelCertificate(record.code)}
                isLock={checkLockButton(record)}
                isUnLock={checkUnLockButton(record)}
                isDelCertificate={checkDelCertificateButton(record)}
            />
        );
    };
    const checkLockButton = (record: any) => {
        if (record.token_status === 2 && record.cts_status === 1 && record.is_tms ===1 && record.certificate_status === 1) return true;
        return false;
    };
    const checkUnLockButton = (record: any) => {
        if (record.token_status === 1 && record.cts_status === 1 && record.is_tms ===1 && record.certificate_status === 1) return true;
        return false;
    };
    const checkDelCertificateButton = (record: any) => {
        if (record.cts_status === 1 && record.certificate_status === 1 && record.is_tms ===1) return true;
        return false;
    };
    const [versionTokenList, setVersionTokenList] = useState({});
    useEffect(() => {
        fetchVersionTokenOption();
    }, []);
    const fetchVersionTokenOption = async () => {
        const service = new TokenManagerService();
        const result = await service.getVersionToken();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.code;
        });
        setVersionTokenList(options);
    };
    const [modalImport, setModalImport] = useState({
        visible_modal_deny : false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });

    const handleModalImportCancel = () => {
        setModalImport({...modalImport, 'visible_modal_deny':false})
    };

    const confirmImport = async () => {
        try {
            if (!fileName) {
                setModalImport({...modalImport, 'error_text_deny': "Bạn chưa tải file import lên" });
                return false;
            }
            const service = new TokenManagerService();
            const data = {fileName};
            const result = await service.import(data);
            await setModalImport({...modalImport, 'visible_modal_deny': false });
            if (result && result.status === 200) {
                onSuccessAction("Import dữ liệu thành công!", () => {
                    props.history.push("/quan-ly-token");
                });
            } else if (result && result.status === 404) {
                onFailAction("File import ko đúng mẫu!");
            } else {
                onFailAction(result.error, () => {
                    props.history.push("/quan-ly-token");
                    setFileName("");
                });
            }
        }catch (e) {
            onFailAction("Có lỗi xảy ra!");
        }
    };

    const onChangeDataDeny = ({ target: { value } }) => {
        setModalImport({...modalImport, 'text_deny': value});
    };

    const importClick = () => {
        setModalImport({...modalImport, 'visible_modal_deny': true });
    };

    const [fileName, setFileName] = useState(String);

    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            if(fileData){
                setFileName(fileData.file);
            }
            loading.stopRunLoading();
        } else {
            setFileName("");
        }
    };
    const formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return "";
        }
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <ModalImport
                     visible={modalImport.visible_modal_deny}
                     handleCancel={handleModalImportCancel}
                     handleImport={confirmImport}
                     value={modalImport.text_deny}
                     onChange={onChangeDataDeny}
                     error={modalImport.error_text_deny}
                     onchangeFileUpload={onChangeFile}
                     filename={fileName}
                />
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã token"}
                        />
                        <SelectBox
                            name="version_id"
                            options={versionTokenList}
                            label=""
                            wrappedClass="col-md-2"
                            firstOptionLabel="Version token"
                            onChange={onChangeDataSearchVersionId}
                            value={dataSearch.version_id}
                        />
                        <InputWithLabel
                            name="agency_name"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.agency_name}
                            isRequired={false}
                            label={""}
                            placeholder={"Tên đại lý/ CTV"}
                        />
                        <InputWithLabel
                            name="tax_number"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.tax_number}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã số thuế"}
                        />
                        <div className="col-md-6 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`}/>
                            <ButtonImportExcel onClick={importClick}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Mã token", dataIndex: "code"},
                        {title: "Mã số thuế",
                            dataIndex: "",
                            render: (e) => {
                                if (e) {
                                    if (e.info_cert){
                                        if (  e.info_cert.object === 4 || e.info_cert.object === 5 || e.info_cert.object === 6 ) {
                                            if (e.last_info){
                                                return e.last_info.tax_code
                                            }
                                            return null

                                        }else if (e.info_cert.object === 1 || e.info_cert.object ===  2 || e.info_cert.object === 3) {
                                            if (e.info_cert.request_organ) {
                                                return e.info_cert.request_organ.code
                                            } else if (e.info_cert.request_personal) {
                                                return e.info_cert.request_personal.passport
                                            } else return null
                                        }
                                    }else {
                                        return null;
                                    }
                                }
                            }
                        },
                        {title: "Version token", dataIndex: "version_token.code"},
                        {title: "Đại lý/CTV", dataIndex: "owner.fullname"},
                        {title: "Tình trạng token", dataIndex: "",
                            render: (e) => {
                                const dataRen = STATUS_TOKEN_LABEL[Number(e.token_status)];
                                if (dataRen){
                                    return (<Tag color={dataRen['class']}>{dataRen['label']}</Tag>);
                                } 
                            }
                        },
                        {title: "Trạng thái CTS", dataIndex: "",
                            render: (e) => {
                                if(e.cts_status === 1){
                                    return (<Tag color="green">Đã cấp phát</Tag>);
                                }
                                if(e.cts_status === 2){
                                    return (<Tag color="orange">Chưa cấp phát</Tag>);
                                }
                                if(e.cts_status === 3){
                                    return (<Tag color="blue">Mở generate</Tag>);
                                }
                            }
                        },
                        {title: "Chứng thư số", dataIndex: "",
                            render: (e) => {
                                const dataRen = STATUS_CTS_IN_TOKEN_LABEL[Number(e.certificate_status)];
                                if (dataRen){
                                    return (<Tag color={dataRen['class']}>{dataRen['label']}</Tag>);
                                } 
                            }
                        },
                        {title: "Cập nhật USB token", dataIndex: "",
                            render: (e) => {
                                if(e.update_status === 1){
                                    return (<Tag color="red">Không được cập nhật</Tag>);
                                }
                                if(e.update_status === 2){
                                    return (<Tag className="blue">Có thể cập nhật</Tag>);
                                }
                            }
                        },
                        {
                            title: "Ngày cập nhật",
                            dataIndex: "updated_at",
                            render: (text, record, index) => formatDateTime(text)
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={fetchDataFunction}
                />
            </div>
        </div>
    );
};

export default withNavbar()(TokenManager);
