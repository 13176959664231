import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../common/form/InputWithLabel";
import BackButton from "../../common/button/BackButton";
import SubmitButton from "../../common/form/SubmitButton";
import { History } from "history";
import WrapperButtonDiv from "../../common/form/wrapper-button-form/wrapperButtonForm";
import SettingService from "../../../services/setting/SettingService";
import swal from "sweetalert";
import { Collapse } from "antd";
import { Input } from "antd";
import SelectBox from "./../../common/form/SelectBox";
import RoleService from "./../../../services/role/RoleServices";
import SoftwareService from "../../../services/software/SoftwareService";

const { Panel } = Collapse;

interface Props {
    history: History;
}
const { TextArea } = Input;
interface State {
    version: string;
    num_record: string;
    domain_admin: string;
    domain_qlbh: string;
    url_api_sign: string;
    id_api_sign: string;
    api_gen_pdf: string;
    secret: string;
    method_api_sign: string;
    port_api_sign: string;
    scheme_api_sign: string;
    textout_hsm_sign: string;
    Content_Headers_ContentType: string;
    typesignature_hsm_sign: string;
    localpart_api_sign: string;
    host_api_sign: string;
    list_taxcode: string;
    mail_ceo: string;
    mail_accountant: string;
    mail_staff: string;
    mail_support: string;
    permission_contributor: string;
    permissions: Object;
    tms_url_api: string;
    api_gen_pdf_2: string;
    url_gen_cert_newtel: string;
    port_api_gen_cert_newtel: string;
    scheme_api_gen_cert_newtel: string;
    api_id_authen_newtel: string;
    secret_authen_newtel: string;
    host_api_gen_cert_newtel: string;
    fastca_url_api_sign: string;
    fastca_id_api_sign: string;
    fastca_secret: string;
    fastca_method_api_sign: string;
    fastca_port_api_sign: string;
    fastca_scheme_api_sign: string;
    fastca_textout_hsm_sign: string;
    fastca_Content_Headers_ContentType: string;
    fastca_typesignature_hsm_sign: string;
    fastca_localpart_api_sign: string;
    fastca_host_api_sign: string;
    listSoftware: Object;
    id_soft_plugin_newca: string;
    package_baohanh_name: string;
    package_baohanh_code: string;
    package_caplai_name: string;
    package_caplai_code: string;
    a_side_name: string;
    a_side_represent: string;
    a_side_position: string;
    a_side_address: string;
    a_side_phone: string;
    a_side_email: string;
    a_side_tax_code: string;
    a_side_bank_account_number: string;
    a_side_bank: string;
    domain_config_cyber_id: string;
    cyber_id_config_username: string;
    cyber_id_config_password: string;
    cyber_id_api_get_access_token_no02: string;
    cyber_id_api_get_data_source_no17: string;
    cyber_id_app_clients_id: string;
    cyber_id_api_create_user_no08: string;
    cyber_id_api_find_owner_by_tax_code_no35: string;
    cyber_id_api_change_owner_organization_no36: string;
    cyber_id_api_find_owner_organization_no47: string;
    cyber_id_api_create_organization_no31: string;
    cyber_id_api_get_data_person_no18: string;
    cyber_id_api_create_user_same_phone_no46: string;
    email_name: string;
    email_send: string;
    email_username: string;
    email_smtp: string;
    email_password: string;
    email_port: string;
    email_ssl: string;
    url_seaweed_demo: string;
    url_seaweed_demo_volume: string;
    url_seaweed_local_pro: string;
    url_seaweed_local_pro_volume: string;
    domain_resign: string;
}

class SettingForm extends Component<InjectedFormikProps<Props, State>> {
    state = {
        arr_permissions: [],
        permissions: {},
        listSoftware: {},
    };

    getRoleList = async () => {
        const roleService = new RoleService();
        const lstRole = await roleService.getRoleList(5);
        if (lstRole) {
            let permissions = {};
            lstRole.data.map((value) => (permissions[value.id] = value.name));
            this.setState({ permissions });
        }
    };

    getListSoftwareNewCa = async () => {
        const softwareService = new SoftwareService();
        const listSoftWare = await softwareService.getSoftwares({});
        if (listSoftWare) {
            let listSoftWareState = {};
            listSoftWare.data.data.map(
                (value) => (listSoftWareState[value.id] = value.name)
            );
            this.setState({ listSoftware: listSoftWareState });
        }
    };

    componentDidMount() {
        this.getData().then();
        this.getRoleList().then();
        this.getListSoftwareNewCa().then();
    }

    getData = async () => {
        const service = new SettingService();
        const response = await service.getSetting();
        if (response && response.status === 200) {
            const {
                version,
                num_record,
                domain_admin,
                domain_qlbh,
                url_api_sign,
                id_api_sign,
                api_gen_pdf,
                secret,
                method_api_sign,
                port_api_sign,
                scheme_api_sign,
                textout_hsm_sign,
                Content_Headers_ContentType,
                typesignature_hsm_sign,
                localpart_api_sign,
                host_api_sign,
                fastca_url_api_sign,
                fastca_id_api_sign,
                fastca_secret,
                fastca_method_api_sign,
                fastca_port_api_sign,
                fastca_scheme_api_sign,
                fastca_textout_hsm_sign,
                fastca_Content_Headers_ContentType,
                fastca_typesignature_hsm_sign,
                fastca_localpart_api_sign,
                fastca_host_api_sign,
                list_taxcode,
                mail_ceo,
                mail_accountant,
                mail_staff,
                mail_support,
                permission_contributor,
                tms_url_api,
                api_gen_pdf_2,
                port_api_gen_cert_newtel,
                scheme_api_gen_cert_newtel,
                api_id_authen_newtel,
                secret_authen_newtel,
                host_api_gen_cert_newtel,
                url_gen_cert_newtel,
                id_soft_plugin_newca,
                package_baohanh_name,
                package_baohanh_code,
                package_caplai_name,
                package_caplai_code,
                a_side_name,
                a_side_represent,
                a_side_position,
                a_side_address,
                a_side_phone,
                a_side_email,
                a_side_tax_code,
                a_side_bank_account_number,
                a_side_bank,
                domain_config_cyber_id,
                cyber_id_config_username,
                cyber_id_config_password,
                cyber_id_api_get_access_token_no02,
                cyber_id_api_get_data_source_no17,
                cyber_id_app_clients_id,
                cyber_id_api_create_user_no08,
                cyber_id_api_find_owner_by_tax_code_no35,
                cyber_id_api_change_owner_organization_no36,
                cyber_id_api_find_owner_organization_no47,
                cyber_id_api_create_organization_no31,
                cyber_id_api_get_data_person_no18,
                cyber_id_api_create_user_same_phone_no46,
                email_name,
                email_send,
                email_username,
                email_smtp,
                email_password,
                email_port,
                email_ssl,
                url_seaweed_demo,
                url_seaweed_demo_volume,
                url_seaweed_local_pro,
                url_seaweed_local_pro_volume,
                domain_resign,
            } = response.data;
            this.props.setValues({
                version,
                num_record,
                domain_admin,
                domain_qlbh,
                url_api_sign,
                id_api_sign,
                api_gen_pdf,
                secret,
                method_api_sign,
                port_api_sign,
                scheme_api_sign,
                textout_hsm_sign,
                Content_Headers_ContentType,
                typesignature_hsm_sign,
                localpart_api_sign,
                host_api_sign,
                fastca_url_api_sign,
                fastca_id_api_sign,
                fastca_secret,
                fastca_method_api_sign,
                fastca_port_api_sign,
                fastca_scheme_api_sign,
                fastca_textout_hsm_sign,
                fastca_Content_Headers_ContentType,
                fastca_typesignature_hsm_sign,
                fastca_localpart_api_sign,
                fastca_host_api_sign,
                list_taxcode,
                mail_ceo,
                mail_accountant,
                mail_staff,
                mail_support,
                permission_contributor,
                tms_url_api,
                api_gen_pdf_2,
                port_api_gen_cert_newtel,
                scheme_api_gen_cert_newtel,
                api_id_authen_newtel,
                secret_authen_newtel,
                host_api_gen_cert_newtel,
                url_gen_cert_newtel,
                id_soft_plugin_newca,
                package_baohanh_name,
                package_baohanh_code,
                package_caplai_name,
                package_caplai_code,
                a_side_name,
                a_side_represent,
                a_side_position,
                a_side_address,
                a_side_phone,
                a_side_email,
                a_side_tax_code,
                a_side_bank_account_number,
                a_side_bank,
                domain_config_cyber_id,
                cyber_id_config_username,
                cyber_id_config_password,
                cyber_id_api_get_access_token_no02,
                cyber_id_api_get_data_source_no17,
                cyber_id_app_clients_id,
                cyber_id_api_create_user_no08,
                cyber_id_api_find_owner_by_tax_code_no35,
                cyber_id_api_change_owner_organization_no36,
                cyber_id_api_find_owner_organization_no47,
                cyber_id_api_create_organization_no31,
                cyber_id_api_get_data_person_no18,
                cyber_id_api_create_user_same_phone_no46,
                email_name,
                email_send,
                email_username,
                email_smtp,
                email_password,
                email_port,
                email_ssl,
                url_seaweed_demo,
                url_seaweed_demo_volume,
                url_seaweed_local_pro,
                url_seaweed_local_pro_volume,
                domain_resign,
            } as Pick<State, keyof State>);
        }
    };

    onChangeSoftwareSelect = (e) => {
        this.props.setFieldValue("id_soft_plugin_newca", parseInt(e));
    };

    onChangePermissionSelect = (e) => {
        this.props.setFieldValue("permission_contributor", parseInt(e));
    };

    render() {
        const {
            version,
            num_record,
            domain_admin,
            domain_qlbh,
            api_gen_pdf,
            url_api_sign,
            id_api_sign,
            secret,
            method_api_sign,
            port_api_sign,
            scheme_api_sign,
            textout_hsm_sign,
            Content_Headers_ContentType,
            typesignature_hsm_sign,
            localpart_api_sign,
            host_api_sign,
            fastca_url_api_sign,
            fastca_id_api_sign,
            fastca_secret,
            fastca_method_api_sign,
            fastca_port_api_sign,
            fastca_scheme_api_sign,
            fastca_textout_hsm_sign,
            fastca_Content_Headers_ContentType,
            fastca_typesignature_hsm_sign,
            fastca_localpart_api_sign,
            fastca_host_api_sign,
            list_taxcode,
            mail_ceo,
            mail_accountant,
            mail_staff,
            mail_support,
            permission_contributor,
            tms_url_api,
            api_gen_pdf_2,
            port_api_gen_cert_newtel,
            scheme_api_gen_cert_newtel,
            api_id_authen_newtel,
            secret_authen_newtel,
            host_api_gen_cert_newtel,
            url_gen_cert_newtel,
            id_soft_plugin_newca,
            package_baohanh_name,
            package_baohanh_code,
            package_caplai_name,
            package_caplai_code,
            a_side_name,
            a_side_represent,
            a_side_position,
            a_side_address,
            a_side_phone,
            a_side_email,
            a_side_tax_code,
            a_side_bank_account_number,
            a_side_bank,
            domain_config_cyber_id,
            cyber_id_config_username,
            cyber_id_config_password,
            cyber_id_api_get_access_token_no02,
            cyber_id_api_get_data_source_no17,
            cyber_id_app_clients_id,
            cyber_id_api_create_user_no08,
            cyber_id_api_find_owner_by_tax_code_no35,
            cyber_id_api_change_owner_organization_no36,
            cyber_id_api_find_owner_organization_no47,
            cyber_id_api_create_organization_no31,
            cyber_id_api_get_data_person_no18,
            cyber_id_api_create_user_same_phone_no46,
            email_name,
            email_send,
            email_username,
            email_smtp,
            email_password,
            email_port,
            email_ssl,
            url_seaweed_demo,
            url_seaweed_demo_volume,
            url_seaweed_local_pro,
            url_seaweed_local_pro_volume,
            domain_resign,
        } = this.props.values;
        return (
            <React.Fragment>
                <form onSubmit={this.props.handleSubmit}>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header="Cấu hình chung" key="1">
                            <div className="input-group">
                                <InputWithLabel
                                    name="version"
                                    label="Version"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={version}
                                    error={this.props.errors.version}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="num_record"
                                    label="Số bản ghi trên 1 trang"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={num_record}
                                    error={this.props.errors.num_record}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="domain_admin"
                                    label="Domain amdin"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={domain_admin}
                                    error={this.props.errors.domain_admin}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="domain_qlbh"
                                    label="Domain QLBH"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={domain_qlbh}
                                    error={this.props.errors.domain_qlbh}
                                    isRequired={true}
                                />
                                <SelectBox
                                    options={this.state.permissions}
                                    label="Quyền mặc định cộng tác viên"
                                    name="permission_contributor"
                                    wrappedClass="col-md-2 form-group"
                                    onChange={this.onChangePermissionSelect}
                                    value={permission_contributor}
                                    firstOptionLabel="Chọn vai trò"
                                    error={
                                        this.props.errors.permission_contributor
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="tms_url_api"
                                    label="TMS url api"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={tms_url_api}
                                    error={this.props.errors.tms_url_api}
                                    isRequired={true}
                                />
                                <SelectBox
                                    options={this.state.listSoftware}
                                    label="Lựa chọn phần mềm Plugin NewCA"
                                    name="id_soft_plugin_newca"
                                    wrappedClass="col-md-2 form-group"
                                    onChange={this.onChangeSoftwareSelect}
                                    value={
                                        id_soft_plugin_newca
                                            ? id_soft_plugin_newca
                                            : ""
                                    }
                                    firstOptionLabel="Plugin NewCA"
                                    error={
                                        this.props.errors.id_soft_plugin_newca
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="domain_resign"
                                    label="Link ký lại dk02"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={domain_resign}
                                    error={this.props.errors.domain_resign}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cầu hình ký số tự động" key="2">
                            <div className="input-group">
                                <InputWithLabel
                                    name="url_api_sign"
                                    label="Api ký file"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={url_api_sign}
                                    error={this.props.errors.url_api_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="secret"
                                    label="Secret"
                                    wrapClass="col-md-5 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={secret}
                                    error={this.props.errors.secret}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="method_api_sign"
                                    label="Phương thức"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={method_api_sign}
                                    error={this.props.errors.method_api_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="port_api_sign"
                                    label="Port"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={port_api_sign}
                                    error={this.props.errors.port_api_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="scheme_api_sign"
                                    label="Scheme"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={scheme_api_sign}
                                    error={this.props.errors.scheme_api_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="textout_hsm_sign"
                                    label="Text HSM"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={textout_hsm_sign}
                                    error={this.props.errors.textout_hsm_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="Content_Headers_ContentType"
                                    label="Content Headers ContentType"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={Content_Headers_ContentType}
                                    error={
                                        this.props.errors
                                            .Content_Headers_ContentType
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="typesignature_hsm_sign"
                                    label="Type hsm sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={typesignature_hsm_sign}
                                    error={
                                        this.props.errors.typesignature_hsm_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="localpart_api_sign"
                                    label="Path sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={localpart_api_sign}
                                    error={this.props.errors.localpart_api_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="host_api_sign"
                                    label="Host sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={host_api_sign}
                                    error={this.props.errors.host_api_sign}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="id_api_sign"
                                    label="Id api sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={id_api_sign}
                                    error={this.props.errors.id_api_sign}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel
                            header="Cầu hình ký số tự động - FastCA"
                            key="21"
                        >
                            <div className="input-group">
                                <InputWithLabel
                                    name="fastca_url_api_sign"
                                    label="Api ký file"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_url_api_sign}
                                    error={
                                        this.props.errors.fastca_url_api_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_secret"
                                    label="Secret"
                                    wrapClass="col-md-5 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_secret}
                                    error={this.props.errors.fastca_secret}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_method_api_sign"
                                    label="Phương thức"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_method_api_sign}
                                    error={
                                        this.props.errors.fastca_method_api_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_port_api_sign"
                                    label="Port"
                                    wrapClass="col-md-2 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_port_api_sign}
                                    error={
                                        this.props.errors.fastca_port_api_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_scheme_api_sign"
                                    label="Scheme"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_scheme_api_sign}
                                    error={
                                        this.props.errors.fastca_scheme_api_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_textout_hsm_sign"
                                    label="Text HSM"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_textout_hsm_sign}
                                    error={
                                        this.props.errors
                                            .fastca_textout_hsm_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_Content_Headers_ContentType"
                                    label="Content Headers ContentType"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_Content_Headers_ContentType}
                                    error={
                                        this.props.errors
                                            .fastca_Content_Headers_ContentType
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_typesignature_hsm_sign"
                                    label="Type hsm sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_typesignature_hsm_sign}
                                    error={
                                        this.props.errors
                                            .fastca_typesignature_hsm_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_localpart_api_sign"
                                    label="Path sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_localpart_api_sign}
                                    error={
                                        this.props.errors
                                            .fastca_localpart_api_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_host_api_sign"
                                    label="Host sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_host_api_sign}
                                    error={
                                        this.props.errors.fastca_host_api_sign
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="fastca_id_api_sign"
                                    label="Id api sign"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={fastca_id_api_sign}
                                    error={this.props.errors.fastca_id_api_sign}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình tạo file PDF" key="3">
                            <div className="input-group">
                                <InputWithLabel
                                    name="api_gen_pdf"
                                    label="Api tạo file PDF"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={api_gen_pdf}
                                    error={this.props.errors.api_gen_pdf}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="api_gen_pdf_2"
                                    label="Api tạo file PDF(Không đính kèm ô ký)"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={api_gen_pdf_2}
                                    error={this.props.errors.api_gen_pdf_2}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình gửi mail Cấp bù" key="4">
                            <div className="input-group">
                                <InputWithLabel
                                    name="email_name"
                                    label="Email name"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_name}
                                    error={this.props.errors.email_name}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="email_send"
                                    label="Email send"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_send}
                                    error={this.props.errors.email_send}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="email_username"
                                    label="Username"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_username}
                                    error={this.props.errors.email_username}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="email_password"
                                    label="Email password"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_password}
                                    error={this.props.errors.email_password}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="email_smtp"
                                    label="Email SMTP"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_smtp}
                                    error={this.props.errors.email_smtp}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="email_port"
                                    label="Email Port"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_port}
                                    error={this.props.errors.email_port}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="email_ssl"
                                    label="Email SSL"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={email_ssl}
                                    error={this.props.errors.email_ssl}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình danh sách mã số thuế" key="5">
                            <div className="input-group">
                                <TextArea
                                    name="list_taxcode"
                                    placeholder="Danh sách mã số thuế"
                                    autoSize={{ minRows: 4, maxRows: 10 }}
                                    className="form-control"
                                    required={false}
                                    value={list_taxcode}
                                    onChange={this.props.handleChange}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình địa chỉ email" key="6">
                            <div className="input-group">
                                <InputWithLabel
                                    name="mail_ceo"
                                    label="Mail giám đốc"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={mail_ceo}
                                    error={this.props.errors.mail_ceo}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="mail_accountant"
                                    label="Mail kế toán"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={mail_accountant}
                                    error={this.props.errors.mail_accountant}
                                    isRequired={true}
                                />
                            </div>
                            <div className="input-group">
                                <InputWithLabel
                                    name="mail_staff"
                                    label="Mail nghiệp vụ"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={mail_staff}
                                    error={this.props.errors.mail_staff}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="mail_support"
                                    label="Mail hỗ trợ kinh doanh"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={mail_support}
                                    error={this.props.errors.mail_support}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình Newtel" key="8">
                            <div className="input-group">
                                <InputWithLabel
                                    name="url_gen_cert_newtel"
                                    label="Url api sinh chứng thư số"
                                    wrapClass="col-md-4 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={url_gen_cert_newtel}
                                    error={
                                        this.props.errors.url_gen_cert_newtel
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="port_api_gen_cert_newtel"
                                    label="Port api sinh chứng thư số"
                                    wrapClass="col-md-4 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={port_api_gen_cert_newtel}
                                    error={
                                        this.props.errors
                                            .port_api_gen_cert_newtel
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="scheme_api_gen_cert_newtel"
                                    label="Schema api sinh chứng thư số"
                                    wrapClass="col-md-4 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={scheme_api_gen_cert_newtel}
                                    error={
                                        this.props.errors
                                            .scheme_api_gen_cert_newtel
                                    }
                                    isRequired={true}
                                />
                            </div>
                            <div className="input-group">
                                <InputWithLabel
                                    name="api_id_authen_newtel"
                                    label="Api id authen sinh chứng thư số"
                                    wrapClass="col-md-4 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={api_id_authen_newtel}
                                    error={
                                        this.props.errors.api_id_authen_newtel
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="secret_authen_newtel"
                                    label="Secret authen sinh chứng thư số"
                                    wrapClass="col-md-4 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={secret_authen_newtel}
                                    error={
                                        this.props.errors.secret_authen_newtel
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="host_api_gen_cert_newtel"
                                    label="Host api sinh chứng thư số"
                                    wrapClass="col-md-4 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={host_api_gen_cert_newtel}
                                    error={
                                        this.props.errors
                                            .host_api_gen_cert_newtel
                                    }
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình gói bảo hành" key="9">
                            <div className="input-group">
                                <InputWithLabel
                                    name="package_baohanh_name"
                                    label="Tên hiển thị gói"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={package_baohanh_name}
                                    error={
                                        this.props.errors.package_baohanh_name
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="package_baohanh_code"
                                    label="Mã hiển thị gói"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={package_baohanh_code}
                                    error={
                                        this.props.errors.package_baohanh_code
                                    }
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình gói cấp lại" key="10">
                            <div className="input-group">
                                <InputWithLabel
                                    name="package_caplai_name"
                                    label="Tên hiển thị gói"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={package_caplai_name}
                                    error={
                                        this.props.errors.package_caplai_name
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="package_caplai_code"
                                    label="Mã hiển thị gói"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={package_caplai_code}
                                    error={
                                        this.props.errors.package_caplai_code
                                    }
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel
                            header="Cầu hình ký số tự động dk03 thông tin bên A"
                            key="25"
                        >
                            <div className="input-group">
                                <InputWithLabel
                                    name="a_side_name"
                                    label="Tên đầy đủ"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_name}
                                    error={this.props.errors.a_side_name}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_represent"
                                    label="Người đại diện"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_represent}
                                    error={this.props.errors.a_side_represent}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_position"
                                    label="Chức vụ"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_position}
                                    error={this.props.errors.a_side_position}
                                    isRequired={true}
                                />

                                <InputWithLabel
                                    name="a_side_phone"
                                    label="Số điện thoại"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_phone}
                                    error={this.props.errors.a_side_phone}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_email"
                                    label="Email"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_email}
                                    error={this.props.errors.a_side_email}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_tax_code"
                                    label="Mã số thuế"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_tax_code}
                                    error={this.props.errors.a_side_tax_code}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_bank_account_number"
                                    label="Số tài khoản ngân hàng"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_bank_account_number}
                                    error={
                                        this.props.errors
                                            .a_side_bank_account_number
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_bank"
                                    label="Ngân hàng"
                                    wrapClass="col-md-12 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_bank}
                                    error={this.props.errors.a_side_bank}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="a_side_address"
                                    label="Địa chỉ"
                                    wrapClass="col-md-12 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={a_side_address}
                                    error={this.props.errors.a_side_address}
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình kết nối với CyberID" key="26">
                            <div className="input-group">
                                <InputWithLabel
                                    name="domain_config_cyber_id"
                                    label="Domain CyberID"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={domain_config_cyber_id}
                                    error={
                                        this.props.errors.domain_config_cyber_id
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_config_username"
                                    label="CyberID username"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_config_username}
                                    error={
                                        this.props.errors
                                            .cyber_id_config_username
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_config_password"
                                    label="CyberID password"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_config_password}
                                    error={
                                        this.props.errors
                                            .cyber_id_config_password
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_get_access_token_no02"
                                    label="Api get access token No02"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_api_get_access_token_no02}
                                    error={
                                        this.props.errors
                                            .cyber_id_api_get_access_token_no02
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_get_data_source_no17"
                                    label="Api get data source No17"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_api_get_data_source_no17}
                                    error={
                                        this.props.errors
                                            .cyber_id_api_get_data_source_no17
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_app_clients_id"
                                    label="App clients id"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_app_clients_id}
                                    error={
                                        this.props.errors
                                            .cyber_id_app_clients_id
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_create_user_no08"
                                    label="Api create user No08"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_api_create_user_no08}
                                    error={
                                        this.props.errors
                                            .cyber_id_api_create_user_no08
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_find_owner_by_tax_code_no35"
                                    label="Api find owner by tax code No35"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={
                                        cyber_id_api_find_owner_by_tax_code_no35
                                    }
                                    error={
                                        this.props.errors
                                            .cyber_id_api_find_owner_by_tax_code_no35
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_change_owner_organization_no36"
                                    label="Api change owner organization No36"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={
                                        cyber_id_api_change_owner_organization_no36
                                    }
                                    error={
                                        this.props.errors
                                            .cyber_id_api_change_owner_organization_no36
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_find_owner_organization_no47"
                                    label="Api find owner organization No47"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={
                                        cyber_id_api_find_owner_organization_no47
                                    }
                                    error={
                                        this.props.errors
                                            .cyber_id_api_find_owner_organization_no47
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_create_organization_no31"
                                    label="Api create organization No31"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={
                                        cyber_id_api_create_organization_no31
                                    }
                                    error={
                                        this.props.errors
                                            .cyber_id_api_create_organization_no31
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="domain_config_cyber_id"
                                    label="Domain Cyber ID"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={domain_config_cyber_id}
                                    error={
                                        this.props.errors.domain_config_cyber_id
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_get_data_person_no18"
                                    label="Api get data person No18"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={cyber_id_api_get_data_person_no18}
                                    error={
                                        this.props.errors
                                            .cyber_id_api_get_data_person_no18
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="cyber_id_api_create_user_same_phone_no46"
                                    label="Api create user same phone"
                                    wrapClass="col-md-3 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={
                                        cyber_id_api_create_user_same_phone_no46
                                    }
                                    error={
                                        this.props.errors
                                            .cyber_id_api_create_user_same_phone_no46
                                    }
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                        <Panel header="Cấu hình lưu trữ File" key="11">
                            <div className="input-group">
                                <InputWithLabel
                                    name="url_seaweed_demo"
                                    label="URL master demo"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={url_seaweed_demo}
                                    error={this.props.errors.url_seaweed_demo}
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="url_seaweed_demo_volume"
                                    label="URL volumn demo"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={url_seaweed_demo_volume}
                                    error={
                                        this.props.errors
                                            .url_seaweed_demo_volume
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="url_seaweed_local_pro"
                                    label="URL master pro"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={url_seaweed_local_pro}
                                    error={
                                        this.props.errors.url_seaweed_local_pro
                                    }
                                    isRequired={true}
                                />
                                <InputWithLabel
                                    name="url_seaweed_local_pro_volume"
                                    label="URL volumn pro"
                                    wrapClass="col-md-6 form-group"
                                    onChangeValue={this.props.handleChange}
                                    value={url_seaweed_local_pro_volume}
                                    error={
                                        this.props.errors
                                            .url_seaweed_local_pro_volume
                                    }
                                    isRequired={true}
                                />
                            </div>
                        </Panel>
                    </Collapse>
                    <WrapperButtonDiv>
                        <BackButton history={this.props.history} />
                        <SubmitButton onClick={this.props.submitForm} />
                    </WrapperButtonDiv>
                </form>
            </React.Fragment>
        );
    }
}

const settingUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        version: "",
        num_record: "",
        domain_admin: "",
        domain_qlbh: "",
        api_gen_pdf: "",
        url_api_sign: "",
        id_api_sign: "",
        secret: "",
        method_api_sign: "",
        port_api_sign: "",
        scheme_api_sign: "",
        textout_hsm_sign: "",
        Content_Headers_ContentType: "",
        typesignature_hsm_sign: "",
        localpart_api_sign: "",
        host_api_sign: "",
        fastca_url_api_sign: "",
        fastca_id_api_sign: "",
        fastca_secret: "",
        fastca_method_api_sign: "",
        fastca_port_api_sign: "",
        fastca_scheme_api_sign: "",
        fastca_textout_hsm_sign: "",
        fastca_Content_Headers_ContentType: "",
        fastca_typesignature_hsm_sign: "",
        fastca_localpart_api_sign: "",
        fastca_host_api_sign: "",
        list_taxcode: "",
        mail_ceo: "",
        mail_accountant: "",
        mail_staff: "",
        mail_support: "",
        permission_contributor: "",
        permissions: "",
        tms_url_api: "",
        api_gen_pdf_2: "",
        port_api_gen_cert_newtel: "",
        scheme_api_gen_cert_newtel: "",
        api_id_authen_newtel: "",
        secret_authen_newtel: "",
        host_api_gen_cert_newtel: "",
        url_gen_cert_newtel: "",
        listSoftware: "",
        id_soft_plugin_newca: "",
        package_baohanh_code: "",
        package_baohanh_name: "",
        package_caplai_name: "",
        package_caplai_code: "",
        a_side_name: "",
        a_side_represent: "",
        a_side_position: "",
        a_side_address: "",
        a_side_phone: "",
        a_side_email: "",
        a_side_tax_code: "",
        a_side_bank_account_number: "",
        a_side_bank: "",
        domain_config_cyber_id: "",
        cyber_id_config_username: "",
        cyber_id_config_password: "",
        cyber_id_api_get_access_token_no02: "",
        cyber_id_api_get_data_source_no17: "",
        cyber_id_app_clients_id: "",
        cyber_id_api_create_user_no08: "",
        cyber_id_api_find_owner_by_tax_code_no35: "",
        cyber_id_api_change_owner_organization_no36: "",
        cyber_id_api_find_owner_organization_no47: "",
        cyber_id_api_create_organization_no31: "",
        cyber_id_api_get_data_person_no18: "",
        cyber_id_api_create_user_same_phone_no46: "",
        email_name: "",
        email_send: "",
        email_username: "",
        email_smtp: "",
        email_password: "",
        email_port: "",
        email_ssl: "",
        url_seaweed_demo: "",
        url_seaweed_demo_volume: "",
        url_seaweed_local_pro: "",
        url_seaweed_local_pro_volume: "",
        domain_resign: "",
    }),
    validationSchema: Yup.object().shape({
        version: Yup.string().required("Phiên bản không được bỏ trống."),
        num_record: Yup.string().required("Số bản ghi không được bỏ trống."),
        domain_admin: Yup.string().required(
            "Domain admin không được bỏ trống."
        ),
        domain_qlbh: Yup.string().required("Domain QLBH không được bỏ trống."),
        permission_contributor: Yup.string().required(
            "Quyền CTV không được bỏ trống."
        ),
        id_soft_plugin_newca: Yup.string().required(
            "Phần mềm plugin NewCA không được bỏ trống"
        ),
    }),

    handleSubmit: async (values, { setSubmitting, props }) => {
        setSubmitting(false);
        const data = new SettingService();
        const result = await data.updateSetting({ ...values });
        if (result && result.status === 200) {
            swal({
                title: "Thành công!",
                text: "Cập nhật thành công!",
                icon: "success",
                dangerMode: false,
            }).then(function () {
                props.history.push("/cau-hinh-he-thong");
            });
        }
    },
})(SettingForm);

export default settingUpdateForm;
