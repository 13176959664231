import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import CateContractService from "../../../../services/category-contract/CateContractService";
import ModalView from "../../../common/form/Modal";
import StopAgencyService from "../../../../services/stop-agency/StopAgencyService";
import DenyButton from "../../../common/button/DenyButton";
import ModalDeny from "../../../common/form/ModalDeny";
import { checkPermission } from "../../../../helpers/NewCaHelper";

interface FormValues {
    file_type: string;
    result: string;
    file: string;
    status: number;
}
const CONFIRM_REQUEST_STOP = 'confirm-request-stop-cooperater';
interface OtherProps extends RouteComponentProps<{id: string;}> {}
type Props = OtherProps & FormikProps<FormValues>;

const StopAgencyView: React.FC<Props> = props => {
    const service = new StopAgencyService();
    const handleModalCancel = () => {
        setModal({...modal,'visible_modal': false}) ;
    };
    const [data, setData] = useState({
        type_user : "",
        owner_name: "",
        short_name: "",
        owner_code: "",
        owner_tax_code: "",
    });
    const {file_type, file} = props.values;
    const onFetchDataOwner = async () => {
        const data = await service.getDataOwnerStop(id);
        var taxCode;
        var typeUser;
        if (data.data.passport){
            taxCode = data.data.passport;
            typeUser = 'Cộng tác viên';
        }else{
            taxCode = data.data.tax_code;
            typeUser = 'Đại lý';
        }
        setData({...data,
            'type_user': typeUser,
            'owner_name': data.data.fullname,
            'short_name': data.data.sortname,
            'owner_code': data.data.code,
            'owner_tax_code': taxCode,
        });
    };

    useEffect(() => {
        onFetchDataOwner();
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const onClickModalFile = async () => {
        const result = await service.getLiquidationFile(id);
        if (result && result.status === 200) {
            setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'biên bản thanh lý','type_file': 'application/pdf'});
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra!");
    };

    const [modal, setModal] = useState({
        visible_modal : false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny : false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.find(id);
        props.setFieldValue(result, result.data.result);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
    };

    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny':false})
    };

    const confirmDeny = async () => {
        if (modalDeny.text_deny.replace(/\s+/g, '') === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống" });
            return false;
        }
        const data = {data:modalDeny.text_deny, id:id};
        const result = await service.ceoRefuse(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false });
        if (result && result.status === 200) {
            onSuccessAction("Từ chối đại lý thành công!", () => {
                props.history.push("/dung-hop-tac");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({ target: { value } }) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };

    const submitDeny = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': true });
    };

    const submitApprove = async () => {
        const result = await service.getCeoApprove(id);
        if (result && result.status === 200) {
            onSuccessAction("Duyệt và ký thành công!", () => {
                props.history.push("/dung-hop-tac");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt và ký!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt và ký!");
        }
    };

    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Chi tiết yêu cầu dừng hợp tác</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Loại người dùng"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.type_user}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Tên đại lý/CTV"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Mã số thế/CMND"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_tax_code}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Tên viết tắt"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.short_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Mã đại lý/CTV"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_code}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="file_type"
                            options={{
                                1: "Điện tử",
                                2: "Giấy"
                            }}
                            label="Loại biên bản thanh lý"
                            firstOptionLabel="-- Chọn loại biên bản thanh lý --"
                            wrappedClass="col-md-2"
                            onChange={props.handleChange}
                            value={file_type}
                            error={props.errors.file_type}
                            isRequired={true}
                            isDisabled={true}
                        />
                        {(file ?
                        <div className="col-md-2">
                            <label className="invisible">123123123123</label>
                            <button type="button" className="btn btn-primary ml-1 mr-1 p-b-b-3" onClick={onClickModalFile}>
                                <i className="fas fa-file-pdf mr-1"/>Biên bản thanh lý
                            </button>
                        </div>
                        : "")}
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">

                        {(props.values.status === 4 && checkPermission(CONFIRM_REQUEST_STOP)) ?
                            (
                                <SubmitButton onClick={submitApprove} text=" Giám đốc duyệt" iconClass="fas fa-check"/>
                            )
                            : (" ")
                        }
                        {(props.values.status === 4 && checkPermission(CONFIRM_REQUEST_STOP)) ?
                            (
                                <DenyButton onClick={submitDeny}/>
                            )
                            : (" ")
                        }
                        <BackButton
                            history={props.history}
                            url="/dung-hop-tac"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        file_type: "",
        result:"",
        file:"",
        status:0,
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(StopAgencyView);

export default withNavbar()(RenderCreateForm);
