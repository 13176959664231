import React, {Component} from "react";
import LabelInput from "./label/LabelInput";
import TextArea from "antd/lib/input/TextArea";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";

interface Props {
    name: string;
    label: string;
    wrapClass: string;
    placeholder?: string;
    onChangeValue?: any;
    value?: any;
    error?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    maxLength?: number;
    rows: number;
}
interface State {

}
export default class TextAreaWithLabel extends Component<Props, State> {
    state = {};
    render() {
        return (
            <div className={`form-group ${this.props.wrapClass}`}>
                <LabelInput
                    nameFor={this.props.name}
                    label={this.props.label}
                    isRequired={this.props.isRequired}
                />
                <TextArea
                    disabled={this.props.isDisabled}
                    onChange={this.props.onChangeValue}
                    name={this.props.name}
                    rows={this.props.rows}
                    value={this.props.value}
                    autosize= {{ minRows: 2, maxRows: 6 }}
                />
                <ValidateMessageError error={this.props.error}/>
            </div>
        );
    }
}
