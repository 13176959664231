import React from "react";
import ActionButton from './../../common/button/action/ActionButton';
interface Props {
  onClickUpdate?: () => void;
  onClickLock?: () => void;
  onClickDelete?: () => void;
  onClickView?: () => void;
  onClickConfirmButton?: () => void;
  permissionConfirm? : string;
  tittle? : string;
  mesDelete? : string;
  icon? : string;
}

export default function TableActionButton(props: Props) {
  return (
    <div className="row justify-content-center">
      {props.permissionConfirm ? (
        <ActionButton
          type="primary"
          icon= {props.icon ? props.icon : "fa-check-circle"}
          onClick={
            props.onClickConfirmButton ? props.onClickConfirmButton : () => {}
          }
          title={props.tittle ? props.tittle : "Duyệt"}
          isDeleteButton={true}
          customMessage={props.mesDelete}
        />
      ) : (
        ""
      )}
    </div>
  );
}
