import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import CKEditor from 'ckeditor4-react';
import ModalView from "../../../common/form/Modal";
import StopAgencyService from "../../../../services/stop-agency/StopAgencyService";

interface FormValues {
    file_type: string;
    result: string;
}

interface OtherProps extends RouteComponentProps<{id: string;}> {}

type Props = OtherProps & FormikProps<FormValues>;

const StopAgencyUpdate: React.FC<Props> = props => {
    const service = new StopAgencyService();
    const handleModalCancel = () => {
        setModal({...modal,'visible_modal': false}) ;
    };

    const [data, setData] = useState({
        type_user : "",
        owner_name: "",
        short_name: "",
        owner_code: "",
        owner_tax_code: "",
    });
    const {file_type, result} = props.values;
    const onFetchDataOwner = async () => {
        const data = await service.getDataOwnerStop(id);
        var taxCode;
        var typeUser;
        if (data.data.passport){
            taxCode = data.data.passport;
            typeUser = 'Cộng tác viên';
        }else{
            taxCode = data.data.tax_code;
            typeUser = 'Đại lý';
        }
        setData({...data,
            'type_user': typeUser,
            'owner_name': data.data.fullname,
            'short_name': data.data.sortname,
            'owner_code': data.data.code,
            'owner_tax_code': taxCode,
        });
    };
    const onchangeType = (e) =>{
        props.setFieldValue('file_type', e);
    };
    useEffect(() => {
        onFetchDataOwner();
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const [dataEditor, setdataEditor] = useState({});
    const onClickModalFile = async () => {
        const data = {data:dataEditor,id:id};
        const result = await service.getBase64(data);
        if (result && result.status === 200) {
            setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'trước biên bản thanh lý điện tử','type_file': 'pdf'});
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra!");
    };
    const [modal, setModal] = useState({
        visible_modal : false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.find(id);
        setdataEditor(result.data.content);
        props.setFieldValue(result, result.data.result);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
    };

    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <h4 className="card-title1 ml-3">Cập nhật yêu cầu dừng hợp tác</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Loại người dùng"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.type_user}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Tên đại lý/CTV"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Mã số thế/CMND"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_tax_code}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name=""
                            label="Tên viết tắt"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name=""
                            label="Mã đại lý/CTV"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={data.owner_code}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="file_type"
                            options={{
                                1: "Điện tử",
                                2: "Giấy"
                            }}
                            label="Loại biên bản thanh lý"
                            firstOptionLabel="-- Chọn loại biên bản thanh lý --"
                            wrappedClass="col-md-4"
                            onChange={onchangeType}
                            value={file_type}
                            error={props.errors.file_type}
                            isRequired={true}
                        />
                    </div>
                    <div className="col-md-12" >
                        <label>Kết quả thực hiện hợp đồng</label>
                        <CKEditor
                            name ="content"
                            onChange={ ( event) => {
                                const data = event.editor.getData();
                                setdataEditor(data);
                                props.setFieldValue('result',data);
                            }}
                            data = {result}
                        />
                    </div>
                    <div className="col-md-12" ><a href="/#" onClick={onClickModalFile}>Xem trước</a></div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <SubmitButton onClick={props.handleSubmit} text="Trình giám đốc"/>
                        <BackButton
                            history={props.history}
                            url="/dung-hop-tac"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        file_type: "",
        result:"",
    }),
    validationSchema: Yup.object().shape({
        file_type: Yup.string().required("Loại biên bản thanh lý không được bỏ trống"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new StopAgencyService();
        const result = await service.store({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(StopAgencyUpdate);

export default withNavbar()(RenderCreateForm);
