import React, {useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, queryStringToJSON, reloadPage} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import {onDeleteAlert, onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import BannerService from "../../../services/banner/BannerService";
import {Tag} from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Danh sách banner";

const Banner: React.FC<Props> = props => {

    /**
     * Define
     */
    const service = new BannerService();

    /**
     * States
     */
    const [dataSearch, setDataSearch] = useState({name: "", status: ""});

    /**
     *Function
     */
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search) {
            if (key === 'raw') {
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeDataSearchName = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchStatus = e => {
        setDataSearch({...dataSearch, status: e});
    };
    const onRenderActionColumn = (text, record) => {
        return (
            <TableActionButton
                onClickUpdate={() =>
                    props.history.push(`/banner/cap-nhat/${record.id}`)
                }
                isDisplayUpdateButton={true}
                onClickDelete={() => onClickDeleteButton(record.id)}
            />
        );
    };
    const onClickDeleteButton = id => {
        onDeleteAlert(async willDelete => {
            if (willDelete) {
                const service = new BannerService();
                const result = await service.delete({id: id});
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa bản ghi banner thành công!", () => {
                            reloadPage(props.location, props.history)
                        }
                    );
                }
            }
        });
    };

    /**
     * Fetch
     */
    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch, ...queryString});
            if (Number(result.status) === 200) {
                return result;
            } else {
                onFailAction("Có lỗi xảy ra");
            }
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="name"
                            wrapClass="col-md-2 form-group pl-0"
                            onChangeValue={onChangeDataSearchName}
                            value={dataSearch.name}
                            isRequired={false}
                            label={""}
                            placeholder={"Tên ảnh"}
                        />
                        <SelectBox
                            name="status"
                            options={{1: "Hoạt động", 2: "Không hoạt động"}}
                            wrappedClass="col-md-2 form-group pl-0"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.status}
                            label=""
                            firstOptionLabel="Trạng thái"
                        />
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Tên", dataIndex: "name"},
                        {title: "Thứ tự ưu tiên", dataIndex: 'order_by',},
                        {title: "Đường dẫn liên hệ", dataIndex: "link"},
                        {
                            title: "Ảnh",
                            dataIndex: "",
                            render: e => <img width={'100%'} height={100} alt={e.path} src={`data:image/jpeg;base64,${e.base64_content}`}/>,
                            width: 200
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (e) => {
                                if (Number(e) === 1) {
                                    return (<Tag color="green">Hoạt động</Tag>);
                                }
                                if (Number(e) === 2) {
                                    return (<Tag color="red">Không hoạt động</Tag>);
                                } else {
                                    return (<Tag color="cyan">Không xác định</Tag>);
                                }
                            }
                        },
                        {title: "Mô tả", dataIndex: "description"},
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(Banner);
