import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/uc-token";
class ApproveUcService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            `${PREFIX_URL}/index`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async approve(id) {
        const result = await this.request(
            `${PREFIX_URL}/approve/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
}

export default ApproveUcService;
