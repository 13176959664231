import React, { Component } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import CreateForm from "./CreateForm";
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps{
}
interface State {
}

class CreateUser extends Component<Props, State> {
  state = {
  };

  onChangeInput = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    } as any);
  };

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <CreateForm history={this.props.history}/>
        </div>
      </div>
    );
  }
}

const createUserWithNavbar = withNavbar()(CreateUser);

export default createUserWithNavbar;
