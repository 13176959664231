import React, { Component } from "react";
import "../css/main.css";
import SelectBox from "../form/wh/SelectBox";
import InputWithLabel from "../form/InputWithLabel";
import { Popconfirm } from "antd";

interface Props {
  assetList?: any;
  changeData?: any;
  errors?: any;
  data?: any;
  onDelete?: any;
  keyDelete?: any;
  tittle?: string;
}
interface State {}

export default class Table extends Component<Props, State> {
  componentDidMount() {}

  render() {
    const dataSource = this.props.data;
    return (
      <table className="w-100 m-t-20 ant-table-bordered" key="2">
        <thead className="ant-table-thead">
          <tr>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">STT</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center w-40">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    {this.props.tittle
                      ? this.props.tittle
                      : "Danh mục quà tặng"}
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>

            <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Số lượng</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>

            <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Đơn vị</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Số lượng còn lại
                  </span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
            <th className="text-center">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Tác vụ</span>
                  <span className="ant-table-column-sorter"></span>
                </div>
              </span>
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {dataSource ? (
            Object.keys(dataSource).map((key, index) => (
              <tr
                className={`ant-table-row editable-row ant-table-row-level-${key}`}
                data-row-key={key}
                key={key}
              >
                <td className="text-center">{1 + Number(key)}</td>
                <td>
                  <SelectBox
                    name={`gift_asset_id${key}`}
                    options={this.props.assetList}
                    label=""
                    wrappedClass=""
                    firstOptionLabel={
                      this.props.tittle
                        ? "Chọn " + this.props.tittle
                        : "Chọn danh mục quà tặng"
                    }
                    onChange={this.props.changeData}
                    value={dataSource[key]["gift_asset_id"]}
                    error={""}
                    isRequired={true}
                    dataKey={"gift_asset_id"}
                  />
                </td>
                <td className="text-center">
                  <InputWithLabel
                    name={`gift_amount${key}`}
                    label=""
                    wrapClass=""
                    onChangeValue={this.props.changeData}
                    value={dataSource[key]["gift_amount"]}
                    error={
                      this.props.errors.dataGift &&
                      dataSource[key]["gift_amount"] !== "" &&
                      (!Number.isInteger(
                        Number(dataSource[key]["gift_amount"])
                      ) ||
                        Number(dataSource[key]["gift_amount"]) < 1)
                        ? "Số lượng phải là số nguyên dương"
                        : "" ||
                          (this.props.errors.dataGift &&
                            dataSource[key]["gift_amount"] === "")
                        ? "Số lượng không được trống"
                        : "" ||
                          Number(dataSource[key]["gift_amount"]) >
                            Number(dataSource[key]["gift_rest"])
                        ? "Số lượng không đủ"
                        : ""
                    }
                    isRequired={true}
                    maxLength={255}
                    dataKey={"gift_amount"}
                    type="number"
                  />
                </td>
                <td className="text-center">
                  <InputWithLabel
                    name={`gift_unit${key}`}
                    label=""
                    wrapClass=""
                    onChangeValue={this.props.changeData}
                    value={dataSource[key]["gift_unit"]}
                    isRequired={true}
                    maxLength={255}
                    dataKey={"gift_unit"}
                  />
                </td>
                <td className="text-center">{dataSource[key]["gift_rest"]}</td>
                <td className="text-center">
                  <Popconfirm
                    title="Bạn có chắc chắn xóa?"
                    onConfirm={this.props.onDelete}
                    okText="Xóa"
                    cancelText="Bỏ qua"
                  >
                    <a
                      href="/#"
                      className="pointer text-danger ml-1 mr-1"
                      title="Xóa"
                      onClick={this.props.keyDelete}
                    >
                      <i className="fas fa-trash-alt fa-lg"></i>
                    </a>
                  </Popconfirm>
                </td>
              </tr>
            ))
          ) : (
            <tr></tr>
          )}
        </tbody>
      </table>
    );
  }
}
