import React, { Component } from "react";
import "../css/main.css";

interface Props {
  onClick?: any;
  title?: String;
}
interface State {}

export default class SearchButton extends Component<Props, State> {
  state = {};

  render() {
    return (
      <button className="btn btn-primary mr-1 ml-1 font-btn" onClick={this.props.onClick ? this.props.onClick  : null}>
        <i className="fas fa-search mr-1"></i> {this.props.title ? this.props.title : 'Tìm kiếm'}
      </button>
    );
  }
}
