export class OPTION {
  static readonly STATUS = {
    1: "Chờ xử lý",
    2: "Hoàn thành"
  };
  static readonly USERTYPE = {
    1: "Đại lý",
    2: "Cộng tác viên"
  };
}

export const STATUS_LABEL_CLASS = {
  1: {
    label: "Chờ xử lý",
    class: "orange"
  },
  2: {
    label: "Đã khóa tài khoản",
    class: "green"
  }
};

export const OWNER_LABEL_CLASS = {
  1: {
    label: "Đại lý",
    class: "cyan"
  },
  2: {
    label: "Cộng tác viên",
    class: "green"
  }
};
