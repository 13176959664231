import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    checkPermission,
    convertJsonToQueryString,
    formatDateTime,
    queryStringToJSON,
    reloadPage
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {reGenDK02, onSearchDelete, onFailAction, onSuccessAction, reGenRequest} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import ToolMspService from "../../../services/tool-msp/ToolMspService";
import { Tag } from "antd";
interface Props extends RouteComponentProps {
}

const TITLE = "Tool MSP";
const PERMISSION_TOOL_REGEN_DK02 = 'tool-regen-dk02';
const PERMISSION_TOOL_REGEN_DK02_COMPENSATION = 'tool-regen-dk02-compensation';
const PERMISSION_TOOL_REGEN_CERTIFICATE = 'tool-regen-certificate';
const PERMISSION_TOOL_CLEAR_TOKEN_BY_CODE = 'tool-clear-token-by-code';
const PERMISSION_UPDATE_TOKEN_V4 = 'tool-update-token-v4';

const Tool: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({tax_code: "", identity: "", tax_code_compensation: ""});
    const [tokenCode, setTokenCode] = useState("");
    const [tokenV4Code, setTokenV4Code] = useState("");
    const service = new ToolMspService();


    useEffect(() => {
        // eslint-disable-next-line
    }, []);

    //Tool xóa token theo mã
    const onChangeTokenCode = e => {
        e.preventDefault();
        setTokenCode(e.target.value)
    };
    const onClickDeleteTokenButton = e => {
        e.preventDefault();
        onSearchDelete(async willDelete => {
            if (willDelete) {
                try {
                    if(tokenCode === ''){
                        onFailAction('Mã token ko được trống');
                        return false;
                    }
                    const service = new ToolMspService();
                    const result = await service.deleteToken(tokenCode);
                    if (result && result.status === 200) {
                        setTokenCode('');
                        onSuccessAction(
                            "Xóa Token thành công!",
                            reloadPage(props.location, props.history)
                        );
                    }else{
                        onFailAction(
                            result.message
                        );
                    }
                } catch (error) {
                    console.log(error);
            onFailAction();
                }
            }
        });
    };

    //Tool cập nhật Token V4 gen lại
    const onChangeTokenV4Code = e => {
        e.preventDefault();
        setTokenV4Code(e.target.value)
    };
    const onClickUpdateTokenV4 = e => {
        e.preventDefault();
        onSearchDelete(async willDelete => {
            if (willDelete) {
                try {
                    if(tokenV4Code === ''){
                        onFailAction('Mã token ko được trống');
                        return false;
                    }
                    const service = new ToolMspService();
                    const result = await service.updateTokenV4(tokenV4Code);
                    if (result && result.status === 200) {
                        setTokenCode('');
                        onSuccessAction(
                            "Cập nhật Token thành công!",
                            reloadPage(props.location, props.history)
                        );
                    }else{
                        onFailAction(
                            result.message
                        );
                    }
                } catch (error) {
                    console.log(error);
            onFailAction();
                }
            }
        });
    };

    // Tool cập nhật yêu cầu gen lại
    const onSubmitSearchButton2 = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeDataSearchInput2 = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const fetchDataRequestCert = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            return await service.listRequestCert({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const onClickReGen = id => {
        reGenRequest(async willReGen => {
            if (willReGen) {
                const service = new ToolMspService();
                const result = await service.toolReGen(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Cập nhật yêu cầu gen lại thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
            }
        });
    };
    const onRenderActionColumn2 = (text, record, index) => {
        return (
            <TableActionButton
                onClickReGen={() => onClickReGen(record.id)}
            />
        );
    };

    //Tool sinh lại DK 02
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            return await service.listRequest({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const fetchDataRequestCompensation = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            console.log(await service.listRequestCompensation({...dataSearch,...queryString}));
            return await service.listRequestCompensation({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const onClickDeleteButton = id => {
        reGenDK02(async willDelete => {
            if (willDelete) {
                const service = new ToolMspService();
                const result = await service.reGen(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Gen lại DK02 thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
            }
        });
    };
    const onClickRegenDK02 = (text, record, index) => {
        return (
            <TableActionButton
                onClickReGen={() => onClickDeleteButton(record.id)}
            />
        );
    };
    const onClickRegenDK02Compensation = (text, record, index) => {
        return (
            <TableActionButton
                onClickReGen={() => onClickRegenDk02CompensationAction(record.id)}
            />
        );
    };
    const onClickRegenDk02CompensationAction = id => {
        reGenDK02(async willDelete => {
            if (willDelete) {
                const service = new ToolMspService();
                const result = await service.reGenDK02Compensation(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Gen lại DK02 thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
            }
        });
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                {checkPermission(PERMISSION_TOOL_REGEN_DK02) ? (
                    <React.Fragment>
                        <h5 className="card-title">1. Gen lại DK02</h5>
                        <form onSubmit={onSubmitSearchButton} className="search-form-index">
                            <div className="input-group">
                                <InputWithLabel
                                    name="tax_code"
                                    wrapClass="col-md-2 form-group nopadding-left"
                                    onChangeValue={onChangeDataSearchInput}
                                    value={dataSearch.tax_code}
                                    isRequired={false}
                                    label={""}
                                    placeholder={"Mã số thuế"}
                                />
                                <div className="col-md-3 form-group">
                                    <SearchButton onClick={onSubmitSearchButton}/>
                                </div>
                            </div>
                        </form>
                        <NewTable
                            columns={[
                                {
                                    title: "Mã yêu cầu",
                                    dataIndex: "code",
                                },
                                {
                                    title: "Tên khách hàng",
                                    dataIndex: "",
                                    render: (e)=>{
                                        if (e){
                                            if (e.organization) return e.organization.fullname;
                                            if (e.requestpersonal) return e.requestpersonal.fullname;
                                        }
                                    }
                                },
                                {
                                    title: "CMND/Mã số DN",
                                    dataIndex: "",
                                    render: (e)=>{
                                        if (e){
                                            if (e.organization){
                                                return e.organization.code;
                                            }
                                            if (e.requestpersonal) {
                                                return e.requestpersonal.passport;
                                            }
                                        }
                                    }
                                },
                                {
                                    title: "Đối tượng",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.object === 1){
                                            return (<Tag color="green">Cấp mới</Tag>);
                                        }
                                        if(e.object === 2){
                                            return (<Tag color="volcano">Gia hạn</Tag>);
                                        }
                                        if(e.object === 3){
                                            return (<Tag color="geekblue">Chuyển đổi</Tag>);
                                        }
                                    }
                                },
                                {title: "Gói dịch vụ", dataIndex: "cateservicespackage.name"},
                                {
                                    title: "Người tạo",
                                    dataIndex: "",
                                    render : (e) => {
                                        if(e){
                                            return e.user.fullname
                                        }
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    dataIndex: "",
                                    render: (e) => formatDateTime(e.created_at)
                                },
                                {
                                    title: "Người duyệt yêu cầu",
                                    dataIndex: "",
                                    render : (e) => {
                                        if(Number(e.status) === 4 || Number(e.status) === 5 || Number(e.status) === 6){
                                            if (Number(e.provider_id) === 1){
                                                return "Nhà cung cấp đã duyệt";
                                            }else {
                                                return e.user_approve.fullname;
                                            }
                                        }
                                    }
                                },
                                {
                                    title: "Người quản lý",
                                    dataIndex: "",
                                    render : (e) =>{
                                        if(e) {
                                            if(e.user.type === 5) return e.user.fullname;
                                            if(e.user.type === 7) return e.user.agency.user.fullname;
                                            if(e.user.type === 8) return e.user.contributor.user.fullname
                                        }
                                    }
                                },
                                {
                                    title: "Loại yêu cầu",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.type_request === 1){
                                            return (<Tag color="cyan">Tổ chức</Tag>);
                                        }
                                        if(e.type_request === 2){
                                            return (<Tag color="blue">Cá nhân</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Trạng thái",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.status === 2){
                                            return (<Tag color="orange">Chờ duyệt</Tag>);
                                        }
                                        if(e.status === 3){
                                            return (<Tag color="red">Nghiệp vụ từ chối</Tag>);
                                        }
                                        if(e.status === 4){
                                            return (<Tag color="geekblue">Nghiệp vụ đã duyệt</Tag>);
                                        }
                                        if(e.status === 5){
                                            return (<Tag color="green">Đã sinh chứng thư số</Tag>);
                                        }if(e.status === 6){
                                            return (<Tag color="blue">Chờ gen</Tag>);
                                        }if(e.status === 7){
                                            return (<Tag color="red">CA từ chối</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "NCC",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.provider_id === 1){
                                            return (<Tag color="blue">FastCA</Tag>);
                                        }
                                        else{
                                            return (<Tag color="red">NewTel</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Tác vụ",
                                    render: onClickRegenDK02
                                }
                            ]}
                            onFetchData={() => fetchDataFunction()}
                        />
                    </React.Fragment>
                ) :("")}



                 {/*Sinh lại DK02 Cấp bù*/}
                {checkPermission(PERMISSION_TOOL_REGEN_DK02_COMPENSATION) ? (
                    <React.Fragment>
                        <h5 className="card-title">1. Gen lại DK02 Cấp Bù</h5>
                        <form onSubmit={onSubmitSearchButton} className="search-form-index">
                            <div className="input-group">
                                <InputWithLabel
                                    name="tax_code_compensation"
                                    wrapClass="col-md-2 form-group nopadding-left"
                                    onChangeValue={onChangeDataSearchInput}
                                    value={dataSearch.tax_code_compensation}
                                    isRequired={false}
                                    label={""}
                                    placeholder={"Mã số thuế"}
                                />
                                <div className="col-md-3 form-group">
                                    <SearchButton onClick={onSubmitSearchButton}/>
                                </div>
                            </div>
                        </form>
                        <NewTable
                            columns={[
                                {
                                    title: "Mã yêu cầu",
                                    dataIndex: "code",
                                },
                                {
                                    title: "Tên khách hàng",
                                    dataIndex: "",
                                    render: (e)=>{
                                        if (e){
                                            return e.owner;
                                        }
                                    }
                                },
                                {
                                    title: "CMND/Mã số DN",
                                    dataIndex: "",
                                    render: (e)=>{
                                        if (e){
                                            return e.uid;
                                        }
                                    }
                                },
                                {
                                    title: "Gói dịch vụ",
                                    dataIndex: "cert_compensation.service_name"
                                },
                                {
                                    title: "Serial number",
                                    dataIndex: "",
                                    render : (e) => {
                                        if(e){
                                            return e.certinfo_compensation.serial_cert
                                        }
                                    }
                                },
                                {
                                    title: "Hiệu lực từ",
                                    dataIndex: "",
                                    render: (e) => formatDateTime(e.certinfo_compensation.cert_start)
                                },
                                {
                                    title: "Hiệu lực đến",
                                    dataIndex: "",
                                    render: (e) => formatDateTime(e.certinfo_compensation.cert_end)
                                },
                                {
                                    title: "Mã token",
                                    dataIndex: "",
                                    render : (e) => {
                                        if(e){
                                            return e.certinfo_compensation.token_code
                                        }
                                    }
                                },
                                {
                                    title: "Trạng thái", dataIndex: "",
                                    render: (e) => {
                                        if (e.status === 1) {
                                            return (<Tag color="cyan">Đã duyệt</Tag>);
                                        }
                                        if (e.status === 2) {
                                            return (<Tag color="volcano">Chờ duyệt</Tag>);
                                        }
                                        if (e.status === 3) {
                                            return (<Tag color="green">Hoàn thành</Tag>);
                                        }
                                        if (e.status === 4) {
                                            return (<Tag color="magenta">Hủy</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Step", dataIndex: "",
                                    render: (e) => {
                                        if (e.step === 1) {
                                            return (<Tag color="cyan">Đã tạo yêu cầu</Tag>);
                                        }
                                        if (e.step === 2) {
                                            return (<Tag color="volcano">Đã ký DK01</Tag>);
                                        }
                                        if (e.step === 3) {
                                            return (<Tag color="red">Cài đặt bước 1</Tag>);
                                        }
                                        if (e.step === 4 || e.step === 5) {
                                            return (<Tag color="magenta">Cài đặt bước 2</Tag>);
                                        }if (e.step === 6) {
                                            return (<Tag color="green">Hoàn thành</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Tác vụ",
                                    render: onClickRegenDK02Compensation
                                }
                            ]}
                            onFetchData={() => fetchDataRequestCompensation()}
                        />
                    </React.Fragment>
                ) : ("")}


                 {/*Sinh lại chứng thư số*/}
                {checkPermission(PERMISSION_TOOL_REGEN_CERTIFICATE) ? (
                    <React.Fragment>
                        <h5 className="card-title">3. Yêu cầu sinh lại chứng thư số</h5>
                        <form onSubmit={onSubmitSearchButton2} className="search-form-index">
                            <div className="input-group">
                                <InputWithLabel
                                    name="identity"
                                    wrapClass="col-md-2 form-group nopadding-left"
                                    onChangeValue={onChangeDataSearchInput2}
                                    value={dataSearch.identity}
                                    isRequired={false}
                                    label={""}
                                    placeholder={"Mã định danh"}
                                />
                                <div className="col-md-3 form-group">
                                    <SearchButton onClick={onSubmitSearchButton2}/>
                                </div>
                            </div>
                        </form>
                        <NewTable
                            columns={[
                                {
                                    title: "Mã yêu cầu",
                                    dataIndex: "code",
                                },
                                {
                                    title: "Tên khách hàng",
                                    dataIndex: "",
                                    render: (e)=>{
                                        if (e){
                                            if (e.organization) return e.organization.fullname;
                                            if (e.requestpersonal) return e.requestpersonal.fullname;
                                        }
                                    }
                                },
                                {
                                    title: "CMND/Mã số DN",
                                    dataIndex: "",
                                    render: (e)=>{
                                        if (e){
                                            if (e.organization){
                                                return e.organization.code;
                                            }
                                            if (e.requestpersonal) {
                                                return e.requestpersonal.passport;
                                            }
                                        }
                                    }
                                },
                                {
                                    title: "Đối tượng",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.object === 1){
                                            return (<Tag color="green">Cấp mới</Tag>);
                                        }
                                        if(e.object === 2){
                                            return (<Tag color="volcano">Gia hạn</Tag>);
                                        }
                                        if(e.object === 3){
                                            return (<Tag color="geekblue">Chuyển đổi</Tag>);
                                        }
                                    }
                                },
                                {title: "Gói dịch vụ", dataIndex: "cateservicespackage.name"},
                                {
                                    title: "Người tạo",
                                    dataIndex: "",
                                    render : (e) => {
                                        if(e){
                                            return e.user.fullname
                                        }
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    dataIndex: "",
                                    render: (e) => formatDateTime(e.created_at)
                                },
                                {
                                    title: "Người duyệt yêu cầu",
                                    dataIndex: "",
                                    render : (e) => {
                                        if(Number(e.status) === 4 || Number(e.status) === 5 || Number(e.status) === 6){
                                            if (Number(e.provider_id) === 1){
                                                return "Nhà cung cấp đã duyệt";
                                            }else {
                                                return e.user_approve.fullname;
                                            }
                                        }
                                    }
                                },
                                {
                                    title: "Người quản lý",
                                    dataIndex: "",
                                    render : (e) =>{
                                        if(e) {
                                            if(e.user.type === 5) return e.user.fullname;
                                            if(e.user.type === 7) return e.user.agency.user.fullname;
                                            if(e.user.type === 8) return e.user.contributor.user.fullname
                                        }
                                    }
                                },
                                {
                                    title: "Loại yêu cầu",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.type_request === 1){
                                            return (<Tag color="cyan">Tổ chức</Tag>);
                                        }
                                        if(e.type_request === 2){
                                            return (<Tag color="blue">Cá nhân</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Trạng thái",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.status === 2){
                                            return (<Tag color="orange">Chờ duyệt</Tag>);
                                        }
                                        if(e.status === 3){
                                            return (<Tag color="red">Nghiệp vụ từ chối</Tag>);
                                        }
                                        if(e.status === 4){
                                            return (<Tag color="geekblue">Nghiệp vụ đã duyệt</Tag>);
                                        }
                                        if(e.status === 5){
                                            return (<Tag color="green">Đã sinh chứng thư số</Tag>);
                                        }if(e.status === 6){
                                            return (<Tag color="blue">Chờ gen</Tag>);
                                        }if(e.status === 7){
                                            return (<Tag color="red">CA từ chối</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Serial CTS",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.info_cert){
                                            return e.info_cert.certificateSerial;
                                        }
                                    }
                                },
                                {
                                    title: "NCC",
                                    dataIndex: "",
                                    render: (e) => {
                                        if(e.provider_id === 1){
                                            return (<Tag color="blue">FastCA</Tag>);
                                        }
                                        else{
                                            return (<Tag color="red">NewTel</Tag>);
                                        }
                                    }
                                },
                                {
                                    title: "Tác vụ",
                                    render: onRenderActionColumn2
                                }
                            ]}
                            onFetchData={() => fetchDataRequestCert()}
                        />
                    </React.Fragment>
                ) : ("")}


                {/*Xóa token*/}
                {checkPermission(PERMISSION_TOOL_CLEAR_TOKEN_BY_CODE) ? (
                    <React.Fragment>
                        <h5 className="card-title">4. Xóa Token theo mã</h5>
                        <form onSubmit={onClickDeleteTokenButton} className="search-form-index">
                            <div className="input-group">
                                <InputWithLabel
                                    name="tokenCode"
                                    wrapClass="col-md-2 form-group nopadding-left"
                                    onChangeValue={onChangeTokenCode}
                                    value={tokenCode}
                                    isRequired={false}
                                    label={""}
                                    placeholder={"Mã token cần xóa"}
                                />
                                <div className="col-md-3 form-group">
                                    <SearchButton onClick={onClickDeleteTokenButton} title = {'Tìm kiếm và xóa'}/>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                ) :("")}

                {/*Cập nhật token V4*/}
                {checkPermission(PERMISSION_UPDATE_TOKEN_V4) ? (
                    <React.Fragment>
                        <h5 className="card-title">3. Cập nhật token V4</h5>
                        <form onSubmit={onClickUpdateTokenV4} className="search-form-index">
                            <div className="input-group">
                                <InputWithLabel
                                    name="tokenV4Code"
                                    wrapClass="col-md-2 form-group nopadding-left"
                                    onChangeValue={onChangeTokenV4Code}
                                    value={tokenV4Code}
                                    isRequired={false}
                                    label={""}
                                    placeholder={"Mã token cần cập nhật"}
                                />
                                <div className="col-md-3 form-group">
                                    <SearchButton onClick={onClickUpdateTokenV4} title = {'Tìm kiếm và xóa'}/>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                ) : ("")}

            </div>
        </div>
    );
};

export default withNavbar()(Tool);
