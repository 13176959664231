import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/tool-msp";
class ToolMspService extends FetchAPI {
    public async listRequest(data) {
        const result = await this.request(
            `${PREFIX_URL}/listRequest`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async listRequestCompensation(data) {
        const result = await this.request(
            `${PREFIX_URL}/listRequestCompensation`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async listRequestCert(data) {
        const result = await this.request(
            `${PREFIX_URL}/listRequestCert`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async toolReGen(id) {
        const result = await this.request(
            `${PREFIX_URL}/tool-re-gen/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async reGen(id) {
        const result = await this.request(
            `${PREFIX_URL}/reGen/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async reGenDK02Compensation(id) {
        const result = await this.request(
            `${PREFIX_URL}/reGenDK02Compensation/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async deleteToken(code) {
        const result = await this.request(
            `${PREFIX_URL}/deleteToken/${code}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async updateTokenV4(code) {
        const result = await this.request(
            `${PREFIX_URL}/updateTokenV4/${code}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

}

export default ToolMspService;
