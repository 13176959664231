import React, {Component} from "react";
import "../css/main.css";
import InputWithLabel from "../form/InputWithLabel";
import {Popconfirm} from "antd";

interface Props {
    discountList?: any;
    changeData?: any;
    errors?: any;
    data?: any;
    onDelete?: any;
    keyDelete?: any;
    tittle?: string;
    isHidden?: boolean;
    isDisable?: boolean;
}

export default class Table extends Component<Props> {
    render() {
        const dataSource = this.props.data;
        return (     
            <table className="w-100 m-t-20 ant-table-bordered" style={{border: "1px solid #e8e8e8"}} key="2">
                <thead className="ant-table-thead">
                    <tr>
                        <th rowSpan={2} className="text-center th-stt">STT
                        </th>
                        <th rowSpan={2} className="text-center">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">
                                      {this.props.tittle
                                          ? this.props.tittle
                                          : ""}
                                    </span>
                                </div>
                            </span>
                        </th>
                        <th colSpan={3} className="text-center">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Tỷ lệ chiết khấu %</span>
                                </div>
                            </span>
                        </th>
                        <th rowSpan={2} className="text-center">
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Trợ giá token gói cấp mới 3 năm (VNĐ)</span>
                                </div>
                                <div>
                                    <i style={{fontWeight: 'initial'}}>(Chỉ dùng cho cấp mới và chuyển đổi)</i>
                                </div>
                            </span>
                        </th>
                        <th rowSpan={2} className="text-center" hidden={this.props.isHidden ? this.props.isHidden : false}>
                            <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Tác vụ</span>
                                </div>
                            </span>
                        </th>
                  </tr>
                  <tr>
                      <th className="text-center">
                          <span className="ant-table-header-column">
                              <div>
                                  <span className="ant-table-column-title">Cấp mới</span>
                              </div>
                          </span>
                      </th>
                      <th className="text-center">
                          <span className="ant-table-header-column">
                              <div>
                                  <span className="ant-table-column-title">Gia hạn</span>
                              </div>
                          </span>
                      </th>
                      <th className="text-center">
                          <span className="ant-table-header-column">
                              <div>
                                  <span className="ant-table-column-title">Chuyển đổi</span>
                              </div>
                          </span>
                      </th>
                      
                  </tr>
              </thead>
              <tbody className="ant-table-tbody">
                {dataSource ? (
                    Object.keys(dataSource).map((key, index) => (
                        <tr
                            className={`ant-table-row editable-row ant-table-row-level-${key}`}
                            data-row-key={key}
                            key={key}
                        >
                            <td className="text-center">{1 + Number(key)}</td>
                            <td>
                                <InputWithLabel
                                    name={`number${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={Number(dataSource[key]["number"])}
                                    error={
                                        this.props.errors.dataDiscount && (dataSource[key]["number"] === "")
                                            ? "Số lượng không được trống"
                                            : ""
                                    }
                                    isRequired={true}
                                    maxLength={255}
                                    dataKey={"number"}
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`percent_new${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["percent_new"]}
                                    error={
                                        this.props.errors.dataDiscount && 
                                        (Number(dataSource[key]["percent_new"]) < 0 
                                            || Number(dataSource[key]["percent_new"]) > 100)
                                            ? "Tỷ lệ chiết khấu phải >= 0 và <=100"
                                            : ""||
                                            ( this.props.errors.dataDiscount && dataSource[key]["percent_new"] === ""
                                            ? "Tỷ lệ chiết khấu không được trống"
                                            : "" )
                                    }
                                    isRequired={true}
                                    dataKey={"percent_new"}
                                    type="number"
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`percent_extension${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["percent_extension"]}
                                    error={
                                        this.props.errors.dataDiscount && 
                                        (Number(dataSource[key]["percent_extension"]) < 0 
                                            || Number(dataSource[key]["percent_extension"]) > 100)
                                            ? "Tỷ lệ chiết khấu phải >= 0 và <=100"
                                            : ""||
                                            ( this.props.errors.dataDiscount && dataSource[key]["percent_extension"] === ""
                                            ? "Tỷ lệ chiết khấu không được trống"
                                            : "" )
                                    }
                                    type="number"
                                    dataKey={"percent_extension"}
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`percent_change${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["percent_change"]}
                                    error={
                                        this.props.errors.dataDiscount && 
                                        (Number(dataSource[key]["percent_change"]) < 0 
                                            || Number(dataSource[key]["percent_change"]) > 100)
                                            ? "Tỷ lệ chiết khấu phải >= 0 và <=100"
                                            : ""||
                                            ( this.props.errors.dataDiscount && dataSource[key]["percent_change"] === ""
                                            ? "Tỷ lệ chiết khấu không được trống"
                                            : "" )
                                    }
                                    type="number"
                                    dataKey={"percent_change"}
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                  name={`token_price_3_year${key}`}
                                  label=""
                                  wrapClass=""
                                  onChangeValue={this.props.changeData}
                                  value={dataSource[key]["token_price_3_year"]}
                                  maxLength={255}
                                  error= {
                                    this.props.errors.dataDiscount 
                                        && Number(dataSource[key]["token_price_3_year"]) < 0 
                                        ? "Trợ giá phải là số dương"
                                        : ""
                                  }
                                  dataKey={"token_price_3_year"}
                                  isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                  type="number"
                                />
                            </td>
                            <td className="text-center" hidden={this.props.isHidden ? this.props.isHidden : false}>
                                {Number(dataSource[key]['key']) === 0 ? "" : (
                                    <Popconfirm
                                        title="Bạn có chắc chắn xóa?"
                                        onConfirm={this.props.onDelete}
                                        okText="Xóa"
                                        cancelText="Bỏ qua"
                                    >
                                        <a
                                            href="/#"
                                            className="pointer text-danger ml-1 mr-1"
                                            title="Xóa"
                                            onClick={this.props.keyDelete}
                                        >
                                            <i className="fas fa-trash-alt fa-lg"/>
                                        </a>
                                    </Popconfirm>)}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr/>
                )}
                </tbody>
            </table>
        );
    }
}
