import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString,
    formatDateTimeAll,
    queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import RequestChangeInfoService from "../../../services/request-change-info/RequestChangeInfoService";
import TableActionButton from "../user/table/action-button/TableActionButton";
import SelectDate from "../../common/form/SelectDate";
import { Tag } from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Yêu cầu thay đổi thông tin đại lý/CTV";

const RequestChangeInfo: React.FC<Props> = props => {
    const service = new RequestChangeInfoService();
    const [dataSearch, setDataSearch] = useState({sale_id: "", type: "", text:"", created_at:""});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const onChangeDataSearchSaleId = (e) => {
        setDataSearch({...dataSearch, "sale_id": e});
    };
    const onChangeDataSearchType = (e) => {
        setDataSearch({...dataSearch, "type": e});
    };

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const [listSaleId, setListSaleId] = useState({});
    useEffect(() => {
        getListSaleUser();
        // eslint-disable-next-line
    }, []);

    const getListSaleUser = async () => {
        const result = await service.getListSaleUser();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setListSaleId(options);
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickView={() =>
                    (
                        record.type === 1
                        ? props.history.push(`/yeu-cau-thay-doi-thong-tin/chi-tiet/dai-ly/${record.id}`)
                        : props.history.push(`/yeu-cau-thay-doi-thong-tin/chi-tiet/cong-tac-vien/${record.id}`)
                    )
                }
            />
        );
    };

    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({...dataSearch, created_at: dateString});
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="text"
                            wrapClass="col-md-3 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.text}
                            isRequired={false}
                            label={""}
                            placeholder="MST/CMND, tên đại lý/CTV"
                        />
                        <SelectBox
                            name="sale_id"
                            options={listSaleId}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Người quản lý"
                            onChange={onChangeDataSearchSaleId}
                            value={dataSearch.sale_id}
                        />
                        <SelectBox
                            name="type"
                            options={{
                                1: "Đại lý",
                                2: "Cộng tác viên",
                            }}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Loại"
                            onChange={onChangeDataSearchType}
                            value={dataSearch.type}
                        />
                        <SelectDate
                            label=""
                            name="receive_date"
                            wrappedClass="col-md-3 form-group"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-1 form-group nopadding-left">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "MST/CMND",
                            dataIndex: "",
                            render: (e)=>{
                                if (e.type === 1){
                                    return e.agency.tax_code;
                                }else{
                                    return e.contributor.passport;
                                }
                            }
                        },
                        {
                            title: "Tên đại lý/CTV",
                            dataIndex: "",
                            render: (e)=>{
                                if (e.type === 1){
                                    return e.agency.fullname;
                                }else{
                                    return e.contributor.fullname;
                                }
                            }
                        },
                        {
                            title: "Loại người dùng",
                            dataIndex: "",
                            render: (e) => {
                                if(e.type === 1){
                                    return (<Tag color="cyan">Đại lý</Tag>);
                                }
                                if(e.type === 2){
                                    return (<Tag color="blue">Cộng tác viên</Tag>);
                                }
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: (e) => {
                                if(e.status === 1){
                                    return (<Tag color="magenta">Chờ nghiệp vụ duyệt</Tag>);
                                }
                                if(e.status === 2){
                                    return (<Tag color="cyan">Nghiệp vụ từ chối</Tag>);
                                }
                                if(e.status === 3){
                                    return (<Tag color="green">Hoàn thành</Tag>);
                                }
                                if(e.status === 4){
                                    return (<Tag color="red">Hủy</Tag>);
                                }
                                if(e.status === 5){
                                    return (<Tag color="orange">Chờ HTKD duyệt</Tag>);
                                }
                                if(e.status === 6){
                                    return (<Tag color="purple">HTKD từ chối</Tag>);
                                }
                            }
                        },
                        {
                            title: "Người quản lý",
                            dataIndex: "",
                            render: (e) => {
                                if(e.type === 1){
                                    return e.agency.manager.fullname;
                                }else{
                                    return e.contributor.manager.fullname;
                                }
                            }
                        },
                        {
                            title: "Thời gian tạo",
                            dataIndex: "",
                            render: (e) => formatDateTimeAll(e.created_at)
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(RequestChangeInfo);
