import FetchAPI from "../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../helpers/constant/MethodAPIConstant";

class WhTokenService extends FetchAPI {
  public async getWhTokens(data: object) {
    const path = "/wh/token";
    const result = await this.request(path, data, TypeMethodContsantEnum.GET);
    return result;
  }

  public async getWhToken(id) {
    const result = await this.request(
      "wh/token-show/"+id,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async createWhToken(data) {
    const result = await this.request(
      "wh/token",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

  public async updateWhToken(data) {
    const result = await this.request(
      "wh/token-update",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }
  public async getBase64(id) {
    const result = await this.request(
      `wh/token-file/${id}`,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async getLastCodeToken(value, first_code) {
      const result = await this.request(
          "wh/getLastCodeToken",
          {value, first_code},
          TypeMethodContsantEnum.GET
      );
      return result;
  }
}

export default WhTokenService;
