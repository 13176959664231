import React, { Component } from "react";
import { match, RouteComponentProps } from "react-router";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import UpdateForm from "./UpdateForm";

interface MatchParams {
  id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  match: match<MatchParams>;
}
interface State {}

class Update extends Component<Props, State> {
  state = {};
  componentDidMount() {}

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <UpdateForm
            history={this.props.history}
            id={this.props.match.params.id}
          ></UpdateForm>
        </div>
      </div>
    );
  }
}

const updateWithNavBar = withNavbar()(Update);

export default updateWithNavBar;
