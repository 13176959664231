import React, { Component } from "react";
import { Table } from "antd";

interface Props {
  data?: any;
  total?: string;
}
interface State {
}
const columns = [
  {
    title: "STT",
    key: "key",
    render: (text, record, index) => index + 1,
    className: "text-center",
    width: "10%",
  },
  {
    title: "Tài sản",
    dataIndex: "gift_asset_name",
    key: "gift_asset_id",
  },
  {
    title: "Đơn vị",
    dataIndex: "gift_unit",
    className: "text-center",
    key: "gift_unit",
  },
  {
    title: "Số lượng",
    dataIndex: "gift_amount",
    className: "text-center",
    key: "gift_amount",
  },
  {
    title: "Đơn giá(VNĐ)",
    dataIndex: "gift_price",
    key: "gift_price",
    className: "text-center column-money",
  },
  {
    title: "Thành tiền(VNĐ)",
    dataIndex: "",
    className: "text-center column-money",
    render: (e) => {
      if (e) {
        return <a href="/#">{Number(e.gift_amount) * Number(e.gift_price)}</a>;
      }
    },
  },
];
export default class AssetList extends Component<Props, State> {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Table
          className={"w-100 m-t-20 ant-table-bordered"}
          columns={columns}
          dataSource={this.props.data ? this.props.data : []}
          rowKey="id2"
          footer={() => (
            <div className="text-right">
              <b>Tổng tiền(VNĐ): {this.props.total}</b>
            </div>
          )}
        />
      </div>
    );
  }
}
