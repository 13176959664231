import DocLegalManager from "../../pages/doc-legal-manager/DocLegalManager";

const DOC_LEGAL_MANAGER = [
    {
        component: DocLegalManager,
        link: "/quan-ly-ho-so-phap-ly",
        isExact: false
    },
];
export default DOC_LEGAL_MANAGER;

