import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class AgencyService extends FetchAPI{

    public async getAgencys(data: object) {
        const path = "admin/agency"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async getAgency(id) {
        const path = "admin/agency-show"
        const result = await this.request(path, {id}, TypeMethodContsantEnum.GET);
        return result;
    }
    public async getAgencyDocumentFile(data: object) {
        const path = "admin/agency-document-file"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async getAgencyApprove(data: object) {
        const path = "admin/agency-approve"
        const result = await this.request(path, data, TypeMethodContsantEnum.POST);
        return result;
    }

    public async getAgencyDeny(data: object) {
        const path = "admin/agency-deny"
        const result = await this.request(path, data, TypeMethodContsantEnum.POST);
        return result;
    }

}

export default AgencyService;