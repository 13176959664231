import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import swal from "sweetalert";
import { onFailAction } from "../../../common/swal/SwalCommon";
import SelectDate from "../../../common/form/SelectDate";
import ProvinceService from "../../../../services/province/ProvinceService";

interface Props {
  id: string;
  history: History;
}
interface State {
  code: string;
  fullname: string;
  short_name: string;
  start_date: string;
  end_date: string;
}

class UpdateUserForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    permissions: {},
    agency: {},
    code: '',
    short_name: '',
    start_date: '',
    end_date: '',
  };

  componentDidMount() {

    this.props.setFieldValue("valid_at", '');
    this.props.setFieldValue("release_at", '');
    this.getDataUpdate();
  }

  getDataUpdate = async () => {
    const service = new ProvinceService();
    const result = await service.getProvince(this.props.id);
    if (result && result.status === 200) {
      const {
        code,
        short_name,
        fullname,
        start_date,
        end_date,
      } = result.data;

      this.props.setValues({
        code,
        short_name,
        fullname,
        start_date,
        end_date
      } as Pick<State, keyof State>);


      let value_start_date = '';
      if (result.data.start_date !== null) {
        value_start_date = result.data.start_date;
      }
      let value_end_date = '';
      if (result.data.end_date !== null) {
        value_end_date = result.data.end_date;
      }
      this.props.setFieldValue("start_date", value_start_date);
      this.props.setFieldValue("end_date", value_end_date);
    }
  };

  onChangeSelectStartDate = (date, dateString) => {
    this.props.setFieldValue("start_date", dateString);
  };

  onChangeSelectEndDate = (date, dateString) => {
    this.props.setFieldValue("end_date", dateString);
  };



  render() {
    const {
      code,
      short_name,
      fullname,
      start_date,
      end_date,
    } = this.props.values;

    return (
      <form>
        <div className="input-group">
          <InputWithLabel
            name="code"
            label="Mã tỉnh thành"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
          />
          <InputWithLabel
            name="short_name"
            label="Tên viết tắt"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={short_name}
            error={this.props.errors.short_name}
            isRequired={true}
          />
          <InputWithLabel
              name="fullname"
              label="Tên tỉnh thành"
              wrapClass="col-md-4 form-group"
              onChangeValue={this.props.handleChange}
              value={fullname}
              error={this.props.errors.fullname}
              isRequired={true}
          />
        </div>
        <div className="input-group">
          <SelectDate
            label="Ngày bắt đầu hiệu lực"
            name="start_date"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectStartDate}
            value={start_date}
            error={this.props.errors.start_date}
            isRequired={false}
          />
          <SelectDate
            label="Ngày kết thúc"
            name="end_date"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectEndDate}
            value={end_date}
            error={this.props.errors.end_date}
            isRequired={false}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url='/tinh-thanh'/>
          <SubmitButton onClick={this.props.handleSubmit} />
        </div>
      </form>
    );
  }
}

const userUpdateForm = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    code : "",
    short_name : "",
    fullname : "",
    start_date : "",
    end_date : "",
  }),
  validationSchema: Yup.object().shape({
    code: Yup.string().required("Tên phần mềm không được bỏ trống."),
    fullname: Yup.string().required("Số phần mềm không được bỏ trống.")
  }),
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new ProvinceService();
    const result = await service.updateProvince({ ...values, permissions: '', id: props.id });
    if (result && result.status === 200) {
      swal({
        title: "Thành công!",
        text: "Cập nhật thành công!",
        icon: "success",
        dangerMode: false
      }).then(function () {
        props.history.goBack();
      });
    } else if (result.status === 1) {
      setErrors(result.errors);
    } else {
      onFailAction(result.errors);
    }
  },

})(UpdateUserForm);

export default userUpdateForm;
