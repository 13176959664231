import React, {useEffect, useState} from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../common/form/InputWithLabel";
import BackButton from "../../common/button/BackButton";
import {onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import CateContractService from "../../../services/category-contract/CateContractService";
import ModalView from "../../common/form/Modal";
import RequestDocumentService from "../../../services/request-document/RequestDocumentService";
import DenyButton from "../../common/button/DenyButton";
import SubmitButton from "../../common/form/SubmitButton";
import ModalDeny from "../../common/form/ModalDeny";
import {Card} from "antd";
import Radio from "../../common/form/Radio";
import TextAreaWithLabel from "../../common/form/TextAreaWithLabel";
import {formatDateTime} from "../../../helpers/NewCaHelper";

interface FormValues {
    status:number;
    belong_to:number;
    owner_name: string;
    number_box:string;
    number_envelop:string;
    receive_fullname:string;
    receive_passport:string;
    receive_address:string;
    receive_phone:string;
    receive_date:string;
    receive_type:number;
    receive_type_pay:number;
    note:number;
    reason: string;
    code:string;
}

interface OtherProps extends RouteComponentProps<{ id: string; }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const RequestCertificateViewPersonal: React.FC<Props> = props => {
    const service = new RequestDocumentService();
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    const {
        status,
        belong_to,
        owner_name,
        number_box,
        number_envelop,
        receive_fullname,
        receive_passport,
        receive_date,
        receive_type,
        receive_type_pay,
        note,
        reason,
        code,
    } = props.values;

    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.find(id);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        if (result.data.agency) {
            props.setFieldValue('owner_name', result.data.agency.fullname);
        }
        if (result.data.contributor) {
            props.setFieldValue('owner_name', result.data.contributor.fullname);
        }
    };

    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false})
    };

    const confirmDeny = async () => {
        if (modalDeny.text_deny.replace(/\s+/g, '') === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.getDeny(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/yeu-cau-tai-lieu-ban-hang");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };
    const submitDeny = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': true});
    };
    
    const submitApprove = async () => {
        const result = await service.getApprove(id);
        if (result && result.status === 200) {
            onSuccessAction("Duyệt thành công!", () => {
                props.history.push("/yeu-cau-tai-lieu-ban-hang");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        }
    };
    
    const onViewFileDoc = async () => {
        try {
            const result = await service.getFile(id);
            if (result && result.status === 200) {
                setModal({
                    ...modal,
                    'base64': result.base64,
                    'visible_modal': true,
                    'loading': true,
                    'title_modal': 'file',
                    'type_file': 'pdf'
                });
                return true;
            } else {
                onFailAction("Không tìm thấy file");
            }
            onFailAction("Có lỗi xảy ra!");
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Yêu cầu tài liệu bán hàng</h4>
            <div className="card-body">
                <form>
                    <div>
                        {/*lý do từ chối*/}
                        {status === 5 ? (
                            <div className="input-group">
                                <InputWithLabel
                                    name={""}
                                    label="Lý do từ chối"
                                    isDisabled={true}
                                    wrapClass="col-md-12"
                                    value={reason}
                                    onChangeValue={""}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {/*card 1*/}
                        <Card className="m-r-15-i m-l-15-i" title="Thông tin điều chỉnh" size="small">
                            <div className="input-group">
                                <div className="input-group">
                                    <InputWithLabel
                                        name=""
                                        wrapClass="col-md-4"
                                        label="Loại người dùng"
                                        isDisabled={true}
                                        value={belong_to === 1 ? 'Đại lý' : 'Cộng tác viên'}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Đại lý/CTV"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={owner_name}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        name=""
                                        wrapClass="col-md-4"
                                        label="Mã số thuế/CMND"
                                        isDisabled={true}
                                        value={code}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <Radio
                                        options={{1: "Tại newca", 2: "Chuyển phát nhanh"}}
                                        label="Hình thức nhận"
                                        name=""
                                        onChange={""}
                                        wrappedClass="col-md-3"
                                        isDisabled={true}
                                        value={receive_type}
                                    />
                                    <Radio
                                        options={{1: "Tiền mặt", 2: "Chuyển khoản"}}
                                        label="Hình thức thanh toán"
                                        name=""
                                        onChange={""}
                                        wrappedClass="col-md-3"
                                        isDisabled={true}
                                        value={receive_type_pay}
                                    />
                                    <InputWithLabel
                                        label="Số lượng vỏ hộp"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={number_box}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Số lượng phong bì"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={number_envelop}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        label="Người nhận"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={receive_fullname}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="CMND/hộ chiếu người nhận"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={receive_passport}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Ngày nhận"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={formatDateTime(receive_date)}
                                        onChangeValue={""}
                                    />
                                    <div className="col-md-3">
                                        <label className="invisible">12121</label>
                                        <button type="button" className="btn btn-outline-primary form-control btn-sm" onClick={onViewFileDoc}>File đề xuất</button>
                                    </div>
                                </div>
                                <div className="input-group">
                                    <TextAreaWithLabel
                                        label="Ghi chú"
                                        name={""}
                                        wrapClass="col-md-12"
                                        isDisabled={true}
                                        value={note}
                                        onChangeValue={""}
                                        rows = {4}
                                    />
                                </div>
                            </div>
                        </Card>
                        {/*nút duyệt*/}
                        <div className="input-group d-flex justify-content-center p-5">
                            {Number(status) === 4 ?
                                (
                                    <SubmitButton onClick={submitApprove} text="Duyệt" iconClass="fas fa-check"/>
                                )
                                : (" ")
                            }
                            {Number(status) === 4 ?
                                (
                                    <DenyButton onClick={submitDeny}/>
                                )
                                : (" ")
                            }
                            <BackButton
                                history={props.history}
                                // url="/yeu-cau-tai-lieu-ban-hang"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        status:0,
        belong_to:0,
        owner_name: '',
        number_box:'',
        number_envelop:'',
        receive_fullname:'',
        receive_passport:'',
        receive_address:'',
        receive_phone:'',
        receive_date:'',
        receive_type:0,
        receive_type_pay:0,
        note:0,
        reason:'',
        code:''
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(RequestCertificateViewPersonal);

export default withNavbar()(RenderCreateForm);
