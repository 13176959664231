import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import AgencyService from "../../../../services/agency/AgencyService";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import { STATUS_LABEL_CLASS } from "../enum/AgencyEnum";
import { formatDateTimeAll, checkPermission } from "../../../../helpers/NewCaHelper";
import { Tag } from "antd";

interface Props {
  location: Location;
  history: History;
  match: match;
  user: any;
}
interface State { }

const per_view_detail_agency = 'view-detail-agency-ad'

export default class AgencyTable extends Component<Props, State> {
  state = {};

  componentDidMount() {
    this.fetch();
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const service = new AgencyService();
    try {
      const agency = await service.getAgencys(params);
      return agency;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  render() {
    return (
      <Table
        columns={this.columns}
        onFetchData={this.fetch}
      />
    );
  }

  onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickLock={() => true}
        onClickUpdate={() =>
          this.props.history.push(`/dai-ly/cap-nhat/${record.id}`)
        }
        isDisplayLockButton={false}
        isDisplayUpdateButton={this.checkUpdateAble(record)}
      />
    );
  };

  checkUpdateAble = (record: any): any => {
    if (!checkPermission(per_view_detail_agency)) return false;
    return true;
  };

  renderStatusColumn = (text, record, index) => {
    return (
      <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
        {STATUS_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  columns = [
    { title: "Mã đại lý", dataIndex: "code" },
    { title: "Mã số thuế", dataIndex: "tax_code" },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: this.renderStatusColumn
    },
    { title: "Tên đại lý", dataIndex: "fullname", className: "css_agency_name" },
    { title: "Tên viết tắt", dataIndex: "sortname" },
    { title: "Email", dataIndex: "email" },
    { title: "SĐT", dataIndex: "phone" },
    { title: "Người quản lý", dataIndex: "sale_fullname" },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: (text, record, index) => formatDateTimeAll(text)
    },
    {
      title: "Tác vụ",
      render: this.onRenderActionColumn
    }
  ];
}
