import React, { Component } from 'react'
import { match, RouteComponentProps } from 'react-router'
import withNavbar from '../../../common/HOC/NavbarContainerHOC'
import UpdateFormDetail from './UpdateForm'

interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams>{
    match:match<MatchParams>;
}
interface State {
}

class UpdatePage extends Component<Props, State> {
    state = {}

    componentDidMount(){
    }

    render() {
        return (
            <div className="card">
                <div className="card-title1 ml-3">Duyệt đại lý</div>
                <div className="card-body">
                    <UpdateFormDetail history={this.props.history} id={this.props.match.params.id}/>
                </div>
            </div>
        )
    }
}

const updateWithNavBar = withNavbar()(UpdatePage);

export default updateWithNavBar;

