import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_SERVICES = "admin/quan-ly-hop-dong/";
class ContractManagerService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            PREFIX_SERVICES + "index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async onPreviewFile(id, type, isAddendum, typeAddendum){
        const result = await this.request(
            PREFIX_SERVICES + `previewFile/${id}`,
            {type, isAddendum, typeAddendum},
            TypeMethodContsantEnum.GET
        );
        return result;
    };
    
    public async getListCrmUser(){
        const result = await this.request(
            PREFIX_SERVICES + `getListCrmUser`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    };
}

export default ContractManagerService;
