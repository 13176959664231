import React, { Component } from 'react'

interface Props {
    btnClass?:string,
    iconClass?:string,
    onClick:any,
    text?:string,
}
interface State {
}

export default class SubmitButton extends Component<Props, State> {
    state = {}

    render() {
        return (
            <button type="button" onClick={this.props.onClick} className={this.props.btnClass || ' btn-submit-form btn btn-primary ml-1 mr-1'}>
                <i className={this.props.iconClass || "fas fa-save mr-1"}/>
                {' ' + (this.props.text || 'Lưu')}
            </button>
        )
    }
}
