import React, { useState, useEffect } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import { TypeWhToken } from "../Enum";
import { Input } from "antd";
import SelectDate from "../../../common/form/SelectDate";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import TableAddRowAsset from "../../../common/table/TableAddRowAsset";
import SelectBox from "../../../common/form/SelectBox";
import ClickButton from "./../../../common/button/ClickButton";
import AssetCategoryService from "../../../../services/asset-category/AssetCategoryService";
import { getDateCurrent } from "./../../../../helpers/NewCaHelper";
import WhTokenExportService from "./../../../../services/whTokenExport/WhTokenExportService";
import { onSuccessAction } from "../../../../helpers/SwalCommon";
import { onFailAction } from "./../../../../helpers/SwalCommon";
import DataWhService from "./../../../../services/data-wh/DataWhService";
import Store from "../../../../store/Store";

const { TextArea } = Input;

interface FormValues {
  code: string;
  note: string;
  exporter_pos: string;
  type: string;
  location: string;
  receiver: string;
  export_date: string;
  owner_id: string;
  belong_to: string;
  dataToken: object;
  dataDoc: object;
  dataAsset: object;
}

interface OtherProps {
  history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const WhTokenCreate: React.FC<Props> = props => {
  const [agency, setAgency] = useState({});
  const [dataAssetState, setdataAssetState] = useState({});
  const [keyDelete, setKeyDelete] = useState({});
  const [assetCateGiftList, setAssetCateGiftList] = useState({});
  //const [totalMoney, setTotalMoney] = useState({});
  const userName =  Store.getState().authReducer.fullname;
  const {
    code,
    note,
    location,
    receiver,
    export_date,
    owner_id,
    belong_to,
    dataAsset
  } = props.values;

  const getAgency = async type => {
    const Service = new DataWhService();
    const lst = await Service.getAgencyContributor(type);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      setAgency(agency);
      props.setFieldValue("owner_id", "");
    }
  };

  const onChangeSelectDateExportValid = (date, dateString) => {
    props.setFieldValue("export_date", dateString);
    if (dateString) {
      fetchCodeExport(dateString);
    } else {
      fetchCodeExport(getDateCurrent("-"));
    }
  };

  const handleChangeBoxAgency = e => {
    props.setFieldValue("owner_id", e);
  };

  const changedataAsset = e => {
    let key = $(e.target)
      .parent()
      .parent()
      .parent()
      .data("row-key");

    let value = e.target.value;
    dataAssetState[key][$(e.target).data("key")] = value;
    setdataAssetState(dataAssetState);
    props.setFieldValue("dataAsset", dataAssetState);
    if ($(e.target).data("key") === "asset_id") {
      showAssetRest(key);
    }
    if (
      $(e.target).data("key") === "amount" ||
      $(e.target).data("key") === "price"
    ) {
      // showTotalMoney(key);
      // showTotal();
    }
  };

  // const showTotalMoney = async key => {
  //   if (dataAssetState[key]["amount"] && dataAssetState[key]["price"]) {
  //     let total =
  //       Number(dataAssetState[key]["amount"]) *
  //       Number(dataAssetState[key]["price"]);
  //     dataAssetState[key]["total"] = total;
  //   } else {
  //     dataAssetState[key]["total"] = 0;
  //   }
  //   setdataAssetState(dataAssetState);
  //   props.setFieldValue("dataAsset", dataAssetState);
  // };

  // const showTotal = () => {
  //   let total = 0;
  //   Object.keys(dataAssetState).map(
  //     (key, index) => (total = total + Number(dataAssetState[key]["total"]))
  //   );
  //   setTotalMoney(total);
  // };

  const showAssetRest = async key => {
    if (dataAssetState[key]["asset_id"]) {
      const service = new DataWhService();
      const result = await service.getAsset({
        asset_id: dataAssetState[key]["asset_id"]
      });
      if (result && result.status === 200) {
        dataAssetState[key]["rest"] = result.data;
      } else {
        dataAssetState[key]["rest"] = 0;
      }
    } else {
      dataAssetState[key]["rest"] = 0;
    }
    setdataAssetState(dataAssetState);
    props.setFieldValue("rest", dataAssetState);
  };

  const onClickDeleteGift = () => {
    let newDataDelete = removeObjectByKey(dataAssetState);
    const arr: string[] = [];
    $.each(newDataDelete, function(key, value) {
      if (value) {
        arr.push(value);
      }
    });
    setdataAssetState(arr);
    props.setFieldValue("dataAsset", arr);
  };

  const onChangeSelectedType = (e) => {
    props.setFieldValue("belong_to", e);
    if (!e) {
      setAgency({});
    } else {
      let type = 7;
      if (Number(e) === 2) type = 8;
      getAgency(type);
    }
  };

  const getKeyDelete = e => {
    let key = $(e.target)
      .parent()
      .parent()
      .parent()
      .data("row-key");
    setKeyDelete(key);
  };

  const removeObjectByKey = arr => {
    delete arr[Number(keyDelete)];
    return arr;
  };

  const setDataFirst = () => {
    const dataFirstGift = [
      {
        key: 0,
        asset_id: "",
        rest: "0",
        amount: "",
        price: "",
        total: "0",
        unit: ""
      }
    ];

    setdataAssetState(dataFirstGift);
    props.setFieldValue("dataAsset", dataFirstGift);
  };

  const fetchWhAssetOptionGift = async () => {
    const assetCate = new AssetCategoryService();
    const result = await assetCate.all(2);
    const options = {};
    result.data.forEach(element => {
      options[element.id] = element.name;
    });

    setAssetCateGiftList(options);
  };
  const fetchCodeExport = async date => {
    const getCodeService = new WhTokenExportService();
    const result = await getCodeService.getCode(date);
    if (result && result.status === 200) {
      props.setFieldValue("code", result.data);
    }
  };

  useEffect(() => {
    const date = getDateCurrent("-");
    fetchWhAssetOptionGift();
    fetchCodeExport(date);
    setDataFirst();
    props.setFieldValue("type", "2");
    //setTotalMoney(0);
    // eslint-disable-next-line
  }, []);

  const onClickAddHandleAsset = async () => {
    let key = Object.keys(dataAssetState).length;
    let newData = {
      key: key,
      asset_id: "",
      rest: "",
      amount: "",
      price: "",
      total: "",
      unit: ""
    };

    var totalData = { ...dataAssetState, [key]: newData };
    setdataAssetState(totalData);
    props.setFieldValue("dataAsset", totalData);
  };
  return (
    <div className="card">
      <h4 className="card-title1 ml-3">Xuất kho tài sản</h4>
      <div className="card-body">
        <form>
          <div className="input-group">
            <InputWithLabel
              name="code"
              label="Số hóa đơn"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={code}
              error={props.errors.code}
              isRequired={true}
              maxLength={255}
              isDisabled={true}
            />
            <SelectBox
              options={TypeWhToken.TYPEAGENCY}
              label="Loại người dùng"
              name="belong_to"
              wrappedClass="col-md-3"
              onChange={onChangeSelectedType}
              value={belong_to}
              firstOptionLabel="Lựa chọn"
              error={props.errors.belong_to}
              isRequired={true}
            />
            <SelectBoxAgency
              options={agency}
              label="Đại lý/cộng tác viên"
              name="owner_id"
              wrappedClass="col-md-3"
              onChange={handleChangeBoxAgency}
              value={owner_id}
              firstOptionLabel="Chọn đại lý/cộng tác viên"
              error={props.errors.owner_id}
              isRequired={true}
            />
            <InputWithLabel
              name="exporter"
              label="Người xuất kho"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={userName}
              maxLength={255}
              isDisabled={true}
            />
          </div>
          <div className="input-group">
            <InputWithLabel
              name="receiver"
              label="Người nhận hàng"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={receiver}
              error={props.errors.receiver}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="location"
              label="Tại kho"
              wrapClass="col-md-6"
              onChangeValue={props.handleChange}
              value={location}
              error={props.errors.location}
              isRequired={false}
              maxLength={255}
            />
            <SelectDate
              label="Ngày xuất kho"
              name="export_date"
              wrappedClass="col-md-3"
              onChange={onChangeSelectDateExportValid}
              value={export_date}
              error={props.errors.export_date}
              isRequired={true}
              placeholder={"yyyy-mm-dd"}
            />
          </div>
          <div className="input-group">
            <div className="col-md-12">
              <label>Về việc</label>
              <TextArea
                name="note"
                placeholder="Ghi chú"
                autoSize={{ minRows: 4, maxRows: 10 }}
                className="form-control"
                required={false}
                value={note}
                onChange={props.handleChange}
              />
            </div>
          </div>
          <div className="input-group col-md-12 m-t-20">
            <div className="col-md-11 p-l-0">
              <h5>Tài sản</h5>
            </div>
            <div className="col-md-1 p-r-0">
              <ClickButton
                text="Thêm"
                css="btn btn-success ml-1 mr-1 font-btn float-right"
                onClick={onClickAddHandleAsset}
              ></ClickButton>
            </div>
            <TableAddRowAsset
              assetList={assetCateGiftList}
              data={dataAsset}
              changeData={changedataAsset}
              onDelete={onClickDeleteGift}
              keyDelete={getKeyDelete}
              errors={props.errors}
              tittle={"Danh mục tài sản"}
            />
          </div>
          {/* <div className="input-group col-md-12 m-t-20 ">
            <div className="col-md-10"></div>
            <div className="col-md-2 pull-right">
              <h6>Tổng tiền(VNĐ) : {Number(totalMoney)}</h6>
            </div>
          </div> */}

          <div className="input-group d-flex justify-content-center p-5">
            <BackButton history={props.history}></BackButton>
            <SubmitButton onClick={props.handleSubmit}></SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const RenderCreateForm = withFormik<Props, FormValues>({
  mapPropsToValues: () => ({
    code: "",
    note: "",
    exporter_pos: "",
    type: "",
    location: "",
    receiver: "",
    export_date: "",
    owner_id: "",
    belong_to: "",
    dataToken: {},
    dataDoc: {},
    dataAsset: {}
  }),
  validationSchema: Yup.object().shape({
    belong_to: Yup.string().required("Phải lựa chọn loại người dùng."),
    owner_id: Yup.string().required(
      "Đại lý/ cộng tác viên không thể bỏ trống."
    ),
    receiver: Yup.string().required("Người nhận không thể bỏ trống."),
    export_date: Yup.date().required("Ngày xuất kho không được trống"),
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new WhTokenExportService();
    const result = await service.storeAsset(values);
    if (result && result.status === 200) {
      onSuccessAction("Xuất kho tài sản thành công!", function() {
        props.history.goBack();
      });
    } else if (result && result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction(result.error);
    }
  }
})(WhTokenCreate);

export default withNavbar()(RenderCreateForm);
