import React, {Component} from "react";
import "../css/main.css";
import SelectBox from "../form/wh/SelectBox";
import InputWithLabel from "../form/InputWithLabel";
import {Popconfirm} from "antd";

interface Props {
    assetList?: any;
    changeData?: any;
    errors?: any;
    data?: any;
    onDelete?: any;
    keyDelete?: any;
    tittle?: string;
    isHidden?: boolean;
    isDisable?: boolean;
}

export default class Table extends Component<Props> {
    render() {
        const dataSource = this.props.data;
        return (
            <table className="w-100 m-t-20 ant-table-bordered" key="2">
                <thead className="ant-table-thead">
                <tr>
                    <th className="text-center th-stt">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">STT</span>
                  <span className="ant-table-column-sorter"/>
                </div>
              </span>
                    </th>
                    <th className="text-center w-40">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    {this.props.tittle
                        ? this.props.tittle
                        : "Danh mục quà tặng"}
                  </span>
                  <span className="ant-table-column-sorter"/>
                </div>
              </span>
                    </th>
                    <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Đơn vị</span>
                  <span className="ant-table-column-sorter"/>
                </div>
              </span>
                    </th>
                    <th className="text-center w-10">
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Số lượng</span>
                  <span className="ant-table-column-sorter"/>
                </div>
              </span>
                    </th>
                    <th className="text-center" hidden={this.props.isHidden ? this.props.isHidden : false}>
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">
                    Số lượng còn lại
                  </span>
                  <span className="ant-table-column-sorter"/>
                </div>
              </span>
                    </th>
                    {/* <th className="text-center w-10">
                      <span className="ant-table-header-column">
                        <div>
                          <span className="ant-table-column-title">Đơn giá(VNĐ)</span>
                          <span className="ant-table-column-sorter"/>
                        </div>
                      </span>
                    </th> */}
                    {/* <th className="text-center w-10">
                      <span className="ant-table-header-column">
                        <div>
                          <span className="ant-table-column-title">Thành tiền(VNĐ)</span>
                          <span className="ant-table-column-sorter"/>
                        </div>
                      </span>
                    </th> */}
                    <th className="text-center" hidden={this.props.isHidden ? this.props.isHidden : false}>
              <span className="ant-table-header-column">
                <div>
                  <span className="ant-table-column-title">Tác vụ</span>
                  <span className="ant-table-column-sorter"/>
                </div>
              </span>
                    </th>
                </tr>
                </thead>
                <tbody className="ant-table-tbody">
                {dataSource ? (
                    Object.keys(dataSource).map((key, index) => (
                        <tr
                            className={`ant-table-row editable-row ant-table-row-level-${key}`}
                            data-row-key={key}
                            key={key}
                        >
                            <td className="text-center">{1 + Number(key)}</td>
                            <td>
                                <SelectBox
                                    name={`doc_asset_id${key}`}
                                    options={this.props.assetList}
                                    label=""
                                    wrappedClass=""
                                    firstOptionLabel={"Chọn danh mục tài liệu"}
                                    onChange={this.props.changeData}
                                    value={dataSource[key]["doc_asset_id"]}
                                    error={
                                        this.props.errors.dataDoc &&
                                        dataSource[key]["doc_asset_id"] === ""
                                            ? "Danh mục tài liệu không được trống"
                                            : ""
                                    }
                                    isRequired={true}
                                    dataKey={"doc_asset_id"}
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`doc_unit${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["doc_unit"]}
                                    isRequired={true}
                                    maxLength={255}
                                    dataKey={"doc_unit"}
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`doc_amount${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["doc_amount"]}
                                    error={
                                        this.props.errors.dataDoc &&
                                        dataSource[key]["doc_amount"] !== "" &&
                                        (!Number.isInteger(Number(dataSource[key]["doc_amount"])) ||
                                            Number(dataSource[key]["doc_amount"]) < 1)
                                            ? "Số lượng phải là số nguyên dương > 0"
                                            : "" ||
                                            (this.props.errors.dataDoc &&
                                                dataSource[key]["doc_amount"] === "")
                                            ? "Số lượng không được trống"
                                            : "" ||
                                            Number(dataSource[key]["doc_amount"]) >
                                            Number(dataSource[key]["rest"])
                                                ? "Số lượng không đủ"
                                                : ""
                                    }
                                    isRequired={true}
                                    maxLength={255}
                                    dataKey={"doc_amount"}
                                    type="number"
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td>
                            <td className="text-center"
                                hidden={this.props.isHidden ? this.props.isHidden : false}>{dataSource[key]["rest"]}</td>
                            {/* <td className="text-right">
                                <InputWithLabel
                                    name={`price${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["price"]}
                                    error={
                                        this.props.errors.dataDoc &&
                                        dataSource[key]["price"] !== "" &&
                                        (!Number.isInteger(Number(dataSource[key]["price"])) ||
                                            Number(dataSource[key]["price"]) < 1)
                                            ? "Đơn giá phải là số nguyên dương"
                                            : ""
                                    }
                                    isRequired={true}
                                    maxLength={255}
                                    dataKey={"price"}
                                    type="number"
                                    min={1}
                                    isDisabled={this.props.isDisable ? this.props.isDisable : false}
                                />
                            </td> */}
                            {/* <td className="text-right">{dataSource[key]["total"]}</td> */}
                            <td className="text-center" hidden={this.props.isHidden ? this.props.isHidden : false}>
                                {Number(dataSource[key]['key']) === 0 ? "" : (
                                    <Popconfirm
                                        title="Bạn có chắc chắn xóa?"
                                        onConfirm={this.props.onDelete}
                                        okText="Xóa"
                                        cancelText="Bỏ qua"
                                    >
                                        <a
                                            href="/#"
                                            className="pointer text-danger ml-1 mr-1"
                                            title="Xóa"
                                            onClick={this.props.keyDelete}
                                        >
                                            <i className="fas fa-trash-alt fa-lg"/>
                                        </a>
                                    </Popconfirm>)}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr/>
                )}
                </tbody>
            </table>
        );
    }
}
