import React, {useState} from "react"
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON, reloadPage, checkPermission} from "../../../helpers/NewCaHelper"
import {RouteComponentProps} from "react-router-dom"
import InputWithLabel from "../../common/form/InputWithLabel"
import SearchButton from "../../common/button/SearchButton"
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton"
import NewTable from "../../common/table/NewTable"
import {onDeleteAlert, onFailAction, onSuccessAction, onLockAlert} from "../../../helpers/SwalCommon"
import TableActionButton from "../user/table/action-button/TableActionButton"
import withNavbar from "../../common/HOC/NavbarContainerHOC"
import CateDiscountService from "../../../services/cate-discount/CateDiscountService"
import SelectBox from "../../common/form/SelectBox"
import { Tag } from "antd"
import SelectDateFormat from "../../common/form/SelectDateFormat";

interface Props extends RouteComponentProps {
}

const TITLE = "Danh sách chiết khấu"
const per_add = "cate-discount-create"
const per_update = "cate-discount-update"
const per_delete = "cate-discount-delete"

const CateDiscount: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({name: "", code: "", type: "", belong_to: "", owner_name: "", status: "", createdAt: ""})

    const onSubmitSearchButton = e => {
        e.preventDefault()
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        })
    }

    const onChangeDataSearchInput = e => {
        const name = e.target.name
        const value = e.target.value
        setDataSearch({...dataSearch, [name]: value})
    }
    const onChangeDataSearchType = (e) => {
        setDataSearch({...dataSearch, "type": e})
    }
    const onChangeDataSearchBelongTo = (e) => {
        setDataSearch({...dataSearch, "belong_to": e})
    }
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, "status": e})
    }
    const onChangeSelectDateCreatedAt = (date, dateString)=> {
      setDataSearch({...dataSearch, createdAt: dateString})
    }

    const fetchDataFunction = async () => {
        const service = new CateDiscountService();
        const queryString = queryStringToJSON(props.location.search)
        try {
            return await service.list({...dataSearch,...queryString})
        } catch (error) {
            onFailAction(error.message)
        }
    };

    const onClickDeleteButton = code => {
        onDeleteAlert(async willDelete => {
            if (willDelete) {
                const service = new CateDiscountService();
                const result = await service.delete(code);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa bản ghi chiết khấu thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
                else onFailAction("Có lỗi xảy ra");
            }
        });
    };
    const onClickLockButton = code => {
        onLockAlert(async willDelete => {
          if (willDelete) {
              const service = new CateDiscountService();
              const result = await service.lock(code,1);
              if (result && result.status === 200) {
                  onSuccessAction(
                      "Khóa chiết khấu thành công!",
                      reloadPage(props.location, props.history)
                  );
              }
              else onFailAction("Có lỗi xảy ra");
          }
      });
  };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickView= {() => props.history.push(`/danh-sach-chiet-khau/chi-tiet/${record.id}`)}
                onClickUpdate={ checkPermission(per_update) ? () =>
                    props.history.push(`/danh-sach-chiet-khau/cap-nhat/${record.id}`) :null
                }
                onClickLock={()=> onClickLockButton(record.code)}
                onClickDelete={ checkPermission(per_delete) ? () => onClickDeleteButton(record.code) : undefined}
                isLock={Number(record.status) !== 3}
            />
        );
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                      <div className="row input-group">
                        <InputWithLabel
                            name="name"
                            wrapClass="col-md-2 form-group"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.name}
                            isRequired={false}
                            label={""}
                            placeholder={"Tên chiết khấu"}
                        />
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group "
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã chiết khấu"}
                        />
                        <SelectBox
                            name="type"
                            wrappedClass="col-md-2 form-group "
                            label=""
                            options={{
                              1: "Chiết khấu thông thường",
                              2: "Chiết khấu đặt biệt"
                            }}
                            firstOptionLabel="Loại chiết khấu"
                            onChange={onChangeDataSearchType}
                            value={dataSearch.type}
                        />
                        <SelectBox
                            name="status"
                            wrappedClass="col-md-2 form-group "
                            label=""
                            options={{
                              1: "Còn hiệu lực",
                              2: "Hết hiệu lực",
                              3: "Đã khóa"
                            }}
                            firstOptionLabel="Hiệu lực"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.status}
                        /> 
                      </div>
                      <div className="row input-group mt-2">
                        <SelectBox
                              name="belong_to"
                              wrappedClass="col-md-2 form-group "
                              label=""
                              options={{
                                1: "Đại lý",
                                2: "Cộng tác viên"
                              }}
                              firstOptionLabel="Loại người dùng"
                              onChange={onChangeDataSearchBelongTo}
                              value={dataSearch.belong_to}
                        />
                        <InputWithLabel
                            name="owner_name"
                            wrapClass="col-md-2 form-group"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.owner_name}
                            isRequired={false}
                            label={""}
                            placeholder={"Tên đại lý/CTV"}
                        />
                        <SelectDateFormat
                            label=""
                            name="createdAt"
                            wrappedClass="col-md-2 form-group"
                            onChange={onChangeSelectDateCreatedAt}
                            value={dataSearch.createdAt}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-4 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`} hidden={!checkPermission(per_add)}/>
                        </div> 
                      </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Tên chiết khấu", dataIndex: "name"},
                        {title: "Mã chiết khấu", dataIndex: "code"},
                        {
                          title: "Loại chiết khấu", 
                          dataIndex: "type",
                          render: text => 
                            text === 1 ? "Chiết khấu thông thường" : "Chiết khấu đặt biệt"

                        },
                        {
                          title: "Tên đại lý/ CTV",
                          dataIndex: "",
                          render: (e) =>{
                            if (e.type === 2) {
                              if (e.belong_to === 1) return e.agency.fullname
                              else  return e.contributor.fullname
                            }
                          }       
                        },
                        {
                          title: "Loại người dùng",
                          dataIndex: "",
                          render: (e) => {
                            if(e.belong_to === 1) return <Tag color = "cyan">{"Đại lý"}</Tag>
                            else if (e.belong_to === 2) return <Tag color = "green">{"Cộng tác viên"}</Tag>
                          }
                        },
                        {
                          title: "Hiệu lực",
                          dataIndex: "",
                          render: (e) => {
                            if (e.status === 1) return <Tag color = "geekblue">{"Còn hiệu lực"}</Tag>
                            else if (e.status === 2) return <Tag color = "purple">{"Hết hiệu lực"}</Tag>
                            else if (e.status ===3) return <Tag color = "red">{"Đã khóa"}</Tag>
                          }
                        },
                        {
                          title: "Ngày tạo",
                          dataIndex:"created_at",
                          className: "text-center",
                          render: text => formatDateTime(text)
                        },

                        {
                          title: "Tác vụ",
                          render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(CateDiscount)
