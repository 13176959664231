import React, {useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString, formatDateTimeAll, queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import {onFailAction} from "../../../helpers/SwalCommon";
import { Tag } from 'antd';
import SelectDate from "../../common/form/SelectDate";
import LogActionService from "../../../services/logaction/LogActionService";
import { actions, categories } from "./constant";

interface Props extends RouteComponentProps{
}

const TITLE = "Quản lý log";

const LogManagement: React.FC<Props> = props => {
    const service = new LogActionService();
    const [dataSearch, setDataSearch] = useState({
      username: "",type:"", created_at: "",category:"",action:""});
    const onSubmitSearchButton = e => {
        e.preventDefault();

        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at: dateString });
    };
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchTypeRequest = (e) => {
        setDataSearch({...dataSearch, "type": e});
    };
    const onChangeDataSearchActionRequest = (e) => {
      setDataSearch({...dataSearch, "action": e});
  };
  const onChangeDataSearchCategoryRequest = (e) => {
    setDataSearch({...dataSearch, "category": e});
};
    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <SelectBox
                            options={{1: "Admin" , 2: "QLBH"}}
                            label={""}
                            name={"type"}
                            wrappedClass={"col-md-2 nopadding-left"}
                            onChange={onChangeDataSearchTypeRequest}
                            value={dataSearch.type}
                            firstOptionLabel = {"--Loại người dùng--"}
                        />
                        <SelectBox
                            options={categories}
                            label={""}
                            name={"category"}
                            wrappedClass={"col-md-2 nopadding-left"}
                            onChange={onChangeDataSearchCategoryRequest}
                            value={dataSearch.category}
                            firstOptionLabel = {"--Danh mục--"}
                        />
                        <SelectBox
                            options={actions}
                            label={""}
                            name={"action"}
                            wrappedClass={"col-md-2 nopadding-left"}
                            onChange={onChangeDataSearchActionRequest}
                            value={dataSearch.action}
                            firstOptionLabel = {"--Hành động--"}
                        />
                        <InputWithLabel
                            name="username"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.username}
                            label={""}
                            placeholder="Tên người dùng"
                        />
                        <SelectDate
                            label=""
                            name="created_at"
                            wrappedClass="col-md-2 form-group nopadding-left"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-2 form-group nopadding-left">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                      {
                        title: "Người tạo",
                        dataIndex: "",
                        render: e => (<div>{e.created_by_data?.username}</div>)
                    },
                    {
                      title: 'Loại khách hàng',
                      dataIndex: "",
                      render: (e) => {
                      if (e) {
                        if(e.type===1){
                          return <Tag color="green">Admin</Tag>
                        }else{
                          return <Tag color="blue">QLBH</Tag>
                              }
                        }
                      }
                    },
                    {
                      title: "Hành động",
                      dataIndex: "",
                      render: (e) => {
                        if (e.action) {
                         return <div>{actions[e.action]}</div>;
                        }
                        }
                    },
                    {
                      title: "Danh mục",
                      dataIndex: "",
                      render: (e) => {
                        if (e.category) {
                          return <div>{categories[e.category]}</div>;
                          }
                        }
                    },
                   
                    {
                      title: "Id bản ghi",
                      dataIndex: "target_id",
                    },
                    {
                      title: "Mô tả",
                      dataIndex: "description",
                    },
                    {
                      title: "Ngày tạo",
                      dataIndex: "created_at",
                      render: text => formatDateTimeAll(text)
                    }
                    
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
}
export default withNavbar()(LogManagement);
