import React, { Component} from "react";
import TableClass from "./TableClass";
import Panigation from "./pagination/Panigation";
import FetchAPI from "../../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../../helpers/constant/MethodAPIConstant";
import "./table.css";
import { Location, History } from "history";
import {
  queryStringToJSON,
  convertJsonToQueryString
} from "../../../helpers/NewCaHelper";
import { match } from "react-router";

interface Props {
  columns: Array<TableClass>;
  pagination: boolean;
  addActionRow?: boolean;
  thClassAction?: string;
  tdClassAction?: string;
  checkEditAble?: any;
  checkDeleteAble?: any;
  onClickEdit?: any;
  onClickDelete?: any;
  defaultTdClass?: string;
  defaultThClass?: string;
  isDisabledIndexColum?: boolean;
  location: Location;
  fetchDataFunction: any;
  history: History;
  match: match;
}
interface State {
  lastPage: number;
  currentPage: number;
  dataPaginate: any;
  dataIsLoadded: boolean;
  raw: number;
}

export default class Table extends Component<Props, State> {
  state = {
    lastPage: 1,
    currentPage: 1,
    dataPaginate: { data: { data: [], path: "" }, data_search: {} },
    dataIsLoadded: false,
    raw: 10
  };

  public static defaultProps = {
    checkEditAble: () => true,
    checkDeleteAble: () => true,
    onClickEdit: () => null,
    onClickDelete: () => null
  };

  childFunction = () => {
  };

  componentDidMount() {
    this.fetchingData();
  }

  fetchingData = async () => {
    const queryString = queryStringToJSON(this.props.location.search);
    const fetchData = await this.props.fetchDataFunction(queryString);
    if (fetchData) {
      this.setState({
        dataPaginate: fetchData,
        lastPage: fetchData.data.last_page,
        currentPage: fetchData.data.current_page
      });
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchingData();
    }
    window.onpopstate = e => {
      this.fetchingData();
    };
  }

  render() {
    const { pagination, thClassAction, defaultThClass } = this.props;
    const { lastPage, currentPage } = this.state;
    return (
      <div className="table-responsive table-striped">
        <table className="table table-bordered">
          <thead>
            <tr className="tr-thead">
              {this.props.isDisabledIndexColum ? (
                ""
              ) : (
                <th className={defaultThClass}>STT</th>
              )}
              {this.props.columns.map((column, index) => {
                return (
                  <th
                    className={column.thClass ? column.thClass : defaultThClass}
                    key={index}
                  >
                    {column.headers}
                  </th>
                );
              })}
              {this.props.addActionRow ? (
                <th className={thClassAction ? thClassAction : defaultThClass}>
                  Tác vụ
                </th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>{this.customerRenderTD()}</tbody>
        </table>
        {pagination ? (
          <Panigation
            pageCount={lastPage}
            currentPage={currentPage - 1}
            onChangePage={this.onChangePage}
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  onChangePage = async page => {
    const { dataPaginate } = this.state;
    const pageSelected = page.selected + 1;
    const fetchAPI = new FetchAPI();
    const dataQuery = queryStringToJSON(this.props.location.search);
    const queryString = convertJsonToQueryString({
      ...dataQuery,
      page: pageSelected
    });
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
    const data = await fetchAPI.request(
      dataPaginate.data.path,
      {
        ...dataQuery,
        page: pageSelected
      },
      TypeMethodContsantEnum.GET
    );
    if (data) {
      this.setState({
        dataPaginate: data,
        currentPage: data.data.current_page
      });
    }
  };

  onRenderActionCell(data) {
    const {
      checkDeleteAble,
      checkEditAble,
      tdClassAction,
      defaultTdClass
    } = this.props;
    if (this.props.addActionRow) {
      return (
        <td className={tdClassAction ? tdClassAction : defaultTdClass}>
          {checkEditAble
            ? this.onRenderEditCell(checkEditAble(data), data)
            : ""}
          {checkDeleteAble
            ? this.onRenderDeleteCell(checkDeleteAble(data), data)
            : ""}
        </td>
      );
    }
  }

  onRenderIndexRow = index => {
    const { isDisabledIndexColum, defaultTdClass } = this.props;
    const { currentPage, raw } = this.state;
    return isDisabledIndexColum ? (
      ""
    ) : (
      <td className={defaultTdClass}>{index + (currentPage - 1) * raw + 1}</td>
    );
  };

  onRenderEditCell(checkEditAble, data) {
    if (checkEditAble) {
      return (
        // eslint-disable-next-line
        <a
          className="pointer text-primary pl-2"
          title="Sửa"
          onClick={() => this.props.onClickEdit(data)}
        >
          <i className="far fa-edit fa-lg" />
        </a>
      );
    }
  }

  onRenderDeleteCell(checkDeleteAble, data) {
    if (checkDeleteAble) {
      return (
        // eslint-disable-next-line
        <a
          className="pointer text-danger pl-2"
          title="Xóa"
          onClick={() => this.props.onClickDelete(data)}
        >
          <i className="far fa-trash-alt fa-lg" />
        </a>
      );
    }
  }

  customerRenderTD() {
    const { defaultTdClass } = this.props;
    return this.state.dataPaginate.data.data.map((data, index) => {
      return (
        <tr key={index}>
          {this.onRenderIndexRow(index)}
          {this.props.columns.map((column, index2) => {
            return (
              <td
                className={column.tdClass ? column.tdClass : defaultTdClass}
                key={index2}
              >
                {column.cell
                  ? column.cell(data[column.accessor])
                  : data[column.accessor]}
              </td>
            );
          })}
          {this.onRenderActionCell(data)}
        </tr>
      );
    });
  }
}
