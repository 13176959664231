export const STATUS_TOKEN_FOR_CTS_LABEL = {
    1: {
        label: "Đã cấp phát",
        class: "green"
    },
    2: {
        label: "Chưa cấp phát",
        class: "orange"
    },
    3: {
        label: "Mở generate",
        class: "blue"
    }
};

export const STATUS_CTS_IN_TOKEN_LABEL = {
    1: {
        label: "Chưa xóa",
        class: "geekblue"
    },
    2: {
        label: "Đã xóa",
        class: "red"
    },
    3: {
        label: "Chờ xoá",
        class: "orange"
    }
};


export const TYPE_PROMOTION_OPTION = {
    1: {
        label: "Thêm mới",
        class: "geekblue"
    },
    2: {
        label: "Thay thế",
        class: "blue"
    }
};

export const STATUS_TOKEN_LABEL = {
    1: {
        label: "Khoá",
        class: "orange"
    },
    2: {
        label: "Mở khoá",
        class: "green"
    },
    9: {
        label: "Chờ mở khoá",
        class: "orange"
    },
    10: {
        label: "Chờ khoá",
        class: "orange"
    }
};