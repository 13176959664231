import React, {Component} from "react";
import {InjectedFormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import PreviewButton from "../../../common/button/PreviewButton";
import SubmitButton from "../../../common/form/SubmitButton";
import Radio from "../../../common/form/Radio";
import DenyButton from "../../../common/button/DenyButton";
import ButtonLink from "../../../common/button/ButtonLink";
import {History} from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import WrapperButtonInput from "../../../common/form/wrapper-button-form/wrapperButtonInput";
import ModalView from "../../../common/form/Modal";
import ModalDeny from "../../../common/form/ModalDeny";
import {onFailAction, onSuccessAction} from "../../../common/swal/SwalCommon";
import RequestTokenService from "../../../../services/requesttokenagency/RequestTokenService";
import {TYPE_PAY, TYPE_RECEIVE} from "../RequestTkAdminEnum";
import {Checkbox, Form, Input} from 'antd';
import moment from "moment";
import {checkPermission} from '../../../../helpers/NewCaHelper';

const {TextArea} = Input;

interface Props {
    id: string;
    history: History;
}

interface State {
    owner: any;
    agency: any;
    contributor: any;
    base64: string;
    token_admin_num: string;
    receive_type: string;
    type_pay: string;
    box_num: string;
    code: string;
    envelope_num: string;
    receive_fullname: string;
    receive_passport: string;
    receive_address: string;
    receive_phone: string;
    status: string;
    token_admin_note: string;
    receive_date: string;
    status_create_account: string;
    id: string;
    owner_id: string;
    acc_type_login: string;
    acc_email: string;
    acc_full_name: string;
    acc_user_name: string;
    level_agency: any;
    acc_phone: string;
    belong_to: string;
    resign_document: boolean
}

const permission_confirm = 'request-account-admin-handle';
const permission_create_account = 'create-account-admin-agency';

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
    service = new RequestTokenService();
    state = {
        dataSearchDocument: {
            id: this.props.id,
            type: ""
        },
        visible_modal: false,
        visible_modal_deny: false,
        loading: false,
        title_modal: '',
        base64: '',
        type_file: '',
        text_deny: '',
        error_text_deny: '',
        type_contract: '',
        list_type_receive: {},
        list_type_pay: {},
        resign_document: false
    };

    componentDidMount() {
        this.getData().then();
        this.setState({list_type_receive: TYPE_RECEIVE});
        this.setState({list_type_pay: TYPE_PAY});
    }

    getData = async () => {
        const data = await this.service.getRequest(this.props.id);
        if (data && data.status === 200) {
            const {
                owner,
                agency,
                contributor,
                token_admin_num,
                receive_type,
                type_pay,
                box_num,
                envelope_num,
                receive_fullname,
                receive_passport,
                receive_address,
                receive_phone,
                status,
                token_admin_note,
                receive_date,
                status_create_account,
                id,
                owner_id,
                code,
                acc_type_login,
                acc_email,
                acc_full_name,
                acc_user_name,
                level_agency,
                acc_phone,
                resign_document,
                belong_to
            } = data.data;
            this.props.setValues({
                owner,
                agency,
                contributor,
                token_admin_num,
                receive_type,
                type_pay,
                box_num,
                envelope_num,
                receive_fullname,
                receive_passport,
                receive_address,
                receive_phone,
                status,
                token_admin_note,
                receive_date,
                status_create_account,
                id,
                owner_id,
                code,
                acc_type_login,
                acc_email,
                acc_full_name,
                acc_user_name,
                level_agency,
                acc_phone,
                resign_document,
                belong_to

            } as Pick<State, keyof State>);
        }
    };

    onClickModalFile = (tittle) => {
        this.fetchDataFunction().then();
        this.setState({visible_modal: true, title_modal: tittle});
    };

    fetchDataFunction = async () => {
        const result = await this.service.getFile(this.props.id);
        if (result && result.status === 200) {
            this.setState({loading: true});
            this.setState({base64: result.data});
            this.setState({type_file: result.type_file});
            return result
        } else {
            this.setState({base64: ''});
            onFailAction('Không tìm thấy file');
        }
        onFailAction('Có lỗi xảy ra khi lấy file');
    };

    handleModalCancel = () => {
        this.setState({
            visible_modal: false,
        });
    };

    submitApprove = async () => {
        const value = this.props.values;
        const self = this;
        const result = await this.service.getApprove({...value, id: this.props.id});
        if (result && result.status === 200) {
            onSuccessAction("Duyệt yêu cầu token admin thành công!", () => {
                self.props.history.push("/danh-sach-xuat-kho/xuat-kho-token");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        }
    }

    handleModalDenyCancel = () => {
        this.setState({
            visible_modal_deny: false,
        });
    };

    onChangeDataDeny = ({target: {value}}) => {
        this.setState({text_deny: value});
    };

    submitDeny = () => {
        this.setState({visible_modal_deny: true});
    }

    confirmDeny = async () => {
        const value = this.props.values;
        if (this.state.text_deny.replace(/\s+/g, '') === '') {
            this.setState({error_text_deny: 'Lý do từ chối không được trống'});
            return false;
        }
        const self = this;
        const result = await this.service.getDeny({...value, id: this.props.id, text_deny: this.state.text_deny});
        await this.setState({visible_modal_deny: false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối yêu cầu token admin thành công!", () => {
                self.props.history.push("/yeu-cau-tk-admin-token");
            });

        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.errors);
        }
    }

    checkTypeReceive = () => {
        const receive_type = this.props.values.receive_type + '';
        if (receive_type === "1") {
            return <InputWithLabel
                name="receive_passport"
                label="Số chứng minh nhân dân"
                wrapClass="col-md-3"
                onChangeValue={this.props.handleChange}
                value={this.props.values.receive_passport}
                isRequired={true}
                isDisabled={true}
            />;
        } else {
            return <React.Fragment>
                <InputWithLabel
                    name="receive_address"
                    label="Địa chỉ"
                    wrapClass="col-md-3"
                    onChangeValue={this.props.handleChange}
                    value={this.props.values.receive_address}
                    isRequired={true}
                    isDisabled={true}
                />
                <InputWithLabel
                    name="receive_phone"
                    label="Số điện thoại"
                    wrapClass="col-md-3"
                    onChangeValue={this.props.handleChange}
                    value={this.props.values.receive_phone}
                    isRequired={true}
                    isDisabled={true}
                />
            </React.Fragment>;
        }
    }

    getParamsAgency = (agency_id, code) => {
        return {
            type: 7,
            agency_id: agency_id,
            code: code
        };

    }

    checkButtonsConfirm = () => {
        return checkPermission(permission_confirm);

    };

    checkButtonCreateAccount = () => {
        return checkPermission(permission_create_account);

    };

    renderLevelAgency = (e) => {
        let text: string
        switch (e) {
            case 1:
                text = "Admin"
                break;
            case 2:
                text = "Cấp 2"
                break;
            default:
                text = ""
        }
        return text
    }

    render() {
        const {
            owner,
            agency,
            token_admin_num,
            receive_type,
            type_pay,
            box_num,
            envelope_num,
            receive_fullname,
            status,
            token_admin_note,
            receive_date,
            receive_passport,
            receive_address,
            receive_phone,
            status_create_account,
            owner_id,
            code,
            acc_type_login,
            acc_email,
            acc_full_name,
            acc_user_name,
            level_agency,
            acc_phone,
            resign_document,
            belong_to,
        } = this.props.values;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="input-group">
                    {
                        (belong_to) ? (
                            (Number(belong_to) === 1)
                                ? (
                                    <React.Fragment>
                                        <InputWithLabel
                                            name="agency_name"
                                            label="Tên đại lý"
                                            wrapClass="col-md-3"
                                            onChangeValue={this.props.handleChange}
                                            value={owner.fullname}
                                            isRequired={true}
                                            isDisabled={true}
                                        />
                                        <InputWithLabel
                                            name="tax_code"
                                            label="Mã số thuế"
                                            wrapClass="col-md-3"
                                            onChangeValue={this.props.handleChange}
                                            value={owner.tax_code}
                                            isRequired={true}
                                            isDisabled={true}
                                        />
                                    </React.Fragment>
                                )
                                : (
                                    <React.Fragment>
                                        <InputWithLabel
                                            name="agency_name"
                                            label="Tên cộng tác viên"
                                            wrapClass="col-md-3"
                                            onChangeValue={this.props.handleChange}
                                            value={owner.fullname}
                                            isRequired={true}
                                            isDisabled={true}
                                        />
                                        <InputWithLabel
                                            name="tax_code"
                                            label="HC/CMTND"
                                            wrapClass="col-md-3"
                                            onChangeValue={this.props.handleChange}
                                            value={owner.passport}
                                            isRequired={true}
                                            isDisabled={true}
                                        />
                                    </React.Fragment>
                                )
                        ) : (
                            <React.Fragment>
                                <InputWithLabel
                                    name="agency_name"
                                    label="Tên đại lý"
                                    wrapClass="col-md-3"
                                    onChangeValue={this.props.handleChange}
                                    value={agency.fullname}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                                <InputWithLabel
                                    name="tax_code"
                                    label="Mã số thuế"
                                    wrapClass="col-md-3"
                                    onChangeValue={this.props.handleChange}
                                    value={agency.tax_code}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                            </React.Fragment>
                        )
                    }

                    <InputWithLabel
                        name="code"
                        label="Mã yêu cầu"
                        wrapClass="col-md-2"
                        onChangeValue={this.props.handleChange}
                        value={code}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <Radio
                        options={this.state.list_type_pay}
                        name="type_pay"
                        label="Hình thức thanh toán"
                        wrappedClass="col-md-2"
                        onChange={this.props.handleChange}
                        value={type_pay}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <Radio
                        options={{1: "Token RA", 2: "Mật khẩu"}}
                        name="acc_type_login"
                        label="Loaị đăng nhập"
                        wrappedClass="col-md-2"
                        onChange={this.props.handleChange}
                        value={acc_type_login}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        label="Họ và tên"
                        name="acc_full_name"
                        wrapClass="col-md-3"
                        type="text"
                        value={acc_full_name}
                        onChangeValue={this.props.handleChange}
                        maxLength={255}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        label="Email"
                        name="acc_email"
                        wrapClass="col-md-3"
                        type="text"
                        maxLength={255}
                        onChangeValue={this.props.handleChange}
                        value={acc_email}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        label="Tên tài khoản"
                        name="acc_user_name"
                        wrapClass="col-md-2"
                        type="text"
                        maxLength={255}
                        onChangeValue={this.props.handleChange}
                        value={acc_user_name}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        label="Số điện thoại"
                        name="acc_phone"
                        isRequired={true}
                        wrapClass="col-md-2"
                        type="number"
                        onChangeValue={this.props.handleChange}
                        value={acc_phone}
                        isDisabled={true}
                    />
                    {
                        (Number(belong_to) === 2)
                            ? ( "" )
                            : ( <InputWithLabel
                                name="level_agency"
                                label="Phân quyền"
                                wrapClass="col-md-2"
                                onChangeValue={this.props.handleChange}
                                value={this.renderLevelAgency(level_agency)}
                                isRequired={true}
                                isDisabled={true}
                            />)
                    }
                </div>
                {(Number(acc_type_login) === 2 && !resign_document) ? ('') : (
                    <React.Fragment>
                        <div className="input-group">
                            <Radio
                                options={this.state.list_type_receive}
                                name="receive_type"
                                label="Hình thức nhận"
                                wrappedClass="col-md-3"
                                onChange={this.props.handleChange}
                                value={receive_type}
                                isRequired={true}
                                isDisabled={true}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                name="receive_fullname"
                                label="Người nhận"
                                wrapClass="col-md-3"
                                type="text"
                                onChangeValue={this.props.handleChange}
                                maxLength={255}
                                isRequired={true}
                                isDisabled={true}
                                value={receive_fullname}
                            />
                            <InputWithLabel
                                name="receive_date"
                                label="Ngày nhận"
                                wrapClass="col-md-3"
                                type="text"
                                maxLength={255}
                                onChangeValue={this.props.handleChange}
                                value={receive_date ? moment(receive_date).format('DD-MM-YYYY') : null}
                                isRequired={true}
                                isDisabled={true}
                            />
                            {Number(receive_type) === 1 ? (
                                <InputWithLabel
                                    name="receive_passport"
                                    label="Số CMND/Hộ chiếu"
                                    wrapClass="col-md-3"
                                    type="text"
                                    maxLength={255}
                                    onChangeValue={this.props.handleChange}
                                    value={receive_passport}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                            ) : (
                                <React.Fragment>
                                    <InputWithLabel
                                        name="receive_address"
                                        label="Địa chỉ nhận chuyển phát"
                                        wrapClass="col-md-4"
                                        onChangeValue={this.props.handleChange}
                                        value={receive_address}
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        name="receive_phone"
                                        label="Số điện thoại người nhân"
                                        wrapClass="col-md-2"
                                        onChangeValue={this.props.handleChange}
                                        value={receive_phone}
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </React.Fragment>
                )}
                <div className="input-group">
                    <div className={'col-md-4'}>
                        <Form.Item>
                            <Checkbox
                                disabled={true}
                                checked={resign_document}
                            >Đăng ký tài liệu bán hàng</Checkbox>
                        </Form.Item>
                    </div>
                </div>
                {resign_document ? (
                    <div className="input-group">
                        <InputWithLabel
                            name="token_admin_num"
                            label="Số lượng Token"
                            wrapClass="col-md-3"
                            onChangeValue={this.props.handleChange}
                            value={token_admin_num}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="box_num"
                            label="Số lượng vỏ hộp"
                            wrapClass="col-md-3"
                            onChangeValue={this.props.handleChange}
                            value={box_num}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="envelope_num"
                            label="Số lượng phong bì"
                            wrapClass="col-md-3"
                            onChangeValue={this.props.handleChange}
                            value={envelope_num}
                            isRequired={true}
                            isDisabled={true}
                        />
                    </div>
                ) : ""}
                <div className="input-group col-md">
                    <TextArea
                        placeholder="Ghi chú"
                        autoSize={{minRows: 4, maxRows: 10}}
                        className="form-control"
                        value={token_admin_note}
                        required={true}
                        disabled={true}
                    />
                </div>
                <div>
                    <div className="col-md-3">
                        <WrapperButtonInput>
                            <PreviewButton name={'File Yêu cầu'} history={this.props.history} onClick={() => {
                                this.onClickModalFile('File yêu cầu token admin')
                            }}/>
                        </WrapperButtonInput>
                    </div>
                </div>
                <WrapperButtonDiv>
                    <BackButton history={this.props.history} url='/yeu-cau-tk-admin-token'/>
                    {((['4', '7'].indexOf(status + '') > -1) && (status_create_account + '') === '1' && this.checkButtonCreateAccount())
                        ?
                        <ButtonLink params={this.getParamsAgency(owner_id, code)} link={'/quan-ly-nguoi-dung/them-moi'}
                                    btnName={'Tạo tài khoản đại lý'}/>
                        : ''
                    }
                    {((['2'].indexOf(status + '') > -1) && this.checkButtonsConfirm())
                        ? <DenyButton onClick={this.submitDeny}/>
                        : ''
                    }
                    {((['2'].indexOf(status + '') > -1) && this.checkButtonsConfirm())
                        ? <SubmitButton onClick={this.submitApprove} text={' Duyệt'} iconClass={'fa fa-check-circle'}/>
                        : ''
                    }
                </WrapperButtonDiv>
                <ModalView visible={this.state.visible_modal} handleCancel={this.handleModalCancel}
                           title={this.state.title_modal} value={this.state.base64} loading={this.state.loading}
                           type_file={this.state.type_file}/>
                <ModalDeny visible={this.state.visible_modal_deny} handleCancel={this.handleModalDenyCancel}
                           handleDeny={this.confirmDeny} value={this.state.text_deny} onChange={this.onChangeDataDeny}
                           error={this.state.error_text_deny}/>
            </form>
        );
    }
}

const userUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        owner: {},
        agency: {},
        contributor: {},
        base64: "",
        token_admin_num: "",
        receive_type: "",
        type_pay: "", box_num: "",
        envelope_num: "",
        receive_fullname: "",
        receive_passport: "",
        receive_address: "",
        receive_phone: "",
        status: "",
        token_admin_note: "",
        receive_date: "",
        status_create_account: "",
        id: "",
        owner_id: "",
        code: "",
        acc_type_login: "",
        acc_email: "",
        acc_full_name: "",
        acc_user_name: "",
        level_agency: "",
        acc_phone: "",
        belong_to: "",
        resign_document: false
    }),
    validationSchema: Yup.object().shape({}),

    handleSubmit: async (values, {setSubmitting}) => {
        setSubmitting(false);
    },

})(UpdateForm);

export default userUpdateForm;
