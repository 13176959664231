import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class SettingService extends FetchAPI{

    public async getSetting(){
        const result = await this.request("config",{},TypeMethodContsantEnum.GET);
        return result;
    }

    public async updateSetting(data){
        const result = await this.request("config",data,TypeMethodContsantEnum.POST);
        return result;
    }

}

export default SettingService;