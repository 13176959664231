import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class RequestLockAccount extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "admin/yeu-cau-khoa-tai-khoan/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async confirm(id) {
        const result = await this.request(
            `admin/yeu-cau-khoa-tai-khoan/confirm/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
}

export default RequestLockAccount;
