import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

const PREFIX_SERVICES = "admin/";

class CrossCheckingService extends FetchAPI{

    public async list(data) {
        const result = await this.request(
            PREFIX_SERVICES + "cross-checking",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getCrossCheckingConclude(id) {
        const result = await this.request(
            PREFIX_SERVICES + "cross-checking-conclude",
            {id},
            TypeMethodContsantEnum.GET
        )
        return result
    }
    public async updateCrossConclude(data) {
        const result = await this.request(
            PREFIX_SERVICES + "update-cross-checking-conclude",
            data,
            TypeMethodContsantEnum.POST
        )
        return result;
    }
    public async getFile(id) {
        const result = await this.request(
            PREFIX_SERVICES + "get-file",
            {id},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async approval(id) {
        const result = await this.request(
            PREFIX_SERVICES + "onApproval",
            {id},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async confirm(id) {
        const result = await this.request(
            PREFIX_SERVICES + "onConfirm",
            {id},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async crossCheckingRequest(data) {
        const result = await this.request(
            PREFIX_SERVICES + "cross-checking-request",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
}

export default CrossCheckingService;