import React, { Component } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import SearchButton from "../../common/button/SearchButton";
import InputWithLabel from "../../common/form/InputWithLabel";
import SelectBox from "../../common/form/SelectBox";
import Helmet from "react-helmet";
import MainTable from "./table/MainTable";
import { Type } from "./Enum";
import ProvinceService from "../../../services/province/ProvinceService";
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps{
  name: string;
}
interface State {
  dataSearch: any;
  province: object;
  province_arr: object;
}
const TITLE = "Danh sách gói dịch vụ";
class PackageIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      province_code: "",
      type: "",
      provider_id: "",
      type_device: "",
      text_search: ""
    },
    province: {},
    province_arr: {}
  };

  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };

  onChangeDataSearchType = (e) => {
    const dataSearch = { ...this.state.dataSearch, "type": e };
    this.setState({ dataSearch });
  };
  onChangeDataSearchTypeProvider = (e) => {
    const dataSearch = { ...this.state.dataSearch, "provider_id": e };
    this.setState({ dataSearch });
  };

  onChangeDataSearchTypeDevice = (e) => {
    const dataSearch = { ...this.state.dataSearch, "type_device": e };
    this.setState({ dataSearch });
  };
  onChangeDataSearchProvinceCode = (e) => {
    const dataSearch = { ...this.state.dataSearch, "province_code": e };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = data => {
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  };

  componentDidMount() {
    this.getProvince().then();
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch: queryString
    });
  }
  getProvince = async () => {
    const service = new ProvinceService();
    const result = await service.getProvinceList();
    if (result && result.status === 200) {
      this.setState({
        province: result.data,
        province_arr: result.data_key
      } as any);
    }
  };

  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <h4 className="card-title1">{TITLE}</h4>
        <div className="card-body">
          <form
            onSubmit={this.onSubmitSearchButton}
            className="search-form-index"
          >
            <div className="input-group">
              <InputWithLabel
                name="text_search"
                wrapClass="col-md-4 form-group nopadding-left"
                onChangeValue={this.onChangeDataSearchInput}
                value={this.state.dataSearch.text_search}
                isRequired={false}
                label={""}
                placeholder={"Mã hoặc tên gói dịch vụ"}
              />
              <SelectBox
                value={this.state.dataSearch.type}
                label=""
                onChange={this.onChangeDataSearchType}
                name="type"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={Type.TYPE}
                firstOptionLabel="Loại gói dịch vụ"
              />

              {/*<SelectBox*/}
              {/*  value={this.state.dataSearch.provider_id}*/}
              {/*  label=""*/}
              {/*  onChange={this.onChangeDataSearchTypeProvider}*/}
              {/*  name="provider_id"*/}
              {/*  wrappedClass="col-md-2 form-group nopadding-left"*/}
              {/*  options={Type.TYPENCC}*/}
              {/*  firstOptionLabel="Nhà cung cấp"*/}
              {/*/>*/}
              <SelectBox
                  value={this.state.dataSearch.type_device}
                  label=""
                  onChange={this.onChangeDataSearchTypeDevice}
                  name="type_device"
                  wrappedClass="col-md-2 form-group pl-0"
                  options={Type.TYPE_DEVICE}
                  firstOptionLabel="Thiết bị đâu cuối"
              />
              <div className="col-md-3 form-group">
                <SearchButton onClick={this.onSubmitSearchButton} />
                {/*<GoToCreatePageButton*/}
                {/*  toURL={`${this.props.match.url}/them-moi`}*/}
                {/*/>*/}
              </div>
            </div>
          </form>
          <MainTable
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(PackageIndex);

export default pageWithNavBar;
