import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import CompensationService from "../../../../services/compensation/CompensationService";
import {Card} from "antd";
import SelectDate from "../../../common/form/SelectDate";
import RequestCertificateService from "../../../../services/request-certificate/RequestCertificateService";

interface FormValues {
    customer_type: number;
    username: string;
    customer_name: string;
    uid: string;
    cert_serial: string;
    subjectdn: string;
    cert_start: string;
    cert_end: string;
    service_name: string;
    create_time: string;
    create_username: string;
    request_month: string;
    request_year:string;
    contract_status:number;
    token_serial:string;
    service_time:string;
    province:string;
}

interface OtherProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const CompensationUpdate: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [provinceOption, setProvinceOption] = useState({});
    const {customer_type, username, customer_name, uid,cert_serial,subjectdn,cert_start,cert_end,service_name,
        create_time,create_username,request_month,request_year,contract_status,token_serial, service_time, province} = props.values;
    const onFetchData = async () => {
        const service = new CompensationService();
        const compensationData = await service.find(id);
        for (let key in compensationData.data) {
            props.setFieldValue(key, compensationData.data[key]);
            if (!compensationData.data['request_month']){
                props.setFieldValue('request_month','');
            }
            if (!compensationData.data['request_year']){
                props.setFieldValue('request_year','');
            }
        }
    };
    useEffect(() => {
        onFetchData();
        getProvince();
        // eslint-disable-next-line
    }, []);
    const service = new RequestCertificateService();
    const getProvince = async  () =>{
        const province = await service.getProvince();
        const options = {};
        province.forEach(element => {
            options[element.fullname] = element.fullname;
        });
        setProvinceOption(options);

    };
    const onChangeProvince = async (e) =>{
        if(e){
            props.setFieldValue('province',e);
        }
    };
    
    const changeType = (e) => {
        props.setFieldValue("customer_type",e);
        if (Number(e) === 0) {
            props.setFieldValue('username', 'PID')
        }
        if (Number(e) === 1) {
            props.setFieldValue('username', 'SID')
        }
        if (Number(e) === 2) {
            props.setFieldValue('username', 'OID')
        }
        if (!e){
            props.setFieldValue('username','');
            props.setFieldValue('uid','');
        }
    };
    const changeStatusContract = (e) => {
        props.setFieldValue("contract_status",e)
    };
    const onChangeSelectDateValid1 = (date, dateString) => {
        props.setFieldValue('create_time',dateString);
    };
    const onChangeSelectDateValid2 = (date, dateString) => {
        props.setFieldValue('cert_start',dateString);
    };
    const onChangeSelectDateValid3 = (date, dateString) => {
        props.setFieldValue('cert_end',dateString);
    };
    const onChangeSelectDateValid4 = (date, dateString) => {
        props.setFieldValue('service_time',dateString);
    };
    const onChangeUsername = (e) => {
        props.setFieldValue('username',e.target.value);
        props.setFieldValue('uid', e.target.value.substring(3));
    };
    return (
        <div className="card">
            <form>
                <h4 className="card-title1 ml-3">Thêm mới dữ liệu cấp bù</h4>
                <Card title="Thông tin khách hàng">
                    <div className="input-group">
                        <SelectBox
                            name="customer_type"
                            options={{
                                0: "Cá nhân",
                                1: "Cá nhân thuộc tổ chức",
                                2: "Tổ chức"
                            }}
                            label="Loại khách hàng"
                            wrappedClass="col-md-3 form-group"
                            firstOptionLabel=""
                            onChange={changeType}
                            value={customer_type === 0 ? "Cá nhân" : customer_type}
                            error={props.errors.customer_type}
                            isRequired={true}
                        />
                        <InputWithLabel
                            name="username"
                            label="Tài khoản"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={onChangeUsername}
                            value={username}
                            error={props.errors.username}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="customer_name"
                            label="Tên khách hàng"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={customer_name}
                            error={props.errors.customer_name}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="uid"
                            label="Định danh"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={uid}
                            error={props.errors.uid}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="province"
                            options={provinceOption}
                            label="Tỉnh thành"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn tỉnh thành"
                            onChange={onChangeProvince}
                            value={province}
                            error={props.errors.province}
                            isRequired={true}
                        />
                    </div>
                </Card>
                <Card title="Thông tin chứng thư số">
                    <div className="input-group">
                        <InputWithLabel
                            name="cert_serial"
                            label="Cert serial"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={cert_serial}
                            error={props.errors.cert_serial}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="subjectdn"
                            label="SubjectDN"
                            wrapClass="col-md-5 form-group"
                            onChangeValue={props.handleChange}
                            value={subjectdn}
                            error={props.errors.subjectdn}
                            maxLength={255}
                        />
                        <SelectDate
                            label="Ngày bắt đầu"
                            name="cert_start"
                            wrappedClass="col-md-2"
                            onChange={onChangeSelectDateValid2}
                            value={cert_start}
                            isRequired={true}
                            placeholder={''}
                            error={props.errors.cert_start}
                        />
                        <SelectDate
                            label="Ngày kết thúc"
                            name="cert_end"
                            wrappedClass="col-md-2"
                            onChange={onChangeSelectDateValid3}
                            value={cert_end}
                            isRequired={true}
                            placeholder={''}
                            error={props.errors.cert_end}
                        />
                    </div>
                </Card>
                <Card title="Thông tin khác">
                    <div className="input-group">
                        <InputWithLabel
                            name="service_name"
                            label="Tên gói"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={service_name}
                            error={props.errors.service_name}
                            isRequired={true}
                            maxLength={255}
                        />
                        <SelectDate
                            label="Ngày tạo yêu cầu"
                            name="create_time"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectDateValid1}
                            value={create_time}
                            isRequired={true}
                            placeholder={''}
                            error={props.errors.create_time}
                        />
                        <InputWithLabel
                            name="create_username"
                            label="Người tạo yêu cầu"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={create_username}
                            error={props.errors.create_username}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="request_month"
                            label="Tháng gửi yêu cầu sinh CTS"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={request_month}
                            error={props.errors.request_month}
                            max={12}
                            maxLength={2}
                            type="number"
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="request_year"
                            label="Năm gửi yêu cầu sinh CTS"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={request_year}
                            error={props.errors.request_year}
                            maxLength={255}
                        />
                        <SelectBox
                            name="contract_status"
                            options={{
                                0: "Thiếu",
                                1: "Đủ",
                            }}
                            label="Trạng thái hồ sơ"
                            wrappedClass="col-md-3 form-group"
                            firstOptionLabel=""
                            onChange={changeStatusContract}
                            value={contract_status === 0 ? "Thiếu" : contract_status}
                            error={props.errors.contract_status}
                            isRequired={true}
                        />
                        <InputWithLabel
                            name="token_serial"
                            label="Mã token"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={token_serial}
                            error={props.errors.token_serial}
                            isRequired={true}
                            maxLength={255}
                        />
                        <SelectDate
                            label="Thời hạn sử dụng dịch vụ (đến ngày)"
                            name="service_time"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectDateValid4}
                            value={service_time}
                            isRequired={true}
                            placeholder={''}
                            error={props.errors.service_time}
                        />
                    </div>
                </Card>
                <div className="input-group d-flex justify-content-center p-5">
                    <BackButton history={props.history} url="/du-lieu-cap-bu"/>
                    <SubmitButton onClick={props.handleSubmit}/>
                </div>
            </form>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        customer_type: 0,
        username: '',
        customer_name: '',
        uid: '',
        cert_serial: '',
        subjectdn: '',
        cert_start: '',
        cert_end: '',
        service_name: '',
        create_time: '',
        create_username: '',
        request_month: '',
        request_year:'',
        contract_status:0,
        token_serial:'',
        service_time:'',
        province:'',
    }),
    validationSchema: Yup.object().shape({
        customer_type: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        username: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        customer_name: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        uid: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        cert_serial: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        cert_start: Yup.date().required("Trường dữ liệu không thể bỏ trống."),
        cert_end: Yup.date().required("Trường dữ liệu không thể bỏ trống."),
        service_name: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        create_time: Yup.date().required("Trường dữ liệu không thể bỏ trống."),
        create_username: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        request_month: Yup.string().max(12, 'Trường dữ liệu trong khoảng 1-12').min(1, 'Trường dữ liệu trong khoảng 1-12'),
        request_year: Yup.string().max(9999, 'Trường dữ liệu trong khoảng 1-9999').min(1, 'Trường dữ liệu trong khoảng 1-9999'),
        contract_status: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        token_serial: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        service_time: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
        province: Yup.string().required("Trường dữ liệu không thể bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CompensationService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.push(`/du-lieu-cap-bu`);
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(CompensationUpdate);

export default withNavbar()(RenderCreateForm);
