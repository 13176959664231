import { renderToString } from "react-dom/server";
import React from "react";

import { ClimbingBoxLoader } from "react-spinners";

export const loading = {
  runLoadingBlockUI,
  stopRunLoading
};

const override = `
    display: block;
    margin: 0 auto;
    border-color: #36D7B7;
    color: #36D7B7;`;

function runLoadingBlockUI(){
  ($ as any).blockUI({
    css: {
      border: "none",
      padding: "15px",
      backgroundColor: 0,
      "-webkit-border-radius": "10px",
      "-moz-border-radius": "10px",
      opacity: 0.5,
      color: "#fff"
    },
    message: renderToHtmlCode()
  });
};

function stopRunLoading() {
  ($ as any).unblockUI();
};

const renderToHtmlCode = () => {
  return renderToString(
    <ClimbingBoxLoader
      css={override}
      sizeUnit={"px"}
      size={30}
      color={"#ffffff"}
      loading={true}
    />
    // <ScaleLoader
    //   css={override}
    //   height={40}
    //   width={4}
    //   radius={2}
    //   margin={'2px'}
    //   color={"#ffffff"}
    //   loading={true}
    // />
  );
};
