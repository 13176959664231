import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class CompensationServive extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "admin/cap-bu",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async find(id) {
        const result = await this.request(
            `admin/cap-bu/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async store(data) {
        const result = await this.request(
            "admin/cap-bu/store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async update(data) {
        const result = await this.request(
            "admin/cap-bu/update",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
}

export default CompensationServive;
