import React, {useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON, reloadPage} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import {onDeleteAlert, onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import CateContractService from "../../../services/category-contract/CateContractService";
import SelectBox from "../../common/form/SelectBox";

interface Props extends RouteComponentProps {
}

const TITLE = "Danh mục mẫu tài liệu";

const ContractCategory: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({code: "", type: ""});

    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchType = (e) => {
        setDataSearch({...dataSearch, "type": e});
    };

    const fetchDataFunction = async () => {
        const service = new CateContractService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            return await service.list({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const onClickDeleteButton = id => {
        onDeleteAlert(async willDelete => {
            if (willDelete) {
                const service = new CateContractService();
                const result = await service.delete(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa bản ghi tài sản thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
            }
        });
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={() =>
                    props.history.push(`/danh-muc-hop-dong/cap-nhat/${record.id}`)
                }
                onClickDelete={() => onClickDeleteButton(record.id)}
                isDisplayUpdateButton = {true}
            />
        );
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã tài liệu"}
                        />
                        <SelectBox
                            name="type"
                            options={{
                                1: "Hợp đồng đại lý",
                                2: "Hợp đồng dịch vụ",
                                3: "Khác"
                            }}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Chọn loại tài liệu"
                            onChange={onChangeDataSearchType}
                            value={dataSearch.type}
                        />
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Tên", dataIndex: "name"},
                        {title: "Mã", dataIndex: "code"},
                        {
                            title: "Loại tài liệu",
                            dataIndex: "type",
                            render: text => {
                                if(Number(text) === 1){
                                    return <p>Hợp đồng đại lý</p>
                                }
                                if(Number(text) === 2){
                                    return <p>Hợp đồng dịch vụ</p>
                                }
                                if(Number(text) === 3){
                                    return <p>Khác</p>
                                }
                            }
                        },
                        {
                            title: "Loại đại lý",
                            dataIndex: "type_agency",
                            render: text =>
                                text === 1 ? "Đại lý" : "Cộng tác viên"
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },

                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(ContractCategory);
