import FetchAPI from '../../helpers/FetchAPI';
import {TypeMethodContsantEnum} from '../../helpers/constant/MethodAPIConstant';

class HomeService extends FetchAPI{
    public async getData() {
        return await this.request("admin/get-data-dashboard", {}, TypeMethodContsantEnum.GET)
    }
    public async getAmChart() {
        return await this.request("admin/get-data-amchart", {}, TypeMethodContsantEnum.GET);
    }
    public async getDataCard() {
        return await this.request("admin/get-data-card", {}, TypeMethodContsantEnum.GET);
    }
}
export default HomeService;