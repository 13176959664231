import React from "react";

interface Props {
  onClick: () => void;
  tittle? : string;
  icon? : string;
}

export default function ActionButtonView(props: Props) {
  return (
    <button
      className="pointer text-primary ml-1 mr-1"
      title={(props.tittle) ? props.tittle : "Xem chi tiết"}
      onClick={props.onClick}
    >
      <i className={(props.icon) ? props.icon : "fa fa-eye fa-lg"} />
    </button>
  );
}
