import React, {Component} from "react";
import LabelInput from "./label/LabelInput";
import {TreeSelect} from "antd";
const { SHOW_PARENT } = TreeSelect;
interface Props {
    options: any;
    label?: string;
    name: string;
    wrappedClass: string;
    isRequired?: boolean;
    defaultValue: any;
    onChange?: any;
    error?: string;
    isDisabled?: boolean;
}

interface State {
}

export default class CheckBoxGroup extends Component<Props, State> {

    state ={};
    onChange = value => {
        this.setState({ value });
        this.props.onChange(value);
    };
    render() {
        const tProps = {
            treeData: this.props.options ? this.props.options : [],
            value: this.props.defaultValue,
            onChange: this.onChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            style: {
                width: '100%',
            },
        };
            return (
                <div className={this.props.wrappedClass}>
                    {(this.props.label) ?
                        <LabelInput
                            nameFor={this.props.name}
                            label={this.props.label}
                            isRequired={this.props.isRequired}

                        />
                        : ''}
                    <div>
                        <TreeSelect {...tProps} />
                    </div>
                </div>
            );
        }
    }
