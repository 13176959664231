export enum StatusEnum {
  ACTIVE = "1",
  STOP = "2",
  DEACTIVE = "3"
}

export class Type {
  static readonly TYPE = {
    1: "Mới",
    2: "Gia hạn",
    3: "Chuyển đổi"
  };
  static readonly TYPENCC = {
    1: "FastCA",
    2: "Newtel"
  };
  static readonly TYPE_DEVICE = {
    1: "Token",
    2: "HSM",
    3: "FastCA RS",
  };
  static readonly VALID = {
    1: "Hoạt động",
    2: "Ngừng hoạt động",
  };
  static readonly badgeClass = {
    1: "geekblue",
    2: "green",
    3: "orange"
  };
  static readonly badgeClassStatus = {
    1: "green",
    2: "red"
  };
  static readonly Province = {101: "Hà Nội", 103: "Hải Phòng", 105: "Hà Tây cũ (hết h.lực)", 107: "Hải Dương", 109: "Hưng Yên", 111: "Hà Nam", 113: "Nam Định", 115: "Thái Bình", 117: "Ninh Bình", 201: "Hà Giang", 203: "Cao Bằng", 205: "Lào Cai", 207: "Bắc Cạn", 209: "Lạng Sơn", 211: "Tuyên Quang", 213: "Yên Bái", 215: "Thái Nguyên", 217: "Phú Thọ", 219: "Vĩnh Phúc", 221: "Bắc Giang", 223: "Bắc Ninh", 225: "Quảng Ninh", 301: "Tỉnh Điện Biên", 302: "Tỉnh Lai Châu", 303: "Sơn La", 305: "Hòa Bình", 401: "Thanh Hoá", 403: "Nghệ An", 405: "Hà Tĩnh", 407: "Quảng Bình", 409: "Quảng Trị", 411: "Thừa Thiên - Huế", 501: "Đà Nẵng", 503: "Quảng Nam", 505: "Quảng Ngãi", 507: "Bình Định", 509: "Phú Yên", 511: "Khánh Hòa", 601: "Kon Tum", 603: "Gia Lai", 605: "Đắc Lắc", 606: "Tỉnh Đắk Nông", 701: "TP Hồ Chí Minh", 703: "Lâm Đồng", 705: "Ninh Thuận", 707: "Bình Phước", 709: "Tây Ninh", 711: "Bình Dương", 713: "Đồng Nai", 715: "Bình Thuận", 717: "Bà Rịa - Vũng Tàu", 801: "Long An", 803: "Đồng Tháp", 805: "An Giang", 807: "Tiền Giang", 809: "Vĩnh Long", 811: "Bến Tre", 813: "Kiên Giang", 815: "Cần Thơ", 816: "Tỉnh Hậu Giang", 817: "Trà Vinh", 819: "Sóc Trăng", 821: "Bạc Liêu", 823: "Cà Mau"};

}