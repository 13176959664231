export enum StatusEnum {
  ACTIVE = "1",
  STOP = "2",
  DEACTIVE = "3",
}

export class Type {
  static readonly TYPE = {
    1: "Loại 1",
    2: "Loại 2",
    3: "Loại 3",
  };
  static readonly badgeClass = {
    4: "badge-primary",
    5: "badge-danger",
    6: "badge-success",
    7: "badge-warning",
    8: "badge-danger",
  };
  static readonly STATUS = {
    4: "Hỗ trợ đã duyệt",
    5: "Giám đốc từ chối",
    6: "Hoạt động",
    7: "Đang xử lý dừng hợp tác",
    8: "Đã dừng",
  };
}

export const PERSONAL_SCALE_OPTIONS = {
  1: "Dưới 10 nhân sự",
  2: "Trên 10 nhân sự",
};

export const SUPPLY_CAPACITY_OPTIONS = {
  1: "Dưới 50 thuê bao",
  2: "Trên 50 thuê bao",
};

export const CONTRACT_TYPE_OPTIONS = {
  2: "Hợp đồng điện tử",
  1: "Hợp đồng giấy",
};

export const STATUS_LABEL_CLASS = {
  1: {
    label: "Nháp",
    class: "badge-secondary",
  },
  2: {
    label: "Chờ duyệt",
    class: "badge-warning",
  },
  3: {
    label: "Từ chối",
    class: "",
  },
  4: {
    label: "HTKD Đã duyệt",
    class: "badge-primary",
  },
  5: {
    label: "Giám đốc từ chối",
    class: "",
  },
  6: {
    label: "Hoạt động",
    class: "badge-success",
  },
  7: {
    label: "Đang xử lý dừng hợp tác",
    class: "badge-warning",
  },
  8: {
    label: "Đã dừng",
    class: "badge-danger",
  },
};

export enum AgencyEnum {
  STATUS_WAITING_APPROVE = 2,
  STATUS_DRAFT = 1,
  DIGITAL_CONTRACT = 2,
}

export const AgencyRules = {
  code: [
    {
      required: true,
      message: "Mã đại lý không thể bỏ trống!",
    },
  ],
  tax_code: [
    {
      required: true,
      message: "Mã số thuế không thể bỏ trống!",
    },
  ],
  deputy: [{}],
};

export const AGENCY_CONTACT = {
  1: "Ban lãnh đạo",
  2: "Kinh doanh",
  3: "Kỹ thuật nghiệp vụ",
  4: "Hỗ trợ khách hàng",
  5: "Kế toán",
};

export enum AGENCY_CONACT_TYPE_ENUM {
  DIRECTORS = 1,
  BUSINESS = 2,
  SKILL_BUSINESS = 3,
  CUSTOMER_SUPPORT = 4,
  ACCOUNTANT = 5,
}
