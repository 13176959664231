import FetchAPI from "../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../helpers/constant/MethodAPIConstant";

class WhTokenExportService extends FetchAPI {
  public async getCode(date) {
    const result = await this.request(
      `wh/export/code/${date}`,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async store(data) {
    const result = await this.request(
      "wh/export/store",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

  public async storeAsset(data) {
    const result = await this.request(
      "wh/export/storeAsset",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

    public async storeDoc(data) {
        const result = await this.request(
            "wh/export/storeDoc",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

  public async list(data: object) {
    const path = "/wh/export/index";
    const result = await this.request(path, data, TypeMethodContsantEnum.GET);
    return result;
  }

  public async getDetail(id) {
    const result = await this.request(
      "wh/export/show/"+id,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }
  
  public async getExportDoc(id) {
    const result = await this.request(
      "wh/export/getExportDoc/"+id,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }
  
  public async getListToken(id, typeToken) {
    const result = await this.request(
      `wh/export/getListToken/${id}/${typeToken}`,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }
}

export default WhTokenExportService;
