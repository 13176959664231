export const actions={
    1 : "Thêm",
    2 : 'Sửa',
    3 : 'Xóa',
    4 : 'Xem',
    11 : 'Xem danh sách',
    5 : 'Đăng nhập',
    10 : 'Đăng xuất',
    6 : 'Xuất Excel',
    7 : 'Duyệt',
    8 : 'Ký',
    9 : 'Từ chối',
    12 : 'Khóa',
    25 : 'Mở Khóa',
    21 : 'Xem tất cả',
    22 : 'Xem chi tiết',
    23 : 'Duyệt và ký',
    24 : 'Xuất kho',
    26 : 'Import',
    27 : 'Cấp quyền',
    28 : 'Gửi mail',
    29 : 'Yêu cầu điều chỉnh thông tin',
    30 : 'Tải xuống file Excel',
    31 : 'Reset mật khẩu',
    32:"Gửi yêu cầu",
}
export const categories={
    1 : 'Đăng nhập',
    // Quản lý hợp tác
    36 : 'Quản lý đại lý',
    37 : 'Quản lý cộng tác viên',
    38 : 'Yêu cầu tạo tài khoản',
    39 : 'Dừng hợp tác',
    40 : 'Yêu cầu thay đổi thông tin',
    41 : 'Biên bản thanh lý',
    
    // Quản lý kho
    42 : 'Kho token',
    43 : 'Kho tài liệu bán hàng',
    44 : 'Kho tài sản',
    45 : 'Quản lý xuất kho',
    46 : 'Danh sách token chi tiết',

    // Nghiệp vụ
    47 : 'Yêu cầu khóa tài khoản',
    48 : 'Quản lí Token',
    49 : 'Cấp quyền cập nhật USB Token',
    50 : 'Yêu cầu CTS',
    51 : 'Phê duyệt UC',
    52 : 'Thông tin CTS',
    53 : 'Danh sách CTS',
    54 : 'Yêu cầu thay đổi thông tin CTS',
    55 : 'Dữ liệu cấp bù',
    56 : 'Yêu cầu cấp bù',

   // Hỗ trợ khách hàng 
    57 : 'Yêu cầu đào tạo',
    58 : 'Yêu cầu tài liệu bán hàng',

    // Gói danh mục
    59 : 'Gói dịch vụ',
    60 : 'Văn bản',
    61 : 'Phần mềm',
    62 : 'Nhà cung cấp',
    63 : 'Version Token',
    64 : 'Tài sản',
    65 : 'Danh mục chiết khấu',
    66 : 'Mẫu tài liệu',
    67 : 'Mẫu mail',
    68 : 'Tỉnh thành',
    69 : 'Quận huyện',

//Danh sách khách hàng
    70 : 'Danh sách khách hàng',

//Quản lý hồ sơ
    71 : 'Quản lý hợp đồng',
    72 : 'Quản lý hồ sơ pháp lý',
    //Đối soát
    73 : 'Danh sách đối soát',
    74 : 'Danh sách yêu cầu',
    75 : 'Danh sách cấp bù',
//Hệ thống
    76 : 'Quản lý người dùng',
    77 : 'Quản lý vai trò',
    78 : 'Cấu hình hệ thống',
    80 : 'Cấu hình CTS',
    79 : 'Tool cho MSP',
    81 : 'Quản lý log',
   82:"Thay đổi người quản lý",
    83:"Yêu cầu CTS cá nhân",
    84:"Yêu cầu CTS tổ chức",
    85:"Yêu cầu cấp lại & bảo hành",
    86:'Quản lý hồ sơ cấp lại & bảo hành'
}