import CrossChecking from "../../pages/crosscheking/CrossChecking"
import CrossCheckingRequest from "../../pages/crosscheking/CrossCheckingRequest"
import CrossCheckingCompensationRequest from "../../pages/crosscheking/CrossCheckingCompensationRequest"


const CROSS_CHECKING_ROUTE = [
    {
        component: CrossChecking,
        link: "/danh-sach-doi-soat-all",
        isExact: false,
        permission: 'cross-checking-list'
    },
    {
        component: CrossCheckingRequest,
        link: "/danh-sach-doi-soat-yeu-cau",
        isExact: false,
        permission: 'cross-checking-list'
    },
    {
        component: CrossCheckingCompensationRequest,
        link: "/danh-sach-doi-soat-cap-bu",
        isExact: false,
        permission: 'cross-checking-list'
    },
];
export default CROSS_CHECKING_ROUTE;

