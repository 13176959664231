import RequestChangeInfo from "../../pages/request-change-info/RequestChangeInfo";
import RequestChangeInfoViewContributor from "../../pages/request-change-info/view/RequestChangeInfoViewContributor";
import RequestChangeInfoViewAgency from "../../pages/request-change-info/view/RequestChangeInfoViewAgency";

const CHANGE_INFO_ROUTES = [
    {
        component: RequestChangeInfo,
        link: "/yeu-cau-thay-doi-thong-tin",
        isExact: false
    },
    {
        component: RequestChangeInfoViewContributor,
        link: "/yeu-cau-thay-doi-thong-tin/chi-tiet/cong-tac-vien/:id",
        isExact: false
    },
    {
        component: RequestChangeInfoViewAgency,
        link: "/yeu-cau-thay-doi-thong-tin/chi-tiet/dai-ly/:id",
        isExact: false
    },
];

export default CHANGE_INFO_ROUTES;

