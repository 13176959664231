import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {History} from "history";
import {TypeWhToken} from "../Enum";
import {Input} from "antd";
import SelectDate from "../../../common/form/SelectDate";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import TableAddRowDocument from "../../../common/table/TableAddRowDocument";
import SelectBox from "../../../common/form/SelectBox";
import WhTokenExportService from "./../../../../services/whTokenExport/WhTokenExportService";
import {onSuccessAction} from "../../../../helpers/SwalCommon";
import {onFailAction} from "./../../../../helpers/SwalCommon";
import WhDocumentService from "../../../../services/WhDocument/WhDocumentService";
import {RouteComponentProps} from "react-router";
import UserService from "../../../../services/user/UserService";
const {TextArea} = Input;

interface FormValues {
    code: string;
    note: string;
    exporter: string;
    exporter_pos: string;
    type: string;
    location: string;
    receiver: string;
    export_date: string;
    created_date: string;
    owner_id: string;
    belong_to: string;
    dataDoc: object;
}

interface OtherProps extends RouteComponentProps<{
    id: string;
}> {
}

interface OtherProps {
    history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const WhTokenCreate: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [agency, setAgency] = useState({});
    const [dataDocState, setDataDocState] = useState({});
    const [docCateList, setDocCateList] = useState({});
    //const [totalMoney, setTotalMoney] = useState({});
    const {
        code,
        note,
        exporter,
        location,
        receiver,
        export_date,
        owner_id,
        belong_to,
    } = props.values;

    const fetchWhAssetOptionDoc = async () => {
        const service = new WhDocumentService();
        const result = await service.all();
        const options = {};
        if (result && result.data){
            result.data.forEach(element => {
                options[element.id] = element.name;
            });
            setDocCateList(options);
        }
    };

    const fetchData = async () => {
        const service = new WhTokenExportService();
        const result = await service.getExportDoc(id);
        let type = 7;
        if (result.data.belong_to === 2) type = 8;
        getAgency(type);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        let key = 0;
        let detail = result.data.wh_export_detail;
        let allTotal = 0;
        const dataDocTemp = [] as any;
        detail.forEach(function (value) {
            let total = Number(value.price) * Number(value.total_document);
            const newData = {...dataDocState,
                "key":key,
                "doc_asset_id": value.wh_document_id,
                "doc_amount": value.total_document,
                "price": value.price,
                "doc_unit": value.unit,
                "total": total,
            };
            key++;
            allTotal = allTotal + total;
            dataDocTemp.push(newData);
            setDataDocState(dataDocTemp);
        });
        //setTotalMoney(allTotal);
    };
    useEffect(() => {
        fetchWhAssetOptionDoc();
        fetchData();
        // eslint-disable-next-line
    }, []);
    const getAgency = async (type) => {
        const userService = new UserService();
        const lst = await userService.getAgencyContributor(type);
        if (lst) {
            let agency = {};
            lst.data.map((value) => (agency[value.id] = value.fullname));
            setAgency(agency);
        }
    };
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Xuất kho tài liệu</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            label="Số hóa đơn"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectBox
                            options={TypeWhToken.TYPEAGENCY}
                            label="Loại người dùng"
                            name="belong_to"
                            wrappedClass="col-md-3"
                            value={belong_to}
                            firstOptionLabel="Lựa chọn"
                            error={props.errors.belong_to}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBoxAgency
                            options={agency}
                            label="Đại lý/cộng tác viên"
                            name="owner_id"
                            wrappedClass="col-md-3"
                            value={owner_id}
                            firstOptionLabel="Chọn đại lý/cộng tác viên"
                            error={props.errors.owner_id}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="exporter"
                            label="Người xuất kho"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={exporter}
                            error={props.errors.exporter}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="receiver"
                            label="Người nhận hàng"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={receiver}
                            error={props.errors.receiver}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="location"
                            label="Tại kho"
                            wrapClass="col-md-6"
                            onChangeValue={props.handleChange}
                            value={location}
                            error={props.errors.location}
                            isRequired={false}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectDate
                            label="Ngày xuất kho"
                            name="export_date"
                            wrappedClass="col-md-3"
                            value={export_date}
                            error={props.errors.export_date}
                            isRequired={true}
                            placeholder={"yyyy-mm-dd"}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <div className="col-md-12">
                            <label>Về việc</label>
                            <TextArea
                                name="note"
                                placeholder="Ghi chú"
                                autoSize={{minRows: 4, maxRows: 10}}
                                className="form-control"
                                required={false}
                                value={note}
                                onChange={props.handleChange}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="input-group col-md-12 m-t-20">
                        <TableAddRowDocument
                            assetList={docCateList}
                            data={dataDocState}
                            changeData={()=>{}}
                            onDelete={""}
                            keyDelete={""}
                            errors={props.errors}
                            tittle={"Danh mục tài liệu"}
                            isDisable={true}
                            isHidden={true}
                        />
                    </div>
                    {/* <div className="input-group col-md-12 m-t-20 ">
                        <div className="col-md-10"/>
                        <div className="col-md-2 pull-right">
                            <h6>Tổng tiền(VNĐ) : {Number(totalMoney)}</h6>
                        </div>
                    </div> */}
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton history={props.history}/>
                        {/* <SubmitButton onClick={props.handleSubmit}/> */}
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        note: "",
        exporter: "",
        exporter_pos: "",
        type: "",
        location: "",
        receiver: "",
        export_date: "",
        created_date: "",
        owner_id: "",
        belong_to: "",
        dataDoc:{}
    }),
    validationSchema: Yup.object().shape({
        belong_to: Yup.string().required("Phải lựa chọn loại người dùng."),
        owner_id: Yup.string().required(
            "Đại lý/ cộng tác viên không thể bỏ trống."
        ),
        exporter: Yup.string().required("Người xuất kho không thể bỏ trống."),
        receiver: Yup.string().required("Người nhận không thể bỏ trống."),
        export_date: Yup.date().required("Ngày xuất kho không được trống"),
        created_date: Yup.date().required("Ngày lập phiếu không được trống")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new WhTokenExportService();
        const result = await service.storeAsset(values);
        if (result && result.status === 200) {
            onSuccessAction("Xuất kho tài sản thành công!", function () {
                props.history.goBack();
            });
        } else if (result && result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(WhTokenCreate);

export default withNavbar()(RenderCreateForm);
