import React, {useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString,formatDateTimeAll,queryStringToJSON} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";

import DataWhService from "../../../services/data-wh/DataWhService";
import { Tag } from "antd";
import UserService from "../../../services/user/UserService";

interface Props extends RouteComponentProps{

}
const TITLE = "Danh sách Token Chi tiết";

const ListTokenDetail: React.FC<Props>  = props => {
    const [dataSearch, setDataSearch] = useState({code_export: "",token_code:"",status:"", belong_to: "",owner_id:""});
    const [agency, setAgency] = useState({})
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, 'status': e})
    }
    const getAgency = async belong_to => {
      const userService = new UserService();
      const lst = await userService.getOwnerByBelongTo(belong_to);
      if (lst) {
        let agency = {};
        lst.data.map(value => (agency[value.id] = value.fullname));
        setAgency(agency)
      }
    }
    const onChangeDataSearchBelongTo = (e) => {
        if (!e) {
            setAgency({})
          }
          if(Number(e)===1) {
              getAgency(1)
          }
          if(Number(e)===2){
              getAgency(2)
          }
          if(Number(e)===3){
            getAgency(3)
        }
        setDataSearch({...dataSearch, 'belong_to': e})
    }
    const onChangeDataSearchOwnerId = (e) => {
        setDataSearch({...dataSearch, "owner_id": e})
    }
    const fetchDataFunction = async () => {
        const service = new DataWhService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.listTokenDetail({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    }
    const onRenderActionColumn = (text, record, index) => {
        if (record.status ===2){
            return (
                <TableActionButton
                    onClickView={() => props.history.push(`/danh-sach-xuat-kho/xem-chi-tiet-token/${record.wh_export_detail.wh_export.id}`)}
                />
            );
        }
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="token_code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.token_code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã Token"}
                        />
                        <InputWithLabel
                            name="code_export"
                            wrapClass="col-md-2 form-group"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code_export}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã xuất kho"}
                        />
                        

                        <SelectBox options={{
                            1: "Đại lý",
                            2: "Cộng tác viên"
                        }}
                            label=""
                            name="belong_to"
                            wrappedClass="col-md-2"
                            firstOptionLabel="Loại người dùng"
                            onChange={onChangeDataSearchBelongTo}
                            value={dataSearch.belong_to}/>

                        <SelectBox
                              name="owner_id"
                              wrappedClass="col-md-2 form-group "
                              label=""
                              options={agency}
                              firstOptionLabel="Tên đại lý/CTV/NVKD"
                              onChange={onChangeDataSearchOwnerId}
                              value={dataSearch.owner_id}
                        />
                        <SelectBox options={{
                            1: "Chưa xuất",
                            2: "Đã xuất"
                        }}
                            label=""
                            name="status"
                            wrappedClass="col-md-1 nopadding-left"
                            firstOptionLabel="Trạng thái"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.status}/>
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Mã Token",
                            dataIndex: "code",
                        },
                        {
                            title: "Mã xuất kho",
                            dataIndex: "",
                            render: e => {
                                if(e.status===2)
                                    return e.wh_export_detail.wh_export.code;
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: e => {
                                if (e) {
                                    if(e.status===1)
                                        return (<Tag color="orange">Chưa xuất</Tag>);
                                    if(e.status===2)
                                        return (<Tag color="green">Đã xuất</Tag>);
                                }
                            }
                        },
                        {
                            title: "Loại người dùng",
                            dataIndex: "",
                            render: e => {
                                if (e.status===2) {
                                    if(e.wh_export_detail.wh_export.belong_to === 1)
                                        return 'Đại lý';
                                    if(e.wh_export_detail.wh_export.belong_to=== 2)
                                        return 'CTV';
                                }
                            }
                        },
                        {
                            title: "Tên đại lý/CTV",
                            dataIndex: "",
                            render: e => {
                                if (e.status ===2){
                                    if (e.wh_export_detail.wh_export.belong_to===1){
                                        if(e.wh_export_detail.wh_export.agency){
                                            return e.wh_export_detail.wh_export.agency.fullname;
                                        }
                                    }
                                    if (e.wh_export_detail.wh_export.belong_to===2){
                                        if(e.wh_export_detail.wh_export.contributor){
                                            return e.wh_export_detail.wh_export.contributor.fullname;
                                        }
                                    }
                                }
                            }
                        },
                        {
                            title: "Ngày xuất/Ngày nhập kho",
                            dataIndex: "",
                            render: e => {
                                if (e){
                                    if (e.status ===1)
                                        return formatDateTimeAll(e.wh_token.receive_date);
                                    if (e.status ===2)
                                        return formatDateTimeAll(e.wh_export_detail.wh_export.export_date);
                                }
                            }
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }

                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};
export default withNavbar()(ListTokenDetail);
