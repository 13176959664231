import React, { Component } from "react";
import Table from "../../common/table/Table";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON,
  reloadPage
} from "../../../helpers/NewCaHelper";
import { History, Location } from "history";
import { match } from "react-router";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import SearchButton from "../../common/button/SearchButton";
import InputWithLabel from "../../common/form/InputWithLabel";
import Helmet from "react-helmet";
import { onDeleteAlert, onSuccessAction } from "../../common/swal/SwalCommon";
import FieldService from "../../../services/field/FieldService";

interface Props {
  history: History;
  location: Location;
  match: match;
  name:string;
}
interface State {
  dataSearch: any;
}

const TITLE = "Lĩnh vực kinh doanh";
class PageIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      type: "",
      text_search: ""
    }
  };

   fetchDataFunction = async (dataSearch = {})=>{
    const service = new FieldService();
    const result = await service.getFields(
        {...dataSearch},
      );

    if(result && result.status===200){
      return result
    }
    return null;
  }

  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = (data) =>{
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  }

  onClickDeleteButton = (data) =>{
    const self = this;
    onDeleteAlert(async function(willDelete){
        if(willDelete){
          const service = new FieldService();
          const result = await service.deleteFieldById(data.id);
          if(result && result.status === 200){
            onSuccessAction('Xóa bản ghi thành công!',reloadPage(self.props.location,self.props.history));
          }
        }
    });
  }

  componentDidMount() {
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch:queryString
    })
  }

  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <h4 className="card-title1">{TITLE}</h4>
        <div className="card-body">
          <form onSubmit={this.onSubmitSearchButton} className='search-form-index'>
            <div className="input-group">
              <InputWithLabel
                name="text_search"
                wrapClass="col-md-4 form-group nopadding-left"
                onChangeValue={this.onChangeDataSearchInput}
                value={this.state.dataSearch.text_search}
                isRequired={false}
                label={""}
                placeholder={"Mã hoặc tên lĩnh vực kinh doanh"}
              />
              <div className="col-md-3 form-group nopadding-left">
                <SearchButton onClick={this.onSubmitSearchButton} />
                <GoToCreatePageButton
                  toURL={`${this.props.match.url}/them-moi`}
                />
              </div>
            </div>
          </form>
          <Table
            columns={[
              {
                headers: "Mã lĩnh vực",
                accessor: "code"
              },
              {
                headers: "Tên lĩnh vực",
                accessor: "name"
              },
              {
                headers: "Thời gian tạo",
                accessor: "created_at"
              }
            ]}
            pagination={true}
            addActionRow={true}
            defaultTdClass="text-center"
            defaultThClass="text-center"
            onClickDelete={this.onClickDeleteButton}
            onClickEdit={this.onClickUpdateButton}
            location={this.props.location}
            fetchDataFunction={this.fetchDataFunction}
            history={this.props.history}
            match={this.props.match}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(PageIndex);

export default pageWithNavBar;
