import Home from "../pages/home/Home";
import UserIndex from "../pages/user/User";
import Logout from "../pages/login/Logout";
import ChangePassword from "../pages/changepassword/ChangePass";
import CreateUser from "../pages/user/create/CreateUser";
import UpdateUser from "../pages/user/update/UpdateUser";
import RoleIndex from "../pages/role/Role";
import CreateRole from "../pages/role/create/RoleCreate";
import UpdateRole from "../pages/role/update/RoleUpdate";
import Setting from "../pages/setting/Setting";
import Field from "../pages/field/Field";
import CreateField from "../pages/field/create/CreateField";
import UpdateField from "../pages/field/update/UpdateField";
import District from "../pages/district/District";
import CreateDistrict from "../pages/district/create/CreateDistrict";
import UpdateDistrict from "../pages/district/update/UpdateDistrict";
import TaxDepartment from "../pages/taxdepartment/TaxDepartment";
import CreateTaxDepartment from "../pages/taxdepartment/create/CreateTaxDepartment";
import UpdateTaxDepartment from "../pages/taxdepartment/update/UpdateTaxDepartment";
import Package from "../pages/package/Package";
import CreatePackage from "../pages/package/create/CreatePackage";
import UpdatePackage from "../pages/package/update/UpdatePackage";
import Agency from "../pages/agency/Agency";
import UpdateAgency from "../pages/agency/update/UpdateAgency";
import Contributor from "../pages/contributor/Contributor";
import UpdateContributor from "../pages/contributor/update/UpdateContributor";
import Document from "../pages/document/Document";
import CreateDocument from "../pages/document/create/CreateDocument";
import UpdateDocument from "../pages/document/update/UpdateDocument";
import Software from "../pages/software/Software";
import CreateSoftware from "../pages/software/create/CreateSoftware";
import UpdateSoftware from "../pages/software/update/UpdateSoftware";
import RequestTkAdminToken from "../pages/requestTkAdmin/RequestTkAdmin";
import RequestTkAdminTokenUpdate from "../pages/requestTkAdmin/update/RequestTkAdminTokenUpdate";
import RequestTokenCts from "../pages/requestTokenCts/RequestTokenCts";
import RequestTokenCtsUpdate from "../pages/requestTokenCts/update/RequestTokenCtsUpdate";
import ProviderCategory from "../pages/provider-category/ProviderCategory";
import ProviderCategoryCreate from "../pages/provider-category/create/ProviderCategoryCreate";
import ProviderCategoryUpdate from "../pages/provider-category/update/ProviderCategoryUpdate";
import Province from "../pages/province/Province";
import CreateProvince from "../pages/province/create/CreateProvince";
import UpdateProvince from "../pages/province/update/UpdateProvince";
import VersionToken from "../pages/version-token/VersionToken";
import VersionTokenCreate from "../pages/version-token/create/VersionTokenCreate";
import VersionTokenUpdate from "../pages/version-token/update/VersionTokenUpdate";
import AssetCategory from "../pages/asset-category/AssetCategory";
import AssetCategoryCreate from "../pages/asset-category/create/AssetCategoryCreate";
import AssetCategoryUpdate from "../pages/asset-category/update/AssetCategoryUpdate";
import Service from "../pages/service/Service";
import CreateService from "../pages/service/create/CreateService";
import UpdateService from "../pages/service/update/UpdateService";

import WhAsset from "../pages/whAsset/WhAsset";
import WhAssetCreate from "../pages/whAsset/create/WhAssetCreate";
import WhAssetUpdate from "../pages/whAsset/update/WhAssetUpdate";
import WhDocument from "../pages/whDocument/WhDocument";
import WhDocumentCreate from "../pages/whDocument/create/WhDocumentCreate";
import WhDocumentUpdate from "../pages/whDocument/update/WhDocumentUpdate";
import ViewWhDocument from "../pages/whDocument/view/ViewWhDocument";

import WhToken from "../pages/whToken/WhToken";
import ExportWhTokenList from "../pages/whToken/ExportWhTokenList";
import CreateWhToken from "../pages/whToken/create/WhTokenCreate";
import UpdateWhToken from "../pages/whToken/update/WhTokenUpdate";
import ViewWhToken from "../pages/whToken/view/ViewWhToken";
import ViewWhAsset from "../pages/whAsset/view/ViewWhAsset";
import ExportWhToken from "../pages/whToken/export/WhTokenExport";
import ExportWhAsset from "../pages/whAsset/export/WhAssetExport";
import ContractCategory from "../pages/contract-category/ContractCategory";
import ContractCategoryCreate from "../pages/contract-category/create/ContractCategoryCreate";
import ContractCategoryUpdate from "../pages/contract-category/update/ContractCategoryUpdate";
import Signature from "../pages/signature/Signature";
import Tool from "../pages/tool/Tool";
import ExportWhTokenDetail from "../pages/whToken/exportDetail/tokenDetail";
import ExportWhAssetDetail from "../pages/whAsset/exportDetail/assetDetail";
import WhDocumentViewExport from "../pages/whDocument/export/WhDocumentViewExport";
import StopAgencyCreate from "../pages/stop-agency/create/StopAgencyCreate";
import StopAgencyUpdate from "../pages/stop-agency/update/StopAgencyUpdate";
import StopAgency from "../pages/stop-agency/StopAgency";
import StopAgencyView from "../pages/stop-agency/view/StopAgencyView";
import UploadLiquidation from "../pages/stop-agency/upload/UploadLiquidation";
import WhTokenDetail from "../pages/whToken/WhTokenDetail";
import ChangeCertGroup from "../pages/customer/change-info-cert/ChangeInfoCertificateGroup";
import ChangeCertPersonal from "../pages/customer/change-info-cert/ChangeInfoCertificatePersonal";
import ChangeInfoCertificateGroupUpdate from "../pages/customer/change-info-cert/ChangeInfoCertificateGroupUpdate";
import ChangeInfoCertificatePersonalUpdate from "../pages/customer/change-info-cert/ChangeInfoCertificatePersonalUpdate";
import LockAccountRequest from "../pages/account/LockAccountRequest";
import TokenManager from "../pages/token-manager/TokenManager";
import TokenManagerCreate from "../pages/token-manager/create/TokenManagerCreate";
import TokenManagerUpdate from "../pages/token-manager/update/TokenManagerUpdate";
import TokenManagerView from "../pages/token-manager/view/TokenManagerView";

import MailCategory from "../pages/mail-category/MailCategory";
import MailCategoryCreate from "../pages/mail-category/create/MailCategoryCreate";
import MailCategoryUpdate from "../pages/mail-category/update/MailCategoryUpdate";
import ApproveUpdateToken from "../pages/approve-update-usb-token/ApproveUpdateToken";

import RequestCertificate from "../pages/request-certificate/RequestCertificate";
import RequestCertificateViewGroup from "../pages/request-certificate/view/RequestCertificateViewGroup";
import RequestCertificateViewPersonal from "../pages/request-certificate/view/RequestCertificateViewPersonal";
import RequestTraining from "../pages/request-training/RequestTraining";
import RequestTrainingView from "../pages/request-training/view/RequestTrainingView";

import WhDocumentCreateExport from "../pages/whDocument/export/WhDocumentCreateExport";
import CHANGE_INFO_ROUTES from "./route-change-info/ChangeInfoRoute";
import CHANGE_INFO_CERTIFICATE_ROUTES from "./route-change-info-certificate/ChangeInfoCertificateRoute";
import REQUEST_DOCUMENT_ROUTE from "./route-request-document/RequestDocumentRoute";
import ApproveUc from "../pages/approve-uc/ApproveUc";
import SUPPORT_ROUTES from "./support/Support";
import DOC_LEGAL_MANAGER from "./route-doc-legal-manager/DocLegalManagerRoute";

import GenCertCTS from "../pages/gen-cert-cts/GenCertCTS";
import WrappedPreviewCTS from "../pages/gen-cert-cts/preview/PreviewCTS";

import InfoCertificate from "../pages/infocertificate/InfoCertificate";

import Customer from "../pages/customer/Customer";
import PreviewOrganization from "../pages/customer/view-organization/PreviewOrganization";
import PreviewPersonal from "../pages/customer/view-personal/PreviewPersonaln";

import ContractManager from "../pages/contract-manager/ContractManager";
import CATE_DISCOUNT_ROUTE from "./route-cate-discount/CateDiscountRoute";
import CROSS_CHECKING_ROUTE from "./route-cross-checking/CrossCheckingRoute";
import {COMPENSATION_ROUTE} from "./route-compensation-data/CompensationDataRoute";
import {COMPENSATION_REQUEST_ROUTE} from "./route-compensation-request/CompensationRequestRoute";
import LogManagement from "../pages/log-management/LogManagement";
import Banner from "../pages/banner/Banner";
import BannerCreate from "../pages/banner/create/BannerCreate";
import BannerUpdate from "../pages/banner/update/BannerUpdate";
// import NEACList from "../pages/NEAC/NEACList";

export const ROUTERS_CONSTANT = [
    ...CHANGE_INFO_ROUTES,
    ...CHANGE_INFO_CERTIFICATE_ROUTES,
    ...REQUEST_DOCUMENT_ROUTE,
    ...SUPPORT_ROUTES,
    ...DOC_LEGAL_MANAGER,
    ...CATE_DISCOUNT_ROUTE,
    ...CROSS_CHECKING_ROUTE,
    ...COMPENSATION_ROUTE,
    ...COMPENSATION_REQUEST_ROUTE,
    {
        component: UserIndex,
        link: "/quan-ly-nguoi-dung",
        isExact: false
    },
    {
        component: Logout,
        link: "/logout",
        isExact: false
    },
    {
        component: ChangePassword,
        link: "/doi-mat-khau",
        isExact: false
    },
    {
        component: Home,
        link: "/",
        isExact: true
    },
    {
        component: CreateUser,
        link: "/quan-ly-nguoi-dung/them-moi",
        isExact: true
    },
    {
        component: UpdateUser,
        link: "/quan-ly-nguoi-dung/cap-nhat/:id",
        isExact: true
    },
    {
        component: RoleIndex,
        link: "/quan-ly-vai-tro",
        isExact: false
    },
    {
        component: CreateRole,
        link: "/quan-ly-vai-tro/them-moi",
        isExact: true
    },
    {
        component: UpdateRole,
        link: "/quan-ly-vai-tro/cap-nhat/:id",
        isExact: true
    },
    {
        component: Setting,
        link: "/cau-hinh-he-thong",
        isExact: false
    },
    {
        component: Field,
        link: "/linh-vuc-kinh-doanh",
        isExact: false
    },
    {
        component: CreateField,
        link: "/linh-vuc-kinh-doanh/them-moi",
        isExact: true
    },
    {
        component: UpdateField,
        link: "/linh-vuc-kinh-doanh/cap-nhat/:id",
        isExact: true
    },
    {
        component: Province,
        link: "/tinh-thanh",
        isExact: false
    },
    {
        component: CreateProvince,
        link: "/tinh-thanh/them-moi",
        isExact: true
    },
    {
        component: UpdateProvince,
        link: "/tinh-thanh/cap-nhat/:id",
        isExact: true
    },
    {
        component: District,
        link: "/quan-huyen",
        isExact: false
    },
    {
        component: CreateDistrict,
        link: "/quan-huyen/them-moi",
        isExact: true
    },
    {
        component: UpdateDistrict,
        link: "/quan-huyen/cap-nhat/:id",
        isExact: true
    },

    {
        component: TaxDepartment,
        link: "/chi-cuc-thue",
        isExact: false
    },
    {
        component: CreateTaxDepartment,
        link: "/chi-cuc-thue/them-moi",
        isExact: true
    },
    {
        component: UpdateTaxDepartment,
        link: "/chi-cuc-thue/cap-nhat/:id",
        isExact: true
    },

    {
        component: UserIndex,
        link: "/quan-ly-nguoi-dung",
        isExact: false
    },
    {
        component: Logout,
        link: "/logout",
        isExact: false
    },
    {
        component: ChangePassword,
        link: "/doi-mat-khau",
        isExact: false
    },
    {
        component: Home,
        link: "/",
        isExact: true
    },
    {
        component: CreateUser,
        link: "/quan-ly-nguoi-dung/them-moi",
        isExact: true
    },
    {
        component: UpdateUser,
        link: "/quan-ly-nguoi-dung/cap-nhat/:id",
        isExact: true
    },
    {
        component: RoleIndex,
        link: "/quan-ly-vai-tro",
        isExact: false
    },
    {
        component: CreateRole,
        link: "/quan-ly-vai-tro/them-moi",
        isExact: true
    },
    {
        component: UpdateRole,
        link: "/quan-ly-vai-tro/cap-nhat/:id",
        isExact: true
    },
    {
        component: Setting,
        link: "/cau-hinh-he-thong",
        isExact: false
    },
    {
        component: Field,
        link: "/linh-vuc-kinh-doanh",
        isExact: false
    },
    {
        component: CreateField,
        link: "/linh-vuc-kinh-doanh/them-moi",
        isExact: true
    },
    {
        component: UpdateField,
        link: "/linh-vuc-kinh-doanh/cap-nhat/:id",
        isExact: true
    },
    {
        component: Province,
        link: "/tinh-thanh",
        isExact: false
    },
    {
        component: CreateProvince,
        link: "/tinh-thanh/them-moi",
        isExact: true
    },
    {
        component: UpdateProvince,
        link: "/tinh-thanh/cap-nhat/:id",
        isExact: true
    },
    {
        component: District,
        link: "/quan-huyen",
        isExact: false
    },
    {
        component: CreateDistrict,
        link: "/quan-huyen/them-moi",
        isExact: true
    },
    {
        component: UpdateDistrict,
        link: "/quan-huyen/cap-nhat/:id",
        isExact: true
    },
    /**
     * Banner
     */
    {
        component: Banner,
        link: "/banner",
        isExact: false
    },
    {
        component: BannerCreate,
        link: "/banner/them-moi",
        isExact: true
    },
    {
        component: BannerUpdate,
        link: "/banner/cap-nhat/:id",
        isExact: true
    },

    {
        component: TaxDepartment,
        link: "/chi-cuc-thue",
        isExact: false
    },
    {
        component: CreateTaxDepartment,
        link: "/chi-cuc-thue/them-moi",
        isExact: true
    },
    {
        component: UpdateTaxDepartment,
        link: "/chi-cuc-thue/cap-nhat/:id",
        isExact: true
    },

    {
        component: Package,
        link: "/goi-dich-vu",
        isExact: false
    },
    {
        component: CreatePackage,
        link: "/goi-dich-vu/them-moi",
        isExact: true
    },
    {
        component: UpdatePackage,
        link: "/goi-dich-vu/cap-nhat/:id",
        isExact: true
    },
    {
        component: Agency,
        link: "/dai-ly",
        isExact: true
    },
    {
        component: UpdateAgency,
        link: "/dai-ly/cap-nhat/:id",
        isExact: true
    },
    {
        component: Contributor,
        link: "/cong-tac-vien",
        isExact: true
    },
    {
        component: UpdateContributor,
        link: "/cong-tac-vien/cap-nhat/:id",
        isExact: true
    },
    {
        component: RequestTkAdminToken,
        link: "/yeu-cau-tk-admin-token",
        isExact: true
    },
    {
        component: RequestTkAdminTokenUpdate,
        link: "/yeu-cau-tk-admin-token/xu-ly/:id",
        isExact: true
    },
    {
        component: RequestTokenCts,
        link: "/yeu-cau-token-cts",
        isExact: true
    },
    {
        component: RequestTokenCtsUpdate,
        link: "/yeu-cau-token-cts/xu-ly/:id",
        isExact: true
    },
    {
        component: Document,
        link: "/tai-lieu",
        isExact: true
    },
    {
        component: CreateDocument,
        link: "/tai-lieu/them-moi",
        isExact: true
    },
    {
        component: UpdateDocument,
        link: "/tai-lieu/cap-nhat/:id",
        isExact: true
    },
    {
        component: Software,
        link: "/phan-mem",
        isExact: true
    },
    {
        component: CreateSoftware,
        link: "/phan-mem/them-moi",
        isExact: true
    },
    {
        component: UpdateSoftware,
        link: "/phan-mem/cap-nhat/:id",
        isExact: true
    },
    {
        component: ProviderCategory,
        link: "/nha-cung-cap",
        isExact: true
    },
    {
        component: ProviderCategoryCreate,
        link: "/nha-cung-cap/them-moi",
        isExact: true
    },
    {
        component: ProviderCategoryUpdate,
        link: "/nha-cung-cap/cap-nhat/:id",
        isExact: true
    },
    {
        component: Province,
        link: "/tinh-thanh",
        isExact: true
    },
    {
        component: CreateProvince,
        link: "/tinh-thanh/them-moi",
        isExact: true
    },
    {
        component: UpdateProvince,
        link: "/tinh-thanh/cap-nhat/:id",
        isExact: true
    },
    {
        component: VersionToken,
        link: "/danh-muc-version-token",
        isExact: true
    },
    {
        component: VersionTokenCreate,
        link: "/danh-muc-version-token/them-moi",
        isExact: true
    },
    {
        component: VersionTokenUpdate,
        link: "/danh-muc-version-token/cap-nhat/:id",
        isExact: true
    },
    {
        component: AssetCategory,
        link: "/danh-muc-tai-san",
        isExact: true
    },
    {
        component: AssetCategoryCreate,
        link: "/danh-muc-tai-san/them-moi",
        isExact: true
    },
    {
        component: AssetCategoryUpdate,
        link: "/danh-muc-tai-san/cap-nhat/:id",
        isExact: true
    },

    {
        component: Service,
        link: "/dich-vu",
        isExact: true,
    },
    {
        component: CreateService,
        link: "/dich-vu/them-moi",
        isExact: true,
    },
    {
        component: UpdateService,
        link: "/dich-vu/cap-nhat/:id",
        isExact: true,
    },
    //kho tài sản
    {
        component: WhAsset,
        link: "/kho-tai-san",
        isExact: true,
    },
    {
        component: WhAssetCreate,
        link: "/kho-tai-san/them-moi",
        isExact: true,
    },
    {
        component: WhAssetUpdate,
        link: "/kho-tai-san/cap-nhat/:id",
        isExact: true,
    },
    {
        component: ViewWhAsset,
        link: "/kho-tai-san/xem-chi-tiet/:id",
        isExact: true,
    },
    {
        component: ExportWhAsset,
        link: "/kho-tai-san/xuat-kho",
        isExact: true,
    },
    //kho tài liệu bán hàng
    {
        component: WhDocument,
        link: "/kho-tai-lieu-ban-hang",
        isExact: true,
    },
    {
        component: WhDocumentCreate,
        link: "/kho-tai-lieu-ban-hang/them-moi",
        isExact: true,
    },
    {
        component: WhDocumentUpdate,
        link: "/kho-tai-lieu-ban-hang/cap-nhat/:id",
        isExact: true,
    },
    {
        component: ViewWhDocument,
        link: "/kho-tai-lieu-ban-hang/xem-chi-tiet/:id",
        isExact: true,
    },
    {
        component: Service,
        link: "/dich-vu",
        isExact: true,
    },
    {
        component: CreateService,
        link: "/dich-vu/them-moi",
        isExact: true,
    },
    {
        component: UpdateService,
        link: "/dich-vu/cap-nhat/:id",
        isExact: true,
    },
    {
        component: WhToken,
        link: "/kho-token",
        isExact: true,
    },
    {
        component: CreateWhToken,
        link: "/kho-token/them-moi",
        isExact: true,
    },
    {
        component: UpdateWhToken,
        link: "/kho-token/cap-nhat/:id",
        isExact: true,
    },
    {
        component: ExportWhToken,
        link: "/kho-token/xuat-kho",
        isExact: true,
    },
    {
        component: ViewWhToken,
        link: "/kho-token/xem-chi-tiet/:id",
        isExact: true,
    },
    {
        component: WhTokenDetail,
        link: "/danh-sach-token-chi-tiet",
        isExact: true,
    },
    //danh mục hợp đồng
    {
        component: ContractCategory,
        link: "/danh-muc-hop-dong",
        isExact: true,
    },
    {
        component: ContractCategoryCreate,
        link: "/danh-muc-hop-dong/them-moi",
        isExact: true,
    },
    {
        component: ContractCategoryUpdate,
        link: "/danh-muc-hop-dong/cap-nhat/:id",
        isExact: true,
    },
    //danh mục mail
    {
        component: MailCategory,
        link: "/danh-muc-mail",
        isExact: true,
    },
    {
        component: MailCategoryCreate,
        link: "/danh-muc-mail/them-moi",
        isExact: true,
    },
    {
        component: MailCategoryUpdate,
        link: "/danh-muc-mail/cap-nhat/:id",
        isExact: true,
    },
    //danh mục hợp đồng
    {
        component: ExportWhTokenList,
        link: "/danh-sach-xuat-kho",
        isExact: true,
    },
    {
        component: ExportWhToken,
        link: "/danh-sach-xuat-kho/xuat-kho-token",
        isExact: true,
    },
    {
        component: ExportWhAsset,
        link: "/danh-sach-xuat-kho/xuat-kho-qua-tang",
        isExact: true,
    },
    {
        component: ExportWhTokenDetail,
        link: "/danh-sach-xuat-kho/xem-chi-tiet-token/:id",
        isExact: true,
    },
    {
        component: ExportWhAssetDetail,
        link: "/danh-sach-xuat-kho/xem-chi-tiet-tai-san/:id",
        isExact: true,
    },
    {
        component: WhDocumentViewExport,
        link: "/danh-sach-xuat-kho/xem-chi-tiet-tai-lieu/:id",
        isExact: true,
    },
    //Cấu hình chứng thư số
    {
        component: Signature,
        link: "/them-chung-thu-so",
        isExact: true,
    },
    {
        component: Tool,
        link: "/tool-msp",
        isExact: true,
    },
     //nghiệp vụ
    {
      component: LockAccountRequest,
      link: "/yeu-cau-khoa-tai-khoan",
      isExact: true,
    },
    {
      component: TokenManager,
      link: "/quan-ly-token",
      isExact: true,
    },
    {
        component: TokenManagerCreate,
        link: "/quan-ly-token/them-moi",
        isExact: true,
    },
    {
        component: TokenManagerUpdate,
        link: "/quan-ly-token/cap-nhat/:id",
        isExact: true,
    },
    {
        component: TokenManagerView,
        link: "/quan-ly-token/xem-chi-tiet/:id",
        isExact: true,
    },
    {
        component: ApproveUpdateToken,
        link: "/cap-quyen-cap-nhat-usb-token",
        isExact: true,
    },
    {
        component: RequestCertificate,
        link: "/yeu-cau-chung-thu-so",
        isExact: true,
    },
    // {
    //     component: NEACList,
    //     link: "/neac-list",
    //     isExact: true,
    // },
    {
        component: RequestCertificateViewPersonal,
        link: "/yeu-cau-chung-thu-so/ca-nhan/:id",
        isExact: true,
    },
    {
        component: RequestCertificateViewGroup,
        link: "/yeu-cau-chung-thu-so/to-chuc/:id",
        isExact: true,
    },
    {
        component: GenCertCTS,
        link: "/gen-cert-cts",
        permission: "",
        isExact: true
    },
    {
        component: WrappedPreviewCTS,
        link: "/gen-cert-cts/xem/:id",
        permission: "",
        isExact: true
    },
    {
        component: InfoCertificate,
        link: "/danh-sach-chung-thu-so",
        permission: "",
        isExact: true
    },

    //Dừng đại lý
    {
        component: StopAgencyCreate,
        link: "/dung-hop-tac/them-moi/:id",
        isExact: true,
    },
    {
        component: StopAgencyUpdate,
        link: "/dung-hop-tac/cap-nhat/:id",
        isExact: true,
    },
    {
        component: StopAgencyView,
        link: "/dung-hop-tac/chi-tiet/:id",
        isExact: true,
    },
    {
        component: UploadLiquidation,
        link: "/dung-hop-tac/cap-nhat-bien-ban-thanh-ly/:id",
        isExact: true,
    },
    {
        component: StopAgency,
        link: "/dung-hop-tac",
        isExact: true,
    },
    {
        component: WhDocumentCreateExport,
        link: "/kho-tai-lieu/xuat-kho",
        isExact: true,
    },
    //Phê duyệt UC
    {
        component: ApproveUc,
        link: "/danh-sach-phe-duyet-uc",
        isExact: true,
    },
    //Hỗ trợ bán hàng
    {
        component: RequestTraining,
        link: "/danh-sach-yeu-cau-dao-tao",
        isExact: true,
    },
    {
        component: RequestTrainingView,
        link: "/danh-sach-yeu-cau-dao-tao/xem-chi-tiet/:id",
        isExact: true,
    },
    //Danh mục khách hàng
    {
        component: Customer,
        link: "/danh-sach-khach-hang",
        permission: "list-customer-ad",
        isExact: true,
    },
    {
        component: PreviewOrganization,
        link: "/danh-sach-khach-hang/xem-chi-tiet-to-chuc/:id/:created_by/:uid",
        isExact: true,
    },
    {
        component: PreviewPersonal,
        link: "/danh-sach-khach-hang/xem-chi-tiet-ca-nhan/:id/:created_by/:uid",
        isExact: true,
    },
    {
        component: ChangeCertPersonal,
        link: "/danh-sach-khach-hang/thay-doi-thong-tin-ca-nhan/:id/:created_by/:uid",
        isExact: true,
    },
    {
        component: ChangeCertGroup,
        link: "/danh-sach-khach-hang/thay-doi-thong-tin-to-chuc/:id/:created_by/:uid",
        isExact: true,
    },
    {
        component: ChangeInfoCertificatePersonalUpdate,
        link: "/danh-sach-khach-hang/chinh-sua-thay-doi-thong-tin-ca-nhan/:id/:uid",
        isExact: true,
    },
    {
        component: ChangeInfoCertificateGroupUpdate,
        link: "/danh-sach-khach-hang/chinh-sua-thay-doi-thong-tin-to-chuc/:id/:uid",
        isExact: true,
    },
    {
        component: ContractManager,
        link: "/quan-ly-hop-dong",
        isExact: true,
    },
    {
        component: ContractManager,
        link: "/quan-ly-hop-dong/previewFile/:id",
        isExact: true,
    },
    {
        component: LogManagement,
        link:"/log-management",
        isExact:true,
    }

];

export const NAVLINK_CONSTANT = [
    {
        link: "agency",
        icon: "fas fa-store-alt",
        displayName: "Quản lý hợp tác",
        permission: "menu-agency",
        childMenu: [
            {
                link: "/dai-ly",
                childIcon: "fas fa-store",
                displayName: "Quản lý đại lý",
                permission: "agency-list-admin"
            },
            {
                link: "/cong-tac-vien",
                childIcon: "fas fa-fist-raised",
                displayName: "Quản lý cộng tác viên",
                permission: "contributor-list-admin"
            },
            {
                link: "/yeu-cau-tk-admin-token",
                childIcon: "fas fa-user-shield",
                displayName: "Yêu cầu tạo tài khoản",
                permission: "request-account-admin-list"
            },
            {
                link: "/dung-hop-tac",
                childIcon: "fas fa-stop",
                displayName: "Dừng hợp tác",
                permission: "request-stop-cooperater"
            },
            {
                link: "/yeu-cau-thay-doi-thong-tin",
                childIcon: "fas fa-dice",
                displayName: "Yêu cầu thay đổi thông tin",
                permission: "nv-request-change-info"
            },
        ]
    },
    {
        link: "wearhourse",
        icon: "fas fa-warehouse",
        displayName: "Quản lý kho",
        permission: "menu-wearhourse",
        childMenu: [
            {
                link: "/kho-token",
                childIcon: "fas fa-database",
                displayName: "Kho token",
                permission: "wh-token"
            },
            {
                link: "/kho-tai-lieu-ban-hang",
                childIcon: "fas fa-box-open",
                displayName: "Kho tài liệu bán hàng",
                permission: "wh-document"
            },
            {
                link: "/kho-tai-san",
                childIcon: "fas fa-briefcase",
                displayName: "Kho tài sản",
                permission: "wh-asset"
            },
            {
                link: "/danh-sach-xuat-kho",
                childIcon: "fas fa-file-export",
                displayName: "Quản lý xuất kho",
                permission: "wh-export"
            },
            {
                link: "/danh-sach-token-chi-tiet",
                childIcon: "fas fa-align-justify",
                displayName: "Danh sách token chi tiết",
                permission: "wh-export"
            },
        ]
    },
    {
    link: "operation",
    icon: "fas fa-wrench",
    displayName: "Nghiệp vụ",
    permission: "menu-operation",
    childMenu: [
        {
            link: "/yeu-cau-khoa-tai-khoan",
            childIcon: "fas fa-user-lock",
            displayName: "Yêu cầu khóa tài khoản",
            permission: "nv-request-lock-account"
        },
        {
            link: "/quan-ly-token",
            childIcon: "fas fa-server",
            displayName: "Quản lý token",
            permission: "nv-token-manager"
        },
        {
            link: "/cap-quyen-cap-nhat-usb-token",
            childIcon: "fas fa-fighter-jet",
            displayName: "Cấp quyền cập nhật usb token",
            permission: "nv-update-usb-token"
        },
        {
            link: "/yeu-cau-chung-thu-so",
            childIcon: "fas fa-certificate",
            displayName: "Yêu cầu chứng thư số",
            permission: "nv-request-cetificate"
        },
        {
            link: "/danh-sach-phe-duyet-uc",
            childIcon: "fas fa-check",
            displayName: "Phê duyệt UC",
            permission: "nv-confirm-uc"
        },
        {
            link: "/gen-cert-cts",
            childIcon: "fas fa-file-invoice",
            displayName: "Thông tin chứng thư số",
            permission: "nv-gen-cert"
        },
        {
            link:"/danh-sach-chung-thu-so",
            childIcon: "fas fa-align-justify",
            displayName: "Danh sách chứng thư số",
            permission: "list-cer"
        },
        {
            link:"/thay-doi-thong-tin-chung-thu-so",
            childIcon: "fas fa-cube",
            displayName: "Yêu cầu thay đổi thông tin CTS",
            permission: "nv-request-change-info-certificate"
        },
        {
            link:"/du-lieu-cap-bu",
            childIcon: "fas fa-cube",
            displayName: "Dữ liệu cấp bù",
            permission: ""
        },
        {
            link:"/yeu-cau-cap-bu",
            childIcon: "fas fa-cube",
            displayName: "Yêu cầu cấp bù",
            permission: "list-request-compensation"
        },
        // {
        //     link:"/neac-list",
        //     childIcon: "fas fa-cube",
        //     displayName: "NEAC",
        //     permission: ""
        // },
    ]
  },
    {
        link: "support-sell",
        icon: "fas fa-shopping-cart",
        displayName: "Hỗ trợ bán hàng",
        permission: "menu-support-sell",
        childMenu: [
            {
                link: "/danh-sach-yeu-cau-dao-tao",
                childIcon: "fas fa-list-alt",
                displayName: "Danh sách yêu cầu đào tạo",
                permission: "list-request-training-ad"
            },
            {
                link: "/yeu-cau-token-cts",
                childIcon: "fas fa-user-shield",
                displayName: "Yêu cầu tài liệu bán hàng",
                permission: "request-document"
            },
        ]
    },
    {
        link: "category",
        icon: "fas fa-folder-plus",
        displayName: "Quản lý danh mục",
        permission: "menu-category",
        childMenu: [
            {
                link: "/goi-dich-vu",
                childIcon: "fas fa-box-open",
                displayName: "Gói dịch vụ",
                permission: "cate-package"
            },
            {
                link: "/tai-lieu",
                childIcon: "fas fa-file-word",
                displayName: "Văn bản",
                permission: "cate-document"
            },
            {
                link: "/phan-mem",
                childIcon: "fas fa-toolbox",
                displayName: "Phần mềm",
                permission: "cate-software"
            },
            {
                link: "/nha-cung-cap",
                childIcon: "fas fa-ethernet",
                displayName: "Nhà cung cấp",
                permission: "cate-provider"
            },
            {
                link: "/danh-muc-version-token",
                childIcon: "fas fa-code-branch",
                displayName: "Version token",
                permission: "cate-version-token"
            },
            {
                link: "/danh-muc-tai-san",
                childIcon: "fas fa-gem",
                displayName: "Tài sản",
                permission: "cate-asset"
            },
            {
                link: "/danh-sach-chiet-khau",
                childIcon: "fas fa-universal-access",
                displayName: "Danh mục chiết khấu",
                permission: "cate-discount-list"
            },
            {
                link: "/danh-muc-hop-dong",
                childIcon: "fas fa-file-pdf",
                displayName: "Mẫu tài liệu",
                permission: "cate-template-document"
            },
            {
                link: "/danh-muc-mail",
                childIcon: "fas fa-envelope",
                displayName: "Mẫu mail",
                permission: "cate-template-email"
            },
            {
                link: "/tinh-thanh",
                childIcon: "fas fa-ethernet",
                displayName: "Tỉnh thành",
                permission: "cate-template-email"
            },
            {
                link: "/quan-huyen",
                childIcon: "fas fa-ethernet",
                displayName: "Quận huyện",
                permission: "cate-template-email"
            },
            {
                link: "/banner",
                childIcon: "fas fa-image",
                displayName: "Banner",
                permission: "cate-template-email"
            }
        ]
    },
    {
        link: "customer",
        icon: "fas fa-users",
        displayName: "Khách hàng",
        permission: "menu-customer",
        childMenu: [
            {
                link: "/danh-sach-khach-hang",
                childIcon: "fas fa-list-alt",
                displayName: "Danh sách khách hàng",
                permission: "list-customer-ad"
            }
        ]
    },
    {
        link: "manager-files",
        icon: "fas fa-archive",
        displayName: "Quản lý hồ sơ",
        permission: "menu-manager-files",
        childMenu: [
            {
                link: "/quan-ly-hop-dong",
                childIcon: "fas fa-file",
                displayName: "Quản lý hợp đồng",
                permission: "contract-manager"
            },
            {
                link:"/quan-ly-ho-so-phap-ly",
                childIcon: "fas fa-balance-scale",
                displayName: "Quản lý hồ sơ pháp lý",
                permission: "doc-legal-manager"
            },
        ]
    },
    {
        link: "crosschecking",
        icon: "fas fa-clipboard",
        displayName: "Đối soát",
        permission: "menu-crosschecking",
        childMenu: [
            {
                link: "/danh-sach-doi-soat-all",
                childIcon: "fas fa-list-alt",
                displayName: "Danh sách đối soát",
                permission: "cross-checking-list"
            },
            {
                link: "/danh-sach-doi-soat-yeu-cau",
                childIcon: "fas fa-list-alt",
                displayName: "Đối soát yêu cầu",
                permission: "cross-checking-list"
            },
            {
                link: "/danh-sach-doi-soat-cap-bu",
                childIcon: "fas fa-list-alt",
                displayName: "Đối soát cấp bù",
                permission: "cross-checking-list"
            }
        ]
    },
    {
        link: "system",
        icon: "fas fa-cogs",
        displayName: "Hệ thống",
        permission: "menu-system",
        childMenu: [
            {
                link: "/quan-ly-nguoi-dung",
                childIcon: "fas fa-user",
                displayName: "Quản lý người dùng",
                permission: "user-list"
            },
            {
                link: "/quan-ly-vai-tro",
                childIcon: "fas fa-users-cog",
                displayName: "Quản lý vai trò",
                permission: "role-list"
            },
            {
                link: "/cau-hinh-he-thong",
                childIcon: "fas fa-tools",
                displayName: "Cấu hình hệ thống",
                permission: "config-system"
            },
            {
                link: "/them-chung-thu-so",
                childIcon: "fas fa-signature",
                displayName: "Cấu hình chứng thư số",
                permission: "config-certificate"
			},
            {
                link: "/tool-msp",
                childIcon: "fas fa-toolbox",
                displayName: "Tool for MSP",
                permission: "tool-msp"
			},
            {
                link: "/log-management",
                childIcon: "fas fa-toolbox",
                displayName: "Quản lý log",
                permission: "log-management"
			},
        ]
    },
    {
        link: "support",
        icon: "fas fa-headset",
        displayName: "Hỗ trợ",
        permission: "",
        childMenu: [
            {
                link: "/ho-tro-tai-lieu",
                childIcon: "fas fa-book-open",
                displayName: "Văn bản",
                permission: ""
            },
            {
                link: "/ho-tro-phan-mem",
                childIcon: "fas fa-cog",
                displayName: "Phần mềm",
                permission: ""
            }
        ]
    }
];
