import React, {Component} from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {convertJsonToQueryString, queryStringToJSON} from "../../../helpers/NewCaHelper";
import {History, Location} from "history";
import {match} from "react-router";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import SearchButton from "../../common/button/SearchButton";
import {StatusEnum, TypeUser} from "./UserEnum";
import InputWithLabel from "../../common/form/InputWithLabel";
import SelectBox from "../../common/form/SelectBox";
import Helmet from "react-helmet";
import UserService from "../../../services/user/UserService";
import UserTable from "./table/UserTable";
import SelectBoxAgency from "../../common/form/SelectBoxAgency";

interface Props {
    history: History;
    location: Location;
    match: match;
    name: string;
}

interface State {
    dataSearch: any;
    agency: any;
}

const TITLE = "Danh sách người dùng";

class UserIndex extends Component<Props, State> {
    state = {
        dataSearch: {
            type: "",
            text_search: "",
            owner_id: null
        },
        agency:{}
    };

    // Function to fetch agency data
    fetchAgencyData = async (type) => {
        const userService = new UserService();
        const lst = await userService.getAgencyContributor(type);
        if (lst) {
            let agency = {};
            lst.data.map(value => (agency[value.id] = value.fullname));
            this.setState({agency});
          }
    };
    
    fetchDataFunction = async (dataSearch = {}) => {
        const fetchAPI = new UserService();
        const result = await fetchAPI.getUsers({...dataSearch});
        if (result && result.status === 200) {
            return result;
        }
        return null;
    };
    
    onChangeDataSearchInput =  e => {
        const name = e.target.name;
        const value = e.target.value;
        const dataSearch = {...this.state.dataSearch, [name]: value};
        this.setState({dataSearch});
    };
    onChangeDataSearchType = async (e) => {
        const dataSearch = {...this.state.dataSearch, "type": e};
        this.setState({dataSearch});

        if (e === "7" || e === "8"){
            await this.fetchAgencyData(e);
        }
    };

    handleChangeBoxAgency =  e => {
        const dataSearch = {...this.state.dataSearch, owner_id: e};
        this.setState({dataSearch});


    };

    onSubmitSearchButton = async e => {
        e.preventDefault();
        let {dataSearch} = this.state;
        const queryString = convertJsonToQueryString(dataSearch);
        this.props.history.push({
            pathname: this.props.match.url,
            search: queryString
        });
    };
    
    onRenderStatusColumn(status) {
        switch (status) {
            case StatusEnum.ACTIVE:
                return <label className="badge badge-success">Hoạt động</label>;
            case StatusEnum.DEACTIVE:
                return <label className="badge badge-warning">Tạm dừng</label>;
            default:
                return <label className="badge badge-danger">Không hoạt động</label>;
        }
    }
    
    onRenderTypeUserColumn(type) {
        return (
            <label className={`badge ${TypeUser.badgeClass[type]}`}>
                {TypeUser.TYPE[type]}
            </label>
        );
    }
    
    onClickUpdateButton = data => {
        this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
    };
    
    async  componentDidMount() {
        const queryString = queryStringToJSON(this.props.location.search);
        this.setState({
            dataSearch: queryString
        });

        // Fetch agency data when the component mounts
    }
    
    render() {
        return (
            <div className="card">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <h4 className="card-title1">{TITLE}</h4>
                <div className="card-body">
                    <form
                        onSubmit={this.onSubmitSearchButton}
                        className="search-form-index"
                    >
                        <div className="input-group">
                            <SelectBox
                                value={this.state.dataSearch.type}
                                label=""
                                onChange={this.onChangeDataSearchType}
                                name="type"
                                wrappedClass="col-md-2 form-group nopadding-left"
                                options={TypeUser.TYPE}
                                firstOptionLabel="Loại người dùng"
                            />
                            <SelectBoxAgency
                                options={this.state.agency}
                                label=""
                                name="owner_id"
                                wrappedClass="col-md-2 form-group nopadding-left"
                                onChange={this.handleChangeBoxAgency}
                                value={this.state.dataSearch.owner_id}
                                firstOptionLabel="Tất cả đại lý"
                                isRequired={false}
                                />
                            <InputWithLabel
                                name="text_search"
                                wrapClass="col-md-4 form-group nopadding-left"
                                onChangeValue={this.onChangeDataSearchInput}
                                value={this.state.dataSearch.text_search}
                                isRequired={false}
                                label={""}
                                placeholder={"Họ tên, tên đăng nhập, email hoặc số điện thoại"}
                            />
                            <div className="col-md-4 form-group">
                                <SearchButton onClick={this.onSubmitSearchButton}/>
                                <GoToCreatePageButton
                                    toURL={`${this.props.match.url}/them-moi`}
                                />
                            </div>
                        </div>
                    </form>
                    <UserTable
                        location={this.props.location}
                        history={this.props.history}
                        match={this.props.match}
                    />
                </div>
            </div>
        );
    }
}

const pageWithNavBar = withNavbar()(UserIndex);

export default pageWithNavBar;
