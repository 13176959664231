export const STATUS_REQUEST_TOKEN_LABEL = {
    1: {
      label: "Nháp",
      class: "badge-secondary"
    },
    2: {
      label: "Chờ kế toán duyệt",
      class: "badge-info"
    },
    3: {
      label: "Kế toán từ chối",
      class: "badge-danger"
    },
    4: {
      label: "Kế toán đã duyệt / Hoàn thành",
      class: "badge-success"
    },
    5:{
        label: "Chờ HTKD duyệt",
        class: "badge-warning"
    },
    6:{
        label: "HTKD từ chối",
        class: "badge-danger"
    }
  };
export const STATUS_CERTIFICATE = {
    1:{
        label: "Hoạt động",
        class: "green"
    },
    2:{
        label: "Đã thu hồi",
        class: "orange"
    },
    3:{
        label: "Đã hủy",
        class: "purple"
    },
    4:{
        label: "Đã tạm dừng",
        class: "volcano"
    },

    8:{
        label: "Đã xóa",
        class: "red"
    },
    9: {
        label: "Đã hết hạn",
        class: "magenta"
    },
    10: {
        label: "Chờ khách hàng ký xác nhận thu hồi",
        class: "crimson"
    },
    11: {
        label: "Chờ nghiệp vụ duyệt thu hồi",
        class: "fuchsia"
    },

    15: {
        label: "Chờ khách hàng ký xác nhận hủy",
        class: "pea"
    },
    16: {
        label: "Chờ nghiệp vụ duyệt hủy",
        class: "olive"
    },
    20: {
        label: "Chờ khách hàng ký xác nhận tạm dừng",
        class: "cyan"
    },
    21: {
        label: "Chờ nghiệp vụ duyệt tạm dừng",
        class: "azure"
    },

    25: {
        label: "Chờ thay đổi thông tin",
        class: "cyan"
    },
    26: {
        label: "Chờ cấp lại thiết bị",
        class: "coral"
    },
    27: {
        label: "Chờ bảo hành thiết bị",
        class: "lime"
    },
  };
export const STATUS_REQUEST = {
    1:{
        label: "Yêu cầu cấp mới",
        class: "green"
    },
    2: {
        label: "Yêu cầu gia hạn",
        class: "orange"
    },
    3:{
        label: "Yêu cầu chuyển đổi",
        class: "cyan"
    },
    4:{
        label: "Yêu cầu thay đổi thông tin",
        class: "purple"
    },
    5:{
        label: "Yêu cầu cấp lại",
        class: "red"
    },
    6:{
        label: "Yêu cầu bảo hành",
        class: "blue"
    }
};

  export enum REQUEST_TOKEN_PERMISSION {
      ALL = "request-token-all",
  }

  export enum REQUEST_STOP_COOP {
      ALL = "stop-coop-list",
  }

export class TypeToken {
    static readonly TYPEAGENCY = {
        1: "Đại lý",
        2: "Cộng tác viên"
    };
}

