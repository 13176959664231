import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./css/main.css";
import "./css/util.css";
import "./css/login.css";
import { authenticationService } from "../../../services/AuthenticationService";
import { History, Location } from "history";
import UserService from "../../../services/user/UserService";
import Store from "../../../store/Store";
import { setUserDataAction } from "./../../../actions/AuthActions";
import axios from "axios";
import {Button, Input, Modal, Select} from "antd";
import LabelInput from "../../common/form/input-file-with-label/LabelInput";
import {loading} from "../../common/loading/Loading";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
const { REACT_APP_BASE_GET_TOKEN_URL } = window['runConfig'];
interface Props {
  history: History;
  location: Location;
}
interface State {
  username: string;
  password: string;
  mes: string;
  isVisitable: boolean;
  isDisplayMessage: boolean;
  dataTokens: any;
  dataTokenSelect: {
    CN: string,
    IssuedBy: string,
    STT: string,
    Serial: string,
    ValidFrom: string,
    ValidTo: string
  }
}
const initialState = {
  username: "",
  password: "",
  mes: "",
  isVisitable: false,
  isDisplayMessage: false,
  dataTokens: [],
  dataTokenSelect: {
    CN: "",
    IssuedBy: "",
    STT: "",
    Serial: "",
    ValidFrom: "",
    ValidTo: ""
  }
};

export default class Login extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = initialState;
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }
  }

  loginByToken = async data => {
    const user = await authenticationService.login(data);
    this.callUser(user).then();
  };

  onHandleChangeInput = evt => {
    const value = evt.target.value;
    const name = evt.target.name;
    this.setState({
      [name]: value
    } as Pick<State, keyof State>);
  };

  onLogin = async evt => {
    evt.preventDefault();
    const { username, password } = this.state;
    const data = {
      username,
      password,
      type_system: 1
    };
    const user = await authenticationService.login(data);
    this.callUser(user).then();
    return false;
  };

  onLoginByToken = async evt => {
    evt.preventDefault();
    const persons = {
      type_system: 1,
      type_login: 2
    };

    axios
      .get(window.location.origin + "/index.php")
      .then(res => {
        const info = res.data;
        if (info !== null && info.cert !== false && info.seri !== false) {
          persons["username"] = info.cert;
          persons["token"] = info.seri;
          const user = this.loginByToken(persons);
          this.callUser(user);
        } else {
          this.setState({ mes: "Thông tin chứng thư số không đầy đủ" });
        }
      })
      .catch(error => {
        this.setState({
          mes: "Trình duyệt hiện tại không thể đăng nhập chứng thư số"
        });
      });
  };

  onOK = async () => {
    let {dataTokenSelect} = this.state
    let data = {
      type_system: 1,
      type_login: 2,
      username: dataTokenSelect.CN,
      token: dataTokenSelect.Serial
    }
    const user = await authenticationService.login(data);
    if (user && user.token){
      this.callUser(user).then();
    }else {
      this.setState({
        isVisitable: false,
        mes: "Thông tin chứng thư số bị thiếu hoặc không đúng!",
        isDisplayMessage: true
      });
    }
  }

  onLoginByTokenV2 = async (e) => {
    e.preventDefault();
    // Step 1: Get data form plugin
    loading.runLoadingBlockUI();
    this.reset();
    // const baseUrl = "http://localhost:6706/api/certificate/getcertstore"
    const baseUrl = `${REACT_APP_BASE_GET_TOKEN_URL}certificate/getcertstore`;
    const dataRawStr = await axios
        .get(baseUrl, {timeout: 2000})
        .then((response) => {
          if (response && response.data.length > 0) {
            return response.data
          } else {
            this.setState({
              mes: 'Bạn chưa có CTS!',
              isDisplayMessage: true
            })
            return
          }
        })
        .catch(() => {
          this.setState({
            mes: 'Bạn cần cài đặt plugin Token!',
            isDisplayMessage: true
          })
          return
        });
    // Step2: Show modal
    let dataTokenArr = [];
    if (dataRawStr != null) {
      dataTokenArr = JSON.parse(dataRawStr)
      this.setState({
        isVisitable: true,
        dataTokens: dataTokenArr
      })
    }
    loading.stopRunLoading()
  };

  downloadPlugin = () => {
    try{
      const link = `${REACT_APP_BASE_API_URL}gen-cts-customer/get-file-plugin`;
      window.open(link);
    }catch (error){
      this.setState({
        mes: "Có lỗi xảy ra!"
      });
    }
  }

  onCancel = () => {
    this.reset();
  }

  reset() {
    this.setState(initialState);
  }


  handleChange = (index) => {
    const {dataTokens} = this.state
    this.setState({
      dataTokenSelect: dataTokens[index]
    })
  }

  callUser = async user => {
    if (user && user.token) {
      this.props.history.push("/");
      const serviceUser = new UserService();
      const result = await serviceUser.currentUser();
      Store.dispatch(setUserDataAction(result.data));
    } else if (user) {
      if (user.status === 1) {
        this.setState({ mes: user.message });
      }
    }
  };

  show_message = mes => {
    if (mes !== "") {
      return (
        <div>
          <span className="red">{mes}</span>{" "}
        </div>
      );
    }
  };

  render() {
    const {Option} = Select;
    const {dataTokens} = this.state;
    return (
      <div>
        <Helmet>
          <title>Login page</title>
        </Helmet>
        <div className="limiter">
          <div className="container-login100">
            <div className="wrap-login100 p-l-50 p-r-50 p-t-55 p-b-55">
              <form
                  method="post"
                  onSubmit = {this.onLogin}
              >
                <Modal
                    title={
                      <div className={'login100-form-title text-center'}>
                        <div className={'text-center mt-3'}>
                          <img
                              src={"/images/logo2.jpg"}
                              width="auto"
                              height="40"
                              className="d-inline-block align-center"
                              alt=""
                          />
                        </div>
                      </div>
                    }
                    visible={this.state.isVisitable}
                    onCancel={this.onCancel}
                    onOk={this.onOK}
                    centered={true}
                    closable={false}
                    footer={[
                      <Button
                          key="back"
                          onClick={this.onCancel}
                          style={{'width': '5vw'}}
                      >
                        Hủy bỏ
                      </Button>,
                      <Button
                          key="submit"
                          type="primary"
                          onClick={this.onOK}
                          style={{'width': '5vw'}}
                      >
                        Đăng nhập
                      </Button>,

                    ]}
                >
                  <LabelInput
                      label={'Chọn chứng thư số đăng nhập'}
                      nameFor={''}
                  />
                  <Select
                      className={'form-control customSelectLogin mb-3'}
                      style={{'border': 'none'}}
                      value={this.state.dataTokenSelect.CN ? this.state.dataTokenSelect.CN : '---Lựa chọn Token---'}
                      onChange={this.handleChange}
                  >
                    {dataTokens.length > 0 ? dataTokens.map((data, index) => (
                        <Option
                            key={index}
                            value={index}
                        >
                          {data['CN']}
                        </Option>
                    )) : ''}
                  </Select>
                  <LabelInput
                      label={'Tên Token'}
                      nameFor={''}
                  />
                  <Input
                      className={"form-control mb-3"}
                      value={this.state.dataTokenSelect.CN}
                      type={"text"}
                      name={'CN'}
                      disabled={true}
                  />

                  <LabelInput
                      label={'Mã Serial'}
                      nameFor={''}/>
                  <Input
                      className={"form-control"}
                      value={this.state.dataTokenSelect.Serial}
                      type={"text"}
                      name={'serial'}
                      disabled={true}
                  />

                </Modal>
                <div className="text-center mt-1">
                  <img
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    width="auto"
                    height="40"
                    className="d-inline-block align-center"
                    alt=""
                  />
                </div>
                <span className="login100-form-title p-b-14 mt-4 text-center">Đăng nhập</span>
                <span className="txt1 p-b-11">Tài khoản</span>
                <div
                  className="wrap-input100 validate-input m-b-20"
                  data-validate="Username is required"
                >
                  <input
                    className="input100 form-control"
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={this.onHandleChangeInput}
                    autoComplete="current-username"
                  />
                  <span className="focus-input100" />
                </div>

                <span className="txt1 p-b-11">Mật khẩu</span>
                <div
                  className="wrap-input100 validate-input m-b-12"
                  data-validate="Password is required"
                >
                  {/* <span className="btn-show-pass">
                                        <i className="fa fa-eye" />
                                    </span> */}
                  <input
                    className="input100 form-control"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.onHandleChangeInput}
                    autoComplete="current-password"
                  />
                  <span className="focus-input100" />
                </div>

                <div className="flex-sb-m w-full pull-right" hidden>
                  <div className="">
                    <button className="txt3">Forgot Password?</button>
                  </div>
                </div>
                <div className="flex-sb-m w-full p-b-24">
                  {this.show_message(this.state.mes)}
                </div>
                <div
                  className="container-login50-form-btn text-center"
                  onClick={this.onLogin}
                >
                  <button className="login100-form-btn" type="button">Login</button>
                </div>
                <div
                  className="container-login50-form-btn text-center p-r-10"
                  onClick={this.onLoginByTokenV2}
                >
                  <button className="login100-form-btn" type="button">Login By Token</button>
                </div>
                <div className={'text-center'}>
                  <Button
                      key="link"
                      type="link"
                      onClick={this.downloadPlugin}
                      className={'mt-3'}
                  >
                      <span>
                          <i className=" fa fa-download fa-lg"/>
                          Tải xuống plugin kết nối token
                      </span>
                  </Button>
                </div>
                <div className="flex-sb-m w-full p-b-24"/>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
