import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { convertJsonToQueryString } from './../../../helpers/NewCaHelper';

interface Props {
  btnClass?: string;
  btnName?: string;
  link?: any;
  onClick?: any;
  params?: any;
}
interface State {}

export default class ButtonLink extends Component<Props, State> {
  state = {};

  withParams = () => {
    let rObj = "";
    if (this.props.params) {
        rObj = convertJsonToQueryString(this.props.params)
    }
    return rObj;
  };
  render() {
    return (
      <a href={this.props.link + this.withParams() || "#"}>
        <button
          type="button"
          onClick={this.props.onClick}
          className={
            this.props.btnClass || "btn-submit-form btn btn-primary ml-1 mr-1"
          }
        >
          <i className="fas fa-angle-double-right mr-1"></i>
          {this.props.btnName}
        </button>
      </a>
    );
  }
}
