import FetchAPI from "../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../helpers/constant/MethodAPIConstant";

class ProviderCategoryService extends FetchAPI {
  public async list(data) {
    const result = await this.request(
      "cate/danh-muc-nha-cung-cap",
      data,
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async all() {
    const result = await this.request(
      "cate/danh-muc-nha-cung-cap/all",
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async find(id) {
    const result = await this.request(
      `cate/danh-muc-nha-cung-cap/find/${id}`,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async store(data) {
    const result = await this.request(
      "cate/danh-muc-nha-cung-cap/store",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

  public async update(data) {
    const result = await this.request(
      "cate/danh-muc-nha-cung-cap/update",
      data,
      TypeMethodContsantEnum.PATCH
    );
    return result;
  }

  public async delete(id) {
    const result = await this.request(
      "cate/danh-muc-nha-cung-cap/delete",
      { id },
      TypeMethodContsantEnum.DELETE
    );
    return result;
  }

}

export default ProviderCategoryService;
