import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/quan-ly-ho-so";
class DocLegalManager extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            `${PREFIX_URL}/index`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async previewFile(id,type) {
        const result = await this.request(
            `${PREFIX_URL}/previewFile/${id}/${type}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async onRefuse(data) {
        const result = await this.request(
            `${PREFIX_URL}/onRefuse`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async onApprove(id, typeD) {
        const result = await this.request(
            `${PREFIX_URL}/onApprove/${id}`,
            {typeD},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async onApproveSendFastCA(id, data) {
        const result = await this.request(
            `${PREFIX_URL}/onApproveSendFastCA/${id}`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async onApproveRequestUpload(id) {
        const result = await this.request(
            `${PREFIX_URL}/onApproveRequestUpload/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async onRefuseRequestUpload(id) {
        const result = await this.request(
            `${PREFIX_URL}/onRefuseRequestUpload/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getAllAgencyAndContributor() {
        const result = await this.request(
            `${PREFIX_URL}/allAgencyContributor`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async uploadFile(data) {
        const result = await this.request(
            `${PREFIX_URL}/uploadFile`,
            {data},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

}

export default DocLegalManager;
