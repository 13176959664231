import React, { Component } from 'react'
import { match, RouteComponentProps } from 'react-router'
import withNavbar from '../../../common/HOC/NavbarContainerHOC'
import UpdateUserForm from './UpdateForm'

interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams>{
    match:match<MatchParams>;
}
interface State {
}

class UpdateUser extends Component<Props, State> {
    state = {}

    componentDidMount(){
    }

    render() {
        return (
            <div className="card">
                <div className="card-title1 ml-3">Cập nhật quận huyện</div>
                <div className="card-body">
                    <UpdateUserForm history={this.props.history} id={this.props.match.params.id}></UpdateUserForm>
                </div>
            </div>
        )
    }
}

const updateUserWithNavBar = withNavbar()(UpdateUser);

export default updateUserWithNavBar;

