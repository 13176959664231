import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString,
    formatDateTimeAll,
    queryStringToJSON,
    checkPermission
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../stop-agency/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import { STATUS_LABEL_CLASS, STATUS_TYPE_CLASS } from "./StopCoopEnum";
import SelectBox from "../../common/form/SelectBox";
import StopAgencyService from "../../../services/stop-agency/StopAgencyService";
import SelectDate from "../../common/form/SelectDate";
import { Tag } from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Yêu cầu dừng hợp tác";
const CREATE_LIQUIDATION= 'create-liquidation';

const StopAgency: React.FC<Props> = props => {
    const service = new StopAgencyService();
    const [dataSearch, setDataSearch] = useState({sale_id: "", type_user: "", fullname:"", tax_code:"", created_at:""});
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at: dateString });
    };
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const onChangeDataSearchSale_Id = (e) => {
        setDataSearch({...dataSearch, 'sale_id': e});
    };

    const onChangeDataSearchTypeUser = (e) => {
        setDataSearch({...dataSearch, 'type_user': e});
    };

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickAddLiquidation={() =>
                    props.history.push(`/dung-hop-tac/them-moi/${record.id}`)
                }
                onClickUpdate={() =>
                    props.history.push(`/dung-hop-tac/cap-nhat/${record.id}`)
                }
                onClickView = {() =>
                    props.history.push(`/dung-hop-tac/chi-tiet/${record.id}`)
                }
                onClickUploadLiquidation = {() =>
                    props.history.push(`/dung-hop-tac/cap-nhat-bien-ban-thanh-ly/${record.id}`)
                }
                isUpdate={checkUpdateAble(record)}
                isUploadLiquidation={checkUploadLiquidationAble(record)}
                isAddLiquidation={checkAddLiquidationAble(record)}
            />
        );
    };
    const checkUpdateAble = (record: any): any => {
        if (record.status === 5) return true;
        return false;
    };
    const checkUploadLiquidationAble = (record: any): any => {
        if (record.file_type === 2 && record.status === 8) return true;
        return false;
    };
    const checkAddLiquidationAble = (record: any): any => {
        if (record.status === 3 && checkPermission(CREATE_LIQUIDATION)) return true;
        return false;
    };
    const [listSaleId, setListSaleId] = useState({});

    useEffect(() => {
        getListSaleUser();
        // eslint-disable-next-line
    }, []);

    const getListSaleUser = async () => {
        const result = await service.getListSaleUser();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setListSaleId(options);
    };
    const renderTypeOwner = (text, record, index) => {
        return (
            <Tag color={`${STATUS_TYPE_CLASS[text].class}`}>
                {STATUS_TYPE_CLASS[text].label}
            </Tag>
        );
    };
    const renderStatus = (text, record, index) => {
        return (
            <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
                {STATUS_LABEL_CLASS[text].label}
            </Tag>
        );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <SelectBox
                            name="sale_id"
                            options={listSaleId}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Chọn người quản lý"
                            onChange={onChangeDataSearchSale_Id}
                            value={dataSearch.sale_id}
                        />
                        <SelectBox
                            name="type_user"
                            options={{
                                1: "Đại lý",
                                2: "Cộng tác viên"
                            }}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Chọn loại người dùng"
                            onChange={onChangeDataSearchTypeUser}
                            value={dataSearch.type_user}
                        />
                        <InputWithLabel
                            name="fullname"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.fullname}
                            isRequired={false}
                            label={""}
                            placeholder="Tên đại lý hoặc CTV"
                        />
                        <InputWithLabel
                            name="tax_code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.tax_code}
                            isRequired={false}
                            label={""}
                            placeholder="Mã số thuế hoặc CMND"
                        />
                        <SelectDate
                            label=""
                            name="created_at"
                            wrappedClass="col-md-2 form-group nopadding-left"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-2 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Loại người dùng", dataIndex: "belong_to", render: renderTypeOwner},
                        {title: "Mã đại lý/CTV", dataIndex: "owner.code"},
                        {title: "Tên đại lý/CTV", dataIndex: "owner.fullname"},
                        {title: "Tên viết tắt", dataIndex: "owner.sortname"},
                        {
                            title: 'Mã số thuế/CMND',
                            dataIndex: '',
                            render: (e) => {
                                if (e) {
                                    if (e.belong_to === 1) {
                                        return e.owner.tax_code;
                                    } else {
                                        return e.owner.passport;
                                    }
                                }
                            }
                        },
                        {title: "Trạng thái", dataIndex: "status", render: renderStatus},
                        {title: "Người quản lý", dataIndex: "owner.manager.fullname"},
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTimeAll(text)
                        },

                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(StopAgency);
