import React, { Component } from "react";
import { History } from "history";
import "../css/main.css";
interface Props {
  history: History;
  url?: string;
}
interface State {}


export default class BackButton extends Component<Props, State> {
  state = {};

  onBackButton = e => {
    const link = this.props.url;
    e.preventDefault(this.props.url);
    if(link){
      this.props.history.push(link);
    }else{
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <button
        type="button"
        className="btn btn-secondary ml-1 mr-1 font-btn"
        onClick={this.onBackButton}
      >
        <i className="fas fa-arrow-left mr-1"></i>
        Trở lại
      </button>
    );
  }
}
