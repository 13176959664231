import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import swal from "sweetalert";
import { onFailAction } from "../../../common/swal/SwalCommon";
import SelectDate from "../../../common/form/SelectDate";
import CateService from "../../../../services/service/Service";

interface Props {
  id: string;
  history: History;
}
interface State {
  name: string;
  code: string;
  start_date: string;
  end_date: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    permissions: {},
    start_date: "",
    end_date: ""
  };

  componentDidMount() {
    this.props.setFieldValue("start_date", "");
    this.props.setFieldValue("end_date", "");
    this.getDataUpdate();
  }

  getDataUpdate = async () => {
    const service = new CateService();
    const result = await service.getService(this.props.id);
    if (result && result.status === 200) {
      const { name, code } = result.data;

      this.props.setValues({
        name,
        code
      } as Pick<State, keyof State>);

      let start_date = "";
      if (result.data.start_date !== null) {
        start_date = result.data.start_date;
      }
      let end_date = "";
      if (result.data.end_date !== null) {
        end_date = result.data.end_date;
      }
      this.props.setFieldValue("start_date", start_date);
      this.props.setFieldValue("end_date", end_date);
    }
  };

  onChangeSelectDateValid = (date, dateString) => {
    this.props.setFieldValue("start_date", dateString);
  };

  onChangeSelectDateRelease = (date, dateString) => {
    this.props.setFieldValue("end_date", dateString);
  };

  render() {
    const { name, code, start_date, end_date } = this.props.values;

    return (
      <form>
        <div className="input-group">
          <InputWithLabel
            name="name"
            label="Tên dịch vụ"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
            maxLength={255}
          />
          <InputWithLabel
            name="code"
            label="Mã dịch vụ"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
            maxLength={255}
          />
          <SelectDate
            label="Ngày hiệu lực"
            name="start_date"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateValid}
            value={start_date}
            error={this.props.errors.start_date}
            isRequired={true}
            placeholder={'yyyy-mm-dd'}
          />
          <SelectDate
            label="Ngày hết hiệu hành"
            name="end_date"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateRelease}
            value={end_date}
            error={this.props.errors.end_date}
            isRequired={true}
            placeholder={'yyyy-mm-dd'}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url="/dich-vu"></BackButton>
          <SubmitButton onClick={this.props.handleSubmit}></SubmitButton>
        </div>
      </form>
    );
  }
}

const Form = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: "",
    code: "",
    start_date: "",
    end_date: ""
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên dịch vụ không được bỏ trống."),
    code: Yup.string().required("Mã dịch vụ không được bỏ trống."),
    start_date: Yup.date().required("Ngày hiệu lực không được trống"),
    end_date: Yup.date().required("Ngày hết hiệu lực không được trống"),
  }),
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new CateService();
    const result = await service.updateService({
      ...values,
      permissions: "",
      id: props.id
    });
    if (result && result.status === 200) {
      swal({
        title: "Thành công!",
        text: "Cập nhật thành công!",
        icon: "success",
        dangerMode: false
      }).then(function() {
        props.history.goBack();
      });
    } else if (result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction(result.errors);
    }
  }
})(UpdateForm);

export default Form;
