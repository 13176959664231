import FetchAPI from '../../helpers/FetchAPI';
import {TypeMethodContsantEnum} from '../../helpers/constant/MethodAPIConstant';
import {permissionMenu} from '../../helpers/NewCaHelper';

class UserService extends FetchAPI {

    public async createUser(data) {
        return await this.request("user", data, TypeMethodContsantEnum.POST);
    }

    public async updateUser(data) {
        return await this.request("user-update", data, TypeMethodContsantEnum.POST);
    }

    public async deleteUserById(id) {
        return await this.request("user-delete", {id}, TypeMethodContsantEnum.GET);
    }

    public async getUser(id) {
        return await this.request("user-show", {id}, TypeMethodContsantEnum.GET);
    }

    public async currentUser() {
        const result = await this.request("/user-info", {}, TypeMethodContsantEnum.GET);
        result.data.permission_list = await permissionMenu(result.data.permission_list);
        return result;
    }

    public async changePassword(data) {
        return await this.request("change-password", data, TypeMethodContsantEnum.POST);
    }

    public async getUserManager() {
        return await this.request("user-manager", {}, TypeMethodContsantEnum.GET);
    }

    public async getUserByType(type) {
        return await this.request("user-by-type", {type}, TypeMethodContsantEnum.GET);
    }

    public getUsers(data: object) {
        return this.request("user", data, TypeMethodContsantEnum.GET);
    }

    public async loginByToken(data) {
        return await this.request("auth/login", data, TypeMethodContsantEnum.POST);
    }

    public async getAgencyContributor(type) {
        return await this.request("admin/agency-contributor-all", {type}, TypeMethodContsantEnum.GET);
    }

    public async getOwnerByBelongTo(belong_to) {
        return await this.request("admin/agency-contributor-nvkd-by-belong-to", {belong_to}, TypeMethodContsantEnum.GET);
    }

    public async activeUserById(id) {
        return await this.request("user-active", {id}, TypeMethodContsantEnum.GET);
    }

    public async resetPassword(id) {
        return await this.request("reset-password", {id}, TypeMethodContsantEnum.GET);
    }

    public async getCode(type) {
        return await this.request("get-code", {type}, TypeMethodContsantEnum.GET)
    }

    public async getNvkd(user_type, owner_id) {
        return await this.request("get-nvkd", {user_type, owner_id}, TypeMethodContsantEnum.GET)
    }

    public async getCodeUser(codeUser) {
        return await this.request("get-code-user", {codeUser}, TypeMethodContsantEnum.GET)
    }

}

export default UserService;