import React, { Component } from "react";
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import { Switch } from 'antd';
import "../css/main.css";

interface Props {
    label: string;
    name: string;
    wrappedClass: string;
    firstOptionLabel: string;
    onChange: any;
    value: any;
    error?: any;
    isRequired?: boolean;
}
interface State { }

export default class SelectSwitch extends Component<Props, State> {
    state = {};

    render() {
        return (
            <div className={this.props.wrappedClass}>

                {(this.props.label) ?
                    <LabelInput
                        nameFor={this.props.name}
                        label={this.props.label}
                        isRequired={this.props.isRequired}
                    />
                    : ''}

                <div>
                    <Switch checked={this.props.value} onChange={this.props.onChange} />
                </div>
                <ValidateMessageError error={this.props.error}></ValidateMessageError>
            </div>
        );
    }
}
