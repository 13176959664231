import React, { Component } from "react";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import "../css/main.css";
import { Select } from 'antd';

const { Option } = Select;


interface Props {
  options: any;
  label: string;
  name: string;
  wrappedClass: string;
  firstOptionLabel?: string;
  onChangeValue?: any;
  onChange?: any;
  value: any;
  error?: any;
  isRequired?:boolean;
  checkUpdate? : boolean;
}
interface State {}

export default class SelectUserManager extends Component<Props, State> {
  state = {};
  render() {
    const { options } = this.props;
    const isUpdate = this.props.checkUpdate ? true: false
    return (
      <div className={this.props.wrappedClass}>
        <Select className="font-input"
          dropdownStyle={{ width: 200 }}
          placeholder={this.props.firstOptionLabel}
          onChange={this.props.onChange}
          allowClear={true}
          value={isUpdate ? (this.props.value ? String(this.props.value) : '') : (this.props.value ? String(this.props.value) : this.props.firstOptionLabel)}
        >
          {Object.keys(options).map(key => (
            <Option key={key} value={key} title={options[key]} className={this.props.name}>
              {options[key]}
            </Option>
          ))}
        </Select>
       <ValidateMessageError error={this.props.error}></ValidateMessageError>
      </div>
    );
  }
}

