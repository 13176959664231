import React from "react";

interface Props {
    onClick: () => void;
    tittle? : string;
    icon? : string;
    clName?: string;
}

export default function ActionButtonHandle(props: Props) {
    return (
        <button
            className={(props.clName) ? props.clName : "pointer text-primary ml-1 mr-1"}
            title={(props.tittle) ? props.tittle : ""}
            onClick={props.onClick}
        >
            <i className={(props.icon) ? props.icon : ""} />
        </button>
    );
}
