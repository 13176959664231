import React, { Component } from "react";
import Navbar from "../../navbar/Navbar";
import SideNavPage from "../../side-nav/SideNavPage";
import SideNavContext from "../../side-nav/SideNavContext";

const withNavbar = () => WrappedComponent => {
  return class NavbarContainer extends Component {
    render() {
      return (
        <React.Fragment>
          <Navbar {...this.props} />
          <SideNavContext.Consumer>
            {({ isExpand, toogleExpand }) => (
              <SideNavPage
                {...this.props}
                isExpand={isExpand}
                onToogleSide={toogleExpand}
              >
                <WrappedComponent {...this.props} />
              </SideNavPage>
            )}
          </SideNavContext.Consumer>
        </React.Fragment>
      );
    }
  };
};

export default withNavbar;
