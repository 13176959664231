import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString, formatDateTime,
    queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import RequestDocumentService from "../../../services/request-document/RequestDocumentService";
import TableActionButton from "../user/table/action-button/TableActionButton";
import SelectDate from "../../common/form/SelectDate";

interface Props extends RouteComponentProps {
}

const TITLE = "Yêu cầu tài liệu bán hàng";

const RequestDocument: React.FC<Props> = props => {
    const service = new RequestDocumentService();
    const [dataSearch, setDataSearch] = useState({created_by: "", belong_to:"", owner:"", created_at:"", status:""});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({...dataSearch, created_at: dateString});
    };
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const [listSaleId, setListSaleId] = useState({});
    useEffect(() => {
        getListSaleUser();
        // eslint-disable-next-line
    }, []);

    const getListSaleUser = async () => {
        const result = await service.getListUser();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setListSaleId(options);
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickView={() =>
                    props.history.push(`/yeu-cau-tai-lieu-ban-hang/chi-tiet/${record.id}`)
                }
            />
        );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <SelectBox
                            name="belong_to"
                            options={{1:'Đại lý', 2:'Cộng tác viên'}}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Loại người dùng"
                            onChange={onChangeDataSearchInput}
                            value={dataSearch.belong_to}
                        />
                        <InputWithLabel
                            name="owner"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.owner}
                            isRequired={false}
                            label={""}
                            placeholder="Tên đại lý/CTV"
                        />
                        <SelectBox
                            name="status"
                            options={{1:'Nháp', 2:'chờ HTKD  duyệt', 3:' HTKD từ chối',4:'Chờ kế toán duyệt',5:'Kế toán từ chối',6:'Hoàn thành'}}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Trạng thái"
                            onChange={onChangeDataSearchInput}
                            value={dataSearch.status}
                        />
                        <SelectBox
                            name="created_by"
                            options={listSaleId}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Người yêu cầu"
                            onChange={onChangeDataSearchInput}
                            value={dataSearch.created_by}
                        />
                        <SelectDate
                            label=""
                            name="created_at"
                            wrappedClass="col-md-2 form-group"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-2 form-group nopadding-left">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Tên đại lý/CTV",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.agency) return e.agency.fullname;
                                    if (e.contributor) return e.contributor.fullname;
                                }
                            }
                        },
                        {
                            title: "Loại người dùng",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.belong_to === 1) return 'Đại lý';
                                    if (e.belong_to === 2) return 'Cộng tác viên';
                                }
                            }
                        },
                        {
                            title: "MST/CMND",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.agency) return e.agency.tax_code;
                                    if (e.contributor) return e.contributor.passport;
                                }
                            }
                        },
                        {title: "Người yêu cầu", dataIndex: "user.fullname"},
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: (e) => {
                                if(e.status === 4){
                                    return (<span className="badge badge-warning w-100">Chờ kế toán duyệt</span>);
                                }
                                if(e.status === 5){
                                    return (<span className="badge badge-danger w-100">Kế toán từ chối</span>);
                                }
                                if(e.status === 6){
                                    return (<span className="badge badge-success w-100">Hoàn thành</span>);
                                }
                            }
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(RequestDocument);
