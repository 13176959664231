import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import SelectBox from "../../../common/form/SelectBox";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import { TypeUser } from "../UserEnum";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import RoleService from "../../../../services/role/RoleServices";
import UserService from "../../../../services/user/UserService";
import swal from "sweetalert";
import { onFailAction } from "../../../common/swal/SwalCommon";

interface Props {
  id: string;
  history: History;
}
interface State {
  username: string;
  email: string;
  phone: string;
  code: string;
  fullname: string;
  type: number;
  role: string;
  token_agency: string;
  level_agency: string;
  permissions: Object;
  owner_id: string;
  agency: Object;
}

class UpdateUserForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    permissions: {},
    agency: {}
  };

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    const userService = new UserService();
    const user = await userService.getUser(this.props.id);
    if (user && user.status === 200) {
      const {
        username,
        email,
        fullname,
        token_agency,
        phone,
        code,
        type,
        owner_id,
        level_agency,
      } = user.data;
      const role = user.user_role;
      this.props.setValues({
        username,
        email,
        fullname,
        token_agency,
        phone,
        code,
        type,
        level_agency,
        owner_id,
      } as Pick<State, keyof State>);
      await this.getRoleList(type);
      await this.getAgency(type);
      this.props.setFieldValue('role', role);
    }
  };

  getRoleList = async type => {
    const roleService = new RoleService();
    const lstRole = await roleService.getRoleList(type);
    if (lstRole) {
      let permissions = {};
      lstRole.data.map(value => (permissions[value.id] = value.name));
      this.setState({ permissions });
      this.props.setFieldValue('role', '');
    }
  };

  //Lấy dữ liệu cộng tác viên hoặc đại lý
  getAgency = async type => {
    const userService = new UserService();
    const lst = await userService.getAgencyContributor(type);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      this.setState({ agency });
    }
  };

  onChangeSelectedRole = e => {
    this.props.setFieldValue("type",e)
    this.getRoleList(e);
    this.getAgency(e);
  };

  handleChangeBoxAgency = e => {
    this.props.setFieldValue("owner_id", e);
  }

  handleChangeLevelAgency = async e => {
    this.props.setFieldValue('level_agency', e)
  }

  changeRole = (e) => {
    this.props.setFieldValue("role",e)
  }

  render() {
    const {
      username,
      email,
      code,
      phone,
      fullname,
      token_agency,
      type,
      role,
      level_agency,
      owner_id
    } = this.props.values;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="input-group">
          <InputWithLabel
            name="username"
            label="Tên đăng nhập"
            wrapClass="col-md-2 form-group"
            onChangeValue={this.props.handleChange}
            value={username}
            error={this.props.errors.username}
            isRequired={true}
            isDisabled={true}
            maxLength={255}
          />
          <InputWithLabel
            name="code"
            label="Mã tk"
            wrapClass="col-md-1 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
            isDisabled={true}
            maxLength={255}
          />
          <InputWithLabel
            name="token_agency"
            label="Token đăng nhập"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={token_agency}
            error={this.props.errors.token_agency}
            isRequired={false}
            maxLength={255}
          />
          <InputWithLabel
            name="fullname"
            label="Tên đầy đủ"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={fullname}
            error={this.props.errors.fullname}
            isRequired={true}
            maxLength={255}
          />
          <InputWithLabel
            name="email"
            label="Email"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={email}
            error={this.props.errors.email}
            isRequired={true}
            maxLength={255}
          />
        </div>
        <div className="input-group">
          <InputWithLabel
              name="phone"
              label="Số điện thoại"
              wrapClass="col-md-3 form-group"
              onChangeValue={this.props.handleChange}
              value={phone}
              error={this.props.errors.phone}
              isRequired={false}
              maxLength={50}
          />
          <SelectBox
            options={TypeUser.TYPE}
            label="Loại người dùng"
            name="type"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectedRole}
            value={type}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={true}
          />
          <SelectBox
            options={this.state.permissions}
            label="Vai trò"
            name="role"
            wrappedClass={Number(type) === 7 ? "col-md-2 form-group" : "col-md-3 form-group"}
            onChange={this.changeRole}
            value={role}
            firstOptionLabel="Chọn vai trò"
            error={this.props.errors.role}
            isRequired={true}
          />
          {Number(type) === 7 ? (
            <SelectBoxAgency
                options={{1: "Admin", 2: "Cấp 2"}}
                label="Phân quyền"
                name="level_agency"
                wrappedClass="col-md-2 form-group"
                onChange={this.handleChangeLevelAgency}
                value={level_agency}
                firstOptionLabel="Chọn cấp"
                error={this.props.errors.level_agency}
                isRequired={true}
            />
          ) : ""}
          <SelectBoxAgency
            options={this.state.agency}
            label="Đại lý/cộng tác viên"
            name="owner_id"
            wrappedClass={Number(type) === 7 ? "col-md-2 form-group" : "col-md-3 form-group"}
            onChange={this.handleChangeBoxAgency}
            value={owner_id}
            firstOptionLabel="Chọn đại lý/cộng tác viên"
            error={this.props.errors.owner_id}
            isRequired={false}
          />
        </div>
        <WrapperButtonDiv>
          <BackButton history={this.props.history} url='/quan-ly-nguoi-dung'/>
          <SubmitButton onClick={this.props.submitForm}/>
        </WrapperButtonDiv>
      </form>
    );
  }
}

const userUpdateForm = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    username: "",
    code: "",
    email: "",
    fullname: "",
    token_agency: "",
    phone: "",
    type: 0,
    role: "",
    permissions: "",
    owner_id: "",
    level_agency: "",
    agency: ""
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required("Tên đăng nhập không được bỏ trống."),
    email: Yup.string()
      .required("Email không được bỏ trống.")
      .email("Email không đúng định dạng."),
    fullname: Yup.string().required("Tên người dùng không được bỏ trống."),
    type: Yup.number().required("Loại người dùng không được bỏ trống."),
    role: Yup.number().required("Chức vụ người dùng không được bỏ trống."),
    // level_agency: Yup.number().required("Phân quyền đại lý không được bỏ trống.")
  }),

  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const userService = new UserService();
    const result = await userService.updateUser({ ...values, permissions: '', id: props.id });
    if (result && result.status === 200) {
      swal({
        title: "Thành công!",
        text: "Cập nhật thành công!",
        icon: "success",
        dangerMode: false
      }).then(function () {
        props.history.goBack();
      });
    } else if (result && result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction('Có lỗi xảy ra lấy dữ liệu');
    }
  },

})(UpdateUserForm);

export default userUpdateForm;
