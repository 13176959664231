import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import TokenManagerService from "../../../../services/token-manager/TokenManagerService";
import TableAddRowTokenManager from "../../../common/table/TableAddRowTokenManager";

interface FormValues {
    code: string;
    version_id: string;
    sopin: string;
    belong_to: string;
    owner_id: string;
    cts_status: string;
    token_status: string;
    is_tms: string;
    data_customer: object;
    type: any;
}

interface OtherProps extends RouteComponentProps<{
    id: string;
}> {
}

type Props = OtherProps & FormikProps<FormValues>;

const TokenManagerView: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [dataCustomer, setDataCustomer] = useState({});
    const [keyDelete, setKeyDelete] = useState({});
    const [hidden, setHidden] = useState(true);

    const onChangeSelectReceiveDate = (date, dateString, id) => {
        dataCustomer[id]['setting_date'] = dateString;
        setDataCustomer(dataCustomer);
        props.setFieldValue("data_customer", dataCustomer);
    };

    const [ownerList, setOwnerList] = useState({});
    const [versionTokenList, setVersionTokenList] = useState({});

    const {
        code,
        version_id,
        sopin,
        belong_to,
        owner_id,
        cts_status,
        token_status,
        data_customer,
        type,
        is_tms
    } = props.values;

    const fetchOwnerOption = async (e) => {
        let belong_to = e.target.value;
        const service = new TokenManagerService();
        const result = await service.getOwner(belong_to);
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setOwnerList(options);
        props.setFieldValue('belong_to', belong_to);
    };

    const fetchVersionTokenOption = async () => {
        const service = new TokenManagerService();
        const result = await service.getVersionToken();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.code;
        });
        setVersionTokenList(options);
    };

    const onFetchData = async () => {
        const service = new TokenManagerService();
        const tokenManager = await service.find(id);
        if (tokenManager.data['cts_status'] === 1 || (tokenManager.data['cts_status'] === 3 && tokenManager.data.token_cts)){
            setHidden(false);
        }
        for (let key in tokenManager.data) {
            props.setFieldValue(key, tokenManager.data[key]);
        }
        props.setFieldValue('data_customer', tokenManager.data['token_cts']);
        setDataCustomer(tokenManager.data['token_cts']);
        getOwner(tokenManager.data['belong_to']);
    };

    useEffect(() => {
        onFetchData();
        fetchVersionTokenOption();
        // eslint-disable-next-line
    }, []);

    const changeDataCustomer = e => {
        let key = $(e.target)
            .parent()
            .parent()
            .parent()
            .data("row-key");
        let value = e.target.value;
        dataCustomer[key][$(e.target).data("key")] = value;
        setDataCustomer(dataCustomer);
        props.setFieldValue("data_customer", dataCustomer);
    };

    const onClickDeleteDoc = () => {
        let newDataDelete = removeObjectByKey(dataCustomer);
        const arr: string[] = [];
        $.each(newDataDelete, function(key, value) {
            if (value) {
                arr.push(value);
            }
        });
        setDataCustomer(arr);
        props.setFieldValue("data_customer", arr);
    };

    const removeObjectByKey = arr => {
        delete arr[Number(keyDelete)];
        return arr;
    };

    const getKeyDelete = e => {
        let key = $(e.target)
            .parent()
            .parent()
            .parent()
            .data("row-key");
        setKeyDelete(key);
    };

    const getOwner = async (belong_to) => {
        const service = new TokenManagerService();
        const result = await service.getOwner(belong_to);
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setOwnerList(options);
    };
    const setDataFirst = () => {
        const dataFirst = [
            {
                key: 0,
                serial:"",
                tax_code:"",
                customer_name:"",
                setting_date:"",
            }
        ];
        setDataCustomer(dataFirst);
        props.setFieldValue("data_customer", dataFirst);
    };

    const changeCtsStatus = (e) => {
        if (e.target.value && e.target.value === 1){
            setHidden(false);
            if (Object.keys(data_customer).length === 0) {
                setDataFirst();
            }
        }else {
            setHidden(true);
        }
        props.setFieldValue("cts_status", e.target.value);
    };

    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Xem chi tiết token</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            label="Mã token"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="version_id"
                            options={versionTokenList}
                            label="Version token"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Version token --"
                            onChange={props.handleChange}
                            value={version_id}
                            error={props.errors.version_id}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="sopin"
                            label="SOPIN"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={sopin}
                            error={props.errors.sopin}
                            isRequired={true}
                            maxLength={255}
                            type="text"
                            isDisabled={true}
                        />
                        <SelectBox
                            name="type"
                            options={{1:'Token RA', 2:'Token CTS'}}
                            label="Loại token"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Loại Token --"
                            onChange={props.handleChange}
                            value={type}
                            error={props.errors.type}
                            isRequired={true}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <SelectBox
                            name="belong_to"
                            options={{
                                1: 'Đại lý',
                                2: 'Cộng tác viên'
                            }}
                            label="Loại người dùng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Chọn đại lý/CTV --"
                            onChange={fetchOwnerOption}
                            value={belong_to}
                            error={props.errors.belong_to}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="owner_id"
                            options={ownerList}
                            label="Đại lý/CTV"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Chọn đại lý/CTV --"
                            onChange={props.handleChange}
                            value={owner_id}
                            error={props.errors.owner_id}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="cts_status"
                            options={{
                                1: "Đã cấp phát",
                                2: "Chưa cấp phát",
                                3: "Mở generate"
                            }}
                            label="Tình trạng CTS"
                            wrappedClass="col-md-1"
                            firstOptionLabel="-- Chọn tình trạng CTS --"
                            onChange={changeCtsStatus}
                            value={cts_status}
                            error={props.errors.cts_status}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="is_tms"
                            options={{
                                1: "Có",
                                2: "Không",
                            }}
                            label="Đánh dấu Token thuộc TMS"
                            wrappedClass="col-md-2"
                            firstOptionLabel="-- Chọn đánh dấu Token thuộc TMS --"
                            onChange={props.handleChange}
                            value={is_tms}
                            error={props.errors.is_tms}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="token_status"
                            options={{
                                1: "Đã khóa",
                                2: "Chưa khóa"
                            }}
                            label="Tình trạng USB token"
                            wrappedClass="col-md-3"
                            firstOptionLabel="-- Chọn tình trạng USB token --"
                            onChange={props.handleChange}
                            value={token_status}
                            error={props.errors.token_status}
                            isRequired={true}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group col-md-12 m-t-20" hidden={hidden}>
                        <div className="col-md-11 p-l-0">
                        </div>
                        <TableAddRowTokenManager
                            errors={props.errors}
                            data={dataCustomer}
                            changeData={changeDataCustomer}
                            onDelete={onClickDeleteDoc}
                            keyDelete={getKeyDelete}
                            changeSelectDate={onChangeSelectReceiveDate}
                            isDisabled={true}
                            isHidden={true}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            // url="/quan-ly-token"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        version_id : "",
        sopin : "",
        belong_to : "",
        owner_id : "",
        cts_status : "",
        token_status : "",
        data_customer : {},
        type: "",
        is_tms: "",
    }),
    validationSchema: Yup.object().shape({
        code: Yup.string().required("Mã token không được bỏ trống."),
        version_id: Yup.string().required("Version token không được bỏ trống."),
        sopin: Yup.string().required("SOPIN không được bỏ trống."),
        belong_to: Yup.string().required("Loại người dùng không được bỏ trống."),
        owner_id: Yup.string().required("Đại lý/CTV không được bỏ trống."),
        cts_status: Yup.string().required("Trạng thái CTS không được bỏ trống."),
        token_status: Yup.string().required("Tình trạng USB token không được bỏ trống."),
        is_tms: Yup.string().required("Đánh dấu Token thuộc TMS không được bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new TokenManagerService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result && result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result && result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction('Có lỗi trong quá trình cập nhật');
        }
    }
})(TokenManagerView);

export default withNavbar()(RenderCreateForm);
