import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {onFailAction, onSuccessAction, onWarningAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import ModalView from "../../../common/form/Modal";
import RequestCertificateService from "../../../../services/request-certificate/RequestCertificateService";
import Radio from "../../../common/form/Radio";
// import DenyButton from "../../../common/button/DenyButton";
import SubmitButton from "../../../common/form/SubmitButton";
import ModalDeny from "../../../common/form/ModalDeny";
import {Card} from "antd";
import SelectDate from "../../../common/form/SelectDate";
import InputFileUploadPreview from "../../../common/form/InputFileUploadPreview";
import {loading} from "../../../common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";
import SelectBox from "../../../common/form/SelectBox";
import _ from "lodash";
import CateContractService from "../../../../services/category-contract/CateContractService";
import {formatMoney, handleFormatString} from "../../../../helpers/NewCaHelper";
import TextAreaWithLabel from "../../../common/form/TextAreaWithLabel";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface FormValues {
    passport: string,
    passport_date: string,
    passport_place: string,
    tax_code: string,
    passport_file: string,
    fullname: string,
    phone: string,
    email: string,
    address: string,
    is_organization: number,
    organization_name: string,
    organization_department: string,
    organization_tax_code: string,
    organization_position: string,
    organization_email: string,
    organization_phone: string,
    organization_address: string,
    organization_file: string,
    sp_fullname: string,
    sp_position: string,
    promotion_code: string,
    sp_email: string,
    sp_phone: string,
    type_device: number,
    object: number,
    package_id: string,
    package_price: string,
    file_register: string,
    support_register: number,
    status: number,
    reason: string,
    file: string,
    secret_code: string,
    password: string,
    type_docurment: number,
    province_code: string,
    district_code: string,
    file_register_paper,
    serial_cts: string,
    token_type: number,
    provider_id: number,
    identity_type: number,
    quantity: number,
    version_token_newtel: number,
    hsm_provider: number,
    date_expired_cert: string,
    type_extend?:number

}

interface OtherProps extends RouteComponentProps<{ id: string; }> {
}

type Props = OtherProps & FormikProps<FormValues>;
const RequestCertificateViewPersonal: React.FC<Props> = props => {
    const service = new RequestCertificateService();
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };

    const {
        passport,
        passport_date,
        passport_place,
        tax_code,
        fullname,
        phone,
        email,
        address,
        is_organization,
        organization_name,
        organization_department,
        organization_tax_code,
        organization_position,
        organization_email,
        organization_phone,
        organization_address,
        package_price,
        status,
        reason,
        secret_code,
        type_docurment,
        province_code,
        district_code,
        package_id,
        serial_cts,
        token_type,
        provider_id,
        identity_type,
        quantity,
        promotion_code,
        version_token_newtel,
        password,
        hsm_provider,
        date_expired_cert,
        type_extend
    } = props.values;

    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });
    // const [document, setDocument] = useState(0);
    const [statusGen, setStatusGen] = useState(false);
    const [statusDK03, setStatusDK03] = useState(false);
    const [passportFile, setPassportFile] = useState("");
    const [organFile, setOrganFile] = useState("");
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [isOrgan, setIsOrgan] = useState(0);
    const [objectRequest, setObjectRequest] = useState(0);
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState([]);
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [fileRegisterPaper, setFileRegisterPaper] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Chứng minh nhân dân");
    const [versionToken, setVerionToken] = useState({});
    const [linkReSign, setLinkReSign] = useState(false);
    const [link, setLink] = useState("");
    const [secretCode, setSecretCode] = useState("");
    const [typeDevice, setTypeDevice] = useState(0);

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.getPersonalRequest(id);
        if(result.isExit === true){
            const sameIden = result.sameIden.toString();
            const txt = "Yêu cầu này đang trùng với yêu cầu:\n" + sameIden;
            onWarningAction(txt)
        }
        if (result.data.status === 3 ||result.data.status === 4 || result.data.status === 5 || result.data.status === 6){
            setIsDisable(true);
        }
        setIsOrgan(result.data.requestpersonal.is_organization);
        setObjectRequest(result.data.object);
        setTypeDevice(result.data.type_device);
        // setDocument(result.data.file_status);
        if(Number(result.data.status) === 5){
            setStatusGen(true);
        }
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        for (let key in result.data.requestpersonal) {
            props.setFieldValue(key, result.data.requestpersonal[key]);
        }
        if (Number(result.data.support_register) === 1) {
            for (let key in result.data.requestsupport) {
                props.setFieldValue(`sp_${key}`, result.data.requestsupport[key]);
            }
        }
        for (let key in result.data.cateservicespackage) {
            props.setFieldValue(key, result.data.cateservicespackage[key]);
        }
        if(Number(result.data.requestpersonal.identity_type) === 1){
            setLabelCode("Chứng minh nhân dân");
        }if(Number(result.data.requestpersonal.identity_type) === 2){
            setLabelCode("Hộ chiếu");
        }if(Number(result.data.requestpersonal.identity_type) === 3){
            setLabelCode("Căn cước công dân");
        }if(Number(result.data.requestpersonal.identity_type) === 4){
            setLabelCode("Mã bảo hiểm xã hội");
        }if(Number(result.data.requestpersonal.identity_type) === 5){
            setLabelCode("Mã nhân viên");
        }
        let arrAll:any = [];
        result.data.list_document.forEach(function (values) {
            if (Number(values.type) === 7 ){
                setLinkReSign(true);
                setLink(result.domain+"/re-sign-dk02/" + btoa(id) +"/1")
                setSecretCode(result.data.password);
            }
            arrAll[values.id] = values.type;
            if (values.type === 4){
                const arrName = values.file.split('/');
                setFileRegisterPaper(arrName['3']);
                props.setFieldValue("file_register_paper", arrName['3']);
            }
            if (values.type === 1){
                const arrName = values.file.split('/');
                setPassportFile(arrName['3']);
                props.setFieldValue("passport_file", arrName['3']);
            }
            if (values.type === 2){
                const arrName = values.file.split('/');
                setOrganFile(arrName['3']);
                props.setFieldValue("organization_file", arrName['3']);
            }
        });
        arrAll.forEach(function(item, index, array) {
            if(Number(item) === 7){
                setStatusGen(true);
            }
            if(Number(item) === 14){
                setStatusDK03(true);
            }
        });
        const options = {};
        options[result.data.district.newtel_district_code] = result.data.district.fullname;
        setDistrict(options);

        let object = 1;
        if(Number(result.data.requestpersonal.is_organization) === 1){
            object = 2;
        }
        const type_search = 4;
        const type = result.data.object; //Đối tượng
        const type_device = result.data.type_device;
        const data = await getCateServicePackage(type_search, type, object, type_device, result.data.provider_id, result.data.hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.setFieldValue("object",result.data.object);
        props.setFieldValue("package_price",formatMoney(result.data.package_price));
        loading.stopRunLoading();
    };
    const getAllProvider = async () =>{
        const data = await service.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await service.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    const onChangeVersionToken = async value => {
        props.setFieldValue('version_token_newtel',value);
    };
    useEffect(() => {
        onFetchData();
        getProvince();
        getAllProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);
    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false})
    };

    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim() === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.getDeny(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/yeu-cau-chung-thu-so");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };

    // const submitDeny = () => {
    //     setModalDeny({...modalDeny, 'visible_modal_deny': true});
    // };

    // const submitAccept = (type) => {
    //     let title = "Bạn có chắc chắn muốn duyệt yêu cầu ?"
    //     if(type === 2){
    //         title = "Bạn có chắc chắn muốn sửa và duyệt yêu cầu ?"
    //     }
    //     onAcceptRequestCTS(async willDelete => {
    //         if (willDelete) {
    //             try {
    //                 submitApprove(type)
    //             } catch (error) {
    //                 console.log(error);
    //         onFailAction();
    //             }
    //         }
    //     }, title);
    // };

    const onViewFileDoc = async type => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token){
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/yeu-cau-chung-thu-so/getFileDoc/${password}/${type}?token=${obj.token}`;
                window.open(link);
            }

        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    const onChangeSelectPassportDate = (date, dateString) => {
        props.setFieldValue("passport_date", dateString);
        setDataChanged(true);
    };
    const onChangePassportFile = async e => {
        if(e){
            setDataChanged(true);
        }
        const files = e.target.files[0];
        if (files) {
            const fileSize = files.size/1024/1024;
            if(fileSize>2){
                onFailAction("File tải lên không thể lớn hơn 2MB", () => {
                    setPassportFile("");
                    props.setFieldValue("passport_file", '');
                });
            }else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    setPassportFile(fileData.file);
                    props.setFieldValue("passport_file", fileData.file);
                }
                loading.stopRunLoading();
            }
        } else {
            setPassportFile("");
            props.setFieldValue("passport_file", '');
        }
    };
    const onChangeOrganFile = async e => {
        if (e){
            setDataChanged(true);
        }
        const files = e.target.files[0];
        if (files) {
            const fileSize = files.size/1024/1024;
            if(fileSize>2){
                onFailAction("File tải lên không thể lớn hơn 2MB", () => {
                    setOrganFile("");
                    props.setFieldValue("organization_file", '');
                });
            }else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    setOrganFile(fileData.file);
                    props.setFieldValue("organization_file", fileData.file);
                }
                loading.stopRunLoading();
            }
        } else {
            setOrganFile("");
            props.setFieldValue("organization_file", '');

        }
    };
    const onClickModalFile = async type => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token){
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/yeu-cau-chung-thu-so/getFileDoc/${password}/${type}?token=${obj.token}`;
                window.open(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    const getProvince = async  () =>{
        const province = await service.getProvince();
        const options = {};
        province.forEach(element => {
            options[element.newtel_code] = element.fullname;
        });
        setProvince(options);

    };
    const onChangeProvince = async (e) =>{
        if(e){
            setDataChanged(true);
            props.setFieldValue('province_code',e);
            const district = await service.getDistrictByProvince(e);
            const options = {};
            district.forEach(element => {
                options[element.newtel_district_code] = element.fullname;
            });
            setDistrict(options);

        } else{
            setDistrict({});
        }
        props.setFieldValue('district_code',undefined);
    };
    const onChangeDistrict = async (e) =>{
        setDataChanged(true);
        props.setFieldValue('district_code',e);
    };
    const onChangIsOrgan = async e =>{
        setDataChanged(true);
        // props.setFieldValue( 'object', undefined);
        props.setFieldValue( 'type_device', undefined);
        props.setFieldValue( 'package_id', undefined);
        props.setFieldValue( 'package_price', undefined);
        props.setFieldValue( 'is_organization', e.target.value);
        setIsOrgan(e.target.value);
    };
    const onChangeObjectRequest = async (e) =>{
        setDataChanged(true);
        props.setFieldValue( 'object', e.target.value);
        setObjectRequest(e.target.value);
        props.setFieldValue( 'type_device', undefined);
        props.setFieldValue( 'package_id', undefined);
        props.setFieldValue( 'package_price', undefined);
    };
    const onChangeTypeDevice = async (e) =>{
        setDataChanged(true);
        props.setFieldValue( 'type_device', e.target.value);
        props.setFieldValue( 'package_id', undefined);
        props.setFieldValue( 'package_price', undefined);
        props.setFieldValue( 'version_token_newtel', undefined);
        loading.runLoadingBlockUI();
        let object = 1;
        if(Number(isOrgan) === 1){
            object = 2;
        }
        const type_search = 4;
        const type = objectRequest; //Đối tượng
        const type_device = e.target.value;
        const data = await getCateServicePackage(type_search, type, object, type_device, provider_id, hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        loading.stopRunLoading();
    };

    const getCateServicePackage = async (type_search, type, obj, type_device, provider_id,hsm_provider) =>{
        return await service.getListCateServicePackage(type_search, type, obj,type_device, provider_id, hsm_provider);
    };
    const onChangePackage = async value => {
        setDataChanged(true);
        props.setFieldValue('package_id',value);
        const selectdCate: any = _.find(cateServicePackageAmount, { id: parseInt(value) });
        if(selectdCate){
            props.setFieldValue( 'package_price', formatMoney(selectdCate.price));
        }
    };
    const onPreviewFile = async () =>{
        const values = props.values;
        const result = await service.previewFileDk0102({
            ...values,
            id: id,
        });
        if (result && result.status === 200) {
            setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'File pháp lý','type_file': 'pdf'}) ;
            return true;
        } else {
            onFailAction("Có lỗi xảy ra khi xem trước file!");
            Object.keys(result.error).map(function(key) {
                props.setFieldError(key, result.error[key]);
                return false;
            });
        }
    };
    // const  submitApprove = async (type) => {
    //     //type = 1 : Approve
    //     //type = 2 : Update and Approve
    //     if (Number(type) === 1){
    //         const data = {document, id};
    //         try {
    //             const result = await service.getApprove(data);
    //             if (result && result.status === 200) {
    //                 onSuccessAction("Duyệt thành công!", () => {
    //                     props.history.push("/yeu-cau-chung-thu-so");
    //                 });
    //             } else if (result && result.status === 404) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             } else if (result && result.status === 0){
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }else if (!result){
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }else if (result.error) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //                 Object.keys(result.error).map(function(key) {
    //                     props.setFieldError(key, result.error[key]);
    //                     return false;
    //                 });
    //             }else {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }
    //         }catch (e) {
    //             onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //         }
    //     }
    //     if(Number(type) === 2){
    //         const dataValue = props.values;
    //         const isUpdateApprove = 1;
    //         const datas = {...dataValue,isUpdateApprove, document, id};
    //         try {
    //             const result = await service.getUpdateAndApprovePersonal(datas);
    //             if (result && result.status === 200) {
    //                 onSuccessAction("Duyệt thành công!", () => {
    //                     props.history.push("/yeu-cau-chung-thu-so");
    //                 });
    //             } else if (result && result.status === 404) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             } else if (result && result.status === 0) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             } else if (result.error) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //                 Object.keys(result.error).map(function (key) {
    //                     props.setFieldError(key, result.error[key]);
    //                     return false;
    //                 });
    //             }else if (!result){
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }else {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }
    //         }catch (e) {
    //             onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //         }
    //     }
    // };
    const onChangeDataField = async e =>{
        if (e){
            setDataChanged(true);
        }
    };
    // const onChangeSupportRegister = async e =>{
    //     if (e){
    //         props.setFieldValue('support_register', e.target.value);
    //         setDataChanged(true);
    //     }
    // };
    const onChangeTypeDoc = async e =>{
        if (e){
            props.setFieldValue('type_docurment', e.target.value);
            setDataChanged(true);
        }
    };
    const onChangeFileDk0102 = async e => {
        const files = e.target.files[0];
        if (files) {
            const fileSize = files.size/1024/1024;
            if(fileSize>2){
                onFailAction("File tải lên không thể lớn hơn 2MB", () => {
                    setFileRegisterPaper("");
                    props.setFieldValue("file_register_paper", '');
                });
            }else {
                const formData = new FormData();
                formData.append("file", files);
                loading.runLoadingBlockUI();
                const fileData = await BaseServices.axiosUpLoadFile(formData);
                if (fileData) {
                    setFileRegisterPaper(fileData.file);
                    props.setFieldValue("file_register_paper", fileData.file);
                }
                loading.stopRunLoading();
            }
        } else {
            setFileRegisterPaper('');
            props.setFieldValue("file_register_paper", "");
        }
    };
    const onChangeFormatFullname = (e) =>{
        const strFormart = handleFormatString(e.target.value, 3);
        props.setFieldValue('fullname',  strFormart);
        setDataChanged(true);
    };
    const onChangeFormatOrganName = (e) =>{
        const strFormart = handleFormatString(e.target.value, 3);
        props.setFieldValue('organization_name',  strFormart);
        setDataChanged(true);
    };
    // const onChangeFormatSupportName = (e) =>{
    //     const strFormart = handleFormatString(e.target.value, 3);
    //     props.setFieldValue('sp_fullname',  strFormart);
    //     setDataChanged(true);
    // };
    const onChangePrice = (e) =>{
        const price = formatMoney(e.target.value);
        props.setFieldValue('package_price', price)
        setDataChanged(true);
    };
    const sendMailReSign = async () => {
        const dataSend = {
            id: id,
            link: link
        };
        const result = await service.sendMailReSign(dataSend);
        if (result && result.status === 200) {
            onSuccessAction("Gửi mail thành công", () => {
                props.history.goBack();
            })
        }
    };
    const onChangeSelectDateExpiredDate = (date, dateString) => {
        setDataChanged(true);
        props.setFieldValue("date_expired_cert", dateString);
    };
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Yêu cầu chứng thư số cá nhân</h4>
            <div className="card-body">
                <form>
                    {status === 3 || status === 7? (
                        <div className="input-group">
                            <TextAreaWithLabel
                                label={"Lý do từ chối"}
                                name={""}
                                rows={0}
                                value={reason}
                                wrapClass={"col-md-12"}
                                isDisabled={true}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    {linkReSign ? (
                        <React.Fragment>
                        <div className="input-group">
                            <InputWithLabel
                                name={""}
                                label="Link ký lại DK02"
                                isDisabled={true}
                                wrapClass="col-md-6"
                                value={link}
                                onChangeValue={""}
                            />
                            <SubmitButton btnClass={"btn-submit-form btn btn-primary ml-1 mr-1 justify-content-center right"} onClick={sendMailReSign} text="Gửi mail ký lại" iconClass="fas fa-paper-plane" />

                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                name={""}
                                label="Mã xác thức"
                                isDisabled={true}
                                wrapClass="col-md-6"
                                value={secretCode}
                                onChangeValue={""}
                            />
                        </div>
                        </React.Fragment>
                    ) : (
                        ""
                    )}
                    <Card className="m-r-15-i m-l-15-i" title="Trường hợp cá nhân thuộc tổ chức doanh nghiệp"
                          size="small">
                        <Radio
                            options={{1: "Có", 2: "Không"}}
                            label=""
                            name="is_organization"
                            onChange={onChangIsOrgan}
                            wrappedClass="col-md-4"
                            isRequired={true}
                            value={is_organization}
                            isDisabled={isDisable}
                            error={props.errors.is_organization}
                        />
                        <div className="input-group">
                            {Number(isOrgan) === 1 ? (
                                <React.Fragment>
                                    <div className="input-group">
                                        <InputWithLabel
                                            label="Tên tổ chức"
                                            name="organization_name"
                                            wrapClass="col-md-3"
                                            isRequired={true}
                                            value={organization_name}
                                            onChangeValue={props.handleChange}
                                            onBlur={onChangeFormatOrganName}
                                            error={props.errors.organization_name}
                                            isDisabled={isDisable}
                                        />
                                        <InputWithLabel
                                            label="Tên phòng ban"
                                            name="organization_department"
                                            wrapClass="col-md-3"
                                            value={organization_department}
                                            onBlur={onChangeDataField}
                                            onChangeValue={props.handleChange}
                                            isDisabled={isDisable}
                                            error={props.errors.organization_department}
                                            isRequired={true}
                                        />
                                        <InputWithLabel
                                            label="MST tổ chức/doanh nghiệp"
                                            name="organization_tax_code"
                                            wrapClass="col-md-3"
                                            isRequired={true}
                                            value={organization_tax_code}
                                            onChangeValue={props.handleChange}
                                            onBlur={onChangeDataField}
                                            error={props.errors.organization_tax_code}
                                            isDisabled={isDisable}
                                        />
                                        <InputWithLabel
                                            label="Chức vụ"
                                            name="organization_position"
                                            wrapClass="col-md-3"
                                            value={organization_position}
                                            onBlur={onChangeDataField}
                                            onChangeValue={props.handleChange}
                                            isDisabled={isDisable}
                                            error={props.errors.organization_position}
                                            isRequired={true}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <InputWithLabel
                                            label="Email"
                                            name="organization_email"
                                            wrapClass="col-md-3"
                                            isRequired={true}
                                            value={organization_email}
                                            onChangeValue={props.handleChange}
                                            onBlur={onChangeDataField}
                                            error={props.errors.organization_email}
                                            isDisabled={isDisable}
                                        />
                                        <InputWithLabel
                                            label="Số điện thoại"
                                            name="organization_phone"
                                            wrapClass="col-md-3"
                                            isRequired={true}
                                            value={organization_phone}
                                            onChangeValue={props.handleChange}
                                            onBlur={onChangeDataField}
                                            error={props.errors.organization_phone}
                                            isDisabled={isDisable}
                                        />
                                        <InputWithLabel
                                            label="Địa chỉ tổ chức/doanh nghiệp"
                                            name="organization_address"
                                            wrapClass="col-md-3"
                                            isRequired={true}
                                            value={organization_address}
                                            onChangeValue={props.handleChange}
                                            onBlur={onChangeDataField}
                                            error={props.errors.organization_address}
                                            isDisabled={isDisable}
                                        />
                                        <div className="col-md-3">
                                            <InputFileUploadPreview
                                                name="organization_file"
                                                classWrapped="col-md-12 viewInputHide pr-0 pl-0"
                                                label="File xác nhận tổ chức/doanh nghiệp"
                                                filename={organFile}
                                                onChanageFileUpload={onChangeOrganFile}
                                                isRequired={true}
                                                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                                onClickDownloadFile = {organFile}
                                                funcntionView = {()=>onClickModalFile(5)}
                                                error={props.errors.organization_file}
                                                isDisabled={isDisable}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                        </div>
                    </Card>
                    <Card className="m-r-15-i m-l-15-i mt-4" title="Đăng ký dịch vụ chứng thư số" size="small">
                        <div className="input-group">
                            <div className="input-group">
                                <SelectBox
                                    name="provider_id"
                                    options={provider}
                                    label="Nhà cung cấp"
                                    wrappedClass="col-md-2"
                                    firstOptionLabel="Chọn nhà cung cấp"
                                    value={provider_id}
                                    error={props.errors.provider_id}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                                <Radio
                                    options={{1: "Cấp mới", 2: "Gia hạn", 3: "Chuyển đổi"}}
                                    label="Đối tượng"
                                    name="object"
                                    onChange={onChangeObjectRequest}
                                    wrappedClass="col-md-3"
                                    isRequired={true}
                                    value={objectRequest}
                                    isDisabled={true}
                                    error={props.errors.object}
                                />
                                {/* {Number(objectRequest) === 2? (
                                        <Radio
                                        options={{
                                            1: "Trước hạn",
                                            2: "Quá hạn",
                                        }}
                                        label="Loại gia hạn"
                                        name="type_extend"
                                        onChange={onChangeObjectRequest}
                                        wrappedClass="col-md-6 col-lg-3"
                                        value={type_extend}
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                    ) : ("")} */}
                                {Number(objectRequest) === 2 || Number(objectRequest) === 3 ?(
                                    <div className="input-group">
                                        <InputWithLabel
                                            label={"Serial CTS"}
                                            name={"serial_cts"}
                                            wrapClass="col-md-3"
                                            isDisabled={true}
                                            value={serial_cts}
                                            onChangeValue={""}
                                            error={props.errors.serial_cts}
                                        />
                                        <SelectBox
                                            name="token_type"
                                            options={{
                                                1: 'Token mới',
                                                2: 'Token cũ',
                                            }}
                                            label="Loại token"
                                            wrappedClass="col-md-2"
                                            value={token_type}
                                            error={props.errors.token_type}
                                            isDisabled={true}
                                        />
                                    </div>
                                ):("")}
                                <Radio
                                    options={{1: "Token", 2: "HSM", 3: "FastCA RS"}}
                                    label="Loại thiết bị đầu cuối thuê bao"
                                    name="type_device"
                                    onChange={onChangeTypeDevice}
                                    wrappedClass="col-md-2"
                                    isRequired={true}
                                    value={typeDevice}
                                    error={props.errors.type_device}
                                    isDisabled={true}
                                />
                            </div>
                            <div className="input-group">
                                <div className="input-group">
                                    {Number(provider_id) === 2 && Number(typeDevice) === 1 ? (
                                        <SelectBox
                                            name="version_token_newtel"
                                            options={versionToken}
                                            label="Version Token"
                                            wrappedClass="col-md-2"
                                            firstOptionLabel="Chọn version token"
                                            onChange={onChangeVersionToken}
                                            value={version_token_newtel}
                                            error={props.errors.version_token_newtel}
                                            isRequired={true}
                                            isDisabled={isDisable}
                                        />
                                    ):('')}
                                    {Number(typeDevice) === 2 ? (
                                        <Radio
                                            options={{1: "CyberLotus", 2: "Khác"}}
                                            label="Nhà cung cấp HSM"
                                            name="hsm_provider"
                                            onChange={onChangePackage}
                                            wrappedClass="col-md-2"
                                            isRequired={true}
                                            value={hsm_provider}
                                            error={props.errors.hsm_provider}
                                            isDisabled={true}
                                        />
                                    ):("")}
                                    <SelectBox
                                        name="package_id"
                                        options={cateServicePackage}
                                        label="Gói dịch vụ"
                                        wrappedClass="col-md-2"
                                        firstOptionLabel="Chọn gói dịch vụ"
                                        onChange={onChangePackage}
                                        value={package_id}
                                        error={props.errors.package_id}
                                        isRequired={true}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Giá bán"
                                        name={"package_price"}
                                        wrapClass="col-md-2"
                                        value={package_price}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangePrice}
                                        isRequired={true}
                                        error={props.errors.package_price}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Số lượng"
                                        name={"quantity"}
                                        wrapClass="col-md-2"
                                        value={quantity}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        isRequired={true}
                                        error={props.errors.quantity}
                                        isDisabled={isDisable}
                                        type={'number'}
                                    />
                                    <InputWithLabel
                                        label="Chương trình khuyến mãi"
                                        name={"promotion_code"}
                                        wrapClass="col-md-2"
                                        value={promotion_code}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className={"input-group"}>
                                    {Number(objectRequest) === 2 || Number(objectRequest) === 3 ? (
                                        <SelectDate
                                            name="date_expired_cert"
                                            wrappedClass="col-md-3"
                                            label="Ngày hết hạn"
                                            onChange={onChangeSelectDateExpiredDate}
                                            value={date_expired_cert ? date_expired_cert : ''}
                                            isDisabled={isDisable}
                                            error={props.errors.date_expired_cert}
                                        />
                                    ) : ("")}
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="m-r-15-i m-l-15-i" title="Thông tin cá nhân đăng ký" size="small">
                        <div className="input-group">
                            {Number(isOrgan) === 1 ? (
                                <SelectBox
                                    name="identity_type"
                                    options={{
                                        1: 'Chứng minh nhân dân',
                                        2: 'Hộ chiếu',
                                        3: 'Căn cước công dân',
                                        4: 'Mã bảo hiểm xã hội',
                                        5: 'Mã nhân viên',
                                    }}
                                    label="Loại định danh"
                                    wrappedClass="col-md-4"
                                    value={identity_type}
                                    error={props.errors.identity_type}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                            ):(
                                <SelectBox
                                    name="identity_type"
                                    options={{
                                        1: 'Chứng minh nhân dân',
                                        2: 'Hộ chiếu',
                                        3: 'Căn cước công dân',
                                        4: 'Mã bảo hiểm xã hội',
                                    }}
                                    label="Loại định danh"
                                    wrappedClass="col-md-4"
                                    value={identity_type}
                                    error={props.errors.identity_type}
                                    isRequired={true}
                                    isDisabled={true}
                                />
                            )}

                            <InputWithLabel
                                label={labelCode}
                                name={"passport"}
                                wrapClass="col-md-4"
                                isDisabled={true}
                                value={passport}
                                onChangeValue={""}
                                error={props.errors.passport}
                            />
                            <InputWithLabel
                                name="tax_code"
                                wrapClass="col-md-4"
                                label="Mã số thuế (nếu có)"
                                value={tax_code}
                                onChangeValue={props.handleChange}
                                error={props.errors.tax_code}
                                onBlur={onChangeDataField}
                                isDisabled={isDisable}
                            />
                            <SelectDate
                                label="Ngày cấp"
                                name="passport_date"
                                wrappedClass="col-md-4"
                                onChange={onChangeSelectPassportDate}
                                value={passport_date ? passport_date : ''}
                                isRequired={true}
                                isDisabled={isDisable}
                                error={props.errors.passport_date}
                            />
                            <InputWithLabel
                                name="passport_place"
                                wrapClass="col-md-4"
                                label="Nơi cấp"
                                value={passport_place}
                                onChangeValue={props.handleChange}
                                isRequired={true}
                                error={props.errors.passport_place}
                                onBlur={onChangeDataField}
                                isDisabled={isDisable}
                            />
                            <div className="col-md-4">
                                <InputFileUploadPreview
                                    name="passport_file"
                                    classWrapped="col-md-12 viewInputHide pr-0 pl-0"
                                    label="File CMND/Hộ chiếu"
                                    filename={passportFile}
                                    onChanageFileUpload={onChangePassportFile}
                                    isRequired={true}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    onClickDownloadFile = {passportFile}
                                    funcntionView = {()=>onClickModalFile(4)}
                                    error={props.errors.passport_file}
                                    isDisabled={isDisable}
                                />
                            </div>
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                label="Họ tên cá nhân"
                                name="fullname"
                                wrapClass="col-md-4"
                                value={fullname}
                                onChangeValue={props.handleChange}
                                onBlur={onChangeFormatFullname}
                                error={props.errors.fullname}
                                isRequired={true}
                                isDisabled={isDisable}
                            />
                            <InputWithLabel
                                label="Số điện thoại"
                                name="phone"
                                wrapClass="col-md-4"
                                isRequired={true}
                                value={phone}
                                onChangeValue={props.handleChange}
                                onBlur={onChangeDataField}
                                error={props.errors.phone}
                                isDisabled={isDisable}
                            />
                            <InputWithLabel
                                label="Email"
                                name="email"
                                wrapClass="col-md-4"
                                isRequired={true}
                                value={email}
                                onChangeValue={props.handleChange}
                                onBlur={onChangeDataField}
                                error={props.errors.email}
                                isDisabled={isDisable}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                label="Địa chỉ thường trú"
                                name="address"
                                wrapClass="col-md-4"
                                value={address}
                                onChangeValue={props.handleChange}
                                onBlur={onChangeDataField}
                                error={props.errors.address}
                                isDisabled={isDisable}
                            />
                            <SelectBox
                                name="province_code"
                                options={province}
                                label="Tỉnh thành"
                                wrappedClass="col-md-4"
                                firstOptionLabel="Chọn tỉnh thành"
                                onChange={onChangeProvince}
                                value={province_code}
                                error={props.errors.province_code}
                                isRequired={true}
                                isDisabled={isDisable}
                            />
                            <SelectBox
                                name="district_code"
                                options={district}
                                label="Quận huyện"
                                wrappedClass="col-md-4"
                                firstOptionLabel="Chọn quận huyện"
                                onChange={onChangeDistrict}
                                value={district_code}
                                error={props.errors.district_code}
                                isRequired={true}
                                isDisabled={isDisable}
                            />
                        </div>
                    </Card>
                    {/*<Card className="m-r-15-i m-l-15-i mt-4" title="Đăng Ký Hỗ Trợ Khẩn Cấp" size="small">*/}
                    {/*    <div className="input-group">*/}
                    {/*        <div className="input-group">*/}
                    {/*            <Radio*/}
                    {/*                options={{1: "Có", 2: "Không"}}*/}
                    {/*                label=""*/}
                    {/*                name="support_register"*/}
                    {/*                onChange={onChangeSupportRegister}*/}
                    {/*                wrappedClass="col-md-5"*/}
                    {/*                value={support_register}*/}
                    {/*                isRequired={true}*/}
                    {/*                error={props.errors.support_register}*/}
                    {/*                isDisabled={isDisable}*/}
                    {/*            />*/}
                    {/*            /!*line 1*!/*/}
                    {/*            {Number(support_register) === 1 ? (*/}
                    {/*                <div className="input-group">*/}
                    {/*                    <div className="input-group">*/}
                    {/*                        <InputWithLabel*/}
                    {/*                            label="Họ tên"*/}
                    {/*                            name="sp_fullname"*/}
                    {/*                            wrapClass="col-md-3"*/}
                    {/*                            isRequired={true}*/}
                    {/*                            value={sp_fullname}*/}
                    {/*                            onChangeValue={props.handleChange}*/}
                    {/*                            onBlur={onChangeFormatSupportName}*/}
                    {/*                            error={props.errors.sp_fullname}*/}
                    {/*                            isDisabled={isDisable}*/}
                    {/*                        />*/}
                    {/*                        <InputWithLabel*/}
                    {/*                            label="chức vụ"*/}
                    {/*                            name="sp_position"*/}
                    {/*                            wrapClass="col-md-3"*/}
                    {/*                            value={sp_position}*/}
                    {/*                            onChangeValue={props.handleChange}*/}
                    {/*                            onBlur={onChangeDataField}*/}
                    {/*                            isDisabled={isDisable}*/}
                    {/*                            error={props.errors.sp_position}*/}
                    {/*                        />*/}
                    {/*                        <InputWithLabel*/}
                    {/*                            label="số điện thoại"*/}
                    {/*                            name="sp_phone"*/}
                    {/*                            wrapClass="col-md-3"*/}
                    {/*                            isRequired={true}*/}
                    {/*                            value={sp_phone}*/}
                    {/*                            onChangeValue={props.handleChange}*/}
                    {/*                            onBlur={onChangeDataField}*/}
                    {/*                            error={props.errors.sp_phone}*/}
                    {/*                            isDisabled={isDisable}*/}
                    {/*                        />*/}
                    {/*                        <InputWithLabel*/}
                    {/*                            label="email"*/}
                    {/*                            name="sp_email"*/}
                    {/*                            wrapClass="col-md-3"*/}
                    {/*                            isRequired={true}*/}
                    {/*                            value={sp_email}*/}
                    {/*                            onChangeValue={props.handleChange}*/}
                    {/*                            onBlur={onChangeDataField}*/}
                    {/*                            error={props.errors.sp_email}*/}
                    {/*                            isDisabled={isDisable}*/}
                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            ) : ("")}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Card>*/}
                    <Card className="m-r-15-i m-l-15-i mt-4" title="Mẫu đăng ký" size="small">
                        <div className="input-group">
                            <Radio
                                options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}}
                                name="type_docurment"
                                label="Loại hồ sơ"
                                wrappedClass="col-md-2-3"
                                onChange={onChangeTypeDoc}
                                value={type_docurment}
                                error={props.errors.type_docurment}
                                isRequired={true}
                                isDisabled={isDisable}
                            />
                            {dataChanged ? (
                                <div className="col-md-2">
                                    <label className="invisible">12121</label>
                                    <button type="button" className="form-control btn btn-primary ml-1 mr-1 p-b-b-3" onClick={onPreviewFile}>Mẫu DK-01.02</button>
                                </div>

                            ) : (
                                <div className="col-md-2">
                                    <label className="invisible">12121</label>
                                    <button type="button" className="form-control btn btn-success ml-1 mr-1 p-b-b-3" onClick={() => onViewFileDoc(6)}>File đăng ký</button>
                                </div>
                            )}
                            {dataChanged ? (
                                <div className="col-md-3">
                                    <InputFileUploadPreview
                                        name="file_register_paper"
                                        classWrapped="col-md-12 viewInputHide"
                                        label="Tải file DK-01.02"
                                        filename={fileRegisterPaper}
                                        onChanageFileUpload={onChangeFileDk0102}
                                        isRequired={true}
                                        accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                        onClickDownloadFile = {fileRegisterPaper}
                                        funcntionView = {()=>onClickModalFile(6)}
                                        error={props.errors.file_register_paper}
                                        isDisabled={isDisable}
                                    />
                                </div>
                            ) : ('')}
                            {statusGen ? (
                                <div className="col-md-2">
                                    <label className="invisible">12121</label>
                                    <button type="button" className="form-control btn btn-primary ml-1 mr-1 p-b-b-3" onClick={()=>onViewFileDoc(7)}>File DK-02</button>
                                </div>
                            ) : ("")}
                            {statusDK03 ? (
                                <div className="col-md-2">
                                    <label className="invisible">12121</label>
                                    <button type="button" className="form-control btn btn-primary ml-1 mr-1 p-b-b-3" onClick={()=>onViewFileDoc(14)}>File DK-03</button>
                                </div>
                            ) : ("")}
                            {(Number(status) === 6 && Number(provider_id) === 1 ?
                                <InputWithLabel
                                    label="Mã bảo mật"
                                    name=""
                                    wrapClass="col-md-2"
                                    isDisabled={true}
                                    value={secret_code}
                                    onChangeValue={""}
                                />
                                : "")}
                        </div>
                    </Card>
                    <div className="input-group d-flex justify-content-center p-5">
                        {/*{(Number(status) === 2 || Number(status) === 7) && dataChanged?*/}
                        {/*    (*/}
                        {/*        // <SubmitButton onClick={() => submitApprove(2)} text="Sửa và Duyệt" iconClass="fas fa-check"/>*/}
                        {/*        <SubmitButton onClick={() => submitAccept(2)} text="Sửa và Duyệt" iconClass="fas fa-check"/>*/}
                        {/*    )*/}
                        {/*    : (" ")*/}
                        {/*}*/}
                        {/*{(Number(status) === 2 || Number(status) === 7) && !dataChanged?*/}
                        {/*    (*/}
                        {/*        // <SubmitButton onClick={() => submitApprove(1)} text="Duyệt" iconClass="fas fa-check"/>*/}
                        {/*        <SubmitButton onClick={() => submitAccept(1)} text="Duyệt" iconClass="fas fa-check"/>*/}
                        {/*    )*/}
                        {/*    : (" ")*/}
                        {/*}*/}
                        {/*{(Number(status) === 2 || Number(status) === 7) ?*/}
                        {/*    (*/}
                        {/*        <DenyButton onClick={submitDeny}/>*/}
                        {/*    )*/}
                        {/*    : (" ")*/}
                        {/*}*/}
                        <BackButton
                            history={props.history}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        passport: '',
        passport_date: '',
        passport_place: '',
        tax_code: '',
        passport_file: '',
        fullname: "",
        phone: '',
        email: '',
        address: '',
        is_organization: 0,
        organization_name: '',
        organization_department: '',
        organization_tax_code: '',
        organization_position: '',
        organization_email: '',
        organization_phone: '',
        organization_address: '',
        organization_file: '',
        sp_fullname: '',
        sp_position: '',
        sp_email: '',
        sp_phone: '',
        type_device: 0,
        object: 0,
        package_id: '',
        package_price: '',
        file_register: '',
        support_register: 0,
        status: 0,
        reason: '',
        file: '',
        secret_code: '',
        password: '',
        type_docurment: 0,
        province_code: '',
        district_code: '',
        promotion_code: '',
        file_register_paper: '',
        serial_cts: '',
        token_type: 0,
        provider_id: 0,
        identity_type: 0,
        quantity: 0,
        version_token_newtel: 0,
        hsm_provider: 0,
        date_expired_cert: '',

    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    },
})(RequestCertificateViewPersonal);

export default withNavbar()(RenderCreateForm);
