import swal from "sweetalert";

export const onDeleteAlert = (onYesAction,title?, text?) => {
  swal({
    title: (title) ? title : "Chắn xóa bản ghi này ?",
    text: (text) ? text : "Nếu xóa sẽ không thể khôi phục !",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};

export const onSuccessAction = (text: string, afterClosed: any = () => {}) => {
  swal({
    title: "Thành công!",
    text: text,
    icon: "success",
    dangerMode: false
  }).then(afterClosed);
};

export const onFailAction = (text?: string, afterClosed: any = () => {}) => {
  swal({
    title: "Internal Server Error",
    text: text,
    icon: "error",
    dangerMode: true
  }).then(afterClosed);
};

export const onActiveAlert = (onYesAction,title?, text?) => {
  swal({
    title: (title) ? title : "Bạn muốn kích hoạt lại tài khoản này ?",
    text: (text) ? text : "",
    icon: "info",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};