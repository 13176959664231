import React, { Component } from "react";
import { Modal } from 'antd';
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import InputUploadFileSingle from './input-file-with-label/InputUploadFileSingle';

interface Props {
    handleCancel?: any;
    handleButton?: any;
    checkedList?: any;
    plainOptions?: any;
    plainIdOptions?: any;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    fileName?: any;
    error? : string;
}
interface State {
    visible: boolean;
}

export default class ModalUploadDocument extends Component<Props, State> {

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Modal
                    title={this.props.title}
                    visible={this.props.visible}
                    onOk={this.props.handleButton}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Đồng ý"
                    cancelText="Đóng"
                    className="modal-lg"
                    width="auto"
                    closable={false}
                >
                    <>
                        {this.props.plainIdOptions.includes(1) &&
                        <InputUploadFileSingle
                            name={'1'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[1]}
                            filename={this.props.fileName.name1}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(2) &&
                        <InputUploadFileSingle
                            name={'2'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[2]}
                            filename={this.props.fileName.name2}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(3) &&
                        <InputUploadFileSingle
                            name={'3'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[3]}
                            filename={this.props.fileName.name3}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(4) &&
                        <InputUploadFileSingle
                            name={'4'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[4]}
                            filename={this.props.fileName.name4}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(5) &&
                        <InputUploadFileSingle
                            name={'5'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[5]}
                            filename={this.props.fileName.name5}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(6) &&
                        <InputUploadFileSingle
                            name={'6'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[6]}
                            filename={this.props.fileName.name6}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(7) &&
                        <InputUploadFileSingle
                            name={'7'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[7]}
                            filename={this.props.fileName.name7}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(8) &&
                        <InputUploadFileSingle
                            name={'8'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[8]}
                            filename={this.props.fileName.name8}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(9) &&
                        <InputUploadFileSingle
                            name={'9'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[9]}
                            filename={this.props.fileName.name9}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(10) &&
                        <InputUploadFileSingle
                            name={'10'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[10]}
                            filename={this.props.fileName.name10}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(11) &&
                        <InputUploadFileSingle
                            name={'11'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[11]}
                            filename={this.props.fileName.name11}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(12) &&
                        <InputUploadFileSingle
                            name={'12'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[12]}
                            filename={this.props.fileName.name12}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(13) &&
                        <InputUploadFileSingle
                            name={'13'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[13]}
                            filename={this.props.fileName.name13}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                        {this.props.plainIdOptions.includes(14) &&
                        <InputUploadFileSingle
                            name={'14'}
                            classWrapped="col-md-12"
                            label={this.props.plainOptions[14]}
                            filename={this.props.fileName.name14}
                            onChangeFileUpload={this.props.onChange}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                        />
                        }
                    </>
                    <ValidateMessageError error={this.props.error}></ValidateMessageError>
                </Modal>
            </div>
        );
    }
}
