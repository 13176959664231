import swal from "sweetalert";

export const onDeleteAlert = onYesAction => {
  swal({
    title: "Bạn có chắc chắn xóa ?",
    text: "Nếu xóa sẽ không thể khôi phục bản ghi!!",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};

export const onLockAlert = onYesAction => {
    swal({
        title: "Bạn chắc chắn muốn khóa ?",
        text: "",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};

export const onUnLockAlert = onYesAction => {
    swal({
        title: "Chắc chắn mở khóa ?",
        text: "",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};

export const onDelCertificate = onYesAction => {
    swal({
        title: "Chắc chắn xóa chứng thư số USB token ?",
        text: "",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};
export const onApproveFile = onYesAction => {
    swal({
        title: "Chắc chắn duyệt hồ sơ ?",
        text: "",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};
export const onApproveFileUpdateRequest = onYesAction => {
    swal({
        title: "Cho phép cập nhật hồ sơ ?",
        text: "",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};
export const onRefuseFileUpdateRequest = onYesAction => {
    swal({
        title: "Từ chối yêu cầu cập nhật hồ sơ ?",
        text: "Khi từ chối hồ sơ sẽ về trạng thái trước đó!",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};

export const onSuccessAction = (text: string, afterClosed: any = () => {}) => {
  swal({
    title: "Thành công!",
    text: text,
    icon: "success",
    dangerMode: false
  }).then(afterClosed);
};

export const onFailAction = (text?: string, afterClosed: any = () => {}) => {
  swal({
    title: "Internal Server Error",
    text: text,
    icon: "error",
    dangerMode: true
  }).then(afterClosed);
};
export const onDenyChange = onYesAction => {
    swal({
        title: "Bạn có chắc chắn từ chối điều chỉnh thông tin?",
        text: "",
        icon: "warning",
        buttons: ["Hủy", "Đồng ý"],
        dangerMode: true
    }).then(onYesAction);
};
export const onWarningAction = (text: string, afterClosed: any = () => {}) => {
  swal({
    title: "Cảnh báo!",
    text: text,
    icon: "warning",
    dangerMode: true
  }).then(afterClosed);
};
export const onViewText = (text: any , afterClosed: any = () => {}) => {
    swal({
        title: "Lý do",
        text: text,
        icon: "warning",
        dangerMode: true
    }).then(afterClosed);
};
export const onHandleAction = (title: any, text: any , afterClosed: any = () => {}) => {
    swal({
        title: title,
        text: text,
        icon: "success",
        dangerMode: true
    }).then(afterClosed);
};
export const reGenDK02 = onYesAction => {
  swal({
    title: "Bạn có chắc chắn muốn sinh lại file DK02 ?",
    text: "Sinh lại file sẽ mất chữ ký của khách",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};
export const reGenRequest = onYesAction => {
  swal({
    title: "Bạn có chắc chắn muốn cập nhật yêu cầu gen lại?",
    text: "Cập nhật sẽ mất chứng thư số của khách",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};
export const onSearchDelete = onYesAction => {
  swal({
    title: "Bạn có chắc chắn muốn xóa Token này ?",
    text: "Nếu xóa cần xóa cả token bên TMS",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};
export const onAcceptRequestCTS = (onYesAction, title?) => {
  swal({
    title: title,
    text: "",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};
export const onEditAcceptRequestCTS = onYesAction => {
  swal({
    title: "Bạn có chắc chắn muốn duyệt yêu cầu ?",
    text: "",
    icon: "warning",
    buttons: ["Hủy", "Đồng ý"],
    dangerMode: true
  }).then(onYesAction);
};
