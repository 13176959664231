import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import CateContractService from "../../../../services/category-contract/CateContractService";
import ModalView from "../../../common/form/Modal";
import {formatDateTime} from "../../../../helpers/NewCaHelper";
// import DenyButton from "../../../common/button/DenyButton";
// import SubmitButton from "../../../common/form/SubmitButton";
import ModalDeny from "../../../common/form/ModalDeny";
import {Card} from "antd";
import RequestChangeInfoService from "../../../../services/request-change-info/RequestChangeInfoService";
import TextAreaWithLabel from "../../../common/form/TextAreaWithLabel";
// import {checkPermission} from "../../../../helpers/NewCaHelper"

// const confirm_request = 'confirm-request-change-info-owner-ad'

interface FormValues {
    status: number,
    reason_customer: string,
    reason_newca: string,
    code:string,
    fullname:string,
    sortname:string,
    passport:string,
    passport_date:string,
    passport_place:string,
    birthday:string,
    phone:string,
    email:string,
    address:string,
    job:string,
    competitive_area:string,
    cooperate_capacity:string,
    supply_capacity:string,
    provider_list:string,
    file:string,
}

interface OtherProps extends RouteComponentProps<{ id: string; }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const RequestCertificateViewPersonal: React.FC<Props> = props => {
    const service = new RequestChangeInfoService();
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    const {
        status,
        reason_customer,
        reason_newca,
        code,
        fullname,
        sortname,
        passport,
        passport_date,
        passport_place,
        birthday,
        phone,
        email,
        address,
        job,
        competitive_area,
        cooperate_capacity,
        supply_capacity,
        provider_list,
        file,
    } = props.values;

    const onClickModalFile = async pathFile => {
        const result = await service.disPlayPdfFile(pathFile);
        if (result && result.status === 200) {
            setModal({
                ...modal,
                'base64': result.base64,
                'visible_modal': true,
                'loading': true,
                'title_modal': 'file',
                'type_file': 'pdf'
            });
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra!");
    };
    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.findContributor(id);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        for (let key in result.data.change_info_detail_contributor) {
            props.setFieldValue(key, result.data.change_info_detail_contributor[key]);
        }
        props.setFieldValue("code", result.data.contributor ? result.data.contributor.code : "")
    };

    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false})
    };
    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim() === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.getDeny(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/yeu-cau-thay-doi-thong-tin");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };
    // const submitDeny = () => {
    //     setModalDeny({...modalDeny, 'visible_modal_deny': true});
    // };
    // const submitApprove = async () => {
    //     const result = await service.getApprove(id);
    //     if (result && result.status === 200) {
    //         onSuccessAction("Duyệt thành công!", () => {
    //             props.history.push("/yeu-cau-thay-doi-thong-tin");
    //         });
    //     } else if (result && result.status === 404) {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     } else {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     }
    // };

    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Yêu cầu thay đổi thông tin cộng tác viên</h4>
            <div className="card-body">
                <form>
                    <div>
                        {/*lý do từ chối*/}
                        {status === 2 ? (
                            <div className="input-group">
                                <InputWithLabel
                                    name={""}
                                    label="Lý do từ chối"
                                    isDisabled={true}
                                    wrapClass="col-md-12 text-danger"
                                    value={reason_newca}
                                    onChangeValue={""}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {/*card 1*/}
                        <Card className="m-r-15-i m-l-15-i" title="Thông tin cộng tác viên" size="small">
                            <div className="input-group">
                                <div className="input-group">
                                    <TextAreaWithLabel
                                        name={"reason_customer"}
                                        wrapClass="col-md-12"
                                        label="Lý do thay đổi thông tin"
                                        onChangeValue={""}
                                        value={reason_customer}
                                        isDisabled={true}
                                        rows={4}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        name=""
                                        wrapClass="col-md-4"
                                        label="Tên cộng tác viên"
                                        isDisabled={true}
                                        value={fullname}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Mã cộng tác viên"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={code}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Tên viết tắt"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={sortname}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    {(file ?
                                        <div className="col-md-4">
                                            <label className="invisible">123123123123</label>
                                            <button type="button" className="form-control btn btn-primary p-b-b-3"
                                                    onClick={() => onClickModalFile(file)}>
                                                <i className="fas fa-file-pdf"/> File CMND/Hộ chiếu
                                            </button>
                                        </div>
                                        : "")}
                                    <InputWithLabel
                                        label="CMND/Hộ chiếu"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={passport}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Nơi cấp"
                                        name={""}
                                        wrapClass="col-md-3 d-none"
                                        isDisabled={true}
                                        value={passport_place}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Ngày cấp"
                                        name={""}
                                        wrapClass="col-md-3 d-none"
                                        isDisabled={true}
                                        value={formatDateTime(passport_date)}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Ngày sinh"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={formatDateTime(birthday)}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        name="passport_date"
                                        wrapClass="col-md-4"
                                        label="Số điện thoại"
                                        isDisabled={true}
                                        value={phone}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Email"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={email}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Địa chỉ"
                                        name={""}
                                        wrapClass="col-md-4"
                                        isDisabled={true}
                                        value={address}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Ngành nghề chính"
                                        name={""}
                                        wrapClass="col-md-3 d-none"
                                        isDisabled={true}
                                        value={job}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        name=""
                                        wrapClass="col-md-3 d-none"
                                        label="Khả năng hợp tác"
                                        isDisabled={true}
                                        value={cooperate_capacity}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Năng lực cung cấp CTS/tháng"
                                        name={""}
                                        wrapClass="col-md-3 d-none"
                                        isDisabled={true}
                                        value={supply_capacity}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Khu vực thị trường"
                                        name={""}
                                        wrapClass="col-md-3 d-none"
                                        isDisabled={true}
                                        value={competitive_area}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Đã hợp tác với nhà cung cấp nào khác"
                                        name={""}
                                        wrapClass="col-md-3 d-none"
                                        isDisabled={true}
                                        value={provider_list}
                                        onChangeValue={""}
                                    />
                                </div>
                            </div>
                        </Card>
                        {/*nút duyệt*/}
                        <div className="input-group d-flex justify-content-center p-5">
                            {/*{(status === 1 && checkPermission(confirm_request)) */}
                            {/*    ? <SubmitButton onClick={submitApprove} text="Duyệt" iconClass="fas fa-check"/>*/}
                            {/*    : " "*/}
                            {/*}*/}
                            {/*{status === 1 && checkPermission(confirm_request) */}
                            {/*    ? <DenyButton onClick={submitDeny}/>*/}
                            {/*    : " "*/}
                            {/*}*/}
                            <BackButton
                                history={props.history}
                                // url="/yeu-cau-thay-doi-thong-tin"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        status: 0,
        reason_customer: "",
        reason_newca: "",
        code:"",
        fullname:'',
        sortname:'',
        passport:'',
        passport_date:'',
        passport_place:'',
        birthday:'',
        phone:'',
        email:'',
        address:'',
        job:'',
        competitive_area:'',
        cooperate_capacity:'',
        supply_capacity:'',
        provider_list:'',
        file:'',
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(RequestCertificateViewPersonal);

export default withNavbar()(RenderCreateForm);
