import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class TaxDepartmentService extends FetchAPI{

    public async getTaxDepartments(data: object) {
        const path = "/cate/taxdepartment"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteTaxDepartmentById(id){
        const result =  await this.request("cate/taxdepartment-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getTaxDepartment(id){
        const result = await this.request("cate/taxdepartment-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createTaxDepartment(data){
        const result = await this.request("cate/taxdepartment",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateTaxDepartment(data){
        const result = await this.request("cate/taxdepartment-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
}

export default TaxDepartmentService;