import RequestDocument from "../../pages/request-document/RequestDocument";
import RequestDocumentView from "../../pages/request-document/RequestDocumentView";

const REQUEST_DOCUMENT_ROUTE = [
    {
        component: RequestDocument,
        link: "/yeu-cau-tai-lieu-ban-hang",
        isExact: false,
        permission: 'request-document'
    },
    {
        component: RequestDocumentView,
        link: "/yeu-cau-tai-lieu-ban-hang/chi-tiet/:id",
        isExact: false,
        permission: 'request-document'
    },
];

export default REQUEST_DOCUMENT_ROUTE;

