import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON, reloadPage} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import ApproveUcService from "../../../services/approve-uc/ApproveUcService";
import SelectBox from "../../common/form/SelectBox";
import SelectDate from "../../common/form/SelectDate";
import {onSuccessAction} from "../../common/swal/SwalCommon";
import {Tag} from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Danh sách phê duyệt UC";

const ApproveUc: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({uid: "", token_code: "", status: "", created_at:""});
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at: dateString });
    };
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, 'status' : e})
    }

    const fetchDataFunction = async () => {
        const service = new ApproveUcService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            return await service.list({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const approve = async id => {
        const service = new ApproveUcService();
        const result = await service.approve(id);
        if (result && result['status']===200){
            onSuccessAction("Phê duyệt UC thành công",reloadPage(props.location, props.history));
        }else{
            onFailAction("Phê duyệt UC thất bại");
        }
    };

    const refresh = ()=>{
        reloadPage(props.location, props.history);
    };

    useEffect(() => {
        fetchDataFunction();
        // eslint-disable-next-line
    }, []);

    const onRenderActionColumn = (text, record, index) => {
        return (
            record.status === 1 ? (<div className="row justify-content-center">
                <a href="#/" className="pointer text-primary ml-1 mr-1" onClick={()=>approve(record.id)}><i className="fas fa-check fa-lg" title={"Duyệt"}/></a>
            </div>) : ("")
        );
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="uid"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.uid}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã định danh"}
                        />
                        <InputWithLabel
                            name="token_code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.token_code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã token"}
                        />
                        <SelectBox
                            name="status"
                            options={{
                                1: "Chưa duyệt",
                                2: "Đã duyệt"
                            }}
                            label=""
                            wrappedClass="col-md-2"
                            firstOptionLabel="Trạng thái"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.status}
                        />
                        <SelectDate
                            label=""
                            name="created_at"
                            wrappedClass="col-md-2 form-group nopadding-left"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <button className="btn btn-success mr-1 ml-1 font-btn" type="button" onClick={refresh}><i className="fas fa-retweet fa-lg"/> Làm mới</button>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Mã token", dataIndex: "token_code"},
                        {title: "Mã định danh", dataIndex: "uid"},
                        {title: "Email", dataIndex: "email"},
                        {title: "UC", dataIndex: "uc"},
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: text =>
                                text === 1 ? <Tag color="red">Chưa duyệt</Tag> : <Tag color="green">Đã duyệt</Tag>
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(ApproveUc);
