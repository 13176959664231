import React, {Component} from "react";
import {Table} from "antd";


interface Props {
    data?: any;
}

interface State {
}
const { Column, ColumnGroup } = Table;
export default class DiscountList extends Component<Props, State> {
    render() {
        return (
            <div>
                <Table
                    className={"w-100 m-t-20 ant-table-bordered"}
                    //columns={columns}
                    dataSource={this.props.data ? this.props.data : []}
                    pagination={false}
                    rowKey="key"
                >
                    <Column 
                        title="STT" 
                        dataIndex="key" 
                        key="key" 
                        render={(text, record, index) => index + 1}
                        className="text-center"
                        />
                        
                    <Column 
                        title="Số lượng thuê bao" 
                        dataIndex="number" 
                        key="number" 
                        className="text-center"/>
                    <ColumnGroup title="Tỷ lệ chiết khấu %">
                        <Column title="Cấp mới" dataIndex="percent_new" key="percent_new" className="text-center" width="15%"/>
                        <Column title="Gia hạn" dataIndex="percent_extension" key="percent_extension" className="text-center" width="15%" />
                        <Column title="Chuyển đổi" dataIndex="percent_change" key="percent_change" className="text-center" width="15%" />
                    </ColumnGroup>
                    <Column 
                        title="Trợ giá Token gói cấp mới 3 năm (VNĐ)"
                        dataIndex="token_price_3_year" 
                        key="token_price_3_year" 
                        align="right"
                        />
                </Table>
            </div>
        );
    }
}
