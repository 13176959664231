import React, {useState} from "react"
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON, reloadPage} from "../../../helpers/NewCaHelper"
import {RouteComponentProps} from "react-router-dom"
import InputWithLabel from "../../common/form/InputWithLabel"
import SearchButton from "../../common/button/SearchButton"
import NewTable from "../../common/table/NewTable"
import {onFailAction, onSuccessAction} from "../../../helpers/SwalCommon"
import TableActionButton from "../user/table/action-button/TableActionButton"
import TableActionApproval from "./TableActionApproval";
import withNavbar from "../../common/HOC/NavbarContainerHOC"
import SelectBox from "../../common/form/SelectBox"
import ModalView from "../../common/form/Modal"
import { Tag } from "antd"
import {STATUS_LABEL_CLASS, BELONG_TO_LABEL_CLASS} from "./Enum"
import ClickButton from "../../common/button/ClickButton"
import CrossCheckingService from "../../../services/crosschecking/CrossCheckingService"
import UserService from "../../../services/user/UserService"
import ModalConclude from "./ModalConclude"

interface Props extends RouteComponentProps {
}

const TITLE = "Danh sách đối soát";

const CrossChecking: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({belong_to: "",owner_id: "",tax_code:"", month:"",year:"", status: ""
    });
    const [agency, setAgency] = useState({})
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [conclude, setConclude] = useState({});
    const [modal, setModal] = useState({
      visible_modal : false,
      loading: false,
      title_modal: "",
      base64: "",
      type_file: "",
      text_deny: "",
      error_text_deny: "",
      type_contract: ""
    });
    const handleModalCancel = () => {
        setModal({...modal,'visible_modal': false}) ;
    };
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        })
    };
    const getAgency = async belong_to => {
      const userService = new UserService();
      const lst = await userService.getOwnerByBelongTo(belong_to);
      if (lst) {
        let agency = {};
        lst.data.map(value => (agency[value.id] = value.fullname));
        setAgency(agency)
      }
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value})
    };
    const onChangeDataSearchBelongTo = (e) => {
      if (!e) {
        setAgency({})
      }
      if(Number(e)===1) {
          getAgency(1)
      }
      if(Number(e)===2){
          getAgency(2)
      }
      if(Number(e)===3){
        getAgency(3)
    }
      setDataSearch({...dataSearch, "belong_to": e})
    };
    const onChangeDataSearchOwnerId = (e) => {
        setDataSearch({...dataSearch, "owner_id": e})
    };
    const onChangeDataSearchStatus = (e) => {
        setDataSearch({...dataSearch, "status": e})
    };
    const onChangeDataSearchMonth = (e) => {
        setDataSearch({...dataSearch, "month": e})
    };
    const onChangeDataSearchYear = (e) => {
      setDataSearch({...dataSearch, "year": e})
    };
    const fetchDataFunction = async () => {
        const service = new CrossCheckingService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result 
            
        } catch (error) {
            onFailAction(error.message)
        }
    };
    const onClickModalFile = async (id) => {
      const service = new CrossCheckingService();
      const result = await service.getFile(id);
      if (result && result.status === 200) {
          setModal({...modal,'base64': result.data, 'visible_modal': true, 'loading': true, 'title_modal': 'File đối soát','type_file': 'pdf'});
          return true;
      } else {
          onFailAction("Không tìm thấy file");
      }
      onFailAction("Có lỗi xảy ra khi lấy file");
  };
    const currencyFormat = (num,status) => {
      let value = '';
        if(num){
          value = num.toString();
        }
        if(status === 1)
          return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        if(status === 2)
          return value.replace(/[^0-9]/gi, '');
        if(status === 3) 
          return value.replace(new RegExp(/,/g),"")
    };
    const onClickShowModal = async (id) => {
      const service = new CrossCheckingService();
      const lst = await service.getCrossCheckingConclude(id);
      if(lst){
        lst.data.amount_total = Number(lst.data.amount_rest_last_month ? lst.data.amount_rest_last_month : 0) 
        + Number(lst.data.amount_pay ? lst.data.amount_pay : 0) 
        + Number(lst.data.amount_temp_document ? lst.data.amount_temp_document : 0)
        - Number(lst.data.amount_return_document ? lst.data.amount_return_document : 0) 
        - Number(lst.data.amount_paid_previous ? lst.data.amount_paid_previous : 0) 
        + Number(lst.data.amount_not_enough_token ? lst.data.amount_not_enough_token : 0) 
        - Number(lst.data.amount_other ? lst.data.amount_other : 0)
      }
      lst.data.amount_rest_last_month = currencyFormat(lst.data.amount_rest_last_month,1) ;
      lst.data.amount_pay = currencyFormat(lst.data.amount_pay,1);
      lst.data.amount_temp_document = currencyFormat(lst.data.amount_temp_document,1) ;
      lst.data.amount_return_document = currencyFormat(lst.data.amount_return_document,1);
      lst.data.amount_paid_previous = currencyFormat(lst.data.amount_paid_previous,1);
      lst.data.amount_not_enough_token = currencyFormat(lst.data.amount_not_enough_token,1);
      lst.data.amount_other = currencyFormat(lst.data.amount_other,1);
      lst.data.amount_total = currencyFormat(lst.data.amount_total,1);

      setConclude(lst.data);
      setVisibleModal(true)
    };
    const showTotal = () => {
      let total = 0;
      Object.keys(conclude).map(
        (key, index) => (
          total =  Number(currencyFormat(conclude["amount_rest_last_month"],2)) 
          + Number(currencyFormat(conclude["amount_pay"],2)) 
          + Number(currencyFormat(conclude["amount_temp_document"],2))  
          - Number(currencyFormat(conclude["amount_return_document"],2))  
          - Number(currencyFormat(conclude["amount_paid_previous"],2))  
          + Number(currencyFormat(conclude["amount_not_enough_token"],2))  
          - Number(currencyFormat(conclude["amount_other"],2)) 
        ));
        setConclude({...conclude,'amount_total': currencyFormat(total,1)})
    };
    const onChangeInputNumber = e => {
      const name = e.target.name;
      const value = currencyFormat(Number(currencyFormat(e.target.value,2)),1);
      setConclude({...conclude,[name] : value})
    };
    const onChangeInput = e => {
      const name = e.target.name;
      const value = e.target.value;
      setConclude({...conclude,[name] : value})
    };
    const onBlur = e => {
      showTotal()
    };
    const handleOk = async () => {
      let data = {
        'amount_rest_last_month': currencyFormat(conclude["amount_rest_last_month"],2),
        'amount_pay':  currencyFormat(conclude["amount_pay"],2),
        'amount_temp_document1': currencyFormat(conclude["amount_temp_document"],2),
        'amount_return_document': currencyFormat(conclude["amount_return_document"],2),
        'amount_paid_previous': currencyFormat(conclude["amount_paid_previous"],2),
        'amount_not_enough_token': currencyFormat(conclude["amount_not_enough_token"],2),
        'amount_other': currencyFormat(conclude["amount_other"],2),
        'amount_total': currencyFormat(conclude["amount_total"],3),
        'id' : conclude["id"],
        'note_amount_rest_last_month': conclude["note_amount_rest_last_month"],
        'note_amount_pay':  conclude["note_amount_pay"],
        'note_amount_temp_document': conclude["note_amount_temp_document"],
        'note_amount_paid_previous':  conclude["note_amount_paid_previous"],
        'note_amount_not_enough_token': conclude["note_amount_not_enough_token"],
        'note_amount_other':  conclude["note_amount_other"],
        'note_amount_total': conclude["note_amount_total"],
      };
      const service = new CrossCheckingService();
      const result = await service.updateCrossConclude(data);
      if (result && result.status === 200) {
        onSuccessAction("Cập nhật thành công", () => {
          reloadPage(props.location, props.history);
          setVisibleModal(false)
        });
      } else {
        onFailAction("Có lỗi xảy ra");
      }
      setLoadingModal(true)
    };  
    const handleCancel = () => {
      setVisibleModal(false)
    };
    const onRenderActionResult = (text,record,index)=> {
      return (
        <ClickButton
            text=" "
            css="btn btn-success font-btn"
            onClick={() => onClickShowModal(record.crosschecking_conclude.id)}  
        />
      )
    };
    const onRenderActionFile = (record) => {
      return (
        <ClickButton
            text=" File đối soát"
            css="btn btn-primary font-btn"
            icon= "fas fa-eye"
            disable= {!record.file}
            onClick={() => onClickModalFile(record.id)}  
        />
      )
    };
    const onClickApproval = async (id) => {
      const service = new CrossCheckingService();
      const result = await service.approval(id);
      if(result && result.status === 200){
          onSuccessAction('Duyệt đối soát thành công', () => {
            props.history.push('/danh-sach-doi-soat')
          })
      } else {
        onFailAction("Có lỗi xảy ra");
      }
    };
    const onConfirm = async (id) => {
      const service = new CrossCheckingService();
      const result = await service.confirm(id);
      if(result && result.status === 200){
          onSuccessAction("Đóng đối soát thành công", () => {
            props.history.push('/danh-sach-doi-soat')
          })
      } else {
        onFailAction("Có lỗi xảy ra");
      }
    };
    const onRenderActionColumn = (text, record, index) => {
        return (
            <div>
              <TableActionButton      
                  onConfirm = {() => onConfirm(record.id)}
                  isConfirm ={record.status === 5 && record.newca_sign === 1}
              />
                <TableActionApproval
                  onClickConfirmButton={() => onClickApproval(record.id)}
                  mesDelete="Bạn có chắc chắn muốn duyệt và ký đối soát này!"
                  icon = "fas fa-check-circle"
                  tittle= "Duyệt và ký"
                  permissionConfirm={record.status === 4}
                />
            </div>
        );
    };
    const renderTypeOwner = (text, record, index) => {
      return (
        <Tag color={`${BELONG_TO_LABEL_CLASS[text].class}`}>
          {BELONG_TO_LABEL_CLASS[text].label}
        </Tag>
      );
    };
    const renderStatusColumn = (text, record, index) => {
      return (
        <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
          {STATUS_LABEL_CLASS[text].label}
        </Tag>
      );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                      <div className="row input-group">
                        <SelectBox
                              name="belong_to"
                              wrappedClass="col-md-2 form-group "
                              label=""
                              options={{
                                1: "Đại lý",
                                2: "Cộng tác viên",
                                3: "Nhân viên kinh doanh"
                              }}
                              firstOptionLabel="Loại người dùng"
                              onChange={onChangeDataSearchBelongTo}
                              value={dataSearch.belong_to}
                        />
                        <SelectBox
                              name="owner_id"
                              wrappedClass="col-md-2 form-group "
                              label=""
                              options={agency}
                              firstOptionLabel="Tên đại lý/CTV/NVKD"
                              onChange={onChangeDataSearchOwnerId}
                              value={dataSearch.owner_id}
                        />
                        <InputWithLabel
                            name="tax_code"
                            wrapClass="col-md-2 form-group"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.tax_code}
                            label={""}
                            placeholder={"MST/CMND"}
                        />
                          <SelectBox
                              name="status"
                              wrappedClass="col-md-2 form-group"
                              label=""
                              options={{
                                  1: 'Chờ tạo kết luận',
                                  2: 'Chờ đại lý/ cộng tác viên/NVKD  duyệt',
                                  3: 'Đại lý/ cộng tác viên/NVKD từ chối',
                                  4: 'Đại lý cộng tác viên/NVKD đã duyệt',
                                  5: 'Newca đã duyệt',
                                  6: 'Hoàn thành'

                              }}
                              firstOptionLabel="Trạng thái"
                              onChange={onChangeDataSearchStatus}
                              value={dataSearch.status}
                          />
                        <SelectBox
                            name="month"
                            wrappedClass="col-md-1 form-group"
                            options={{
                              1: "1",
                              2: "2",
                              3: "3",
                              4: "4",
                              5: "5",
                              6: "6",
                              7: "7",
                              8: "8",
                              9: "9",
                              10: "10",
                              11: "11",
                              12: "12"
                            }}
                            onChange={onChangeDataSearchMonth}
                            value={dataSearch.month}
                            isRequired={false}
                            label={""}
                            firstOptionLabel={"Tháng"}
                        />
                        <SelectBox
                            name="year"
                            wrappedClass="col-md-1 form-group"
                            options={{
                              2020: "2020",
                              2021: "2021",
                              2022: "2022",
                              2023: "2023",
                              2024: "2024",
                              2025: "2025",
                              2026: "2026",
                              2027: "2027",
                              2028: "2028",
                              2029: "2029",
                              2030: "2030",
                            }}
                            onChange={onChangeDataSearchYear}
                            value={dataSearch.year}
                            isRequired={false}
                            label={""}
                            firstOptionLabel={"Năm"}
                        />
                        <div className="col-md-2 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>  
                      </div>
                    </div>
                </form>
                <ModalConclude
                  visible={visibleModal}
                  handleCancel={handleCancel}
                  handleUpdate={handleOk}
                  onChange= {onChangeInput}
                  onChangeInputNumber = {onChangeInputNumber}
                  loading={loadingModal}
                  data = {conclude}
                  onBlur= {onBlur}
                />
                <ModalView
                    visible={modal.visible_modal}
                    handleCancel={handleModalCancel}
                    title={modal.title_modal}
                    value={modal.base64}
                    type_file={modal.type_file}
                    loading={modal.loading}
                />
                <NewTable
                    columns={[
                        {
                          title: "Tên đại lý/CTV/NVKD", 
                          dataIndex: "",
                          render: (e) => e.belong_to === 3 ? e.employee.fullname : e.owner.fullname
                        },
                        {
                          title: "MST/CMND", 
                          dataIndex: "",
                          render: (e) => e.belong_to === 1 ? e.owner.tax_code : (e.belong_to === 2 ? e.owner.passport : "")
                        },
                        {
                          title: "Người quản lý", 
                          dataIndex: "",
                          render: (e) => e.belong_to === 3 ? "" : e.user.fullname
                          
                        },
                        {
                          title: "Loại người dùng",
                          dataIndex: "belong_to",
                          render: renderTypeOwner
                        },
                        {
                          title: "Năm",
                          dataIndex: "year",
                          className: "text-center"
                        },
                        {
                          title: "Tháng",
                          dataIndex: "month",
                          className: "text-center"
                        },
                        {
                          title: "Trạng thái",
                          dataIndex: "status",
                          render: renderStatusColumn
                        },
                        {
                          title: "Ngày tạo",
                          dataIndex: "",
                          className: "text-center",
                          render: (e) => formatDateTime(e.created_at)
                        },
                        {
                          title: "Kết luận",
                          dataIndex: "",
                          className: "text-center",
                          render: onRenderActionResult,
                          
                        },
                        {
                          title: "File đối soát",
                          dataIndex: "",
                          className: "text-center",
                          // render: onRenderActionFile,
                          render: (e) => onRenderActionFile(e),
                        },
                        {
                          title: "Tác vụ",
                          dataIndex:"",
                          render: onRenderActionColumn
                        },
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(CrossChecking)
