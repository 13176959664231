export const CERT_STATUS = {
    1: {'text':'Hoạt động', 'color':'green'},
    2: {'text':"Chờ thu hồi", 'color':'magenta'},
    3: {'text':"Đã thu hồi", 'color':'red'},
    4: {'text':"Chờ hủy", 'color':'volcano'},
    5: {'text':"Đã hủy", 'color':'orange'},
    6: {'text':"Chờ dừng", 'color':'gold'},
    7: {'text':"Đã dừng", 'color':'geekblue'},
    8: {'text':"Đã xóa", 'color':'purple'},
};
export const CERT_OBJECT = {
    1: {'text':'Cấp mới', 'color':'green'},
    2: {'text':"Gia hạn", 'color':'magenta'},
    3: {'text':"Thay đổi thông tin", 'color':'red'},
};

export const CERT_DEVICE = {
    1: {'text':"HSM", 'color':'geekblue'},
    2: {'text':'Token', 'color':'green'},
};