import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class BannerService extends FetchAPI {
    public async list(data) {
        return await this.request(
            "cate/banner/index",
            data,
            TypeMethodContsantEnum.GET
        );
    }

    public async store(data) {
        return await this.request(
            "cate/banner/store",
            data,
            TypeMethodContsantEnum.POST
        );
    }

    public async delete(data) {
        return await this.request(
            "cate/banner/delete",
            data,
            TypeMethodContsantEnum.POST
        );
    }

    public async find(id) {
        return await this.request(
            `cate/banner/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
    }

    public async update(data) {
        return await this.request(
            "cate/banner/update",
            data,
            TypeMethodContsantEnum.POST
        );
    }
}

export default BannerService;
