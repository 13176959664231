import CateDiscount from "../../pages/cate-discount/CateDiscount"
import CateDiscountCreate from "../../pages/cate-discount/create/CateDiscountCreate"
import CateDiscountUpdate from "../../pages/cate-discount/update/CateDiscountUpdate"
import CateDiscountView from "../../pages/cate-discount/view/CateDiscountView"

const CATE_DISCOUNT_ROUTE = [
    {
        component: CateDiscount,
        link: "/danh-sach-chiet-khau",
        isExact: false,
        permission: 'cate-discount-list'
    },
    {
        component: CateDiscountCreate,
        link: "/danh-sach-chiet-khau/them-moi",
        isExact: false,
        permission: 'cate-discount-create'
    },
    {
        component: CateDiscountView,
        link: "/danh-sach-chiet-khau/chi-tiet/:id",
        isExact: false
    },
    {
        component: CateDiscountUpdate,
        link: "/danh-sach-chiet-khau/cap-nhat/:id",
        isExact: false
    }
];
export default CATE_DISCOUNT_ROUTE;

