import React from "react";

interface Props {
  onClick: () => void;
  tittle? : string;
  icon? : string;
}

export default function ActionButtonUpdate(props: Props) {
  return (
    // eslint-disable-next-line
    <a
      className="pointer text-primary ml-1 mr-1"
      title={(props.tittle) ? props.tittle : "Sửa"}
      onClick={props.onClick}
    >
      <i className={(props.icon) ? props.icon : "far fa-edit fa-lg"} />
    </a>
  );
}
