import React, {useEffect, useState} from "react"
import withNavbar from "../../../common/HOC/NavbarContainerHOC"
import {FormikProps, withFormik} from "formik"
import * as Yup from "yup"
import InputWithLabel from "../../../common/form/InputWithLabel"
import BackButton from "../../../common/button/BackButton"
import SubmitButton from "../../../common/form/SubmitButton"
import {History} from "history";
import SelectDateFormat from "../../../common/form/SelectDateFormat"
import TableAddRowDiscount from "../../../common/table/TableAddRowDiscount"
import SelectBox from "../../../common/form/SelectBox"
import ClickButton from "./../../../common/button/ClickButton"
import {getDateCurrent} from "./../../../../helpers/NewCaHelper"
import {onSuccessAction} from "../../../../helpers/SwalCommon"
import {onFailAction} from "./../../../../helpers/SwalCommon"
import CateDiscountService from "../../../../services/cate-discount/CateDiscountService"

interface FormValues {
    code: string;
    name: string;
    type: string;
    belong_to: string;
    owner_id: string;
    date_begin: string;
    date_end: string;
    dataDiscount: object;
}

interface OtherProps {
    history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const CateDiscountCreate: React.FC<Props> = props => {
    const [dataDiscountState, setdataDiscountState] = useState({})
    const [keyDelete, setKeyDelete] = useState({})
    const [checkType, setCheckType] = useState(true)
    const [agency, setAgency] = useState({})
    const {
        code,
        name,
        type,
        owner_id,
        belong_to,
        date_begin,
        date_end,
        dataDiscount
    } = props.values;

    const getAgency = async belong_to => {
        const service = new CateDiscountService()
        const lst = await service.getAgencyContributor(belong_to)
        if (lst) {
            let agency = {}
            lst.data.map(value => (agency[value.id] = value.fullname))
            setAgency(agency)
            props.setFieldValue("owner_id", "")
        }
    }

    const onChangeSelectDateBegin = (date, dateString) => {
        props.setFieldValue("date_begin", dateString);
    }
    const onChangeSelectDateEnd = (date, dateString) => {
        props.setFieldValue("date_end", dateString);
    }

    const changedataDoc = e => {
        let key = $(e.target)
        .parent()
        .parent()
        .parent()
        .data("row-key")
        let value = e.target.value
        dataDiscountState[key][$(e.target).data("key")] = value
        setdataDiscountState(dataDiscountState);
        props.setFieldValue("dataDiscount", dataDiscountState)
    };

    const onClickDeleteGift = () => {
        let newDataDelete = removeObjectByKey(dataDiscountState);
        const arr: string[] = [];
        $.each(newDataDelete, function (key, value) {
            if (value) {
                arr.push(value);
            }
        });
        setdataDiscountState(arr);
        props.setFieldValue("dataDiscount", arr);
    };

    const onChangeSelectedType = (e) => {
        if (Number(e)===2){
            setCheckType(false)
        }
        if (Number(e)===1){
            setCheckType(true)
            props.setFieldValue("belong_to",undefined)
            props.setFieldValue("owner_id",undefined)
        }
        if (!e) {
            setCheckType(true)
            props.setFieldValue("belong_to",undefined)
            props.setFieldValue("owner_id",undefined)
        }
        props.setFieldValue("type", e)
    }; 
    const onChangeSelectedOwnerId = (e) => {
        props.setFieldValue("owner_id",e)
    }

    const onChangeSelectedBelongTo = (e) => {
        if (!e) {
            setAgency({})
        }
        if(Number(e)===1) {
            getAgency(1)
        }
        if(Number(e)===2){
            getAgency(2)
        }
        props.setFieldValue("belong_to", e);
    }

    const getKeyDelete = e => {
        let key = $(e.target)
        .parent()
        .parent()
        .parent()
        .data("row-key");
        setKeyDelete(key);
    };
    const removeObjectByKey = arr => {
        delete arr[Number(keyDelete)];
        return arr;
    };

    const setDataFirst = () => {
        const dataDiscount = [
            {
                key: 0,
                number: "",
                percent_new:"",
                percent_extension:"",
                percent_change:"",
                token_price_3_year: 0
            }
        ];
        setdataDiscountState(dataDiscount);
        props.setFieldValue("dataDiscount", dataDiscount);
    };

    useEffect(() => {
        getDateCurrent("-");
        setDataFirst();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickAddHandleAsset = async () => {
        let key = Object.keys(dataDiscountState).length;
        let newData = {
            key: key,
            number: "",
            percent_new: "",
            percent_extension: "",
            percent_change: "",
            token_price_3_year: 0,
        };
        var totalData = {...dataDiscountState, [key]: newData};
        setdataDiscountState(totalData);
        props.setFieldValue("dataDiscount", totalData);
    };
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Thêm mới chiết khấu</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="name"
                            label="Tên chiết khấu"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={name}
                            error={props.errors.name}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="code"
                            label="Mã chiết khấu"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                        />
                        <SelectDateFormat
                            label="Ngày bắt đầu"
                            name="date_begin"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectDateBegin}
                            value={date_begin}
                            error={props.errors.date_begin}
                            isRequired={true}
                            placeholder={"dd-mm-yyyy"}
                        />
                        <SelectDateFormat
                            label="Ngày kết thúc"
                            name="date_end"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectDateEnd}
                            value={date_end}
                            error={props.errors.date_end}
                            isRequired={true}
                            placeholder={"dd-mm-yyyy"}
                        />
                    </div>
                    <div className="input-group">
                        <SelectBox
                            options={{1: "Chiết khấu thông thường", 2: "Chiết khấu đặt biệt"}}
                            label="Loại chiết khấu"
                            name="type"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectedType}
                            value={type}
                            firstOptionLabel="Loại chiết khấu"
                            error={props.errors.type}
                            isRequired={true}
                        />
                        <SelectBox
                            options={{1: "Đại lý", 2: "Cộng tác viên"}}
                            label="Loại người dùng"
                            name="belong_to"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectedBelongTo}
                            value={belong_to}
                            firstOptionLabel="Loại ngời dùng"
                            error={props.errors.belong_to}
                            hidden={checkType}
                            // isRequired={true}
                        />
                        <SelectBox
                            options={agency}
                            label="Tên đại lý/ CTV"
                            name="owner_id"
                            wrappedClass="col-md-3"
                            onChange={onChangeSelectedOwnerId}
                            value={owner_id}
                            firstOptionLabel="Tên đại lý/ CTV"
                            error={props.errors.owner_id}
                            hidden={checkType}
                            // isRequired={false}
                        />
                    </div>

                    <div className="input-group col-md-12 m-t-20">
                        <div className="col-md-11 p-l-0"/>
                        <div className="col-md-1 p-r-0">
                            <ClickButton
                                text="Thêm"
                                css="btn btn-success ml-1 mr-1 font-btn float-right"
                                onClick={onClickAddHandleAsset}
                            />
                        </div>
                        <TableAddRowDiscount
                            data={dataDiscount}
                            changeData={changedataDoc}
                            onDelete={onClickDeleteGift}
                            keyDelete={getKeyDelete}
                            errors={props.errors}
                            tittle={"Số lượng thuê bao"}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton history={props.history}
                                    url="/danh-sach-chiet-khau"/>
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        name: "",
        type: "",
        belong_to: "",
        owner_id: "",
        date_begin: "",
        date_end: "",
        dataDiscount:{}
    }),
    validationSchema: Yup.object().shape({
        type: Yup.string().required("Loại chiết khấu không được bỏ trống"),
        code: Yup.string().required("Mã chiết khấu không được bỏ trống"),
        name: Yup.string().required("Tên chiết khấu không được bỏ trống"),
        date_begin: Yup.string().required("Ngày bắt đầu không được trống"),
        date_end: Yup.string().required("Ngày kết thúc không được trống")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateDiscountService();
        const result = await service.store(values);
        if (result && result.status === 200) {
            onSuccessAction("Thêm mới thành công!", function () {
                props.history.push(`/danh-sach-chiet-khau`);
            });
        } else if (result && result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(CateDiscountCreate);

export default withNavbar()(RenderCreateForm);
