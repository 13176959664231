import React, {Component} from "react";
import "../css/main.css";
import InputWithLabel from "../form/InputWithLabel";
import {Popconfirm} from "antd";
import SelectDate from "../form/SelectDate";
import SelectBox from "../form/wh/SelectBox";

interface Props {
    assetList?: any;
    changeData?: any;
    errors?: any;
    data?: any;
    onDelete?: any;
    keyDelete?: any;
    tittle?: string;
    changeSelectDate?: any;
    isDisabled?: boolean;
    isHidden?:boolean;
}

interface State {
}

export default class Table extends Component<Props, State> {
    render() {
        const dataSource = this.props.data;
        console.log(dataSource)

        return (
            <table className="w-100 m-t-5 ant-table-bordered" key="2">
                <thead className="ant-table-thead">
                <tr>
                    <th className="text-center w-5">
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">STT</span>
                            </div>
                        </span>
                    </th>
                    <th className="text-center w-25">
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">Serial CTS (*)</span>
                            </div>
                        </span>
                    </th>
                    <th className="text-center w-15">
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">Loại (*)</span>
                            </div>
                        </span>
                    </th>
                    <th className="text-center w-20">
                          <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Mã số thuế/CMND (*)</span>
                                </div>
                          </span>
                    </th>
                    <th className="text-center w-20">
                          <span className="ant-table-header-column">
                                <div>
                                    <span className="ant-table-column-title">Email (*)</span>
                                </div>
                          </span>
                    </th>
                    <th className="text-center w-20">
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">Tên khách hàng (*)</span>
                            </div>
                        </span>
                    </th>
                    <th className="text-center w-20">
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">Trạng thái CTS (*)</span>
                            </div>
                        </span>
                    </th>
                    <th className="text-center w-20">
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">Ngày cài đặt (*)</span>
                            </div>
                        </span>
                    </th>
                    <th className="text-center w-15" hidden={this.props.isHidden}>
                        <span className="ant-table-header-column">
                            <div>
                                <span className="ant-table-column-title">Tác vụ</span>
                            </div>
                        </span>
                    </th>
                </tr>
                </thead>
                <tbody className="ant-table-tbody">

                {dataSource ? (
                    Object.keys(dataSource).map((key, index) => (
                        <tr
                            className={`ant-table-row editable-row ant-table-row-level-${key}`}
                            data-row-key={key}
                            key={key}
                        >
                            <td className="text-center">{1 + Number(key)}</td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`serial${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["serial"]}
                                    isRequired={true}
                                    maxLength={500}
                                    dataKey={"serial"}
                                    placeholder="Serial CTS"
                                    error={
                                        this.props.errors.serial &&
                                        dataSource[key]["serial"] === ""
                                            ? "Serial không được trống"
                                            : ""
                                    }
                                    isDisabled={this.props.isDisabled}
                                />
                            </td>
                            <td className="text-center">
                                <SelectBox
                                    name={`co${key}`}
                                    options={{
                                        1: 'Cá nhân',
                                        2: 'Tổ chức'
                                    }}
                                    label=""
                                    firstOptionLabel={"Chọn"}
                                    wrappedClass=""
                                    onChange={this.props.changeData}
                                    value={dataSource[key]["co"]}
                                    dataKey={"co"}
                                    error={
                                        (this.props.errors.co &&
                                        !dataSource[key]["co"])
                                            ? "Loại không được trống"
                                            : ""
                                    }
                                    isRequired={true}
                                    isDisabled={this.props.isDisabled }
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`tax_code${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["tax_code"]}
                                    isRequired={true}
                                    maxLength={15}
                                    dataKey={"tax_code"}
                                    placeholder="Mã số thuế/CMND"
                                    error={
                                        (this.props.errors.tax_code &&
                                            dataSource[key]["tax_code"] === "")
                                            ? "Mã số thuế không được trống"
                                            : ""
                                            ||
                                            (this.props.errors.data_customer &&
                                                dataSource[key]["tax_code"].length < 9)
                                            ? "Mã số thuế phải từ 9 ký tự"
                                            : ""
                                    }
                                    isDisabled={this.props.isDisabled}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`email${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["email"]}
                                    isRequired={true}
                                    maxLength={255}
                                    dataKey={"email"}
                                    placeholder="Email"
                                    error={
                                        (this.props.errors.email &&
                                            dataSource[key]["email"] === "")
                                            ? "Email không được trống"
                                            : "" || (this.props.errors.data_customer && this.props.errors.data_customer[0] === "email")
                                            ? "Định dạng email abc@abc.xyz"
                                            : ""
                                    }
                                    isDisabled={this.props.isDisabled}
                                />
                            </td>
                            <td className="text-center">
                                <InputWithLabel
                                    name={`customer_name${key}`}
                                    label=""
                                    wrapClass=""
                                    onChangeValue={this.props.changeData}
                                    value={dataSource[key]["customer_name"]}
                                    isRequired={true}
                                    maxLength={255}
                                    dataKey={"customer_name"}
                                    placeholder="Tên khách hàng"
                                    error={
                                        this.props.errors.customer_name &&
                                        dataSource[key]["customer_name"] === ""
                                            ? "Tên khách hàng không được trống"
                                            : ""
                                    }
                                    isDisabled={this.props.isDisabled}
                                />
                            </td>
                            <td className="text-center">
                                <SelectBox
                                    name={`status_cts${key}`}
                                    options={{
                                        1: "Hoạt động",
                                        2: "Đã thu hồi",
                                        3: "Đã hủy",
                                        4: "Đã tạm dừng",
                                        8: "Đã xóa",
                                        9: "Đã hết hạn",
                                        10: "Chờ khách hàng ký xác nhận thu hồi",
                                        11: "Chờ nghiệp vụ duyệt thu hồi",
                                        15: "Chờ khách hàng ký xác nhận hủy",
                                        16: "Chờ nghiệp vụ duyệt hủy",
                                        20: "Chờ khách hàng ký xác nhận tạm dừng",
                                        21: "Chờ nghiệp vụ duyệt tạm dừng",
                                        25: "Chờ thay đổi thông tin",
                                        26: "Chờ cấp lại thiết bị",
                                        27: "Chờ bảo hành thiết bị",
                                    }}
                                    label=""
                                    wrappedClass=""
                                    firstOptionLabel={"Chọn"}
                                    onChange={this.props.changeData}
                                    value={dataSource[key].status_cts ? dataSource[key].status_cts : ""}
                                    dataKey={"status_cts"}
                                    error={
                                        (this.props.errors.status_cts &&
                                        dataSource[key]["status_cts"] === "")
                                            ? "Trạng thái cts không được trống"
                                            : ""
                                    }
                                    isRequired={true}
                                    isDisabled={this.props.isDisabled}
                                />
                            </td>
                            <td className="text-center">
                                <SelectDate
                                    label=""
                                    name={`setting_date${key}`}
                                    wrappedClass=""
                                    onChange={
                                        (date, dateString) => this.props.changeSelectDate(date, dateString, key)
                                    }
                                    value={(dataSource[key]["setting_date"]) || ""}
                                    isRequired={true}
                                    placeholder="Ngày cài đặt"
                                    isDisabled={this.props.isDisabled}
                                    error={
                                        this.props.errors.setting_date &&
                                        dataSource[key]["setting_date"] === ""
                                            ? "Ngày cài đặt không được bỏ trống"
                                            : ""
                                    }
                                />
                            </td>
                            <td className="text-center" hidden={this.props.isHidden}>
                                {dataSource[key]['token_manager_id'] || dataSource[key]['key'] === 0 ? ("") :
                                    (<Popconfirm
                                        title="Chắc chắn xóa?"
                                        onConfirm={this.props.onDelete}
                                        okText="Xóa"
                                        cancelText="Bỏ qua"
                                    >
                                        <a
                                            href="/#"
                                            className="pointer text-danger ml-1 mr-1"
                                            title="Xóa"
                                            onClick={this.props.keyDelete}
                                        >
                                            <i className="fas fa-trash-alt fa-lg"/>
                                        </a>
                                    </Popconfirm>)
                                }
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr/>
                )}
                </tbody>
            </table>
        );
    }
}
