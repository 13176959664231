import React, {Component} from "react";
import {Modal, Table} from "antd";

interface Props {
    handleCancel?: any;
    data?: any;
    visible?: boolean;
    loading?: boolean;
    title?: string;
}

interface State {
    paginate: any;
}

export default class ModalViewTokenDetail extends Component<Props, State> {
    state = {
        paginate: { pageSize: 10, current: 1 },
    };
    render() {
        const columns = [
            {
                title: "STT",
                key: "index",
                render: (text, record, index) => (this.state.paginate.current - 1) * this.state.paginate.pageSize + index + 1,
                className: "text-center",
                width: "10%"
            },
            {
                title: "Mã token",
                dataIndex: "token_code",
                key: "code",
                width: "30%",
                className: "text-center"
            },
        ];
        
        const onChangeData = (e) => {
            const paginate = { ...this.state.paginate };
            paginate.current = Number(e.current);
            paginate.pageSize = 10;
            this.setState({paginate:paginate});
        };
        return (
            <div>
                <Modal
                    title={this.props.title}
                    visible={this.props.visible}
                    onOk={this.props.handleCancel}
                    cancelButtonProps={{hidden: true}}
                    zIndex={0}
                    destroyOnClose={true}
                    okText="Đóng"
                    className="modal-sm"
                    width="50%"
                    closable={false}
                >
                    <Table
                        dataSource={
                            this.props.data ? this.props.data : []
                        }
                        columns={columns}
                        className={"ant-table-bordered"}
                        onChange={onChangeData}
                    />
                </Modal>
            </div>
        );
    }
}
