import React, { Component } from "react";
import { Table } from "antd";

interface Props {
  handleCancel?: any;
  data?: any;
  visible?: boolean;
  loading?: boolean;
  title?: string;
}
interface State {
  visible: boolean;
}
const columns = [
  {
    title: "STT",
    key: "key",
    render: (text, record, index) => index + 1,
    className: "text-center",
    width: "10%",
  },
  {
    title: "Quà tặng",
    dataIndex: "gift_asset_name",
    key: "gift_asset_id",
  },
  {
    title: "Đơn vị",
    dataIndex: "gift_unit",
    className: "text-center",
    key: "gift_unit",
  },
  {
    title: "Số lượng",
    dataIndex: "gift_amount",
    className: "text-center",
    key: "gift_amount",
  },
];
export default class TokenList extends Component<Props, State> {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Table
          className={"w-100 m-t-20 ant-table-bordered"}
          columns={columns}
          dataSource={this.props.data ? this.props.data : []}
          rowKey="id2"
        />
      </div>
    );
  }
}
