import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import { STATUS_LABEL_CLASS } from "../Enum";
import { formatDateTime, formatDateTimeAll } from "../../../../helpers/NewCaHelper";
import CateService from "../../../../services/service/Service";
import {
  onDeleteAlert,
  onSuccessAction
} from "../../../common/swal/SwalCommon";
import { reloadPage } from "../../../../helpers/NewCaHelper";

interface Props {
  location: Location;
  history: History;
  match: match;
}
interface State {}

export default class MainTable extends Component<Props, State> {
  state = {};
  service = new CateService();
  componentDidMount() {
    this.fetch();
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getServices(params);
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  onClickDeleteButton = id => {
    const self = this;
    onDeleteAlert(async function(willDelete) {
      if (willDelete) {
        const result = await self.service.deleteServiceById(id);
        if (result && result.status === 200) {
          onSuccessAction(
            "Xóa dịch vụ thành công!",
            reloadPage(self.props.location, self.props.history)
          );
        }
      }
    });
  };

  render() {
    return (
      <Table
        columns={this.columns}
        onFetchData={this.fetch}
      />
    );
  }

  onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickLock={() => this.onClickDeleteButton(record.id)}
        onClickUpdate={() =>
          this.props.history.push(`/dich-vu/cap-nhat/${record.id}`)
        }
        isDisplayLockButton={true}
        isDisplayUpdateButton={true}
      />
    );
  };

  renderStatusColumn = text => {
    return (
      <div>
        <span className={`badge ${STATUS_LABEL_CLASS[text].class} ml-2`}>
          {STATUS_LABEL_CLASS[text].label}
        </span>{" "}
      </div>
    );
  };

  columns = [
    { title: "Tên dịch vụ", dataIndex: "name" },
    { title: "Mã dịch vụ", dataIndex: "code" },
    {
      title: "Ngày hiệu lực",
      dataIndex: "start_date",
      render: text => formatDateTime(text)
    },
    {
      title: "Ngày hết hiệu lực",
      dataIndex: "end_date",
      render: text => formatDateTime(text)
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: this.renderStatusColumn
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: text => formatDateTimeAll(text)
    },

    {
      title: "Tác vụ",
      render: this.onRenderActionColumn
    }
  ];
}
