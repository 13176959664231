import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {TypeWhToken} from "../Enum";
import {Input} from "antd";
import SelectDate from "../../../common/form/SelectDate";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import UserService from "../../../../services/user/UserService";
import SelectBox from "../../../common/form/SelectBox";
import {getDateCurrent} from "../../../../helpers/NewCaHelper";
import WhTokenExportService from "../../../../services/whTokenExport/WhTokenExportService";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import TokenList from "./TokenList";
import DocList from "./DocList";
import GiftList from "./GiftList";
import ModalViewTokenList from "../exportDetail/ModalViewTokenList";

const {TextArea} = Input;

interface FormValues {
    code: string;
    note: string;
    exporter: string;
    exporter_pos: string;
    type: string;
    location: string;
    receiver: string;
    export_date: string;
    created_date: string;
    owner_id: string;
    belong_to: string;
    dataToken: object;
    dataDoc: object;
    dataGift: object;
}

interface OtherProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const WhTokenCreate: React.FC<Props> = (props) => {
    const id = props.match.params.id;
    const [agency, setAgency] = useState({});
    const [dataTokenState, setDataTokenState] = useState({
        data: [],
        total: "",
    });
    const [dataDocState, setDataDocState] = useState({
        data: [],
    });
    const [dataGiftState, setDataGiftState] = useState({
        data: [],
    });

    const {
        code,
        note,
        exporter,
        location,
        receiver,
        export_date,
        owner_id,
        belong_to,
    } = props.values;

    const getAgency = async (type) => {
        const userService = new UserService();
        const lst = await userService.getAgencyContributor(type);
        if (lst) {
            let agency = {};
            lst.data.map((value) => (agency[value.id] = value.fullname));
            setAgency(agency);
        }
    };

    const fetchCodeExport = async (date) => {
        const getCodeService = new WhTokenExportService();
        const result = await getCodeService.getCode(date);
        if (result && result.status === 200) {
            props.setFieldValue("code", result.data);
        }
    };

    useEffect(() => {
        const date = getDateCurrent("-");
        fetchCodeExport(date);
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const onFetchData = async () => {
        const service = new WhTokenExportService();
        const result = await service.getDetail(id);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        let type = 7;
        if (result.data.belong_to === 2){
            type = 8;
        }
        getAgency(type);
        if (result.data.wh_export_detail) {
            const dataDetail = result.data.wh_export_detail;
            const dataDetailToken = result.data.wh_export_detail_token;
            const dataTokenTemp = [] as any;
            const dataDocTemp = [] as any;
            const dataGiftTemp = [] as any;
            for (let key in dataDetailToken) {
                const temp1 = {};
                temp1["key"] = `token` + key;
                temp1["type"] = dataDetailToken[key].type_token;
                temp1["version_type"] = dataDetailToken[key].version_token_code;
                temp1["amount"] = dataDetailToken[key].amount;
                temp1["price"] = dataDetailToken[key].price;
                temp1["total"] = 0;
                dataTokenTemp.push(temp1);
            }
            for (let key in dataDetail) {
                if (dataDetail[key].type === 2) {
                    const temp2 = {};
                    temp2["key"] = `doc` + key;
                    temp2["doc_asset_id"] = dataDetail[key].wh_document_id;
                    temp2["doc_asset_name"] = dataDetail[key].doc_category.name;
                    temp2["doc_unit"] = dataDetail[key].unit;
                    temp2["doc_amount"] = dataDetail[key].total_document;
                    temp2["doc_price"] = 0;
                    temp2["doc_total"] = 0;
                    dataDocTemp.push(temp2);
                }

                if (dataDetail[key].type === 3) {
                    const temp3 = {};
                    temp3["key"] = `gift` + key;
                    temp3["gift_asset_id"] = dataDetail[key].wh_gift_id;
                    temp3["gift_asset_name"] = dataDetail[key].gift_category.name;
                    temp3["gift_unit"] = dataDetail[key].unit;
                    temp3["gift_amount"] = dataDetail[key].total_gift;
                    temp3["gift_price"] = key;
                    temp3["gift_total"] = key;
                    dataGiftTemp.push(temp3);
                }
            }

            setDataTokenState({
                ...dataTokenState,
                data: dataTokenTemp,
                total: result.data.wh_export_total_price,
            });
            setDataDocState({
                ...dataDocState,
                data: dataDocTemp,
            });
            setDataGiftState({
                ...dataGiftState,
                data: dataGiftTemp,
            });
        }
    };
    const [modalDetailToken, setModalDetailToken] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        data: {}
    });
    const handleModalDetailCancel = () => {
        setModalDetailToken({ ...modalDetailToken, visible_modal: false });
    };
    const onClickModalDetail = async (type) => {
        const service = new WhTokenExportService();
        const result = await service.getListToken(id, type);
        if (result && result.status === 200){
            setModalDetailToken({
                ...modalDetailToken,
                visible_modal: true,
                loading: true,
                title_modal: "Xem danh sách token xuất kho",
                data: result.data
            });
        }
    };
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Xem chi tiết phiếu xuất kho token</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            label="Số hóa đơn"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectBox
                            options={TypeWhToken.TYPEAGENCY}
                            label="Loại người dùng"
                            name="belong_to"
                            wrappedClass="col-md-3"
                            value={belong_to}
                            firstOptionLabel="Lựa chọn"
                            error={props.errors.belong_to}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBoxAgency
                            options={agency}
                            label="Đại lý/cộng tác viên"
                            name="owner_id"
                            wrappedClass="col-md-3"
                            value={owner_id}
                            firstOptionLabel="Chọn đại lý/cộng tác viên"
                            error={props.errors.owner_id}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="exporter"
                            label="Người xuất kho"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={exporter}
                            error={props.errors.exporter}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="receiver"
                            label="Người nhận hàng"
                            wrapClass="col-md-3"
                            onChangeValue={props.handleChange}
                            value={receiver}
                            error={props.errors.receiver}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="location"
                            label="Tại kho"
                            wrapClass="col-md-6"
                            onChangeValue={props.handleChange}
                            value={location}
                            error={props.errors.location}
                            isRequired={false}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectDate
                            label="Ngày xuất kho"
                            name="export_date"
                            wrappedClass="col-md-3"
                            value={export_date}
                            error={props.errors.export_date}
                            isRequired={true}
                            placeholder={"yyyy-mm-dd"}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group">
                        <div className="col-md-12">
                            <label>Về việc</label>
                            <TextArea
                                name="note"
                                placeholder="Ghi chú"
                                autoSize={{minRows: 4, maxRows: 10}}
                                className="form-control"
                                required={false}
                                value={note}
                                onChange={props.handleChange}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="input-group col-md-12 m-t-20">
                        <div className="col-md-11 p-l-0">
                            <h5>Token</h5>
                        </div>
                        <div className="col-md-12 p-l-0">
                            <TokenList
                                data={dataTokenState.data}
                                total={dataTokenState.total}
                                onClickViewTokenRa={() => onClickModalDetail(1)}
                                onClickViewTokenCTS={() => onClickModalDetail(2)}
                            />
                        </div>
                    </div>
                    {Object.keys(dataDocState.data).length !== 0 ? (
                        <div className="input-group col-md-12 m-t-20">
                            <div className="col-md-11 p-l-0">
                                <h5>Tài liệu bán hàng</h5>
                            </div>
                            <div className="col-md-12 p-l-0">
                                <DocList data={dataDocState.data}/>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {Object.keys(dataGiftState.data).length !== 0 ? (
                        <div className="input-group col-md-12 m-t-20">
                            <div className="col-md-11 p-l-0">
                                <h5>Quà tặng</h5>
                            </div>
                            <div className="col-md-12 p-l-0">
                                <GiftList data={dataGiftState.data}/>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton history={props.history}/>
                    </div>
                </form>
            </div>
            <ModalViewTokenList
                visible={modalDetailToken.visible_modal}
                handleCancel={handleModalDetailCancel}
                title={modalDetailToken.title_modal}
                data={modalDetailToken.data}
                loading={modalDetailToken.loading}
            />
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        note: "",
        exporter: "",
        exporter_pos: "",
        type: "",
        location: "",
        receiver: "",
        export_date: "",
        created_date: "",
        owner_id: "",
        belong_to: "",
        dataToken: {},
        dataDoc: {},
        dataGift: {},
    }),
    validationSchema: Yup.object().shape({
        belong_to: Yup.string().required("Phải lựa chọn loại người dùng."),
        owner_id: Yup.string().required(
            "Đại lý/ cộng tác viên không thể bỏ trống."
        ),
        exporter: Yup.string().required("Người xuất kho không thể bỏ trống."),
        receiver: Yup.string().required("Người nhận không thể bỏ trống."),
        export_date: Yup.date().required("Ngày xuất kho không được trống"),
        created_date: Yup.date().required("Ngày lập phiếu không được trống"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new WhTokenExportService();
        const result = await service.store(values);
        if (result && result.status === 200) {
            onSuccessAction("Nhập kho token thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    },
})(WhTokenCreate);

export default withNavbar()(RenderCreateForm);
