import RequestChangeInfoCertificate from "../../pages/request-change-info-certificate/RequestChangeInFoCertificate";
import RequestChangeInfoCertificateViewPersonal from "../../pages/request-change-info-certificate/RequestChangeInfoCertificateViewPersonal";
import RequestChangeInfoCertificateViewGroup from "../../pages/request-change-info-certificate/RequestChangeInfoCertificateViewGroup";

const CHANGE_INFO_CERTIFICATE_ROUTES = [
    {
        component: RequestChangeInfoCertificate,
        link: "/thay-doi-thong-tin-chung-thu-so",
        isExact: false,
        permission: 'nv-request-change-info-certificate'
    },
    {
        component: RequestChangeInfoCertificateViewPersonal,
        link: "/thay-doi-thong-tin-chung-thu-so/chi-tiet/ca-nhan/:id",
        isExact: false,
        permission: 'nv-request-change-info-certificate'
    },
    {
        component: RequestChangeInfoCertificateViewGroup,
        link: "/thay-doi-thong-tin-chung-thu-so/chi-tiet/to-chuc/:id",
        isExact: false,
        permission: 'nv-request-change-info-certificate'
    },
];

export default CHANGE_INFO_CERTIFICATE_ROUTES;

