import React, { Component } from "react";
import "../css/main.css";

interface Props {
    onClick: any;
}
interface State {}

export default class ButtonImportExcel extends Component<Props, State> {
    state = {};
    
    render() {
        return (
            <button type="button" className="btn btn-info mr-1 ml-1 font-btn" onClick={this.props.onClick}>
                <i className="fas fa-file-upload fa-lg"/> Import
            </button>
        );
    }
}