import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {onFailAction, onSuccessAction, onWarningAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import CateContractService from "../../../../services/category-contract/CateContractService";
import ModalView from "../../../common/form/Modal";
import RequestCertificateService from "../../../../services/request-certificate/RequestCertificateService";
import Radio from "../../../common/form/Radio";
import SubmitButton from "../../../common/form/SubmitButton";
import ModalDeny from "../../../common/form/ModalDeny";
import {Card} from "antd";
import {loading} from "../../../common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";
import SelectDate from "../../../common/form/SelectDate";
import _ from "lodash";
import SelectBox from "../../../common/form/SelectBox";
import InputFileUploadPreview from "../../../common/form/InputFileUploadPreview";
import {formatMoney, handleFormatString} from "../../../../helpers/NewCaHelper";
import TextAreaWithLabel from "../../../common/form/TextAreaWithLabel";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface FormValues {
    type_legal: number,
    code: string,
    provide_date: string,
    provide_organization: string,
    tax_code: string,
    fullname: string,
    address: string,
    email: number,
    phone: string,
    represent_fullname: string,
    represen_passport: string,
    represen_passport_date: string,
    represen_passport_place: string,
    represen_position: string,
    represen_email: string,
    sp_fullname: string,
    sp_position: string,
    sp_email: string,
    sp_phone: string,
    type_device: number,
    object: number,
    package_price: string,
    file_register: string,
    support_register: number,
    status: number,
    reason: string,
    package_id: string,
    file: string,
    secret_code: string,
    password: string,
    represen_phone: string,
    type_docurment: number,
    province_code: string,
    district_code: string,
    file_legal: string,
    file_deputy_passport: string,
    file_register_paper: string,
    serial_cts: string,
    promotion_code: string,
    token_type: number,
    provider_id: number,
    identity_type:number,
    quantity:number,
    version_token_newtel:number,
    hsm_provider:number,
    date_expired_cert: string,
    type_extend?:number
}

interface OtherProps extends RouteComponentProps<{ id: string; }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const RequestCertificateViewGroup: React.FC<Props> = props => {
    const service = new RequestCertificateService();
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    const {
        type_legal,
        code,
        provide_date,
        provide_organization,
        tax_code,
        fullname,
        address,
        email,
        phone,
        represent_fullname,
        represen_passport,
        represen_passport_date,
        represen_passport_place,
        represen_position,
        represen_email,
        type_device,
        package_price,
        status,
        reason,
        package_id,
        secret_code,
        represen_phone,
        type_docurment,
        province_code,
        district_code,
        serial_cts,
        token_type,
        provider_id,
        identity_type,
        quantity,
        promotion_code,
        version_token_newtel,
        password,
        hsm_provider,
        date_expired_cert,
        type_extend
    } = props.values;
    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });
    // const [document, setDocument] = useState(0);
    const [statusGen, setStatusGen] = useState(false);
    const [statusDK03, setStatusDK03] = useState(false);
    const [fileLegal, setFileLegal] = useState("");
    const [fileDeputyPassport, setFileDeputyPassport] = useState("");
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState([]);
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [objectRequest, setObjectRequest] = useState(0);
    const [dataChanged, setDataChanged] = useState(false);
    const [fileRegisterPaper, setFileRegisterPaper] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số thuế");
    const [versionToken, setVerionToken] = useState({});
    const [linkReSign, setLinkReSign] = useState(false);
    const [link, setLink] = useState("");
    const [secretCode, setSecretCode] = useState("");


    const id = props.match.params.id;
    const onFetchData = async () => {
        loading.runLoadingBlockUI();
        const result = await service.getGroupRequest(id);
        if(result.isExit === true){
            const sameIden = result.sameIden.toString();
            const txt = "Yêu cầu này đang trùng với yêu cầu:\n" + sameIden;
            onWarningAction(txt)
        }
        if (result.data.status === 3 ||result.data.status === 4 || result.data.status === 5 || result.data.status === 6){
            setIsDisable(true);
        }
        if (Number(result.data.organization.identity_type) === 1){
            setLabelCode("Mã số thuế");
        }if (Number(result.data.organization.identity_type) === 2){
            setLabelCode("Mã ngân sách");
        }if (Number(result.data.organization.identity_type) === 3){
            setLabelCode("Số quyết định");
        }if (Number(result.data.organization.identity_type) === 4){
            setLabelCode("Mã bảo hiểm xã hội");
        }if (Number(result.data.organization.identity_type) === 5){
            setLabelCode("Giấy phép đầu tư");
        }
        setObjectRequest(result.data.object);
        // setDocument(result.data.file_status);
        // if(Number(result.data.status) === 5){
        //     setStatusGen(true);
        // }
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        for (let key in result.data.organization) {
            props.setFieldValue(key, result.data.organization[key]);
        }
            for (let key in result.data.requestsupport) {
                props.setFieldValue(`sp_${key}`, result.data.requestsupport[key]);
            }
        let arrAll:any = [];
        result.data.list_document.forEach(function (values) {
            if (Number(values.type) === 7 ){
                setLinkReSign(true);
                setLink(result.domain+"/re-sign-dk02/"+ btoa(id) +"/1")
                setSecretCode(result.data.password);
            }
            if (values.type === 3){
                const arrName = values.file.split('/');
                setFileRegisterPaper(arrName['3']);
                props.setFieldValue("file_register_paper", arrName['3']);
            }
            if (values.type === 5){
                const arrName = values.file.split('/');
                setFileLegal(arrName['3']);
                props.setFieldValue("file_legal", arrName['3']);
            }
            if (values.type === 6){
                const arrName = values.file.split('/');
                setFileDeputyPassport(arrName['3']);
                props.setFieldValue("file_deputy_passport", arrName['3']);

            }
            arrAll[values.id] = values.type;
        });
        arrAll.forEach(function(item, index, array) {
            if(Number(item) === 7){
                setStatusGen(true);
            }if(Number(item) === 14){
                setStatusDK03(true);
            }
        });
        // setProvinceCode(result.data.)
        const options = {};
        options[result.data.district.newtel_district_code] = result.data.district.fullname;
        setDistrict(options);

        const type_search = 4;
        const type = result.data.object; //Đối tượng
        const object = 3;
        const type_device = result.data.type_device;
        const data = await getCateServicePackage(type_search, type, object, type_device, result.data.provider_id, result.data.hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        loading.stopRunLoading();
    };
    const getCateServicePackage = async (type_search, type, obj, type_device, provider_id, hsm_provider) =>{
        return await service.getListCateServicePackage(type_search, type, obj,type_device, provider_id, hsm_provider);
    };
    const getAllProvider = async () =>{
        const data = await service.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await service.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    useEffect(() => {
        onFetchData();
        getProvince();
        getAllProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);

    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false})
    };

    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim() === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.getDeny(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/yeu-cau-chung-thu-so");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };
    // const submitDeny = () => {
    //     setModalDeny({...modalDeny, 'visible_modal_deny': true});
    // };

    // const submitAccept = (type) => {
    //     let title = "Bạn có chắc chắn muốn duyệt yêu cầu ?"
    //     if(type === 2){
    //         title = "Bạn có chắc chắn muốn sửa và duyệt yêu cầu ?"
    //     }
    //     onAcceptRequestCTS(async willDelete => {
    //         if (willDelete) {
    //             try {
    //                 submitApprove(type)
    //             } catch (error) {
    //                 console.log(error);
    //         onFailAction();
    //             }
    //         }
    //     }, title);
    // };

    const onViewFileDoc = async type => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token){
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/yeu-cau-chung-thu-so/getFileDoc/${password}/${type}?token=${obj.token}`;
                window.open(link);
            }

        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };

    //tai file
    const onChangeLegalFile = async e => {
        if (e) {
            setDataChanged(true);
        }
        const files = e.target.files[0];
            if (files) {
                const fileSize = files.size / 1024 / 1024;
                if (fileSize > 2) {
                    onFailAction("File tải lên không thể lớn hơn 2MB", () => {
                        setFileLegal("");
                        props.setFieldValue("file_legal", '');
                    });
                }else {
                    const formData = new FormData();
                    formData.append("file", files);
                    loading.runLoadingBlockUI();
                    const fileData = await BaseServices.axiosUpLoadFile(formData);
                    if (fileData) {
                        setFileLegal(fileData.file);
                        props.setFieldValue("file_legal", fileData.file);
                    }
                    loading.stopRunLoading();
                }
            } else {
                setFileLegal("");
                props.setFieldValue("file_legal", '');
            }

    };
    const onChangeDeputyPassportFile = async e => {
        if (e){
            setDataChanged(true);
        }
        const files = e.target.files[0];

            if (files) {
                const fileSize = files.size/1024/1024;
                if(fileSize>2){
                    onFailAction("File tải lên không thể lớn hơn 2MB", () => {
                        setFileDeputyPassport("");
                        props.setFieldValue("file_deputy_passport", '');
                    });
                }else {
                    const formData = new FormData();
                    formData.append("file", files);
                    loading.runLoadingBlockUI();
                    const fileData = await BaseServices.axiosUpLoadFile(formData);
                    if (fileData) {
                        setFileDeputyPassport(fileData.file);
                        props.setFieldValue("file_deputy_passport", fileData.file);
                    }
                    loading.stopRunLoading();
                }
            } else {
                setFileDeputyPassport("");
                props.setFieldValue("file_deputy_passport", '');
            }


    };
    const onChangeFileDk0101 = async e => {
        const files = e.target.files[0];
            if (files) {
                const fileSize = files.size/1024/1024;
                if(fileSize>2){
                    onFailAction("File tải lên không thể lớn hơn 2MB", () => {
                        setFileRegisterPaper("");
                        props.setFieldValue("file_register_paper", '');
                    });
                }else {
                    const formData = new FormData();
                    formData.append("file", files);
                    loading.runLoadingBlockUI();
                    const fileData = await BaseServices.axiosUpLoadFile(formData);
                    if(fileData){
                        setFileRegisterPaper(fileData.file);
                        props.setFieldValue("file_register_paper", fileData.file);
                    }
                    loading.stopRunLoading();
                }
            } else {
                setFileRegisterPaper('');
                props.setFieldValue("file_register_paper", "");
            }

    };
    const onClickModalFile = async type => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token){
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/yeu-cau-chung-thu-so/getFileDoc/${password}/${type}?token=${obj.token}`;
                window.open(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    const onChangeSelectProvideDate = (date, dateString) => {
        props.setFieldValue("provide_date", dateString);
        setDataChanged(true);
    };
    const onChangeSelectRepresenPassportDate = (date, dateString) => {
        setDataChanged(true);
        props.setFieldValue("represen_passport_date", dateString);
    };
    const getProvince = async  () =>{
        const province = await service.getProvince();
        const options = {};
        province.forEach(element => {
            options[element.newtel_code] = element.fullname;
        });
        setProvince(options);

    };
    const onChangeProvince = async (e) =>{
        if(e){
            setDataChanged(true);
            props.setFieldValue('province_code',e);
            const district = await service.getDistrictByProvince(e);
            const options = {};
            district.forEach(element => {
                options[element.newtel_district_code] = element.fullname;
            });
            setDistrict(options);

        } else{
            setDistrict({});
        }
        props.setFieldValue('district_code',undefined);
    };
    const onChangeDistrict = async (e) =>{
        setDataChanged(true);
        props.setFieldValue('district_code',e);
    };
    const onChangePackage = async value => {
        setDataChanged(true);
        props.setFieldValue('package_id',value);
        const selectdCate: any = _.find(cateServicePackageAmount, { id: parseInt(value) });
        if(selectdCate){
            props.setFieldValue( 'package_price', selectdCate.price );
        }
    };
    const onChangeVersionToken = async value => {
        props.setFieldValue('version_token_newtel',value);
    };
    const onChangeObjectRequest = async (e) =>{
        setDataChanged(true);
        props.setFieldValue( 'object', e.target.value);
        setObjectRequest(e.target.value);
        props.setFieldValue( 'type_device', undefined);
        props.setFieldValue( 'package_id', undefined);
        props.setFieldValue( 'package_price', undefined);
    };
    const onChangeTypeDevice = async (e) =>{
        setDataChanged(true);
        props.setFieldValue( 'type_device', e.target.value);
        props.setFieldValue( 'package_id', undefined);
        props.setFieldValue( 'package_price', undefined);
        props.setFieldValue( 'version_token_newtel', undefined);
        loading.runLoadingBlockUI();
        const type_search = 4;
        const type = objectRequest; //Đối tượng
        const object = 3;
        const type_device = e.target.value;
        const data = await getCateServicePackage(type_search, type, object, type_device, provider_id, hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        loading.stopRunLoading();
    };
    const onChangeTypeLegal = async e =>{
        if (e){
            props.setFieldValue( 'type_legal', e.target.value);
            setDataChanged(true);
        }
    };

    const onChangeTypeDoc = async e =>{
        if (e){
            props.setFieldValue( 'type_docurment', e.target.value);
            setDataChanged(true);
        }
    };
    const onChangeDataField = async e =>{
        if (e){
            setDataChanged(true);
        }
    };
    const onPreviewFile = async () =>{
        const values = props.values;
            const result = await service.previewFileDk0101({
                ...values,
                id: id,
            });
            console.log(result.base64)
        if (result && result.status === 200) {
                setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'File pháp lý','type_file': 'pdf'}) ;
                return true;
            } else {
                onFailAction("Có lỗi xảy ra khi xem trước file!");
                Object.keys(result.error).map(function(key) {
                    props.setFieldError(key, result.error[key]);
                    return false;
                });
            }
    };
    const onChangeSelectDateExpiredDate = (date, dateString) => {
        setDataChanged(true);
        props.setFieldValue("date_expired_cert", dateString);
    };
    // const   submitApprove = async (type) => {
    //     //type = 1 : Approve
    //     //type = 2 : Update and Approve
    //     if (Number(type) === 1){
    //         const data = {document, id};
    //         try {
    //             const result = await service.getApprove(data);
    //             if (result && result.status === 200) {
    //                 onSuccessAction("Duyệt thành công!", () => {
    //                     props.history.push("/yeu-cau-chung-thu-so");
    //                 });
    //             } else if (result && result.status === 404) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             } else if (result && result.status === 0){
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }else if (!result){
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }else if (result.error) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //                 Object.keys(result.error).map(function(key) {
    //                     props.setFieldError(key, result.error[key]);
    //                     return false;
    //                 });
    //             }else {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }
    //         }catch (e) {
    //             onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //         }
    //     }
    //     if(Number(type) === 2){
    //         const dataValue = props.values;
    //         const isUpdateApprove = 1;
    //         const datas = {...dataValue,isUpdateApprove, document, id};
    //         try {
    //             const result = await service.getUpdateAndApproveGroup(datas);
    //             if (result && result.status === 200) {
    //                 onSuccessAction("Duyệt thành công!", () => {
    //                     props.history.push("/yeu-cau-chung-thu-so");
    //                 });
    //             } else if (result && result.status === 404) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             } else if (result && result.status === 0) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             } else if (result.error) {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //                 Object.keys(result.error).map(function (key) {
    //                     props.setFieldError(key, result.error[key]);
    //                     return false;
    //                 });
    //             }else if (!result){
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }else {
    //                 onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //             }
    //         }catch (e) {
    //             onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //         }
    //     }
    // };
    const onChangeFormatFullname = (e) =>{
        const strFormart = handleFormatString(e.target.value, 3);
        props.setFieldValue('fullname',  strFormart)
        setDataChanged(true);
    };
    const onChangeFormatFullnamePresent = (e) =>{
        const strFormart = handleFormatString(e.target.value, 3);
        props.setFieldValue('represent_fullname',  strFormart)
        setDataChanged(true);
    };
    // const onChangeFormatFullnameSupport = (e) =>{
    //     const strFormart = handleFormatString(e.target.value, 3);
    //     props.setFieldValue('sp_fullname',  strFormart)
    //     setDataChanged(true);
    // };
    const onChangePrice = (e) =>{
        const price = formatMoney(e.target.value);
        props.setFieldValue('package_price', price);
        setDataChanged(true);
    };
    const sendMailReSign = async () => {
        const dataSend = {
            id: id,
            link: link
        };
        const result = await service.sendMailReSign(dataSend);
        if (result && result.status === 200) {
            onSuccessAction("Gửi mail thành công", () => {
                props.history.goBack();
            })
        }
    };
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Yêu cầu chứng thư số tổ chức</h4>
            <div className="card-body">
                <form>
                    <div>
                        {/*lý do từ chối*/}
                        {status === 3  || status === 7 ? (
                            <div className="input-group">
                                <TextAreaWithLabel
                                 label={"Lý do từ chối"}
                                 name={""}
                                 rows={0}
                                 value={reason}
                                 wrapClass={"col-md-12"}
                                 isDisabled={true}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {linkReSign ? (
                            <React.Fragment>
                            <div className="input-group">
                                <InputWithLabel
                                    name={""}
                                    label="Link ký lại DK02"
                                    isDisabled={true}
                                    wrapClass="col-md-6"
                                    value={link}
                                    onChangeValue={""}
                                />
                                <SubmitButton btnClass={"btn-submit-form btn btn-primary ml-1 mr-1 justify-content-center right"} onClick={sendMailReSign} text="Gửi mail ký lại" iconClass="fas fa-paper-plane" />
                            </div>
                            <div className="input-group">
                                <InputWithLabel
                                    name={""}
                                    label="Mã xác thực"
                                    isDisabled={true}
                                    wrapClass="col-md-6"
                                    value={secretCode}
                                    onChangeValue={""}
                                />
                            </div>
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {/*card 1*/}
                        <Card className="m-r-15-i m-l-15-i" title="Thông tin doanh nghiệp" size="small">
                            <div className="input-group">
                                <div className="input-group">
                                    <Radio
                                        options={{1: "Giấy ĐKKD", 2: "Giấy phép đầu tư", 3: "Quyết định thành lập"}}
                                        label="Giấy tờ pháp lý"
                                        name="type_legal"
                                        onChange={onChangeTypeLegal}
                                        wrappedClass="col-md-5"
                                        value={type_legal}
                                        isRequired={true}
                                        error={props.errors.type_legal}
                                        isDisabled={isDisable}
                                    />
                                    <div className="col-md-3">
                                        <InputFileUploadPreview
                                            name="file_legal"
                                            classWrapped="col-md-12 viewInputHide"
                                            label="File pháp lý"
                                            filename={fileLegal}
                                            onChanageFileUpload={onChangeLegalFile}
                                            isRequired={true}
                                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                            onClickDownloadFile = {fileLegal}
                                            funcntionView = {()=>onClickModalFile(1)}
                                            error={props.errors.file_legal}
                                            isDisabled={isDisable}
                                        />
                                    </div>
                                    <SelectDate
                                        label="Ngày cấp"
                                        name="provide_date"
                                        wrappedClass="col-md-2"
                                        onChange={onChangeSelectProvideDate}
                                        value={provide_date ? provide_date : ''}
                                        error={props.errors.provide_date}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Nơi cấp"
                                        name={"provide_organization"}
                                        wrapClass="col-md-2"
                                        value={provide_organization ?  provide_organization : ''}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.provide_organization}
                                        isDisabled={isDisable}
                                    />
                                </div>
                                <div className="input-group">
                                    <SelectBox
                                        name="identity_type"
                                        options={{
                                            1: 'Mã số thuế',
                                            2: 'Mã số ngân sách',
                                            3: 'Số ngân sách',
                                            4: 'Mã bảo hiểm xã hội',
                                            5: 'Giấy phép đầu tư'
                                        }}
                                        label="Loại định danḥ"
                                        wrappedClass="col-md-3"
                                        value={identity_type}
                                        error={props.errors.identity_type}
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        label={labelCode}
                                        name={"code"}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={code}
                                        onChangeValue={""}
                                        onBlur={onChangeDataField}
                                        isRequired={true}
                                        error={props.errors.code}
                                    />
                                    <InputWithLabel
                                        label="Mã số thuế"
                                        name={"tax_code"}
                                        wrapClass="col-md-3"
                                        value={tax_code}
                                        onBlur={onChangeDataField}
                                        onChangeValue={props.handleChange}
                                        error={props.errors.tax_code}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Tên doanh nghiệp"
                                        name={"fullname"}
                                        wrapClass="col-md-3"
                                        value={fullname}
                                        onChangeValue={props.handleChange}
                                        isRequired={true}
                                        error={props.errors.fullname}
                                        isDisabled={isDisable}
                                        onBlur={onChangeFormatFullname}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        label="Số điện thoại"
                                        name={"phone"}
                                        wrapClass="col-md-2"
                                        value={phone}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        isRequired={true}
                                        error={props.errors.phone}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Email"
                                        name={"email"}
                                        wrapClass="col-md-2"
                                        value={email}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        isRequired={true}
                                        error={props.errors.email}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Địa chỉ"
                                        name={"address"}
                                        wrapClass="col-md-2"
                                        value={address}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.address}
                                        isDisabled={isDisable}
                                    />
                                    <SelectBox
                                        name="province_code"
                                        options={province}
                                        label="Tỉnh thành"
                                        wrappedClass="col-md-3"
                                        firstOptionLabel="Chọn tỉnh thành"
                                        onChange={onChangeProvince}
                                        value={province_code}
                                        error={props.errors.province_code}
                                        isRequired={true}
                                        isDisabled={isDisable}
                                    />
                                    <SelectBox
                                        name="district_code"
                                        options={district}
                                        label="Quận huyện"
                                        wrappedClass="col-md-3"
                                        firstOptionLabel="Chọn quận huyện"
                                        onChange={onChangeDistrict}
                                        value={district_code}
                                        error={props.errors.district_code}
                                        isRequired={true}
                                        isDisabled={isDisable}
                                    />
                                </div>

                                    {Number(objectRequest) === 2 || Number(objectRequest) === 3 ?(
                                        <div className="input-group">
                                            <InputWithLabel
                                                label={"Serial CTS"}
                                                name={"serial_cts"}
                                                wrapClass="col-md-3"
                                                isDisabled={true}
                                                value={serial_cts}
                                                onChangeValue={""}
                                                error={props.errors.serial_cts}
                                            />
                                            <SelectBox
                                                name="token_type"
                                                options={{
                                                    1: 'Token mới',
                                                    2: 'Token cũ',
                                                }}
                                                label="Loại token"
                                                wrappedClass="col-md-3"
                                                value={token_type}
                                                error={props.errors.token_type}
                                                isDisabled={true}
                                            />
                                        </div>
                                    ):("")}
                            </div>
                        </Card>
                        {/*card 2*/}
                        <Card className="m-r-15-i m-l-15-i mt-4" title="Thông tin gói chứng thư số" size="small">
                            <div className="input-group">
                                <div className="input-group">
                                    <SelectBox
                                        name="provider_id"
                                        options={provider}
                                        label="Nhà cung cấp"
                                        wrappedClass="col-md-2"
                                        firstOptionLabel="Chọn nhà cung cấp"
                                        value={provider_id}
                                        error={props.errors.provider_id}
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                    <Radio
                                        options={{1: "Cấp mới", 2: "Gia hạn", 3: "Chuyển đổi"}}
                                        label="Đối tượng"
                                        name="object"
                                        onChange={onChangeObjectRequest}
                                        wrappedClass="col-md-3"
                                        value={objectRequest}
                                        isRequired={true}
                                        error={props.errors.object}
                                        isDisabled={true}
                                    />
                                    {/* {Number(objectRequest) === 2? (
                                        <Radio
                                        options={{
                                            1: "Trước hạn",
                                            2: "Quá hạn",
                                        }}
                                        label="Loại gia hạn"
                                        name="type_extend"
                                        onChange={onChangeObjectRequest}
                                        wrappedClass="col-md-6 col-lg-3"
                                        value={type_extend}
                                        isRequired={true}
                                        isDisabled={true}
                                    />
                                    ) : ("")} */}
                                    <Radio
                                        options={{1: "Token", 2: "HSM", 3: 'FastCA RS'}}
                                        label="Thiết bị đầu cuối"
                                        name="type_device"
                                        onChange={onChangeTypeDevice}
                                        wrappedClass="col-md-3"
                                        value={type_device}
                                        isRequired={true}
                                        error={props.errors.type_device}
                                        isDisabled={true}
                                    />
                                    {Number(type_device) === 2 ? (
                                        <Radio
                                            options={{1: "CyberLotus", 2: "Khác"}}
                                            label="Nhà cung cấp HSM"
                                            name="hsm_provider"
                                            onChange={onChangePackage}
                                            wrappedClass="col-md-2"
                                            isRequired={true}
                                            value={hsm_provider}
                                            error={props.errors.hsm_provider}
                                            isDisabled={true}
                                        />
                                    ):("")}
                                </div>
                                <div className="input-group">
                                    {Number(provider_id) === 2 && Number(type_device) === 1 ? (
                                        <SelectBox
                                            name="version_token_newtel"
                                            options={versionToken}
                                            label="Version Token"
                                            wrappedClass="col-md-2"
                                            firstOptionLabel="Chọn version token"
                                            onChange={onChangeVersionToken}
                                            value={version_token_newtel}
                                            error={props.errors.version_token_newtel}
                                            isRequired={true}
                                            isDisabled={isDisable}
                                        />
                                    ):('')}
                                    <SelectBox
                                        name="package_id"
                                        options={cateServicePackage}
                                        label="Gói dịch vụ"
                                        wrappedClass="col-md-2"
                                        firstOptionLabel="Chọn gói dịch vụ"
                                        onChange={onChangePackage}
                                        value={package_id}
                                        error={props.errors.package_id}
                                        isRequired={true}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Giá bán"
                                        name={"package_price"}
                                        wrapClass="col-md-2"
                                        value={package_price}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangePrice}
                                        isRequired={true}
                                        error={props.errors.package_price}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Số lượng"
                                        name={"quantity"}
                                        wrapClass="col-md-2"
                                        value={quantity}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        isRequired={true}
                                        error={props.errors.quantity}
                                        isDisabled={isDisable}
                                        type={'number'}
                                    />
                                    <InputWithLabel
                                        label="Chương trình khuyến mãi"
                                        name={"promotion_code"}
                                        wrapClass="col-md-2"
                                        value={promotion_code}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className={"input-group"}>
                                    {Number(objectRequest) === 2 || Number(objectRequest) === 3 ? (
                                        <SelectDate
                                            name="date_expired_cert"
                                            wrappedClass="col-md-3"
                                            label="Ngày hết hạn"
                                            onChange={onChangeSelectDateExpiredDate}
                                            value={date_expired_cert ? date_expired_cert : ''}
                                            isDisabled={isDisable}
                                            error={props.errors.date_expired_cert}
                                        />
                                    ) : ("")}
                                </div>
                            </div>
                        </Card>
                        {/*card 3*/}
                        <Card className="m-r-15-i m-l-15-i mt-4" title="Người đại diện" size="small">
                            {/*line 1*/}
                            <div className="input-group">
                                <div className="input-group">
                                    <InputWithLabel
                                        name="represent_fullname"
                                        wrapClass="col-md-3"
                                        label="Họ và tên"
                                        value={represent_fullname}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeFormatFullnamePresent}
                                        isRequired={true}
                                        error={props.errors.represent_fullname}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Số CMND"
                                        name={"represen_passport"}
                                        wrapClass="col-md-3"
                                        value={represen_passport}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.represen_passport}
                                        isDisabled={isDisable}
                                    />
                                    <div className="col-md-3">
                                        <InputFileUploadPreview
                                            name="file_deputy_passport"
                                            classWrapped="col-md-12 viewInputHide pr-0 pl-0"
                                            label="File CMND/Hộ chiếu"
                                            filename={fileDeputyPassport}
                                            onChanageFileUpload={onChangeDeputyPassportFile}
                                            isRequired={true}
                                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                            onClickDownloadFile = {fileDeputyPassport}
                                            funcntionView = {()=>onClickModalFile(2)}
                                            error={props.errors.file_deputy_passport}
                                            isDisabled={isDisable}
                                        />
                                    </div>
                                    <SelectDate
                                        label="Ngày cấp"
                                        name="represen_passport_date"
                                        wrappedClass="col-md-3"
                                        onChange={onChangeSelectRepresenPassportDate}
                                        value={represen_passport_date ? represen_passport_date : ''}
                                        error={props.errors.represen_passport_date}
                                        isDisabled={isDisable}
                                    />
                                </div>
                            </div>
                            {/*line 2*/}
                            <div className="input-group">
                                <div className="input-group">
                                    <InputWithLabel
                                        label="Nơi cấp"
                                        name={"represen_passport_place"}
                                        wrapClass="col-md-3"
                                        value={represen_passport_place}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.represen_passport_place}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        name="represen_position"
                                        wrapClass="col-md-3"
                                        label="Chức vụ"
                                        value={represen_position}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.represen_position}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        name="represen_email"
                                        wrapClass="col-md-3"
                                        label="Email"
                                        value={represen_email}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.represen_email}
                                        isDisabled={isDisable}
                                    />
                                    <InputWithLabel
                                        label="Số điện thoại"
                                        name={"represen_phone"}
                                        wrapClass="col-md-3"
                                        value={represen_phone}
                                        onChangeValue={props.handleChange}
                                        onBlur={onChangeDataField}
                                        error={props.errors.represen_phone}
                                        isDisabled={isDisable}
                                    />
                                </div>
                            </div>
                        </Card>
                        {/*card 4*/}
                        {/*<Card className="m-r-15-i m-l-15-i mt-4" title="Đăng Ký Hỗ Trợ Khẩn Cấp" size="small">*/}
                        {/*    <div className="input-group">*/}
                        {/*        <div className="input-group">*/}
                        {/*                <div className="input-group">*/}
                        {/*                    <InputWithLabel*/}
                        {/*                        name="sp_fullname"*/}
                        {/*                        wrapClass="col-md-3"*/}
                        {/*                        label="Họ tên đầu mối"*/}
                        {/*                        value={sp_fullname}*/}
                        {/*                        onChangeValue={props.handleChange}*/}
                        {/*                        onBlur={onChangeFormatFullnameSupport}*/}
                        {/*                        error={props.errors.sp_fullname}*/}
                        {/*                        isDisabled={isDisable}*/}
                        {/*                    />*/}
                        {/*                    <InputWithLabel*/}
                        {/*                        label="Chức vụ"*/}
                        {/*                        name={"sp_position"}*/}
                        {/*                        wrapClass="col-md-3"*/}
                        {/*                        value={sp_position}*/}
                        {/*                        onChangeValue={props.handleChange}*/}
                        {/*                        onBlur={onChangeDataField}*/}
                        {/*                        error={props.errors.sp_position}*/}
                        {/*                        isDisabled={isDisable}*/}
                        {/*                    />*/}
                        {/*                    <InputWithLabel*/}
                        {/*                        name="sp_email"*/}
                        {/*                        wrapClass="col-md-3"*/}
                        {/*                        label="Email"*/}
                        {/*                        value={sp_email}*/}
                        {/*                        onChangeValue={props.handleChange}*/}
                        {/*                        onBlur={onChangeDataField}*/}
                        {/*                        isRequired={true}*/}
                        {/*                        error={props.errors.sp_email}*/}
                        {/*                        isDisabled={isDisable}*/}
                        {/*                    />*/}
                        {/*                    <InputWithLabel*/}
                        {/*                        label="Số điện thoại"*/}
                        {/*                        name={"sp_phone"}*/}
                        {/*                        wrapClass="col-md-3"*/}
                        {/*                        value={sp_phone}*/}
                        {/*                        onChangeValue={props.handleChange}*/}
                        {/*                        onBlur={onChangeDataField}*/}
                        {/*                        error={props.errors.sp_phone}*/}
                        {/*                        isDisabled={isDisable}*/}
                        {/*                    />*/}
                        {/*                </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Card>*/}
                        {/*card 5*/}
                        <Card className="m-r-15-i m-l-15-i mt-4" title="Mẫu đăng ký" size="small">
                                <div className="input-group">
                                    <Radio
                                        options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}}
                                        label="Loại hồ sơ"
                                        name={"type_docurment"}
                                        wrappedClass="col-md-2-3"
                                        isRequired={true}
                                        onChange={onChangeTypeDoc}
                                        value={type_docurment}
                                        error={props.errors.type_docurment}
                                        isDisabled={isDisable}
                                    />
                                    {dataChanged ? (
                                        <div className="col-md-2">
                                            <label className="invisible">12121</label>
                                            <button type="button" className="form-control btn btn-primary ml-1 mr-1 p-b-b-3" onClick={onPreviewFile}>Mẫu DK-01.01</button>
                                        </div>

                                    ) : (
                                        <div className="col-md-2">
                                            <label className="invisible">12121</label>
                                            <button type="button" className="form-control btn btn-success ml-1 mr-1 p-b-b-3" onClick={() => onViewFileDoc(3)}>File đăng ký</button>
                                        </div>
                                    )}
                                    {dataChanged ? (
                                        <div className="col-md-3">
                                            <InputFileUploadPreview
                                                name="file_register_paper"
                                                classWrapped="col-md-12 viewInputHide"
                                                label="Tải file DK-01.01"
                                                filename={fileRegisterPaper}
                                                onChanageFileUpload={onChangeFileDk0101}
                                                isRequired={true}
                                                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                                onClickDownloadFile = {fileRegisterPaper}
                                                funcntionView = {()=>onClickModalFile(3)}
                                                error={props.errors.file_register_paper}
                                                isDisabled={isDisable}
                                            />
                                        </div>
                                    ) : ('')}
                                    {statusGen ? (
                                        <div className="col-md-2">
                                            <label className="invisible">12121</label>
                                            <button type="button" className="form-control btn btn-primary ml-1 mr-1 p-b-b-3" onClick={()=>onViewFileDoc(7)}>File DK-02</button>
                                        </div>
                                    ) : ("")}
                                    {statusDK03 ? (
                                        <div className="col-md-2">
                                            <label className="invisible">12121</label>
                                            <button type="button" className="form-control btn btn-primary ml-1 mr-1 p-b-b-3" onClick={()=>onViewFileDoc(14)}>File DK-03</button>
                                        </div>
                                    ) : ("")}

                                    {(Number(status) === 6 && Number(provider_id) === 1 ?
                                        <InputWithLabel
                                            label="Mã bảo mật"
                                            name=""
                                            wrapClass="col-md-2"
                                            isDisabled={true}
                                            value={secret_code}
                                            onChangeValue={""}
                                        />
                                        : "")}
                                </div>
                            </Card>
                        {/*nút duyệt*/}
                        <div className="input-group d-flex justify-content-center p-5">
                            {/*{(Number(status) === 2 || Number(status) === 7) && dataChanged?*/}
                            {/*    (*/}
                            {/*        <SubmitButton onClick={() => submitAccept(2)} text="Sửa và Duyệt" iconClass="fas fa-check"/>*/}
                            {/*    )*/}
                            {/*    : (" ")*/}
                            {/*}*/}
                            {/*{(Number(status) === 2 || Number(status) === 7) && !dataChanged?*/}
                            {/*    (*/}
                            {/*        <SubmitButton onClick={() => submitAccept(1)} text="Duyệt" iconClass="fas fa-check"/>*/}
                            {/*    )*/}
                            {/*    : (" ")*/}
                            {/*}*/}
                            {/*{(Number(status) === 2 || Number(status) === 7) ?*/}
                            {/*    (*/}
                            {/*        <DenyButton onClick={submitDeny}/>*/}
                            {/*    )*/}
                            {/*    : (" ")*/}
                            {/*}*/}
                            {/*{ ((Number(status) === 4 || Number(status) === 6) && checkPermission(deny_supper)) ?*/}
                            {/*    (*/}
                            {/*        <DenyButton onClick={submitDeny}/>*/}
                            {/*    )*/}
                            {/*    : (" ")*/}
                            {/*}*/}
                            <BackButton
                                history={props.history}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        type_legal: 0,
        code: '',
        provide_date: '',
        provide_organization: '',
        tax_code: '',
        fullname: '',
        address: '',
        email: 0,
        phone: '',
        represent_fullname: '',
        represen_passport: '',
        represen_passport_date: '',
        represen_passport_place: '',
        represen_position: '',
        represen_email: '',
        sp_fullname: '',
        sp_position: '',
        sp_email: '',
        sp_phone: '',
        type_device: 0,
        object: 0,
        package_price: '',
        promotion_code: '',
        file_register: '',
        support_register: 0,
        status: 0,
        reason: '',
        package_id: '',
        file: '',
        secret_code: '',
        password: '',
        represen_phone: '',
        type_docurment: 0,
        province_code: '',
        district_code: '',
        file_legal: '',
        file_deputy_passport: '',
        file_register_paper: '',
        serial_cts: '',
        token_type: 0,
        provider_id: 0,
        identity_type: 0,
        quantity: 0,
        version_token_newtel: 0,
        hsm_provider: 0,
        date_expired_cert: '',
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const services = new CateContractService();
        const result = await services.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(RequestCertificateViewGroup);

export default withNavbar()(RenderCreateForm);
