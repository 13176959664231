import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import {
  convertJsonToQueryString,
  formatDateTime,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { RouteComponentProps } from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import WhTokenExportService from "../../../services/whTokenExport/WhTokenExportService";
import { onFailAction } from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import { TypeWhToken, TYPEEXPORT_LABEL_CLASS } from "./Enum";
import SelectDate from "../../common/form/SelectDate";
import { Tag } from "antd";

interface Props extends RouteComponentProps {}

const TITLE = "Danh sách xuất kho";
const URL_TS = 'xem-chi-tiet-tai-san';
const URL_TOKEN = 'xem-chi-tiet-token';
const URL_DOC = 'xem-chi-tiet-tai-lieu';

const VersionToken: React.FC<Props> = props => {
  const [dataSearch, setDataSearch] = useState({
    text_search: "",
    type: "",
    provider_id: "",
    version_token_id: "",
    export_date: ""
  });

  const onSubmitSearchButton = e => {
    e.preventDefault();
      const search = queryStringToJSON(props.location.search);
      let raw = {};
      for (let key in search){
          if (key === 'raw'){
              raw['raw'] = search[key];
          }
      }
      const queryString = convertJsonToQueryString({...dataSearch, ...raw});
    props.history.push({
      search: queryString
    });
  };

  const onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    setDataSearch({ ...dataSearch, [name]: value });
  };

  const onChangeDataSearchType = (e) => {
    setDataSearch({...dataSearch, 'type': e})
}
  const fetchDataFunction = async () => {
    const service = new WhTokenExportService();
    const queryString = queryStringToJSON(props.location.search);
    try {
      const result = await service.list({ ...dataSearch, ...queryString });
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  useEffect(() => {}, []);

  const onRenderActionColumn = (text, record, index) => {
    let url_type = URL_TOKEN;
    if(record.type === 2){
      url_type = URL_TS;
    }
    if (record.type === 3){
        url_type = URL_DOC
    }
    return (
      <TableActionButton
        onClickUpdate={() =>
          props.history.push(`/danh-sach-xuat-kho/${url_type}/${record.id}`)
        }
        tittle='Xem chi tiết' icon='fa fa-eye fa-lg'
        isDisplayUpdateButton={true}
      />
    );

  };
  const renderTypeColumn = text => {
    return (
      <div>
        <Tag color={`${TYPEEXPORT_LABEL_CLASS[text].class}`}>
          {TYPEEXPORT_LABEL_CLASS[text].label}
        </Tag>{" "}
      </div>
    );
  };

  const onChangeSelectDateValid = (date, dateString) => {
    setDataSearch({ ...dataSearch, export_date: dateString });
  };

  return (
    <div className="card">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <h4 className="card-title1">{TITLE}</h4>

      <div className="card-body">
        <form onSubmit={onSubmitSearchButton} className="search-form-index">
          <div className="input-group">
            <InputWithLabel
              name="text_search"
              wrapClass="col-md-2 form-group nopadding-left"
              onChangeValue={onChangeDataSearchInput}
              value={dataSearch.text_search}
              isRequired={false}
              label={""}
              placeholder={"Mã xuất kho"}
            />
            <SelectBox
              value={dataSearch.type}
              label=""
              onChange={onChangeDataSearchType}
              name="type"
              wrappedClass="col-md-2 form-group nopadding-left"
              options={TypeWhToken.TYPEEXPORT}
              firstOptionLabel="Loại sản phẩm"
            />
            <SelectDate
              label=""
              name="export_date"
              wrappedClass="col-md-2 form-group h-35 nopadding-left"
              onChange={onChangeSelectDateValid}
              value={dataSearch.export_date}
              isRequired={true}
              placeholder={"Ngày xuất kho"}
            />
            <div className="col-md-6 form-group">
              <SearchButton onClick={onSubmitSearchButton} />
              <GoToCreatePageButton
                toURL={`${props.match.url}/xuat-kho-token`}
                text="Xuất kho token"
                icon="fas fa-file-export"
              />
              <GoToCreatePageButton
                toURL={`${props.match.url}/xuat-kho-qua-tang`}
                text="Xuất kho tài sản"
                icon="fas fa-file-export"
              />
                <GoToCreatePageButton
                    toURL={`/kho-tai-lieu/xuat-kho`}
                    text="Xuất kho tài liệu"
                    icon="fas fa-file-export"
                />
            </div>
          </div>
        </form>
        <NewTable
          columns={[
            { title: "Mã xuất kho", dataIndex: "code" },
            {
              title: "Loại sản phẩm",
              dataIndex: "type",
              render: renderTypeColumn
            },
            { title: "Người xuất kho", dataIndex: "exporter" },
            { title: "Người nhận", dataIndex: "receiver" },
            {
              title: "Ngày xuất kho",
              dataIndex: "export_date",
              render: text => formatDateTime(text)
            },
            {
              title: "Tác vụ",
              render: onRenderActionColumn
            }
          ]}
          onFetchData={fetchDataFunction}
        />
      </div>
    </div>
  );
};

export default withNavbar()(VersionToken);
