import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class PackageService extends FetchAPI{

    public async getPackages(data: object) {
        const path = "/cate/servicepackage"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deletePackageById(id){
        const result =  await this.request("cate/servicepackage-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getPackage(id){
        const result = await this.request("cate/servicepackage-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createPackage(data){
        const result = await this.request("cate/servicepackage",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updatePackage(data){
        const result = await this.request("cate/servicepackage-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
    public async getCertificateProfileName(id){
        const result = await this.request("cate/get-certificate-profile-name/" + id,{},TypeMethodContsantEnum.GET);
        return result;
    }
    public async getEntityCertificateProfile(){
        const result = await this.request("cate/get-entity-certificate-profile",{},TypeMethodContsantEnum.GET);
        return result;
    }
}

export default PackageService;