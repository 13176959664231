import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import { Type } from "../Enum";
import {formatDateTime} from "../../../../helpers/NewCaHelper";
import {
  onDeleteAlert,
  onSuccessAction
} from "../../../common/swal/SwalCommon";
import { reloadPage } from "../../../../helpers/NewCaHelper";
import PackageService from "../../../../services/package/PackageService";
import { RouteComponentProps } from 'react-router-dom';
import { Tag } from "antd";

interface Props extends RouteComponentProps{
}
interface State {}

export default class MainTable extends Component<Props, State> {
  state = {};
  service = new PackageService();
  componentDidMount() {
    this.fetch();
  }
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getPackages(params);
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  onClickDeleteButton = id => {
    const self = this;
    onDeleteAlert(async function(willDelete) {
      if (willDelete) {
        const result = await self.service.deletePackageById(id);
        if (result && result.status === 200) {
          onSuccessAction(
            "Xóa gói dịch vụ thành công!",
            reloadPage(self.props.location, self.props.history)
          );
        }
        if (result && result.status === 2) {
          onFailAction(
            result.message
          );
        }
      }
    });
  };

  render() {
    return (
      <Table
        columns={this.columns}
        onFetchData={this.fetch}
      />
    );
  }

  onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickLock={() => this.onClickDeleteButton(record.id)}
        onClickUpdate={() =>
          this.props.history.push(`/goi-dich-vu/cap-nhat/${record.id}`)
        }
        isDisplayLockButton={true}
        isDisplayUpdateButton={true}
      />
    );
  };
  onRenderTypeColumn(type) {
    return (
      <Tag color={`${Type.badgeClass[type]}`}>
        {Type.TYPE[type]}
      </Tag>
    );
  }
  onRenderTypeColumnNCC(type) {
    return (
      <Tag color={`${Type.badgeClass[type]}`}>
        {Type.TYPENCC[type]}
      </Tag>
    );
  }
  onRenderTypeColumnTypeDevice(type) {
    return (
      <Tag color={`${Type.badgeClass[type]}`}>
        {Type.TYPE_DEVICE[type]}
      </Tag>
    );
  }
  onRenderStatusColum(type) {
    return (
      <Tag color={`${Type.badgeClassStatus[type]}`}>
        {Type.VALID[type]}
      </Tag>
    );
  }
  onRenderProvince(province){
    let province_keys = province.split(",");
    let list = '';
    province_keys.forEach(element => {
      if(list !== ''){
        list = list + ', ' + Type.Province[element];
      }else{
        list = Type.Province[element];
      }
    });
    return(
      <span>{list}</span>
    );
  }

  columns = [
    { title: "Tên gói dịch vụ", dataIndex: "name" },
    { title: "Mã gói dịch vụ", dataIndex: "code" },
    {
      title: "Loại gói",
      dataIndex: "type",
      render: this.onRenderTypeColumn
    },
    // {
    //   title: "Nhà cung cấp",
    //   dataIndex: "provider_id",
    //   render: this.onRenderTypeColumnNCC
    // },
    {
      title: "Thiết bị đầu cuối",
      dataIndex: "type_device",
      render: this.onRenderTypeColumnTypeDevice
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: text => formatDateTime(text)
    },
    {
      title: "Hiệu lực",
      dataIndex: "valid",
      render: this.onRenderStatusColum
    },
    // {
    //   title: "Tác vụ",
    //   render: this.onRenderActionColumn
    // }
  ];
}
