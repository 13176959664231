import Compensation from "../../pages/compensation/Compensation";
import CompensationCreate from "../../pages/compensation/create/CompensationCreate";
import CompensationUpdate from "../../pages/compensation/update/CompensationUpdate";

export const COMPENSATION_ROUTE = [
    {
        component: Compensation,
        link: "/du-lieu-cap-bu",
        isExact: false
    },
    {
        component: CompensationCreate,
        link: "/du-lieu-cap-bu/them-moi",
        isExact: false
    },
    {
        component: CompensationUpdate,
        link: "/du-lieu-cap-bu/cap-nhat/:id",
        isExact: false
    },
];

