import React, { Component } from "react";
import { Modal, Spin } from "antd";

interface Props {
  handleCancel?: any;
  value?: string;
  visible: boolean;
  loading: boolean;
  title: string;
  type_file?: string;
}
interface State {
  visible: boolean;
}

export default class ModalView extends Component<Props, State> {
  componentDidMount() {}

  render() {
    return (
      <div>
        <Modal
          title={"Xem " + this.props.title}
          visible={this.props.visible}
          onOk={this.props.handleCancel}
          cancelButtonProps={{ hidden: true }}
          zIndex={1}
          destroyOnClose={true}
          okText="Đóng"
          className="modal-xl"
          width="auto"
          closable={false}
        >
          {this.props.type_file && (this.props.type_file === "application/pdf" || this.props.type_file === "pdf") ? (
            this.props.loading ? (
              <iframe
                title={this.props.title}
                src={`data:application/pdf;base64,${this.props.value}`}
                width="100%"
                height="800px"
              />
            ) : (
              <div className="custom-spin">
                <Spin size="large" />
              </div>
            )
          ) : this.props.loading ? (
            <img
              alt=""
              className="img-thumbnail img-bill"
              src={`data:image;base64,${this.props.value}`}
            />
          ) : (
            <div className="custom-spin">
              <Spin size="large" />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
