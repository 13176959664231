import React, { Component } from "react";
import "../../common/css/dashboard.css";
import { List } from "antd";
import HomeService from "../../../services/home/HomeService"
import { NUMBER_COLOR } from "./Enum"

import InfiniteScroll from "react-infinite-scroller";

interface Props {
   dataSource?: any
}
interface State {
}
export default class ListTopAgency extends Component<Props, State> {
    state = {
        dataSource: [{
            id:0,
            name:"",
            description: "",
            number:""
        }]
    }
    handleInfiniteOnLoad = () => {

    }
    componentDidMount() {
        this.fetch()
    }
    fetch = async (params = {}) => {
        const service = new HomeService()
        const result = await service.getData()
        this.setState({dataSource: result.data.data.dataTopAgency})
    }
    render() {
        return (
            <div className="demo-infinite-container">
                <div className="card-feeds"><span className="">Top Đại lý/ CTV</span></div>
                <div className="p-2" style={{height: "313px"}}>
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={0}
                        useWindow={false}>
                        <List
                            dataSource={this.state.dataSource}
                            loadMore={this.handleInfiniteOnLoad}
                            renderItem={item => (
                            <List.Item key={item.id}>
                            <List.Item.Meta
                                avatar={
                                    <span className="fa-stack fa-3x" style={{color:`${NUMBER_COLOR[item.id]}`}}>
                                        <i className="fa fa-circle fa-stack-2x"></i>
                                        <strong className="fa-stack-1x">{item.id}</strong>
                                    </span>
                                }
                                title={item.name}
                                description={item.description}
                            />
                            <div><i>{item.number} yêu cầu</i></div>
                            </List.Item>
                        )}
                        />
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}