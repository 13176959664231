export enum StatusEnum {
    ACTIVE = "0",
    STOP = "1",
    DEACTIVE = "2"
  }
  
  export class TypeWhToken {
    static readonly TYPE = {
      1: "Token RA",
      2: "Token CTS",
    };
    static readonly badgeClass = {
      1: "badge-primary",
      2: "badge-success"
    };
    static readonly TYPEAGENCY = {
      1: "Đại lý",
      2: "Cộng tác viên"
    };
    static readonly TYPEEXPORT = {
      1: "Token",
      2: "Tài sản"
    };
  }
  
  export const TYPE_LABEL_CLASS = {
    1: {
      label: "Token RA",
      class: "badge-primary"
    },
    2: {
      label: "Token CTS",
      class: "badge-success"
    }
  };
  
  export const TYPEEXPORT_LABEL_CLASS = {
    1: {
      label: "Token",
      class: "badge-primary"
    },
    2: {
      label: "Tài sản",
      class: "badge-success"
    }
  };
  export const TYPEEXPORT_TOKEN_DETAIL = {
    1: {
      label: "Đang trong kho",
      class: "badge-primary"
    },
    2: {
      label: "Đã xuất",
      class: "badge-danger"
    }
  };
  