import React, {Component} from "react";
import {Table, Tag} from "antd";

interface Props {
    data?: any;
    total?: string;
    onClickViewTokenRa?: any;
    onClickViewTokenCTS?: any;
}

interface State {
}

export default class TokenList extends Component<Props, State> {
    render() {
        const renderTypeColumn = (text) => {
            return (
                <div>
                    {text === 1 ? (
                        <Tag color="blue" className="pointer" onClick={this.props.onClickViewTokenRa}>Token RA</Tag>
                    ) : (
                        <Tag color="green" className="pointer" onClick={this.props.onClickViewTokenCTS}>Token CTS</Tag>
                    )}
                </div>
            );
        };
        const columns = [
            {
                title: "STT",
                key: "stt",
                render: (text, record, index) => index + 1,
                className: "text-center",
                width: "10%",
            },
            {
                title: "Loại token",
                dataIndex: "type",
                render: renderTypeColumn,
                key: "type",
                width: "30%",
            },
            {
                title: "Version token",
                dataIndex: "version_type",
                key: "version_type",
            },
            {
                title: "Số lượng",
                dataIndex: "amount",
                className: "text-center",
                key: "amount",
            }
        ];
        return (
            <div>
                <Table
                    className={"w-100 m-t-20 ant-table-bordered"}
                    columns={columns}
                    dataSource={this.props.data ? this.props.data : []}
                    pagination={false}
                    rowKey={(record, index) => {
                        return index.toString();
                    }}
                    // footer={() => (
                    //     <div className="text-right">
                    //         <b>Tổng tiền(VNĐ): {this.props.total}</b>
                    //     </div>
                    // )}
                />
            </div>
        );
    }
}
