import React, { Component } from "react";
import Helmet from "react-helmet";
import LoadingComponent from './../../common/loading/LoadingComponent';

interface Props {
  title: string;
  loading?: boolean;
}
interface State {}

class PageWrapper extends Component<Props, State> {
  state = {};

  render() {
    const { loading } = this.props;

    return (
      <div className="card">
        {loading ? (
          <div className="loadding-content-wrapper">
            <LoadingComponent />
          </div>
        ) : (
          ""
        )}
          <Helmet>
            <title>{this.props.title}</title>
          </Helmet>
          <h4 className="card-title1">{this.props.title}</h4>
          <div className="card-body">
          {this.props.children}
          </div>
      </div>
    );
  }
}

export default PageWrapper;
