import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import swal from "sweetalert";
import { onFailAction } from "../../../common/swal/SwalCommon";
import SelectProvince from "../../../common/form/SelectProvince";
import ProvinceService from "../../../../services/province/ProvinceService";
import TaxDepartmentService from "../../../../services/taxdepartment/TaxDepartmentService";

interface Props {
  id: string;
  history: History;
}
interface State {
  fullname: string;
  code: string;
  short_name: string;
  province_code: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    province: []
  };


  componentDidMount() {
    this.getDataUpdate();
    this.getProvince();
  }
  
  getProvince = async () => {
    const service = new ProvinceService();
    const result = await service.getProvinceList();
    if(result && result.status===200){
      this.setState({
        province:  result.data
      } as any);
    }
  }

  getDataUpdate = async () => {
      const service = new TaxDepartmentService();
      const result = await service.getTaxDepartment(this.props.id);
      if (result && result.status === 200) {
        const {
          fullname,
          code,
          short_name,
          province_code
        } = result.data;
        this.props.setValues({
          fullname,
          code,
          short_name,
          province_code
        } as Pick<State,keyof State>);
      }
  };

  render() {
    const {
      fullname,
      code,
      short_name,
      province_code,
    } = this.props.values;
    return (
      <form onSubmit={this.props.handleSubmit}>
      <div className="input-group">
          <InputWithLabel
            name="code"
            label="Mã chi cục thuế"
            wrapClass="col-md-2 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
          />
          <InputWithLabel
            name="fullname"
            label="Tên chi cục thuế"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={fullname}
            error={this.props.errors.fullname}
            isRequired={true}
          />
          <InputWithLabel
            name="short_name"
            label="Tên viết tắt"
            wrapClass="col-md-2 form-group"
            onChangeValue={this.props.handleChange}
            value={short_name}
            error={this.props.errors.short_name}
          />
          <SelectProvince
            label="Tỉnh thành"
            onChange={this.props.handleChange}
            value={province_code}
            error={this.props.errors.province_code}
            name="province_code"
            wrappedClass="col-md-4 form-group nopadding-left"
            options={this.state.province}
            firstOptionLabel="Tất cả"
            isRequired={true}
          />
        </div>
        <WrapperButtonDiv>
          <BackButton
              history={this.props.history}
                      // url='/chi-cuc-thue'
          ></BackButton>
          <SubmitButton onClick={this.props.submitForm}></SubmitButton>
        </WrapperButtonDiv>
      </form>
    );
  }
}

const userUpdateForm = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    fullname: "",
    code: "",
    short_name: "",
    province_code: "",
  }),
  validationSchema: Yup.object().shape({
    fullname: Yup.string().required("Tên chi cục thuế không được bỏ trống."),
    code: Yup.string().required("Mã chi cục thuế không được bỏ trống."),
    province_code: Yup.string().required("Mã tỉnh thành không được bỏ trống."),
  }),

  handleSubmit: async (values, { setSubmitting,props,setErrors }) => {
    setSubmitting(false);
    const data = new TaxDepartmentService();
    const result = await data.updateTaxDepartment({...values,id:props.id});
    if (result && result.status === 200) {
      swal({
        title: "Thành công!",
        text: "Cập nhật thành công!",
        icon: "success",
        dangerMode: false
      }).then(function() {
        props.history.goBack();
      });
    }else if(result.status===1){
      setErrors(result.errors);
    }else{
      onFailAction(result.errors);
    }
  },

})(UpdateForm);

export default userUpdateForm;
