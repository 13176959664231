/* Imports */
import {Component} from 'react';
import "../../common/css/dashboard.css"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import HomeService from "../../../services/home/HomeService"

interface Props {
  dataSource?: any
}
interface State {
}
class DonutChart extends Component<Props, State>{
  state = {
    dataSource: [{
        owner:"",
        litres:""
    }]
  }
  componentDidMount() {
    this.fetch()
  }
  fetch = async (params = {}) => {
      const service = new HomeService()
      const result = await service.getData()
      this.setState({dataSource: result.data.data.dataTotalRequest})
  }
  render() {
    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end
    // Create chart instance
      if(!document.getElementById('donutChart')){
          return false
      }
    let chart = am4core.create("donutChart", am4charts.PieChart);

    // Add data
    chart.data = this.state.dataSource

    // Set inner radius
    chart.innerRadius = am4core.percent(40);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "owner";
    pieSeries.slices.template.stroke = am4core.color("#ffff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.labels.template.disabled = true;
    chart.logo.disabled = true;


    let title = chart.titles.create();
    title.text = "Yêu cầu chứng thư số trong tháng";
    title.marginTop = 17;
    title.fontSize = 20;
    title.marginBottom = 8;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.legend = new am4charts.Legend();
    return (
      true
    );
  }
}
export default DonutChart;
