import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class PermissionService extends FetchAPI{

    public async getPermissionList(type){
        const result = await this.request("/permission",{type},TypeMethodContsantEnum.GET);
        return result;
    }

}

export default PermissionService;