import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class SoftwareService extends FetchAPI{

    public async getSoftwares(data: object) {
        const path = "/cate/software"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteSoftwareById(id){
        const result =  await this.request("cate/software-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getSoftware(id){
        const result = await this.request("cate/software-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async downloadSoftware(id){
        const result = await this.request("cate/software-download",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createSoftware(data){
        const result = await this.request("cate/software",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateSoftware(data){
        const result = await this.request("cate/software-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
}

export default SoftwareService;