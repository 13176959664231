import React, {Component} from "react";
import LabelInput from "../../../common/form/label/LabelInput";
import ValidateMessageError from "../../../common/form/validate-message-error/ValidateMessageError";
import "../../../common/css/main.css";

interface Props {
    options: any;
    label: string;
    name: string;
    wrappedClass: string;
    firstOptionLabel?: string;
    onChange?: any;
    value: any;
    error?: any;
    isRequired?: boolean;
    dataKey?: string;
    isDisabled?: boolean;
    hidden? : boolean;
}

interface State {
}

export default class SelectBox extends Component<Props, State> {
    state = {};
    
    render() {
        const {options} = this.props;
        return (
            <div className={this.props.wrappedClass} hidden={this.props.hidden}>
                {
                    (this.props.label) ?
                    <LabelInput
                        nameFor={this.props.name}
                        label={this.props.label}
                        isRequired={this.props.isRequired}
                    />
                    : ''
                }
                <select className="form-control font-input"
                        name={this.props.name}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        data-key={this.props.dataKey}
                        disabled={this.props.isDisabled}>
                    {
                        (this.props.firstOptionLabel) ? <option value="">{this.props.firstOptionLabel}</option> : ''
                    }
                    {Object.keys(options).map((key, index) => (
                        <option key={index} value={key} title={options[key]}>
                            {options[key]}
                        </option>
                    ))}
                </select>
                <ValidateMessageError error={this.props.error}/>
            </div>
        );
    }
}
