import FetchAPI from '../../helpers/FetchAPI';
import {TypeMethodContsantEnum} from '../../helpers/constant/MethodAPIConstant';

class RequestTokenService extends FetchAPI{

    public async getRequests(data: object) {
        const path = "admin/yeu-cau-token-admin/list"
        return await this.request(path, data, TypeMethodContsantEnum.GET);
    }

    public async getRequestsCreateUser(data: object) {
        const path = "admin/yeu-cau-token-admin/listReqCreateUser"
        return await this.request(path, data, TypeMethodContsantEnum.GET);
    }

    public async getRequest(id) {
        const path = "admin/yeu-cau-token-admin/get-model/"+id
        return await this.request(path, {}, TypeMethodContsantEnum.GET);
    }

    public async getFile(id) {
        const path = "admin/yeu-cau-token-admin/file/"+id
        return await this.request(path, {}, TypeMethodContsantEnum.GET);
    }

    public async getApprove(data: object) {
        const path = "admin/yeu-cau-token-admin/approve"
        return await this.request(path, data, TypeMethodContsantEnum.POST);
    }

    public async getDeny(data: object) {
        const path = "admin/yeu-cau-token-admin/deny"
        return await this.request(path, data, TypeMethodContsantEnum.POST);
    }

}

export default RequestTokenService;