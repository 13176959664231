import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

class CateContractService extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            "cate/hop-dong/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async find(id) {
        const result = await this.request(
            `cate/hop-dong/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async store(data) {
        const result = await this.request(
            "cate/hop-dong/store",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async update(data) {
        const result = await this.request(
            "cate/hop-dong/update",
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async delete(id) {
        const result = await this.request(
            "cate/hop-dong/delete",
            {id},
            TypeMethodContsantEnum.DELETE
        );
        return result;
    }

    public async getBase64(data) {
        const result = await this.request(
            `cate/hop-dong/preview`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
}

export default CateContractService;
