import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import { TYPE_LABEL_CLASS } from "../Enum";
import { formatDateTime } from "../../../../helpers/NewCaHelper";
import SoftwareService from "../../../../services/software/SoftwareService";
import { onDeleteAlert, onSuccessAction } from "../../../common/swal/SwalCommon";
import {
  reloadPage
} from "../../../../helpers/NewCaHelper";
import { Tag } from "antd";

interface Props {
  location: Location;
  history: History;
  match: match;
}
interface State { }

export default class AgencyTable extends Component<Props, State> {
  state = {};
  service = new SoftwareService();
  componentDidMount() {
    this.fetch();
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    try {
      const result = await this.service.getSoftwares(params);
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  onClickDeleteButton = (id) => {
    const self = this;
    onDeleteAlert(async function (willDelete) {
      if (willDelete) {
        const result = await self.service.deleteSoftwareById(id);
        if (result && result.status === 200) {
          onSuccessAction('Xóa phần mềm thành công!', reloadPage(self.props.location, self.props.history));
        }
        else if (result && result.status === 406){
          onFailAction(result.message)
        }
      }
    });
  }

  render() {
    return (
      <Table
        columns={this.columns}
        onFetchData={this.fetch}
      />
    );
  }

  onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickLock={() => this.onClickDeleteButton(record.id)}
        onClickUpdate={() =>
          this.props.history.push(`/phan-mem/cap-nhat/${record.id}`)
        }
        isDisplayLockButton={true}
        isDisplayUpdateButton={true}
      />
    );
  };

  renderTypeColumn = (text) => {
    return (
      <div>
        {Object.keys(TYPE_LABEL_CLASS).map((key, index) => (
          (text.indexOf(key) > -1) ?
            <Tag key={key} color={`${TYPE_LABEL_CLASS[key].class}`}>
              {TYPE_LABEL_CLASS[key].label}
            </Tag> : ''
        ))}
      </div>
    );
  };

  columns = [
    { title: "Tên phần mềm", dataIndex: "name" },
    { title: "Version", dataIndex: "version" },
    { title: "Ngày hiệu lực", dataIndex: "valid_at" },
    {
      title: "Hiển thị trên",
      dataIndex: "type",
      render: this.renderTypeColumn
    },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: (text) => formatDateTime(text)
    },

    {
      title: "Tác vụ",
      render: this.onRenderActionColumn
    }
  ];
}
