import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import SelectBox from "../../../common/form/SelectBox";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import { History } from "history";
import * as Yup from "yup";
import UserService from "../../../../services/user/UserService";
import RoleService from "../../../../services/role/RoleServices";
import { TypeUser } from "../UserEnum";
import InputFileUpload from "../../../common/form/InputFileUpload";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";
import { queryStringToJSON } from "../../../../helpers/NewCaHelper";
import { Card } from 'antd';

interface Props {
  history: History;
}
interface State {
  username: string;
  email: string;
  password: string;
  confirm_password: string;
  phone: string;
  code: string;
  fullname: string;
  type: string;
  login_type: string;
  role: string;
  owner_id: string;
  level: string;
  token_agency: string;
  request_token_code: string;
  base_64: any;
  file_name: string;
  permissions: Object;
  agency: Object;
}
class CreateUserForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    type: "",
    owner_id: "",
    request_token_code: "",
    code: "",
    level: "",
    permissions: {},
    agency: {},
    request: {},
    isDisableCode: false,
    owner_type: 0,
    nvkdName: "",
  };
  componentDidMount() {
    if (this.props.history.location.search) {
      let paramArr = queryStringToJSON(this.props.history.location.search);
      this.setState({
        type: paramArr["type"],
        owner_id: paramArr["agency_id"],
        request_token_code: paramArr["code"]
      });
      this.getAgency(paramArr["type"]).then();
    }
  }

  getRoleList = async type => {
    const roleService = new RoleService();
    const lstRole = await roleService.getRoleList(type);
    if (lstRole) {
      let permissions = {};
      lstRole.data.map(value => (permissions[value.id] = value.name));
      this.setState({ permissions });
      this.props.setFieldValue("role", "");
    }
  };

  //Lấy dữ liệu cộng tác viên hoặc đại lý
  getAgency = async type => {
    const userService = new UserService();
    const lst = await userService.getAgencyContributor(type);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      this.setState({ agency });
    }
  };

  //truyền mã tài khoản tự động khi loại vai trò : NVKD
  getCode = async type => {
    const userService = new UserService();
    const result = await userService.getCode(type);
    return result
  }

  onChangeSelectedRole = async (e) => {
    if (e){
      this.state.type = e;
      this.props.setFieldValue("type", e);
      this.state.owner_id = e;
      this.getRoleList(e).then();
      this.getAgency(e).then();
      if (Number(e) === 5){
        const result = await this.getCode(5)
        this.props.setFieldValue('code', result)
        this.state.isDisableCode= true
      }
      else {
        this.state.isDisableCode = false
        this.props.setFieldValue('code', '')
      }
    }
    this.props.setFieldValue("owner_id",undefined);
    this.props.setFieldValue("nvkdName",undefined);


  };

  onChangeFile = file => {
    this.props.setFieldValue("base_64", file.base64);
    this.props.setFieldValue("file_name", file.fileName);
  };

  handleChangeBoxAgency = async e => {
    if (e) {
      let fullName = ""
      let userName = ""
      let email = ""

      this.props.setFieldValue("owner_id", e);
      const userService = new UserService();
      const result = await userService.getNvkd(this.state.type, Number(e));
      const codeUser = await userService.getCodeUser(result.code);

      if (result && Number(result.type) === 5) {
        fullName = result.fullname
        userName = result.username
        email    = result.email
      }else {
        if (result.user !== null){
          fullName = result.user.fullname
          userName = result.user.username
          email    = result.user.email
        }
      }

      this.state.nvkdName = fullName;
      this.props.setFieldValue('username', userName);
      this.props.setFieldValue("nvkdName", fullName);
      this.props.setFieldValue("fullname", fullName);
      this.props.setFieldValue("email", email);
      this.props.setFieldValue('code', codeUser);
      this.state.isDisableCode= true
    }else {
      this.props.setFieldValue("owner_id",undefined);
    }
  };

  handleChangeLevelAgency = async e => {
    this.props.setFieldValue('level', e)
  }

  onchangeLoginType = () => {
    const login_type = this.props.values.login_type + "";
    if (login_type === "2") {
      return (
        <InputWithLabel
          name="token_agency"
          label="Serial chứng thư số "
          type="token_agency"
          wrapClass="col-md-6 form-group"
          onChangeValue={this.props.handleChange}
          value={this.props.values.token_agency}
          error={this.props.errors.token_agency}
          isRequired={true}
          maxLength={255}
        />
      );
    } else {
      return (
        <React.Fragment>
          {" "}
          <InputWithLabel
            name="password"
            label="Mật khẩu"
            type="password"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={this.props.values.password}
            error={this.props.errors.password}
            isRequired={true}
            maxLength={255}
          />
          <InputWithLabel
            name="confirm_password"
            label="Nhập lại mật khẩu"
            type="password"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={this.props.values.confirm_password}
            error={this.props.errors.confirm_password}
            isRequired={true}
            maxLength={255}
          />
        </React.Fragment>
      );
    }
  };

  changeLoginType = (e) => {
    this.props.setFieldValue("login_type", e)
  };

  changePermission = (e) => {
    this.props.setFieldValue("role", e)
  };

  render() {
    const {
      username,
      email,
      code,
      phone,
      fullname,
      login_type,
      type,
      role,
      owner_id,
      level
    } = this.props.values;
    return (
      <Card className="m-r-15-i m-l-15-i" title={<label><h4 className={' text-uppercase text-bold'}>Thêm mới người dùng</h4></label>} size="default">
      <form>
      <div className="input-group">
          <SelectBox
            options={TypeUser.TYPE}
            label="Loại người dùng"
            name="type"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectedRole}
            value={type}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={true}
          />
          <SelectBox
            options={this.state.permissions}
            label="Vai trò"
            name="role"
            wrappedClass="col-md-3 form-group"
            onChange={this.changePermission}
            value={role}
            firstOptionLabel="Chọn vai trò"
            error={this.props.errors.role}
            isRequired={true}
          />
          <SelectBoxAgency
            options={this.state.agency}
            label="Tên đại lý/tên cộng tác viên"
            name="owner_id"
            wrappedClass="col-md-2 form-group"
            onChange={this.handleChangeBoxAgency}
            value={owner_id}
            firstOptionLabel="Chọn đại lý/cộng tác viên"
            error={this.props.errors.owner_id}
            isAllowClear={false}
            isRequired={true}
          />
        {Number(this.state.type) === 7 ? (
            <SelectBoxAgency
                options={{1: "Admin", 2: "Cấp 2"}}
                label="Phân quyền"
                name="level"
                wrappedClass="col-md-2 form-group"
                onChange={this.handleChangeLevelAgency}
                value={level}
                firstOptionLabel="Chọn cấp"
                error={this.props.errors.level}
                isRequired={true}
            />
        ) : ("")}

        <InputWithLabel
            name="request_token_code"
            label="Mã yêu cầu"
            wrapClass="col-md-2 form-group"
            onChangeValue={this.props.handleChange}
            value={this.state.request_token_code ? this.state.request_token_code : ""}
            error={this.props.errors.request_token_code}
            isDisabled={true}
            maxLength={255}
        />
        {Number(this.state.owner_id) === 7 || Number(this.state.owner_id) === 8 ? (
            <InputWithLabel
                name="nvkdName"
                label="NVKD"
                wrapClass="col-md-3 form-group"
                onChangeValue={this.props.handleChange}
                value={this.state.nvkdName}
                isDisabled={true}
            />
        ):("")}
        </div>
        <div className="input-group" hidden>
          <InputFileUpload
            name="file"
            error={this.props.errors.base_64}
            classWrapped="col-md-4 form-group"
            label="File đính kèm"
            onChangeFileUpload={this.onChangeFile}
            isRequired={false}
    />
        </div>
        {}
        <div className="input-group">
          <InputWithLabel
            name="username"
            label="Tên đăng nhập"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={username}
            error={this.props.errors.username}
            isRequired={true}
            maxLength={255}
          />
          <InputWithLabel
            name="code"
            label="Mã tài khoản"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
            maxLength={255}
            isDisabled= {this.state.isDisableCode}
          />
          <InputWithLabel
            name="fullname"
            label="Tên đầy đủ"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={fullname}
            error={this.props.errors.fullname}
            isRequired={true}
            maxLength={255}
          />
          <InputWithLabel
            name="email"
            label="Email"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={email}
            error={this.props.errors.email}
            isRequired={true}
            maxLength={255}
          />
        </div>
        <div className="input-group">
          <SelectBox
            options={TypeUser.LOGIN_TYPE}
            label="Loại đăng nhập"
            name="login_type"
            wrappedClass="col-md-3 form-group"
            onChange={this.changeLoginType}
            value={login_type}
            firstOptionLabel="Đăng nhập bằng"
            error={this.props.errors.login_type}
            isRequired={true}
          />
          {this.onchangeLoginType()}

          <InputWithLabel
            name="phone"
            label="Số điện thoại"
            wrapClass="col-md-3 form-group"
            onChangeValue={this.props.handleChange}
            value={phone}
            error={this.props.errors.phone}
            isRequired={false}
            maxLength={50}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton
            history={this.props.history}
            url="/quan-ly-nguoi-dung"
          />
          <SubmitButton onClick={this.props.handleSubmit}/>
        </div>
      </form>
      </Card>
    );
  }
}

const UserCreateForm = withFormik<Props, State>({
  mapPropsToValues: () => ({
    username: "",
    password: "",
    confirm_password: "",
    code: "",
    email: "",
    fullname: "",
    phone: "",
    type: "",
    login_type: "",
    role: "",
    owner_id: "",
    level: "",
    permissions: "",
    agency: "",
    base_64: "",
    file_name: "",
    token_agency: "",
    request_token_code: "",
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required("Tên đăng nhập không được bỏ trống."),
    email: Yup.string()
      .required("Email không được bỏ trống.")
      .email("Email không đúng định dạng."),
    code: Yup.string().required("Mã người dùng không được bỏ trống."),
    fullname: Yup.string().required("Tên người dùng không được bỏ trống."),
    type: Yup.number().required("Loại người dùng không được bỏ trống."),
    login_type: Yup.number().required("Loại đăng nhập không được bỏ trống."),
    role: Yup.number().required("Vai trò người dùng không được bỏ trống."),
    // level: Yup.number().required("Phân quyền đại lý không được bỏ trống.")
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    let paramArr = queryStringToJSON(props.history.location.search);
    values.request_token_code = paramArr["code"];
    setSubmitting(false);
    const user = new UserService();
    const result = await user.createUser(values);
    if (result) {
      if (result.status === 200) {
        onSuccessAction("Thêm mới người dùng thành công!", function() {
          props.history.push('/quan-ly-nguoi-dung');
        });
      } else if (result.status === 422) {
        setErrors(result.error);
      } else {
        onFailAction('Có lỗi khi lưu dữ liệu');
      }
    }
  }
})(CreateUserForm);

export default UserCreateForm;
