import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import { History } from "history";
import * as Yup from "yup";
import Swal from "sweetalert";
import InputWithLabel from "../../common/form/InputWithLabel";
import ChangePasswordButton from "../../common/button/ChangePasswordButton";
import UserService from "../../../services/user/UserService";
import {Card} from "antd";

interface Props {
    history: History;
}
interface State {
    password : string;
    newPassword : string;
    reNewPassword : string
}

class ChangePassForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    data: {
      password: "",
      newPassword: "",
      reNewPassword: ""
    }
  };

  async componentDidMount() {
  }
  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };

  onSubmitForm = async e => {
    e.preventDefault();
  };

  render() {
    const {
        password,
        newPassword,
        reNewPassword,
    } = this.props.values;
    return (
      <Card className="m-r-15-i m-l-15-i mt-1" title={<label>Đổi mật khẩu</label>} size="default">
        <form>
            <div className="input-group">
              <InputWithLabel
                name="password"
                type="password"
                wrapClass="col-md-4 form-group"
                onChangeValue={this.props.handleChange}
                value={password}
                error={this.props.errors.password}
                isRequired={true}
                label={"Mật khẩu cũ"}
              />
              <InputWithLabel
                name="newPassword"
                type="password"
                wrapClass="col-md-4 form-group"
                onChangeValue={this.props.handleChange}
                value={newPassword}
                error={this.props.errors.newPassword}
                isRequired={true}
                label={"Mật khẩu mới"}
              />
              <InputWithLabel
                name="reNewPassword"
                type="password"
                wrapClass="col-md-4 form-group"
                onChangeValue={this.props.handleChange}
                value={reNewPassword}
                error={this.props.errors.reNewPassword}
                isRequired={true}
                label={"Nhập lại mật khẩu mới"}
              />
            </div>
            <div className="col-6">
            <ChangePasswordButton onClick={this.props.handleSubmit}></ChangePasswordButton>
            </div>
        </form>
      </Card>
    );
  }
}


const ChangePassFormValid = withFormik<Props, State>({
    mapPropsToValues: () => ({
        password: "",
        newPassword: "",
        reNewPassword: ""
    }),
    validationSchema: Yup.object().shape({
        password: Yup.string().required('Mật khẩu cũ không được bỏ trống.'),
        reNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Mật khẩu chưa trùng khớp.")
        .required("Nhập lại mật khẩu không được bỏ trống."),
        newPassword: Yup.string()
        .required("Mật khẩu mới không được bỏ trống.")
        .min(6, "Mật khẩu mới phải có ít nhất 6 ký tự."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
        const user = new UserService();
        const result = await user.changePassword(values);
        if (result.status === 200) {
            Swal({
            title: "Thành công!",
            text: "Đổi mật khẩu thành công!",
            icon: "success",
            dangerMode: false
            }).then(function() {
                props.history.push("/logout");
            });
        }else if(result.status === 1){
            setSubmitting(false)
            setErrors(result.errors)
        }
    }
  })(ChangePassForm);
  export default ChangePassFormValid;