import axios from "axios";
import { authenticationService } from "../services/AuthenticationService";
import { TypeMethodContsantEnum } from "./constant/MethodAPIConstant";
import { loading } from "../components/common/loading/Loading";
import swal from "sweetalert";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
class FetchAPI {
  public BASE_URL = REACT_APP_BASE_API_URL;

  private axiosInstance: any;

  constructor() {
    const currentUserValue = authenticationService.currentUserValue
      ? authenticationService.currentUserValue.token
      : "";
    this.axiosInstance = axios.create({
      baseURL: this.BASE_URL,
      timeout: 30000,
      headers: { Authorization: currentUserValue,'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json', 'Accept': 'application/json'},
      httpAgent: true
    });
  }

  public async request(
    path: string,
    data: object,
    method: TypeMethodContsantEnum
  ) {
    const dynamicKeyData =
      method === TypeMethodContsantEnum.GET ? "params" : "data";

    const configRequest = {
      url: path,
      method,
      [dynamicKeyData]: data
    };
    try {
      loading.runLoadingBlockUI();
      const result = await this.axiosInstance
        .request(configRequest)
        .catch(response => handleResponse(response));
      loading.stopRunLoading();
      return result.data;
    } catch(error) {
      console.log(JSON.stringify(error));
    }finally{
      loading.stopRunLoading();
    }
  }

  public GET_API_USER_EXAMPLE(path: string = "/user", data: object) {
    return this.request(path, data, TypeMethodContsantEnum.GET);
  }
}

export function handleResponse(error) {
  const response = error.response;
  if (response && [ 401].indexOf(response.status) !== -1) {
    // authenticationService.logout();
    // window.location.reload();
  }
  swal({
    title: "Error",
    text: error.message,
    icon: "error",
    dangerMode: false
  });
  throw new Error(error);
}

export default FetchAPI;
