import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, queryStringToJSON,formatDateTime} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import MenuDropAddendum from "../agency/form/btn-group/menu-drop-addendum/MenuDropAddendum";
import {Dropdown, Tag} from "antd";
import {onFailAction} from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import ContractManagerService from "../../../services/contract-manager/ContractManagerService";
import Store from '../../../store/Store';
import AntModal from "../../common/modal/AntModal";

interface Props extends RouteComponentProps {
}

const TITLE = "Quản lý hợp đồng";
const authUser = Store.getState().authReducer.type

const ContractManager: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({
        code: "",
        type_sign: "",
        type_contract: "",
        created_by:""
    });
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [file, setFile] = useState("");
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const onChangeDataSearchTypeSign = (e) => {
        setDataSearch({...dataSearch, "type_sign": e})
    }
    const onChangeDataSearchTypeContract = (e) => {
        setDataSearch({...dataSearch,"type_contract": e})
    }
    const onChangeDataSearchCreatedBy  = (e) => {
        setDataSearch({...dataSearch,"created_by" : e})
    }
    const fetchDataFunction = async () => {
        const service = new ContractManagerService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result =  await service.list({...dataSearch, ...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    //Click xem hợp đồng phụ lục
    const onPreviewFile = async (id, type, isAddendum, typeAddendum) => {
        try {
            const service = new ContractManagerService();
            setVisibleModal(true);
            setLoadingModal(true);
            const result = await service.onPreviewFile(id, type, isAddendum, typeAddendum);
            setFile(result.base64);
        } catch (error) {
            onFailAction("Có lỗi xảy ra!");
            setVisibleModal(false);
        } finally {
            setLoadingModal(false);
        }
    };
    const [listUser, setListUser] = useState();
    const getListUser = async () => {
        const service = new ContractManagerService();
        const result = await service.getListCrmUser();
        setListUser(result);
    };
    useEffect(() => {
        getListUser();
        // eslint-disable-next-line
    },[]);
    //Đóng modal
    const onOkModal = () => {
        setVisibleModal(false);
    };
    // nút xem file
    const renderActionButton = (text, record, index) => {
        return (
            <div className="row justify-content-center">
                {(record.belong_to === 2 && record.type_contract_manager === 1) || record.type_contract_manager === 2 || (record.belong_to === 1 && record.agency && record.agency.type === 1)
                    ? (<button title="Xem file" className="btn btn-outline-primary btn-sm w-100 ml-2 mr-2" onClick={() => onPreviewFile(record.id, record.type_contract_manager, false, '')}>Xem file</button>)
                    : ('')
                }
                {(record.belong_to === 1 && record.type_contract_manager === 1 && record.agency.type === 2)
                    ?
                    <Dropdown
                        overlay={
                            <MenuDropAddendum onClick={(index) => onPreviewFile(record.id, record.type_contract_manager, true, index)}
                                  contract={true}
                                  addendum1={!!(record.agency.product && record.agency.product.includes("1"))}
                                  addendum2={!!(record.agency.product && record.agency.product.includes("2"))}
                                  addendum3={!!(record.agency.product && record.agency.product.includes("3"))}
                            />
                        }
                        trigger={["click"]}
                        placement="bottomLeft"
                    >
                        <button className="btn btn-outline-primary btn-sm w-100 ml-2 mr-2">Xem file <i className="fas fa-angle-down"/></button>
                    </Dropdown> : ''
                }
            </div>
        );
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã đại lý/CTV"}
                        />
                        <SelectBox
                            name="type_sign"
                            options={{1:"Giấy", 2:"Điện tử"}}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Hình thức ký"
                            onChange={onChangeDataSearchTypeSign}
                            value={dataSearch.type_sign}
                        />
                        <SelectBox
                            name="type_contract"
                            options={{1:"Hợp đồng đại lý/CTV", 2:"Biên bản thanh lý"}}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Loại hợp đồng"
                            onChange={onChangeDataSearchTypeContract}
                            value={dataSearch.type_contract}
                        />
                        <SelectBox
                            name="created_by"
                            options={listUser ? listUser : ''}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Người quản lý"
                            onChange={onChangeDataSearchCreatedBy}
                            value={dataSearch.created_by}
                            hidden={authUser===5 && authUser === 7 && authUser === 8 ? true : false}
                        />
                        <div className="col-md-2 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                        <AntModal
                            visible={visibleModal}
                            loading={loadingModal}
                            className={"w-75 h-60"}
                            bodyStyle={{height: "900px"}}
                            style={{top: ""}}
                            onCLickOk={onOkModal}
                        >
                            <iframe
                                title="Quản lý hợp đồng"
                                src={`data:application/pdf;base64,${file}`}
                                height="100%"
                                width="100%"
                            />
                        </AntModal>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Mã đại lý/CTV",
                            dataIndex: "",
                            render: (e) => {
                                if (Number(e.type_contract_manager) === 1) {
                                    if (Number(e.belong_to) === 1) {
                                        return e.agency.code;
                                    }
                                    if (Number(e.belong_to) === 2) {
                                        return e.contributor.code;
                                    }
                                }else{
                                    if (Number(e.belong_to) === 1) {
                                        return e.owner_agency.code;
                                    }
                                    if (Number(e.belong_to) === 2) {
                                        return e.owner_contributor.code;
                                    }
                                }
                            }},
                        { title: "Loại",
                            dataIndex: "",
                            render: (e) => {
                                if (Number(e.type_contract_manager) === 1) {
                                    return <Tag color="green">Hợp đồng đại lý/CTV</Tag>
                                }else{
                                    return <Tag color="red">Biên bản thanh lý</Tag>
                                }
                            }},
                        {title: "Hình thức ký",
                            dataIndex: "",
                            render: (e) => {
                                if (Number(e.type_contract_manager) === 1) {
                                    if (Number(e.belong_to) === 1) {
                                        if (Number(e.agency.type) === 1){
                                            return <Tag color="green">Hợp đồng giấy</Tag>
                                        } else {
                                            return <Tag color="blue">Hợp đồng điện tử</Tag>
                                        }
                                    }else{
                                        if (Number(e.contributor.type) === 1){
                                            return  <Tag color="green">Hợp đồng giấy</Tag>
                                        } else {
                                            return <Tag color="blue">Hợp đồng điện tử</Tag>
                                        }
                                    }
                                }else{
                                    if (Number(e.file_type) === 1) {
                                        return <Tag color="blue">Biên bản thanh lý điện tử</Tag>
                                    }else{
                                        return <Tag color="green">Biên bản thanh lý giấy</Tag>
                                    }
                                }
                            }},
                        {
                            title: "Ngày hợp đồng",
                            dataIndex: "",
                            render: (e) => formatDateTime(e.contract_date)
                        },
                        {
                            title: "Người tạo",
                            dataIndex: "user.fullname",
                        },
                        {
                            title: "Bên A",
                            dataIndex: "",
                            render: (e) => {
                                if (Number(e.type_contract_manager) === 1) {
                                    if (e.belong_to === 1){
                                        if (e.agency.type === 1){
                                            return <Tag color="green">Đã ký</Tag>
                                        } else {
                                            if (e.newca_signed_status) {
                                                return <Tag color="green">Đã ký</Tag>
                                            }else{
                                                return <Tag color="red">Chưa ký</Tag>
                                            }
                                        }
                                    } else {
                                        if (e.contributor.type === 1){
                                            return <Tag color="green">Đã ký</Tag>
                                        } else {
                                            if (e.newca_signed_status) {
                                                return <Tag color="green">Đã ký</Tag>
                                            }else{
                                                return <Tag color="red">Chưa ký</Tag>
                                            }
                                        }
                                    }
                                }else{
                                    if (e.file_type === 1){
                                        if (e.newca_sign_status) {
                                            return <Tag color="green">Đã ký</Tag>
                                        }else{
                                            return <Tag color="red">Chưa ký</Tag>
                                        }
                                    }else{
                                        return <Tag color="green">Đã ký</Tag>
                                    }
                                }
                            }
                        },
                        {
                            title: "Bên B",
                            dataIndex: "",
                            render: (e) => {
                                if (Number(e.type_contract_manager) === 1) {
                                    if (e.belong_to === 1){
                                        if (e.agency.type === 1){
                                            return <Tag color="green">Đã ký</Tag>
                                        } else {
                                            if (e.owner_signed_status) {
                                                return <Tag color="green">Đã ký</Tag>
                                            }else{
                                                return <Tag color="red">Chưa ký</Tag>
                                            }
                                        }
                                    } else {
                                        if (e.contributor.type === 1){
                                            return <Tag color="green">Đã ký</Tag>
                                        } else {
                                            if (e.owner_signed_status) {
                                                return <Tag color="green">Đã ký</Tag>
                                            }else{
                                                return <Tag color="red">Chưa ký</Tag>
                                            }
                                        }
                                    }

                                }else{
                                    if (e.file_type === 1){
                                        if (e.customer_sign_status) {
                                            return <Tag color="green">Đã ký</Tag>
                                        }else{
                                            return <Tag color="red">Chưa ký</Tag>
                                        }
                                    }else{
                                        return <Tag color="green">Đã ký</Tag>
                                    }
                                }
                            }
                        },
                        {
                            title: "File HĐ - Phụ lục",
                            render: renderActionButton
                        }
                    ]}
                    onFetchData={fetchDataFunction}
                />
            </div>
        </div>
    );
};
export default withNavbar()(ContractManager);
