import React, { Component } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { History, Location } from "history";
import { match } from "react-router";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import SearchButton from "../../common/button/SearchButton";
import { TypeService } from "./Enum";
import InputWithLabel from "../../common/form/InputWithLabel";
import SelectBox from "../../common/form/SelectBox";
import Helmet from "react-helmet";
import MainTable from "./table/MainTable";
interface Props {
  history: History;
  location: Location;
  match: match;
  name: string;
}
interface State {
  dataSearch: any;
}
const TITLE = 'Danh sách dịch vụ';
class ServiceIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      status: "",
      text_search: ""
    }
  };

  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = (data) => {
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  }

  componentDidMount() {
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch: queryString
    })
  }

  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <h4 className="card-title1">{TITLE}</h4>
        <div className="card-body">
          <form onSubmit={this.onSubmitSearchButton} className='search-form-index'>
            <div className="input-group">
              <SelectBox
                value={this.state.dataSearch.status}
                label=""
                onChange={this.onChangeDataSearchInput}
                name="status"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={TypeService.STATUS}
                firstOptionLabel="Trạng thái"
              />
              <InputWithLabel
                name="text_search"
                wrapClass="col-md-4 form-group nopadding-left"
                onChangeValue={this.onChangeDataSearchInput}
                value={this.state.dataSearch.text_search}
                isRequired={false}
                label={""}
                placeholder={"Tên hoặc mã dịch vụ"}
              />
              <div className="col-md-3 form-group">
                <SearchButton onClick={this.onSubmitSearchButton} />
                <GoToCreatePageButton
                  toURL={`${this.props.match.url}/them-moi`}
                />
              </div>
            </div>
          </form>
          <MainTable
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(ServiceIndex);

export default pageWithNavBar;
