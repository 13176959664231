import React, { Component } from "react";

interface Props {}
interface State {
  error: any;
  info: any;
}

export default class ErrorHandle extends Component<Props, State> {
  state = {
    error: false,
    info: {componentStack:''}
  };

  componentDidCatch(error, info) {
    this.setState({
      error: error,
      info: info,
    });
  }

  render() {
    if(this.state.error) {
        // Some error was thrown. Let's display something helpful to the user
        return (
          <div>
            <h5>{this.state.error}</h5>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.info.componentStack}
            </details>
          </div>
        );
      }
      // No errors were thrown. As you were.
      return this.props.children;
  }
}
