import React, { Component } from "react";
import { Modal } from 'antd';
import InputFileUpload from "./InputFileUpload";

interface Props {
    handleCancel?: any;
    handleImport?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    error? : string;
    onchangeFileUpload?: any;
    filename?: string;
}
interface State {
    visible: boolean;
}

export default class ModalDeny extends Component<Props, State> {
    render() {
        return (
            <div>
                <Modal
                    title={'Import token'}
                    visible={this.props.visible}
                    onOk={this.props.handleImport}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Import"
                    cancelText="Đóng"
                    className="modal-xs"
                    width="40%"
                    closable={false}
                    // bodyStyle={}
                >
                <InputFileUpload
                    name="file"
                    classWrapped="col-md-12"
                    label="File import"
                    filename={this.props.filename}
                    onChangeFileUpload={this.props.onchangeFileUpload}
                    isRequired={true}
                    accept={".xlsx"}
                    error={this.props.error}
                />
                <div className="col-md-12">
                    <a href="/file-import-mau.xlsx" download><i className="fas fa-download fa-lg"/> Tải file import mẫu</a>
                </div>
                </Modal>
            </div>
        );
    }
}
