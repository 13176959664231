import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {History} from "history";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import AssetCategoryService from "../../../../services/asset-category/AssetCategoryService";
import SelectDate from "../../../common/form/SelectDate";
import WhDocumentService from "../../../../services/WhDocument/WhDocumentService";
import { loading } from "../../../common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";
import InputFileUpload from "../../../common/form/InputFileUpload";
import { Card } from "antd";
import ProviderCategoryService from "../../../../services/ProviderCategory/ProviderCategoryService";

interface FormValues {
    code: string;
    note: string;
    asset_id: string;
    total: string;
    receive_date: string;
    deliver_pos: string;
    deliver: string;
    receiver: string;
    receiver_pos: string;
    file: string;
    deliver_phone: string;
    receiver_phone: string;
    deliver_email: string;
    receiver_email: string;
    provider_id: string;
}

interface OtherProps {
    history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const WhDocumentCreate: React.FC<Props> = props => {

    const onChangeSelectReceiveDate = (date, dateString) => {
        props.setFieldValue("receive_date", dateString);
    };

    const [assetCateList, setAssetCateList] = useState({});

    const [providerCateList, setProviderCateList] = useState({});

    const {
        code,
        note,
        asset_id,
        total,
        receive_date,
        deliver_pos,
        deliver,
        receiver,
        receiver_pos,
        file,
        deliver_email,
        deliver_phone,
        receiver_email,
        receiver_phone,
        provider_id
    } = props.values;

    const fetchWhAssetOption = async () => {
        const assetCate = new AssetCategoryService();
        const result = await assetCate.all(1);
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
            setAssetCateList(options);
    };

    const fetchProviderOption = async () => {
        const assetCate = new ProviderCategoryService();
        const result = await assetCate.all();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setProviderCateList(options);
    };

    useEffect(() => {
        fetchWhAssetOption();
        fetchProviderOption();
    }, []);

    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            if(fileData){
                props.setFieldValue("file", fileData.file);
            }
            loading.stopRunLoading();
        } else {
            props.setFieldValue("file", "");
        }
    };
    const changeAssetId = (e) => {
        props.setFieldValue('asset_id',e);
    };
    const changeProviderId = (e) => {
        props.setFieldValue('provider_id',e);
    };
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Nhập kho tài liệu bán hàng</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            label="Mã nhập kho"
                            wrapClass="col-md-2 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                            placeholder="Mã nhập kho"
                        />
                        <SelectBox
                            name="asset_id"
                            options={assetCateList}
                            label="Loại tài liệu bán hàng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Loại tài liệu bán hàng"
                            onChange={changeAssetId}
                            value={asset_id}
                            error={props.errors.asset_id}
                            isRequired={true}
                        />
                        <SelectBox
                            name="provider_id"
                            options={providerCateList}
                            label="Nhà cung cấp"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Nhà cung cấp"
                            onChange={changeProviderId}
                            value={provider_id}
                            error={props.errors.provider_id}
                            isRequired={true}
                        />
                        <InputWithLabel
                            name="total"
                            label="Số lượng"
                            wrapClass="col-md-1 form-group"
                            onChangeValue={props.handleChange}
                            value={total}
                            error={props.errors.total}
                            isRequired={true}
                            maxLength={11}
                            type="number"
                        />
                        <SelectDate
                            label="Ngày nhập kho"
                            name="receive_date"
                            wrappedClass="col-md-3 form-group"
                            onChange={onChangeSelectReceiveDate}
                            value={receive_date}
                            error={props.errors.receive_date}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputFileUpload
                            name="file"
                            classWrapped="col-md-3 "
                            label="File bàn giao"
                            filename={file}
                            onChangeFileUpload={onChangeFile}
                            error={props.errors.file}
                            isRequired={true}
                            accept={"application/pdf"}
                        />
                        <InputWithLabel
                            name="note"
                            label="Ghi chú"
                            wrapClass="col-md-9 form-group"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                        />
                    </div>
                    <Card className="m-r-15-i m-l-15-i" title="Thông tin người bàn giao">
                        <div className="input-group">
                            <InputWithLabel
                                name="deliver"
                                label="Người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver}
                                error={props.errors.deliver}
                                isRequired={true}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="deliver_pos"
                                label="Chức vụ người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_pos}
                                error={props.errors.deliver_pos}
                                isRequired={false}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="deliver_phone"
                                label="Số điện thoại người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_phone}
                                error={props.errors.deliver_phone}
                                isRequired={false}
                                maxLength={20}
                            />
                            <InputWithLabel
                                name="deliver_email"
                                label="Email người bàn giao"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={deliver_email}
                                error={props.errors.deliver_email}
                                isRequired={false}
                                maxLength={255}
                            />
                        </div>
                    </Card>
                    <Card className="m-r-15-i m-l-15-i mt-3" title="Thông tin người nhận">
                        <div className="input-group m-t-10">
                            <InputWithLabel
                                name="receiver"
                                label="Người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver}
                                error={props.errors.receiver}
                                isRequired={true}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="receiver_pos"
                                label="Chức vụ người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_pos}
                                error={props.errors.receiver_pos}
                                isRequired={false}
                                maxLength={255}
                            />
                            <InputWithLabel
                                name="receiver_phone"
                                label="Số điện thoại người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_phone}
                                error={props.errors.receiver_phone}
                                isRequired={false}
                                maxLength={20}
                            />
                            <InputWithLabel
                                name="receiver_email"
                                label="Email người nhận"
                                wrapClass="col-md-3 "
                                onChangeValue={props.handleChange}
                                value={receiver_email}
                                error={props.errors.receiver_email}
                                isRequired={false}
                                maxLength={255}
                            />
                        </div>
                    </Card>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            url="/kho-tai-lieu-ban-hang"
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>

                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        note: "",
        asset_id: "",
        total: "",
        receive_date: "",
        deliver: "",
        deliver_pos: "",
        receiver: "",
        receiver_pos: "",
        file: "",
        deliver_phone: "",
        receiver_phone: "",
        deliver_email: "",
        receiver_email: "",
        provider_id: "",
    }),
    validationSchema: Yup.object().shape({
        code: Yup.string().required("Mã nhập kho không được bỏ trống."),
        asset_id: Yup.string().required("Loại tài liệu bán hàng không được bỏ trống."),
        total: Yup.string().required("Tổng nhập kho không được bỏ trống."),
        receive_date: Yup.string().required("Ngày nhập kho không được bỏ trống."),
        deliver: Yup.string().required("Người giao không được bỏ trống."),
        receiver: Yup.string().required("Người nhận không được bỏ trống."),
        provider_id: Yup.string().required("Nhà cung cấp không được bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new WhDocumentService();
        const result = await service.store(values);
        if (result && result.status === 200) {
            onSuccessAction("Thêm mới thành công!", function () {
                props.history.goBack();
            });
        } else if (result && result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction('Có lỗi trong quá trình thêm mới');
        }
    }
})(WhDocumentCreate);

export default withNavbar()(RenderCreateForm);
