export const STATUS_LABEL_CLASS = {
    1: {
        label: "Chờ duyệt",
        class: ""
    },
    2: {
        label: "Chờ duyệt",
        class: "cyan"
    },
    3: {
        label: "Chờ kế toán duyệt",
        class: "orange"
    },
    4: {
        label: "Chờ giám đốc duyệt",
        class: "blue"
    },
    5: {
        label: "Giám đốc từ chối",
        class: "red"
    },
    6: {
        label: "Giám đốc đã duyệt",
        class: "green"
    },
    7: {
        label: "Hoàn thành",
        class: "green"
    },
    8: {
        label: "Chờ cập nhật biên bản thanh lý",
        class: "orange"
    },
    10: {
        label: "Đã hủy",
        class: "red"
    }
};
export class OPTION {
    static readonly STATUS = {
        1: "Chờ duyệt",
        2: "Chờ đại lý duyệt",
        3: "Chờ kế toán duyệt",
        4: "Chờ giám đốc duyệt",
        5: "Giám đốc từ chối",
        6: "Giám đốc đã duyệt",
        7: "Hoàn thành",
        8: "Chờ cập nhật biên bản thanh lý",
        10: "Đã hủy"
    };
}

export const STATUS_TYPE_CLASS = {
    1: {
        label: "Đại lý",
        class: "cyan"
    },
    2: {
        label: "Cộng tác viên",
        class: "blue"
    }
};