import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { convertJsonToQueryString, formatDateTime, reloadPage, queryStringToJSON } from '../../../helpers/NewCaHelper';
import { RouteComponentProps } from 'react-router-dom';
import InputWithLabel from '../../common/form/InputWithLabel';
import SearchButton from '../../common/button/SearchButton';
import GoToCreatePageButton from '../../common/button/GoToCreatePageButton';
import NewTable from '../../common/table/NewTable';
import VersionTokenServices from '../../../services/version-token/VersionTokenServices';
import { onFailAction, onDeleteAlert, onSuccessAction } from '../../../helpers/SwalCommon';
import TableActionButton from '../user/table/action-button/TableActionButton';
import withNavbar from '../../common/HOC/NavbarContainerHOC';

interface Props extends RouteComponentProps{
}

const TITLE = "Danh mục version token";

const VersionToken: React.FC<Props> = (props) => {
    const [dataSearch, setDataSearch] = useState({ ten: "", ma: "" });

    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
          search: queryString
        });
      };

      const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
      };

      const fetchDataFunction = async () => {
        const service = new VersionTokenServices();
        const queryString = queryStringToJSON(props.location.search);
        try {
          return await service.list({...dataSearch,...queryString});
        } catch (error) {
          console.log(error);
            onFailAction();
        }
      };

      const onClickDeleteButton = id => {
        onDeleteAlert(async willDelete => {
          if (willDelete) {
            const service = new VersionTokenServices();
            const result = await service.delete(id);
            if (result && result.status === 200) {
              onSuccessAction(
                "Xóa version token thành công!",
                reloadPage(props.location, props.history)
              );
            }
          }
        });
      };

      const onRenderActionColumn = (text, record, index) => {
        return (
          <TableActionButton
            onClickUpdate={() =>
              props.history.push(`/danh-muc-version-token/cap-nhat/${record.id}`)
            }
            onClickDelete={() => onClickDeleteButton(record.id)}
          />
        );
      };

    return (
        <div className="card">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <h4 className="card-title1">{TITLE}</h4>
      <div className="card-body">
        <form onSubmit={onSubmitSearchButton} className="search-form-index">
          <div className="input-group">
            <InputWithLabel
              name="ma"
              wrapClass="col-md-4 form-group nopadding-left"
              onChangeValue={onChangeDataSearchInput}
              value={dataSearch.ma}
              isRequired={false}
              label={""}
              placeholder={"Mã version token"}
            />
            <div className="col-md-3 form-group">
              <SearchButton onClick={onSubmitSearchButton} />
              <GoToCreatePageButton toURL={`${props.match.url}/them-moi`} />
            </div>
          </div>
        </form>
        <NewTable
          columns={[
            { title: "Mã", dataIndex: "code" },
            { title: "Loại token", dataIndex: "type" },
            { title: "Nhà cung cấp", dataIndex: "provider.name" },
            {
              title: "Ngày tạo",
              dataIndex: "created_at",
              render: text => formatDateTime(text)
            },
            {
              title: "Tác vụ",
              render: onRenderActionColumn
            }
          ]}
          onFetchData={fetchDataFunction}
        />
      </div>
    </div>
    )
}

export default withNavbar()(VersionToken);
