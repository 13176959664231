import React from "react";

interface Props {
  onClick: () => void;
  tittle?: string;
}

export default function ActionButtonActive(props: Props) {
  return (
    // eslint-disable-next-line
    <a
      className="pointer text-success ml-1 mr-1"
      title={(props.tittle) ? props.tittle : "Kích hoạt lại"}
      onClick={props.onClick}
    >
      <i className="fas fa-unlock-alt fa-lg" />
    </a>
  );
}
