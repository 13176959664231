import React, {Component} from "react";
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import "../css/main.css";
import { Select } from "antd";


const { Option } = Select;
interface Props {
    options: any;
    label: string;
    name: string;
    wrappedClass: string;
    firstOptionLabel?: string;
    onChange?: any;
    value?: any;
    error?: any;
    isRequired?: boolean;
    dataKey?: string;
    isDisabled?: boolean;
    hidden? : boolean;
    checkUpdate? : boolean;
}

interface State {
}

export default class SelectBox extends Component<Props, State> {
    state = {};

    render() {
        const {options} = this.props;
        const isUpdate = this.props.checkUpdate ? true: false
        return (
            <div className={this.props.wrappedClass} hidden={this.props.hidden}>
                {
                    (this.props.label) ?
                    <LabelInput
                        nameFor={this.props.name}
                        label={this.props.label}
                        isRequired={this.props.isRequired}
                    />
                    : ''
                }
                    <Select className="font-input col-md-12"
                        optionFilterProp="children"
                        showSearch
                        dropdownStyle={{ width: 200 }}
                        placeholder={this.props.firstOptionLabel}
                        onChange={this.props.onChange}
                        data-key={this.props.dataKey}
                        disabled={this.props.isDisabled}
                        allowClear={true}
                        value={isUpdate ? (this.props.value ? String(this.props.value) : '') : (this.props.value ? String(this.props.value) : this.props.firstOptionLabel)}
                    >
                        {Object.keys(options).map((key, index) => (
                            <Option key={index} value={key} title={options[key]} className={this.props.name} >
                                {options[key]}
                            </Option>
                        ))}
                    </Select>
                    <ValidateMessageError error={this.props.error}/>
            </div>
        );
    }
}
