import React, {useEffect,useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import SelectDateFormat from "../../../common/form/SelectDateFormat";
import BackButton from "../../../common/button/BackButton";
import CateDiscountService from "../../../../services/cate-discount/CateDiscountService"
import {RouteComponentProps} from "react-router-dom";
import SelectBox from "../../../common/form/SelectBox";
import DiscountList from "../DiscountList";

interface FormValues {
    code: string;
    name: string;
    type: string;
    belong_to: string;
    owner_id: string;
    date_begin: string;
    date_end: string;
    // dataDiscount: any;
}

interface OtherProps
    extends RouteComponentProps<{
        id: string
    }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const CateDiscountView: React.FC<Props> = props => {
    const id = props.match.params.id;
    const {
        code,
        name,
        type,
        belong_to,
        owner_id,
        date_begin,
        date_end,
    } = props.values;
    const [dataDiscount, setDataDiscount] = useState({
        data: []
    })
    const [checkType, setCheckType] = useState(true)
    const [agency, setAgency] = useState({})
    const getAgency = async belong_to => {
        const service = new CateDiscountService()
        const lst = await service.getAgencyContributor(belong_to)
        if (lst) {
            let agency = {}
            lst.data.map(value => (agency[value.id] = value.fullname))
            setAgency(agency)
        }
    }
    const onFetchData = async () => {
        const service = new CateDiscountService();
        const result = await service.findCode(id)
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        if(Number(result.data.type) === 1 ){
            setCheckType(true)
        }
        if(Number(result.data.type) === 2 ){
            setCheckType(false)
            getAgency(result.data.belong_to)
            //props.setFieldValue("owner_id", result.data.owner_id);
        }
        setDataDiscount({
            ...dataDiscount,
            data: result.dataDiscount
        });
        // setDataDismount(result.dataDismount)
    };
    const changeType = (e) => {
        props.setFieldValue("type",e)
    }
    const changeBelongTo = (e) => {
        props.setFieldValue("belong_to",e)
    }
    const changeOwnerId = (e) => {
        props.setFieldValue("owner_id", e);
    }
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Chi tiết chiết khấu</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="name"
                            label="Tên chiết khấu"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={name}
                            isRequired={true}
                            maxLength={255}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="code"
                            label="Mã chiết khấu"
                            wrapClass="col-md-3 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            isRequired={true}
                            isDisabled={true}
                            maxLength={255}
                        />
                        <SelectDateFormat
                            label="Ngày bắt đầu "
                            name="date_begin"
                            wrappedClass="col-md-3 form-group"
                            onChange={(value, dateString) => {
                                props.setFieldValue("date_begin", dateString);
                            }}
                            value={date_begin}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectDateFormat
                            label="Ngày kết thúc"
                            name="date_end"
                            wrappedClass="col-md-3 form-group"
                            onChange={(value, dateString) => {
                                props.setFieldValue("date_end", dateString);
                            }}
                            value={date_end}
                            isRequired={true}
                            isDisabled={true}
                        />
                        
                    </div>
                    <div className="input-group">
                        <SelectBox
                            name="type"
                            options={{
                                1: "Chiết khấu thông thường", 
                                2: "Chiết khấu đặt biệt"
                            }}
                            label="Loại chiết khấu"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn loại chiết khấu"
                            onChange={changeType}
                            value={type}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="belong_to"
                            options={{
                                1: "Đại lý", 
                                2: "Cộng tác viên"
                            }}
                            label="Loại người dùng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn loại người dùng"
                            onChange={changeBelongTo}
                            value={belong_to}
                            isRequired={false}
                            hidden={checkType}
                            isDisabled={true}
                        />
                        <SelectBox
                            name="owner_id"
                            options={agency}
                            label="Loại người dùng"
                            wrappedClass="col-md-3"
                            firstOptionLabel="Chọn loại người dùng"
                            onChange={changeOwnerId}
                            value={owner_id}
                            isRequired={false}
                            hidden={checkType}
                            isDisabled={true}
                        />
                    </div>
                    <div className="input-group col-md-12 m-t-20">
                        <div className="col-md-12 p-0">
                            <DiscountList
                                data={dataDiscount.data}
                            />

                        </div>
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            url="/danh-sach-chiet-khau"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderUpdateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        name: "",
        type: "",
        belong_to: "",
        owner_id: "",
        date_begin: "",
        date_end: "",
        dataDiscount:{}
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
    }
})(CateDiscountView);

export default withNavbar()(RenderUpdateForm);
