import $ from 'jquery';
$('body').on('keydown', 'input, select', function(e) {
    if (e.key === "Enter") {
        // var self = $(this), form = self.parents('form:eq(0)'), focusable, next;
        // focusable = form.find('input,a,select,button,textarea').filter(':visible');
        // next = focusable.eq(focusable.index(this)+1);
        // if (next.length) {
        //     next.focus();
        // } else {
        //     form.submit();
        // }
        // return false;
    }
});

$( document ).ready(function() {
    var listDiv = $( "#root" ).nextAll();
    listDiv.each(function( index ) {
        if($(this).prop("tagName") === 'DIV'){
            listDiv[index].setAttribute('hidden','hidden')
        }
        
      });
});