import React, { Component } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { match } from "react-router";
import { History, Location } from "history";
import UserCreateForm from "./CreateForm";


interface Props {
  history: History;
  location: Location;
  match: match;
}
interface State {

}

class CreateUser extends Component<Props, State> {
  state = {

  };

  onChangeInput = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    } as any);
  };

  onChangeFileHandler = (file) => {
  }
  render() {
    return (
      <div className="card">
        <h4 className="card-title1 ml-3">Thêm mới tài liệu</h4>
        <div className="card-body">
          <UserCreateForm history={this.props.history}></UserCreateForm>
        </div>
      </div>
    );
  }
}

const createUserWithNavbar = withNavbar()(CreateUser);

export default createUserWithNavbar;
