export enum StatusEnum {
    ACTIVE = "1",
    STOP = "2",
    DEACTIVE = "3"
  }
  
  export class Type {
    static readonly USERTYPE = {
      1: "Đại lý",
      2: "Cộng tác viên",
      3: "Nhân viên kinh doanh"
    };
    static readonly TYPE = {
      1: "Loại 1",
      2: "Loại 2",
      3: "Loại 3"
    };
    static readonly badgeClass = {
      4: "badge-primary",
      5: "badge-danger",
      6: "badge-success"
    };
    static readonly STATUS = {
      2: "Chờ kế toán duyệt",
      3: "Kế toán Từ chối",
      4: "Kế toán đã duyệt",
      5: "Chờ HTKD duyệt",
      6: "HTKD đã duyệt",
      7: "Đã xuất token"
    };
  }

  export const TYPE_RECEIVE = {
    1: "Tại NewCA",
    2: "Chuyển phát nhanh"
  };

  export const TYPE_PAY = {
    1: "Chuyển khoản",
    2: "Tiền mặt"
  };

  export const STATUS_LABEL_CLASS = {
    1: {
      label: "Nháp",
      class: ""
    },
    2: {
      label: "Chờ kế toán duyệt",
      class: "orange"
    },
    3: {
      label: "Kế toán Từ chối",
      class: "volcano"
    },
    4: {
      label: "Kế toán đã duyệt",
      class: "green"
    },
    5: {
      label: "Chờ HTKD duyệt",
      class: "orange"
    },
    6: {
      label: "HTKD từ chối",
      class: "red"
    },
    7: {
      label: "Đã xuất Token",
      class: "geekblue"
    },
  };

  export const STATUS_CREATE_ACCOUNT = {
    1: {
      label: "Chưa tạo tài khoản",
      class: "badge-secondary"
    },
    2: {
      label: "Đã tạo tài khoản",
      class: "badge-success"
    },
  };
  
  export const OWNER_LABEL_CLASS = {
    1: {
      label: "Đại lý",
      class: "cyan"
    },
    2: {
      label: "Cộng tác viên",
      class: "green"
    },
    3: {
      label: "NVKD",
      class: 'orange'
    }
  };
  export enum AgencyEnum {
    STATUS_WAITING_APPROVE = 2,
    STATUS_DRAFT = 1,
    DIGITAL_CONTRACT = 2,
  }
  
  export const AgencyRules = {
    code: [
      {
        required: true,
        message: "Mã đại lý không thể bỏ trống!"
      }
    ],
    tax_code: [
      {
        required: true,
        message: "Mã số thuế không thể bỏ trống!"
      }
    ],
    deputy: [{}]
  };
