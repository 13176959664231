import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {reloadPage} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import TokenManagerService from "../../../services/token-manager/TokenManagerService";
import {loading} from "../../common/loading/NewLoading";
import BaseServices from "../../../services/base/BaseServices";
import InputFileUpload from "../../common/form/InputFileUpload";
import {onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";

interface Props extends RouteComponentProps {
}

const TITLE = "Cấp quyền update cho USB token";

const WhAsset: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({
        version_id: "",
        type: ""
    });
    
    const onChangeDataSearchVersionId = (e) => {
        setDataSearch({...dataSearch, 'version_id': e});
    };

    const [versionTokenList, setVersionTokenList] = useState({});
    useEffect(() => {
        fetchVersionTokenOption();
        // eslint-disable-next-line
    }, []);

    const fetchVersionTokenOption = async () => {
        const service = new TokenManagerService();
        const result = await service.getVersionToken();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.code;
        });
        setVersionTokenList(options);
    };
    const [fileName, setFileName] = useState('');
    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            if (fileData) {
                setFileName(fileData.file);
            }
            loading.stopRunLoading();
        } else {
            setFileName("");
        }
    };
    const [hiddenVersionToken, setHiddenVersionToken] = useState(true);
    const [hiddenUploadFile, setHiddenUploadFile] = useState(true);
    const [hiddenSubmit, sethiddenSubmit] = useState(true);
    const onChangeType = (e) => {
        setDataSearch({...dataSearch, 'type': e});
        if (Number(e) === 1) {
            setHiddenVersionToken(false);
            setHiddenUploadFile(true);
            sethiddenSubmit(false);
        }
        if (Number(e) === 2) {
            setHiddenVersionToken(true);
            setHiddenUploadFile(false);
            sethiddenSubmit(false);
        }
        if (e === '') {
            setHiddenVersionToken(true);
            setHiddenUploadFile(true);
            sethiddenSubmit(true);
        }
    };

    const onConfirm = async () => {
        const data = {"type":dataSearch.type, "version_id":dataSearch.version_id, "filename":fileName};
        if (data.type === "1" && !data.version_id){
            onFailAction("Bạn phải chọn một mã version token");
            return;
        }
        if (data.type === "2" && !data.filename){
            onFailAction("Bạn chưa tải file lên");
            return;
        }
        const service = new TokenManagerService();
        const result = await service.approveUpdate(data);
        if (result && result.status === 200) {
            onSuccessAction(
                "Cấp quyền thành công",
                reloadPage(props.location, props.history)
            );
        }else{
            onFailAction(result.message);
        }
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <div className="input-group">
                    <SelectBox
                        name="type"
                        options={{
                            1: "Cấp theo lô",
                            2: "Cấp theo mã token"
                        }}
                        label=""
                        wrappedClass="col-md-3"
                        firstOptionLabel="Loại cấp"
                        onChange={onChangeType}
                        value={dataSearch.type}
                    />
                    <SelectBox
                        name="version_id"
                        options={versionTokenList}
                        label=""
                        wrappedClass="col-md-3"
                        firstOptionLabel="Version token"
                        onChange={onChangeDataSearchVersionId}
                        value={dataSearch.version_id}
                        hidden={hiddenVersionToken}
                    />
                    <InputFileUpload
                        name="file"
                        classWrapped="col-md-3"
                        label=""
                        filename={fileName}
                        onChangeFileUpload={onChangeFile}
                        isRequired={false}
                        accept={".xlsx"}
                        error={""}
                        isHidden={hiddenUploadFile}
                        isNote={false}
                    />
                    <a href="/file-mau-update-token.xlsx" download hidden={hiddenUploadFile}><i
                        className="fas fa-download fa-lg"/> Tải file mẫu</a>
                    <div className="col-md-2 form-group">
                        <a href="#/" onClick={onConfirm} className="btn btn-success mr-1 ml-1 font-btn"
                           hidden={hiddenSubmit}>Xác nhận</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withNavbar()(WhAsset);
