import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/yeu-cau-thay-doi-thong-tin";
class RequestCertificate extends FetchAPI {
    public async list(data) {
        return await this.request(
            `${PREFIX_URL}/index`,
            data,
            TypeMethodContsantEnum.GET
        );
    }

    public async getListSaleUser() {
        return await this.request(
            `${PREFIX_URL}/getListSaleUser`,
            {},
            TypeMethodContsantEnum.GET
        );
    }

    public async findAgency(id) {
        return await this.request(
            `${PREFIX_URL}/findAgency/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
    }

    public async findContributor(id) {
        return await this.request(
            `${PREFIX_URL}/findContributor/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
    }

    public async disPlayPdfFile(file) {
        return await this.request(
            `${PREFIX_URL}/disPlayPdfFile`,
            {file},
            TypeMethodContsantEnum.GET
        );
    }

    public async getDeny(data) {
        return await this.request(
            `${PREFIX_URL}/getDeny`,
            data,
            TypeMethodContsantEnum.GET
        );
    }

    public async getApprove(id) {
        return await this.request(
            `${PREFIX_URL}/getApprove/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
    }
}

export default RequestCertificate;
