import CompensationRequest from "../../pages/compensation-request/CompensationRequest";
import CompensationRequestDetail from "../../pages/compensation-request/CompensationRequestDetail";

export const COMPENSATION_REQUEST_ROUTE = [
    {
        component: CompensationRequest,
        link: "/yeu-cau-cap-bu",
        isExact: false
    },
    {
        component: CompensationRequestDetail,
        link: "/yeu-cau-cap-bu/chi-tiet/:id",
        isExact: false
    },
];

