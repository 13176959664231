import React, { Component } from "react";
import { NAVLINK_CONSTANT } from "../routes/RoutersList";
import { Link } from "react-router-dom";
import _ from "lodash";
import "./SideNav.css";
import Store from "../../store/Store";

interface Props {

}
interface State {
}

export default class SideNavPage extends Component<any, State> {
  state = {
  };

  SidebarCollapse = () => {
    // Collapse/Expand icon
    this.props.onToogleSide();
  }

  checkSelectedLink = link => {
    return _.startsWith(this.props.location.pathname, link)
      ? "selected-link"
      : "";
  };

  checkSubMenu = lstLink => {
    const pathName = this.props.location.pathname;
    return _.some(lstLink, function (tag) {
      return _.startsWith(pathName, tag.link);
    })
      ? " show"
      : "";
  };

  objectContains(obj, term: string): boolean {
    for (let key in obj) {
      if (obj[key].indexOf(term) !== -1) return true;
    }
    return false;
  }

  render() {
    const isExpand = this.props.isExpand;
    const user_role = Store.getState().authReducer.permission_list;

    return (
      <div className="row" id="body-row">
        <div
          id="sidebar-container"
          className={`d-none d-md-block ${isExpand ? 'sidebar-collapsed ' : 'sidebar-expanded'}`} hidden
        >
          <ul className="list-group">
            {NAVLINK_CONSTANT.map((route, index) => {
              if (route.childMenu.length > 0) {
                return (
                  <div key={index}>
                    <a
                      href={`#submenu${index}`}
                      data-toggle="collapse"
                      aria-expanded="false"
                      className="bg-light list-group-item list-group-item-action flex-column align-items-start"
                    >
                      <div className="d-flex w-100 justify-content-start align-items-center">
                        <span className={`${route.icon} fa-fw mr-3`} />
                        <span className={`menu-collapsed ${isExpand ? 'd-none' : ''}`}>
                          {route.displayName}
                        </span>
                        <span className={`fas fa-caret-down ${isExpand ? 'd-none' : ''} ml-auto`} />
                      </div>
                    </a>
                    <div
                      id={`submenu${index}`}
                      className={
                        `collapse sidebar-submenu ${isExpand ? 'd-none' : ''}` +
                        this.checkSubMenu(route.childMenu)
                      }
                    >
                      {route.childMenu.map((childRoute, childIndex) => {

                        if (this.objectContains(user_role, childRoute.permission)) {
                          return (
                            <Link
                              key={childIndex}
                              to={childRoute.link}
                              className={
                                this.checkSelectedLink(childRoute.link) +
                                " list-group-item list-group-item-action bg-light"
                              }
                            >
                              <span
                                className={`${childRoute.childIcon} fa-fw mr-3`}
                              />
                              <span className={`menu-collapsed ${isExpand ? 'd-none' : ''}`}>
                                {childRoute.displayName}
                              </span>
                            </Link>
                          );
                        }
                        return true;
                      })}
                    </div>
                  </div>
                );
              } else {
                return (
                  <Link
                    key={index}
                    to={route.link}
                    className={`${this.checkSelectedLink(
                      route.link
                    )} bg-light list-group-item list-group-item-action`}
                  >
                    <div className="d-flex w-100 justify-content-start align-items-center">
                      <span className={`${route.icon} fa-fw mr-3`} />
                      <span className={`menu-collapsed ${isExpand ? 'd-none' : ''}`}>{route.displayName}</span>
                    </div>
                  </Link>
                );
              }
            })}
            {/* eslint-disable-next-line  */}
            <a
              data-toggle="sidebar-colapse"
              className="bg-light list-group-item list-group-item-action d-flex align-items-center pointer"
              onClick={this.SidebarCollapse}
            >
              <div className="d-flex w-100 justify-content-center align-items-center">
                <span
                  id="collapse-icon"
                  className={`fa fa-2x fa-angle-double-${isExpand ? 'right' : 'left'}`}
                />
              </div>
            </a>
          </ul>
        </div>
        <div className="col pt-3 main-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}
