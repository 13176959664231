import React, {useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON, reloadPage} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import {onDeleteAlert, onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import RoleService from "../../../services/role/RoleServices";
import { TypeRole } from "./Enum";
import { Tag } from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Danh sách vai trò ";

const AssetCategory: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({type: "", text_search: ""});

    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchType = (e) => {
        setDataSearch({...dataSearch, "type": e});
    }

    const fetchDataFunction = async () => {
        const service = new RoleService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.getRoles(queryString);
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const onClickDeleteButton = id => {
        onDeleteAlert(async willDelete => {
            if (willDelete) {
                const service = new RoleService();
                const result = await service.deleteRoleById(id);
                if (result && result.status === 200) {
                    onSuccessAction(
                        "Xóa bản ghi tài sản thành công!",
                        reloadPage(props.location, props.history)
                    );
                }
            }
        });
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={() =>
                    props.history.push(`/quan-ly-vai-tro/cap-nhat/${record.id}`)
                }
                onClickDelete={() => onClickDeleteButton(record.id)}
                isDisplayUpdateButton= {true}
            />
        );
    };

    const onRenderTypeColumn = (type) => {
        return (
          <Tag color={`${TypeRole.badgeClass[type]}`}>
            {TypeRole.TYPE[type]}
          </Tag>
        );
      }

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="text_search"
                            wrapClass="col-md-4 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.text_search}
                            isRequired={false}
                            label={""}
                            placeholder={"Tên vai trò"}
                        />
                        <SelectBox
                            name="type"
                            options={{
                                1: "Admin",
                                2: "QLBH"
                            }}
                            label=""
                            wrappedClass="col-md-2"
                            firstOptionLabel="Tất cả loại vai trò"
                            onChange={onChangeDataSearchType}
                            value={dataSearch.type}
                        />
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Tên vai trò", dataIndex: "name"},
                        {title: "Tên hiển thị", dataIndex: "display_name"},
                        {
                            title: "Loại vai trò",
                            dataIndex: "type",
                            render: onRenderTypeColumn
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },

                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(AssetCategory);
