import React, { Component } from "react";
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import "../css/main.css";
import { Radio } from 'antd';

interface Props {
    options: any;
    label: string;
    name: string;
    wrappedClass: string;
    onChange: any;
    value: any;
    error?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
}
interface State { }

export default class RadioButton extends Component<Props, State> {

    render() {
        const { options } = this.props;
        return (
            <div className={this.props.wrappedClass}>

                {(this.props.label) ?
                    <LabelInput
                        nameFor={this.props.name}
                        label={this.props.label}
                        isRequired={this.props.isRequired}
                    />
                    : ''}

                <Radio.Group className="mt-1" onChange={this.props.onChange} name={this.props.name} value={this.props.value+''} disabled={this.props.isDisabled}>
                    {Object.keys(options).map((key, index) => (
                        <Radio value={key} key={key}>{options[key]}</Radio>
                    ))}
                </Radio.Group>
                <ValidateMessageError error={this.props.error}/>
            </div>
        );
    }
}
