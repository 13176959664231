import React, { useEffect, useState } from "react";
import {Form} from "antd";
import { FormComponentProps } from "antd/lib/form";
import InputWithLabel from "../../../common/form/label/InputWithLabel";
import SelectWithLabel from "../../../common/form/SelectWithLabel";
import _ from "lodash";
import SelectDateSearch from './../../../common/form/SelectDateSearch';
import { GenCertServices } from './../../../../services/gen-cert/GenCertServices';
import SearchButtonV2 from "../../../common/button/SearchButtonV2";

interface Props extends FormComponentProps {}
const GenCertSearchForm: React.FC<Props> = props => {
    const [cateServicePackage, setCateServicePackage] = useState({});
    const fetchCateServicePackage = async () => {
        const data = await GenCertServices.getListCateServicePackage();
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
    };
    useEffect(() => {
        fetchCateServicePackage();
        // eslint-disable-next-line
    },[]);

    return (
        <Form>
            <div className="input-group">
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="ma-dinh-danh"
                    placeholder={"Mã định danh"}
                    wrapClass="col-md-2 nopadding-left"
                />
                <InputWithLabel
                    form={props.form}
                    label=""
                    name="secret_code"
                    placeholder={"Mã bảo mật"}
                    wrapClass="col-md-2"
                />
                <SelectWithLabel
                    options={cateServicePackage}
                    name="package_id_search"
                    wrappedClass="col-md-2"
                    form={props.form}
                    placeholder="Gói dịch vụ"
                    firstOptionLabel={"Chọn gói dịch vụ"}
                />

                <SelectDateSearch
                    name="created_at_search"
                    form={props.form}
                    wrapClass="col-md-2"
                    label=""
                />
                <div className="form-group col-md-2 mt-1">
                    <SearchButtonV2 data={props.form.getFieldsValue()} />
                </div>
            </div>
        </Form>
    );
};

const WrappedGenCertSearchForm = Form.create<Props>({
    name: "GenCertSearchForm"
})(GenCertSearchForm);

export default WrappedGenCertSearchForm;
