import React, {Component} from "react";
import {InjectedFormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import PreviewButton from "../../../common/button/PreviewButton";
import SubmitButton from "../../../common/form/SubmitButton";
import DenyButton from "../../../common/button/DenyButton";
import {History} from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import WrapperButtonInput from "../../../common/form/wrapper-button-form/wrapperButtonInput";
import ModalView from "../../../common/form/Modal";
import ModalDeny from "../../../common/form/ModalDeny";
import {onFailAction, onSuccessAction} from "../../../common/swal/SwalCommon";
import ContributorService from "../../../../services/contributor/ContributorService";
import {CONTRACT_TYPE_OPTIONS, LIST_COOPERATE} from "../ContributorEnum";
import CheckBoxGroup from "../../../common/form/CheckBoxGroup";
import {checkPermission} from "../../../../helpers/NewCaHelper"

const per_contributor_handle_admin = 'contributor-handle-admin'

interface Props {
    id: string;
    history: History;
}

interface State {
    code: string;
    fullname: string;
    sortname: string;
    birthday: string;
    phone: string;
    email: string;
    address: string;
    job: string;
    passport: string;
    passport_date: string;
    passport_place: string;
    cooperate_capacity: string;
    competitive_area: string;
    supply_capacity: string;
    relation_business: string;
    is_cooperate: string;
    is_want_cooperate: string;
    bank_number: string;
    bank_branch: string;
    status: number;
    isdeleted: string;
    sale_id: string;
    type: string;
    discount_rate: string;
    contract_date: string;
    contract_code: string;
    data_contact: any;
    visible_modal: boolean;
    visible_modal_deny: boolean;
    loading: boolean;
    title_modal: string;
    dataSearchDocument: any;
    base64: string;
    type_file: string;
    product: any,
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
    service = new ContributorService();
    state = {
        dataSearchDocument: {
            id: this.props.id,
            type: ""
        },
        visible_modal: false,
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: "",
        list_cooperate: LIST_COOPERATE
    };

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const data = await this.service.getContributor(this.props.id);
        if (data && data.status === 200) {
            const {
                code,
                fullname,
                sortname,
                birthday,
                phone,
                email,
                address,
                job,
                passport,
                passport_date,
                passport_place,
                cooperate_capacity,
                competitive_area,
                supply_capacity,
                relation_business,
                is_cooperate,
                is_want_cooperate,
                bank_number,
                bank_branch,
                status,
                isdeleted,
                sale_id,
                type,
                contract_date,
                contract_code,
                discount_rate,
                product
            } = data.data;
            this.props.setValues({
                code,
                fullname,
                sortname,
                birthday,
                phone,
                email,
                address,
                job,
                passport,
                passport_date,
                passport_place,
                cooperate_capacity,
                competitive_area,
                supply_capacity,
                relation_business,
                is_cooperate,
                is_want_cooperate,
                bank_number,
                bank_branch,
                status,
                isdeleted,
                sale_id,
                type,
                contract_date,
                contract_code,
                discount_rate,
                product
            } as Pick<State, keyof State>);
        }
    };

    onClickModalFile = (type, tittle) => {
        const dataSearchDocument = {...this.state.dataSearchDocument, type};
        this.fetchDataFunction(dataSearchDocument);

        this.setState({visible_modal: true, title_modal: tittle});
    };

    fetchDataFunction = async data => {
        const result = await this.service.getContributorDocumentFile(data);
        if (result && result.status === 200) {
            this.setState({loading: true});
            this.setState({base64: result.data});
            this.setState({type_file: result.type_file});
            return result;
        } else {
            this.setState({base64: ""});
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra khi lấy file");
    };

    handleModalCancel = () => {
        this.setState({
            visible_modal: false
        });
    };

    submitApprove = async () => {
        const value = this.props.values;
        const self = this;
        const result = await this.service.getContributorApprove({
            ...value,
            id: this.props.id
        });
        if (result && result.status === 200) {
            onSuccessAction("Duyệt và ký cộng tác viên thành công!", () => {
                self.props.history.push("/cong-tac-vien");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt và ký!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt và ký!");
        }
    };

    handleModalDenyCancel = () => {
        this.setState({
            visible_modal_deny: false
        });
    };
    onChangeDataDeny = ({target: {value}}) => {
        this.setState({text_deny: value});
    };

    submitDeny = () => {
        this.setState({visible_modal_deny: true});
    };

    confirmDeny = async () => {
        const value = this.props.values;
        if (this.state.text_deny.replace(/\s+/g, '') === "") {
            this.setState({error_text_deny: "Lý do từ chối không được trống"});
            return false;
        }
        const self = this;
        const result = await this.service.getContributorDeny({
            ...value,
            id: this.props.id,
            text_deny: this.state.text_deny
        });
        await this.setState({visible_modal_deny: false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối cộng tác viên thành công!", () => {
                self.props.history.push("/cong-tac-vien");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.errors);
        }
    };

    render() {
        const {
            code,
            fullname,
            sortname,
            birthday,
            phone,
            email,
            address,
            job,
            passport,
            passport_date,
            passport_place,
            cooperate_capacity,
            competitive_area,
            supply_capacity,
            is_cooperate,
            discount_rate,
            contract_date,
            contract_code,
            product
        } = this.props.values;

        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="input-group">
                    <InputWithLabel
                        name="fullname"
                        label="Tên cộng tác viên"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={fullname}
                        error={this.props.errors.fullname}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="sortname"
                        label="Tên viết tắt"
                        wrapClass="col-md-1"
                        onChangeValue={this.props.handleChange}
                        value={sortname}
                        error={this.props.errors.sortname}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="code"
                        label="Mã cộng tác viên"
                        wrapClass="col-md-2"
                        onChangeValue={this.props.handleChange}
                        value={code}
                        error={this.props.errors.code}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="birthday"
                        label="Ngày sinh"
                        wrapClass="col-md-2"
                        onChangeValue={this.props.handleChange}
                        value={birthday}
                        error={this.props.errors.birthday}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="address"
                        label="Địa chỉ"
                        wrapClass="col-md-4"
                        onChangeValue={this.props.handleChange}
                        value={address}
                        error={this.props.errors.address}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        name="email"
                        label="Email"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={email}
                        error={this.props.errors.email}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="phone"
                        label="Số điện thoại"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={phone}
                        error={this.props.errors.phone}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="cooperate_capacity"
                        label="Khả năng hợp tác"
                        wrapClass="col-md-2"
                        onChangeValue={this.props.handleChange}
                        value={cooperate_capacity}
                        error={this.props.errors.cooperate_capacity}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="job"
                        label="Ngành nghề kinh doanh chính"
                        wrapClass="col-md-4"
                        onChangeValue={this.props.handleChange}
                        value={job}
                        error={this.props.errors.job}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        name="passport"
                        label="Chứng minh thư"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={passport}
                        error={this.props.errors.passport}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="passport_place"
                        label="Nơi cấp"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={passport_place}
                        error={this.props.errors.passport_place}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="passport_date"
                        label="Ngày cấp"
                        wrapClass="col-md-2"
                        onChangeValue={this.props.handleChange}
                        value={passport_date}
                        error={this.props.errors.passport_date}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <div className="col-md-4">
                        <WrapperButtonInput>
                            <PreviewButton
                                name={"File CMT"}
                                history={this.props.history}
                                onClick={() => {
                                    this.onClickModalFile("5", "File CMT");
                                }}
                            ></PreviewButton>
                        </WrapperButtonInput>
                    </div>
                </div>

                <div className="input-group">
                    <InputWithLabel
                        name="supply_capacity"
                        label="Năng lực cung cấp CKS/tháng"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={supply_capacity}
                        error={this.props.errors.supply_capacity}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="discount_rate"
                        label="Phần trăm chiết khấu"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={discount_rate}
                        error={this.props.errors.discount_rate}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="competitive_area"
                        label="Tình hình thị trường, khu vực"
                        wrapClass="col-md-6"
                        onChangeValue={this.props.handleChange}
                        value={competitive_area}
                        error={this.props.errors.competitive_area}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        label="Đã hợp tác với nhà cung cấp nào khác"
                        name="is_cooperate"
                        onChangeValue={this.props.handleChange}
                        wrapClass="col-md-6"
                        isDisabled={true}
                        value={is_cooperate}
                    />
                    <CheckBoxGroup
                        label="Cung cấp sản phẩm, dịch vụ cho NewCA"
                        wrappedClass="col-md-6 check-box-group"
                        onChange={this.props.handleChange}
                        name="product"
                        defaultValue={product}
                        options={[
                            {title: 'Dịch vụ chữ ký số', value: "1"},
                            {title: 'Phần mềm hóa đơn điện tử', value: "2"},
                            {title: 'Phần mềm kê khai bảo hiểm xã hội (IVAN)', value: "3"},
                        ]}

                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        name="type"
                        label="Loại hợp đồng"
                        wrapClass="col-md-3 "
                        onChangeValue={this.props.handleChange}
                        value={CONTRACT_TYPE_OPTIONS[this.props.values.type]}
                        error={this.props.errors.type}
                        isRequired={true}
                        isDisabled={true}
                    />

                    <WrapperButtonInput name={"col-md-3"}>
                        <PreviewButton
                            name={"Hợp đồng"}
                            history={this.props.history}
                            onClick={() => {
                                this.onClickModalFile("2", "Hợp đồng");
                            }}
                        />
                        {Number(this.props.values.type) === 2 ? (
                            <PreviewButton
                                name={"Tờ trình"}
                                history={this.props.history}
                                onClick={() => {
                                    this.onClickModalFile("1", "Tờ trình");
                                }}
                            />
                        ) : ("")}
                    </WrapperButtonInput>

                    <InputWithLabel
                        name="contract_date"
                        label="Ngày hợp đồng"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={contract_date}
                        error={this.props.errors.contract_date}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="contract_code"
                        label="Số hợp đồng"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={contract_code}
                        error={this.props.errors.contract_code}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <WrapperButtonDiv>
                    <BackButton history={this.props.history} url="/cong-tac-vien"/>
                    {([5, 6].indexOf(this.props.values.status) > -1 || !checkPermission(per_contributor_handle_admin))
                        ? ""
                        : <DenyButton onClick={this.submitDeny}/>
                    }
                    {([5, 6].indexOf(this.props.values.status) > -1 || !checkPermission(per_contributor_handle_admin))
                        ? ""
                        : <SubmitButton
                            onClick={this.submitApprove}
                            text={" Duyệt và ký"}
                            iconClass={"fa fa-check-circle"}/>
                    }
                </WrapperButtonDiv>
                <ModalView
                    visible={this.state.visible_modal}
                    handleCancel={this.handleModalCancel}
                    title={this.state.title_modal}
                    value={this.state.base64}
                    loading={this.state.loading}
                    type_file={this.state.type_file}
                />
                <ModalDeny
                    visible={this.state.visible_modal_deny}
                    handleCancel={this.handleModalDenyCancel}
                    handleDeny={this.confirmDeny}
                    value={this.state.text_deny}
                    onChange={this.onChangeDataDeny}
                    error={this.state.error_text_deny}
                />
            </form>
        );
    }
}

const userUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        data_contact: {},
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        dataSearchDocument: {},
        visible_modal_deny: false,
        code: "",
        fullname: "",
        sortname: "",
        birthday: "",
        phone: "",
        email: "",
        address: "",
        job: "",
        passport: "",
        passport_date: "",
        passport_place: "",
        cooperate_capacity: "",
        competitive_area: "",
        supply_capacity: "",
        contract_date: "",
        contract_code: "",
        relation_business: "",
        is_cooperate: "",
        is_want_cooperate: "",
        bank_number: "",
        bank_branch: "",
        status: 0,
        isdeleted: "",
        sale_id: "",
        type: "",
        discount_rate: "",
        type_file: "",
        product: [],
    }),
    validationSchema: Yup.object().shape({}),

    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
    }
})(UpdateForm);

export default userUpdateForm;
