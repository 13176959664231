import FetchAPI from "../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../helpers/constant/MethodAPIConstant";

class VersionTokenServices extends FetchAPI {
  public async list(data) {
    const result = await this.request(
      "cate/danh-muc-version-token",
      data,
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async find(id) {
    const result = await this.request(
      `cate/danh-muc-version-token/find/${id}`,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async store(data) {
    const result = await this.request(
      "cate/danh-muc-version-token/store",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

  public async update(data) {
    const result = await this.request(
      "cate/danh-muc-version-token/update",
      data,
      TypeMethodContsantEnum.PATCH
    );
    return result;
  }

  public async delete(id) {
    const result = await this.request(
      "cate/danh-muc-version-token/delete",
      { id },
      TypeMethodContsantEnum.DELETE
    );
    return result;
  }

  public async all() {
    const result = await this.request(
      "cate/danh-muc-version-token/all",
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }
}

export default VersionTokenServices;
