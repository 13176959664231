import React, { Component } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { History, Location } from "history";
import { match } from "react-router";
import SearchButton from "../../common/button/SearchButton";
import Helmet from "react-helmet";
import { Type } from "./Enum";
import SelectBox from "../../common/form/SelectBox";
import UserService from "../../../services/user/UserService";
import DataTable from "./table/Table";
import SelectBoxAgency from "../../common/form/SelectBoxAgency";
import SelectUserManager from "../../common/form/SelectUserManager";

interface Props {
  history: History;
  location: Location;
  match: match;
  name: string;
  userManager: object;
  user: any;
}
interface State {
  dataSearch: any;
  agency: Object;
}
const TITLE = "Danh sách yêu cầu tài liệu bán hàng";
class PageIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      text_search: "",
      status: "",
      owner_id: "",
      user_id: "",
      belong_to: "",
    },
    userManager: {},
    agency: {}
  };
  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };

  onChangeDataSearchStatus = (e) => {
    const dataSearch = { ...this.state.dataSearch, "status": e };
    this.setState({ dataSearch });
  };

  onChangeDataSearchUserId = (e) => {
    const dataSearch = { ...this.state.dataSearch, "user_id": e };
    this.setState({ dataSearch });
  }

  handleChangeBoxAgency = e => {
    const dataSearch = { ...this.state.dataSearch, owner_id: e };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = data => {
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  };

  componentDidMount() {
    this.getUserCreated('5,7,8');
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch: queryString
    });
  }

  onChangeSelectedRole = (e) => {
    const dataSearch = { ...this.state.dataSearch, "belong_to": e };
    this.setState({ dataSearch });
    let typeGet = 7;
    if(e === '2'){
      typeGet = 8;
    }
    if(e === '3'){
      typeGet = 5;
    }
    this.getAgency(typeGet);
  };

  getUserCreated = async e => {
    const service = new UserService();
    const result = await service.getUserByType(e);
    if (result && result.status === 200) {
      this.setState({
        userManager: result.data
      } as any);
    }
  };

  //Lấy dữ liệu cộng tác viên hoặc đại lý
  getAgency = async type => {
    const userService = new UserService();
    const lst = await userService.getAgencyContributor(type);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      this.setState({ agency });
    }
  };

  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="card-body">
          <h4 className="card-title1">{TITLE}</h4>
          <form
            onSubmit={this.onSubmitSearchButton}
            className="search-form-index"
          >
            <div className="input-group">
              <SelectBox
                options={Type.USERTYPE}
                label=""
                name="belong_to"
                wrappedClass="col-md-2 nopadding-left"
                onChange={this.onChangeSelectedRole}
                value={this.state.dataSearch.belong_to}
                firstOptionLabel="Chọn loại người dùng"
                isRequired={true}
              />
              <SelectBoxAgency
                options={this.state.agency}
                label=""
                name="owner_id"
                wrappedClass="col-md-3 form-group nopadding-left"
                onChange={this.handleChangeBoxAgency}
                value={this.state.dataSearch.owner_id}
                firstOptionLabel="Đại lý/CTV/NVKD"
                isRequired={false}
              />
              <SelectBox
                value={this.state.dataSearch.status}
                label=""
                onChange={this.onChangeDataSearchStatus}
                name="status"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={Type.STATUS}
                firstOptionLabel="Tất cả trạng thái"
              />
              <SelectUserManager
                label=""
                onChange={this.onChangeDataSearchUserId}
                value={this.state.dataSearch.user_id}
                name="user_id"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={this.state.userManager}
                firstOptionLabel="Người yêu cầu"
              />
              <div className="col-md-3 form-group nopadding-left">
                <SearchButton onClick={this.onSubmitSearchButton} />
              </div>
            </div>
          </form>
          <DataTable
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(PageIndex);

export default pageWithNavBar;
