export const STATUS_LABEL_CLASS = {
    1: {
      label: "Chờ kết luận",
      class: ""
    },
    2: {
      label: "Chờ đại lý/ cộng tác viên duyệt",
      class: "orange"
    },
    3: {
      label: "Đại lý/ cộng tác viên từ chối",
      class: "magenta"
    },
    4: {
      label: "Đại lý cộng tác viên đã duyệt",
      class: "purple"
    },
    5: {
      label: "Newca đã duyệt",
      class: "geekblue"
    },
    6: {
      label: "Hoàn thành",
      class: "green"
    }
};
export const BELONG_TO_LABEL_CLASS = {
  1: {
    label: "Đại lý",
    class: "cyan"
  },
  2: {
    label: "Cộng tác viên",
    class: "green"
  },
  3: {
    label: "Nhân viên kinh doanh",
    class: "geekblue"
  }
};