import FetchAPI from "../../helpers/FetchAPI";
import { TypeMethodContsantEnum } from "../../helpers/constant/MethodAPIConstant";

class AssetCategoryService extends FetchAPI {
  public async list(data) {
    const result = await this.request(
      "cate/danh-muc-tai-san",
      data,
      TypeMethodContsantEnum.GET
    );
    return result;
  }

    public async all(type) {
        const result = await this.request(
            "cate/danh-muc-tai-san/all?type="+type,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

  public async find(id) {
    const result = await this.request(
      `cate/danh-muc-tai-san/find/${id}`,
      {},
      TypeMethodContsantEnum.GET
    );
    return result;
  }

  public async store(data) {
    const result = await this.request(
      "cate/danh-muc-tai-san/store",
      data,
      TypeMethodContsantEnum.POST
    );
    return result;
  }

  public async update(data) {
    const result = await this.request(
      "cate/danh-muc-tai-san/update",
      data,
      TypeMethodContsantEnum.PATCH
    );
    return result;
  }

  public async delete(id) {
    const result = await this.request(
      "cate/danh-muc-tai-san/delete",
      { id },
      TypeMethodContsantEnum.DELETE
    );
    return result;
  }
}

export default AssetCategoryService;
