import React, { Component } from "react";
import Routers from "./components/routes/Routers";
import ErrorHandle from "./helpers/ErrorHandle";
import SideNavContext from "./components/side-nav/SideNavContext";
import BackTop from "./components/common/button/BackTop";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";
import UserService from "./services/user/UserService";
import { BehaviorSubject } from "rxjs";
import { authenticationService } from "./services/AuthenticationService";
import { connect } from "react-redux";
import Store from "./store/Store";
import { setUserDataAction } from "./actions/AuthActions";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser")!)
);

class App extends Component<any, any> {
  state = {
    isExpandSiveNav: false,
    i: 1
  };
  get currentUserValue() {
    return currentUserSubject.value;
  }

  componentDidMount() {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser) {
      this.getUser();
    }
  }

  onToogleSideNav = () => {
    this.setState({
      isExpandSiveNav: !this.state.isExpandSiveNav
    });
  };

  getUser = async () => {
    if (this.currentUserValue !== null) {
      const user = new UserService();
      const result = await user.currentUser();
      Store.dispatch(setUserDataAction(result.data));
    }
  };
  render() {
    const { isExpandSiveNav } = this.state;
    return (
      <BrowserRouter>
        <ErrorHandle>
          <SideNavContext.Provider
            value={{
              isExpand: isExpandSiveNav,
              toogleExpand: this.onToogleSideNav
            }}
          >
            <Routers />
            <BackTop></BackTop>
          </SideNavContext.Provider>
        </ErrorHandle>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  const { authReducer } = state;
  return { authData: authReducer };
}
export default connect(mapStateToProps)(App);
