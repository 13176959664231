import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import DenyButton from "../../../common/button/DenyButton";
import { History } from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import ModalDeny from "../../../common/form/ModalDeny";
import { onFailAction, onSuccessAction } from "../../../common/swal/SwalCommon";
import RequestTrainingService from "../../../../services/request-training/RequestTrainingService";
import TextAreaWithLabel from "../../../common/form/TextAreaWithLabel";
import {formatDateTime, checkPermission} from "../../../../helpers/NewCaHelper";

interface Props {
    id: string;
    history: History;
}
interface State {
    owner_agency: any;
    owner_contributor: any;
    belong_to: string;
    content_train: string;
    training_date: string;
    personal: string;
    location: string;
    note: string;
    id: string;
    owner_id: string;
    status: string;
    reason_refuse: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
    service = new RequestTrainingService();
    state = {
        dataSearchDocument: {
            id: this.props.id,
            type: ""
        },
        visible_modal: false,
        visible_modal_deny: false,
        loading: false,
        title_modal: '',
        text_deny: '',
        error_text_deny: '',
    };
    componentDidMount() {
        this.getData();
    }
    
    getData = async () => {
        const data = await this.service.getModel(this.props.id);
        if (data && data.status === 200) {
            const {
                content_train, training_date, personal, location, note, id, owner_id,owner_agency,owner_contributor,belong_to,status,reason_refuse
            } = data.data;
            this.props.setValues({
                content_train, training_date, personal, location, note, id, owner_id,owner_agency,owner_contributor,belong_to,status,reason_refuse
            } as Pick<State, keyof State>);

        }
    };

    submitApprove = async () => {
        const self = this;
        const result = await this.service.approveRequest(this.props.id);
        if (result && result.status === 200) {
            onSuccessAction("Duyệt yêu cầu đào tạo thành công!", () => {
                self.props.history.push("/danh-sach-yeu-cau-dao-tao");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        }
    }

    handleModalDenyCancel = () => {
        this.setState({
            visible_modal_deny: false,
        });
    };
    onChangeDataDeny = ({ target: { value } }) => {
        this.setState({ text_deny: value });
    };

    submitDeny = () => {
        this.setState({ visible_modal_deny: true });
    }

    confirmDeny = async () => {
        const value = this.props.values;
        if (this.state.text_deny.trim() === '') {
            this.setState({ error_text_deny: 'Lý do từ chối không được trống' });
            return false;
        }
        const self = this;
        const result = await this.service.denyRequest({ ...value, id: this.props.id, text_deny: this.state.text_deny });
        await this.setState({ visible_modal_deny: false });
        if (result && result.status === 200) {
            onSuccessAction("Từ chối yêu cầu đào tạo!", () => {
                self.props.history.push("/danh-sach-yeu-cau-dao-tao");
            });

        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.errors);
        }
    }
    render() {
        const {
            content_train, training_date, personal, location, note,owner_agency,owner_contributor,belong_to,status,reason_refuse
        } = this.props.values;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <div className="input-group" hidden={parseInt(status)!==5}>
                    <TextAreaWithLabel
                        name={"reason_refuse"}
                        label={"Lý do từ chối"}
                        wrapClass={"col-md-12"}
                        onChangeValue={this.props.handleChange}
                        value={reason_refuse}
                        rows={2}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        name="fullname"
                        label="Người yêu cầu"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={parseInt(belong_to) === 1 ? owner_agency.fullname : owner_contributor.fullname}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="tax_code"
                        label="Mã số thuế"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={parseInt(belong_to) === 1 ? owner_agency.tax_code : owner_contributor.passport}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="content_train"
                        label="Nội dung"
                        wrapClass="col-md-6"
                        onChangeValue={this.props.handleChange}
                        value={content_train}
                        isRequired={true}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <InputWithLabel
                        name="training_date"
                        label="Thời gian đào tạo"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={formatDateTime(training_date)}
                        isRequired={true}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="personal"
                        label="Nhân sự đào tạo"
                        wrapClass="col-md-3"
                        onChangeValue={this.props.handleChange}
                        value={personal}
                        isDisabled={true}
                    />
                    <InputWithLabel
                        name="location"
                        label="Địa điểm"
                        wrapClass="col-md-6"
                        onChangeValue={this.props.handleChange}
                        value={location}
                        isDisabled={true}
                    />
                </div>
                <div className="input-group">
                    <TextAreaWithLabel
                        name={"note"}
                        label={"Ghi chú"}
                        wrapClass={"col-md-12"}
                        onChangeValue={this.props.handleChange}
                        value={note}
                        rows={2}
                        isDisabled={true}
                    />
                </div>
                    <WrapperButtonDiv>
                        <BackButton
                            history={this.props.history}
                            // url='/danh-sach-yeu-cau-dao-tao'
                        ></BackButton>
                        {(parseInt(status) === 4 && checkPermission('confirm-request-training-ad'))
                            ? <SubmitButton onClick={this.submitApprove} text={' Duyệt'} iconClass={'fa fa-check-circle'}></SubmitButton> : ''}
                        {(parseInt(status) === 4 && checkPermission('confirm-request-training-ad'))
                            ? <DenyButton onClick={this.submitDeny}></DenyButton> : ''}
                </WrapperButtonDiv>
                <ModalDeny visible={this.state.visible_modal_deny} handleCancel={this.handleModalDenyCancel} handleDeny={this.confirmDeny} value={this.state.text_deny} onChange={this.onChangeDataDeny} error={this.state.error_text_deny}></ModalDeny>
            </form>
        );
    }
}
const userUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        owner_agency: {},owner_contributor: {}, content_train: "", training_date: "", personal: "", location: "", note: "", id: "", owner_id:"",belong_to:"",status:"",reason_refuse:""
    }),
    validationSchema: Yup.object().shape({
    }),

    handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
        setSubmitting(false);
    },

})(UpdateForm);

export default userUpdateForm;
