import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import { TYPE_LABEL_CLASS } from "../Enum";
import { formatDateTime } from "../../../../helpers/NewCaHelper";
import ProvinceService from "../../../../services/province/ProvinceService";
import { onDeleteAlert, onSuccessAction } from "../../../common/swal/SwalCommon";
import {
    reloadPage
} from "../../../../helpers/NewCaHelper";

interface Props {
    location: Location;
    history: History;
    match: match;
}
interface State { }

export default class AgencyTable extends Component<Props, State> {
    state = {};
    service = new ProvinceService();
    componentDidMount() {
        this.fetch();
    }

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        try {
            const result = await this.service.getProvinces(params);
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    onClickDeleteButton = (id) => {
        const self = this;
        onDeleteAlert(async function (willDelete) {
            if (willDelete) {
                const result = await self.service.deleteProvinceById(id);
                if (result && result.status === 200) {
                    onSuccessAction('Xóa tỉnh thành thành công!', reloadPage(self.props.location, self.props.history));
                }
            }
        });
    }

    render() {
        return (
            <Table
                columns={this.columns}
                onFetchData={this.fetch}
            />
        );
    }

    onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickLock={() => this.onClickDeleteButton(record.id)}
                onClickUpdate={() =>
                    this.props.history.push(`/tinh-thanh/cap-nhat/${record.id}`)
                }
                isDisplayLockButton={false}
                isDisplayUpdateButton={true}
            />
        );
    };

    formatValid = (text) => {
        return (
            <span key={text} className={`badge ${TYPE_LABEL_CLASS[text].class}`}>
              {TYPE_LABEL_CLASS[text].label}
            </span>
        );
    };

    columns = [
        { title: "Tên tỉnh thành", dataIndex: "fullname" },
        { title: "Mã tỉnh thành", dataIndex: "code" },
        { title: "Ngày bắt đầu", dataIndex: "start_date" },
        { title: "Ngày kết thúc", dataIndex: "end_date" },
        {
            title: "Thời gian tạo",
            dataIndex: "created_at",
            render: (text) => formatDateTime(text)
        },
        {
            title: "Hiệu lực",
            dataIndex: "isvalid",
            render: (text) => this.formatValid(text)
        },
        {
            title: "Tác vụ",
            render: this.onRenderActionColumn
        }
    ];
}
