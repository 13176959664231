import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/yeu-cau-tai-lieu-ban-hang";
class RequestDocument extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            `${PREFIX_URL}/index`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async getListUser() {
        const result = await this.request(
            `${PREFIX_URL}/getListUser`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    
    public async find(id) {
        const result = await this.request(
            `${PREFIX_URL}/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    public async getApprove(id) {
        const result = await this.request(
            `${PREFIX_URL}/getApprove/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
    
    
    public async getDeny(data) {
        const result = await this.request(
            `${PREFIX_URL}/getDeny`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    
    public async getFile(id) {
        const result = await this.request(
            `${PREFIX_URL}/getFile/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    
}
export default RequestDocument;
