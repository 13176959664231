import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import { History } from "history";

import CustomerService from "../../../../services/customer/CustomerService";
import {Card} from "antd";

interface Props {
    id: string;
    history: History;
}

interface State {
    uid: string;
    code: string;
    fullname: string;
    email: string;
    phone: string;
    address: string;
    represent_fullname: string;
    represen_passport: string;
    represen_passport_place: string;
    represen_position: string;
    represen_phone: string;
    represen_email: string;
    name: string;
    created_by_name: string;
    created_by_type: string;
    created_by_agency_name: string;
    created_by_agency_sale: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
    service = new CustomerService();
    state = {
        dataSearchDocument: {
            id: this.props.id,
        }
    }
    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const data = await this.service.show(this.props.id,1);
        if (data && data.status === 200) {
            const {
                uid, code, fullname, email, phone, address, represent_fullname, represen_passport, represen_passport_place, represen_position, represen_phone, represen_email,created_by_name,created_by_type,created_by_agency_name,created_by_agency_sale
            } = data.data;

            const {name} = data.data.request.cateservicespackage
            this.props.setValues({
                uid, code, fullname, email, phone, address, represent_fullname, represen_passport, represen_passport_place, represen_position, represen_phone, represen_email,name,created_by_name,created_by_type,created_by_agency_name,created_by_agency_sale
            } as Pick<State, keyof State>);
        }
    };
    render() {
        const {
            uid, code, fullname, email, phone, address, represent_fullname, represen_passport, represen_passport_place, represen_position, represen_phone, represen_email,name,created_by_name,created_by_type,created_by_agency_name,created_by_agency_sale
        } = this.props.values;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin doanh nghiệp</label>} size="small">
                    {/*lines-1*/}
                    <div className="input-group m-t-10">
                        <InputWithLabel
                            name="uid"
                            label="Mã khách hàng"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={uid}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="code"
                            label="Mã định danh"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={code}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="fullname"
                            label="Tên khách hàng"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={fullname}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="type_request"
                            label="Loại khách hàng"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={"Tổ chức"}
                            isDisabled={true}
                        />
                    </div>
                    {/*lines-2*/}
                    <div className="input-group">
                        <InputWithLabel
                            name="email"
                            label="Email"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={email}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="phone"
                            label="Số điện thoại"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={phone}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="address"
                            label="Địa chỉ"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-6"
                            value={address}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin người đại diện</label>} size="small">
                    {/*lines-3*/}
                    <div className="input-group">
                        <InputWithLabel
                            name="represent_fullname"
                            label="Họ và tên"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={represent_fullname}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="represen_passport"
                            label="Số CMND/ Hộ chiếu"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={represen_passport}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="represen_passport_place"
                            label="Nơi cấp"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={represen_passport_place}
                            isDisabled={true}
                        />
                    </div>
                    {/*lines-4*/}
                    <div className="input-group">
                        <InputWithLabel
                            name="represen_position"
                            label="Chức vụ"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={represen_position}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="represen_phone"
                            label="Số điện thoại"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={represen_phone}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="represen_email"
                            label="Email"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={represen_email}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin khác</label>} size="small">
                    {/*lines-3*/}
                    <div className="input-group m-t-10">
                        <InputWithLabel
                            name="package_code"
                            label="Gói dịch vụ"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="created_by_name"
                            label="Người tạo"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={created_by_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="created_by_type"
                            label="Loại"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={created_by_type}
                            isDisabled={true}
                        />
                    </div>
                    {/*lines-4*/}
                    <div className="input-group">
                        <InputWithLabel
                            name="created_by_agency_name"
                            label="Đại lý"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-8"
                            value={created_by_agency_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="created_by_agency_sale"
                            label="Kinh doanh"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={created_by_agency_sale}
                            isDisabled={true}
                        />
                    </div>
                </Card>
            </form>
        );
    }
}
const userUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        uid: "",
        code: "",
        fullname: "",
        email: "",
        phone: "",
        address: "",
        represent_fullname: "",
        represen_passport: "",
        represen_passport_place: "",
        represen_position: "",
        represen_phone: "",
        represen_email: "",
        name: "",
        created_by_name: "",
        created_by_type: "",
        created_by_agency_name: "",
        created_by_agency_sale: ""
    }),
    validationSchema: Yup.object().shape({
    }),

    handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
        setSubmitting(false);
    },

})(UpdateForm);

export default userUpdateForm;
