import React, {useState} from "react";
import Helmet from "react-helmet";
import {convertJsonToQueryString, formatDateTime, queryStringToJSON} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import TableActionButton from "../user/table/action-button/TableActionButton";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import CompensationService from "../../../services/compensation/CompensationService";
import {Tag} from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Dữ liệu cấp bù";

const Compensation: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({uid: "", cert_serial: ""});
    const service = new CompensationService();
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            console.log(await service.list({...dataSearch,...queryString}))
            return await service.list({...dataSearch,...queryString});
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickUpdate={() =>
                    props.history.push(`/du-lieu-cap-bu/cap-nhat/${record.id}`)
                }
                isDisplayUpdateButton = {true}
            />
        );
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="uid"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.uid}
                            isRequired={false}
                            label={""}
                            placeholder={"MST/CMND/CCCD"}
                        />
                        <InputWithLabel
                            name="cert_serial"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.cert_serial}
                            isRequired={false}
                            label={""}
                            placeholder={"CERT SERIAL"}
                        />
                        <div className="col-md-3 form-group">
                            <SearchButton onClick={onSubmitSearchButton}/>
                            <GoToCreatePageButton toURL={`${props.match.url}/them-moi`}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {title: "Định danh", dataIndex: "uid"},
                        {title: "Tên khách hàng", dataIndex: "customer_name"},
                        {title: "Cert serial", dataIndex: "cert_serial"},
                        {title: "SubjectDN", dataIndex: "subjectdn"},
                        {title: "Gói", dataIndex: "service_name"},
                        {
                            title: "Người tạo", dataIndex: "user_create.fullname"
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Từ ngày",
                            dataIndex: "cert_start",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Đến ngày",
                            dataIndex: "cert_end",
                            render: text => formatDateTime(text)
                        },
                        {title: "Trạng thái hồ sơ", dataIndex: "",
                            render: (e) => {
                                if(e.contract_status === 1){
                                    return (<Tag color="blue">Đủ</Tag>);
                                }else{
                                    return (<Tag className="red">Thiếu</Tag>);
                                }
                            }
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(Compensation);
