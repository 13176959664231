import React, { Component } from "react";
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import "../css/main.css";

interface Props {
  name: string;
  label: string;
  wrapClass: string;
  type?: string;
  placeholder?: string;
  onChangeValue: any;
  value: any;
  error?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  maxLength?: number;
  dataKey?: string;
  min?: number;
  onBlur?: any;
  hidden?:boolean;
  defaultValue?: any;
  max?:number;
}
interface State { }

export default class InputWithLabel extends Component<Props, State> {
  state = {};
  returnFail = () =>{
    return false;
  }
  render() {
    return (
      <div className={this.props.wrapClass} hidden={this.props.hidden? this.props.hidden : false}>
        {(this.props.label) ?
          <LabelInput
            nameFor={this.props.name}
            label={this.props.label}
            isRequired={this.props.isRequired}
          />
          : ''}
        <input
          className="form-control font-input"
          type={this.props.type || "text"}
          name={this.props.name}
          title={this.props.value || ''}
          onChange={this.props.onChangeValue ? this.props.onChangeValue : this.returnFail}
          value={this.props.value || ''}
          autoComplete="off"
          disabled={this.props.isDisabled ? true : false}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength}
          data-key={this.props.dataKey}
          min={this.props.min}
          onBlur={this.props.onBlur}
          max={this.props.max}
        />
        <ValidateMessageError error={this.props.error}/>
      </div>
    );
  }
}
