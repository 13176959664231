import React, {useState} from "react"
import Helmet from "react-helmet";
import {RouteComponentProps} from "react-router-dom"
import SearchButton from "../../common/button/SearchButton"
import withNavbar from "../../common/HOC/NavbarContainerHOC"
import SelectDate from "../../common/form/SelectDate";
import {onFailAction} from "../../common/swal/SwalCommon";
import Axios from "axios";
import {loading} from "../../common/loading/Loading";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface Props extends RouteComponentProps {
}

const TITLE = "Đối soát yêu cầu cấp bù";

const CrossCheckingCompensationRequest: React.FC<Props> = props => {
    const [dataSearch, setDataSearch] = useState({
        date_from:"", date_to:""
    });

    const onSubmitSearchButton = async (e) => {
        e.preventDefault();
        if (!dataSearch.date_from || !dataSearch.date_to){
            onFailAction(
                'Bạn chưa điền đầy đủ thông tin'
            );
            return ;
        }
        loading.runLoadingBlockUI();
        const BASE_URL = REACT_APP_BASE_API_URL;
        let token = localStorage.getItem("currentUser");
        let fileName =`Danh sách đối soát cấp bù.xlsx`;
        if (token){
            let obj = JSON.parse(token);
            const url = `${BASE_URL}admin/cross-checking-compensation-request?token=${obj.token}&date_from=${dataSearch.date_from}&date_to=${dataSearch.date_to}`;
            Axios.get(`${url}`, {
                headers: {},
                responseType: 'blob',
            }).then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                setTimeout(() => URL.revokeObjectURL(link.href), 7000);
                loading.stopRunLoading();
            }).catch(function () {loading.stopRunLoading();});
        }
    };

    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, date_from: dateString });
    };
    const onChangeSelectDateToValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, date_to: dateString });
    };

    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <div className="row input-group">
                            <SelectDate
                                label=""
                                name="created_at_from"
                                wrappedClass="col-md-2 form-group nopadding-left"
                                onChange={onChangeSelectDateValid}
                                value={dataSearch.date_from}
                                isRequired={true}
                                placeholder="Từ ngày"
                            />
                            <SelectDate
                                label=""
                                name="created_at_to"
                                wrappedClass="col-md-2 form-group nopadding-left"
                                onChange={onChangeSelectDateToValid}
                                value={dataSearch.date_to}
                                isRequired={true}
                                placeholder="Đến ngày"
                            />
                            <div className="col-md-2 form-group">
                                <SearchButton onClick={onSubmitSearchButton}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withNavbar()(CrossCheckingCompensationRequest)
