import React, { Component } from "react";
import { Modal } from 'antd';
import { Input } from 'antd';
import ValidateMessageError from "./validate-message-error/ValidateMessageError";

const { TextArea } = Input;

interface Props {
    handleCancel?: any;
    handleDeny?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    error? : string;
}
interface State {
    visible: boolean;
}

export default class ModalDeny extends Component<Props, State> {

    componentDidMount() {
    }

    render() {

        return (
            <div>
                <Modal
                    title={'Lý do từ chối'}
                    visible={this.props.visible}
                    onOk={this.props.handleDeny}
                    onCancel={this.props.handleCancel}
                    destroyOnClose={true}
                    okText="Từ chối"
                    cancelText="Đóng"
                    className="modal-lg"
                    width="auto"
                    closable={false}
                    // bodyStyle={}
                >
                <TextArea
                    placeholder="Nhập vào lý do từ chối"
                    className="form-control"
                    required={true}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    rows={4}
                    autoSize= {{ minRows: 2, maxRows: 6 }}
                />
                <ValidateMessageError error={this.props.error}/>
                </Modal>
            </div>
        );
    }
}
