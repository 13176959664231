import React, {useEffect, useState} from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../common/form/InputWithLabel";
import BackButton from "../../common/button/BackButton";
import {onFailAction, onSuccessAction} from "../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import CateContractService from "../../../services/category-contract/CateContractService";
import ModalView from "../../common/form/Modal";
import RequestChangeInfoCertificateService from "../../../services/request-change-info-certificate/RequestChangeInfoCertificateService";
import DenyButton from "../../common/button/DenyButton";
import SubmitButton from "../../common/form/SubmitButton";
import ModalDeny from "../../common/form/ModalDeny";
import {Card} from "antd";
import {TableInfoCert} from "./TableInfoCert";
import {checkPermission} from "../../../helpers/NewCaHelper"
import RequestCertificateService from "../../../services/request-certificate/RequestCertificateService";
import SelectBox from "../../common/form/SelectBox";
const confirm_request = 'nv-request-change-info-certificate'
interface FormValues {
    code: string,
    fullname: string,
    email: number,
    represent_fullname: string,
    status: number,
    reason: string,
    uid:string,
    contact_name:string,
    contact_position:string,
    contact_phone:string,
    contact_mobile:string,
    contact_email:string,
    contact_fax:string,
    contact_address:string
    list_change_cert:any
    province_code: number
    district_code: number
    identity_type: number
}

interface OtherProps extends RouteComponentProps<{ id: string; }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const RequestCertificateViewPersonal: React.FC<Props> = props => {
    const service = new RequestChangeInfoCertificateService();
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    const {
        code,
        fullname,
        email,
        represent_fullname,
        status,
        reason,
        uid,
        contact_name,
        contact_position,
        contact_phone,
        contact_mobile,
        contact_email,
        contact_fax,
        contact_address,
        list_change_cert,
        identity_type
    } = props.values;
    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.find(id, 1);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        if (Number(result.data.status) === 6 && (result.data.doc_dk02_organ || result.data.doc_dk02_personal)){
            setLinkReSign(true);
            setLink(result.domain + "/view-info-dk02/" + btoa(result.data.code_request) + "/request-change")
            setSecretCode(result.data.password);
        }
        const serviceProvince = new RequestCertificateService();
        const province = await serviceProvince.getProvince();
        province.forEach(element => {
            if (element.newtel_code === result.data.province_code){
                setProvinceName(element.fullname)
            }
        });
        const district = await serviceProvince.getDistrictByProvince(result.data.province_code);
        district.forEach(element => {
            if (element.newtel_district_code === result.data.district_code){
                setDistrictName(element.fullname)
            }
        });

    };
    const [provinceName, setProvinceName] = useState("");
    const [districtName, setDistrictName] = useState("");
    const [linkReSign, setLinkReSign] = useState(false);
    const [link, setLink] = useState("");
    const [secretCode, setSecretCode] = useState("");
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);

    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false})
    };

    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim() === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.getDeny(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/thay-doi-thong-tin-chung-thu-so");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra!");
        } else {
            onFailAction(result.error);
        }
    };
    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };
    const submitDeny = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': true});
    };
    const submitApprove = async () => {
        const result = await service.getApproveGroup(id);
        if (result && result.status === 200) {
            onSuccessAction("Duyệt thành công!", () => {
                props.history.push("/thay-doi-thong-tin-chung-thu-so");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        } else {
            onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
        }
    };

    const onViewFileDoc = async type => {
        try {
            const result = await service.getFile(id, type);
            if (result && result.status === 200) {
                setModal({
                    ...modal,
                    'base64': result.base64,
                    'visible_modal': true,
                    'loading': true,
                    'title_modal': 'file',
                    'type_file': 'pdf'
                });
                return true;
            } else {
                onFailAction("Không tìm thấy file");
            }
            onFailAction("Có lỗi xảy ra!");
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    const onViewFileDK02 = async () => {
        try {
            const result = await service.getFileDK02(id, 1);
            if (result && result.status === 200) {
                setModal({
                    ...modal,
                    'base64': result.base64,
                    'visible_modal': true,
                    'loading': true,
                    'title_modal': 'file',
                    'type_file': 'pdf'
                });
                return true;
            } else {
                onFailAction(result.message);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
    };
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Yêu cầu thay đổi thông tin chứng thư số</h4>
            <div className="card-body">
                <form>
                    <div>
                        {/*lý do từ chối*/}
                        {status === 2 ? (
                            <div className="input-group">
                                <InputWithLabel
                                    name={""}
                                    label="Lý do từ chối"
                                    isDisabled={true}
                                    wrapClass="col-md-12"
                                    value={reason}
                                    onChangeValue={""}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        {linkReSign ? (
                            <React.Fragment>
                                <div className="input-group">
                                    <InputWithLabel
                                        name={""}
                                        label="Link ký lại DK02"
                                        isDisabled={true}
                                        wrapClass="col-md-6"
                                        value={link}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        name={""}
                                        label="Mã xác thực"
                                        isDisabled={true}
                                        wrapClass="col-md-6"
                                        value={secretCode}
                                        onChangeValue={""}
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            ""
                        )}
                        {/*card 1*/}
                        <Card className="m-r-15-i m-l-15-i" title="Thông tin điều chỉnh" size="small">
                            <div className="input-group">
                                <div className="input-group">
                                    <InputWithLabel
                                        name=""
                                        wrapClass="col-md-3"
                                        label="Mã khách hàng"
                                        isDisabled={true}
                                        value={uid}
                                        onChangeValue={""}
                                    />
                                    <SelectBox
                                        name=""
                                        options={{
                                            1: 'Mã số thuế',
                                            2: 'Mã ngân sách',
                                            3: 'Số quyết định',
                                            4: 'Mã bảo hiểm xã hội',
                                            5: 'Giấy phép đầu tư'
                                        }}
                                        label="Loại định danh"
                                        wrappedClass="col-md-3"
                                        onChange={props.handleChange}
                                        value={identity_type}
                                        error={props.errors.identity_type}
                                        isDisabled={true}
                                    />
                                    <InputWithLabel
                                        label="Mã định danh"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={code}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        name=""
                                        wrapClass="col-md-3"
                                        label="Người đại diện"
                                        isDisabled={true}
                                        value={represent_fullname}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        label="Tên khách hàng"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={fullname}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Email"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={email}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Tỉnh thành"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={provinceName}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Quận huyện"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={districtName}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <div className="col-md-3">
                                        <label className="invisible">12121</label>
                                        <button type="button" className="btn btn-outline-primary form-control btn-sm" onClick={()=>onViewFileDoc(1)}>CMND/Hộ chiếu người đại diện</button>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="invisible">12121</label>
                                        <button type="button" className="btn btn-outline-primary form-control btn-sm" onClick={()=>onViewFileDoc(2)}>File giấy tờ pháp lý</button>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="invisible">12121</label>
                                        <button type="button" className="btn btn-outline-primary form-control btn-sm" onClick={()=>onViewFileDoc(3)}>File yêu cầu điều chỉnh thông tin</button>
                                    </div>
                                    {linkReSign ? (
                                        <div className="col-md-3">
                                            <label className="invisible">12121</label>
                                            <button type="button" className="btn btn-outline-primary form-control btn-sm" onClick={()=>onViewFileDK02()}>File DK02</button>
                                        </div>
                                    ) : ("")}
                                </div>
                            </div>
                        </Card>
                        {/*card 2*/}
                        <Card className="m-r-15-i m-l-15-i mt-4" title="Thông tin liên hệ" size="small">
                            <div className="input-group">
                                <div className="input-group">
                                    <InputWithLabel
                                        label="Họ và tên"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={contact_name}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Chức vụ"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={contact_position}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Số điện thoại"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={contact_phone}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Số di động"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={contact_mobile}
                                        onChangeValue={""}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        label="Email"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={contact_email}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Fax"
                                        name={""}
                                        wrapClass="col-md-3"
                                        isDisabled={true}
                                        value={contact_fax}
                                        onChangeValue={""}
                                    />
                                    <InputWithLabel
                                        label="Địa chỉ"
                                        name={""}
                                        wrapClass="col-md-6"
                                        isDisabled={true}
                                        value={contact_address}
                                        onChangeValue={""}
                                    />
                                </div>
                            </div>
                        </Card>
                        {Number(status) === 3 || Number(status) === 4
                            ? (
                                <Card className="m-r-15-i m-l-15-i mt-4" title="Danh sách chứng thư số cần Gen" size="small">
                                    <TableInfoCert
                                        dataCert={list_change_cert}
                                    />
                                </Card>
                            ) : ''
                        }
                        {/*nút duyệt*/}
                        <div className="input-group d-flex justify-content-center p-5">
                            {(Number(status) === 1 && checkPermission(confirm_request)) ?
                                (
                                    <SubmitButton onClick={submitApprove} text="Duyệt"
                                                  iconClass="fas fa-check"/>
                                )
                                : (" ")
                            }
                            {(Number(status) === 1 && checkPermission(confirm_request)) ?
                                (
                                    <DenyButton onClick={submitDeny}/>
                                )
                                : (" ")
                            }
                            <BackButton
                                history={props.history}
                                // url="/thay-doi-thong-tin-chung-thu-so"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: '',
        fullname: '',
        email: 0,
        represent_fullname: '',
        status: 0,
        reason: '',
        uid:'',
        contact_name:'',
        contact_position:'',
        contact_phone:'',
        contact_mobile:'',
        contact_email:'',
        contact_fax:'',
        contact_address:'',
        list_change_cert:[],
        province_code: 0,
        district_code: 0,
        identity_type: 0,
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(RequestCertificateViewPersonal);

export default withNavbar()(RenderCreateForm);
