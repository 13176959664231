import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {History} from "history";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import VersionTokenServices from "../../../../services/version-token/VersionTokenServices";
import SelectBox from "../../../common/form/SelectBox";
import ProviderCategoryService from "../../../../services/ProviderCategory/ProviderCategoryService";

interface FormValues {
    code: string;
    note: string;
    provider_id: string;
    type: string;
}

interface OtherProps {
    history: History;
}

type Props = OtherProps & FormikProps<FormValues>;

const VersionTokenCreate: React.FC<Props> = props => {
    const [providerList, setProviderList] = useState({});
    
    const {code, note, provider_id, type} = props.values;
    
    const fetchProviderOption = async () => {
        const service = new ProviderCategoryService();
        const result = await service.all();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.name;
        });
        setProviderList(options);
    };
    
    useEffect(() => {
        fetchProviderOption();
    }, []);
    
    const changeProviderId = (e) => {
        props.setFieldValue("provider_id", e)
    }

    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Thêm mới Version Token</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="code"
                            label="Mã version token"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="type"
                            label="Loại version token"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={type}
                            error={props.errors.type}
                            isRequired={true}
                            maxLength={255}
                        />
                        <SelectBox
                            name="provider_id"
                            options={providerList}
                            label="Nhà cung cấp"
                            wrappedClass="col-md-4"
                            firstOptionLabel="Chọn nhà cung cấp"
                            onChange={changeProviderId}
                            value={provider_id}
                            error={props.errors.provider_id}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="note"
                            label="Ghi chú"
                            wrapClass="col-md form-group"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            // url="/danh-muc-version-token"
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        code: "",
        note: "",
        provider_id: "",
        type: "",
    }),
    validationSchema: Yup.object().shape({
        code: Yup.string().required("Mã không thể bỏ trống."),
        provider_id: Yup.string().required("Nhà cung cấp không thể bỏ trống."),
        type: Yup.string().required("Loại version token không thể bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new VersionTokenServices();
        const result = await service.store(values);
        if (result.status === 200) {
            onSuccessAction("Thêm mới version token!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(VersionTokenCreate);

export default withNavbar()(RenderCreateForm);
