import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString,
    queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import {onFailAction} from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SelectBox from "../../common/form/SelectBox";
import RequestChangeInfoCertificateService from "../../../services/request-change-info-certificate/RequestChangeInfoCertificateService";
import TableActionButton from "../user/table/action-button/TableActionButton";
import { Tag } from "antd";

interface Props extends RouteComponentProps {
}

const TITLE = "Yêu cầu thay đổi thông tin chứng thư số";

const RequestChangeInFoCertificate: React.FC<Props> = props => {
    const service = new RequestChangeInfoCertificateService();
    const [dataSearch, setDataSearch] = useState({created_by: "", fullname:"", tax_code:""});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };

    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchCreatedBy = (e) => {
        setDataSearch({...dataSearch, 'created_by': e})
    }

    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    const [listSaleId, setListSaleId] = useState({});
    useEffect(() => {
        getListSaleUser();
        // eslint-disable-next-line
    }, []);

    const getListSaleUser = async () => {
        const result = await service.getListUser();
        const options = {};
        result.data.forEach(element => {
            options[element.id] = element.fullname;
        });
        setListSaleId(options);
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickView={() =>
                    (
                        Number(record.type) === 1
                            ? props.history.push(`/thay-doi-thong-tin-chung-thu-so/chi-tiet/to-chuc/${record.id}`)
                            : props.history.push(`/thay-doi-thong-tin-chung-thu-so/chi-tiet/ca-nhan/${record.id}`)
                    )
                }
            />
        );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <InputWithLabel
                            name="fullname"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.fullname}
                            isRequired={false}
                            label={""}
                            placeholder="Tên khách hàng"
                        />
                        <InputWithLabel
                            name="tax_code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.tax_code}
                            isRequired={false}
                            label={""}
                            placeholder="Mã số DN hoặc CMND"
                        />
                        <SelectBox
                            name="created_by"
                            options={listSaleId}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Người tạo"
                            onChange={onChangeDataSearchCreatedBy}
                            value={dataSearch.created_by}
                        />
                        <div className="col-md-2 form-group nopadding-left">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Mã khách hàng",
                            dataIndex: "uid",
                        },
                        {
                            title: "Tên khách hàng",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.group) return e.group.fullname;
                                    if (e.personal) return e.personal.fullname;
                                }
                            }
                        },
                        {
                            title: "CMND/Mã số DN",
                            dataIndex: "",
                            render: (e)=>{
                                if (e){
                                    if (e.group) return e.group.code;
                                    if (e.personal) return e.personal.passport;
                                }
                            }
                        },
                        {title: "Người tạo", dataIndex: "created_by.fullname"},
                        {
                            title: "Loại yêu cầu",
                            dataIndex: "",
                            render: (e) => {
                                if(e.type === 1){
                                    return (<Tag color="cyan">Tổ chức</Tag>);
                                }
                                if(e.type === 2){
                                    return (<Tag color="green">Cá nhân</Tag>);
                                }
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "",
                            render: (e) => {
                                if(e.status === 1){
                                    return (<Tag color="orange">Chờ Khách hàng ký</Tag>);
                                }
                                if(e.status === 2){
                                    return (<Tag color="red">Khách hàng từ chối</Tag>);
                                }
                                if(e.status === 3){
                                    return (<Tag color="geekblue">Chờ nghiệp vụ duyệt</Tag>);
                                }
                                if(e.status === 4){
                                    return (<Tag color="red">Nghiệp vụ từ chối</Tag>);
                                }
                                if(e.status === 5){
                                    return (<Tag color="geekblue">Chờ gen</Tag>);
                                }
                                if(e.status === 6){
                                    return (<Tag color="green">Hoàn thành</Tag>);
                                }
                            }
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(RequestChangeInFoCertificate);
