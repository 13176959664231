import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class DistrictService extends FetchAPI{

    public async getDistricts(data: object) {
        const path = "/cate/district"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteDistrictById(id){
        const result =  await this.request("cate/district-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getDistrict(id){
        const result = await this.request("cate/district-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createDistrict(data){
        const result = await this.request("cate/district",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateDistrict(data){
        const result = await this.request("cate/district-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
}

export default DistrictService;