import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";

const PREFIX_URL = "admin/yeu-cau-chung-thu-so";
class RequestCertificate extends FetchAPI {
    public async list(data) {
        const result = await this.request(
            `${PREFIX_URL}/index`,
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async find(id) {
        const result = await this.request(
            `${PREFIX_URL}/find/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getListSaleUser() {
        const result = await this.request(
            `${PREFIX_URL}/getListSaleUser`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getServicePackage() {
        const result = await this.request(
            `${PREFIX_URL}/getServicePackage`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getPersonalRequest(id) {
        const result = await this.request(
            `${PREFIX_URL}/getPersonalRequest/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getGroupRequest(id) {
        const result = await this.request(
            `${PREFIX_URL}/getGroupRequest/${id}`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async disPlayPdfFile(path) {
        const result = await this.request(
            `${PREFIX_URL}/disPlayPdfFile`,
            {path},
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getApprove(data) {
        const result = await this.request(
            `${PREFIX_URL}/getApprove`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async getUpdateAndApproveGroup(data) {
        const result = await this.request(
            `${PREFIX_URL}/update-and-approve-group`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async getUpdateAndApprovePersonal(data) {
        const result = await this.request(
            `${PREFIX_URL}/update-and-approve-personal`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getDeny(data) {
        const result = await this.request(
            `${PREFIX_URL}/getDeny`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    }

    public async getFileDoc(id, type) {
        const result = await this.request(
            `${PREFIX_URL}/getFileDoc`,
            {id, type},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async getProvince() {
        const result = await this.request(
            `${PREFIX_URL}/getProvince`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getDistrictByProvince(provinceCode) {
        const result = await this.request(
            `${PREFIX_URL}/getDistrictByProvinceCode`,
            {provinceCode},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getListCateServicePackage(type_search,type, obj,type_device, provider_id, hsm_provider) {
        const result = await this.request(
            `${PREFIX_URL}/get-cate-service-package`,
            {type_search,type, obj,type_device, provider_id,hsm_provider},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async getVersionToken() {
        const result = await this.request(
            `${PREFIX_URL}/get-version-token`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    }
    public async previewFileDk0101 (data: object){
        const result = await this.request(
            `${PREFIX_URL}/generate-file-dk0101`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    };
    public async previewFileDk0102 (data: object){
        const result = await this.request(
            `${PREFIX_URL}/generate-file-dk0102`,
            data,
            TypeMethodContsantEnum.POST
        );
        return result;
    };
    public async getAllProvider (){
        const result = await this.request(
            `${PREFIX_URL}/all-provider`,
            {},
            TypeMethodContsantEnum.GET
        );
        return result;
    };
    public async sendMailReSign(data) {
        const result = await this.request(
            `${PREFIX_URL}/sendMailReSign`,
            data ,
            TypeMethodContsantEnum.POST
        );
        return result;
    }
}

export default RequestCertificate;
