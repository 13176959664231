export enum StatusEnum {
  ACTIVE = "0",
  STOP = "1",
  DEACTIVE = "2"
}

export class TypeRole {
  static readonly TYPE = {
    1: "Admin",
    2: "QLBH"
  };
  static readonly badgeClass = {
    1: "geekblue",
    2: "green"
  };
}
