import React, { Component } from "react";
import "../../common/css/main.css"
import {Modal,Button} from 'antd';
import InputWithLabel from "../../common/form/InputWithLabel";

// import InputUploadFile from "../../components/common/form/input-with-label/InputUploadFile";

interface Props {
    handleCancel?: any;
    handleUpdate?: any;
    data?: any;
    value?: string;
    visible: boolean;
    loading?: boolean;
    title?: string;
    onChange?: any;
    onChangeInputNumber?: any;
    error? : string;
    isHidden?: boolean;
    isDisable?: boolean;
    onBlur? : any;
}
interface State {
    visible: boolean;
    dataSource: any;
}
export default class ModalConclude extends Component<Props, State> {
    render() {
        const dataSource = this.props.data;
        const isDisable = dataSource.crosschecking ? (dataSource.crosschecking.status === 5 || dataSource.crosschecking.status === 6) : false
        const isUpdate = dataSource.crosschecking  ? (dataSource.crosschecking.status === 2 || dataSource.crosschecking.status === 4 
            || dataSource.crosschecking.status === 5 || dataSource.crosschecking.status === 6 ) : false
        const last_date = dataSource.crosschecking ? (dataSource.crosschecking.month + '.' + dataSource.crosschecking.year) : ''
        const onRenderFooter = () => {
            return [
                this.props.handleUpdate ? (
                    <Button
                        key={1}
                        type="primary"
                        onClick={this.props.handleUpdate}
                        hidden={isUpdate}
                    >
                        Cập nhật
                    </Button>
                ) : (
                    ""
                ),
                this.props.handleCancel ? (
                    <Button
                        key={2}
                        type="default"
                        onClick={this.props.handleCancel}
                    >
                        Đóng
                    </Button>
                ) : (
                    ""
                )
            ];
        };
        return (
            <div>
                <Modal
                    visible={this.props.visible}
                    className="modal-xs w-75 h-75"
                    width="50%"
                    closable={false}
                    centered
                    style={{ top:"60px"}}
                    footer={onRenderFooter()}
                > { dataSource ? ( 
                    <table className="w-100 m-t-20 ant-table-bordered" style={{border: "1px solid #e8e8e8"}} key={dataSource.id}>
                        <thead className="ant-table-thead">
                            <tr>
                                <th className="text-center th-stt">STT</th>
                                <th className="text-center">Nội dung</th>
                                <th className="text-center">Số tiền (VNĐ)</th>
                                <th className="text-center">Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody className="ant-table-tbody modal-conclude">
                            <tr>
                                <td className="text-center">1</td>
                                <td> Số tiền bên B phải thanh toán tháng trước</td>
                                <td> 
                                    <InputWithLabel
                                        name = {"amount_rest_last_month"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChangeInputNumber}
                                        isRequired={true}
                                        dataKey={"amount_rest_last_month"}
                                        value={dataSource.amount_rest_last_month || "0"}
                                        type= "text"
                                        isDisabled= {isDisable}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_rest_last_month"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_rest_last_month}
                                        dataKey={"note_amount_rest_last_month"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">2</td>
                                <td>
                                    Bên B phải trả cho bên A tiền phát triển khách hàng tháng T{last_date}
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_pay"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.amount_pay || "0"}
                                        type= "text"
                                        dataKey={"amount_pay"}
                                        isDisabled= {true}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_pay"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_pay}
                                        dataKey={"note_amount_pay"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">3</td>
                                <td>
                                    Số tiền tạm giữ về hồ sơ thiếu 50.000đ/ bộ hồ sơ thiếu
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_temp_document"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.amount_temp_document || "0"}
                                        type= "text"
                                        dataKey={"amount_temp_document"}
                                        isDisabled= {true}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_temp_document"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_temp_document}
                                        dataKey={"note_amount_temp_document"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">4</td>
                                <td>
                                    Trả lại số tiền hồ sơ tạm giữ các kỳ trước bổ sung
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_return_document"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChangeInputNumber}
                                        value={dataSource.amount_return_document || "0"}
                                        type= "text"
                                        dataKey={"amount_return_document"}
                                        isDisabled= {isDisable}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_return_document"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_return_document}
                                        dataKey={"note_amount_return_document"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">5</td>
                                <td>
                                    Số tiền bên B đã thanh toán công nợ kỳ trước
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_paid_previous"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChangeInputNumber}
                                        value={dataSource.amount_paid_previous || "0"}
                                        type= "text"
                                        dataKey={"amount_paid_previous"}
                                        isDisabled= {isDisable}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_paid_previous"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_paid_previous}
                                        dataKey={"note_amount_paid_previous"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">6</td>
                                <td>
                                    Thu tiền token thiếu trong kỳ
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_not_enough_token"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChangeInputNumber}
                                        value={dataSource.amount_not_enough_token || "0"}
                                        type= "text"
                                        dataKey={"amount_not_enough_token"}
                                        isDisabled= {isDisable}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_not_enough_token"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_not_enough_token}
                                        dataKey={"note_amount_not_enough_token"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">7</td>
                                <td>
                                    Phát sinh khác
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_other"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChangeInputNumber}
                                        value={dataSource.amount_other || "0"}
                                        type= "text"
                                        dataKey={"amount_other"}
                                        isDisabled= {isDisable}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_other"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_other}
                                        dataKey={"note_amount_other"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">8</td>
                                <td>
                                    Số tiền bên B  phải thanh toán cho bên A là (I+II+III-IV-V+VI-VII)
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"amount_total"}
                                        label=""
                                        wrapClass="td-number"
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.amount_total}
                                        type= "text"
                                        dataKey={"amount_total"}
                                        isDisabled= {true}
                                        onBlur={this.props.onBlur}
                                    />
                                </td>
                                <td className="text-center"> 
                                    <InputWithLabel
                                        name = {"note_amount_total"}
                                        label=""
                                        wrapClass=""
                                        onChangeValue={this.props.onChange}
                                        value={dataSource.note_amount_total}
                                        dataKey={"note_amount_total"}
                                        isDisabled= {isDisable}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    ) : ('')
                }
                {this.props.error ? <div className="col-md-12 text-center"><p className="text-danger">{this.props.error}</p></div>  : ""}
                </Modal>
            </div>
        );
    }
}
