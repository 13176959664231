import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import { History } from "history";
import {Card} from "antd";

import CustomerService from "../../../../services/customer/CustomerService";

interface Props {
    id: string;
    history: History;
}

interface State {
    uid: string;
    passport: string;
    fullname: string;
    email: string;
    phone: string;
    address: string;
    gender: string;
    name: string;
    created_by_name: string;
    created_by_type: string;
    created_by_agency_name: string;
    created_by_agency_sale: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
    service = new CustomerService();
    state = {
        dataSearchDocument: {
            id: this.props.id,
        }
    }
    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        const data = await this.service.show(this.props.id,2);
        if (data && data.status === 200) {
            const {
                uid, fullname, email, phone, address, passport, gender,created_by_name,created_by_type,created_by_agency_name,created_by_agency_sale
            } = data.data;
            const {name} = data.data.request.cateservicespackage
            this.props.setValues({
                uid, fullname, email, phone, address, passport, gender, name,created_by_name,created_by_type,created_by_agency_name,created_by_agency_sale
            } as Pick<State, keyof State>);
        }
    };
    render() {
        const {
            uid,fullname, email, phone, address, passport, gender,name,created_by_name,created_by_type,created_by_agency_name,created_by_agency_sale
        } = this.props.values;
        return (
            <form onSubmit={this.props.handleSubmit}>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin cá nhân</label>} size="small">
                    {/*lines-1*/}
                    <div className="input-group m-t-10">
                        <InputWithLabel
                            name="uid"
                            label="Mã khách hàng"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={uid}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="passport"
                            label="Mã định danh"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={passport}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="fullname"
                            label="Tên khách hàng"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={fullname}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="gender"
                            label="Giới tính"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={parseInt(gender)===1? "Nam": "Nữ"}
                            isDisabled={true}
                        />
                    </div>
                    {/*lines-2*/}
                    <div className="input-group">
                        <InputWithLabel
                            name="type_request"
                            label="Loại khách hàng"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={"Cá nhân"}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="email"
                            label="Email"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={email}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="phone"
                            label="Số điện thoại"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={phone}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="address"
                            label="Địa chỉ"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-3"
                            value={address}
                            isDisabled={true}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin khác</label>} size="small">
                    {/*lines-3*/}
                    <div className="input-group m-t-10">
                        <InputWithLabel
                            name="package_code"
                            label="Gói dịch vụ"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="created_by_name"
                            label="Người tạo"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={created_by_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="created_by_type"
                            label="Loại"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={created_by_type}
                            isDisabled={true}
                        />
                    </div>
                    {/*lines-4*/}
                    <div className="input-group">
                        <InputWithLabel
                            name="created_by_agency_name"
                            label="Đại lý"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-8"
                            value={created_by_agency_name}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            name="created_by_agency_sale"
                            label="Kinh doanh"
                            onChangeValue={this.props.handleChange}
                            wrapClass="col-md-4"
                            value={created_by_agency_sale}
                            isDisabled={true}
                        />
                    </div>
                </Card>
            </form>
        );
    }
}
const userUpdateForm = withFormik<Props, State>({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: () => ({
        uid: "",
        fullname: "",
        email: "",
        phone: "",
        address: "",
        passport: "",
        gender: "",
        name: "",
        created_by_name: "",
        created_by_type: "",
        created_by_agency_name: "",
        created_by_agency_sale: ""
    }),
    validationSchema: Yup.object().shape({
    }),

    handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
        setSubmitting(false);
    },

})(UpdateForm);

export default userUpdateForm;
