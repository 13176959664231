import FetchAPI from '../../helpers/FetchAPI';
import {TypeMethodContsantEnum} from '../../helpers/constant/MethodAPIConstant';

class ContributorService extends FetchAPI{

    public async getContributors(data: object) {
        const path = "admin/contributor"
        return await this.request(path, data, TypeMethodContsantEnum.GET);
    }

    public async getContributor(id) {
        const path = "admin/contributor-show"
        return await this.request(path, {id}, TypeMethodContsantEnum.GET);
    }
    public async getContributorDocumentFile(data: object) {
        const path = "admin/contributor-document-file"
        return await this.request(path, data, TypeMethodContsantEnum.GET);
    }

    public async getContributorApprove(data: object) {
        const path = "admin/contributor-approve"
        return await this.request(path, data, TypeMethodContsantEnum.POST);
    }

    public async getContributorDeny(data: object) {
        const path = "admin/contributor-deny"
        return await this.request(path, data, TypeMethodContsantEnum.POST);
    }

}

export default ContributorService;