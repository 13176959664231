import React from "react";
import ActionButtonUpdate from "../../../../common/button/action/ActionButtonUpdate";
import ActionButtonLock from "../../../../common/button/action/ActionButtonLock";

interface Props {
  onClickUpdate: () => void;
  onClickLock: () => void;
  isDisplayUpdateButton?: boolean;
  isDisplayLockButton?: boolean;
}

export default function TableActionButton(props: Props) {
  return (
    <div className="row justify-content-center">
      {props.isDisplayUpdateButton ? (
        <ActionButtonUpdate onClick={props.onClickUpdate} tittle='Xem cộng tác viên' icon='fa fa-eye fa-lg'/>
      ) : (
        ""
      )}
      {props.isDisplayLockButton ? (
        <ActionButtonLock onClick={props.onClickLock} />
      ) : (
        ""
      )}
    </div>
  );
}
