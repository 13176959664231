    import React, {useEffect } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { onSuccessAction, onFailAction } from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import { RouteComponentProps } from "react-router-dom";
import AssetCategoryService from "../../../../services/asset-category/AssetCategoryService";

interface FormValues {
  code: string;
  type: string;
  name: string;
  note: string;
}

interface OtherProps
  extends RouteComponentProps<{
    id: string;
  }> {}

type Props = OtherProps & FormikProps<FormValues>;

const AssetCategoryUpdate: React.FC<Props> = props => {
  const id = props.match.params.id;
  const { code, note, name, type } = props.values;

  const onFetchData = async () => {
    const service = new AssetCategoryService();
    const versionToken = await service.find(id);
    for (let key in versionToken.data) {
      props.setFieldValue(key, versionToken.data[key]);
    }
  };

  useEffect(() => {
    onFetchData();
    // eslint-disable-next-line
  }, []);
  const changeType = (e) => {
    props.setFieldValue("type",e)
  }
  return (
    <div className="card">
      <h4 className="card-title1 ml-3">Cập nhật danh mục tài sản</h4>
      <div className="card-body">
        <form>
          <div className="input-group">
            <InputWithLabel
              name="name"
              label="Tên tài sản"
              wrapClass="col-md-4 form-group"
              onChangeValue={props.handleChange}
              value={name}
              error={props.errors.name}
              isRequired={true}
              maxLength={255}
            />
            <InputWithLabel
              name="code"
              label="Mã "
              wrapClass="col-md-4 form-group"
              onChangeValue={props.handleChange}
              value={code}
              error={props.errors.code}
              isRequired={true}
              maxLength={255}
            />
            <SelectBox
              name="type"
              options={{
                1: "Tài sản tài liệu bán hàng",
                2: "Tài sản quà tặng"
              }}
              label="Loại tài sản"
              wrappedClass="col-md-4"
              firstOptionLabel="Chọn loại tài sản"
              onChange={changeType}
              value={type}
              error={props.errors.type}
              isRequired={true}
            />
          </div>
          <div className="input-group">
            <InputWithLabel
              name="note"
              label="Ghi chú"
              wrapClass="col-md form-group"
              onChangeValue={props.handleChange}
              value={note}
              error={props.errors.note}
            />
          </div>
          <div className="input-group d-flex justify-content-center p-5">
            <BackButton
              history={props.history}
              // url="/danh-muc-tai-san"
            ></BackButton>
            <SubmitButton onClick={props.handleSubmit}></SubmitButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const RenderCreateForm = withFormik<Props, FormValues>({
  mapPropsToValues: () => ({
    code: "",
    name: "",
    note: "",
    type: ""
  }),
  validationSchema: Yup.object().shape({
    code: Yup.string().required("Mã không thể bỏ trống."),
    name: Yup.string().required("Tên không thể bỏ trống."),
    type: Yup.string().required("Loại tài sản không thể bỏ trống.")
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new AssetCategoryService();
    const result = await service.update({
      ...values,
      id: props.match.params.id
    });
    if (result.status === 200) {
      onSuccessAction("Cập nhật danh mục tài sản!", function() {
        props.history.goBack();
      });
    } else if (result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction(result.error);
    }
  }
})(AssetCategoryUpdate);

export default withNavbar()(RenderCreateForm);
