import React, { Component } from "react";
import "../../common/css/dashboard.css";
import FeatherIcon from 'feather-icons-react';
import HomeService from "../../../services/home/HomeService"

interface Props {}

interface State {
}

export default class Card extends Component<Props, State> {
    state = {
        totalAgency: 0,
        changeAgency:0,
        totalContributor: 0,
        changeContributor:0,
        totalOwner: 0,
        changeOwner:0,
        amountCer: 0,
        amountCerCts: 0,
        totalCustomer: 0,
        updated_at:''
    };
    async componentDidMount() {
        this.fetchOwner()
    }
    fetchOwner = async (params = {}) => {
        const service = new HomeService()
        const dataCard = await service.getDataCard()
        let data= dataCard.data
        this.setState({
            totalAgency: data.totalAgency,
            changeAgency: data.changeAgency,
            totalContributor: data.totalContributor,
            changeContributor: data.changeContributor,
            totalOwner: data.totalOwner,
            changeOwner: data.changeOwner,
            amountCer: data.amountCer,
            amountCerCts: data.amountCerCts,
            totalCustomer: data.totalCustomer,
            updated_at: data.updated_at
          });
    }
    render(){
        return (
        <div>
            {/* line-1 */}
            <div className="row">
                <div className="col-xl-4 col-md-6">
                    <div className="card">
                        <div className="card-block">
                            <div className="row align-items-center">
                                <div className="col-4 ml-4 mt-4 mb-0 p-r-0">
                                    <h5 className="text-muted m-b-0"><span className="number text-c-left f-w-600">{this.state.totalOwner}</span></h5>
                                </div>
                                <div className="col-7 text-right mt-4">
                                    <span className="fa fa-users f-28" style={{fontSize: "35px"}}></span>
                                </div>
                                <div className="col-md-6 ml-4 mt-0 mb-4">
                                    <span className="text-muted m-b-0">Tổng đại lý/ CTV</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-c-left">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <p className="text-white m-b-0"> {Math.abs(this.state.changeAgency)} %</p>
                                </div>
                                <div className="col-3 text-right-icon">
                                    <FeatherIcon icon={this.state.changeAgency >= 0 ? "trending-up" : "trending-down"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="card">
                        <div className="card-block">
                            <div className="row align-items-center">
                                <div className="col-4 ml-4 mt-4 mb-0 p-r-0">
                                    <h5 className="text-muted m-b-0"><span className="number text-c-center f-w-600">{this.state.totalAgency}</span></h5>
                                </div>
                                <div className="col-7 text-right mt-4">
                                    <span className="fa fa-building f-28" style={{fontSize: "35px"}}></span>
                                </div>
                                <div className="col-md-6 ml-4 mt-0 mb-4">
                                    <span className="text-muted m-b-0">Đại lý</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-c-center">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <p className="text-white m-b-0"> {Math.abs(this.state.changeContributor)} %</p>
                                </div>
                                <div className="col-3 text-right-icon">
                                    <FeatherIcon icon={this.state.changeContributor >= 0 ? "trending-up" : "trending-down"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="card">
                        <div className="card-block">
                            <div className="row align-items-center">
                                <div className="col-4 ml-4 mt-4 mb-0 p-r-0">
                                    <h5 className="text-muted m-b-0"><span className="number text-c-right f-w-600">{this.state.totalContributor}</span></h5>
                                </div>
                                <div className="col-7 text-right mt-4">
                                    <span className="fa fa-user-circle f-28" style={{fontSize: "35px"}}></span>
                                </div>
                                <div className="col-md-6 ml-4 mt-0 mb-4">
                                    <span className="text-muted m-b-0">Cộng tác viên</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-c-right">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <p className="text-white m-b-0"> {Math.abs(this.state.changeOwner)} %</p>
                                </div>
                                <div className="col-3 text-right-icon">
                                    <FeatherIcon icon={this.state.changeOwner >= 0 ? "trending-up" : "trending-down"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* line-2 */}
            <div className="row mt-5">
                <div className="col-xl-4 col-md-6">
                    <div className="card">
                        <div className="card-block bg-c-left">
                            <div className="row align-items-center">
                                <div className="col-4 ml-4 mt-4 mb-0 p-r-0">
                                    <h5 className="text-muted m-b-0"><span className="number-line-2 text-c-left f-w-600">{this.state.amountCer} </span></h5>
                                </div>
                                <div className="col-7 text-right mt-4">
                                        <FeatherIcon color="white" icon="layers" size="35px" strokeWidth="2px"/>
                                </div>
                                <div className="col-md-6 ml-4 mt-0 mb-4">
                                    <span className="text-muted-2 m-b-0">Số chứng thư số trong tháng</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-c-left">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <p className="text-white m-b-0"> update {this.state.updated_at}</p>
                                </div>
                                <div className="col-3 text-right-icon">
                                    <FeatherIcon icon="clock"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-6">
                    <div className="card">
                        <div className="card-block bg-c-center">
                            <div className="row align-items-center">
                                <div className="col-4 ml-4 mt-4 mb-0 p-r-0">
                                    <h5 className="text-muted m-b-0"><span className="number-line-2 text-c-center f-w-600">{this.state.amountCerCts} </span></h5>
                                </div>
                                <div className="col-7 text-right mt-4">
                                        <FeatherIcon color="white" icon="package" size="35px" strokeWidth="2px"/>
                                </div>
                                <div className="col-md-12 ml-4 mt-0 mb-4">
                                    <span className="text-muted-2 m-b-0">Số lượng Token đã xuất trong tháng</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer bg-c-center">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <p className="text-white m-b-0"> update {this.state.updated_at}</p>
                            </div>
                            <div className="col-3 text-right-icon">
                                <FeatherIcon icon="clock"/>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*Todo hidden card total customer for nv, please don't delete!*/}

                {/*<div className="col-xl-4 col-md-6">*/}
                {/*    <div className="card">*/}
                {/*        <div className="card-block bg-c-right">*/}
                {/*            <div className="row align-items-center">*/}
                {/*                <div className="col-4 ml-4 mt-4 mb-0 p-r-0">*/}
                {/*                    <h5 className="text-muted m-b-0"><span className="number-line-2 text-c-right f-w-600">{this.state.totalCustomer}</span></h5>*/}
                {/*                </div>*/}
                {/*                <div className="col-7 text-right mt-4">*/}
                {/*                    <FeatherIcon color="white" icon="users" size="35px" strokeWidth="2px"/>*/}
                {/*                </div>*/}
                {/*                <div className="col-md-6 ml-4 mt-0 mb-4">*/}
                {/*                    <span className="text-muted-2 m-b-0">Số lượng khách hàng</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="card-footer bg-c-right">*/}
                {/*            <div className="row align-items-center">*/}
                {/*                <div className="col-9">*/}
                {/*                    <p className="text-white m-b-0"> update {this.state.updated_at}</p>*/}
                {/*                </div>*/}
                {/*                <div className="col-3 text-right-icon">*/}
                {/*                    <FeatherIcon icon="clock"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )}
}
