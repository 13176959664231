const initalState = {permission_list: []}

const authReducer = (state = initalState, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                ...action.userData
            }
        default:
            return state
    }
}
export default authReducer;