import React, {useEffect} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import SelectDate from "../../../common/form/SelectDate";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import ProviderCategoryService from "../../../../services/ProviderCategory/ProviderCategoryService";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";

interface FormValues {
    name: string;
    email: string;
    code: string;
    address: string;
    phone: string;
    note: string;
    start_date: string;
    end_date: string;
    tax_code: string;
}

interface OtherProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const ProviderCategoryUpdate: React.FC<Props> = props => {
    const id = props.match.params.id;
    const {
        name,
        email,
        code,
        address,
        phone,
        note,
        start_date,
        end_date,
        tax_code,
    } = props.values;
    
    const onFetchData = async () => {
        const service = new ProviderCategoryService();
        const providerCategory = await service.find(id);
        for (let key in providerCategory.data) {
            props.setFieldValue(key, providerCategory.data[key]);
        }
    };
    
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);
    
    return (
        <div className="card">
            <h4 className="card-title1 ml-3">Cập nhật nhà cung cấp</h4>
            <div className="card-body">
                <form>
                    <div className="input-group">
                        <InputWithLabel
                            name="name"
                            label="Tên nhà cung cấp"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={name}
                            error={props.errors.name}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="code"
                            label="Mã nhà cung cấp"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={code}
                            error={props.errors.code}
                            isRequired={true}
                            isDisabled={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="tax_code"
                            label="Mã số thuế"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={tax_code}
                            error={props.errors.tax_code}
                            isRequired={true}
                            maxLength={255}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="email"
                            label="Email"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={email}
                            error={props.errors.email}
                            isRequired={true}
                            maxLength={255}
                        />
                        <InputWithLabel
                            name="phone"
                            label="Số điện thoại"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={phone}
                            error={props.errors.phone}
                            isRequired={true}
                        />
                        <InputWithLabel
                            name="address"
                            label="Địa chỉ"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={address}
                            error={props.errors.address}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            name="note"
                            label="Ghi chú"
                            wrapClass="col-md-4 form-group"
                            onChangeValue={props.handleChange}
                            value={note}
                            error={props.errors.note}
                        />
                        <SelectDate
                            label="Ngày bắt đầu hợp tác"
                            name="start_date"
                            wrappedClass="col-md-4 form-group"
                            onChange={(value, dateString) => {
                                props.setFieldValue("start_date", dateString);
                            }}
                            value={start_date}
                            error={props.errors.start_date}
                            isRequired={true}
                        />
                        <SelectDate
                            label="Ngày kết thúc hợp tác"
                            name="end_date"
                            wrappedClass="col-md-4 form-group"
                            onChange={(value, dateString) => {
                                props.setFieldValue("end_date", dateString);
                            }}
                            value={end_date}
                            error={props.errors.end_date}
                            isRequired={true}
                        />
                    </div>
                    <div className="input-group d-flex justify-content-center p-5">
                        <BackButton
                            history={props.history}
                            // url="/nha-cung-cap"
                        />
                        <SubmitButton onClick={props.handleSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderUpdateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        start_date: "",
        end_date: "",
        address: "",
        code: "",
        email: "",
        name: "",
        note: "",
        phone: "",
        tax_code: ""
    }),
    validationSchema: Yup.object().shape({
        name: Yup.string().required("Tên phần mềm không được bỏ trống."),
        code: Yup.string().required("Mã không thể bỏ trống."),
        email: Yup.string()
        .required("Email không thể bỏ trống.")
        .email("Email không đúng định dạng!"),
        phone: Yup.string().required("Số điện thoại không thể bỏ trống."),
        start_date: Yup.string().required("Ngày bắt đầu hợp tác không thể bỏ trống."),
        end_date: Yup.string().required("Ngày kết thúc hợp tác không thẻ bỏ trống."),
        tax_code: Yup.string().matches(/^\d{9,16}$/,"Mã số thuế từ 9 đến 16 ký tự")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new ProviderCategoryService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật nhà cung cấp thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.errors);
        }
    }
})(ProviderCategoryUpdate);

export default withNavbar()(RenderUpdateForm);
