import React, { Component } from "react";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import {
  convertJsonToQueryString,
  queryStringToJSON
} from "../../../helpers/NewCaHelper";
import { History, Location } from "history";
import { match } from "react-router";
import SearchButton from "../../common/button/SearchButton";
import Helmet from "react-helmet";
import { Type } from "./RequestTkAdminEnum";
import SelectBox from "../../common/form/SelectBox";
import UserService from "../../../services/user/UserService";
import AgencyTable from "./table/Table";
import SelectBoxAgency from "../../common/form/SelectBoxAgency";
import SelectUserManager from "../../common/form/SelectUserManager";
import SelectDate from "../../common/form/SelectDate";

interface Props {
  history: History;
  location: Location;
  match: match;
  name: string;
  userManager: object;
  user: any;
}
interface State {
  dataSearch: any;
  agency: Object;
}
const TITLE = "Danh sách yêu cầu tạo tài khoản";
const TYPE_BUSINESS_EMPLOYEE = 5;
class PageIndex extends Component<Props, State> {
  state = {
    dataSearch: {
      text_search: "",
      status: "",
      owner_id: "",
      user_id: "",
      belong_to: "",
      created_at: "",
    },
    userManager: {},
    agency: {}
  };
  onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    const dataSearch = { ...this.state.dataSearch, [name]: value };
    this.setState({ dataSearch });
  };

  handleChangeBoxAgency = e => {
    const dataSearch = { ...this.state.dataSearch, owner_id: e };
    this.setState({ dataSearch });
  };

  onChangeSelectDateValid = (date, dateString) => {
    const dataSearch = { ...this.state.dataSearch, 'created_at': dateString };
    this.setState({ dataSearch });
  };

  onChangeDataSearchStatus = e => {
    const dataSearch = { ...this.state.dataSearch, status: e };
    this.setState({ dataSearch });
  };

  onChangeDataSearchUserId = e => {
    const dataSearch = { ...this.state.dataSearch, user_id: e };
    this.setState({ dataSearch });
  };

  onSubmitSearchButton = async e => {
    e.preventDefault();
    let { dataSearch } = this.state;
    const queryString = convertJsonToQueryString(dataSearch);
    this.props.history.push({
      pathname: this.props.match.url,
      search: queryString
    });
  };

  onClickUpdateButton = data => {
    this.props.history.push(`${this.props.match.url}/cap-nhat/${data.id}`);
  };

  componentDidMount() {
    this.getUserCreated(TYPE_BUSINESS_EMPLOYEE);
    const queryString = queryStringToJSON(this.props.location.search);
    this.setState({
      dataSearch: queryString
    });
    this.getAgency();
  }


  getUserCreated = async e => {
    const service = new UserService();
    const result = await service.getUserByType(e);
    if (result && result.status === 200) {
      this.setState({
        userManager: result.data
      } as any);
    }
  };

  //Lấy dữ liệu cộng tác viên hoặc đại lý
  getAgency = async () => {
    const userService = new UserService();
    const lst = await userService.getAgencyContributor(7);
    if (lst) {
      let agency = {};
      lst.data.map(value => (agency[value.id] = value.fullname));
      this.setState({ agency });
    }
  };

  render() {
    return (
      <div className="card">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="card-body">
          <h4 className="card-title1">{TITLE}</h4>
          <form
            onSubmit={this.onSubmitSearchButton}
            className="search-form-index"
          >
            <div className="input-group">
              <SelectBoxAgency
                options={this.state.agency}
                label=""
                name="owner_id"
                wrappedClass="col-md-3 form-group nopadding-left"
                onChange={this.handleChangeBoxAgency}
                value={this.state.dataSearch.owner_id}
                firstOptionLabel="Tất cả đại lý"
                isRequired={false}
              />
              <SelectBox
                value={this.state.dataSearch.status}
                label=""
                onChange={this.onChangeDataSearchStatus}
                name="status"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={Type.STATUS}
                firstOptionLabel="Tất cả trạng thái"
              />
              <SelectUserManager
                label=""
                onChange={this.onChangeDataSearchUserId}
                value={this.state.dataSearch.user_id}
                name="user_id"
                wrappedClass="col-md-2 form-group nopadding-left"
                options={this.state.userManager}
                firstOptionLabel="Người tạo"
              />
              <SelectDate
                  label=""
                  name="created_at"
                  wrappedClass="col-md-2 form-group nopadding-left"
                  onChange={this.onChangeSelectDateValid}
                  value={this.state.dataSearch.created_at ? this.state.dataSearch.created_at : ''}
                  isRequired={true}
                  placeholder="Ngày tạo"
              />
              <div className="col-md-2 form-group nopadding-left">
                <SearchButton onClick={this.onSubmitSearchButton} />
              </div>
            </div>
          </form>
          <AgencyTable
            location={this.props.location}
            history={this.props.history}
            match={this.props.match}
            user={this.props.user}
          />
        </div>
      </div>
    );
  }
}

const pageWithNavBar = withNavbar()(PageIndex);

export default pageWithNavBar;
