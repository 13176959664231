import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import {RouteComponentProps} from "react-router-dom";
import CateContractService from "../../../../services/category-contract/CateContractService";
import ModalView from "../../../common/form/Modal";
import RequestChangeInfoService from "../../../../services/request-change-info/RequestChangeInfoService";
// import DenyButton from "../../../common/button/DenyButton";
import TextAreaWithLabel from "../../../common/form/TextAreaWithLabel";
// import SubmitButton from "../../../common/form/SubmitButton";
import ModalDeny from "../../../common/form/ModalDeny";
import {Card, Table} from "antd";
// import {checkPermission} from "../../../../helpers/NewCaHelper"

// const confirm_request = 'confirm-request-change-info-owner-ad'
interface FormValues {
    status:number,
    reason_newca: string,
    reason_customer: string,
    data_contact: any,
    fullname:'',
    tax_code:'',
    sortname:'',
    code:'',
    deputy:'',
    deputy_position:'',
    phone:'',
    email:'',
    address:'',
    office_address:'',
    field_id:'',
    personnal_scale:'',
    competitive_area:'',
    supply_capacity:'',
    bank_number:'',
    bank_branch:'',
    file:'',
}

interface OtherProps extends RouteComponentProps<{ id: string; }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const RequestChangeInfoViewAgency: React.FC<Props> = props => {
    const service = new RequestChangeInfoService();
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    const {
        status,
        reason_newca,
        reason_customer,
        data_contact,
        fullname,
        tax_code,
        sortname,
        code,
        deputy,
        deputy_position,
        phone,
        email,
        address,
        office_address,
        field_id,
        personnal_scale,
        competitive_area,
        supply_capacity,
        bank_number,
        bank_branch,
        file,
    } = props.values;

    const onClickModalFile = async pathFile => {
        const result = await service.disPlayPdfFile(pathFile);
        if (result && result.status === 200) {
            setModal({
                ...modal,
                'base64': result.base64,
                'visible_modal': true,
                'loading': true,
                'title_modal': 'file',
                'type_file': 'pdf'
            });
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra!");
    };

    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const [modalDeny, setModalDeny] = useState({
        visible_modal_deny: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
    });

    const id = props.match.params.id;
    const onFetchData = async () => {
        const result = await service.findAgency(id);
        props.setFieldValue('data_contact', result.data.data_contract);
        for (let key in result.data) {
            props.setFieldValue(key, result.data[key]);
        }
        for (let key in result.data.change_info_detail_agency) {
            props.setFieldValue(key, result.data.change_info_detail_agency[key]);
        }
        props.setFieldValue("tax_code", result.data.agency ? result.data.agency.tax_code : "");
        props.setFieldValue("code", result.data.agency ? result.data.agency.code : "");
    };
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);
    const handleModalDenyCancel = () => {
        setModalDeny({...modalDeny, 'visible_modal_deny': false})
    };
    const confirmDeny = async () => {
        if (modalDeny.text_deny.trim() === "") {
            setModalDeny({...modalDeny, 'error_text_deny': "Lý do từ chối không được trống"});
            return false;
        }
        const data = {data: modalDeny.text_deny, id: id};
        const result = await service.getDeny(data);
        await setModalDeny({...modalDeny, 'visible_modal_deny': false});
        if (result && result.status === 200) {
            onSuccessAction("Từ chối thành công!", () => {
                props.history.push("/yeu-cau-thay-doi-thong-tin");
            });
        } else if (result && result.status === 404) {
            onFailAction("Có lỗi xảy ra trong quá trình từ chối!");
        } else {
            onFailAction(result.error);
        }
    };

    const onChangeDataDeny = ({target: {value}}) => {
        setModalDeny({...modalDeny, 'text_deny': value});
    };
    // const submitDeny = () => {
    //     setModalDeny({...modalDeny, 'visible_modal_deny': true});
    // };
    // const submitApprove = async () => {
    //     const result = await service.getApprove(id);
    //     if (result && result.status === 200) {
    //         onSuccessAction("Duyệt thành công!", () => {
    //             props.history.push("/yeu-cau-thay-doi-thong-tin");
    //         });
    //     } else if (result && result.status === 404) {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     } else {
    //         onFailAction("Có lỗi xảy ra trong quá trình duyệt!");
    //     }
    // };
    const columns = [
        {
            title: "STT",
            width: 70,
            dataIndex: "key",
            key: "key",
            fixed: true
        },
        {
            title: "Đầu mối phối hợp",
            width: 250,
            dataIndex: "type_name",
            key: "type_name",
            fixed: true,
            render: text => <b>{text}</b>
        },
        {
            title: "Họ tên",
            dataIndex: "name",
            key: "name",
            width: "auto"
        },
        {
            title: "Chức vụ",
            dataIndex: "position",
            key: "position",
            width: "auto"
        },
        {
            title: "Số điện thoại",
            dataIndex: "phone",
            key: "phone",
            width: "auto"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "auto"
        }
    ];
    return (
        <div className="card">
            <ModalView
                visible={modal.visible_modal}
                handleCancel={handleModalCancel}
                title={modal.title_modal}
                value={modal.base64}
                type_file={modal.type_file}
                loading={modal.loading}
            />
            <ModalDeny
                visible={modalDeny.visible_modal_deny}
                handleCancel={handleModalDenyCancel}
                handleDeny={confirmDeny}
                value={modalDeny.text_deny}
                onChange={onChangeDataDeny}
                error={modalDeny.error_text_deny}
            />
            <h4 className="card-title1 ml-3">Yêu cầu thay đổi thông tin đại lý</h4>
            <div className="card-body">
                <form>
                    {status === 2 ? (
                        <div className="input-group">
                            <InputWithLabel
                                name={""}
                                label="Lý do từ chối"
                                isDisabled={true}
                                wrapClass="col-md-12 text-danger"
                                value={reason_newca}
                                onChangeValue={""}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <Card className="m-r-15-i m-l-15-i" title="Thông tin đại lý" size="small">
                        <div className="input-group">
                            <TextAreaWithLabel
                                name={"reason_customer"}
                                wrapClass="col-md-12"
                                label="Lý do thay đổi thông tin"
                                onChangeValue={""}
                                value={reason_customer}
                                isDisabled={true}
                                rows={4}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                label="Tên đại lý"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={fullname}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Mã số thuế"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={tax_code}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Tên viết tắt"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={sortname}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Mã đại lý"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={code}
                                onChangeValue={""}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                label="Người đại diện"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={deputy}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Chức vụ"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={deputy_position}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Email"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={email}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Số điện thoại"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={phone}
                                onChangeValue={""}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                label="Trụ sở chính"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={address}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Văn phòng giao dịch"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={office_address}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Lĩnh vực hoạt động"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={field_id}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Quy mô nhân sự"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={personnal_scale}
                                onChangeValue={""}
                            />
                        </div>
                        <div className="input-group">
                            <InputWithLabel
                                label="Năng lực cung cấp CTS/tháng"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={supply_capacity}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Khu vực thị trường"
                                name={""}
                                wrapClass="col-md-3"
                                isDisabled={true}
                                value={competitive_area}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Số tài khoản"
                                name={""}
                                wrapClass="col-md-2"
                                isDisabled={true}
                                value={bank_number}
                                onChangeValue={""}
                            />
                            <InputWithLabel
                                label="Ngân hàng"
                                name={""}
                                wrapClass="col-md-2"
                                isDisabled={true}
                                value={bank_branch}
                                onChangeValue={""}
                            />
                            {(file ?
                                <div className="col-md-2">
                                    <label className="invisible">123123123123</label>
                                    <button type="button" className="form-control btn btn-primary p-b-b-3"
                                            onClick={() => onClickModalFile(file)}>
                                        <i className="fas fa-file-pdf"/> File giấy phép ĐKKD
                                    </button>
                                </div>
                                : "")}
                        </div>
                        <div className="col-md-12">
                            <Table
                                rowKey={(record, index) => {
                                    return index.toString();
                                }}
                                columns={columns}
                                dataSource={
                                    Object.keys(data_contact).length !== 0 ? data_contact : []
                                }
                                scroll={{ x: 1200 }}
                                pagination={false}
                                className="form-group ant-table-bordered"
                            />
                        </div>
                    </Card>
                    <div className="input-group d-flex justify-content-center p-5">
                        {/*{(status === 1 && checkPermission(confirm_request)) */}
                        {/*    ? <SubmitButton onClick={submitApprove} text="Duyệt" iconClass="fas fa-check"/>*/}
                        {/*    : " "*/}
                        {/*}*/}
                        {/*{status === 1 && checkPermission(confirm_request) */}
                        {/*    ? <DenyButton onClick={submitDeny}/>*/}
                        {/*    : " "*/}
                        {/*}*/}
                        <BackButton
                            history={props.history}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        status:0,
        reason_newca:'',
        reason_customer:'',
        data_contact: {},
        fullname:'',
        tax_code:'',
        sortname:'',
        code:'',
        deputy:'',
        deputy_position:'',
        phone:'',
        email:'',
        address:'',
        office_address:'',
        field_id:'',
        personnal_scale:'',
        competitive_area:'',
        supply_capacity:'',
        bank_number:'',
        bank_branch:'',
        file:'',
    }),
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new CateContractService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.goBack();
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(RequestChangeInfoViewAgency);

export default withNavbar()(RenderCreateForm);
