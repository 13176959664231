import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import SelectDate from "../../../common/form/SelectDate";
import SelectSwitch from "../../../common/form/SelectSwitch";
import { History } from "history";
import * as Yup from "yup";
import DocumentService from "../../../../services/document/DocumentService";
import InputFileUpload from "../../../common/form/InputFileUpload";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";
import { loading } from "../../../../components/common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";

interface Props {
  history: History;

}
interface State {
  name: string;
  number: string;
  type: string;
  type1: boolean;
  type2: boolean;
  base_64: any;
  file_name: string;
  valid_at: string;
  release_at: string;
}
class CreateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    type1: true,
    type2: true,
    filename: '',
  };

  onChangeSelectDateValid = (date, dateString) => {
    this.props.setFieldValue("valid_at", dateString);
  };

  onChangeSelectDateRelease = (date, dateString) => {
    this.props.setFieldValue("release_at", dateString);
  };

  onChangeSystem1 = e => {
    this.props.setFieldValue("type1", e);
  }

  onChangeSystem2 = e => {
    this.props.setFieldValue("type2", e);
  }

  onChangeFile = async e => {
    const files = e.target.files[0];
    if (files) {
      const formData = new FormData();
      formData.append("file", files);
      loading.runLoadingBlockUI();
      const fileData = await BaseServices.axiosUpLoadFile(formData);
      this.props.setFieldValue("file_name", fileData.file);
      this.setState({
        filename: files.name
      });

      loading.stopRunLoading();
    } else {
      this.setState({
        filename: ''
      });
      this.props.setFieldValue("file_name", '');
    }

  };

  onSetValue = () => {

  }

  render() {
    const {
      name,
      number,
      valid_at,
      release_at,
      type1,
      type2,
    } = this.props.values;

    return (
      <form>
        <div className="input-group">
          <SelectSwitch
            label="Hiện thị ở Admin"
            name="type2"
            wrappedClass="col-md-2 form-group"
            onChange={this.onChangeSystem1}
            value={type1}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={false}
          />
          <SelectSwitch
            label="Hiện thị ở QLBH"
            name="type1"
            wrappedClass="col-md-2 form-group"
            onChange={this.onChangeSystem2}
            value={type2}
            firstOptionLabel="Chọn loại người dùng"
            error={this.props.errors.type}
            isRequired={false}
          />
          <InputWithLabel
            name="name"
            label="Tên tài liệu"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
          />
          <InputWithLabel
            name="number"
            label="Số tài liệu"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={number}
            error={this.props.errors.number}
            isRequired={true}
          />
        </div>
        <div className="input-group">
        <SelectDate
            label="Ngày phát hành"
            name="release_at"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateRelease}
            value={release_at}
            error={this.props.errors.release_at}
            isRequired={false}
          />
          <SelectDate
            label="Ngày hiệu lực"
            name="valid_at"
            wrappedClass="col-md-3 form-group"
            onChange={this.onChangeSelectDateValid}
            value={valid_at}
            error={this.props.errors.valid_at}
            isRequired={false}
          />
          <InputFileUpload
            name="file_name"
            classWrapped="col-md-6 form-group"
            label="File tài liệu"
            filename={this.state.filename}
            onChangeFileUpload={this.onChangeFile}
            isRequired={false}
    />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url='/tai-lieu'/>
          <SubmitButton onClick={this.props.handleSubmit}/>
        </div>
      </form>
    );
  }
}

const RenderCreateForm = withFormik<Props, State>({
  mapPropsToValues: () => ({
    name: "",
    number: "",
    type: "",
    type1: true,
    type2: true,
    base_64: "",
    file_name: "",
    valid_at: "",
    release_at: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên tài liệu không được bỏ trống."),
    number: Yup.string().required("Số tài liệu không được bỏ trống.")
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const user = new DocumentService();
    const result = await user.createDocument(values);
    if (result) {
      if (result.status === 200) {
        onSuccessAction('Thêm mới tài liệu thành công!', function () {
          props.history.goBack();
        })
      } else if (result.status === 1) {
        setErrors(result.errors);
      } else {
        onFailAction(result.errors);
      }
    }
  }
})(CreateForm);

export default RenderCreateForm;
