import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import * as Yup from "yup";
import ProvinceService from "../../../../services/province/ProvinceService";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";
import SelectProvince from "../../../common/form/SelectProvince";
import TaxDepartmentService from "../../../../services/taxdepartment/TaxDepartmentService";

interface Props {
  history: History;
}
interface State {
  fullname: string;
  code: string;
  short_name: string;
  province_code: string
}

class CreateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    type: "",
    province_code: "",
    province: {}
  };
  componentDidMount() {
    this.getProvince();
  }
  getProvince = async () => {
    const service = new ProvinceService();
    const result = await service.getProvinceList();
    if(result && result.status===200){
      this.setState({
        province:  result.data
      } as any);
    }
  }


  render() {
    const {
      fullname,
      code,
      short_name,
      province_code
    } = this.props.values;
    return (
      <form>
        <div className="input-group">
          <InputWithLabel
            name="code"
            label="Mã chi cục thuế"
            wrapClass="col-md-2 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
          />
          <InputWithLabel
            name="fullname"
            label="Tên chi cục thuế"
            wrapClass="col-md-4 form-group"
            onChangeValue={this.props.handleChange}
            value={fullname}
            error={this.props.errors.fullname}
            isRequired={true}
          />
          <InputWithLabel
            name="short_name"
            label="Tên viết tắt"
            wrapClass="col-md-2 form-group"
            onChangeValue={this.props.handleChange}
            value={short_name}
            error={this.props.errors.short_name}
          />
          <SelectProvince
            label="Tỉnh thành"
            onChange={this.props.handleChange}
            value={province_code}
            error={this.props.errors.province_code}
            name="province_code"
            wrappedClass="col-md-4 form-group nopadding-left"
            options={this.state.province}
            firstOptionLabel="Lựa chọn tỉnh thành"
            isRequired={true}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton
              history={this.props.history}
              // url='/chi-cuc-thue'
          ></BackButton>
          <SubmitButton onClick={this.props.handleSubmit}></SubmitButton>
        </div>
      </form>
    );
  }
}

const CreateFormFor = withFormik<Props, State>({
  mapPropsToValues: (props: Props) => ({
    fullname: "",
    code: "",
    short_name: "",
    province_code : ""
  }),
  validationSchema: Yup.object().shape({
    fullname: Yup.string().required("Tên chi cục thuế không được bỏ trống."),
    code: Yup.string().required("Mã chi cục thuế không được bỏ trống."),
    province_code: Yup.string().required("Mã tỉnh thành không được bỏ trống."),
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props,setErrors }) => {
    setSubmitting(false);
    const service = new TaxDepartmentService();
    const result = await service.createTaxDepartment(values);
    if (result) {
      if(result.status===200){
        onSuccessAction('Thêm mới chi cục thuế thành công!',function(){
          props.history.goBack();
        })
      }else if(result.status===1){
        setErrors(result.errors);
      }else{
        onFailAction(result.errors);
      }
    }
  }
})(CreateForm);

export default CreateFormFor;
