export enum StatusEnum {
  ACTIVE = "0",
  STOP = "1",
  DEACTIVE = "2"
}

export class TypeService {
  static readonly STATUS = {
    1: "Đang hoạt động",
    2: "Tạm dừng",
  };
  static readonly badgeClass = {
    1: "badge-primary",
    2: "badge-success"
  };

}

export const STATUS_LABEL_CLASS = {
  1: {
    label: "Đang hoạt động",
    class: "badge-primary"
  },
  2: {
    label: "Tạm dừng",
    class: "badge-danger"
  }
};
