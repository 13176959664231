import React, { useState, useEffect } from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import { withFormik, FormikProps } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import { TypeWhToken } from "../Enum";
import { Input } from "antd";
import SelectDate from "../../../common/form/SelectDate";
import SelectBoxAgency from "../../../common/form/SelectBoxAgency";
import UserService from "../../../../services/user/UserService";
import SelectBox from "../../../common/form/SelectBox";
import { getDateCurrent } from "../../../../helpers/NewCaHelper";
import WhTokenExportService from "../../../../services/whTokenExport/WhTokenExportService";
import { onSuccessAction } from "../../../../helpers/SwalCommon";
import { onFailAction } from "../../../../helpers/SwalCommon";
import { RouteComponentProps } from "react-router-dom";
import AssetList from "./AssetList";

const { TextArea } = Input;

interface FormValues {
  code: string;
  note: string;
  exporter: string;
  exporter_pos: string;
  type: string;
  location: string;
  receiver: string;
  export_date: string;
  created_date: string;
  owner_id: string;
  belong_to: string;
  dataToken: object;
  dataDoc: object;
  dataGift: object;
}

interface OtherProps
  extends RouteComponentProps<{
    id: string;
  }> {}

type Props = OtherProps & FormikProps<FormValues>;

const WhTokenCreate: React.FC<Props> = (props) => {
  const id = props.match.params.id;
  const [agency, setAgency] = useState({});
  const [dataDocState, setDataDocState] = useState({
    data: [],
  });
  const [dataGiftState, setDataGiftState] = useState({
    data: [],
    total: "",
  });

  const {
    code,
    note,
    exporter,
    location,
    receiver,
    export_date,
    owner_id,
    belong_to,
  } = props.values;

  const getAgency = async (type) => {
    const userService = new UserService();
    const lst = await userService.getAgencyContributor(type);
    if (lst) {
      let agency = {};
      lst.data.map((value) => (agency[value.id] = value.fullname));
      setAgency(agency);
    }
  };

  const fetchCodeExport = async (date) => {
    const getCodeService = new WhTokenExportService();
    const result = await getCodeService.getCode(date);
    if (result && result.status === 200) {
      props.setFieldValue("code", result.data);
    }
  };

  useEffect(() => {
    const date = getDateCurrent("-");
    fetchCodeExport(date);
    onFetchData();
    // eslint-disable-next-line
  }, []);

  const onFetchData = async () => {
    const service = new WhTokenExportService();
    const result = await service.getDetail(id);
    for (let key in result.data) {
      props.setFieldValue(key, result.data[key]);
    }
    let type = 7;
    if (Number(result.data.belong_to) === 2) type = 8;
    getAgency(type);

    if (result.data.wh_export_detail) {
      const dataDetail = result.data.wh_export_detail;

      const dataDocTemp = [] as any;
      const dataGiftTemp = [] as any;
      for (let key in dataDetail) {
        if (dataDetail[key].type === 2) {
          const temp2 = {};
          temp2["key"] = `doc` + key;
          temp2["doc_asset_id"] = dataDetail[key].wh_document_id;
          temp2["doc_asset_name"] = dataDetail[key].doc_category.name;
          temp2["doc_unit"] = dataDetail[key].unit;
          temp2["doc_amount"] = dataDetail[key].total_document;
          temp2["doc_price"] = 0;
          temp2["doc_total"] = 0;
          dataDocTemp.push(temp2);
        }

        if (dataDetail[key].type === 3) {
          const temp3 = {};
          temp3["key"] = `gift` + key;
          temp3["gift_asset_id"] = dataDetail[key].wh_gift_id;
          temp3["gift_asset_name"] = dataDetail[key].gift_category.name;
          temp3["gift_unit"] = dataDetail[key].unit;
          temp3["gift_amount"] = dataDetail[key].total_gift;
          temp3["gift_price"] = (dataDetail[key].price ? dataDetail[key].price : 0) ;
          temp3["gift_total"] = 0;
          dataGiftTemp.push(temp3);
        }
      }

      setDataDocState({
        ...dataDocState,
        data: dataDocTemp,
      });
      setDataGiftState({
        ...dataGiftState,
        data: dataGiftTemp,
        total: result.data.wh_export_total_price,
      });
    }
  };

  return (
    <div className="card">
      <h4 className="card-title1 ml-3">Xem chi tiết phiếu xuất kho tài sản</h4>
      <div className="card-body">
        <form>
          <div className="input-group">
            <InputWithLabel
              name="code"
              label="Số hóa đơn"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={code}
              error={props.errors.code}
              isRequired={true}
              maxLength={255}
              isDisabled={true}
            />
            <SelectBox
              options={TypeWhToken.TYPEAGENCY}
              label="Loại người dùng"
              name="belong_to"
              wrappedClass="col-md-3"
              value={belong_to}
              firstOptionLabel="Lựa chọn"
              error={props.errors.belong_to}
              isRequired={true}
              isDisabled={true}
            />
            <SelectBoxAgency
              options={agency}
              label="Đại lý/cộng tác viên"
              name="owner_id"
              wrappedClass="col-md-3"
              value={owner_id}
              firstOptionLabel="Chọn đại lý/cộng tác viên"
              error={props.errors.owner_id}
              isRequired={true}
              isDisabled={true}
            />
            <InputWithLabel
              name="exporter"
              label="Người xuất kho"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={exporter}
              error={props.errors.exporter}
              isRequired={true}
              maxLength={255}
              isDisabled={true}
            />
          </div>
          <div className="input-group">
            <InputWithLabel
              name="receiver"
              label="Người nhận hàng"
              wrapClass="col-md-3"
              onChangeValue={props.handleChange}
              value={receiver}
              error={props.errors.receiver}
              isRequired={true}
              maxLength={255}
              isDisabled={true}
            />
            <InputWithLabel
              name="location"
              label="Tại kho"
              wrapClass="col-md-6"
              onChangeValue={props.handleChange}
              value={location}
              error={props.errors.location}
              isRequired={false}
              maxLength={255}
              isDisabled={true}
            />
            <SelectDate
              label="Ngày xuất kho"
              name="export_date"
              wrappedClass="col-md-3"
              value={export_date}
              error={props.errors.export_date}
              isRequired={true}
              placeholder={"yyyy-mm-dd"}
              isDisabled={true}
            />
          </div>
          <div className="input-group">
            <div className="col-md-12">
              <label>Về việc</label>
              <TextArea
                name="note"
                placeholder="Ghi chú"
                autoSize={{ minRows: 4, maxRows: 10 }}
                className="form-control"
                required={false}
                value={note}
                onChange={props.handleChange}
              />
            </div>
          </div>

          {Object.keys(dataGiftState.data).length !== 0 ? (
            <div className="input-group col-md-12 m-t-20">
              <div className="col-md-11 p-l-0">
                <h5>Tài sản</h5>
              </div>
              <div className="col-md-12 p-l-0">
                <AssetList data={dataGiftState.data} total={dataGiftState.total} />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="input-group d-flex justify-content-center p-5">
            <BackButton history={props.history}></BackButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const RenderCreateForm = withFormik<Props, FormValues>({
  mapPropsToValues: () => ({
    code: "",
    note: "",
    exporter: "",
    exporter_pos: "",
    type: "",
    location: "",
    receiver: "",
    export_date: "",
    created_date: "",
    owner_id: "",
    belong_to: "",
    dataToken: {},
    dataDoc: {},
    dataGift: {},
  }),
  validationSchema: Yup.object().shape({
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props, setErrors }) => {
    setSubmitting(false);
    const service = new WhTokenExportService();
    const result = await service.store(values);
    if (result && result.status === 200) {
      onSuccessAction("Nhập kho token thành công!", function () {
        props.history.goBack();
      });
    } else if (result.status === 422) {
      setErrors(result.error);
    } else {
      onFailAction(result.error);
    }
  },
})(WhTokenCreate);

export default withNavbar()(RenderCreateForm);
