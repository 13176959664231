import React from "react";

interface Props {
  onClick: () => void;
}

export default function ActionButtonUnLock(props: Props) {
  return (
    // eslint-disable-next-line
    <a
      className="pointer text-success ml-1 mr-1"
      title="Mở khóa"
      onClick={props.onClick}
    >
      <i className="fas fa-lock-open fa-lg" />
    </a>
  );
}
