import React, {useEffect,useState} from "react";
import {Card, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import {match} from "react-router";
import _ from "lodash";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import SelectWithLabel from "../../../common/form/SelectWithLabel";
import PageWrapper from "../../wrapper/PageWrapper";
import {loading} from "../../../common/loading/Loading";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import InputWithLabel from "../../../common/form/label/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import CustomerService from "../../../../services/customer/CustomerService";
import SubmitButton from "../../../common/form/SubmitButton";
import InputUploadFile from "../../../common/form/input-file-with-label/InputUploadFile";
import RequestChangeInfoCertificateService
    from "../../../../services/request-change-info-certificate/RequestChangeInfoCertificateService";
import ModalView from "../../../common/form/Modal";

interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    user: any;
    history: any;
}
const ChangeInfoCertGroupUpdate: React.FC<Props> = props => {
    const service = new CustomerService();
    const id = props.match.params.id;
    const [typeChange, setTypeChange] = useState(true);
    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [uIdRequestChange, setuIdRequestChange] = useState(0);
    const [modal, setModal] = useState({
        visible_modal: false,
        loading: false,
        title_modal: "",
        base64: "",
        type_file: "",
        text_deny: "",
        error_text_deny: "",
        type_contract: ""
    });

    const fetchCustomer = async () => {
        const result = await service.getModelToUpdate(id,1);
        setuIdRequestChange(result.data.uid);
        const districts = await service.getDistrictByProvince(result.data.province_code);
        setDistrict(_.mapValues(_.keyBy(districts, "newtel_district_code"), "fullname"));
        props.form.setFieldsValue({
            uid: result.data.uid.toString(),
            identity_type : result.data.identity_type.toString(),
            code: result.data.code,
            fullname: result.data.fullname,
            email: result.data.email,
            address: result.data.address,
            represent_fullname: result.data.represent_fullname,
            contact_name: result.data.contact_name,
            contact_position: result.data.contact_position,
            contact_phone: result.data.contact_phone,
            contact_mobile: result.data.contact_mobile,
            contact_email: result.data.contact_email,
            contact_fax: result.data.contact_fax,
            contact_address: result.data.contact_address,
            change_type: result.data.type_change.toString(),
            province_code: result.data.province_code.toString(),
            district_code: result.data.district_code.toString(),
        });
        if (Number(result.data.type) === 1){
            if (Number(result.data.identity_type) === 1){
                setLabelCode("Mã số thuế");
            }if (Number(result.data.identity_type) === 2){
                setLabelCode("Mã ngân sách");
            }if (Number(result.data.identity_type) === 3){
                setLabelCode("Số quyết định");
            }if (Number(result.data.identity_type) === 4){
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(result.data.identity_type) === 5){
                setLabelCode("Giáy phép đầu tư");
            }
        }
    };

    const onChangeContractType = async value =>{
        if (value === "1") {
            setTypeChange(true);
        } else {
            setTypeChange(false);
        }
    };
    const getProvince = async () => {
        const provinces = await service.getProvince();
        setProvince(_.mapValues(_.keyBy(provinces, "newtel_code"), "fullname"));
    };
    const onChangeProvince = async (e) =>{
        if(e){
            const districts = await service.getDistrictByProvince(e);
            setDistrict(_.mapValues(_.keyBy(districts, "newtel_district_code"), "fullname"));
        }
    };
    const onChangeIdenType = async (e) => {
        if (e){
            if (Number(e) === 1){
                setLabelCode("Mã số thuế");
            }if (Number(e) === 2){
                setLabelCode("Mã ngân sách");
            }if (Number(e) === 3){
                setLabelCode("Số quyết định");
            }if (Number(e) === 4){
                setLabelCode("Mã bảo hiểm xã hội");
            }if (Number(e) === 5){
                setLabelCode("Giấy phép đầu tư");
            }
        }
    };
    useEffect(() => {
        fetchCustomer();
        getProvince();
        // eslint-disable-next-line
    }, []);
    const onDisplayContractFile = async () => {
        // const agencyService = new AgencyService();
        // const valuesForm = this.props.form.getFieldsValue();
        // this.setState({ loading: true, visible: true });
        // try {
        //   let contract = await agencyService.getAgencyContract(valuesForm);
        //   this.setState({
        //     file: contract.data.file
        //   });
        // } catch (error) {
        //   onFailAction("Có lỗi xảy ra khi xem hợp đồng!");
        // } finally {
        //   this.setState({
        //     loading: false
        //   });
        // }
    };
    const update = status => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const val= {
                        ...values,
                        status,
                        request_uid:uIdRequestChange,
                    };

                    const data = await service.updateRequestChangeInfoOrganization(val);
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi yêu cầu !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else {
                        onSuccessAction("Yêu cầu thành công", () => {
                            props.history.push("/danh-sach-khach-hang");
                        });
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra!");
                } finally {
                    loading.stopRunLoading();
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onClickModalFile = async type => {
        const services = new RequestChangeInfoCertificateService();
        const result = await services.getFile(id, type);
        if (result && result.status === 200) {
            setModal({...modal,'base64': result.base64, 'visible_modal': true, 'loading': true, 'title_modal': 'File','type_file': 'pdf'}) ;
            return true;
        } else {
            onFailAction("Không tìm thấy file");
        }
        onFailAction("Có lỗi xảy ra khi lấy file");
    };
    const handleModalCancel = () => {
        setModal({...modal, 'visible_modal': false});
    };
    return (
        <PageWrapper title="Cập nhật yêu cầu điều chỉnh thông tin CTS tổ chức">
            <Form>
                <ModalView
                    visible={modal.visible_modal}
                    handleCancel={handleModalCancel}
                    title={modal.title_modal}
                    value={modal.base64}
                    type_file={modal.type_file}
                    loading={modal.loading}
                />
                <Card className="m-r-15-i m-l-15-i" title={<label>Thông tin điều chỉnh</label>} size="small">
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Mã khách hàng"
                            name="uid"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={true}
                        />
                        <SelectWithLabel
                            options={{
                                1: 'Mã số thuế',
                                2: 'Mã ngân sách',
                                3: 'Số quyết định',
                                4: 'Mã bảo hiểm xã hội',
                                5: 'Giấy phép đầu tư'
                            }}
                            name="identity_type"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            onChange={onChangeIdenType}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            wrapClass="col-md-3"
                            maxLength={16}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Người đại diện"
                            name="represent_fullname"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            maxLength={255}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên khách hàng"
                            name="fullname"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-3"
                            isDisabled={false}
                            maxLength={16}
                            isRequired={true}
                        />
                        <InputUploadFile
                            classWrapped="col-md-3"
                            label="Tải file CMND/Hộ chiếu người đại diện"
                            name="file_present_passport"
                            form={props.form}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                            onClickDownloadFile={()=>onClickModalFile(1)}
                        />
                        <InputUploadFile
                            classWrapped="col-md-3"
                            label="Tải file giấy tờ pháp lý"
                            name="file_legal"
                            form={props.form}
                            extentionsAllow={['pdf']}
                            accept={".pdf"}
                            note={true}
                            onClickDownloadFile={()=>onClickModalFile(2)}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isRequired={true}
                            onChange={onChangeProvince}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-3"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-6"
                            isDisabled={false}
                            maxLength={255}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-1" title={<label>Thông tin liên hệ</label>} size="small">
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="contact_name"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="contact_position"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="contact_phone"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số di động"
                            name="contact_mobile"
                            wrapClass="col-md-3"
                            maxLength={20}
                            minLength={9}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="contact_email"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                            isRequired={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Fax"
                            name="contact_fax"
                            wrapClass="col-md-3"
                            maxLength={255}
                            isDisabled={false}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="contact_address"
                            wrapClass="col-md-6"
                            maxLength={255}
                            isDisabled={false}
                        />
                    </div>
                </Card>
                <Card className="m-r-15-i m-l-15-i mt-1" title={<label>File điều chỉnh thông tin</label>} size="small">
                    <div className="input-group">
                        <SelectWithLabel
                            options={{
                                1: "Ký điện tử",
                                2: "Ký tươi"}}
                            form={props.form}
                            label="Loại điều chỉnh"
                            name="change_type"
                            isRequired={true}
                            wrappedClass="col-md-2"
                            defaultValue={""}
                            isDisabled={false}
                            placeholder="Chọn loại điều chỉnh"
                            onChange={onChangeContractType}
                        />
                        {typeChange === true ?
                            <div className="form-group col-md-2">
                                <button
                                    onClick={onDisplayContractFile}
                                    className="btn ml-5 mt-4 btn-primary btn-small"
                                    disabled={!typeChange}
                                    hidden={typeChange}
                                >
                                    DC 01.01
                                </button>
                            </div>
                            :
                            <InputUploadFile
                                classWrapped="col-md-4"
                                label="File yêu cầu điều chỉnh thông tin"
                                name="file_change_info"
                                form={props.form}
                                isRequired={!typeChange}
                                extentionsAllow={['pdf']}
                                accept={".pdf"}
                                isDisabled={typeChange}
                            />
                        }
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5">
                <SubmitButton onClick={() => update(1)} text="Trình duyệt"
                              iconClass="fas fa-check"/>
                <BackButton
                    history={props.history}
                    // url="/danh-sach-khach-hang"
                />
            </div>
        </PageWrapper>
    );
};

const WrappedChangeInfoCertGroupUpdate = Form.create<Props>({
    name: "ChangeInfoCertGroupUpdate"
})(ChangeInfoCertGroupUpdate);
export default withNavbar()(WrappedChangeInfoCertGroupUpdate);