import React, { useState } from "react";
import Helmet from "react-helmet";
import Table from "../../common/table/NewTable";
import {
  onFailAction,
  onDeleteAlert,
  onSuccessAction
} from "../../../helpers/SwalCommon";
import ProviderCategoryService from "../../../services/ProviderCategory/ProviderCategoryService";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import SearchButton from "../../common/button/SearchButton";
import GoToCreatePageButton from "../../common/button/GoToCreatePageButton";
import { RouteComponentProps } from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import {
    convertJsonToQueryString,
    formatDateTime, queryStringToJSON,
    reloadPage
} from "../../../helpers/NewCaHelper";
import TableActionButton from "../user/table/action-button/TableActionButton";

interface Props extends RouteComponentProps {}
const TITLE = "Danh mục nhà cung cấp";

const ProviderCategory: React.FC<Props> = props => {
  const [dataSearch, setDataSearch] = useState({ ten: "", ma: "" });

  const fetchDataFunction = async () => {
    const service = new ProviderCategoryService();
      const queryString = queryStringToJSON(props.location.search);
    try {
      const provider = await service.list({...dataSearch,...queryString});
      return provider;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  const onChangeDataSearchInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    setDataSearch({ ...dataSearch, [name]: value });
  };

  const onSubmitSearchButton = e => {
    e.preventDefault();
      const search = queryStringToJSON(props.location.search);
      let raw = {};
      for (let key in search){
          if (key === 'raw'){
              raw['raw'] = search[key];
          }
      }
      const queryString = convertJsonToQueryString({...dataSearch, ...raw});
    props.history.push({
      search: queryString
    });
  };

  const onClickDeleteButton = id => {
    onDeleteAlert(async willDelete => {
      if (willDelete) {
        const service = new ProviderCategoryService();
        const result = await service.delete(id);
        if (result && result.status === 200) {
          onSuccessAction(
            "Xóa nhà cung cấp thành công!",
            reloadPage(props.location, props.history)
          );
        }
      }
    });
  };

  const onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickUpdate={() =>
          props.history.push(`/nha-cung-cap/cap-nhat/${record.id}`)
        }
        onClickDelete={() => onClickDeleteButton(record.id)}
      />
    );
  };

  return (
    <div className="card">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <h4 className="card-title1">{TITLE}</h4>
      <div className="card-body">
        <form onSubmit={onSubmitSearchButton} className="search-form-index">
          <div className="input-group">
            <InputWithLabel
              name="ten"
              wrapClass="col-md-4 form-group nopadding-left"
              onChangeValue={onChangeDataSearchInput}
              value={dataSearch.ten}
              isRequired={false}
              label={""}
              placeholder={"Tên nhà cung cấp hoặc mã số thuế"}
            />
            <InputWithLabel
              name="ma"
              wrapClass="col-md-2 form-group nopadding-left"
              onChangeValue={onChangeDataSearchInput}
              value={dataSearch.ma}
              isRequired={false}
              label={""}
              placeholder={"Mã nhà cung cấp"}
            />
            <div className="col-md-3 form-group">
              <SearchButton onClick={onSubmitSearchButton} />
              <GoToCreatePageButton toURL={`${props.match.url}/them-moi`} />
            </div>
          </div>
        </form>
        <Table
          columns={[
            { title: "Tên nhà cung cấp", dataIndex: "name" },
            { title: "Mã số thuế", dataIndex: "tax_code" },
            { title: "Mã", dataIndex: "code" },
            { title: "Email", dataIndex: "email" },
            {
              title: "Ngày bắt đầu hợp tác",
              dataIndex: "start_date",
              render: text => formatDateTime(text)
            },
            {
              title: "Ngày kết thúc hợp tác",
              dataIndex: "end_date",
              render: text => formatDateTime(text)
            },
            {
              title: "Tác vụ",
              render: onRenderActionColumn
            }
          ]}
          onFetchData={fetchDataFunction}
        />
      </div>
    </div>
  );
};

export default withNavbar()(ProviderCategory);
