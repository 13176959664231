import React, {} from "react";
import {RouteComponentProps} from "react-router";
import WrappedGenCertSearchForm from "./search/GenCertSearchForm";
import PageWrapper from "../wrapper/PageWrapper";
import { GenCertServices } from './../../../services/gen-cert/GenCertServices';
import Table from './../../common/tableNew/Table';
import TableActionButton from "./../../common/tableNew/action-button/TableActionButton";
import {STATUS_REQUEST, STATUS_CERTIFICATE} from "./Enum";
import { onFailAction } from './../../common/swal/SwalCommon';
import withNavbar from './../../common/HOC/NavbarContainerHOC';
import moment from "moment";
import { Tag } from "antd";
interface Props extends RouteComponentProps {
}

const GenCertCTS: React.FC<Props> = props => {
    const renderActionButton = (text, record, index) => {
        return (
            <TableActionButton
                onClickPreviewButton={() =>
                    props.history.push(`gen-cert-cts/xem/${record.id}`)
                }
            />
        );
    };

    const renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_CERTIFICATE[text].class}`}>
                {STATUS_CERTIFICATE[text].label}
            </Tag>
        );
    };
    const renderStatusRequest = (text, record, index) => {
        return (
            <Tag color={`${STATUS_REQUEST[text].class}`}>
                {STATUS_REQUEST[text].label}
            </Tag>
        );
    };
    const formatDateTime = (date: string) => {
        if (date) {
            return moment(date).format("DD/MM/YYYY");
        } else {
            return "";
        }
    };

    const columns = [
        {
            title: 'Mã định danh',
            dataIndex: '',
            render: (e) => {
                if (e) {
                    if(Number(e.object) === 1 || Number(e.object) === 2 || Number(e.object) === 3){
                        if(Number(e.customer_type) === 1){
                            return <p>{e.requestcertificate ? e.requestcertificate.organization.code : ""}</p>;
                        }else {
                            return <p>{e.requestcertificate ? e.requestcertificate.requestpersonal.passport : ""}</p>;
                        }

                    }else if(Number(e.object) === 4){
                        return <p>{e.request_change_info.code}</p>;
                    }else{
                        return <p>{e.request_renew_and_warranty.identity}</p>;
                    }
                }
            }
        },
        { title: "Mã bảo mật", dataIndex: '',
            render: (e) => {
                if(Number(e.object) === 1 || Number(e.object) === 2 || Number(e.object) === 3  ){
                    return <p>{e.requestcertificate ? e.requestcertificate.secret_code : ""}</p>;
                }else if(Number(e.object) === 4) {
                        return <p>{e.request_change_info ? e.request_change_info.secret_code : ""}</p>;
                }else {
                        return <p>{e.request_renew_and_warranty ? e.request_renew_and_warranty.secret_code : ""}</p>;
                }
            }
        },
        {
            title: 'Đối tượng',
            dataIndex: "",
            render: (e) => {
                if (e) {
                    if(Number(e.object) === 1){
                        return <p>Cấp mới</p>;
                    }else if(Number(e.object) === 2){
                        return <p>Gia hạn</p>;
                    }else if(Number(e.object) === 3){
                        return <p>Chuyển đổi</p>;
                    }else if(Number(e.object) === 4){
                        return <p>Thay đổi thông tin</p>;
                    }else if(Number(e.object) === 5){
                        return <p>Cấp lại</p>;
                    }else {
                        return <p>Bảo hành</p>;
                    }
                }
            }
        },
        {
            title: "Thời gian tạo",
            dataIndex: "created_at",
            render: (text, record, index) => formatDateTime(text)
        },
        {
            title: "Loại yêu cầu",
            dataIndex: "object", render: renderStatusRequest
        },
        {
            title: "Trạng thái",
            dataIndex: "status", render: renderStatusColumn
        },
        {
            title: "Tác vụ",
            render: renderActionButton
        }
    ];
    const fetch = async (params = {}) => {
        try {
            var data = await GenCertServices.getListIndex(params);
            console.log(data)
            return data
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };

    return (
        <PageWrapper title="Thông tin chứng thư số">
            <WrappedGenCertSearchForm/>
            <Table columns={columns} onFetchData={fetch}/>
        </PageWrapper>
    );
};
export default withNavbar()(GenCertCTS);