import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import "./pagination.css";

interface Props {
    pageRangeDisplayed?:number,
    pageCount:number,
    marginPagesDisplayed?:number,
    currentPage:number,
    onChangePage:Function,
}
interface State {}

export default class Panigation extends Component<Props, State> {
  state = {};

  render() {
    return (
      <div className="pagination pt-4 d-flex justify-content-end">
        <ReactPaginate
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          pageCount={this.props.pageCount}
          pageRangeDisplayed={7}
          marginPagesDisplayed={3}
          previousLabel="<"
          nextLabel=">"
          onPageChange={page => this.props.onChangePage(page)}
          nextClassName="page-item"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={this.props.currentPage}
        />
      </div>
    );
  }
}
