import React, { Component } from "react";
import Table from "../../../common/table/NewTable";
import { Location, History } from "history";
import { match } from "react-router";
import { onFailAction } from "../../../../helpers/SwalCommon";
import TableActionButton from "./action-button/TableActionButton";
import {LEVEL_LABEL_CLASS, STATUS_LABEL_CLASS, TYPE_LABEL_CLASS} from "../UserEnum";
import { formatDateTime } from "../../../../helpers/NewCaHelper";
import UserService from "../../../../services/user/UserService";
import { onDeleteAlert, onSuccessAction, onActiveAlert } from "../../../common/swal/SwalCommon";
import {
  reloadPage, checkPermission
} from "../../../../helpers/NewCaHelper";
import { Tag } from "antd";

interface Props {
  location: Location;
  history: History;
  match: match;
}
interface State {}
const per_active_acc = "active-user";
const per_reset_password = "reset-password";
const TITLE = 'Bạn có chắc chắn muốn xóa tài khoản này?';
export default class AgencyTable extends Component<Props, State> {
  state = {};

  componentDidMount() {
    this.fetch();
  }
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const service = new UserService();
    try {
      const result = await service.getUsers(params);
      return result;
    } catch (error) {
      console.log(error);
            onFailAction();
    }
  };

  onClickDeleteButton = (id) =>{
    const self = this;
    onDeleteAlert(async function(willDelete){
        if(willDelete){
          const user = new UserService();
          const result = await user.deleteUserById(id);
          if(result && result.status === 200){
            onSuccessAction('Xóa tài khoản thành công!',reloadPage(self.props.location,self.props.history));
          }else{
            onFailAction( result ? result.message : 'Không thể xóa người dùng')
          }
        }
    }, TITLE,'Nếu xóa sẽ không thể khôi phục !');
  }
  onClickActiveButton = (id) =>{
    const self = this;
    onActiveAlert(async function(willDelete){
      if(willDelete) {
        const user = new UserService();
        const result = await user.activeUserById(id);
        if(result && result.status === 200){
          onSuccessAction('Kích hoạt lại tài khoản thành công!', reloadPage(self.props.location,self.props.history))
        }
      }
    })
  }

  onClickResetPasswordButton = (id) =>{
    const self = this;
    onActiveAlert(async function(willDelete){
      if(willDelete) {
        const user = new UserService();
        const result = await user.resetPassword(id);
        if(result && result.status === 200){
          onSuccessAction('Reset mật khẩu thành công!', reloadPage(self.props.location,self.props.history))
        }
      }
    }, 'Bạn có chắc chắn muốn reset mật khẩu tài khoản này!')
  }

  render() {
    return (
      <Table
        columns={this.columns}
        onFetchData={this.fetch}
      />
    );
  }

  onRenderActionColumn = (text, record, index) => {
    return (
      <TableActionButton
        onClickActive={() => this.onClickActiveButton(record.id)}
        // onClickDelete={() => this.onClickDeleteButton(record.id)}
        onClickUpdate={() =>
          this.props.history.push(`/quan-ly-nguoi-dung/cap-nhat/${record.id}`)
        }
        isDisplayUpdateButton={true}
        isActive={Number(record.status)===2 && checkPermission(per_active_acc)}
        onClickResetPassword={checkPermission(per_reset_password) ? () => this.onClickResetPasswordButton(record.id): null}
      />
    );
  };

  renderStatusColumn = (text) => {
    return (
      <Tag color={`${STATUS_LABEL_CLASS[text].class}`}>
        {STATUS_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  renderTypeColumn = (text) => {
    return (
      <Tag color={`${TYPE_LABEL_CLASS[text].class}`}>
        {TYPE_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  renderLevelAgencyColumn = (text) => {
    if (text)
    return (
      <Tag color={`${LEVEL_LABEL_CLASS[text].class}`}>
        {LEVEL_LABEL_CLASS[text].label}
      </Tag>
    );
  };

  columns = [
    { title: "Tên đăng nhập", dataIndex: "username" },
    { title: "Họ tên", dataIndex: "fullname" },
    { title: "Email", dataIndex: "email" },
    { title: "Số điện thoại", dataIndex: "phone" },
    {
      title: "Loại người dùng",
      dataIndex: "type",
      render: this.renderTypeColumn
    },
    {
      title: "Cấp đại lý",
      dataIndex: "level_agency",
      render: this.renderLevelAgencyColumn
    },
    { title: "Tên đại lý/cộng tác viên", 
      render: (row) => {
        let str ="";
        if (row.type===7){
          str= row?.agency?.fullname
        }
        if (row.type===8){
          str= row?.contributor?.fullname
        }
        return <>{str}
        </>
      }

     },
    {
      title: "Thời gian tạo",
      dataIndex: "created_at",
      render: (text) => formatDateTime(text)
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      render: this.renderStatusColumn
    },
    {
      title: "Tác vụ",
      render: this.onRenderActionColumn
    }
  ];
}
