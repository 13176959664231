import React, { Component } from "react";
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import { DatePicker } from 'antd';
import "../css/main.css";
import moment from 'moment';

const dateFormat = 'DD-MM-YYYY';
interface Props {
    label: string;
    name: string;
    wrappedClass: string;
    onChange?: any;
    value: string;
    error?: any;
    isRequired?: boolean;
    isDisabled?: boolean;
    placeholder?: string;
}
interface State { }

export default class SelectDateFormat extends Component<Props, State> {
    state = {};
 
    render() {
        return (
            <div className={this.props.wrappedClass}>

                {(this.props.label) ?
                    <LabelInput
                        nameFor={this.props.name}
                        label={this.props.label}
                        isRequired={this.props.isRequired}
                    />
                    : ''}
                <div>
                    {(this.props.value !== '') ? 
                    <DatePicker 
                        key={1} 
                        onChange={this.props.onChange} 
                        value={moment(this.props.value, dateFormat)} 
                        className="full-width" placeholder={this.props.placeholder || 'Select date' } 
                        disabled={this.props.isDisabled}
                        format={dateFormat}
                        locale="vn"
                        />
                    : <DatePicker 
                        key={2} 
                        onChange={this.props.onChange} 
                        className="full-width" 
                        placeholder={this.props.placeholder || 'Select date' } 
                        disabled={this.props.isDisabled}
                        format={dateFormat}
                        locale="vn"
                        />}
                </div>
                <ValidateMessageError error={this.props.error}/>
            </div>
        );
    }
}
