import React, { Component } from "react";
import { BackTop } from 'antd';
import "../css/main.css";

interface Props {}
interface State {}

export default class BackTopClass extends Component<Props, State> {
      render() {
        return (
            <div className="">
            <BackTop />
            <strong style={{ color: 'rgba(64, 64, 64, 0.6)' }}></strong>
            </div>
        );
      }
  }