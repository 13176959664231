import FetchAPI from '../../helpers/FetchAPI';
import { TypeMethodContsantEnum } from '../../helpers/constant/MethodAPIConstant';

class ProvinceService extends FetchAPI{

    public async getProvinceList(type?){
        const result = await this.request("cate/province-all",{type},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getProvinces(data: object) {
        const path = "/cate/province"
        const result = await this.request(path, data, TypeMethodContsantEnum.GET);
        return result;
    }

    public async deleteProvinceById(id){
        const result =  await this.request("cate/province-delete",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async getProvince(id){
        const result = await this.request("cate/province-show",{id},TypeMethodContsantEnum.GET);
        return result;
    }

    public async createProvince(data){
        const result = await this.request("cate/province",data,TypeMethodContsantEnum.POST);
        return result;
    }

    public async updateProvince(data){
        const result = await this.request("cate/province-update",data,TypeMethodContsantEnum.POST);
        return result;
    }
}

export default ProvinceService;