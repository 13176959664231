import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import WrapperButtonDiv from "../../../common/form/wrapper-button-form/wrapperButtonForm";
import FieldService from "../../../../services/field/FieldService";
import swal from "sweetalert";
import PermissionService from "../../../../services/permission/PermissionService";
import { onFailAction } from "../../../common/swal/SwalCommon";

interface Props {
  id: string;
  history: History;
}
interface State {
  name: string;
  code: string;
}

class UpdateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    arr_permissions: []
  };

  getPermissionList = async type => {
    const perService = new PermissionService();
    const lstPermission = await perService.getPermissionList(type);
    if (lstPermission) {
      this.setState({ arr_permissions:lstPermission.data });
    }
  };

  componentDidMount() {
    this.getRole();
  }

  getRole = async () => {
      const service = new FieldService();
      const role = await service.getField(this.props.id);
      if (role && role.status === 200) {
        const {
          name,
          code,
        } = role.data;
        this.props.setValues({
          name,
          code
        } as Pick<State,keyof State>);
      }
  };

  render() {
    const {
      name,
      code,
    } = this.props.values;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="input-group">
          <InputWithLabel
            name="code"
            label="Mã lĩnh vực"
            wrapClass="col-md-6 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
          />
          <InputWithLabel
            name="name"
            label="Tên lĩnh vực"
            wrapClass="col-md-6 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
          />
         
        </div>
        <WrapperButtonDiv>
          <BackButton history={this.props.history} url='/linh-vuc-kinh-doanh'></BackButton>
          <SubmitButton onClick={this.props.submitForm}></SubmitButton>
        </WrapperButtonDiv>
      </form>
    );
  }
}

const userUpdateForm = withFormik<Props, State>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  mapPropsToValues: () => ({
    name: "",
    code: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên lĩnh vực không được bỏ trống."),
    code: Yup.string().required("Mã lĩnh vực không được bỏ trống."),
  }),

  handleSubmit: async (values, { setSubmitting,props,setErrors }) => {
    setSubmitting(false);
    const data = new FieldService();
    const result = await data.updateField({...values,id:props.id});
    if (result && result.status === 200) {
        swal({
          title: "Thành công!",
          text: "Cập nhật thành công!",
          icon: "success",
          dangerMode: false
        }).then(function() {
          props.history.goBack();
        });
      }else if(result.status===1){
        setErrors(result.errors);
      }else{
        onFailAction(result.errors);
      }
  },

})(UpdateForm);

export default userUpdateForm;
