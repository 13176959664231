import FetchAPI from "../../helpers/FetchAPI";
import {TypeMethodContsantEnum} from "../../helpers/constant/MethodAPIConstant";
import BaseServices from "../base/BaseServices";

class InfoCertificateService extends FetchAPI{
    public async list(data) {
        const result = await this.request(
            "admin/cts/index",
            data,
            TypeMethodContsantEnum.GET
        );
        return result;
    }

    public async getFileCert(id,type) {
        const result = await this.request(
            "admin/cts/get-file-cts",
            {id,type},
            TypeMethodContsantEnum.POST
        );
        return result;
    }
    public async CertAction (data)  {
        const result = await BaseServices.request(
             "admin/cts/cert-action",
            data,
            "PUT"
        );
        return result;
    };
    public async approval (id, status){
        const result = await BaseServices.request(
            "admin/cts/approval-action",
            {id, status},
            "PUT"
        );
        return result;
    }
}
export default InfoCertificateService;
