import React, { Component } from "react";
import LabelInput from "./label/LabelInput";
import ValidateMessageError from "./validate-message-error/ValidateMessageError";
import "../css/main.css";
import { Select } from "antd";

const { Option } = Select;
interface Props {
  options: any;
  label: string;
  name: string;
  wrappedClass: string;
  firstOptionLabel: string;
  onChangeValue?: any;
  onChange?: any;
  value: any;
  error?: any;
  isRequired?:boolean;
  checkUpdate? : boolean;
  dataKey?: string;
  isDisabled?: boolean;
}
interface State {}

export default class SelectProvince extends Component<Props, State> {
  state = {};

  render() {
    const { options } = this.props
    const isUpdate = this.props.checkUpdate ? true: false
    return (
      <div className={this.props.wrappedClass}>
        <LabelInput
          nameFor={this.props.name}
          label={this.props.label}
          isRequired={this.props.isRequired}
        />
        <Select
          optionFilterProp="children"
          showSearch
          className="form-control font-input"
          dropdownStyle={{ width: 200 }}
          placeholder={this.props.firstOptionLabel}
          onChange={this.props.onChange}
          data-key={this.props.dataKey}
          disabled={this.props.isDisabled}
          allowClear={true}
          value={isUpdate ? (this.props.value ? String(this.props.value) : '') : (this.props.value ? String(this.props.value) : this.props.firstOptionLabel)}
          >
          {Object.keys(options).map((key, index) => (
            <Option key={options[index]['code']} value={options[index]['code']} className={this.props.name}>
              {options[index]['fullname']}
            </Option>
          ))}
        </Select>
       <ValidateMessageError error={this.props.error}/>
      </div>
    );
  }
}
