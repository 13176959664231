
import {Component} from 'react';
import "../../common/css/dashboard.css"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import HomeService from "../../../services/home/HomeService"

interface Props {
}

interface State {
}

class AmChartColumn extends Component<Props, State>{    
    state = {
        dataSource: [{
            object:"",
            amount_last_month:0,
            amount_now_month: 0
        }],
        last_month: "",
        now_month:""
      }
      componentDidMount() {
        this.fetch()
      }
      fetch = async (params = {}) => {
          const service = new HomeService()
          const result = await service.getAmChart()
           this.setState({dataSource: result.data, last_month: result.last_month, now_month: result.now_month})
      }
    render() {
        /* Chart code */
        // Themes begin
        am4core.useTheme(am4themes_material);
        am4core.useTheme(am4themes_animated);
        // Themes end

        if(!document.getElementById('columnChart')){
            return false
        }
        let chart = am4core.create('columnChart', am4charts.XYChart);
        let title = chart.titles.create();
        title.text = "Số lượng yêu cầu chứng thư số trong các tháng";
        title.fontSize = 20;
        title.marginBottom = 30;
        chart.colors.step = 2;
        chart.logo.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = 'bottom';
        chart.legend.labels.template.maxWidth = 100;
        chart.cursor = new am4charts.XYCursor();
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'object';
        xAxis.renderer.cellStartLocation = 0.2;
        xAxis.renderer.cellEndLocation = 0.8;
        xAxis.renderer.grid.template.location = 0;

        xAxis.renderer.minGridDistance = 50;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;

        yAxis.renderer.minWidth = 50;
        yAxis.cursorTooltipEnabled = false;

        function createSeries(value, name) {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = value;
            series.dataFields.categoryX = 'object';
            series.name = name;
            series.tooltipText = "{name}: [bold]{valueY}[/]";
            series.sequencedInterpolation = true;

            series.columns.template.strokeWidth = 0;
            series.columns.template.column.cornerRadiusTopLeft = 5;
            series.columns.template.column.cornerRadiusTopRight = 5;
            series.columns.template.column.fillOpacity = 0.8;

            let hoverState = series.columns.template.column.states.create("hover");
            hoverState.properties.cornerRadiusTopLeft = 0;
            hoverState.properties.cornerRadiusTopRight = 0;
            hoverState.properties.fillOpacity = 1;


            return series;
        }

        chart.data = this.state.dataSource

        createSeries('amount_last_month', this.state.last_month);
        createSeries('amount_now_month', this.state.now_month);
        return true;
    }
}
export default AmChartColumn