import React from "react";
import ActionButtonUpdate from "../../../../common/button/action/ActionButtonUpdate";
import ActionButtonLock from "../../../../common/button/action/ActionButtonLock";
import ActionButtonDelete from "../../../../common/button/action/ActionButtonDelete";
import ActionButtonView from './../../../../common/button/action/ActionButtonView';
import ActionButtonUnLock from "../../../../common/button/action/ActionButtonUnLock";
import ActionButtonDelCertificate from "../../../../common/button/action/ActionButtonDelCertificate";
import ActionButtonDown from "../../../../common/button/action/ActionButtonDown";
import ActionButtonHandle from "../../../../common/button/action/ActionButtonHandle";
import ActionButtonActive from "../../../../common/button/action/ActionButtonActive";

interface Props {
    onClickUpdate?: any;
    onClickLock?: () => void;
    onClickUnlock?: () => void;
    onClickDelete?: () => void;
    onClickReGen?: () => void;
    onClickActive?: () => void;
    onClickView?: () => void;
    onClickAddLiquidation?: () => void;
    onClickUploadLiquidation?: () => void;
    onClickDelCertificate?: () => void;
    onClickCertDown?: () => void;
    onClickCertNoSignDown?: () => void;
    onConfirm?: () => void;
    isConfirm?: boolean;
    isDisplayUpdateButton?: boolean;
    tittle?: string;
    icon?: string;
    isLock? : boolean;
    isActive? : boolean;
    isUnLock? : boolean;
    isDelCertificate?:boolean;
    onClickDestroy?: ()=> void;
    isDestroy? : boolean;
    isDeny? : boolean;
    onClickDeny?: ()=> void;
    onClickRevoke?: ()=> void;
    isRevoke? : boolean;
    onClickPause?: ()=> void;
    isPause? : boolean;
    onClickApproval?: ()=> void;
    isApproval?: boolean;
    isCertPaperDown?:boolean;
    isCertPaperNoSignDown?:boolean;
    onClickDownCerFile?: any;
    isCerFile?: boolean;
    onClickChangeInfo? : ()=> void;
    isChangeInfo?: boolean;

    onClickDenyChangeInfo?: ()=> void;
    isDenyChangeInfo?: boolean;
    onClickDenyChangeInfoUpdate?: ()=> void;
    isDenyChangeInfoUpdate?: boolean;
    onClickResetPassword?: any;
}

export default function TableActionButton(props: Props) {
    return (
        <div className="row justify-content-center">
            {props.onClickView ? (<ActionButtonView onClick={props.onClickView}/>) : ("")}
            {props.onClickUpdate ? (<ActionButtonUpdate onClick={props.onClickUpdate} tittle={props.tittle} icon={props.icon}/>) : ("")}
            {props.isLock && props.onClickLock ? (<ActionButtonLock onClick={props.onClickLock}/>) : ("")}
            {props.isUnLock && props.onClickUnlock ? (<ActionButtonUnLock onClick={props.onClickUnlock}/>) : ("")}
            {props.isDelCertificate && props.onClickDelCertificate ? (<ActionButtonDelCertificate onClick={props.onClickDelCertificate}/>) : ("")}
            {props.isActive && props.onClickActive ? (<ActionButtonActive onClick={props.onClickActive}/>) : ("")}
            {props.onClickDelete ? (<ActionButtonDelete onClick={props.onClickDelete}/>) : ("")}
            {props.onClickReGen ? (<ActionButtonHandle onClick={props.onClickReGen} icon={"fas fa-redo fa-lg"} />) : ("")}
            {props.onClickDownCerFile && props.isCerFile ? (
                <a href ="#/" className="pointer text-success mr-1" title="Tải xuống chứng thư số" onClick={props.onClickDownCerFile ? props.onClickDownCerFile : () => {}}>
                    <i className="ml-2 fa fa-download fa-lg"/>
                </a>
            ) : ("")}
            {props.onClickCertDown && props.isCertPaperDown ? (<ActionButtonDown tittle={"File giấy chứng nhận"}  onClick={props.onClickCertDown}/>) : ("")}
            {props.onClickCertNoSignDown && props.isCertPaperNoSignDown ? (<ActionButtonDown tittle={"File giấy chứng nhận không ký"} clName={"pointer text-danger ml-1"} onClick={props.onClickCertNoSignDown}/>) : ("")}
            {props.isDestroy && props.onClickDestroy ?(<ActionButtonHandle onClick={props.onClickDestroy} icon={"fas fa-ban fa-lg"} clName={"pointer text-danger ml-1 mr-1"} tittle={"Hủy chứng thư số"}/>):("")}
            {props.isPause && props.onClickPause ?(<ActionButtonHandle onClick={props.onClickPause} icon={"fas fa-pause fa-lg"} clName={"pointer text-danger ml-1 mr-1"} tittle={"Tạm dừng chứng thư số"}/>):("")}
            {props.isRevoke && props.onClickRevoke ?(<ActionButtonHandle onClick={props.onClickRevoke} icon={"fas fa-redo fa-lg"} clName={"pointer text-danger ml-1 mr-1"} tittle={"Thu hồi chứng thư số"}/>):("")}
            {props.isApproval && props.onClickApproval ?(<ActionButtonHandle onClick={props.onClickApproval} icon={"fas fa-check-circle fa-lg"} tittle={props.tittle || "Duyệt"}/>):("")}
            {props.isConfirm && props.onConfirm ?(<ActionButtonHandle onClick={props.onConfirm} icon={"fas fa-times fa-lg"} clName={"pointer text-danger ml-1 mr-1"} tittle={"Đóng"}/>):("")}

            {props.isChangeInfo && props.onClickChangeInfo ? (<ActionButtonHandle onClick={props.onClickChangeInfo} icon={"fas fa-sync"} clName={"pointer text-primary ml-1 mr-1"} tittle={"Điều chỉnh thông tin"}/>) : ("")}
            {props.isDenyChangeInfo && props.onClickDenyChangeInfo ? (<ActionButtonHandle onClick={props.onClickDenyChangeInfo} clName={"pointer text-danger ml-1 mr-1"} tittle={"Từ chối điều chỉnh"} icon={"fas fa-ban fa-lg"}/>) : ("")}
            {props.isDenyChangeInfoUpdate && props.onClickDenyChangeInfoUpdate ? (<ActionButtonHandle onClick={props.onClickDenyChangeInfoUpdate} clName={"pointer text-primary ml-1 mr-1"} tittle={"Chỉnh sửa điều chỉnh thông tin"} icon={"far fa-edit fa-lg"}/>) : ("")}
            {props.onClickResetPassword && (<ActionButtonHandle onClick={props.onClickResetPassword} clName={"pointer text-primary ml-1 mr-1"} tittle={"Reset mật khẩu"} icon={"far fa-clock fa-lg"}/>)}

            {props.isDeny && props.onClickDeny ?(<ActionButtonHandle onClick={props.onClickDeny} icon={"fas fa-ban fa-lg"} clName={"pointer text-danger ml-1 mr-1"} tittle={"Từ chối"}/>):("")}
        </div>
    );
}
