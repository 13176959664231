import _ from "lodash";
import { Location, History } from "history";
import moment from "moment";
import Store from "../store/Store";
const  numeral = require('numeral');

export const convertJsonToQueryString = data => {
  const dataNotNull = _.pickBy(data, _.identity);
  let queryString = "";
  _.forIn(dataNotNull, function(value, key) {
    queryString += `${key}=${value}&`;
  });
  if (queryString) {
    return "?" + _.trimEnd(queryString, "&");
  }
  return "";
};

export const queryStringToJSON = queryString => {
  if (queryString.indexOf("?") > -1) {
    queryString = queryString.split("?")[1];
  }
  var pairs = queryString.split("&");
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split("=");
    result[pair[0]] = decodeURIComponent(pair[1] || "");
  });
  return result;
};

export const reloadPage = (location: Location, history: History) => {
  history.push({ pathname: location.pathname, search: location.search });
};

export const onValidateFormError = (errors, setValidateErrors) => {
  setValidateErrors(errors);
};

export const formatDate = (date: string) => {
  return moment(date).format("l");
};

export const formatDateTime = (date: string) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  } else {
    return "";
  }
};

export const formatDateTimeAll = (date: string) => {
  return moment(date).format("DD-MM-YYYY HH:mm:ss ");
};

export const checkPermission = (permission: string) => {
  if (!permission) {
    return true;
  }
  return (
    Store.getState().authReducer.permission_list.indexOf(permission) !== -1
  );
};

export const getDateCurrent = (type: string) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();

  var todayConvert =  yyyy+ type + mm + type + dd;
  return todayConvert;
};


export const permissionMenu = async (list) => {
  //tạo quyền cho menu quản lý hợp tác
  let arrAgency = ["agency-list-admin", "contributor-list-admin", "request-account-admin-list", "request-stop-cooperater", "nv-request-change-info"];
  let list1 = await createPermissionMenu(list,arrAgency,'menu-agency')

  // tạo quyền cho menu quan ly kho
  let arrWearhourse = ["wh-token", "wh-document", "wh-asset", "wh-export"];
  let list2 = await createPermissionMenu(list1, arrWearhourse, 'menu-wearhourse')

  // tạo quyền cho menu nghiệp vụ
  let arrOperation = ["nv-request-lock-account", "nv-token-manager", "nv-update-usb-token", "nv-request-cetificate",
    "nv-confirm-uc", "nv-gen-cert", "list-cer", "nv-request-change-info-certificate"];
  let list3 = await createPermissionMenu(list2, arrOperation, 'menu-operation')

  // tạo quyền cho menu hỗ trợ bán hàng
  let arrSupportSell = ["list-request-training-ad", "request-document"];
  let list4 = await createPermissionMenu(list3, arrSupportSell, 'menu-support-sell')

  // tạo quyền cho menu quản lý danh mục
  let arrCategory = ["cate-package", "cate-document", "cate-software", "cate-provider", "cate-version-token",
    "cate-asset", "cate-template-document", "cate-template-email","cate-discount-list"];
  let list5 = await createPermissionMenu(list4, arrCategory, 'menu-category')

  // tạo quyền cho menu quản lý khách hàng
  let arrCustomer = ["list-customer-ad"];
  let list6 = await createPermissionMenu(list5, arrCustomer, 'menu-customer')

  // tạo quyền cho menu quản lý hồ sơ
  let arrManagerFiles = ["contract-manager", "doc-legal-manager"];
  let list7 = await createPermissionMenu(list6, arrManagerFiles, 'menu-manager-files')

  // tạo quyền cho menu hệ thống
  let arrSystem = ["user-list", "role-list", "config-system", "config-certificate","log-management"];
  let list8 = await createPermissionMenu(list7, arrSystem, 'menu-system')

  //Tạo quyền cho menu đối soát
  let arrCrossChecking = ["cross-checking-list"]
  let list9 = await createPermissionMenu(list8, arrCrossChecking,'menu-crosschecking')
  return await list9;
}

const createPermissionMenu = (list, child, per) => {
  child.forEach(element => {
    if(list.includes(element)){
      list.push(per)
    }
  });
  let unique = list.filter(function(elem, index, self) {
    return index === self.indexOf(elem);
  })
  return unique;
}
export const currencyFormat = (num,status) => {
  let value = num.toString()
  if(status === 1)
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  if(status === 2)
    return value.replace(/[^0-9]/gi, '')
}
export const handleDateData = (data: Object, list: Object) => {
  for (let key in list) {
    const value = list[key];
    if(data[value]._i){
      data[value] = data[value]._i;
    }else{
      data[value] = data[value].format('YYYY-MM-DD');
    }
  }
  return data;
};

export const handleFormatString = (str, type) =>{
  //type = 1: Viết hoa chữ cái đầu các từ trong chuỗi
  //type = 2: Viết hoa chữ cái đầu của chuỗi
  //type = 3: In hoa chuỗi
  if (Number(type) === 1){
    const words = str.split(' ');
    const arr = [];
    words.filter(function(val){
      // @ts-ignore
      return arr.push(val.charAt(0).toUpperCase()+ val.substr(1).toLowerCase());
    });
    return arr.join(" ").trim();
  }else if(Number(type) === 2){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }else if (Number(type) === 3){
    return str.toUpperCase();
  }
};

export const formatMoney = (price) => {
  let priceInt = parseInt(price);

  var num_price = numeral(priceInt).format('0,0');

  return num_price;

};