import React from "react";
import { Route, Redirect } from "react-router";
import { authenticationService } from "../../services/AuthenticationService";
import {checkPermission} from "../../helpers/NewCaHelper";
import { Result } from "antd";
import store from "../../store/Store";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const currentUser = authenticationService.currentUserValue;
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (!checkPermission(rest.permission)){
          return <Result
              status={403}
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
          />
      }
      // authorised so return component
      return <Component user={store.getState().authReducer}{...props} {...rest} />;
    }}
  />
);
