import React, { Component } from "react";
import { InjectedFormikProps, withFormik } from "formik";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import { History } from "history";
import * as Yup from "yup";
import FieldService from "../../../../services/field/FieldService";
import { onSuccessAction, onFailAction } from "../../../common/swal/SwalCommon";

interface Props {
  history: History;
}
interface State {
  name: string;
  code: string;
}

class CreateForm extends Component<InjectedFormikProps<Props, State>> {
  state = {
    type: "",
    arr_permissions: []
  };
  componentDidMount() {
  }

  render() {
    const {
      name,
      code
    } = this.props.values;
    return (
      <form>
        <div className="input-group">
          <InputWithLabel
            name="code"
            label="Mã lĩnh vực"
            wrapClass="col-md-6 form-group"
            onChangeValue={this.props.handleChange}
            value={code}
            error={this.props.errors.code}
            isRequired={true}
          />
          <InputWithLabel
            name="name"
            label="Tên lĩnh vực"
            wrapClass="col-md-6 form-group"
            onChangeValue={this.props.handleChange}
            value={name}
            error={this.props.errors.name}
            isRequired={true}
          />
        </div>
        <div className="input-group d-flex justify-content-center p-5">
          <BackButton history={this.props.history} url='/linh-vuc-kinh-doanh'></BackButton>
          <SubmitButton onClick={this.props.handleSubmit}></SubmitButton>
        </div>
      </form>
    );
  }
}

const CreateFormFor = withFormik<Props, State>({
  mapPropsToValues: (props: Props) => ({
    name: "",
    code: "",
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Tên lĩnh vực không được bỏ trống."),
    code: Yup.string().required("Mã lĩnh vực không được bỏ trống."),
  }),
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  handleSubmit: async (values, { setSubmitting, props,setErrors }) => {
    setSubmitting(false);
    const service = new FieldService();
    const result = await service.createField(values);
    if (result) {
      if(result.status===200){
        onSuccessAction('Thêm mới lĩnh vực thành công!',function(){
          props.history.goBack();
        })
      }else if(result.status===1){
        setErrors(result.errors);
      }else{
        onFailAction(result.errors);
      }
    }
  }
})(CreateForm);

export default CreateFormFor;
