import React, { useState } from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString,
    formatDateTime,
    formatDateTimeAll,
    queryStringToJSON,
} from "../../../helpers/NewCaHelper";
import { RouteComponentProps } from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import { onFailAction } from "../../../helpers/SwalCommon";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import InfoCertificateService from "../../../services/infocertificate/InfoCertificateService";
import TableActionButton from "../user/table/action-button/TableActionButton";
// import ModalHandel from "../../common/modal/HandleModal";
import { Popover, Tag } from "antd";
import SelectDate from "../../common/form/SelectDate";
import SelectBox from "../../common/form/SelectBox";
import { STATUS_CERTIFICATE } from "../gen-cert-cts/Enum";
const { REACT_APP_BASE_API_URL } = window["runConfig"];
interface Props extends RouteComponentProps {}

const TITLE = "Danh sách chứng thư số";

const InfoCertificate: React.FC<Props> = (props) => {
    const [dataSearch, setDataSearch] = useState({
        text_search: "",
        serial_search: "",
        created_at: "",
        status: null,
    });
    // const [title, setTitle] = useState("");
    // const [okText, setOkText] = useState("");
    // const [placeholder, setPlaceholder] = useState("");
    const onSubmitSearchButton = (e) => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search) {
            if (key === "raw") {
                raw["raw"] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({ ...dataSearch, ...raw });
        props.history.push({
            search: queryString,
        });
    };
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at: dateString });
    };

    const onChangeDataSearchCode = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };
    const onChangeDataSearchSerial = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({ ...dataSearch, [name]: value });
    };

    const onChangeDataSearchStatus = (e) => {
        setDataSearch({ ...dataSearch, status: e });
    };

    const fetchDataFunction = async () => {
        const service = new InfoCertificateService();
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({
                ...dataSearch,
                ...queryString,
            });
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const onClickSigned = (type, id) => {
        try {
            var token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/cts/get-file-cts/${id}/${type}?token=${obj.token}`;
                window.open(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra!");
        }
    };

    const onRenderActionColumn = (text, record, index) => {
        return (
            <div>
                <TableActionButton
                    onClickDownCerFile={() => downloadCer(record.id)}
                    isCerFile={checkActionButtonDownCert(record)}
                    onClickCertDown={() => onClickSigned(1, record.id)}
                    isCertPaperDown={checkActionButtonDownCertPaper(record)}

                    // isCertPaperNoSignDown={checkActionButtonDownCert(record)}
                    // onClickCertNoSignDown={() => onClickSigned(2, record.id)}

                    // onClickRevoke={() => submitAction(record.id, 1)}
                    // isRevoke={checkActionButton(record)}

                    // onClickDestroy={() => submitAction(record.id, 2)}
                    // isDestroy={checkActionButton(record)}

                    // onClickPause={() => submitAction(record.id, 3)}
                    // isPause={checkActionButton(record)}

                    // onClickApproval={() => submitApprovalAction(record.id, record.status)}
                    // isApproval={checkApprovalButton(record)}
                />
            </div>
        );
    };

    const downloadCer = async (id) => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}admin/gen-cts/download-cert/${id}?token=${obj.token}`;
                window.open(link);
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra!");
        }
    };
    // const checkActionButton = (record: any): any => {
    // 	if (Number(record.status) === 1) return true;
    // 	return false;
    // };
    const checkActionButtonDownCert = (record: any): any => {
        if (Number(record.status) === 1 && record.path_cer) return true;
        return false;
    };
    const checkActionButtonDownCertPaper = (record: any): any => {
        if (Number(record.status) === 1 && record.certificate_paper_path)
            return true;
        return false;
    };
    // const [modalAction, setModalAction] = useState({
    // 	visible_modal_deny : false,
    // 	loading: false,
    // 	reason: "",
    // 	error_reason: "",
    // 	id_cert: "",
    // 	type: "",
    // });
    // const handleModalActionCancel = () => {
    // 	setModalAction({...modalAction, 'visible_modal_deny':false})
    // };
    // const confirmAction = async () => {
    // 	if (modalAction.reason === "") {
    // 		setModalAction({...modalAction, 'error_reason': "Lý do không được bỏ trống" });
    // 		return false;
    // 	}
    // 	const data = {reason:modalAction.reason, id:modalAction.id_cert, type:modalAction.type};
    // 	const servicesCertInfo = new InfoCertificateService();
    // 	const result = await servicesCertInfo.CertAction(data);
    // 	await setModalAction({...modalAction, 'visible_modal_deny': false });

    // 	if (result && Number(result.status) === 200) {
    // 		onSuccessAction("Thành công!", () => {
    // 			window.location.reload();
    // 		});
    // 	} else if (result && Number(result.status) === 422) {
    // 		onFailAction("Có lỗi xảy ra!");
    // 	} else {
    // 		onFailAction(result.error);
    // 	}
    // };
    // const onChangeDataAction = ({ target: { value} }) => {
    // 	setModalAction({...modalAction, 'reason': value});
    // };

    // const submitAction = (id, type) => {
    // 	if(Number(type) === 1){
    // 		setTitle("Lý do thu hồi chứng thư số");
    // 		setPlaceholder("Nhập vào lý do thu hồi chứng thư số");
    // 		setOkText("Thu hồi");
    // 	}
    // 	if(Number(type) === 2){
    // 		setTitle("Lý do hủy chứng thư số");
    // 		setPlaceholder("Nhập vào lý do hủy chứng thư số");
    // 		setOkText("Hủy");
    // 	}
    // 	if(Number(type) === 3){
    // 		setTitle("Lý do tạm dừng chứng thư số");
    // 		setPlaceholder("Nhập vào lý do tạm dừng chứng thư số");
    // 		setOkText("Tạm dừng");
    // 	}
    // 	setModalAction({...modalAction, 'visible_modal_deny': true, id_cert: id, type: type });
    // };
    // const approvalAction = async (id, status) =>{
    // 	const servicesCertInfo = new InfoCertificateService();
    // 	const approval = await servicesCertInfo.approval(id, status);
    // 	if(Number(approval.status) === 200){
    // 		onSuccessAction("Duyệt thành công", ()=>{
    // 			window.location.reload();
    // 		})
    // 	}else {
    // 		onFailAction("Có lỗi xảy ra trong quá trình duyệt!!!")
    // 	}
    // };
    // const checkApprovalButton = (record: any): any => {
    // 	if (Number(record.status) === 2 || Number(record.status) === 4 || Number(record.status) === 6) return true;
    // 	return false;
    // };
    const renderStatusColumn = (text, record, index) => {
        return (
            <Tag color={`${STATUS_CERTIFICATE[text]?.class}`}>
                {STATUS_CERTIFICATE[text]?.label}
            </Tag>
        );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form
                    onSubmit={onSubmitSearchButton}
                    className="search-form-index"
                >
                    {/* <ModalHandel
						visible={modalAction.visible_modal_deny}
						handleCancel={handleModalActionCancel}
						handleAction={confirmAction}
						value={modalAction.reason}
						onChange={onChangeDataAction}
						error={modalAction.error_reason}
						title={title}
						okText={okText}
						placeholder={placeholder}

					/> */}
                    <div className="input-group">
                        <InputWithLabel
                            name="text_search"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchCode}
                            value={dataSearch.text_search}
                            isRequired={false}
                            label={""}
                            placeholder={"Mã số DN hoặc Số CMND"}
                        />
                        <InputWithLabel
                            name="serial_search"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchSerial}
                            value={dataSearch.serial_search}
                            isRequired={false}
                            label={""}
                            placeholder={"Serial chứng thư số"}
                        />
                        <SelectDate
                            label=""
                            name="created_at"
                            wrappedClass="col-md-2 form-group nopadding-left"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày hiệu lực"
                        />
                        <SelectBox
                            name="status"
                            options={{
                                1: "Hoạt động",
                                2: "Đã thu hồi",
                                3: "Đã hủy",
                                4: "Đã tạm dừng",
                                8: "Đã xóa",
                                9: "Đã hết hạn",
                                10: "Chờ khách hàng ký xác nhận thu hồi",
                                11: "Chờ nghiệp vụ duyệt thu hồi",
                                16: "Chờ nghiệp vụ duyệt hủy",
                                25: "Chờ thay đổi thông tin",
                                26: "Chờ cấp lại thiết bị",
                                27: "Chờ bảo hành thiết bị",
                            }}
                            label=""
                            wrappedClass="col-md-2 nopadding-left"
                            firstOptionLabel="Trạng thái"
                            onChange={onChangeDataSearchStatus}
                            value={dataSearch.status}
                        />
                        <div className="col-md-2 form-group">
                            <SearchButton onClick={onSubmitSearchButton} />
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Mã định danh",
                            dataIndex: "",
                            render: (e) => {
                                if (
                                    Number(e.object) === 1 ||
                                    Number(e.object) === 2 ||
                                    Number(e.object) === 3
                                ) {
                                    if (e.requestcertificate) {
                                        if (e.requestcertificate.organization) {
                                            return e.requestcertificate
                                                .organization.code;
                                        }
                                        if (
                                            e.requestcertificate.requestpersonal
                                        ) {
                                            return e.requestcertificate
                                                .requestpersonal.passport;
                                        }
                                    }
                                } else if (Number(e.object) === 4) {
                                    return e.request_change_info? e.request_change_info.code : '';
                                } else if (
                                    Number(e.object) === 5 ||
                                    Number(e.object) === 6
                                ) {
                                    return e.request_renew_and_warranty
                                        ? e.request_renew_and_warranty.identity
                                        : "";
                                } else {
                                    return "";
                                }
                            },
                        },
                        {
                            title: "Serial CTS",
                            dataIndex: "certificateSerial",
                        },
                        {
                            title: "Subject DN",
                            dataIndex: "subjectDN",
                            className: "m-w-30",
                        },
                        {
                            title: "Ngày hiệu lực",
                            dataIndex: "certificateBegin",
                            render: (text) => formatDateTime(text),
                        },
                        {
                            title: "Ngày kết thúc",
                            dataIndex: "certificateEnd",
                            render: (text) => formatDateTime(text),
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: renderStatusColumn,
                        },
                        {
                            title: "Lý do",
                            dataIndex: "",
                            render: (e) => {
                                if (Number(e.status) !== 1 && e.reason) {
                                    let content = "";
                                    if (Number(e.reason) === 1) {
                                        content = "Mất token";
                                    }
                                    if (Number(e.reason) === 2) {
                                        content = "Bảo hành";
                                    }
                                    if (Number(e.reason) === 3) {
                                        content = "Hỏng";
                                    }
                                    if (Number(e.reason) === 4) {
                                        content = "Khác";
                                    }
                                    return (
                                        <Popover
                                            content={
                                                Number(e.reason) === 4
                                                    ? e.reason_extend
                                                    : ""
                                            }
                                        >
                                            {content}
                                        </Popover>
                                    );
                                }
                            },
                        },
                        {
                            title: "Ngày yêu cầu",
                            dataIndex: "",
                            render: (e) => {
                                if (
                                    [
                                        2, 3, 4, 8, 9, 10, 11, 12, 15, 16, 17,
                                    ].includes(Number(e.status)) &&
                                    e.date_request
                                ) {
                                    return formatDateTimeAll(e.date_request);
                                }
                            },
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn,
                            width: "10%",
                        },
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
};

export default withNavbar()(InfoCertificate);
