import React, {useEffect, useState} from "react";
import withNavbar from "../../../common/HOC/NavbarContainerHOC";
import {FormikProps, withFormik} from "formik";
import * as Yup from "yup";
import InputWithLabel from "../../../common/form/InputWithLabel";
import BackButton from "../../../common/button/BackButton";
import SubmitButton from "../../../common/form/SubmitButton";
import {onFailAction, onSuccessAction} from "../../../../helpers/SwalCommon";
import SelectBox from "../../../common/form/SelectBox";
import {RouteComponentProps} from "react-router-dom";
import BannerService from "../../../../services/banner/BannerService";
import InputFileUpload from "../../../common/form/InputFileUpload";
import {Input} from "antd";
import {loading} from "../../../common/loading/NewLoading";
import BaseServices from "../../../../services/base/BaseServices";

const {TextArea} = Input;

interface FormValues {
    status: string;
    name: string;
    order_by: string;
    link: string;
    path: string;
    description: string;
}

interface OtherProps
    extends RouteComponentProps<{
        id: string;
    }> {
}

type Props = OtherProps & FormikProps<FormValues>;

const BannerUpdate: React.FC<Props> = props => {

    /**
     * Define
     */
    const id = props.match.params.id;
    const {status, name, link, description, order_by} = props.values;

    /**
     * State
     */
    const [fileName, setFileName] = useState('');

    /**
     * Function
     */
    const onChangeName = (e) => {
        props.setFieldValue('name', e.target.value);
        props.setFieldError('name', '')
    };
    const onChangeOrderBy = (e) => {
        props.setFieldValue('order_by', e.target.value);
        props.setFieldError('order_by', '')
    };
    const onChangeDescription = (e) => {
        props.setFieldValue('description', e.target.value);
    };
    const onChangeLink = (e) => {
        props.setFieldValue('link', e.target.value);
    };
    const onChangeStatus = (e) => {
        props.setFieldValue("status", e);
        props.setFieldError('status', '')
    };
    const onChangeFile = async e => {
        const files = e.target.files[0];
        if (files) {
            const formData = new FormData();
            formData.append("file", files);
            loading.runLoadingBlockUI();
            const fileData = await BaseServices.axiosUpLoadFile(formData);
            props.setFieldValue("path", fileData.file);
            props.setFieldError('path', '')
            setFileName(files.name);
            loading.stopRunLoading();
        } else {
            setFileName('');
            props.setFieldValue("path", '');
        }
    };

    /**
     * Fetch
     */
    const onFetchData = async () => {
        const service = new BannerService();
        const banner = await service.find(id);
        for (let key in banner.data) {
            props.setFieldValue(key, banner.data[key]);
        }
        setFileName(banner.data.path);

    };
    useEffect(() => {
        onFetchData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="card">
            <form>
                <h4 className="card-title1 ml-3">Cập nhật banner</h4>
                <p><i className="ml-3">Lưu ý: Tỷ lệ chuẩn của banner hiện tại là cao: 700px, rộng: 682px</i></p>
                <div className="input-group">
                    <InputWithLabel
                        name="name"
                        label="Tên banner"
                        wrapClass="col-md-2 form-group"
                        onChangeValue={onChangeName}
                        value={name}
                        error={props.errors.name}
                        maxLength={255}
                        isRequired={true}
                    />
                    <InputWithLabel
                        name="order_by"
                        label="Thứ tự ưu tiên"
                        type={'number'}
                        wrapClass="col-md-2 form-group"
                        onChangeValue={onChangeOrderBy}
                        value={order_by}
                        error={props.errors.order_by}
                        maxLength={255}
                        isRequired={true}
                    />
                    <SelectBox
                        name="status"
                        options={{
                            1: "Hoạt động",
                            2: "Không hoạt động"
                        }}
                        label="Trạng thái"
                        wrappedClass="col-md-2 form-group"
                        firstOptionLabel=""
                        onChange={onChangeStatus}
                        value={status}
                        error={props.errors.status}
                        isRequired={true}
                    />
                    <InputWithLabel
                        name="link"
                        label="Đường dẫn liên hệ"
                        wrapClass="col-md-3 form-group"
                        onChangeValue={onChangeLink}
                        value={link}
                        maxLength={255}
                        isRequired={false}
                    />
                    <InputFileUpload
                        name="path"
                        classWrapped="col-md-3 form-group"
                        classChild="custom-file"
                        label="File ảnh"
                        filename={fileName}
                        onChangeFileUpload={onChangeFile}
                        accept={[".png", ".jpg", ".jpeg", ".JPG", ".PNG", ".JPEG"]}
                        error={props.errors.path}
                        isRequired={true}
                    />
                </div>
                <div className="col-md-12">
                    <label>Mô tả</label>
                    <TextArea
                        name="description"
                        placeholder="Ghi chú"
                        autoSize={{minRows: 4, maxRows: 10}}
                        className="form-control"
                        required={true}
                        value={description}
                        onChange={onChangeDescription}
                    />
                </div>
                <div className="input-group d-flex justify-content-center p-5">
                    <BackButton history={props.history} url="/banner"/>
                    <SubmitButton onClick={props.handleSubmit}/>
                </div>
            </form>
        </div>
    );
};

const RenderCreateForm = withFormik<Props, FormValues>({
    mapPropsToValues: () => ({
        status: '',
        name: '',
        order_by: '',
        link: '',
        description: '',
        path: ''
    }),
    validationSchema: Yup.object().shape({
        status: Yup.string().required("Trạng thái không thể bỏ trống."),
        name: Yup.string().required("Tên banner không thể bỏ trống."),
        path: Yup.string().required("File ảnh không thể bỏ trống."),
        order_by: Yup.string().required("Thứ tự ưu tiên không thể bỏ trống."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    handleSubmit: async (values, {setSubmitting, props, setErrors}) => {
        setSubmitting(false);
        const service = new BannerService();
        const result = await service.update({
            ...values,
            id: props.match.params.id
        });
        if (result.status === 200) {
            onSuccessAction("Cập nhật thành công!", function () {
                props.history.push(`/banner`);
            });
        } else if (result.status === 422) {
            setErrors(result.error);
        } else {
            onFailAction(result.error);
        }
    }
})(BannerUpdate);

export default withNavbar()(RenderCreateForm);
