import React, {useState} from "react";
import Helmet from "react-helmet";
import {
    convertJsonToQueryString, formatDateTime, queryStringToJSON
} from "../../../helpers/NewCaHelper";
import {RouteComponentProps} from "react-router-dom";
import InputWithLabel from "../../common/form/InputWithLabel";
import SearchButton from "../../common/button/SearchButton";
import NewTable from "../../common/table/NewTable";
import withNavbar from "../../common/HOC/NavbarContainerHOC";
import TableActionButton from "../user/table/action-button/TableActionButton";
import SelectBox from "../../common/form/SelectBox";
import {onFailAction} from "../../../helpers/SwalCommon";

import CustomerService from "../../../services/customer/CustomerService"
import { Tag } from 'antd';
import SelectDate from "../../common/form/SelectDate";

interface Props extends RouteComponentProps{
}

const TITLE = "Danh sách khách hàng";

const Customer: React.FC<Props> = props => {
    const service = new CustomerService();
    const [dataSearch, setDataSearch] = useState({
        type_request: "",uid:"",code: "",name: "", created_at: ""});
    const onSubmitSearchButton = e => {
        e.preventDefault();
        const search = queryStringToJSON(props.location.search);
        let raw = {};
        for (let key in search){
            if (key === 'raw'){
                raw['raw'] = search[key];
            }
        }
        const queryString = convertJsonToQueryString({...dataSearch, ...raw});
        props.history.push({
            search: queryString
        });
    };
    const onChangeSelectDateValid = (date, dateString) => {
        setDataSearch({ ...dataSearch, created_at: dateString });
    };
    const onChangeDataSearchInput = e => {
        const name = e.target.name;
        const value = e.target.value;
        setDataSearch({...dataSearch, [name]: value});
    };
    const onChangeDataSearchTypeRequest = (e) => {
        setDataSearch({...dataSearch, "type_request": e});
    };
    const fetchDataFunction = async () => {
        const queryString = queryStringToJSON(props.location.search);
        try {
            const result = await service.list({...dataSearch,...queryString});
            return result;
        } catch (error) {
            console.log(error);
            onFailAction();
        }
    };
    const checkActionButton = (record: any): any => {
        if (Number(record.accept_change === true )) return true;
        return false;
    };
    const checkActionButton2 = (record: any): any => {
        if (Number(record.accept_update_change === true )) return true;
        return false;
    };
    const onRenderActionColumn = (text, record, index) => {
        return (
            <TableActionButton
                onClickView={() =>
                    props.history.push(record.passport? `/danh-sach-khach-hang/xem-chi-tiet-ca-nhan/${record.id}/${record.created_by}/${record.uid}`
                        : `/danh-sach-khach-hang/xem-chi-tiet-to-chuc/${record.id}/${record.created_by}/${record.uid}`)
                }
                onClickChangeInfo={() =>
                    props.history.push(record.passport? `/danh-sach-khach-hang/thay-doi-thong-tin-ca-nhan/${record.id}/${record.created_by}/${record.uid}`
                        : `/danh-sach-khach-hang/thay-doi-thong-tin-to-chuc/${record.id}/${record.created_by}/${record.uid}`)
                }
                isChangeInfo={checkActionButton(record)}
                onClickDenyChangeInfoUpdate={() =>
                    props.history.push(record.passport? `/danh-sach-khach-hang/chinh-sua-thay-doi-thong-tin-ca-nhan/${record.id}/${record.uid}`
                        : `/danh-sach-khach-hang/chinh-sua-thay-doi-thong-tin-to-chuc/${record.id}/${record.uid}`)
                }
                isDenyChangeInfoUpdate={checkActionButton2(record)}
            />
        );
    };
    return (
        <div className="card">
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <h4 className="card-title1">{TITLE}</h4>
            <div className="card-body">
                <form onSubmit={onSubmitSearchButton} className="search-form-index">
                    <div className="input-group">
                        <SelectBox
                            options={{1: "Tổ chức" , 2: "Cá nhân"}}
                            label={""}
                            name={"type_request"}
                            wrappedClass={"col-md-2 nopadding-left"}
                            onChange={onChangeDataSearchTypeRequest}
                            value={dataSearch.type_request}
                            firstOptionLabel = {"Tổ chức"}
                        />
                        <InputWithLabel
                            name="uid"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.uid}
                            label={""}
                            placeholder="Mã khách hàng"
                        />
                        <InputWithLabel
                            name="name"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.name}
                            label={""}
                            placeholder="Tên khách hàng"
                        />
                        <InputWithLabel
                            name="code"
                            wrapClass="col-md-2 form-group nopadding-left"
                            onChangeValue={onChangeDataSearchInput}
                            value={dataSearch.code}
                            label={""}
                            placeholder="Mã định danh"
                        />
                        <SelectDate
                            label=""
                            name="created_at"
                            wrappedClass="col-md-2 form-group nopadding-left"
                            onChange={onChangeSelectDateValid}
                            value={dataSearch.created_at}
                            isRequired={true}
                            placeholder="Ngày tạo"
                        />
                        <div className="col-md-2 form-group nopadding-left">
                            <SearchButton onClick={onSubmitSearchButton}/>
                        </div>
                    </div>
                </form>
                <NewTable
                    columns={[
                        {
                            title: "Mã khách hàng",
                            dataIndex: "uid"
                        },
                        {
                            title: "Mã định danh",
                            dataIndex: "",
                            render: (e)=> {
                                if (e) {
                                    if(e.passport){
                                        return <p>{e.passport}</p>;
                                    }else{
                                        return <p>{e.code}</p>;
                                    }
                                }
                            }
                        },
                        {
                            title: "Tên khách hàng",
                            dataIndex: "fullname"
                        },
                        {
                            title: 'Loại khách hàng',
                            dataIndex: "",
                            render: (e) => {
                                if (e) {
                                    if(e.passport){
                                        return <Tag color="green">Cá nhân</Tag>
                                    }else{
                                        return <Tag color="blue">Tổ chức</Tag>
                                    }
                                }
                            }
                        },
                        {
                            title: "Email",
                            dataIndex: "email",
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_at",
                            render: text => formatDateTime(text)
                        },
                        {
                            title: "Tác vụ",
                            render: onRenderActionColumn
                        }
                    ]}
                    onFetchData={() => fetchDataFunction()}
                />
            </div>
        </div>
    );
}
export default withNavbar()(Customer);
